import React, { useEffect } from "react";
import TrackMetadataIcon from "../../../components/svg/search/TrackMetadataIcon";
import {
  addToPlaylistData,
  getCommitSearch,
  getCommitedSearchQuery,
  getSearchQuery,
  getTrackResultData,
} from "../../../helper/ReduxSelectorContext";
import SongDetailCard from "../../../components/playlist/SongDetailCard";
import { Virtuoso } from "react-virtuoso";
import AddToPlaylistModeButton from "../../../components/playlist/AddToPlaylistModeButton.jsx";
import { useDispatch } from "react-redux";
import { disableListSelectMode } from "../../../redux/adToPlaylist/AddToPlaylistSlice.js";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {
  getArtistTracksWithExcludedTrackId,
  getSearchResultV8,
} from "../../../services/ApiClient";
import SongListLoading from "../../../components/loading/SongListLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMemo } from "react";
import CssShimmer from "../../../components/common/CssShimmer";
import { useStoreSearchHistory } from "../../../hooks/useStoreSearchHistory";
import { SearchItemType } from "../../../consts/SearchItemType";
import SongsDetailCardShimmer from "../../../components/Tickets/SongsDetailCardShimmer";
import SearchNoResultView from "../SearchNoResultView";
import {setPlayerPaginationData, setPlayList, setPlayListstatic} from "../../../redux/player/PlayMusicSlice";

function TrackSearchResultTab({ scrollTop }) {
  const searchparam = getCommitedSearchQuery();
  const [, storeSearchItemWithType] = useStoreSearchHistory();
  const searchCommit = getCommitSearch();
  const dispatch = useDispatch();
  const shimmerArray = [1, 2, 3, 4, 5, 6, 6, 7, 8, 9];
  const getArtistTracksWithExcludedTrackIdMutation = useMutation(
    (data) => getArtistTracksWithExcludedTrackId(data),
    {
      onSuccess: (res,variables) => {
        dispatch(setPlayList({ playList: res?.data?.result?.tracks }));
        dispatch(
          setPlayListstatic({ playListStatic: res?.data?.result?.tracks }),
        );
          console.log(getArtistTracksWithExcludedTrackIdMutation,'data updated...')
          dispatch(setPlayerPaginationData({
              playerPaginationData: {
                  url: '/getMoreTracksByTrackId',
                  payload: {track_id: variables?.track_id},
                  end: res?.data?.result?.end
              }
          }))
      },
    }
  );
  const getSearchTrackDataQuery = useInfiniteQuery(
    [`getSearchTrackDataQuery` + searchparam],
    ({ pageParam = 0 }) =>
      getSearchResultV8({
        q: searchparam,
        type: 1,
        offset: pageParam,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.result?.end) return null;
        const allData = allPages?.flatMap((page) => page?.data?.result?.tracks);
        return allData?.length;
      },
      onSuccess: (res) => {
        console.log("dgsdjgkjhsfsdfsdjkgh", res);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const addToPlaylistDataState = addToPlaylistData();

  useEffect(() => {
    return () => {
      dispatch(disableListSelectMode());
    };
  }, []);

  const trackLists = useMemo(
    () =>
      getSearchTrackDataQuery?.data?.pages?.flatMap((page) => {
        if (page?.data?.error) return [];
        return page?.data?.result?.tracks;
      }),
    [getSearchTrackDataQuery?.data]
  );
  const getMonthNewSongNextPage = () => {
    if (
      getSearchTrackDataQuery?.hasNextPage &&
      !getSearchTrackDataQuery?.isFetchingNextPage
    ) {
      getSearchTrackDataQuery.fetchNextPage();
    }
  };
  const storeInSearchHistory = (item) => {
    getArtistTracksWithExcludedTrackIdMutation.mutate({
      offset: 0,
      track_id: item?.id,
    });
    dispatch(setPlayList({ playList: [] }));
    storeSearchItemWithType(
      { track: item, id: item?.id },
      SearchItemType()?.track
    );
  };

  useEffect(() => {
    getSearchTrackDataQuery.refetch();
  }, [searchCommit]);
  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <div
      dir="rtl"
      className="w-full h-full flex flex-col overflow-scroll hide-scroll-bar "
    >
      <AddToPlaylistModeButton
        EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}
      />
      {getSearchTrackDataQuery?.isLoading ? (
        <div className="flex w-full flex-col hide-scroll-bar overflow-scroll !px-4 gap-2 pb-3 mt-4">
          {Array.from(
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
              <SongsDetailCardShimmer />
            ))
          )}
        </div>
      ) : trackLists?.length == 0 ? (
        <div className="w-full h-full flex justify-center items-center mt-14">
          <SearchNoResultView />
        </div>
      ) : (
        <InfiniteScroll
          className="!overflow-hidden"
          scrollableTarget="search-result-parent"
          dataLength={trackLists?.length || 0}
          next={getMonthNewSongNextPage}
          hasMore={getSearchTrackDataQuery?.hasNextPage}
          loader={
            <div className="w-full grid justify-center items-center mt-4">
              <SongListLoading />
            </div>
          }
        >
          <div className=" flex flex-col w-full  pb-16 !px-4 !py-3 gap-2 ">
            <Virtuoso
              customScrollParent={document.getElementById(
                "search-result-parent"
              )}
              data={trackLists}
              itemContent={(index, item) => (
                <div
                  onClick={() => storeInSearchHistory(item)}
                  className="py-[3px]"
                >
                  <SongDetailCard
                    isSelectMode={
                      addToPlaylistDataState?.listSelectItems?.length > 0
                    }
                    inPlayer={false}
                    data={item}
                    index={index}
                    key={index + "track-item"}

                  />
                </div>
              )}
            />
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
}

export default React.memo(TrackSearchResultTab);
