import React, { useState, useEffect } from 'react';
import needMergeWebp from '../../assets/setting/merge.webp';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { CardActionArea } from '@mui/material';
import PrimaryButton from '../../components/common/PrimaryButton.jsx';
import Loading from '../../components/loading/Loading.jsx';
import { useMutation } from '@tanstack/react-query';
import { mergeAccounts } from '../../services/ApiClient.js';
import CommonHeader from '../../components/common/CommonHeader.jsx';
import { login, userData } from '../../redux/login/LoginSlice.js';
import { useDispatch } from 'react-redux';
const NeedMergePage = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [mergeData, setMergeData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location?.state) {
      setMergeData(location?.state);
      console.log(location);
    }
  }, [location]);

  const dispatchToken = token => {
    dispatch(
      login({
        token: token,
      }),
    );
  };

  const dispatchUserData = Data => {
    dispatch(
      userData({
        user: Data,
      }),
    );
  };
  const goToEditNumber = () => {
    navigate('/merge-number', { replace: true });
  };

  const handleMergeAccounts = () => {
    mutateMergeAccounts.mutate({
      phone: mergeData?.phone,
      code: mergeData?.code,
    });
  };

  const mutateMergeAccounts = useMutation(
    verifyData => mergeAccounts(verifyData),
    {
      onSuccess: res => {
        console.log(res);
        //dispatch token when response true
        if (res?.data?.done) {
          //     //login user in verified
          console.log(res?.data);
          dispatchToken(res?.data?.result?.token);
          dispatchUserData(res?.data?.result?.user);
          navigate('/', { replace: true });
        } else {
          setError(res?.data?.error?.message);
        }
      },
      onError: err => {
        console.log(err);
        setError('خطایی پیش آمده');
      },
    },
  );
  return (
    <>
      <CommonHeader title={'ادغام حساب کاربری'} className="fixed z-30" />
      <div className="bg-primary_dark hide-scroll-bar fixed scroll pt-16 top-0 left-0 right-0 bottom-0 z-10 overflow-scroll flex flex-col justify-center items-center w-full h-full mx-auto max-w-[520px] ">
        <div className="w-full h-full px-4 mx-auto flex flex-col justify-start  overflow-scroll hide-scroll-bar">
          <div className="w-full flex flex-col flex justify-center items-center">
            <div className="w-full relative" style={{ aspectRatio: 329 / 170 }}>
              <CustomLazyLoadImage
                appendBaseUrl={false}
                image={needMergeWebp}
              />
            </div>
          </div>
          <div
            dir="rtl"
            className="font-dana font-[500] text-primary mt-5 text-right text-lg"
          >
            {mergeData?.needMergeData?.merge_dialog?.title}
          </div>
          <p
            dir="rtl"
            className="font-dana my-5 text-white text-sm min-[375px]:text-base"
          >
            {mergeData?.needMergeData?.merge_dialog?.description}
          </p>
          <div className="flex flex-row justify-center items-center w-full">
            <CardActionArea
              className={`!rounded-[1.3rem] !w-[200px] mx-auto ${
                !mutateMergeAccounts.isLoading
                  ? 'pointer-events-auto'
                  : 'pointer-events-none'
              }`}
            >
              <PrimaryButton
                className=" !h-[2.875rem]  !font-dana !text-[1.04rem] !font-semibold !w-full !text-[#333333] !tracking-normal   !bg-primary !rounded-[1.3rem]"
                onClick={handleMergeAccounts}
                disabled={mutateMergeAccounts.isLoading}
              >
                {mutateMergeAccounts.isLoading ? (
                  <Loading bgColor={'#000000'} />
                ) : (
                  'ادغام'
                )}
              </PrimaryButton>
            </CardActionArea>
          </div>
          {error && (
            <div>
              <p className="text-red text-[.875rem] font-dana  font-[400] mt-2 ">
                {error}
              </p>
            </div>
          )}
          <button
            className="w-full font-[500] font-dana select-none outline-none flex flex-row justify-center items-center text-primary text-base text-[16px] min-h-[56px] mt-2"
            onClick={goToEditNumber}
          >
            ویرایش شماره
          </button>
        </div>
      </div>
    </>
  );
};

export default NeedMergePage;
