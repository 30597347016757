import React from "react";
import DeleteIconSvg from "../svg/support/DeleteIconSvg.jsx";
import SuccessFullAttachment from "../svg/support/SuccessFullAttachment.jsx";
import { LinearProgress, Box, linearProgressClasses } from "@mui/material";
import CancelSvg from "../svg/support/CancelSvg.jsx";
import FileAttachmentSvg from "../svg/support/FileAttachmentSvg.jsx";
import { formatBytes } from "../../helper/ConvertBytes.js";
import UploadingIconSvg from "../svg/tickets/UploadingIconSvg.jsx";
import { styled } from "@mui/material/styles";
import TextMarquee from "../common/TextMarquee.jsx";

const CustomLinearProgress = styled(LinearProgress)(({}) => ({
  height: 4,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#828282",
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 1,
      backgroundColor: "#F2F2F2",
    },
  },
}));

function UploadingItem({ percentage, controller, item, index }) {
  const cancelUploadHandler = () => {
    controller.abort();
  };
  return (
    <div
      dir="rtl"
      className="w-full bg-gray_1 flex justify-between items-center flex-row rounded-[10px]  p-2 "
    >
      <div className="flex flex-col w-full">
        {/* <div className="flex justify-between"> */}
        {/* <div
                            className='h-[30px]  min-[1440px]:h-[2.0833vw]'
                            onClick={()=>{
                            cancelUploadHandler()
                        }} >
                            <CancelSvg/>
                        </div> */}
        <div className="flex items-center justify-start ">
          <p
            dir="ltr"
            className=" line-clamp-1 text-gray_4 text-left text-[10px] font-dana font-medium w-full"
          >
            <TextMarquee direction={"ltr"}>{item?.name}</TextMarquee>
          </p>
        </div>
        {/* </div> */}
        <div className="flex items-center w-full gap-3  ">
          <div
            dir="ltr"
            className="flex font-dana font-medium text-[10px] min-w-[130px]"
          >
            <span className="text-primary w-max">
              {formatBytes((percentage * item?.size) / 100)}
            </span>
            <span className="text-gray_4 w-max px-1">
              / {formatBytes(item?.size, 2)}
            </span>
          </div>
          <div dir="ltr" className="w-full">
            <CustomLinearProgress
              color="primary"
              variant="determinate"
              value={percentage}
            />
          </div>

        </div>
      </div>
      <div className="h-[38px] w-[38px] mx-2">
        <UploadingIconSvg />
      </div>
      <p className="text-sm text-gray_5 w-4">{index + 1}</p>
    </div>
  );
}

export default UploadingItem;
