import React, { useEffect, useMemo, useState } from 'react';
import PlayListCard from '../../components/playlist/PlayListCard';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { getCollectionHome } from '../../services/ApiClient.js';
import { useQuery } from '@tanstack/react-query';
import {
  foreignCategory,
  persianCategory,
} from '../../consts/PlayListMainCategoryConst.jsx';
import RenderPlayListData from '../../components/playlist/RenderPlayListData.jsx';
import HomeCollectionWithCorner from '../../components/home/HomeCollectionWithCorner.jsx';
import { convertPlayListObject } from '../../helper/ConvertPlayListObject.js';
import PlaylistShimmer from './PlaylistShimmer.jsx';
import PlayListImageContainer from './PlayListImageContainer.jsx';
import Container from '../../components/PageParentContainer/Container';
import AbsoluteContainerBG from '../../components/PageParentContainer/AbsoluteContainerBG';
import CommonHeader from '../../components/common/CommonHeader';
import PlaylistCardsShimmer from '../../components/playlist/PlaylistCardsShimmer.jsx';
import { useScrollPosition } from '../../hooks/useScrollPosition.js';

function PlayList() {
  const [Error, setError] = useState(false);
  const getCollectionHomeQuery = useQuery(
    ['getCollectionHomeQuery'],
    () => getCollectionHome(),
    {
      onSuccess: response => {},
      onError: Error => {
        setError(true);
      },
    },
  );
  useScrollPosition({pageId:'page-header'})
  const memoizedCollectionSections = useMemo(
    () =>
      getCollectionHomeQuery?.data?.data?.result?.collection_sections?.map(
        (item, index) => (
          <RenderPlayListData
            key={`renderPlaylistData${index}${item?.id}`}
            item={item}
          />
        ),
      ),
    [getCollectionHomeQuery?.data?.data?.result?.collection_sections],
  );
  const memoizedSpecialPlaylist = useMemo(() => {
    const specialPlaylist = getCollectionHomeQuery?.data?.data?.result?.special;
    return specialPlaylist ? (
      <div className="py-6">
        <HomeCollectionWithCorner
          cardStyle="!w-[120px] !h-[120px]"
          artistCollection={true}
          fromMyMelodify={true}
          key={'homeCollectionWithCorner-playlist'}
          item={convertPlayListObject(specialPlaylist, true)}
        />
      </div>
    ) : null;
  }, [getCollectionHomeQuery?.data?.data?.result?.special]);
  return (
    <Container
      header={
        <CommonHeader hasChevronNavigateBack={false} title={'پلی لیست ها'} />
      }
    >
      <AbsoluteContainerBG />
      <div className="flex flex-col pb-r48 z-20 pb-4">
        <div dir="rtl">
          <div className="mb-3 mt-2 pr-4 ">
            <p className="text-[15px] font-dana font-light text-gray_5">
              موسیقی جهان
            </p>
          </div>
          {getCollectionHomeQuery?.data?.data?.result?.foreign ? (
            <div className="w-full   justify-start px-4 gap-1 flex flex-wrap xl:min-h-[12vw]">
              {getCollectionHomeQuery?.data?.data?.result?.foreign.map(
                (item, index) => (
                  <PlayListCard
                    itemData={item}
                    key={'playlistCardForeign' + item?.id}
                  >
                    <PlayListImageContainer
                      title={item?.title}
                      image={item?.image}
                    />
                  </PlayListCard>
                ),
              )}
            </div>
          ) : (
            <PlaylistCardsShimmer />
          )}

          <div className="mb-3 mt-6 pr-4 ">
            <p className="text-[15px] font-dana font-light text-gray_5">
              موسیقی ایران
            </p>
          </div>
          {getCollectionHomeQuery?.data?.data?.result?.persian ? (
            <div className="w-full   justify-start px-4 gap-1 flex flex-wrap xl:min-h-[12vw]">
              {getCollectionHomeQuery?.data?.data?.result?.persian.map(
                (item, index) => (
                  <PlayListCard
                    key={'playlistCardPersian' + item?.id}
                    itemData={item}
                  >
                    <PlayListImageContainer
                      title={item?.title}
                      image={item.image}
                    />
                  </PlayListCard>
                ),
              )}
            </div>
          ) : (
            <PlaylistCardsShimmer />
          )}
        </div>
        {!getCollectionHomeQuery?.data?.data?.result && !Error && (
          <PlaylistShimmer />
        )}
        {memoizedSpecialPlaylist}
        <div className="flex flex-col gap-6">
          {memoizedCollectionSections}
        </div>
      </div>
    </Container>
  );
}

export default PlayList;
