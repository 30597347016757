import React from 'react';
import DiscountSuggestion from './DiscountSuggestion.jsx';
import ChevronMainSvg from '../svg/tickets/ChevronMainSvg.jsx';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

function SpecialOffer({ plan, onHandleSpecialOffer }) {
  const handleSpecialOffer = () => {
    onHandleSpecialOffer();
  };
  return (
    <div
      onClick={handleSpecialOffer}
      className="flex flex-col items-start justify-center w-full rounded-[20px] bg-secondary p-4 "
    >
      <div className="flex gap-4 w-full">
        <div className="w-[36px] h-[36px] relative">
          <CustomLazyLoadImage image={plan?.suggestion_image} />
        </div>
        <div className="flex-col items-start justify-center w-full">
          <div className="flex items-center gap-4 lg-1440:gap-[16px] mb-1 ">
            <p className="text-[16px] font-kalameh font-medium text-white leading-6">
              {plan?.suggestion_title}
            </p>
          </div>
          <div className="text-[12px] font-dana font-[400] text-gray_5 leading-6 ">
            {plan?.suggestion_description}
          </div>
          <div className="flex items-center   gap-2 ">
            <p className="text-[16px]  leading-6 text-primary font-kalameh font-medium">
              {plan?.suggestion_view_description}
            </p>
            <div className="w-[8px] h-[12px]">
              <ChevronMainSvg />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecialOffer;
