import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getInactiveSessionData } from '../../helper/ReduxSelectorContext';
import { useAction } from '../../hooks/useAction';
import { selectInactiveSession } from '../../redux/autherror/AuthErrorSlice';
import {
  dispatchIsPlaying,
  dispatchSwipePlayerStatus,
} from '../../redux/globalstore/GlobalStore';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import InactiveSessionImage from '../../assets/inactive-se/inactive-se.webp';
import MelodifyMasked from '../svg/common/melodifyMasked.jsx';
import { resetPlayer } from '../../redux/player/PlayMusicSlice';
import { closeDownloadDialog } from '../../redux/download/downloadSlice.js';
import { hideRedirectToTelegramDialog } from '../../redux/globalData/globalDataSlice.js';
function InactiveSession() {
  let inactiveSession = getInactiveSessionData();
  let action = useAction();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleActiveDevice = actionData => {
    action(actionData);
  };
  const goToActiveDevice = actionData => {
    action(actionData);
  };
  useEffect(() => {
    dispatchIsPlaying(false);
    dispatchSwipePlayerStatus(false);
    clearPlayer();
    dispatch(hideRedirectToTelegramDialog())
    dispatch(closeDownloadDialog());
  }, []);

  const clearPlayer = () => {
    dispatch(resetPlayer());
  };
  return (
    <div
      className={`bg-black hide-scroll-bar fixed scroll top-0 left-0 right-0 bottom-0 ${
        location?.pathname === '/setting/manage-devices' ? 'z-[0]' : 'z-[99999]'
      }  overflow-scroll flex flex-col justify-center items-center w-full h-full mx-auto max-w-[520px] `}
    >
      <div className="w-full h-full px-4 pt-9 mx-auto flex flex-col justify-start  overflow-scroll hide-scroll-bar">
        <div className="w-full flex flex-col bg-secondary rounded-[20px] py-10 min-[375px]:py-14 px-6 flex justify-center items-center">
          <div
            className="min-w-[250px] relative"
            style={{ aspectRatio: 340 / 250 }}
          >
            <CustomLazyLoadImage
              image={InactiveSessionImage}
              appendBaseUrl={false}
            />
          </div>
          <div
            dir="rtl"
            className="font-dana mt-8 mx-auto text-center text-white text-sm min-[375px]:text-lg"
            dangerouslySetInnerHTML={{ __html: inactiveSession?.text }}
          ></div>
        </div>

        {/* <span
          dir="rtl"
          class="mt-4 lg-1440:mt-r16 w-[80%] mx-auto text-center text-gray-6 fa-format-400 text-[18px] lg-1440:text-f18"
        >
          برای استفاده از ملودیفای در این دستگاه روی دکمه زیر بزنید.
        </span> */}
        <button
          onClick={() => handleActiveDevice(inactiveSession?.btn_1_action)}
          className="w-full bg-primary mt-6 font-semibold font-dana select-none outline-none flex flex-row justify-center items-center text-black text-base bg-yellow rounded-[100px] text-[16px] min-h-[56px]"
        >
          {inactiveSession?.btn_1_text}
        </button>

        <div
          className="w-full flex flex-row font-danaBold justify-center items-center gap-3 mt-5 overflow-visible"
          onClick={() => goToActiveDevice(inactiveSession?.btn_2_action)}
        >
          <span className="text-primary text-base text-[16px]">
            {inactiveSession?.btn_2_text}
          </span>
          <div className="aspect-square h-[25px]">
            <svg
              className="w-full h-full"
              viewBox="0 0 25 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.429 0.308594C14.3888 0.308594 15.2579 0.841671 15.7378 1.62859C15.9713 2.00936 16.1269 2.47898 16.088 2.97398C16.0621 3.35475 16.1788 3.73552 16.3863 4.0909C17.0479 5.16975 18.5136 5.5759 19.655 4.96667C20.9392 4.23052 22.5605 4.67475 23.2999 5.93129L24.1689 7.42898C24.9213 8.68552 24.5062 10.2974 23.2091 11.0209C22.1066 11.6682 21.7174 13.1024 22.3789 14.194C22.5865 14.5367 22.82 14.8286 23.1831 15.0063C23.6371 15.2474 23.9873 15.6282 24.2338 16.009C24.7137 16.7959 24.6748 17.7605 24.2079 18.6109L23.2999 20.134C22.82 20.9463 21.925 21.454 21.004 21.454C20.55 21.454 20.0442 21.3271 19.6291 21.0732C19.2918 20.8574 18.9027 20.7813 18.4876 20.7813C17.2035 20.7813 16.1269 21.8347 16.088 23.0913C16.088 24.5509 14.8947 25.6932 13.403 25.6932H11.639C10.1343 25.6932 8.94099 24.5509 8.94099 23.0913C8.91505 21.8347 7.83846 20.7813 6.55433 20.7813C6.12628 20.7813 5.73715 20.8574 5.41288 21.0732C4.99781 21.3271 4.47897 21.454 4.03795 21.454C3.10404 21.454 2.20904 20.9463 1.72912 20.134L0.834117 18.6109C0.35419 17.7859 0.328248 16.7959 0.808175 16.009C1.01571 15.6282 1.40484 15.2474 1.84585 15.0063C2.20904 14.8286 2.44252 14.5367 2.66303 14.194C3.31158 13.1024 2.92245 11.6682 1.81991 11.0209C0.535784 10.2974 0.120712 8.68552 0.860059 7.42898L1.72912 5.93129C2.48143 4.67475 4.08984 4.23052 5.38694 4.96667C6.51541 5.5759 7.98114 5.16975 8.64266 4.0909C8.85019 3.73552 8.96693 3.35475 8.94099 2.97398C8.91505 2.47898 9.05773 2.00936 9.30418 1.62859C9.78411 0.841671 10.6532 0.333978 11.6 0.308594H13.429ZM12.534 9.42167C10.4975 9.42167 8.85019 11.0209 8.85019 13.0136C8.85019 15.0063 10.4975 16.5928 12.534 16.5928C14.5704 16.5928 16.1788 15.0063 16.1788 13.0136C16.1788 11.0209 14.5704 9.42167 12.534 9.42167Z"
                fill="#F2C94C"
              />
            </svg>
          </div>
        </div>
        <div className="w-full flex flex-row h-full justify-center items-center">
          <MelodifyMasked />
        </div>
      </div>
    </div>
  );
}

export default InactiveSession;
