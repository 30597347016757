import React from "react";
import HomePlayLists from "./HomePlayLists";

import HomeMetaDataWithTitle from "../common/HomeMetaDataWithTitle";

function HomePlayList({ item }) {
  return (
    <>
      <div className="w-full   px-1 lg-1440:pr-r24   z-10 mt-4 pr-6 relative ">
        <HomeMetaDataWithTitle
          metaData={item?.collection_category_meta_data}
          eventClassName={{
            prev: "prev-button-collocation",
            next: "next-button-collocation",
          }}
        />
      </div>
      <div className="flex flex-col w-full mt-3 mb-4  gap-[5px] pr-[10px]">
        {item?.collection_category_items?.map(
          (collectionCategoryItems, index) => (
            <HomePlayLists
            key={index+"HomePlayLists"}
              metadata={item?.collection_category_meta_data}
              data={collectionCategoryItems}
              index={index}
            />
          )
        )}
      </div>
    </>
  );
}

export default HomePlayList;
