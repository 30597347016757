import React, { useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { appendBaseUrl } from '../../helper/utils';
function CustomLazyLoadImage({
  image,
  imageClass = '',
  secondClass = '',
  appendBaseUrl: isAppendBaseUrl = true,
  onLoaded = state => {},
  hasEffect,
  loadStrategy = 'lazy',
}) {
  const [loadingError, setLoadingError] = useState(false);
  const afterLoadHandler = () => {
    onLoaded(true);
  };
  const handleOnError = event => {
    event.target.style.display = 'none';
    setLoadingError(true);
  };
  const imageUrl = useMemo(
    () => (isAppendBaseUrl ? appendBaseUrl(image) : image),
    [image, isAppendBaseUrl],
  );

  return (
    <LazyLoadImage
      effect={hasEffect ? 'opacity' : ''}
      className={`w-full h-full absolute top-0 left-0  ${secondClass}  ${imageClass} ${
        loadingError && 'hidden'
      }`}
      alt={''}
      src={imageUrl}
      loading={loadStrategy}
      afterLoad={() => afterLoadHandler()}
      onError={event => handleOnError(event)}
    />
  );
}

export default React.memo(CustomLazyLoadImage);
