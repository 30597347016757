import { useCallback, useRef, useState } from "react";

const useLongPress = (
    enabled = false,
    onLongPress,
    onClick,
    { shouldPreventDefault = true, delay = 300 } = {}
) => {
    if(!enabled) {
        return
    }
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const timeout = useRef();
    const target = useRef();

    const start = useCallback(
        event => {
          const touch = event.touches ? event.touches[0] : event;
          const startX = touch.clientX;
          const startY = touch.clientY;

          const onTouchMove = moveEvent => {
            const moveTouch = moveEvent.touches ? moveEvent.touches[0] : moveEvent;
            const deltaX = Math.abs(moveTouch.clientX - startX);
            const deltaY = Math.abs(moveTouch.clientY - startY);

            // Adjust the threshold based on your requirements
            const movementThreshold = 5;

            if (deltaX > movementThreshold || deltaY > movementThreshold) {
              // Movement exceeds the threshold, cancel long press
              clear(event, false);
            }
          };
          document.addEventListener('touchmove', onTouchMove, { passive: false });
            timeout.current = setTimeout(() => {
              document.removeEventListener('touchmove', onTouchMove);
                onLongPress(event);
                setLongPressTriggered(true);
            }, delay);
        },
        [onLongPress, delay, shouldPreventDefault]
    );

    const clear = useCallback(
        (event, shouldTriggerClick = true) => {
            timeout.current && clearTimeout(timeout.current);
            setLongPressTriggered(false);
            if (shouldPreventDefault && target.current) {
                target.current.removeEventListener("touchend", preventDefault);
            }
        },
        [shouldPreventDefault, longPressTriggered]
    );

    return {
        onMouseDown: e => start(e),
        onTouchStart: e => start(e),
        onMouseUp: e => clear(e),
        onMouseLeave: e => clear(e, false),
        onTouchEnd: e => {
          if(!isTouchEvent(e)){
            e.preventDefault();
          }
          clear(e)
          },
        onClick: e => onClick(e)
    };
};

const isTouchEvent = event => {
    return "touches" in event;
};

const preventDefault = event => {
    if (!isTouchEvent(event)) return;

    if (event.touches.length < 2 && event.preventDefault) {
        event.preventDefault();
    }
};

export default useLongPress;
