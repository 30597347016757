import { useLocation } from 'react-router-dom';
import sha256 from 'crypto-js/sha256.js';

export const useHashedPath = () => {
  const { pathname, search } = useLocation();
  const decodedSearch = decodeURIComponent(search);
  const scrollKey = pathname + (decodedSearch??'');
  const hashedScrollKey = sha256(scrollKey)?.toString();
  return { hashedScrollKey };
};
