import React from "react";
import SendAppSvg from "../svg/setting/SendAppSvg";
import { playStoreUrl } from "../../consts/GlobalConsts";
import { selectInitialData } from "../../redux/initialize/InitializeSlice";
import { useSelector } from "react-redux";
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader";
import { selectUserData } from "../../redux/login/LoginSlice";
import { CardActionArea } from "@mui/material";

function SendApp({ openBottomSheetSetter }) {
  // hook
  const initialData = useSelector(selectInitialData);
  const user = useSelector(selectUserData);

  const shareLink = () => {
    window.navigator
      .share({
        title: "اشتراک",
        text:
          initialData.setting.slogan +
          "\n\nدریافت از پلی استور\n\n" +
          playStoreUrl +
          "\n\nدریافت به صورت مستقیم\n\n" +
          initialData.setting.direct_link +
          "\n\nکد معرف : " +
          user?.ref_number,
      })
      .then(() => console.log("Yay, you shared it :)"))
      .catch((error) => console.log("Oh noh! You couldn"));
  };
  return (
    <div className="flex flex-col gap-2 bg-primary_dark py-4">
      <div className="!px-4">

      <BottomSheetHeader
        closeBottomSheet={() => openBottomSheetSetter(false)}
        HeaderIcon={<SendAppSvg/>}
        title="ارسال اپلیکیشن"
        dividerLine
      />
      </div>
      <CardActionArea dir="rtl" onClick={shareLink} className="!p-4 ">
        <p className="text-primary text-sm font-medium font-dana mx-4">
          ارسال لینک دانلود
        </p>
      </CardActionArea>
    </div>
  );
}

export default SendApp;
