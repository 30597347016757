export const ACTIONS = {
    TICKETS_TYPE: "tickets",
    USER_PROFILE_TYPE: "user_profile",
    NEW_TICKET_TYPE: "new_ticket",
    PLANS_TYPE: "plans",
    PLAN_TYPE: "plan",
    LINK_TYPE: "link",
    RESTART: "restart",
    SHARE_TYPE: "share",
    CLEAR_CACHE: "clear_cache",
    ARTIST_TYPE: "artist",
    ARTISTS_TYPE: "artists",
    DEVICE_MANAGER: "device_manager",
    LOGOUT: "logout",
    SIMPLE_PLAY_LIST_VIEW_TYPE: "simple_playlist",
    PLAYER_VIEW_TYPE: "player",
    TRACK_LIST_VIEW_TYPE: "tracklist",
    PLAY_LIST_VIEW_TYPE: "playlist",
    ARTIST_GROUP_TYPE: "artist_group",
    Exit_TYPE: "exit",
    TRACK_TYPE: "track",
    COLLECTION_TYPE: "collection",
    COLLECTION_CATEGORY_TYPE: "collection_category",
    TICKET_TYPE: "ticket",
    MY_MELODIFY_PLAYLISTS_TYPE: "my_melodify_playlists",
    POPULAR_TRACKS_TYPE: "popular_tracks",
    POPULAR_ARTISTS_TYPE: "popular_artists",
    NEWEST_TRACKS_TYPE: "newest_tracks",
    USER_PLAYLISTS_TYPE: "user_playlists",
    HOME_COLLECTION_TYPE: "home_collection",
    COLLECTION_CATEGORY_BY_ID_TYPE: "collection_category_by_id",
    COLLECTION_CATEGORY: "collection_category",
    OPEN_CONTEXT_MENU: "open_context_menu",
    BANNER_VIEW_TYPE: "custom_banner",
    OPEN_USER_PLAYLIST: "open_user_playlist",
    SHOW_ALL_SEARCH_RESULTS: "show_all_search_results",
    OPEN_ALBUM: "open_album",
    OPEN_ALBUMS_LIST: "open_albums_list",
    PLAYER_SLEEP_TIMER: "player_sleep_timer",
    EXCLUSIVE_TRACKS:'exclusive_tracks',
    GENERAL_COLLECTIONS: 'general_collections',
    GENERAL_COLLECTION_VIEW_TYPE:'general_collections',
    NEW_TRACK_TYPE: "newTrack",
    COLLECTION_UPDATED_TYPE: "collectionUpdated",
    GENERAL_TYPE: "general",
    // GENERAL_LINK_TYPE: "general_link",
    // EXCLUSIVE_TRACKS_TYPE: "exclusive_tracks",
}



















