import React from 'react';

import place from './ArtistPlaceHolderSvg.svg';
import CustomImage from '../../common/CustomImage';

function ArtistPlaceHolderSvg() {
  return (
    <div>
      <CustomImage
        appendBaseUrl={false}
        src={place}
        className="w-full h-full"
      />
    </div>
  );
}

export default React.memo(ArtistPlaceHolderSvg);
