import React from 'react'

function FileIcon() {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
      >
        <path
            fill="#F2F2F2"
            d="M7 2h8l5 4v13a3 3 0 01-3 3H7a3 3 0 01-3-3V5a3 3 0 013-3z"
        ></path>
      </svg>
  )
}

export default FileIcon