import { Base64 } from "js-base64";

export const EncodeObject=(object)=>{
    const jsonString = JSON.stringify(object);
    const base64EncodedString = Base64.encode(jsonString);
    
    return base64EncodedString
}

export const DecodeObject=(encodeObject)=>{
    const decodedString = Base64.decode(encodeObject);
    const decodedObject = JSON.parse(decodedString);
    return decodedObject
}