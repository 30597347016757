import { memo } from "react";

const DownloadSvg = (props) => (
  /**
   * This function returns an SVG element that displays a chevron pointing to the left.
   *
   * @param className (string): The class name of the SVG element.
   * @param rest (object): Any additional props to be passed to the SVG element.
   * @returns An SVG element that displays a chevron pointing to the left.
   */
  <svg
    width={500}
    height={500}
    viewBox="0 0 500 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.7} fill="#F2F2F2">
      <path d="M104.722 73.303c0 1.772-.388 3.473-1.059 4.999a12.41 12.41 0 0 1-11.333 7.33 12.41 12.41 0 0 1-11.333-7.33 12.147 12.147 0 0 1-1.06-4.999c0-2.63.83-5.068 2.242-7.067l6.461 6.418a4.795 4.795 0 0 0 6.761 0l6.832-6.787a12.288 12.288 0 0 1 2.489 7.436Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.909 44.33a43.636 43.636 0 0 0-4.254-3.998s-.018 0-.018-.018c-.3-.228-.582-.474-.882-.702 0 0-.018-.017-.036-.017a10.925 10.925 0 0 0-6.319-2.017c-5.773 0-10.504 4.455-10.892 10.12v.42h8.314a4.302 4.302 0 0 1 2.436.807c.23.158.424.351.618.544a4.2 4.2 0 0 1 .018 5.753l-3.654 3.7-2.878 2.859a17.887 17.887 0 0 1 4.184 11.523c0 1.736-.247 3.42-.706 4.998-2.189 7.56-9.197 13.084-17.494 13.084S77.023 85.86 74.852 78.302a17.91 17.91 0 0 1-.706-4.998c0-4.227 1.448-8.103 3.901-11.19l-3.071-3.016-3.531-3.508.018-.018a4.227 4.227 0 0 1-1.448-3.192 4.26 4.26 0 0 1 1.324-3.086c.23-.228.494-.421.777-.58 0 0 .017 0 .017-.017a4.098 4.098 0 0 1 1.766-.56c.123-.018.265-.018.388-.018.124 0 .247 0 .37.017h8.492v-.21c-.335-5.718-5.12-10.243-10.963-10.243a11.08 11.08 0 0 0-6.69 2.245c-.176.14-.353.28-.53.439a41.703 41.703 0 0 0-4.519 4.297c-6.902 7.576-11.103 17.626-11.103 28.64 0 23.589 19.241 42.705 42.984 42.705 23.743 0 42.985-19.116 42.985-42.705 0-11.172-4.325-21.362-11.404-28.974ZM72.751 99.611c.424.28.954.456 1.518.456a2.768 2.768 0 0 0 2.754-2.718c0-.842-.388-1.579-.97-2.087l.017.017a.982.982 0 0 0-.141-.105h-.018c-.035-.035-.088-.053-.123-.088-5.35-4.016-9.162-9.944-10.433-16.749-.053-.245-.088-.473-.124-.701-.017-.106-.035-.193-.053-.299v-.017a2.292 2.292 0 0 0-.282-.754c-.035-.07-.088-.123-.124-.193-.053-.053-.088-.123-.141-.175a1.58 1.58 0 0 0-.159-.176l-.088-.088a2.816 2.816 0 0 0-1.907-.754c-.194 0-.353.018-.53.053-.282.052-.546.14-.794.28a2.713 2.713 0 0 0-1.412 2.386c0 .07.018.157.018.228 0 .122.035.228.053.35 1.377 8.56 6.072 16.013 12.763 21.011.07.035.123.088.176.123Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.172 91.381v24.625C68.5 115.922 49.344 96.837 49.344 73.3c0-11.014 4.201-21.063 11.103-28.64a41.712 41.712 0 0 1 4.52-4.297c.176-.158.353-.298.53-.438a11.078 11.078 0 0 1 6.69-2.245c5.843 0 10.627 4.525 10.962 10.242v.21h-8.491c-.124-.017-.247-.017-.37-.017-.124 0-.266 0-.39.017a4.097 4.097 0 0 0-1.765.562c0 .017-.017.017-.017.017a3.554 3.554 0 0 0-.777.58 4.262 4.262 0 0 0-1.324 3.086c0 1.28.565 2.42 1.448 3.192l-.018.017 3.53 3.508 3.072 3.017A17.886 17.886 0 0 0 74.146 73.3c0 1.736.247 3.42.706 4.998 2.156 7.507 9.099 13.007 17.32 13.083Zm-17.903 8.683a2.764 2.764 0 0 1-1.518-.456.932.932 0 0 1-.058-.043.699.699 0 0 0-.118-.08c-6.69-4.999-11.386-12.453-12.763-21.011-.006-.044-.015-.085-.023-.125a1.1 1.1 0 0 1-.03-.226c0-.035-.005-.075-.009-.114-.004-.04-.009-.079-.009-.114 0-1.035.565-1.912 1.413-2.385.247-.14.511-.228.794-.28.176-.036.335-.053.53-.053.74 0 1.412.298 1.906.754l.088.088c.053.052.106.105.159.175a.65.65 0 0 1 .07.087c.023.031.045.062.071.088a.72.72 0 0 0 .062.097 2.293 2.293 0 0 1 .344.85v.018l.027.149.026.149c.036.228.07.456.124.701 1.27 6.805 5.084 12.733 10.433 16.75.017.017.04.03.062.043a.312.312 0 0 1 .061.044h.018c.053.035.106.07.141.105l-.018-.017c.583.509.971 1.245.971 2.087a2.768 2.768 0 0 1-2.754 2.719Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.163 72.538H49.352c.189-10.715 4.354-20.476 11.097-27.878a41.712 41.712 0 0 1 4.519-4.297c.176-.158.353-.298.53-.438a11.079 11.079 0 0 1 6.69-2.245c5.843 0 10.627 4.525 10.962 10.242v.21h-8.491c-.124-.017-.247-.017-.37-.017-.124 0-.266 0-.389.017a4.097 4.097 0 0 0-1.765.562c0 .017-.018.017-.018.017a3.578 3.578 0 0 0-.777.58 4.262 4.262 0 0 0-1.324 3.086c0 1.28.565 2.42 1.448 3.192l-.018.017 3.53 3.508 3.072 3.017a17.875 17.875 0 0 0-3.885 10.427Z"
      />
    </g>
  </svg>
);
export default memo(DownloadSvg);
