import React, {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import invoiceIMG from '../../assets/dialogs/invoice.png';
import DialogHeader from '../dialogs/DialogHeader.jsx';
import ClickReaction from '../../helper/ClickReaction';
import { CardActionArea } from '@mui/material';
import CustomImage from '../common/CustomImage';

function OpenPaymentPageDialog({}, ref) {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const closeModal = () => setIsOpen(false);

  useImperativeHandle(ref, () => ({
    openModal(url) {
      setUrl(url);
      setIsOpen(true);
    },
  }));
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[91]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-black bg-opacity-50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className="w-[80vw] max-w-[400px] transform overflow-hidden rounded-[20px]  bg-[#303030] py-6 text-left align-middle shadow-xl transition-all px-4 "
              >
                <DialogHeader
                  header_text={'فاکتور'}
                  onCloseDialog={closeModal}
                />
                <div className="flex flex-col gap-4 justify-center items-center">
                  {/* <img src={`${}`} /> */}
                  <CustomImage
                    appendBaseUrl={false}
                    src={invoiceIMG}
                    alt={''}
                  />
                  <p className="text-gray_6 font-dana text-[16px] font-[400] text-center  ">
                    فاکتور شما ایجاد شد.
                    <br />
                    برای پرداخت روی دکمه زیر بزنید.
                  </p>
                  <ClickReaction>
                    <CardActionArea className="!rounded-[23px]">
                      <div className=" flex items-center justify-center  ">
                        <a
                          target="_blank"
                          type="button"
                          href={url}
                          className="inline-flex text-title_box text-[16px] font-dana font-[500] bg-primary justify-center rounded-[23px] border border-transparent  py-2  px-5  focus:outline-none"
                        >
                          ورود به صفحه پرداخت
                        </a>
                      </div>
                    </CardActionArea>
                  </ClickReaction>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default forwardRef(OpenPaymentPageDialog);
