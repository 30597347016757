import React from "react";

function CssShimmer({
  shimColor = "rgba(255,255,255,0.05)",
  shimStart = "-150%",
  shimEnd = "150%",
  classNames = "",
  reverse = false,
  delay = "0s",
  duration = "0.8s",
  degree = 275,
  count = 1,
  shimWidth = "175%",
}) {
  return (
    <div className={`${classNames} overflow-hidden`}>
      {[...Array(count).keys()].map((item) => (
        <div className={`btnSK w-full h-full`} key={item} style={{}}>
          <div
            className={`skLight w-full h-full`}
            style={{
              "--shim-start": reverse ? shimEnd : shimStart,
              "--shim-end": reverse ? shimStart : shimEnd,
              width: shimWidth,
              animationDelay: delay,
              animationDuration: duration,
              background:
                `-moz-linear-gradient(` +
                degree +
                `deg, rgba(2,0,36,0) 5%, ${shimColor}, rgba(255,255,255,0) 95%)`,
              background:
                `-webkit-linear-gradient(` +
                degree +
                `deg, rgba(2,0,36,0) 5%, ${shimColor}, rgba(255,255,255,0) 95%)`,
              background:
                `linear-gradient(` +
                degree +
                `deg, rgba(2,0,36,0) 5%, ${shimColor}, rgba(255,255,255,0) 95%)`,
            }}
          ></div>
        </div>
      ))}
    </div>
  );
}

export default CssShimmer;
