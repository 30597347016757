    import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { updateFirebaseToken } from "../services/ApiClient";
const firebaseConfigDev = {
    apiKey: "AIzaSyD5-HfOOTft0-KZCYg35KoyQNKD4Ti2Av4",
    authDomain: "melodify-58d0a.firebaseapp.com",
    projectId: "melodify-58d0a",
    storageBucket: "melodify-58d0a.appspot.com",
    messagingSenderId: "553019406678",
    appId: "1:553019406678:web:3cc22edf70c964cd613ef7",
    measurementId: "G-Z3DJBRXTVJ",
};
const firebaseConfig = {
    apiKey: "AIzaSyDQY65QQdxgSi2-GV250lei7yV9Br-I75g",
    authDomain: "melodify-a339f.firebaseapp.com",
    projectId: "melodify-a339f",
    storageBucket: "melodify-a339f.appspot.com",
    messagingSenderId: "873595747413",
    appId: "1:873595747413:web:061575003d03b2a26028dc",
    measurementId: "G-6192V31Q4K"
};

export const FireBaseConf = async () => {
    const app = initializeApp(firebaseConfigDev);
    const messaging = getMessaging(app);
        // getToken(messaging, { vapidKey: 'BEN_PzGRVyGB8QGzBsCUIuiBLLd5nP29EV3Gog1rYsyfeKsop9cQkoz3R8mnaK1hAS7o9-aGPGTO12J9V0FHNYc' }).then((currentToken) => {
    const registration = await navigator.serviceWorker.ready;
    // getToken(messaging, {
    //     serviceWorkerRegistration: registration,
    //     vapidKey:
    //         // "BL7GpA87lwG9h0Oa1MNEO5sS24ubNFJQXG9MvifbxpuE61y_12sdbFIe7yVHJhWDgSv1y-ZdShv_sfAMWptS668",
    //         "BCd9kh0VxlS4-D4Ik7qi7GvKhmgDTHlUQkIyEnobE2R_BtflywBjddePAfN-F8RN1W56gLL6cRdpYrT7kZ-TZUE",
    //     // serviceWorkerRegistration:registration
    // })
    //     .then((currentToken) => {
    //         if (currentToken) {
    //             updateFirebaseToken({ firebase_token: currentToken });
    //             console.log("fireBase init Success...");
    //         } else {
    //             // Show permission request UI    
    //             console.log(
    //                 "No registration token available. Request permission to generate one."
    //             );
    //             // ...
    //         }
    //     })
    //     .catch((err) => {
    //         console.log("An error occurred while retrieving token. ", err);
    //         // ...
    //     });
               
}