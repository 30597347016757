import NoMusicSvg from "../components/svg/myMelodify/NoMusicSvg";
import UserLikedSvg from "../components/svg/myMelodify/UserLikedSvg";

export const MyMelodifyItemConstant = {
  "followed-artists-tracks": {
    api: "getPlaylistTracksByType",
    type: "followed_artists_tracks",
    title: "ترک های خوانندگان دنبال شده",
    noneDataImg: <NoMusicSvg />,
    noneDataText: " شما هنوز خواننده ای را دنبال نکرده اید ",
    showSearchWhenNull: false,
    fixHeader: false,
    showIndexOfCard: true,
  },
  "recently-listened": {
    type: "Local",
    api: "",
    title: "اخیرا شنیده های من",
    noneDataImg: <NoMusicSvg />,
    noneDataText: "شما هنوز آهنگی گوش نداده اید",
    showSearchWhenNull: true,
    fixHeader: false,
    showIndexOfCard: true,
  },
  "most-listened": {
    api: "getUserMostListenedTracks",
    title: "بیشتر شنیده های من",
    noneDataImg: <NoMusicSvg />,
    noneDataText:
      "  با ملودیفای ترکهای بیشتری گوش دهید این پلی‌لیست به زودی برایتان ساخته می‌شود",
    showSearchWhenNull: false,
    fixHeader: false,
    showIndexOfCard: true,
  },
  "user-liked": {
    api: "getUserLikedTracks",
    title: "آهنگ های لایک شده",
    noneDataImg: <UserLikedSvg />,
    noneDataText: "هنوز ترک موردعلاقه ای وجود ندارد !",
    showSearchWhenNull: false,
    fixHeader: true,
    showIndexOfCard: false,
  },
};
