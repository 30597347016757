import {useEffect, useState} from "react";
import {addToPlaylistData} from "../helper/ReduxSelectorContext.js";
import {useDispatch} from "react-redux";
import {
    addItemToPlayerSelectedListItem,
    addItemToSelectedListItem, disableListSelectMode, disablePlayerListSelectMode,
    removeItemOfPlayerSelectedList,
    removeItemOfSelectedList
} from "../redux/adToPlaylist/AddToPlaylistSlice.js";


export const useSelectTrackItem = (inPlayer, track) => {
    const dispatch = useDispatch()
    const [isSelected, setIsSelected] = useState(false);
    const addToPlaylistDataState = addToPlaylistData()

    const checkItemIsSelected = () => {
        const listForCheck = inPlayer ? addToPlaylistDataState?.playerListSelectedItems : addToPlaylistDataState?.listSelectItems
        const isSelect = listForCheck?.find((item) => Number(item?.id) === Number(track?.id));
        setIsSelected(isSelect)
    }


    useEffect(() => {
        checkItemIsSelected()
    }, [addToPlaylistDataState]);
    const deSelectItem = (track) => {
        if (inPlayer) {
            dispatch(removeItemOfPlayerSelectedList({selectedItem: track}))
        } else {
            dispatch(removeItemOfSelectedList({selectedItem: track}))
        }

    }
    const selectItem = (track) => {
        console.log(track, inPlayer)
        if (inPlayer) {
            if(addToPlaylistDataState?.listSelectItems?.length>0){
               dispatch(disableListSelectMode())
            }
            dispatch(addItemToPlayerSelectedListItem({selectedItem: track}))
        } else {
            if(addToPlaylistDataState?.playerListSelectedItems?.length>0){
               dispatch(disablePlayerListSelectMode())
            }
            dispatch(addItemToSelectedListItem({selectedItem: track}))
        }
    }


    return {isSelected, selectItem, deSelectItem}
}
