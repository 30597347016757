import {createSlice} from "@reduxjs/toolkit";


export const AddToPlaylistSlice = createSlice({
    name: "AddToPlaylist",
    initialState: {
        listSelectMode: false,
        playerListSelectMode: false,
        listSelectItems: [],
        playerListSelectedItems: [],
        addToPlaylistBottomSheetLoader: false,
    },
    reducers: {
        enableListSelectMode: (state) => {
            state.playerListSelectMode = false;
            state.playerListSelectedItems = [];
            state.listSelectMode = true;
        },
        enablePlayerListSelectMode: (state) => {
            state.listSelectMode = [];
            state.listSelectMode = false;
            state.playerListSelectMode = true;
        },
        disableListSelectMode: (state) => {
            console.log('log out...')
            state.listSelectItems = [];
            state.listSelectMode = false;
        },
        disablePlayerListSelectMode: (state) => {
            state.playerListSelectedItems = [];
            state.playerListSelectMode = false;
        },
        addItemToSelectedListItem: (state, action) => {
            console.log('reducing player')
            const {selectedItem} = action.payload

            state.listSelectItems = [selectedItem, ...state.listSelectItems]
        },
        removeItemOfSelectedList: (state, action) => {
            const {selectedItem} = action.payload
            state.listSelectItems = state.listSelectItems.filter((item) => selectedItem.id != item.id)
        },
        addItemToPlayerSelectedListItem: (state, action) => {
            const {selectedItem} = action.payload
            state.playerListSelectedItems = [selectedItem, ...state.playerListSelectedItems]
        },
        removeItemOfPlayerSelectedList: (state, action) => {
            const {selectedItem} = action.payload
            state.playerListSelectedItems = state.playerListSelectedItems.filter((item) => selectedItem.id != item.id)
        },
        toggleAddToPlaylistLoader:(state)=>{
            state.addToPlaylistBottomSheetLoader=!state.addToPlaylistBottomSheetLoader
        }

    },
})
export const {
    addItemToPlayerSelectedListItem,
    disableListSelectMode,
    disablePlayerListSelectMode,
    enableListSelectMode,
    removeItemOfSelectedList,
    removeItemOfPlayerSelectedList,
    enablePlayerListSelectMode,
    addItemToSelectedListItem,
    toggleAddToPlaylistLoader
} = AddToPlaylistSlice.actions
export const selectSelectedMode = (state) => state.addToPlaylist
export default AddToPlaylistSlice.reducer
