

export const useContextPosition=(event)=>{
 let clickedX=event.clientX;
 let clickedY=event.clientY;
 let screenWidth=window.innerWidth;
 let screenHeight=window.innerHeight;
 let distanceToTop = clickedY;
 let distanceToBottom = screenHeight - clickedY;
 let distanceToLeft = clickedX;
 let distanceToRight = screenWidth - clickedX;
 let vertical=distanceToTop>distanceToBottom?{bottom:distanceToBottom}:{top:distanceToTop}
 let horizontal=distanceToRight>distanceToLeft?{left:distanceToLeft}:{right:distanceToRight}
 return [vertical,horizontal];

}
