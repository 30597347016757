import HomeTrackCollection from "../components/home/HomeTrackCollection";
import HomePlayList from "../components/home/HomePlayList";
import HomeSectionOne from "../components/home/HomeSectionOne";
import HomeCustomBanner from "../components/home/HomeCustomBanner";
import HomeArtistsView from "../components/home/HomeArtistsView";
import HomeCollectionWithCorner from "../components/home/HomeCollectionWithCorner";
import { Suspense, lazy } from "react";
import HomeExclusiveTracksSection from "../components/home/HomeExclusiveTracksSection";
import HomeGeneralCollectionSection from "../components/home/HomeGeneralCollectionSection.jsx";
// const HomeSectionOne = lazy(() => import("../components/home/HomeSectionOne"));
// const HomePlayList = lazy(() => import("../components/home/HomePlayList"));
// const HomeCustomBanner = lazy(() =>
//   import("../components/home/HomeCustomBanner")
// );
// const HomeArtistsView = lazy(() =>
//   import("../components/home/HomeArtistsView")
// );
// const HomeCollectionWithCorner = lazy(() =>
//   import("../components/home/HomeCollectionWithCorner")
// );
/* const HomeTrackCollection = lazy(() =>
  import("../components/home/HomeTrackCollection")
); */

export const HomeItem = {
  main_banner: {
    component: (item) => (
      // <Suspense fallback={<></>}>
      <HomeSectionOne item={item} />
      // </Suspense>
    ),
  },

  general_collections: {
    component: (item) => (
        // <Suspense fallback={<></>}>
          <HomeGeneralCollectionSection
              cardStyle={`!w-[120px] !h-[120px]`}
              item={item}
          />
        // </Suspense>
    ),
  },

  collection_category: {
    component: (item) => (
      // <Suspense fallback={<></>}>
      <HomePlayList item={item} />
      // </Suspense>
    ),
  },

  track: {
    component: (item) => (
      // <Suspense fallback={<div className="min-h-[520px] w-full ">
        // </div>}>
      <HomeTrackCollection item={item} />
    //  </Suspense>
    ),
  },

  exclusive_tracks: {
    component: (item) => (
      // <Suspense fallback={<></>}>
      <HomeExclusiveTracksSection item={item} />
      // </Suspense>
    ),
  },

  custom_banner: {
    component: (item) => (
      // <Suspense fallback={<></>}>
        <HomeCustomBanner item={item} />
      // </Suspense>
    ),
  },

  artist: {
    component: (item) => (
      // <Suspense fallback={<></>}>
        <HomeArtistsView item={item} />
      // </Suspense>
    ),
  },
  collection: {
    component: (item) => (
      // <Suspense fallback={<></>}>
        <HomeCollectionWithCorner
          cardStyle={`!w-[120px] !h-[120px]`}
          item={item}
        />
      // </Suspense>
    ),
  },
};
