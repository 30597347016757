import { memo } from "react";

function ChevronLeft({ className, shadow = false, ...rest }) {
  /**
   * This function returns an SVG element that displays a chevron pointing to the left.
   *
   * @param className (string): The class name of the SVG element.
   * @param rest (object): Any additional props to be passed to the SVG element.
   * @returns An SVG element that displays a chevron pointing to the left.
   */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 24 24"
      className={`${shadow ? "drop-shadow-Chevron-svg" : ""} ${className}`}
      stroke="#F2F2F2"
      {...rest}
    >
      <path
        className={shadow ? "drop-shadow-Chevron-svg" : ""}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 18l-6-6 6-6"
      ></path>
    </svg>
  );
}

export default memo(ChevronLeft);
