import Quality96 from "/src/assets/setting/Quality96.webp";
import Quality160 from "/src/assets/setting/Quality160.webp";
import Quality320 from "/src/assets/setting/Quality320.webp";

export const Downloadqualities = [
  {
    quality: "96",
    image: Quality96,
  },
  {
    quality: "160",
    image: Quality160,
  },
  {
    quality: "320",
    image: Quality320,
  },
];
