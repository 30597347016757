import React from 'react';

function PlusIconSvg() {
    return (
        <svg
            className='w-full h-full '
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 1V15M1 8H15"
                stroke="#F2C94C"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default PlusIconSvg;
