import React from "react";
import placeHolder from "./MusicPlaceholderSvg.svg";

function MusicPlaceholderSvg() {
  return (
    // <img className="w-full h-full" src={placeHolder}/>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full"
      fill="none"
      viewBox="0 0 118 118"
    >
      <rect width="118" height="118" fill="#212121" rx="5"></rect>
      <path
        fill="#4F4F4F"
        d="M51.974 67.212c1.55-1.55 2.528-3.515 2.752-5.53a7.74 7.74 0 00.046-.856h.002V32h-3.929v22.74a6.981 6.981 0 00-2.659-.514c-2.286 0-4.618 1.02-6.398 2.8-3.319 3.32-3.734 8.304-.926 11.112 1.209 1.21 2.883 1.875 4.714 1.875 2.286 0 4.618-1.02 6.398-2.8zM79.655 32h-3.929v22.74a6.981 6.981 0 00-2.659-.514c-2.286 0-4.618 1.02-6.398 2.8-3.319 3.32-3.734 8.304-.926 11.112 1.209 1.21 2.883 1.875 4.714 1.875 2.286 0 4.618-1.02 6.398-2.8 1.55-1.552 2.528-3.516 2.752-5.531a7.74 7.74 0 00.046-.856h.002V32zM66.674 80.157c-2.163 1.88-4.994 2.914-7.972 2.914-2.978 0-5.809-1.035-7.971-2.914-2.008-1.745-3.16-4.024-3.16-6.253h-3.928c0 3.367 1.644 6.726 4.511 9.218C51.031 85.622 54.777 87 58.702 87s7.671-1.377 10.549-3.878c2.867-2.491 4.51-5.851 4.51-9.218h-3.928c0 2.229-1.151 4.508-3.16 6.253z"
      ></path>
    </svg>
  );
}

export default React.memo(MusicPlaceholderSvg);
