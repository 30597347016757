import { memo } from "react";

const FreePremiumSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#F2F2F2"
      fillRule="evenodd"
      d="M5.381 6.67L9.1 2.41a1.197 1.197 0 011.802 0l3.717 4.26 3.547-2.24a1.197 1.197 0 011.82 1.202l-1.686 10.531a1.694 1.694 0 01-1.672 1.427H3.373a1.694 1.694 0 01-1.672-1.427L.015 5.632a1.196 1.196 0 011.82-1.201L5.38 6.67zm-3.918-.824l1.615 10.097a.299.299 0 00.295.252h13.254a.299.299 0 00.295-.252l1.616-10.097-3.319 2.095a1.197 1.197 0 01-1.54-.224L10 3.5 6.321 7.716a1.197 1.197 0 01-1.54.224L1.463 5.846z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#F2F2F2"
      fillRule="evenodd"
      d="M17.478 12.706a.698.698 0 010 1.395H2.526a.698.698 0 010-1.395h14.952z"
      clipRule="evenodd"
    ></path>
  </svg>
);
export default memo(FreePremiumSvg);
