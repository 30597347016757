import React, { useEffect, useRef, useState } from "react";
import PlayerSwipeContainer from "./PlayerSwipeContainer";
import RoundedDrawerHeader from "../svg/common/RoundedDrawerHeader";
import MinPlayer from "./MinPlayer";
import {
  addToPlaylistData,
  getAdvertiseData,
  getHaveAdvertise,
  getPlayerMusic,
  getPlayerPaginationData,
  getPlayList,
  getSwipePlayerStatus,
  getPlayListStatic,
} from "../../helper/ReduxSelectorContext";
import { useDispatch } from "react-redux";
import {
  setPlayerPaginationData,
  setPlayList,
  setSwipePlayerStatus,
} from "../../redux/player/PlayMusicSlice";
import TrackActionsBottom from "./TrackActionsBottom";
import ScrollTopButton from "../common/ScrollTopButton";
import AdsPlayer from "./avertiseds/AdsPlayer";
import AddToPlaylistModeButton from "../playlist/AddToPlaylistModeButton.jsx";
import { disablePlayerListSelectMode } from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import { useNativeBack } from "../../hooks/useNativeBack.js";
import PlayerTrackList from "./PlayerTrackList.jsx";
import { useMutation } from "@tanstack/react-query";
import { getPlayerPaginationTrack } from "../../services/ApiClient.js";
import SongListLoading from "../loading/SongListLoading.jsx";
import { usePlayerPagination } from "../../hooks/usePlayerPagination.js";
import { playerPaginationKey } from "../../consts/PlayerPaginationKey.js";
import useCountdown from '../../hooks/useCountdown.jsx';

function SwipePlayer() {
  let playedTrackData = getPlayerMusic();
  let playList = getPlayList();
  let playListStatic = getPlayListStatic();
  const dispatch = useDispatch();
  let havAdvertise = getHaveAdvertise();
  const playerSwipeStatus = getSwipePlayerStatus();
  let advertiseData = getAdvertiseData();
  const playerRef = useRef(null);
  const { playerPaginationLoader } = usePlayerPagination(
    playerPaginationKey?.SCROLL_PAGINATION
  );
  const {start, clear} = useCountdown()
  const addToPlaylistDataState = addToPlaylistData();
  const checkClearSelectionOfTracks = (openState) => {
    if (
      !openState &&
      addToPlaylistDataState?.playerListSelectedItems?.length > 0
    ) {
      dispatch(disablePlayerListSelectMode());
    }
  };
  const toggleSheet = (newOpen) => {
    checkClearSelectionOfTracks(newOpen);
    dispatch(setSwipePlayerStatus({ status: newOpen }));
  };
  const closeSwipePlayer = () => {
    dispatch(setSwipePlayerStatus({ status: false }));
  };
  useNativeBack(playerSwipeStatus, "swipe-player-status", closeSwipePlayer);
  const scrollTop = () => {
    const page = document.getElementById("playlist-item");
    page?.scrollTo({ top: 0 });
  };
  useEffect(() => {
    scrollTop();
  }, [playerSwipeStatus]);
  return (
    <>
      <div>
        <PlayerSwipeContainer
          openBottomSheet={playerSwipeStatus}
          toggleSheet={toggleSheet}
        >
          <AddToPlaylistModeButton
            inPlayer={true}
            EnableEditMode={
              addToPlaylistDataState?.playerListSelectedItems?.length > 0
            }
          />
          <ScrollTopButton player={true} id={"playlist-item"} />
          <div
            ref={playerRef}
            id="playlist-item"
            className=" w-[95%] mx-auto h-full relative flex flex-col  overflow-scroll"
          >
            {
              <div className="w-full h-[95%] relative rounded-b-[50px] overflow-hidden shadow-player-shadow  ">
                <div
                  className="absolute top-0 z-10 w-full h-full"
                  style={{
                    background:
                      // "linear-gradient(180deg,rgba(33,33,33,.3) .5%,rgba(33,33,33,.3) 20%,rgba(33,33,33,.7) 30%,rgba(33,33,33,.9) 40%,#212121 49%)",
                      "linear-gradient(rgba(33, 33, 33, 0.3) 0.5%, rgba(33, 33, 33, 0.3) 10%, rgba(33, 33, 33, 0.7) 35%, rgba(33, 33, 33, 0.9) 52%, rgb(33, 33, 33) 60%)",
                  }}
                ></div>
                {havAdvertise && advertiseData ? (
                  <div
                    className="absolute top-0 z-0 w-full h-[97%] "
                    style={{ background: advertiseData[0]?.background_color }}
                  ></div>
                ) : (
                  <div
                    className="absolute top-0 z-0 w-full h-[92%] "
                    style={{ background: playedTrackData?.background_color }}
                  ></div>
                )}
                <div className="z-30 top-0 absolute w-full h-full ">
                  <div
                    onClick={() => toggleSheet(false)}
                    className="!w-fit mx-auto justify-center z flex bg-transparent "
                  >
                    <RoundedDrawerHeader />
                  </div>
                  <div
                    dir="rtl"
                    style={{ overscrollBehavior: "none" }}
                    className="bg-transparent px-7 py-[6px] h-full overflow-hidden z-30 text-black text-[16px] font-dana font-[600] leading-6 flex justify-center items-center   "
                  >
                    {/* player Content */}
                    {havAdvertise && <AdsPlayer />}

                    <MinPlayer adv={havAdvertise} />
                  </div>
                </div>
              </div>
            }

            {/* Player bottoms */}
            <div className="w-full h-[10%] relative rounded-b-[50px] ">
              {havAdvertise ? (
                <div className="w-full pt-4 pb-6 opacity-30 text-white font-kalameh font-[500] flex justify-center items-center">
                  {advertiseData && advertiseData[0]?.bottom_text}
                </div>
              ) : (
                <TrackActionsBottom  start={start} clear={clear} />
              )}
              <PlayerTrackList playList={playListStatic} />
              {playerPaginationLoader && (
                <div className="w-full flex justify-center items-center -mt-8">
                  <SongListLoading />
                </div>
              )}
            </div>
          </div>
        </PlayerSwipeContainer>
      </div>
    </>
  );
}

export default SwipePlayer;
