import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getGeneralCollectionTracks } from '../../services/ApiClient.js';
import { useLocation, useParams } from 'react-router-dom';
import { playListSortTypes } from '../../consts/MenuItemsConsts.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import CompleteSongList from '../../components/ArtistProfile/CompleteSongList.jsx';
import { IMAGE_BASE_URL } from '../../BASE.js';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';
import Loading from '../../components/loading/Loading.jsx';
import { useDispatch } from 'react-redux';
import { setSeenCollection } from '../../redux/RecentlyHeard/RecentlyHeardSlice';

import { globalData } from '../../helper/ReduxSelectorContext';
import ClickReaction from '../../helper/ClickReaction';
import PlaylistUpdateDialog from '../../components/playlist/PlaylistUpdateDialog';
import { setPlaylistUpdateDialogShowed } from '../../redux/globalData/globalDataSlice.js';
import { useAction } from '../../hooks/useAction.js';
import ScrollTopButton from '../../components/common/ScrollTopButton.jsx';
import Container from '../../components/PageParentContainer/Container.jsx';
import CollectionCategoryHeader from '../../components/playlist/CollectionCategoryHeader.jsx';
import VisitCountIcon from '../../components/svg/playList/VisitCountIcon.jsx';
import ToggleFollowSvgIcon from '../../components/svg/playList/ToggleFollowSvgIcon.jsx';
import CssShimmer from '../../components/common/CssShimmer.jsx';
import { playStoreUrl } from '../../consts/GlobalConsts.js';
import SortSvg from '../../components/svg/playList/SortSvg.jsx';
import SongListLoading from '../../components/loading/SongListLoading.jsx';
import { appendBaseUrl } from '../../helper/utils.js';

function GeneralCollection() {
  const { playlistUpdateDialogShowed } = globalData();
  const { collection_id: collectionId } = useParams();
  const location = useLocation();
    const collectionObject = location.state?.collection;
    const dialogRef = useRef(null);
    const [sortType, setSortType] = useState('melodify');
  const [endList, setEndList] = useState();
  const [collectionData, setCollectionData] = useState('');
  const [collectionTrackList, setCollectionTrackList] = useState(null);
  const dispatch = useDispatch();
  const action = useAction();
  const [trackLimit, setTrackLimit] = useState();
  const collectionQueryData = {
    id: collectionId,
    offset: collectionTrackList?.length || 0,
  };
  const getGeneralCollectionTracksMutation = useMutation(
    () => getGeneralCollectionTracks(collectionQueryData),
    {
      onSuccess: res => {
        if (collectionTrackList) {
          setCollectionTrackList(prevState => {
            const updatedState = Array.isArray(prevState) ? [...prevState] : [];
            return [...updatedState, ...res?.data?.result?.tracks];
          });
        } else {
          setCollectionTrackList(res?.data?.result?.tracks);
        }
        setEndList(res?.data?.result?.end);
      },
    },
  );
  const reFetchData = () => {
    getGeneralCollectionTracksMutation.mutate();
  };
  useEffect(() => {
    getGeneralCollectionTracksMutation.mutate();
  }, [collectionId]);

  useEffect(() => {
    setCollectionTrackList(
      getGeneralCollectionTracksMutation?.data?.data?.result?.tracks,
    );
  }, []);

  //todo:we dont have second title in collection data

  const sharePlaylist = () => {
    navigator
      .share({
        title: 'اشتراک',
        text:
          'دانلود و شنیدن  آهنگ های پلی لیست' +
          ' " ' +
          collectionObject?.title +
          ' " ' +
          'در اپلیکیشن ملودیفای \n' +
          '\nلینک برنامه :\nلینک' +
          playStoreUrl,
      })
      .then(() => console.log('Yay, you shared it :)'))
      .catch(error => {
        console.log(`this error happened during sharing : + ${error}`);
      });
  };
  const hasLimit = () => {
    const result = collectionTrackList?.some(item => item?.track_limit != null);
    setTrackLimit(result);
  };
  useEffect(() => {
    hasLimit();
  }, [collectionTrackList]);
  useEffect(() => {
    if (playlistUpdateDialogShowed) {
      return;
    }
    if (collectionData?.is_updated) {
      dispatch(setPlaylistUpdateDialogShowed());
      dialogRef?.current?.openModal();
    }
  }, [collectionData]);

  return (
    <Container style="bg-primary_dark">
      <CollectionCategoryHeader
        hasSearch={false}
        title={collectionObject?.title}
        backgroundId="playlist-songs-background"
        pageId="playlist-song"
      />
      <div
        dir="rtl"
        id="playlist-song"
        className="w-full h-full overflow-scroll hide-scroll-bar pb-10"
      >
        <div
          id="playlist-songs-background"
          className="w-full aspect-square max-h-[45vh] relative  overflow-hidden"
        >
          <CustomLazyLoadImage
            imageClass={`object-cover block`}
            image={collectionObject?.image_large}
          />
          <span className="absolute top-0 flex top-0 left-0 w-full h-full text-white justify-center text-lg items-center font-kalameh">
            {collectionObject?.title}
          </span>
          <div className="absolute top-0 right-0 left-0 bottom-0 bg-playlist-song-overlay h-[100.15%]" />
        </div>
        <ScrollTopButton id={'playlist-song'} />
        <PlaylistUpdateDialog ref={dialogRef} />
        <div
          id="page-infinite-scroll"
          className="relative px-4 flex flex-col gap-5 mt-[-75px] w-full"
        >
          <InfiniteScroll
            className="!overflow-visible"
            next={reFetchData}
            hasMore={!endList}
            loader={
              collectionQueryData.offset === 0 ? (
                <></>
              ) : (
                <div className=" mt-2 lg-1440:mt-r8 flex w-full justify-center text-yellow   ">
                  <SongListLoading />
                </div>
              )
            }
            scrollableTarget="playlist-song"
            dataLength={collectionTrackList?.length || 0}
          >
            <div>
              <CompleteSongList
                scrollId={'playlist-song'}
                hasDetails={false}
                list={collectionTrackList}
                pageId={collectionId}
                collectionObject={collectionObject}
                loading={
                  getGeneralCollectionTracksMutation.isLoading &&
                  !collectionTrackList
                }
              />
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </Container>
  );
}

export default GeneralCollection;
