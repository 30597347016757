export default function (playList) {
    let shufflePlaylist = [...playList];
    for (let i = 0; i < playList.length - 1; i++) {
        const j = Math.floor(Math.random() * (playList.length - i)) + i;
        [shufflePlaylist[i], shufflePlaylist[j]] = [
            shufflePlaylist[j],
            shufflePlaylist[i],
        ];
    }

    let playListWithQid = shufflePlaylist.map((item) => {
        let itemCopy = { ...item };
        // itemCopy.Qid = item.id + "" + Math.floor(Math.random() * 1000000).toFixed()
        itemCopy.Qid = item.id;
        return itemCopy;
    });

    return playListWithQid
}