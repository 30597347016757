import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
// import {toast} from 'react-toastify';
import { ACTIONS } from "../consts/ActionsConstants";
import {
  dispatchInactiveSession,
  dispatchSwipePlayerStatus,
} from "../redux/globalstore/GlobalStore";
import { setCategory } from "../redux/support/SelectedCategorySlice";
import {
  getArtistTracks,
  getCollectionCategory,
  getCollectionTracks,
} from "../services/ApiClient";
import { initialData } from "../redux/initialize/InitializeSlice";
import { logout } from "../redux/login/LoginSlice";
import { openContextMenu } from "../redux/player/DropDownSlice.js";
import { useMutation } from "@tanstack/react-query";
import { clearComponentsList, setPlayPushActionData } from "../redux/player/PlayerSlice.js";
import { mainToast } from "../helper/ToastsEmitter";
import { clearScrollPosition } from '../redux/scrollManageStore/scrollManageSlice.js';

// import {AddToClipBoard} from '../utils/AddToClipBoard';

export function useAction(metaData) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getCollectionCategoryMutation = useMutation((data) =>
    getCollectionCategory(data)
  );
  const handleActions = (action) => {
    console.log("action first line", action);
    switch (action?.target_type) {
      case ACTIONS.GENERAL_COLLECTIONS:
        console.log(action?.target_type)
        handleOpenGeneralCollection(action)
        break;
      case ACTIONS.HOME_COLLECTION_TYPE:
        navigate("/playlist");
        break;
      case ACTIONS.TICKETS_TYPE:
        navigate("/tickets");
        break;
      case ACTIONS.USER_PROFILE_TYPE:
        navigate("/setting");
        break;
      case ACTIONS.PLANS_TYPE:
        dispatch(clearComponentsList());
        navigate("/plans", {
          state: { openPlayerWhenBack: action?.openPlayerWhenBack },
        });
        break;
      case ACTIONS.LINK_TYPE:
        handleLinkAction(action?.link);
        break;
      case ACTIONS.RESTART:
        handleRestartAction();
        break;
      case ACTIONS.LOGOUT:
        handleLogOutAction(action?.data?.logout_message);
        break;
      case ACTIONS.CLEAR_CACHE:
        clearCacheAction();
        break;
      case ACTIONS.Exit_TYPE:
        navigate("/home");
        break;
      case ACTIONS.DEVICE_MANAGER:
        handleDeviceManagerAction();
        break;

      case ACTIONS.USER_PLAYLISTS_TYPE:
        navigate("/my-melodify/user-playlists");
        break;
      case ACTIONS.PLAN_TYPE:
        handlePlaneAction(action?.plan);
        break;
      case ACTIONS.ARTIST_TYPE:
        handleArtistAction(action?.artist);
        break;
      case ACTIONS.TRACK_TYPE:
        handlePlayTrackAction(action);
        break;
      case ACTIONS.SHARE_TYPE:
        handleShareAction(action?.data?.message);
        break;
      case ACTIONS.NEW_TICKET_TYPE:
        createNewTicket(action);
        break;
      case ACTIONS.ARTISTS_TYPE:
        handleArtistsAction(action);
        break;
      case ACTIONS.MY_MELODIFY_PLAYLISTS_TYPE:
        navigate(
          "/my-melodify/" +
          action?.collection?.collection_type.replaceAll("_", "-"),
          { state: { item: action?.collection } }
        );
        break;
      //todo
      case ACTIONS.COLLECTION_TYPE:
        handleCollectionAction(action);
        break;
      case ACTIONS.ARTIST_GROUP_TYPE:
        handleArtistGroupAction(action?.artist_group);
        break;
      //Dones
      //   case ACTIONS.POPULAR_ARTISTS_TYPE:
      //     let type = action?.type ? action.type : "persian";
      //     handleArtistsAction(type);
      //     break;

      // todos
      // todo not send from backend
      case ACTIONS.TICKET_TYPE:
        handleShowTicket(action)
        break;

      case ACTIONS.COLLECTION_CATEGORY_TYPE:
        handleOpenCollectionCategory(action);
        break;

      case ACTIONS.POPULAR_TRACKS_TYPE:
        console.log("Sdfsdfdsfsdfdsfs", action);
        handlePopularTracks(action);
        break;
      case ACTIONS.POPULAR_ARTISTS_TYPE:
        handleArtistsAction(action);
        break;

      case ACTIONS.NEWEST_TRACKS_TYPE:
        handleNewestTrack(action);
        break;

      case ACTIONS.COLLECTION_CATEGORY_BY_ID_TYPE:
        handleGetCollectionCategory(action);
        break;
      case ACTIONS.OPEN_CONTEXT_MENU:
        handleOpenContextMenuAction(action);
        break;
      case ACTIONS.SHOW_ALL_SEARCH_RESULTS:
        handleShowAllSearchResultsAction(action);
        break;
      case ACTIONS.OPEN_USER_PLAYLIST:
        handleOpenUserPlaylistAction(action);
        break;
      case ACTIONS.OPEN_ALBUM:
        console.log(action?.albumObject, "album object");
        navigate("/album-songs", {
          state: { albumObject: action?.albumObject },
        });
        break;
      case ACTIONS.PLAYER_SLEEP_TIMER:
        handleSleepTimer(action?.time);
        break;
      case ACTIONS.EXCLUSIVE_TRACKS:
        handleExclusiveTracks(action);
        break;
      case ACTIONS.GENERAL_TYPE:
        handleGeneralType(action)
        break;
      case ACTIONS.OPEN_ALBUMS_LIST:
        navigate("/albums-list", {
          state: { items: action?.items, artistId: action?.artistId },
        });
        break;
      default:
        break;
    }
  };

  //handle FAQ Data And go To Create New Ticket
  const createNewTicket = (action) => {
    console.log("SDfdsfgsdgsdg", action);
    dispatch(setCategory({ category: action?.faq_category }));
    navigate("/support/create-ticket", { state: { item: action?.faq } });
  };
  // open link in new tab
  const handleLinkAction = (link) => {
    window.open(link, "_blank", "toolbar=0,location=0,menubar=0");
  };

  //reload app in this action
  const handleRestartAction = () => {
    // window.location.reload();
    navigate("/");
  };

  //copy data to clipboard
  const handleShareAction = (message) => {
    navigate("/setting/free-premium");
  };

  //got to plan item
  const handlePlaneAction = (item) => {
    navigate("/payment", { state: item });
  };

  //got to Device manager
  const handleDeviceManagerAction = () => {
    dispatchInactiveSession(null);
    navigate("/setting/manage-devices");
  };

  //got to artist page
  const handleArtistAction = (artist) => {
    dispatchSwipePlayerStatus(false);
    navigate(`/artist-profile/${artist?.id}`, {
      state: { artistObject: artist },
    });
  };

  //got to artists page
  const handleArtistsAction = (action) => {
    let type = action?.type ? action.type : "persian";
    let title = action?.title ? action.title : " خوانندگان ایرانی";
    if(action?.form_notification){
      type =action?.language
    }
    navigate("/artists-page/artists", { state: { type, title } });
  };
  //go to artist group page
  const handleArtistGroupAction = (artist_group) => {
    console.log({ artist_group });
    navigate("/artists-group", { state: artist_group });
  };
  //logout user
  const handleLogOutAction = (message) => {
    //alert("LOG OUT");
    dispatch(logout());
    if (message) {
      localStorage.setItem(
        "message",
        JSON.stringify({ second_logout_message: message })
      );
    }
    localStorage.clear();
    dispatch(initialData({ initialData: null }));
  };

  const handlePlayTrackAction = (trackData) => {
    const artistQueryData = {
      artist_id: trackData?.artist_id,
      offset: 0,
    };
    getArtistTracks(artistQueryData).then((item) => {
      let track = item?.data?.result?.tracks.filter(
        (item) => item.id.toString() === trackData?.track_id.toString()
      );
      dispatch(setPlayPushActionData({ player_data: { track: track?.[0], playlist: item?.data?.result?.tracks } }))
    });
  };
  const handlePopularTracks = (action) => {
    navigate(
      `/popular-songs?language=${action?.type ? action?.language ? action?.language : action?.type : "persian"}`
    );
  };
  const handleNewestTrack = (action) => {
    navigate(
      `/newest-songs?language=${action?.type ? action?.language ? action?.language : action?.type : "persian"}`
    );
  };
  const handleCollectionAction = (action) => {
    console.log("action?.view_type", action);
    switch (action?.view_type) {
      case ACTIONS.PLAY_LIST_VIEW_TYPE:
        //set playlist route when create

        navigate(`/playlist-songs/${action?.collection?.id}`, {
          state: {
            collectionObject: action?.collection,
          },
        });
        break;
      case ACTIONS.TRACK_LIST_VIEW_TYPE:
        // set playlist without sort
         navigate(`/track-list/${action?.collection?.id}?type=${ACTIONS.TRACK_LIST_VIEW_TYPE}`, {
          state: {collection:action?.collection},
        });
        break;
      case ACTIONS.SIMPLE_PLAY_LIST_VIEW_TYPE:
        navigate(`/track-list/${action?.collection?.id}?type=${ACTIONS.SIMPLE_PLAY_LIST_VIEW_TYPE}`, {
          state: {collection:action?.collection},
        });
        break;
      case ACTIONS.GENERAL_COLLECTION_VIEW_TYPE:
        navigate(`/general-collection/${action?.collection?.id}`, {
          state: {
            collection: action?.collection
        }
        });
        break;
      case ACTIONS.PLAYER_VIEW_TYPE:
        handleCollectionPlayerAction(action);
        break;
      case ACTIONS.BANNER_VIEW_TYPE:
        openBannerCollection(action);
      default:
        break;
    }
  };
  const handleCollectionPlayerAction = (action) => {
    const collectionQueryData = {
      collection_id: action?.collection.id,
      offset: 0,
      sort: "melodify",
    };
    getCollectionTracks(collectionQueryData).then((item) => {
      //should get playlist and set in queue
      dispatch(setPlayPushActionData({ player_data: { track: item?.data?.result?.tracks?.[0], playlist: item?.data?.result?.tracks } }))
    });
  };
  //clear cache
  const clearCacheAction = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    mainToast(" !حافظه کَش پاک شد  ");
  };

  const handleOpenCollectionCategory = (action) => {
    const { children } = action;
    console.log(action)
    const type = searchParams.get("type");
    const hasChild = children?.has_child === undefined ? null : children?.has_child;
    if (type === "sub-grid") {
      navigate(`/playlist-songs/${children?.id}`, {
        state: { collectionObject: children },
      });
      return;
    }
    hasChildCaseCheck(hasChild, action, children);
  };
  const hasChildCaseCheck = (hasChild, action, children) => {
    if (hasChild) {
      const collectionItem = action?.children?.children;
      dispatch(clearScrollPosition({pathname:`/collection-subcategory?type=grid&&title=${children?.title}`}))
      navigate(`/collection-subcategory?type=grid&&title=${children?.title}`, {
        state: {
          categoryData: collectionItem,
          subtitle: children?.subtitle,
          id: children?.id,
        },
      });
    }
    if (!hasChild) {
      const collectionObject = {
        collectionItems: null,
        title: null,
        subtitle: null,
        dataObject: null,
      };
      console.log(action,'data test...')
      if (action?.children?.collections) {
        collectionObject.collectionItems = action?.children?.collections?.data
          ? action?.children?.collections?.data
          : action?.children?.collections;
        collectionObject.title = children?.title;
        collectionObject.subtitle = action?.children?.subtitle;
        collectionObject.dataObject = action?.children;
      } else if (action?.children) {
        collectionObject.collectionItems = action?.children?.collections?.data;
        collectionObject.title = action?.children?.title;
        collectionObject.subtitle = action?.children?.subtitle;
        collectionObject.dataObject = action?.children;
      }
      if(action?.form_notification){
        collectionObject.collectionItems =action?.collection_category?.collections?.data
        collectionObject.title = action?.collection_category?.title;
        collectionObject.subtitle = action?.collection_category?.subtitle;
        collectionObject.dataObject = action?.collection_category;
      }
      dispatch(clearScrollPosition({pathname:`/collection-category?type=sub-grid&&title=${collectionObject?.title}`}))
      navigate(
        `/collection-category?type=sub-grid&&title=${collectionObject?.title}`,
        {
          state: {
            categoryData: collectionObject.collectionItems,
            subtitle: collectionObject.subtitle,
            dataObject: collectionObject?.dataObject,
          },
        }
      );
    }
  };
  const handleOpenContextMenuAction = (action) => {
    dispatch(
      openContextMenu({
        data: action.data,
        horizontal: action.horizontal,
        vertical: action.vertical,
        inPlayer: action.inPlayer,
        isDownloadedTrack: action.isDownloadedTrack,
      })
    );
  };
  const openBannerCollection = (action) => {
    console.log(action);
  };
  const handleGetCollectionCategory = (action) => {
    const data = {
      offset: 0,
      collection_category_id: action?.collection_category_id,
    };
    //todo:handle waiting for send request and then show page
    getCollectionCategoryMutation.mutate(data, {
      onSuccess: (response) => {
        const data = response?.data?.result?.collection_category;
        handleOpenCollectionCategory({ item: data });
      },
    });
  };
  const handleOpenUserPlaylistAction = (action) => {
    const { playlistData } = action;
    navigate(`/user-playlist/${playlistData?.id}`, {
      state: {
        playlistData,
      },
    });
  };
  const handleShowAllSearchResultsAction = (action) => {
    navigate("/search-results", {
      state: { type: action?.type, items: action?.items },
    });
  };
  const handleShowTicket = (action) => {
    console.log(action?.ticket_code)
    navigate("/support/show-messages", {
      state: { ticket_code: action?.ticket_code },
    });
  }
  const handleExclusiveTracks = (action) => {
    const actionData = {
      pageData:action?.form_notification?action?.exclusive_tracks:action?.exclusive_tracks_page_data,
      requestUrl:action?.form_notification?action?.exclusive_tracks?.tracks_url :action?.listData[0]?.exclusive_track_items_meta_data?.tracks_url,
      trackTypes:action?.form_notification?action?.exclusive_tracks?.tracks_type :action?.listData[0]?.exclusive_track_items_meta_data?.tracks_type
    }
    navigate("/home/exclusive-tracks", {
      state: {
        pageData: actionData?.pageData,
        requestUrl: actionData?.requestUrl,
        trackTypes: actionData?.trackTypes,
      },
    });
  };
  const handleGeneralType = (action) => {
    const linkEl = document.createElement("a");
    linkEl.setAttribute("href", action?.general_link);
    linkEl.setAttribute("target", "_blank");
    linkEl.click();
  };
  const handleOpenGeneralCollection = (action) => {
    console.log(action);
    navigate(`/generalCollectionGroup/${action?.general_collections_page_data?.group_id}`,{
      state:{actionData:action}

    })

  }

  return handleActions;
}
