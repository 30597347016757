import * as React from "react";
import { memo } from "react";
const AboutUsTermsSvg = (props) => (
  /**
   * This function returns an SVG element that displays a chevron pointing to the left.
   * 
  
   * @param props (object): Any additional props to be passed to the SVG element.
   * @returns An SVG element that displays a chevron pointing to the left.
   */
  <svg
    width={20}
    height={22}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 3H16C16.5304 3 17.0391 3.21071 17.4142 3.58579C17.7893 3.96086 18 4.46957 18 5V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H6"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 1H7C6.44772 1 6 1.44772 6 2V4C6 4.55228 6.44772 5 7 5H13C13.5523 5 14 4.55228 14 4V2C14 1.44772 13.5523 1 13 1Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default memo(AboutUsTermsSvg) ;
