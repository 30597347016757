import sha1 from "js-sha1";
import CryptoJS from "crypto-js";



function aes_128_decrypt(password, data) {
    var ivCiphertext = CryptoJS.enc.Base64.parse(data); // Parse data into a WordArray
    var iv = CryptoJS.lib.WordArray.create(ivCiphertext.words.slice(0, 16 / 4)); // Separate iv
    var payload = CryptoJS.lib.WordArray.create(
        ivCiphertext.words.slice(16 / 4)
    );

    const decrypted = CryptoJS.AES.decrypt(
        {
            ciphertext: payload, // Pass CipherParams object
        },
        password,
        {
            iv: iv,
        }
    );
    return decrypted.toString(CryptoJS.enc.Utf8); // Utf8 decode plaintext
}

export const DecryptLyric = (hashLyric, userData, playedTrack) => {
    let secretKey = sha1(userData?.id + "-" + playedTrack?.id).match(
        /.{0,16}/g
    )[0];
    const encryption_key = CryptoJS.enc.Utf8.parse(secretKey.substr(0, 16));
    const base64original = hashLyric.replace(/-/gi, "+").replace(`//gi, "/"`);
    const data = aes_128_decrypt(encryption_key, base64original);
    return JSON.parse(data);
};



