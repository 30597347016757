import React from "react";

function Loading({ bgColor = "#F2C94C",parentClass='' }) {
  return (
    <div class={`spinner ${parentClass}`}>
      <div style={{ backgroundColor: bgColor }} className="bounce1"></div>
      <div style={{ backgroundColor: bgColor }} className="bounce2"></div>
      <div style={{ backgroundColor: bgColor }} className="bounce3"></div>
    </div>
  );
}

export default Loading;
