import * as React from "react";

function PlayIconType2Svg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className='w-full h-full'
            fill="none"
            viewBox="0 0 24 25"
        >
            <circle cx="12" cy="12.752" r="12" fill="#F2C94C"></circle>
            <path
                fill="url(#paint0_linear_240_5193)"
                d="M16.946 11.62l-6.615-4.078a1.511 1.511 0 00-1.547-.033C8.293 7.783 8 8.282 8 8.844v8.118c0 .85.685 1.545 1.526 1.55h.007c.263 0 .537-.083.793-.24a.437.437 0 10-.455-.745.67.67 0 01-.34.111.679.679 0 01-.658-.676V8.844c0-.241.126-.455.337-.573a.648.648 0 01.663.014l6.615 4.078a.643.643 0 01.312.56.644.644 0 01-.315.559l-4.782 2.928a.437.437 0 10.456.745l4.782-2.928a1.52 1.52 0 00.733-1.302 1.52 1.52 0 00-.728-1.306z"
            ></path>
            <path
                fill="url(#paint1_linear_240_5193)"
                fillRule="evenodd"
                d="M9.524 18.512a1.546 1.546 0 01-1.526-1.55V8.844c0-.562.293-1.061.784-1.335a1.511 1.511 0 011.548.033l6.615 4.077c.456.281.728.77.727 1.306a1.52 1.52 0 01-.733 1.302l-4.782 2.928a.437.437 0 01-.456-.745l4.783-2.928a.643.643 0 00.315-.559.643.643 0 00-.313-.56L9.871 8.285a.648.648 0 00-.663-.014.648.648 0 00-.336.573v8.118c0 .402.34.675.657.676a.67.67 0 00.34-.11.437.437 0 01.455.745c-.256.156-.53.239-.793.239h-.007zm.93-.027c-.29.177-.608.275-.923.275h-.007a1.794 1.794 0 01-1.774-1.798V8.844c0-.652.342-1.234.91-1.552m1.794 11.193a.685.685 0 00-.714-1.17.43.43 0 01-.21.075.43.43 0 01-.41-.428V8.844a.4.4 0 01.209-.356.4.4 0 01.412.009l6.615 4.077a.395.395 0 01.194.348c0 .147-.07.271-.196.348L11.571 16.2a.685.685 0 10.716 1.168l4.781-2.928c.532-.324.851-.89.852-1.514a1.767 1.767 0 00-.845-1.517L10.46 7.33a1.76 1.76 0 00-1.8-.038"
                clipRule="evenodd"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_240_5193"
                    x1="12.837"
                    x2="12.837"
                    y1="7.312"
                    y2="18.512"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#141414"></stop>
                    <stop offset="1" stopColor="#242424"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_240_5193"
                    x1="12.835"
                    x2="12.835"
                    y1="7.063"
                    y2="18.76"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#141414"></stop>
                    <stop offset="1" stopColor="#242424"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default PlayIconType2Svg;
