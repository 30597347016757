// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";

function SwipeProvider({ children, swipeHandler, isShow }) {
  return isShow ? (
    <Swiper
      dir="rtl"
      className="!w-full !h-fit flex flex-col-reverse !select-none new-song-swiper  "
      slidesPerView={1}
      initialSlide={1}
      threshold={"30"}
      onSlideChangeTransitionEnd={(event) => {
        if (event?.activeIndex !== 1) swipeHandler(event);
      }}
    >
      <SwiperSlide></SwiperSlide>
      <SwiperSlide key={"main"}>{children}</SwiperSlide>
      <SwiperSlide></SwiperSlide>
    </Swiper>
  ) : (
    <></>
  );
}

export default SwipeProvider;
