import { useSelector } from "react-redux";
import { selectPremiumDialog } from "../../redux/dialog/DialogSlice.js";
import CongratulationImage from "../../assets/common/congrats.svg";
import CustomLazyLoadImage from './CustomLazyLoadImage.jsx';
import CommonHeader from "./CommonHeader.jsx";
import Container from "../PageParentContainer/Container.jsx";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router";
import handelBreakSentence from "../../helper/handelBreakSentence.jsx";

function PremiumPage() {
  const navigate = useNavigate();
  const startUse = () => {
    navigate("/home", { replace: true });
  };
  const dialogData = useSelector(selectPremiumDialog);

  return (
    <Container
      style="bg-secondary"
      header={
        <CommonHeader
          navigateBackOptions={{ replace: true }}
          title={dialogData?.title || "تبریک"}
          backPath="/home"
        />
      }
    >
      <div
        dir="rtl"
        className="w-full bg-pr flex flex-col items-center min-h-full pb-5 font-dana gap-12"
      >
        <div className={"w-full"}>

            <CustomLazyLoadImage
                imageClass={"w-full object-cover !h-auto !relative "}
                appendBaseUrl={false}
                image={CongratulationImage}
            />
        </div>
        <div className="flex flex-col text-center gap-3">
          <p className="text-primary font-medium font-dana text-2xl">
            {dialogData?.title}
          </p>

          <p dir="rtl" className="text-white  text-base text-center">
            {handelBreakSentence(dialogData?.description || "")}
          </p>
        </div>
        <CardActionArea className="!rounded-full !w-[160px] !mx-auto">
          <button
            onClick={startUse}
            type="button"
            className={
              "text-center inline-flex text-base bg-primary font-medium w-full justify-center rounded-full px-4 py-3 focus:outline-none"
            }
          >
            شروع استفاده
          </button>
        </CardActionArea>
      </div>
    </Container>
  );
}

export default PremiumPage;
