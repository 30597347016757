import { createSlice } from "@reduxjs/toolkit";

export const SupportSlice = createSlice({
    name:"support",
    initialState:{
        tickets:[],
        faqs:[],
    },
    reducers:{
        setTickets:(state,action)=>{
            const {ticket} = action.payload
            state.tickets.push(...ticket)
        },
        setFaqList:(state,action)=>{
            const {faqs} = action.payload
            state.faqs=faqs
        }
    }
})

export const {setTickets,setFaqList} = SupportSlice.actions
export const setAllTickets = (state) => state.ticket.tickets
export const selectFaqs=(state) => state.support.faqs
export default SupportSlice.reducer
