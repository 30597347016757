import { CardActionArea } from "@mui/material";
import React from "react";
import MessengerSvg from "../svg/tickets/MessengerSvg";
import { useNavigate } from "react-router-dom";

function TicketsCard({ ticket }) {
  const {
    date,
    unread_count: count,
    ticket_code: code,
    subject: title,
    ticket_status: { color, title: statusTitle },
  } = ticket;

  const navigate = useNavigate();

  const handleClickTicket = () => {
    navigate("/support/show-messages", { state: ticket });
  };

  return (
    <CardActionArea
      dir="rtl"
      className=" !rounded-md !w-full !min-h-[116px]"
      onClick={handleClickTicket}
    >
      <div className="bg-ticket-card relative flex flex-col w-full h-full  rounded-md py-3 overflow-visible ">
        {count !== 0 && (
          <div className="absolute -top-[9px] left-[9px] bg-red  text-gray_6 text-[10px] font-dana flex items-center justify-center w-[77px] h-[18px] rounded-xl px-[8px] ">
            {count} پیام جدید
          </div>
        )}
        <div className="flex items-center justify-start h-[75px] border-b-2 border-b-secondary px-4">
          <div className="w-[46px] h-[46px] me-2">
            <MessengerSvg />
          </div>
          <div className="flex flex-col w-[calc(100%_-_46px_-_8px)]">
            <p className="text-primary text-sm font-dana font-medium line-clamp-2 break-words w-full">
              {title}
            </p>
            <p className="text-gray_5 text-xs leading-6 font-dana font-medium ">
              کد: {code}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between pt-[10px] px-4">
          <p className=" text-gray_4  max-[520px]:text-[10px] text-xs leading-6 font-dana font-medium ">
            آخرین بروزرسانی: {date}
          </p>
          <div className="bg-black h-[18px] rounded-xl flex justify-center items-center">
            <p
              style={{ color }}
              className=" text-[10px] leading-6 font-dana font-medium px-[10px] "
            >
              {statusTitle}
            </p>
          </div>
        </div>
      </div>
    </CardActionArea>
  );
}

export default TicketsCard;
