import React, { memo, useState } from 'react';
import { usePlayedTrack } from '../../hooks/usePlayedTrack.js';
import { setPlayerPaginationData } from '../../redux/player/PlayMusicSlice.js';
import { useDispatch } from 'react-redux';
import CardActionArea from '@mui/material/CardActionArea';
import { useSelectTrackItem } from '../../hooks/useSelectTrackItem.js';
import { useDownload } from '../../hooks/useDownload.js';
import UserPublishedTrackInfoSection from '../userPublishedTrack/UserPublishedTrackInfoSection.jsx';
import UserPublishedTrackStatisticSection from '../userPublishedTrack/UserPublishedTrackStatisticSection.jsx';

function UserPublishedSongCard({
  index,
  number = false,
  data: item,
  playList = [],
  ShowItemIsPlaying = true,
  type = 'playlist',
  isPlayListFromQueue = false,
  inPlayer = false,
  apiUrl = null,
  requestPayload = null,
  endOfPagination = true,
}) {
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  const { isSelected } = useSelectTrackItem(item);
  const dispatch = useDispatch();

  const [, , , , downloadedItem,] = useDownload(item);
  // size
  const handlePlayMusic = item => {
    setPlayerTrack(
      downloadedItem ? downloadedItem : item,
      playList,
      type,
      isPlayListFromQueue,
    );
    if (!inPlayer && apiUrl) {
      dispatch(
        setPlayerPaginationData({
          playerPaginationData: {
            url: apiUrl,
            payload: requestPayload,
            end: endOfPagination,
          },
        }),
      );
    }
  };

  const styleOfCard = () => {
    if (isSelected) {
      return 'bg-opacity-20';
    }
  };
  return (
    <>
      <CardActionArea className=" !rounded-[5px]">
        <div
          dir="ltr"
          onClick={() => handlePlayMusic(item)}
          className={`p-[1px]  cursor-pointer group   rounded-[5px] relative overflow-visible ${
            currentTrack?.id == item?.id && 'bg-queue-border'
          }`}
        >
          {currentTrack?.id == item?.id && (
            <div className="absolute flex w-[55%] top-[1px] bottom-[1px] bg-queue-border  opacity-20 rounded-[5px]"></div>
          )}
          <div
            className={`
                       ${styleOfCard()}
                       ${currentTrack?.id == item?.id && 'bg-primary'} 
                       ${number ? 'pl-0' : 'pl-4'}
                       py-2 flex justify-between rounded-[5px]  pr-4  w-full items-center bg-secondary`}
          >
            <UserPublishedTrackInfoSection
              item={item}
              currentTrack={currentTrack}
              index={index}
              number={number}
              ShowItemIsPlaying={ShowItemIsPlaying}
            />
            <UserPublishedTrackStatisticSection item={item} />
          </div>
        </div>
      </CardActionArea>
    </>
  );
}

export default memo(UserPublishedSongCard);
