import { memo } from 'react';

function DisableDownloadSvg({ lineColor = '#4F4F4F', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="#4F4F4F"
      {...props}
    >
      <path d="M7.98 1.57a7.359 7.359 0 012.039 0 .745.745 0 00.848-.636.75.75 0 00-.636-.849 8.906 8.906 0 00-2.462 0 .75.75 0 00.212 1.485z"></path>
      <path d="M13.091.98a.75.75 0 00-.682 1.336A7.476 7.476 0 0116.5 9c0 4.135-3.364 7.5-7.5 7.5s-7.5-3.365-7.5-7.5a7.474 7.474 0 014.092-6.682A.75.75 0 004.909.98 8.967 8.967 0 000 9c0 4.962 4.037 9 9 9 4.962 0 9-4.038 9-9A8.968 8.968 0 0013.091.98z"></path>
      <path
        stroke={lineColor}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M17.469 1L1 17.53"
      ></path>
      <path d="M5.47 10.719a.75.75 0 000 1.06l3 3a.746.746 0 001.06 0l3-3a.75.75 0 00-1.06-1.06l-1.72 1.72v-8.69a.75.75 0 00-1.5 0v8.69l-1.72-1.72a.75.75 0 00-1.06 0z"></path>
    </svg>
  );
}

export default memo(DisableDownloadSvg);
