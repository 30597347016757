import { memo } from "react";

const InstagramLogoSvg = (props) => (
   /**
   * This function returns an SVG element that displays a chevron pointing to the left.
   * 
  
   * @param props (object): Any additional props to be passed to the SVG element.
   * @returns An SVG element that displays a chevron pointing to the left.
   */
  <svg
    width={34}
    height={35}
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1.75}
      width={32}
      height={32}
      rx={10}
      stroke="#F2C94C"
      strokeWidth={2}
    />
    <circle cx={17} cy={18.3643} r={7} stroke="#F2C94C" strokeWidth={2} />
    <circle cx={26.2311} cy={8.51916} r={2.46154} fill="#F2C94C" />
  </svg>
);
export default memo(InstagramLogoSvg) ;
