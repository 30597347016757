import React from 'react';

function GreenCheck(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className="w-full h-full"
            viewBox="0 0 18 13"
            stroke="#219653"
            {...props}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 1L6 12 1 7"
            ></path>
        </svg>
    );
}

export default GreenCheck;
