import { memo } from 'react';

import GreenCheck from '../svg/common/GreenCheck';
import CrownSvg from '../svg/common/CrownSvg';
import { CardActionArea } from '@mui/material';
import CustomImage from '../common/CustomImage';

function QualityCard({
  item: { quality, image },
  isChecked = false,
  isPremiumlUser = false,
}) {
  return (
    <CardActionArea className=" !h-[72px] !rounded-[20px]">
      <div className="bg-black_2 h-[72px] rounded-[20px] flex items-center justify-start ps-5 gap-3 ">
        <div className="w-[18px] h-[18px] ">
          {isChecked && <GreenCheck className="w-full h-full " />}
        </div>

        <CustomImage
          appendBaseUrl={false}
          className="w-[90px] h-10"
          src={image}
        />
        {!isPremiumlUser && quality == 320 && (
          <div className="flex items-center justify-center  ">
            <CrownSvg />
            <p className="text-primary text-xs font-dana font-normal leading-6 ps-2 pt-2">
              مخصوص کاربران ویژه
            </p>
          </div>
        )}
      </div>
    </CardActionArea>
  );
}

export default memo(QualityCard);
