import { memo } from "react";

const HeadsetSvg = (props) => (
  <svg
    width={18}
    height={22}
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 21.2398C9.7812 21.2398 10.441 20.7366 10.6895 20.0398H12.6C15.3723 20.0398 17.6971 17.9324 18 15.2398V9.15977C17.689 4.47689 13.7604 0.759766 9 0.759766C4.23964 0.759766 0.311 4.47689 0 9.15977V13.4398C0 14.4339 0.805888 15.2398 1.8 15.2398C2.79411 15.2398 3.6 14.4339 3.6 13.4398V11.5294C3.6 10.2207 2.53909 9.15977 1.2304 9.15977C1.53876 5.13913 4.90172 1.95977 9 1.95977C13.0983 1.95977 16.4612 5.13913 16.7696 9.15977C15.4609 9.15977 14.4 10.2207 14.4 11.5294V12.9004C14.4 14.1924 15.4474 15.2398 16.7394 15.2398C16.4451 17.2698 14.7102 18.8398 12.6 18.8398H10.6895C10.441 18.1429 9.7812 17.6398 9 17.6398C8.00744 17.6398 7.2 18.4472 7.2 19.4398C7.2 20.4323 8.00744 21.2398 9 21.2398Z"
      fill="#F2F2F2"
    />
  </svg>
);
export default memo(HeadsetSvg);
