import React from "react";

function EarSvg(props) {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          fill="none"
          viewBox="0 0 21 20"
          {...props}
      >
        <g fill="#fff" clipPath="url(#clip0_44_10)">
          <path d="M8.328 0a6.844 6.844 0 00-6.836 6.836v9.258A3.91 3.91 0 005.398 20a3.91 3.91 0 003.907-3.906c0-.464.23-.895.616-1.152l1.407-.938a8.597 8.597 0 003.836-7.168A6.844 6.844 0 008.328 0zm2.35 13.029l-1.407.938a2.552 2.552 0 00-1.138 2.127 2.737 2.737 0 01-2.735 2.734 2.737 2.737 0 01-2.734-2.734V6.836a5.67 5.67 0 015.664-5.664 5.67 5.67 0 015.664 5.664 7.428 7.428 0 01-3.314 6.193z"></path>
          <path d="M8.917 3.167a3.724 3.724 0 00-2.989.855 3.71 3.71 0 00-1.293 2.81l-.03 2.415c-.006.582.225 1.15.635 1.56l.065.066c.18.18.277.416.275.666a.9.9 0 01-.276.644l-.023.022c-.41.397-.646.954-.646 1.527v.525c0 .97.789 1.758 1.758 1.758a.586.586 0 100-1.172.587.587 0 01-.586-.586v-.525c0-.257.105-.507.29-.686l.02-.02a2.088 2.088 0 00.016-2.982L6.07 9.98a.99.99 0 01-.292-.718l.02-1.533.01-.112a.978.978 0 011.953 0 .586.586 0 001.172 0 2.15 2.15 0 00-2.781-2.053c.14-.243.323-.465.542-.653a2.52 2.52 0 012.05-.584c1.344.203 2.274 1.509 2.117 2.973a4.303 4.303 0 01-1.897 3.129.586.586 0 10.65.975 5.472 5.472 0 002.413-3.979c.224-2.09-1.142-3.96-3.11-4.257zM19.121 3.935a.586.586 0 00-.828.829 2.933 2.933 0 010 4.143.586.586 0 00.828.829c1.6-1.6 1.6-4.202 0-5.8zM16.634 5.593a.586.586 0 000 .83.582.582 0 010 .828.586.586 0 00.83.828c.331-.332.514-.773.514-1.243 0-.47-.183-.91-.515-1.243a.586.586 0 00-.829 0z"></path>
        </g>
        <defs>
          <clipPath id="clip0_44_10">
            <path
                fill="#fff"
                d="M0 0H20V20H0z"
                transform="translate(.906)"
            ></path>
          </clipPath>
        </defs>
      </svg>
  );
}

export default EarSvg;
