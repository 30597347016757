import React from "react";

function ArtistSearchIcon() {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75737 2.64155C3.75737 1.53838 4.65166 0.644088 5.75483 0.644088H6.01898C6.97626 0.644088 7.75229 1.42011 7.75229 2.37739V4.75479V7.13218C7.75229 8.08946 6.97626 8.86548 6.01898 8.86548H5.75483C4.65166 8.86548 3.75737 7.97119 3.75737 6.86802V2.64155Z"
        stroke="#F2C94C"
        stroke-width="1.28818"
      />
      <path
        d="M10.5096 6.33887C10.5096 8.96486 8.38078 11.0937 5.75479 11.0937C3.12879 11.0937 1 8.96486 1 6.33887"
        stroke="#F2C94C"
        stroke-width="1.28818"
        stroke-linecap="round"
      />
      <path
        d="M5.88888 12.6807V14.0014M4.17188 14.0014H7.60589"
        stroke="#F2C94C"
        stroke-width="1.28818"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default ArtistSearchIcon;
