const CopyIconSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="#fff"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M8.665 13.333A3.337 3.337 0 0 0 12 10V4.162a2.65 2.65 0 0 0-.782-1.886L9.723.781A2.648 2.648 0 0 0 7.837 0H4.665a3.337 3.337 0 0 0-3.333 3.333V10a3.338 3.338 0 0 0 3.333 3.333h4Zm-6-3.333V3.333a2 2 0 0 1 2-2s3.28.01 3.334.016v1.318A1.333 1.333 0 0 0 9.332 4h1.317c.007.054.016 6 .016 6a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2Zm12-4.667v7.334A3.337 3.337 0 0 1 11.332 16h-6a.667.667 0 1 1 0-1.333h6a2 2 0 0 0 2-2V5.333a.667.667 0 0 1 1.333 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default CopyIconSvg;
