import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  checkSum,
  initialData,
  selectCheckSum,
  selectInitialData,
  setDeviceToken,
} from '../../redux/initialize/InitializeSlice';
import {
  login,
  selectUserToken,
  setUserDataFromLocalStorage,
  userData,
} from '../../redux/login/LoginSlice';
import {
  getDialogs,
  getSurveyInfo,
  getUserActivity,
  initializeRequest,
} from '../../services/ApiClient';
import MelodifyWebP from '../../assets/logo/splash-logo.webp';
import { APP_VERSION, APP_VERSION_NAME, BASE_URL } from '../../BASE.js';
import {
  setFollowingArtists,
  setFollowingCollections,
  setLikedTracks,
  showExpireSoonDialog,
  showSurveyDialog,
} from '../../redux/globalData/globalDataSlice.js';
import { setLimitation } from '../../redux/limitation/LimitationSlice';
import {
  setCustomDialog,
  setExpireDialog,
  setGiftDialog,
  setIntroductionDialog,
  setPremiumDialog,
} from '../../redux/dialog/DialogSlice';
import {
  getInitialData,
  globalData,
  useUserData,
} from '../../helper/ReduxSelectorContext';
import RetrySVG from '../svg/common/RetrySVG.jsx';
import {
  resetPlayer,
  resetRecentlyListen,
  resetWeightCount,
} from '../../redux/player/PlayMusicSlice';
import { toggleAdvertise } from '../../redux/player/AdvertiseSlice';
import { setUpkeepData } from '../../redux/upKeepSlice.js';

import { Helmet } from 'react-helmet';
import { useConvertDownload } from '../../hooks/useConvertDownload';
import { disableCountDown } from '../../redux/player/DropDownSlice.js';
import { disableSleepTimer } from '../../redux/player/PlayerSlice.js';
import { removeAllSearchHistoryItem } from '../../redux/search/SearchSlice.js';
import { appendBaseUrl } from '../../helper/utils.js';
import CustomImage from '../common/CustomImage';

function Splash() {
  const [error, setError] = useState(false);
  const [upkeep, setUpkeep] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checkSumSelector = useSelector(selectCheckSum);
  const initialDataSelector = useSelector(selectInitialData);
  const initialDataRedux = getInitialData();
  const token = useSelector(selectUserToken);
  const globalDataSelect = globalData();
  const showedSurvey = globalDataSelect?.showedSurvey;
  const newData = { ...initialDataSelector };
  const [convertData, setconvertData] = useState(false);
  const userDataStored = useUserData();
  const [startConverDownloads] = useConvertDownload();
  const queryClient = useQueryClient();
  useEffect(() => {
    dispatch(disableCountDown());
    dispatch(disableSleepTimer());
    dispatch(resetWeightCount());
    dispatch(toggleAdvertise({ advertise: false }));
  }, []);
  const dispatchToken = () => {
    const localAuth = JSON.parse(localStorage.getItem('userObject'));
    if (localAuth)
      dispatch(
        login({
          token: localAuth?.token,
        }),
      );
  };
  const dispatchDeviceToken = () => {
    const deviceToken = localStorage.getItem('device_token');
    if (deviceToken)
      dispatch(
        setDeviceToken({
          deviceToken: deviceToken,
        }),
      );
  };
  const dispatchUserDataFromLocal = () => {
    const userId = JSON.parse(localStorage.getItem('user'));
    if (userId) dispatch(setUserDataFromLocalStorage({ id: userId?.id }));
  };
  const converUserData = () => {
    dispatchToken();
    dispatchDeviceToken();
    dispatchUserDataFromLocal();
    setconvertData(true);
  };
  useEffect(() => {
    startConverDownloads();
  }, []);

  useEffect(() => {
    converUserData();
    if (convertData) 
    if (!token && !initialDataSelector ||!userDataStored?.is_registered) {
      // redirect user if token in null
      setTimeout(() => {
        navigate('/login/get-number', { replace: true });
      }, 2000);
    } else {
      initializeRequestMutation.mutate(checkSumSelector);
    }
  }, [token, convertData]);
  const getUserActivityMutation = useMutation(() => getUserActivity(), {
    onSuccess: res => {
      dispatch(
        setLikedTracks({ likedTracks: res?.data?.result?.liked_tracks }),
      );
      dispatch(
        setFollowingArtists({
          followedArtists: res?.data?.result?.following_artists,
        }),
      );
      dispatch(
        setFollowingCollections({
          collections: res?.data?.result?.following_collections,
        }),
      );
    },
  });
  const clearRequestCache = () => {
    queryClient?.clear();
  };
  const getCustomDialogsMutation = useMutation(() => getDialogs(), {
    onSuccess: res => {
      if (res?.data?.result?.custom_dialog) {
        dispatch(
          setCustomDialog({
            custom_dialog: res?.data?.result?.custom_dialog,
          }),
        );
      }
      if (res?.data?.result?.premium_dialog) {
        dispatch(
          setPremiumDialog({
            premium_dialog: res?.data?.result?.premium_dialog,
          }),
        );
        navigate('/premium-page');
      }
      if (res?.data?.result?.introduction_dialog) {
        dispatch(
          setIntroductionDialog({
            introduction_dialog: res?.data?.result?.introduction_dialog,
          }),
        );
      }
      if (res?.data?.result?.show_expire_dialog) {
        dispatch(
          setExpireDialog({
            expire_dialog: res?.data?.result?.show_expire_dialog,
          }),
        );
      }
      if (res?.data?.result?.gift_dialog) {
        dispatch(
          setGiftDialog({
            gift_dialog: res?.data?.result?.gift_dialog,
          }),
        );
        navigate('/manage-gift');
      }
    },
  });
  const handleAccountThreshold = () => {
    const premiumDays = newData?.user?.premium_days_left;
    const thresholdDays = newData?.expired_dialog_threshold;
    console.log(premiumDays, thresholdDays, 'logged in');
    if (premiumDays === 0) return;
    if (thresholdDays >= premiumDays) {
      dispatch(showExpireSoonDialog());
    }
  };
  const getSurveyInfoMutation = useMutation(() => getSurveyInfo(), {
    onSuccess: response => {
      if (response?.data?.result?.show_dialog) {
        dispatch(showSurveyDialog());
      }
    },
  });
  const initializeRequestMutation = useMutation(
    checkSumSelector => initializeRequest(checkSumSelector),
    {
      onSuccess: res => {
        if (res?.data?.done) {
          dispatch(resetPlayer());
          clearRequestCache();
          if (
            res?.data?.result?.user?.is_premium !== userDataStored?.is_premium
          ) {
            dispatch(removeAllSearchHistoryItem());
            dispatch(resetRecentlyListen());
          }
          if (
            showedSurvey === null &&
            res?.data?.result?.should_check_survey_info
          ) {
            getSurveyInfoMutation.mutate();
          }
          if (res?.data?.result?.limitations)
            storeLimitations(res?.data?.result?.limitations);
          getUserActivityMutation.mutate();
          dispatch(setUpkeepData({ data: res?.data?.result?.upkeep }));

          // up keep functions
          //   setUpkeep(res?.data?.result?.system_status?.upkeep);
          //   !!res?.data?.result?.system_status?.upkeep &&
          //     dispatch(
          //       setUpKeepDialog({
          //         upKeep_dialog: res?.data?.result?.system_status?.upkeep,
          //       })
          //     );

          // store checksum
          if (checkSumSelector == null) {
            // store data in redux  first time
            dispatchData(res?.data?.result);
            dispatchCheckSum(res?.data?.result?.checksum);
          } else {
            // handle null data and store new data
            Object.keys(res?.data?.result).map(item => {
              if (res?.data?.result[item] != null) {
                newData[item] = res?.data?.result[item];
              } else {
                newData[item] = initialDataSelector[item];
              }
            });
            //store new data in redux
            dispatchData(newData);
            dispatchCheckSum(res?.data?.result?.checksum);
          }

          //   upkeep !== null &&
          navigate('/home', { replace: true });
          localStorage.setItem('homeR', 0);
          setError(false);
        }

        getCustomDialogsMutation.mutate();
      },
      onError: () => {
        setError(true);
      },
    },
  );

  // store checksum in redux
  const dispatchCheckSum = data => {
    let FormatedChecksum = {};
    Object?.entries(data)?.map(item => {
      FormatedChecksum[item?.[0] + '_checksum'] = item?.[1];
    });
    dispatch(
      checkSum({
        checkSum: FormatedChecksum,
      }),
    );
  };

  // store initialize data in redux
  const dispatchData = data => {
    if (data?.device_token !== null) {
      dispatch(setDeviceToken({ deviceToken: data?.device_token }));
    }
    dispatch(
      initialData({
        initialData: data,
      }),
    );
    let mergeUserData = { ...userDataStored, ...data?.user };
    dispatchUserData(mergeUserData);
    handleAccountThreshold();
    if (data?.search_history_titles) changeSearchHistoryArryToObject(data);
  };
  const dispatchUserData = Data => {
    dispatch(
      userData({
        user: Data,
      }),
    );
  };

  const changeSearchHistoryArryToObject = data => {
    let newSearchObject = {};
    Object.values(data?.search_history_titles).map(item => {
      newSearchObject[item?.model_type] = item;
    });

    dispatch(
      initialData({
        initialData: { ...data, search_history_titles: newSearchObject },
      }),
    );
  };

  // getDeviceTokenFromGlobalStore()
  const splashBG = useMemo(
    () => ({
      backgroundImage: `linear-gradient(192.31deg, rgba(242, 201, 76, 0.85) 0.89%, rgba(237, 170, 40, 0.85) 100.37%), url("${appendBaseUrl(
        initialDataRedux?.splash_background_image,
      )}")`,
    }),
    [initialDataRedux?.splash_background_image],
  );
  const storeLimitations = data => {
    let reformatData = {};
    data?.map(item => {
      reformatData = {
        ...reformatData,
        [item.limitation_key]: item.limitation_data,
      };
    });
    dispatch(setLimitation({ limitation: reformatData }));
  };
  const handleClickRetry = () => {
    initializeRequestMutation.mutate(checkSumSelector);
  };
  return (
    <div className="w-full h-screen  bg-splash max-w-container ">
      <Helmet>
        <meta name="theme-color" content="#f2c94c" />
      </Helmet>
      <div className="w-full h-full flex flex-row relative">
        <div
          className={`w-full h-full absolute transition-all ease-in-out bg-cover bg-no-repeat bg-[top_center]  splash-scale`}
          style={splashBG}
        ></div>
        <div className="absolute flex flex-col w-full h-screen justify-center items-center -mt-14">
          <CustomImage
            appendBaseUrl={false}
            className="w-1/5 mb-1 min-w-[100px]"
            src={MelodifyWebP}
          />
          <span className="text-[1.6em] font-kalameh font-[700] text-black_2">
            ملودیفای
          </span>
        </div>
        <div className="absolute flex flex-col w-full  bottom-[60px]">
          <div>
            {error && (
              <div
                onClick={handleClickRetry}
                className=" flex items-center w-1/2 mx-auto gap-2 cursor-pointer text-primary text-center leading-none text-[16px] z-50 bg-yellow justify-center rounded-[20px] border border-transparent bg-blue-100  py-1 focus:outline-none"
              >
                <div
                  className={`aspect-square w-[20px]
                ${initializeRequestMutation?.isLoading && 'animate-spin'} `}
                >
                  <RetrySVG fill={'#000'} />
                </div>
              </div>
            )}
            {error && (
              <span className=" flex items-center w-full mx-auto gap-2 cursor-pointer font-dana text-center leading-none text-[16px]  mb-8 z-50 text-black_2 justify-center rounded-[20px] border border-transparent bg-blue-100  py-1 focus:outline-none">
                خطا در ارتباط با سرور
              </span>
            )}
            <span className="splash_version flex justify-center items-center w-full mx-auto font-dana text-base font-semibold drop-shadow drop-shadow-xl">
              {APP_VERSION_NAME} نسخه
              {BASE_URL.includes('debug') ? 'debug' : ''}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Splash;
