import React from 'react';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';

import { useNavigate } from 'react-router-dom';
import { useAction } from '../../hooks/useAction.js';
import PlaceHolderWithTitle from '../../components/common/PlaceHolderWithTitle.jsx';
import { CardActionArea } from '@mui/material';
import ClickReaction from '../../helper/ClickReaction';

function CollectionCardItem({ item }) {
  const action = useAction();

  const navigate = useNavigate();
  const handleSelectItem = item => {
    action({ target_type: 'collection_category', children: item });
  };
  return (
    <div
      onClick={() => {
        handleSelectItem(item);
      }}
      className=" rounded-[10px]  "
    >
      <ClickReaction>
        <CardActionArea className=" !rounded-[10px]">
          <div className="aspect-square  rounded-[10px]  flex items-center justify-center relative overflow-hidden  xl:min-h-[11vw]">
            <CustomLazyLoadImage image={item?.image} />
            <PlaceHolderWithTitle
              style={'xl:min-h-[11vw]'}
              title={item?.title}
            />
          </div>
        </CardActionArea>
      </ClickReaction>
    </div>
  );
}

export default CollectionCardItem;
