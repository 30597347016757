import React, { useEffect, useRef, useState } from "react";
import ChevronSVG from "../../components/svg/common/ChevronSVG";
import { useSelector } from "react-redux";
import { selectCategory } from "../../redux/support/SelectedCategorySlice.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { addNewTicket, sendCompleteRequest } from "../../services/ApiClient.js";
import { ADD_NEW_TICKET_VALIDATION_SCHEMA } from "../../consts/Validation.js";
import UploadFile from "../../components/support/UploadFile";
import Loading from "../../components/loading/Loading.jsx";
import SelectCategoryBottomSheet from "../../components/support/SelectCategoryBottomSheet.jsx";
import CommonHeader from "../../components/common/CommonHeader.jsx";
import Container from "../../components/PageParentContainer/Container.jsx";
import TicketsHeader from "../../components/Tickets/TicketsHeader.jsx";
import { CardActionArea } from "@mui/material";
import AttachmentSvg from "../../components/svg/support/AttachmentSvg";
import { mainToast } from "../../helper/ToastsEmitter";
import { getHasErrorApi } from "../../redux/UploadFile/UploadFileSlice";
import {selectFaqs} from "../../redux/support/SupportSlice.js";

let replyId = null;

function CreateTicket() {
  const activeCategory = useSelector(selectCategory);
  const hasErrorUploadApi = useSelector(getHasErrorApi);
  const faqs = useSelector(selectFaqs);
  const [openCategory, setOpenCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subCategoryData, setSubCategoryData] = useState({});
  const [UploadFileIsOpen, setUploadFileIsOpen] = useState(false);
  const [HasUploadError, setHasUploadError] = useState(false);
  const [TicketReplyId, setTicketReplyId] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const uploadFileRef = useRef(null);
  // add formik for handle form submit and check validation
  const formik = useFormik({
    initialValues: {
      faq_id: subCategoryData?.id ? subCategoryData?.id : null,
      category_faq_id: activeCategory?.id,
      subject: subCategoryData?.question ? subCategoryData?.question : "",
      description: "",
    },
    enableReinitialize: true,
    validationSchema: ADD_NEW_TICKET_VALIDATION_SCHEMA,
    validateOnChange: false,
    onSubmit: (data) => {
      if (loading) {
        return;
      }
      if (!uploadFileRef.current.isValid())
        mainToast("تعدادی از فایل ها غیر مجاز است");
      else {
        setLoading(true);
        if (!HasUploadError) addNewTicketMutation.mutate(data);
        else uploadFiles(TicketReplyId);
      }
    },
  });
  // send request for add ticket
  const addNewTicketMutation = useMutation((data) => addNewTicket(data), {
    onSuccess: (res) => {
      setTicketReplyId(res?.data?.result?.ticket_reply?.id);
      replyId = res?.data?.result?.ticket_reply?.id;
      uploadFiles(res?.data?.result?.ticket_reply?.id);
    },
  });
  // start upload of file with ref
  const uploadFiles = (replyID) => {
    uploadFileRef.current.uploadFiles(replyID);
  };
  // create instance: send request for complete ticket creation
  const sendCompleteRequestMutation = useMutation(
    (replyId) => sendCompleteRequest(replyId),
    {
      onSuccess: (res) => {
        setLoading(false);
        handleRouteToShowMessage(res?.data?.result?.ticket_reply?.ticket);
        formik.resetForm();
        uploadFileRef.current.resetData();
      },
      onError: (error) => {
        setLoading(false);
        console.log("errorrrrrrrrrrrrrrrr");
      },
    }
  );
  // call mute of complete ticket request
  const CompleteRequestEvent = (isValid) => {
    // setHasUploadError(!isValid);
    // console.log("befor",hasErrorUploadApi,HasUploadError)
    if (isValid) sendCompleteRequestMutation.mutate(replyId);
    else setLoading(false);
  };
  // handle route after ticket completion done and pass ticket data to show message route
  const handleRouteToShowMessage = (ticket) => {
    ticket.is_new = true;
    navigate("/support/show-messages", { state: ticket });
  };
  const closeCategoryModal = () => {
    setOpenCategory(false);
  };
  const openCategoryModal = () => {
    if(faqs?.length===0){
      return
    }
    setOpenCategory(true);
  };
  useEffect(() => {
    setSubCategoryData(location?.state?.item);
  }, [location?.state]);

  useEffect(() => {
    setHasUploadError(hasErrorUploadApi);
  }, [hasErrorUploadApi]);

  return (
    <>
      <Container header={<CommonHeader title={"ثبت تیکت"} />}>
        <div dir="rtl" className="w-full h-full  font-dana">
          <TicketsHeader />
          <SelectCategoryBottomSheet
            open={openCategory}
            setOpen={setOpenCategory}
            // openDialog={openCategoryModal}
            // closeDialog={closeCategoryModal}
          />
          <div className="w-full flex flex-col justify-center items-center ">
            <form
              onSubmit={formik.handleSubmit}
              className="w-full h-full flex flex-col  my-6 px-4 hide-scroll-bar"
            >
              <div className="relative w-full h-full">
                {loading && (
                  <div className="z-50 h-full w-full bg-secondary bg-opacity-60 absolute flex justify-center items-center top-0 "></div>
                )}
                <>
                  {subCategoryData?.ticket_title && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: subCategoryData?.ticket_title,
                      }}
                      className="text-white text-base font-kalameh font-[500]  mb-4 "
                    ></p>
                  )}
                  {subCategoryData?.ticket_description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: subCategoryData?.ticket_description,
                      }}
                      className="text-white text-sm mt-3 font-dana font-[500]  mb-12 "
                    ></div>
                  )}
                  <p className="text-gray_4 text-xs mb-0.5">دسته&zwnj;بندی:</p>
                  <div
                    onClick={openCategoryModal}
                    className={`cursor-pointer w-full flex justify-between items-center flex-row border border-gray_4 rounded-[5px] px-4 min-h-[48px] ${
                      loading
                        ? "pointer-events-none opacity-75 cursor-auto"
                        : ""
                    }`}
                  >
                    <div className="flex flex-row items-center">
                      <span className="text-white text-xs font-[500]">
                        {activeCategory?.title}
                      </span>
                    </div>
                    <div className="rotate-[270deg]">
                      <ChevronSVG classList={"w-2"} fill={"#F2C94C"} />
                    </div>
                  </div>
                  <p className="text-gray_4 text-xs mt-4 mb-0.5">عنوان:</p>
                  <div
                    className={`w-full flex justify-between items-center flex-row border border-1 border-gray_4 bg-transparent rounded-[5px] px-4 min-h-[48px] ${
                      formik.errors.subject && "border !border-1 !border-red"
                    } ${
                      loading
                        ? "pointer-events-none opacity-75 cursor-auto"
                        : ""
                    }`}
                  >
                    <input
                      disabled={loading}
                      autoComplete="off"
                      id="subject"
                      onChange={formik.handleChange}
                      value={formik.values.subject}
                      className={`text-white text-xs font-[500] w-full bg-transparent focus:outline-none `}
                    />
                  </div>
                  {formik?.errors?.subject && (
                    <p className={`text-gray_4 text-sm mt-4 mb-1`}>
                      {formik?.errors?.subject}
                    </p>
                  )}
                  <p className="text-gray_4 text-xs mt-4 mb-0.5">توضیحات:</p>
                  <textarea
                    disabled={loading}
                    id="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    className={`h-[168px] w-full text-xs text border border-1 border-gray_4 bg-transparent rounded-[5px] resize-none text-gray_6 px-4 focus:outline-none p-4 ${
                      formik.errors.description &&
                      "border !border-1 !border-red "
                    } ${
                      loading
                        ? "pointer-events-none opacity-75  cursor-auto"
                        : ""
                    }`}
                  />
                  {formik?.errors?.description && (
                    <p className="text-[14px] text-red mt-2">
                      {formik?.errors?.description}
                    </p>
                  )}
                </>
              </div>
              <UploadFile
                loading={loading}
                ref={uploadFileRef}
                sendCompleteRequest={CompleteRequestEvent}
                setIsOpen={setUploadFileIsOpen}
                isOpen={UploadFileIsOpen}
              />
              <CardActionArea
                disabled={loading}
                className={"!rounded-full !mt-4 !w-[150px]"}
              >
                <>
                  {loading && (
                    <div className="z-50 h-full w-full mx-auto bg-secondary bg-opacity-60 absolute flex justify-center items-center top-0 rounded-full"></div>
                  )}
                  <div
                    onClick={() => setUploadFileIsOpen(true)}
                    className={`flex cursor-pointer items-center justify-evenly bg-transparent border border-primary rounded-full py-3 w-[150px] focus:outline-none ${
                      loading
                        ? "pointer-events-none opacity-75 cursor-auto"
                        : ""
                    }`}
                  >
                    <div className="h-[20px] w-5">
                      <AttachmentSvg />
                    </div>
                    <p className="text-base text-primary">افزودن فایل</p>
                  </div>
                </>
              </CardActionArea>

              <CardActionArea
                className={"!h-[48px] !rounded-full !overflow-hidden !mt-6"}
                disabled={loading}
              >
                <button
                  disabled={loading}
                  type="submit"
                  className="w-full bg-primary text-black_2 font-medium flex items-center justify-center text-base rounded-full h-[48px]"
                >
                  {loading ? (
                    <div>
                      <Loading bgColor={"#303030"} />
                    </div>
                  ) : (
                    <span>{HasUploadError ? "تلاش دوباره" : "ارسال"}</span>
                  )}
                </button>
              </CardActionArea>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
}

export default CreateTicket;
