import React from 'react';
import CustomLazyLoadImage from '../../../components/common/CustomLazyLoadImage';

function PlaylistAndSongSearchResultAvatar({ image }) {
  return (
    <div className="w-full h-full rounded-[5px] overflow-hidden">
      <CustomLazyLoadImage
        image={image}
        imageClass="object-cover rounded-[5px] h-full w-full"
      />
    </div>
  );
}

export default PlaylistAndSongSearchResultAvatar;
