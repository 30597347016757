import { memo } from "react";

const UserLikedSvg = (props) => (
  <svg
    width={88}
    height={88}
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47.072 46.151a4.995 4.995 0 0 0-.034-.56V27.782a2.751 2.751 0 0 0-3.299-2.697l-12.591 2.55a2.761 2.761 0 0 0-2.205 2.698v9.371a4.824 4.824 0 1 0 2.315 4.119v-7.118l13.464-2.728v8.035a4.822 4.822 0 0 0-6.67 6.52 4.824 4.824 0 0 0 9.019-2.38Zm-20.64.182a2.51 2.51 0 1 1-.001-5.018 2.51 2.51 0 0 1 .002 5.018Zm4.826-11.99v-4.01a.44.44 0 0 1 .35-.429l12.591-2.55a.435.435 0 0 1 .524.428v3.833l-13.465 2.728Zm8.479 11.808a2.508 2.508 0 0 1 4.986-.39v.091h.014a2.51 2.51 0 1 1-5 .3Z"
      fill="#4F4F4F"
    />
    <path
      d="M82.383 61.217c-1.228-6.089-5.642-10.023-11.245-10.023-2.467 0-5.098.8-7.706 2.33l-.151-32.629a1.158 1.158 0 0 0-.344-.817L43.003.375l-.015-.012a1.152 1.152 0 0 0-.84-.363h-.006c-.059 0-.117.006-.174.015l-.084-.008a.988.988 0 0 0-.116-.005L14.209.134a9.01 9.01 0 0 0-8.966 9.054l.278 57.853a9.02 9.02 0 0 0 9.01 8.967h.043l30.883-.156c3.096 4.64 8.257 8.948 15.87 11.97l.06.021a2.385 2.385 0 0 0 1.769-.025c15.62-6.328 20.913-17.915 19.229-26.592l-.002-.01ZM59.361 19.799l-9.27.045h-.034a6.703 6.703 0 0 1-6.694-6.664l-.044-9.237L59.36 19.8ZM14.563 73.692a6.704 6.704 0 0 1-6.727-6.663L7.558 9.177a6.695 6.695 0 0 1 6.663-6.728l26.774-.128.053 10.87a9.02 9.02 0 0 0 9.01 8.966h.043l10.87-.052.146 31.426c-2.647-1.616-5.272-2.434-7.828-2.434-5.636 0-10.032 3.977-11.199 10.13-.728 3.795-.148 8.123 2.008 12.316l-29.535.149Zm47.763 11.964a.71.71 0 0 1-.088.023.942.942 0 0 1-.092-.024C47.779 79.94 42.862 69.49 44.364 61.66c.965-5.089 4.385-8.249 8.925-8.249 2.628 0 5.406 1.078 8.256 3.204.406.303.962.307 1.372.01 2.81-2.04 5.652-3.117 8.22-3.117 4.52 0 7.958 3.127 8.976 8.161 2.081 10.734-7.428 19.776-17.787 23.986Z"
      fill="#4F4F4F"
    />
  </svg>
);
export default memo(UserLikedSvg);
