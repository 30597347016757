import { memo } from "react";

function SettingIconSvg(rest) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="#F2F2F2"
      {...rest}
    >
      <path d="M14.239 2c.832 0 1.585.462 2 1.144.203.33.338.737.305 1.166-.023.33.078.66.258.968.573.935 1.844 1.287 2.833.759a2.324 2.324 0 013.159.836l.753 1.298a2.23 2.23 0 01-.832 3.113c-.956.561-1.293 1.804-.72 2.75.18.297.383.55.698.704.393.209.697.539.91.869.416.682.382 1.518-.022 2.255l-.787 1.32a2.343 2.343 0 01-1.99 1.144c-.393 0-.832-.11-1.192-.33-.292-.187-.63-.253-.989-.253-1.113 0-2.046.913-2.08 2.002 0 1.265-1.034 2.255-2.326 2.255h-1.53c-1.303 0-2.338-.99-2.338-2.255-.022-1.089-.955-2.002-2.068-2.002-.371 0-.708.066-.99.253-.36.22-.809.33-1.19.33-.81 0-1.586-.44-2.002-1.144l-.776-1.32c-.416-.715-.438-1.573-.022-2.255.18-.33.517-.66.9-.869.314-.154.516-.407.707-.704.563-.946.225-2.189-.73-2.75a2.248 2.248 0 01-.832-3.113l.753-1.298a2.337 2.337 0 013.17-.836c.978.528 2.249.176 2.822-.759.18-.308.28-.638.258-.968a1.964 1.964 0 01.315-1.166A2.414 2.414 0 0112.654 2h1.585zm-.776 7.898c-1.765 0-3.192 1.386-3.192 3.113s1.427 3.102 3.192 3.102c1.765 0 3.16-1.375 3.16-3.102 0-1.727-1.395-3.113-3.16-3.113z"></path>
    </svg>
  );
}

export default memo(SettingIconSvg);
