import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/common/PrimaryButton.jsx";
import Loading from "../../components/loading/Loading.jsx";
import LoginHeaderBackgroundImage from "../../components/login/LoginHeaderBackgroundImage.jsx";
import AlertSvg from "../../components/lyrics/svg/AlertSvg.jsx";
import MelodifySvgNewLogo from "../../components/lyrics/svg/MelodifySvgNewLogo.jsx";
import getNumberHeader from "../../assets/login/login-header.webp";
import {
  checkPhoneWhitRegex,
  fixedPhone,
} from "../../helper/NumberFormatter.js";
import { mainToast } from "../../helper/ToastsEmitter.jsx";
import { getOtp } from "../../services/ApiClient.js";
import { CardActionArea } from "@mui/material";
import SwipeBottomSheet from "../../components/bottomSheets/SwipeBottomSheet.jsx";
import BottomSheetHeader from "../../components/bottomSheets/BottomSheetHeader.jsx";
import SingleAbout from "../aboutUs/SingleAbout.jsx";
import { AboutUsItems } from "../../consts/AboutUs.jsx";

const pageData = AboutUsItems.find((item) => item.key === "about-terms");
function GetNumber(props) {
  const [showError, setShowError] = useState(false);
  const [emptyNumber, setEmptyNumber] = useState(false);
  const [falseNumber, setFalseNumber] = useState();
  const [error, setError] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();

  const [DialogOpen, setDialogOpen] = useState(false);

  // const [message,setMessage]=useState()
  const mutateOtp = useMutation((phone) => getOtp(phone), {
    onSuccess: (res) => {
      if (res?.data?.done) {
        mainToast("کد تایید ارسال شد");
        navigate("/login/verify-code", {
          replace: true,
          state: { phone: fixedPhone(formik.values.phone.toEnglishDigits()) },
        });
      } else {
        if (res?.data?.error?.message === 'please try again later') {
          setError(
            'تعداد درخواست های ورود شما بیش از حد مجاز است \n لطفا چند دقیقه بعد امتحان کنید',
          );
        }
      }
    },
    onError: (err) => {
      console.log(err);
      setError("خطایی پیش آمده");
    },
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
    },

    onSubmit: (values) => {
      handleSendPhoneNumber(values.phone.toEnglishDigits());
    },
  });

  // send phone number to web service
  const handleSendPhoneNumber = (phone) => {
    setError("")
    formatPhone(phone) && mutateOtp.mutate({ phone: formatPhone(phone) });
  };

  const formatPhone = (phone) => {
    // check number validation
    if (checkPhoneWhitRegex(phone)) {
      setEmptyNumber(false);
      setFalseNumber(false);
      return fixedPhone(phone);
    } else if (!phone) {
      setEmptyNumber(true);
      setFalseNumber(false);
    } else {
      setEmptyNumber(false);
      setFalseNumber(true);
    }
  };

  //   get phone number if come from edit number
  useEffect(() => {
    if (state?.phone !== undefined) {
      formik.setFieldValue("phone", state?.phone);
    }
  }, []);
  useEffect(() => {
    localStorage.getItem("message") && setShowError(true);
    return () => localStorage.removeItem("message");
  }, []);
  const handleNavigateTerms = () => {
    // navigate("/about-us/about-terms");
    setDialogOpen(true);
  };
  const toggleBugReportBottomSheet = (newOpen) => () => {
    setDialogOpen(newOpen);
  };
  const closeBottomSheet = () => {
    setDialogOpen(false);
  };

  return (
    <div className=" bg-[#212121] text-white   w-screen h-screen max-w-[520px]">
      <div>
        <header>
          <LoginHeaderBackgroundImage loginHeader={getNumberHeader}>
            <div className=" h-[220px]  flex justify-center items-end">
              {!showError && (
                <div className={"h-[5.125rem]"}>
                  <MelodifySvgNewLogo height={"h-[5.125rem]"} opacity={false} />
                </div>
              )}
            </div>
          </LoginHeaderBackgroundImage>
        </header>
        <main
          className={`px-[1.187rem] relative z-30  ${
            showError && "bottom-[4.5rem]"
          }`}
        >
          <div dir="rtl" className=" mt-3">
            {showError ? (
              <div
                dir="rtl"
                className=" mx-auto z-50 px-3 border-[.125rem] border-red rounded-[.938rem]  flex-col flex items-start justify-center gap-[.5rem]   h-[8rem] "
              >
                <AlertSvg />
                <div className="  text-white font-semibold font-dana text-[.9rem] leading-6 break-keep">
                  {JSON.parse(localStorage.getItem("message"))}
                </div>
              </div>
            ) : (
              <div>
                <span className="font-dana font-[700] ">ملودیفای</span>
                <p className="font-dana font-[400] text-sm mt-1">
                  یکبار بپیوندید و برای همیشه لذت ببرید ...
                </p>
              </div>
            )}
          </div>
          <div className=" flex flex-col justify-center items-center">
            <div className="mt-[.8125rem]  px-[1rem] py-[.75rem]  h-12 justify-center  items-center  flex w-full  border border-gray-4 rounded-[1.3rem]  overflow-hidden text-white border-gray-1 ">
              <div className="font-dana h-full font-[500] leading-7 mr-[.5rem] text-base">
                +98
              </div>
              <span className="h-[2.06rem] border bg-gray_3 border-gray_3 w-px"></span>

              <input
                onKeyPress={(e) => e.key === "Enter" && formik.handleSubmit()}
                autoComplete="off"
                type="tel"
                placeholder="شماره تلفن خود را وارد کنید"
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                maxLength="13"
                className="h-full text-white font-dana  font-[500] text-base grow bg-transparent outline-none mx-[.5rem] placeholder-gray_4"
              />
            </div>
            <div>
              {emptyNumber && (
                <p className="text-red text-[.875rem] font-dana  font-[400] mt-2  ">
                  شماره تلفن را وارد کنید
                </p>
              )}
              {falseNumber && (
                <div>
                  <p className="text-red text-[.875rem] font-dana  font-[400] mt-2 ">
                    . فرمت شماره موبایل اشتباه است
                  </p>
                </div>
              )}
              {error && (
                <div>
                  <p dir="rtl" dangerouslySetInnerHTML={{__html:error}} className="text-red text-[.875rem] font-dana  font-[400] mt-2 ">
                    
                  </p>
                </div>
              )}
            </div>
            <CardActionArea className={"!mt-2 !rounded-[1.3rem]"}>
              <PrimaryButton
                style="!text-[#333333] !font-dana !text-[1.04rem] !font-[510] h-[2.87rem] !w-full  !bg-primary !rounded-[1.3rem] !m-auto "
                onClick={() => {
                  formik.handleSubmit();
                }}
                type="button"
                disabled={mutateOtp.isLoading}
              >
                {mutateOtp.isLoading ? (
                  <div>
                    <Loading bgColor={"#000000"} />
                  </div>
                ) : showError ? (
                  "بعدی"
                ) : (
                  "تایید"
                )}
              </PrimaryButton>
            </CardActionArea>
            {!showError && (
              <div
                onClick={handleNavigateTerms}
                className="mt-4 w-full flex justify-center items-center "
              >
                <p
                  dir="rtl"
                  className="text-[.8rem] font-dana text-center font-[500]"
                >
                  با ورود یا ثبت نام،
                  <a className="text-primary  px-0.5 font-semibold  underline underline-offset-4">
                    قوانین و شرایط استفاده
                  </a>
                  <span></span> ملودیفای را می پذیرم.
                </p>
              </div>
            )}
          </div>
        </main>
      </div>
      <SwipeBottomSheet
        open={DialogOpen}
        toggleDrawer={toggleBugReportBottomSheet}
      >
        <BottomSheetHeader
          HeaderIcon={pageData.icon}
          dividerLine={true}
          closeBottomSheet={closeBottomSheet}
          title={pageData?.title}
        />
        <SingleAbout itemKey={pageData?.key} />
      </SwipeBottomSheet>
    </div>
  );
}

export default GetNumber;
