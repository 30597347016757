import { memo } from "react";
const AboutSvg = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 19a9 9 0 1 1 0-18 9 9 0 0 1 0 18Zm.07-13.049v4.95M10 14.506h-.009"
      stroke="#E0E0E0"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default memo(AboutSvg) ;
