import React from 'react';

function ClockSvg({fill="#B0B0B5"}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-full h-full"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill={fill}
                d="M13.57 11.766l-2.788-2.091v-4.26a.774.774 0 10-1.55 0v4.647c0 .244.115.474.31.62l3.099 2.324a.77.77 0 001.084-.156.773.773 0 00-.155-1.084z"
            ></path>
            <path
                fill={fill}
                d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18.45c-4.66 0-8.45-3.79-8.45-8.45 0-4.66 3.79-8.45 8.45-8.45 4.66 0 8.45 3.79 8.45 8.45 0 4.66-3.79 8.45-8.45 8.45z"
            ></path>
        </svg>
    );
}

export default ClockSvg;
