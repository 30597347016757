import React, { useCallback, useEffect, useState } from "react";

import OtpInput from "react-otp-persian";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import PrimaryButton from "../../components/common/PrimaryButton.jsx";
import Loading from "../../components/loading/Loading.jsx";
import LoginHeaderBackgroundImage from "../../components/login/LoginHeaderBackgroundImage.jsx";
import { getOtp, verifyCode } from "../../services/ApiClient.js";
import { login, userData } from "../../redux/login/LoginSlice.js";
import {mainToast} from "../../helper/ToastsEmitter.jsx";
import EditButtonSvg from "../../components/svg/common/EditButtonSvg.jsx";
import ResendOtpSVG from "../../components/svg/login/ResendOtpSVG.jsx";

import getVerifyHeader from "../../assets/login/verify_sms_background.webp"
import {CardActionArea} from "@mui/material";


function GetOtp(props) {
  const [error, setError] = useState(false);
  const [otpValue, setOtpValue] = useState(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(120);
  const [loading, setLoading] = useState(false);
  const handleOnChange = (value) => {
    setOtpValue(value);
  };

  const mutateVerifyCode = useMutation((verifyData) => verifyCode(verifyData), {
    onSuccess: (res) => {
      //dispatch token when response true
      if (res?.data?.done) {
        //login user in verified
        dispatchToken(res?.data?.result?.token);
        dispatchUserData(res?.data?.result?.user);
        if (res?.data?.result?.verified) {
          if (res?.data?.result?.user?.is_registered) {
            navigate("/", { replace: true });
          } else {
            //register user in not verified
            navigate("/login/register", { replace: true, state: { FromOtp: true } });
          }
        }
      }
      if(res?.data?.error?.message.includes('not correct'))
      {
        setError('! کد تایید معتبر نیست');
      }
      else {
        setError('خطایی پیش آمده')
      }
    },
    onError:(err) => {
      console.log(err)
      setError('خطایی پیش آمده')
    }
  });

  const goToEditNumber = () => {
    navigate("/login/get-number", { replace: true, state });
  };

  const handleVerifyCode = () => {
    if(!otpValue || otpValue?.length < 4) {
      mainToast("ارقام کد تایید کم است");
    } else {
      mutateVerifyCode.mutate({ phone: state?.phone, code: otpValue });
    }

  };

  const dispatchToken = (token) => {
    dispatch(
      login({
        token: token,
      })
    );
  };

  const dispatchUserData = (Data) => {
    dispatch(
      userData({
        user: Data,
      })
    );
  };

  const resetOTpTimer = () => {
    if (loading) return;
    if (timer <= 0) {
      // call api for resend otp
      setLoading(true);
      setTimeout(() => {
        getOtp({ phone: state?.phone }).then((res) => {
          setOtpValue(null)
          setTimer(120);
          setLoading(false);
        });
      },1000)
    }
  };
  // create resend timeout
  const otpTimeOut = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  //handle resend otp
  useEffect(() => {
    if (timer > 0) {
      setTimeout(otpTimeOut, 1000);
    } else {
      clearTimeout(otpTimeOut);
    }
  }, [timer, otpTimeOut]);

  const handleSubmitWithEnter = (event) => {
    if (event.key === "Enter") {
      handleVerifyCode();
    }
  };

  let minutes = Math.floor(timer / 60);
  let seconds = timer - minutes * 60;

  return (
    <div className=" hide-scroll-bar text-white  w-full h-screen overflow-scroll bg-[#212121] ">
      <div >
        <header>
          <LoginHeaderBackgroundImage loginHeader={getVerifyHeader}>
            <div className="h-[220px] px-[.875rem]  flex justify-end items-end ">
              <div dir="rtl" >
                <p className="font-[700] font-dana " >
                  کد تایید
                </p>
                <p className="font-dana font-[400] text-[.870rem]">
                  کد تایید ارسال شده را وارد کنید.
                </p>
              </div>
            </div>
          </LoginHeaderBackgroundImage>
        </header>
        <main>
          <div className="px-[.875rem] mt-2 relative z-20">
            <div >
              <div className="flex justify-between mt-[.5rem] items-center">
                <div className="text-white text-[.9rem] font-dana font-[400]">
                  {state?.phone && state?.phone}
                </div>
                <div className="flex text-primary gap-[.706rem] items-center justify-start" >
                  <EditButtonSvg />
                  <div
                    className="font-dana font-[600]  "
                    onClick={() => goToEditNumber()}>
                    ویرایش شماره
                  </div>
                </div>
              </div>

              <div
                className="flex w-[13.5rem] flex-col items-stretch justify-center m-auto "
                onKeyPress={(e) => e.key === "Enter" && handleSubmitWithEnter(e)}>
                <OtpInput
                  containerStyle="gap-2.5"
                  shouldAutoFocus={true}
                  type="tel"
                  value={otpValue}
                  inputStyle=" !w-[2.875rem] !h-[2.875rem] mt-[1.68rem] outline-none bg-transparent  border-gray_4 rounded-2xl border-[0.063rem] text-center text-white relative justify-center flex font-dana font-[500]   "
                  onChange={handleOnChange}
                  numInputs={4}
                  separator=""
                  isInputNum={true}
                />
                <div className=" w-full flex justify-end">
                  {error && (
                    <div className="dir-rtl text-red basis-full text-center text-[.875rem] font-dana  font-[400] mt-2">
                      { error }
                    </div>
                  )}
                </div>
                <CardActionArea className={`!rounded-[1.3rem] ${!error ? '!mt-4' : '!mt-2'} ${!mutateVerifyCode.isLoading ? 'pointer-events-auto' : 'pointer-events-none'}`}>
                  <PrimaryButton
                      className=" !h-[2.875rem]  !font-dana !text-[1.04rem] !font-semibold !w-full !text-[#333333] !tracking-normal   !bg-primary !rounded-[1.3rem]"
                      onClick={handleVerifyCode}
                      disabled={mutateVerifyCode.isLoading}>
                    {mutateVerifyCode.isLoading ? <Loading bgColor={"#000000"}/> : "تایید"}
                  </PrimaryButton>
                </CardActionArea>
              </div>

            </div>

            {loading
                ?
                <div className="mt-4">
                <Loading bgColor={"#BDBDBD"}/>
                  </div>
                :
                <div
                    className="flex flex-row justify-center items-center mt-4  gap-2 "
                >
                  {timer > 0 ? (
                      <span className="font-dana font-[500] text-gray_5  text-sm ">
                  {minutes < 2 ? `0${minutes}` : minutes}
                        <span>:</span>
                        {seconds < 10 ? `0${seconds}` : seconds}
                </span>
                  ) : (
                      <div className="flex items-center" onClick={(e) => resetOTpTimer()}>
                  <span className="font-semibold font-dana mr-[.438rem] text-gray_4 text-sm">
                    ارسال مجدد کد
                  </span>
                        <span> <ResendOtpSVG /></span>
                      </div>
                  )

                  }
                </div>
            }

          </div>
        </main >
      </div >
    </div >
  );
}

export default GetOtp;
