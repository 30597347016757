
import DeleteIconSvg from "../svg/support/DeleteIconSvg.jsx";
import SuccessFullAttachment from "../svg/support/SuccessFullAttachment.jsx";
import { formatBytes } from "../../helper/ConvertBytes.js";
import UploadingIconSvg from "../svg/tickets/UploadingIconSvg.jsx";
import CheckSvg from "../svg/tickets/checkSvg.jsx";

function SuccessfulUpload({ index, item }) {
  return (
    <div
      dir="rtl"
      className="w-full bg-gray_1 flex justify-between items-center flex-row rounded-[10px]  p-2 "
    >
      <div>
        <CheckSvg />
      </div>
      <div className="flex items-center justify-center gap-1">
        <div className="flex items-center gap-2">
          <div className="flex flex-col">
            <p
              dir="ltr"
              className=" line-clamp-1 text-gray_4 text-left text-xs font-poppins font-normal w-full pb-1"
            >
              {item?.name}
            </p>
            <p
              dir="ltr"
              className="text-green text-left text-xs font-medium font-dana "
            >
              {formatBytes(item?.size, 2)} / {formatBytes(item?.size, 2)}
            </p>
          </div>
          <div className="w-[38px] h-38px mx-2">
            <UploadingIconSvg />
          </div>
        </div>
        <p className="text-sm font-dana text-white w-4">
          {index + 1}
        </p>
      </div>
    </div>
  );
}

export default SuccessfulUpload;
