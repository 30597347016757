const GiftSettingCardItems = [
  {
    route: '/buy-gift',
    label: 'خرید اشتراک هدیه',
    action: null,
  },
  {
    route: null,
    label: 'فعال سازی اشتراک هدیه',
    action: 'OPEN_DIALOG',
  },
  {
    route: '/manage-gift',
    label: 'مدیریت اشتراک های هدیه',
    action: null,
  },
];
export default GiftSettingCardItems;
