import React from 'react';
import animationData from "../../../assets/lottie-file/circleLoader.json";
import Lottie from "react-lottie";

function ShareLoading({speed =1}) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
    };
    return (
        <div className='w-full h-full max-w-full max-h-full'>
            <Lottie
                speed={speed}
                isClickToPauseDisabled={true}
                options={defaultOptions}
            />
        </div>
    )
}

export default ShareLoading;
