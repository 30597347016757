import React from "react";

function SearchEmptyTabItem() {
  return (
    <svg
      width="97"
      height="96"
      viewBox="0 0 97 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4503_6791)">
        <path
          d="M67.0512 34.6138C64.0245 34.2453 61.3891 32.8108 59.6312 30.5745C59.1911 30.0146 58.3806 29.918 57.8207 30.3576C57.2608 30.7976 57.1639 31.6078 57.6038 32.1678C59.7864 34.9441 63.0309 36.7217 66.7397 37.1732C67.4527 37.2595 68.0896 36.7509 68.1752 36.0495C68.2614 35.3427 67.7582 34.6999 67.0512 34.6138Z"
          fill="#606060"
        />
        <path
          d="M38.2001 30.3577C37.6402 29.9179 36.8297 30.0146 36.3898 30.5746C34.6317 32.811 31.9966 34.2454 28.9696 34.6139C28.2627 34.7 27.7594 35.3426 27.8455 36.0494C27.9311 36.7525 28.5698 37.259 29.2811 37.1733C32.9902 36.7216 36.2347 34.944 38.4172 32.1677C38.8571 31.6079 38.7599 30.7975 38.2001 30.3577Z"
          fill="#606060"
        />
        <path
          d="M64.6258 48.7942C60.076 45.7224 59.2624 45.4359 59.2624 45.042C59.2624 44.669 60.0693 44.3476 62.3178 42.778C62.9093 42.3817 63.0673 41.5809 62.6709 40.9895C62.2745 40.3979 61.4731 40.2401 60.8821 40.6364L58.2016 42.4327C56.1782 43.7892 56.174 46.2915 58.2106 47.6569C63.3238 50.9368 63.2313 51.1601 63.9155 51.1601C65.1917 51.1602 65.6918 49.4961 64.6258 48.7942Z"
          fill="#606060"
        />
        <path
          d="M36.1406 45.494C36.1617 45.4792 36.1555 45.4833 36.1406 45.494V45.494Z"
          fill="#606060"
        />
        <path
          d="M37.5965 42.4074L34.9162 40.611C34.3248 40.2147 33.5239 40.3726 33.1272 40.964C32.7308 41.5553 32.8888 42.3562 33.4803 42.7526L36.1606 44.5488C36.6517 44.8783 36.6538 45.1532 36.1606 45.4838C36.0304 45.5705 36.1083 45.5146 36.1399 45.4923C36.045 45.5591 35.2827 46.0947 31.377 48.8127C30.7926 49.2194 30.6487 50.0227 31.0554 50.6071C31.4644 51.1946 32.2684 51.3338 32.8503 50.9287L37.6064 47.6185C39.6192 46.2614 39.6185 43.7627 37.5965 42.4074Z"
          fill="#606060"
        />
        <path
          d="M64.7253 66.4974C64.7124 66.3778 64.6876 66.2516 64.6555 66.1373C63.9593 63.6713 62.7967 61.3802 61.2749 59.4292C60.7901 58.807 60.261 58.2087 59.6623 57.6195C59.3159 57.2782 58.9623 56.9576 58.6427 56.6881C58.2478 56.3549 57.8509 56.0491 57.4655 55.7732C57.3674 55.7043 57.2948 55.6531 57.247 55.6242C50.6706 51.1243 42.2406 52.0265 36.5871 57.405C33.4394 60.4037 31.3566 65.1059 31.2915 66.6573C31.2233 68.1455 32.601 69.2997 34.0156 68.8655C39.2547 67.2559 45.5946 66.6131 51.8658 67.0557C52.5733 67.1047 53.1925 66.5708 53.2427 65.8606C53.2929 65.1504 52.7578 64.5341 52.0475 64.4839C45.7818 64.0423 39.4324 64.6379 34.0614 66.1636C34.6312 64.4174 35.4663 62.7983 36.5185 61.3695C43.7549 59.6647 52.2763 59.6648 59.5118 61.3695C60.5639 62.7985 61.3993 64.4177 61.9692 66.1638C60.7751 65.825 59.5163 65.5284 58.2166 65.2793C57.5157 65.1461 56.8417 65.6037 56.7078 66.3029C56.5739 67.002 57.0323 67.6775 57.7314 67.8114C61.3113 68.4969 61.8788 68.9566 62.621 68.9566C63.0319 68.9566 63.4369 68.8327 63.7867 68.5928C64.4649 68.1268 64.8212 67.3016 64.7253 66.4974ZM39.7164 58.1291C44.697 54.3924 51.3231 54.385 56.3134 58.129C50.9205 57.3096 45.1093 57.3098 39.7164 58.1291Z"
          fill="#606060"
        />
        <path
          d="M75.7675 9.8521C75.2149 9.40299 74.4028 9.48738 73.9538 10.04C73.505 10.5925 73.589 11.4043 74.1417 11.8532C96.5044 30.0161 94.0038 65.2726 68.7242 79.8654C54.3776 88.1474 36.5223 87.1003 23.2359 77.1975C22.6652 76.7718 21.8573 76.8897 21.4315 77.4605C21.0058 78.0313 21.1238 78.8389 21.6947 79.2643C35.8209 89.7936 54.7876 90.8871 70.0133 82.098C96.9167 66.5679 99.4868 29.1154 75.7675 9.8521Z"
          fill="#606060"
        />
        <path
          d="M70.6806 6.28736C45.7039 -8.68904 13.1098 4.27572 5.51554 32.6134C1.26319 48.4812 6.23272 64.5055 16.9995 75.213C17.251 75.4633 17.5798 75.5882 17.9087 75.5882C19.0461 75.5882 19.6385 74.201 18.8179 73.3851C8.6923 63.3149 3.99764 48.239 8.00623 33.2808C15.1599 6.58677 45.8517 -5.59407 69.3544 8.4982C69.965 8.86429 70.7569 8.66663 71.1233 8.05579C71.4896 7.44511 71.2914 6.65345 70.6806 6.28736Z"
          fill="#606060"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4503_6791"
          x="0"
          y="0"
          width="96.0039"
          height="96"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4503_6791"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4503_6791"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default SearchEmptyTabItem;
