import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { IsIosDevice } from "../../helper/CheckDevice";
import { FreeMode } from "swiper";
import { FixedSizeList as List } from "react-window";
import CollectionCardItem from "../../pages/playList/CollectionCardItem.jsx";
import GeneralCollectionCardItem from "../playlist/GeneralCollectionCardItem.jsx";

const Column = ({ data, index, style }) => (
    <div
        style={{ ...style, right: style.right + 7 + "px" }}

    >
        <CollectionCardItem item={data[index]} queueList={data} />
    </div>
);

function HomeGeneralCollectionSlider({ item }) {
    return IsIosDevice() ? (
        <div className="flex flex-col ">
            <List
                direction="rtl"
                height={163}
                itemCount={item?.general_collections?.length}
                itemSize={110}
                layout="horizontal"
                width={400}
                style={{ width: "100% !important" }}
                itemData={item?.general_collections}
                className="!pr-[17px] "

            >
                {Column}
            </List>
        </div>
    ) : (
        <div className="flex flex-col ">
            <div className="slider">
                <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    // lazy={true}
                    modules={[FreeMode]}
                    freeMode={true}
                    className="!w-full !h-fit flex flex-col-reverse !select-none new-song-swiper  !overflow-visible"
                    cssMode={IsIosDevice()}
                >
                    {item?.general_collections?.map((collection, index) => (
                        <div className="" key={index}>
                            <SwiperSlide
                                className="!w-fit last:!pl-4 first:!pr-4"
                                key={index}
                            >
                                <div className="flex flex-col ">
                                    {/* <!-- persian --> */}
                                    <GeneralCollectionCardItem item={collection} queueList={item?.general_collections} />
                                </div>
                            </SwiperSlide>
                        </div>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default HomeGeneralCollectionSlider;
