import React, { useEffect, useState } from 'react';
import BottomSheetHeader from '../bottomSheets/BottomSheetHeader.jsx';
import Loading from '../loading/Loading.jsx';
import SwipeBottomSheet from '../bottomSheets/SwipeBottomSheet.jsx';
import { useMutation } from '@tanstack/react-query';
import { applyToken } from '../../services/ApiClient.js';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';

function DiscountCodeBottomSheet({ plan, onSetPlan, onSetDiscountCode }) {
  const [openDiscountCode, setOpenDiscountCode] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [validCouponMessage, setValidCouponMessage] = useState('');
  const [error, setError] = useState();

  function closeDiscountModal() {
    setDiscountCode('');
    setOpenDiscountCode(false);
  }

  function openDiscountModal() {
    if(validCouponMessage){
            return;
        }
        setError(null);
        setOpenDiscountCode(true);
    }

    //make request when user have coupon and enter it
    const applyDiscountMutation = useMutation(
        (discountCode) => applyToken(discountCode),
        {
            onSuccess: (res) => {
                if (res.data.done) {
                    setValidCouponMessage(res?.data?.result?.accepted_discount);
                    setOpenDiscountCode(false);
                    onSetPlan({
                        ...plan,
                        price: res?.data?.result?.total_price,
                        old_price: res?.data?.result?.original_price,
                        discount_image: res?.data?.result?.icon
                    })
                }
                setError(res?.data?.error?.message);
            },
        }
    );
    // onClick handling for entering discount
    const handleApplyCoupen = () => {
        if (applyDiscountMutation?.isLoading) {
            return;
        }
        if (discountCode === "") {
            setError("این فیلد ضروری است.");
            return;
        }
        applyDiscountMutation.mutate({plan_id: plan?.id, coupon: discountCode});
    };
    // onchange for coupon input
    const handleDiscountCode = (e) => {
        setDiscountCode(e.target.value);
    };
    const toggleDiscountCodeBottomSheet = (newOpen) => () => {
        setDiscountCode('')
        setOpenDiscountCode(newOpen);
    };
    useEffect(() => {
        onSetDiscountCode(discountCode)
    }, [discountCode])
    return (
        <>
            <div
                onClick={() => openDiscountModal()}
                className="flex items-start justify-center cursor-pointer w-full"
            >
                {validCouponMessage ? (
                    <div
                        className=" flex gap-2 justify-start w-full ">
                        <div className='relative w-[24px] h-[15px]'>
                          <CustomLazyLoadImage image={plan?.discount_image} />
                        </div>
                        <p className="text-green font-dana font-[600] text-[12px]">
                            {validCouponMessage}
                        </p>
                    </div>
                ) : (
                    <div
                        className="flex w-full  font-dana font-[400] leading-6 text-[12px] text-gray_4 gap-2 justify-start items-center">
                        <div className='relative w-[24px] h-[15px]'>
                          <CustomLazyLoadImage image={plan?.discount_image} />
                        </div>
                        <span className="font-dana font-[400] leading-6 text-[12px] text-gray_4 ">
                            {plan?.invoice_discount_title}
                        </span>
                        <span className="text-primary font-dana font-[400] leading-6 text-[12px] ">
                            {plan?.invoice_discount_button_title}
                        </span>
                    </div>
                )}
            </div>
            <SwipeBottomSheet
                open={openDiscountCode}
                toggleDrawer={toggleDiscountCodeBottomSheet}
            >
                <BottomSheetHeader dividerLine={true} closeBottomSheet={closeDiscountModal} title="کد تخفیف"/>
                <div dir='rtl' className='flex flex-col gap-4 w-full px-4 pb-4 pt-3 '>

                    <div  className='flex flex-col gap-2 w-full'>
                        <input
                            onKeyPress={(e) =>
                                e.key === "Enter" && handleApplyCoupen()
                            }
                            dir='ltr'
                            value={discountCode}
                            onChange={handleDiscountCode}
                            className={`bg-transparent font-dana font-[500] w-full  !border-[1px]   rounded-[100px] text-gray_3 text-[12px]  placeholder:text-left   placeholder:text-gray_3 focus:outline-none h-[48px] px-4  ${error ? 'border-red' : 'border-gray_4'}`}
                            placeholder="HGV78JGJ"
                        />
                        {error && (
                            <div
                                className="!text-red font-dana text-[12px] font-[500] ">
                                <p className="!text-red ">{error}</p>
                            </div>
                        )}
                    </div>

          <button
            onClick={handleApplyCoupen}
            type="submit"
            className="bg-primary text-[16px] text-title_box font-dana font-[600] leading-6 flex justify-center items-center rounded-[100px] h-[48px] "
          >
            {applyDiscountMutation?.isLoading ? (
              <Loading bgColor={'#303030'} />
            ) : (
              'اعمال'
            )}
          </button>
        </div>
      </SwipeBottomSheet>
    </>
  );
}

export default DiscountCodeBottomSheet;
