import React from "react";
import CloseDialogButton from "../svg/common/CloseDialogButton.jsx";
import {Dialog} from "@headlessui/react";


function DialogHeader({header_text, header_icon, onCloseDialog}) {
    return (
<>
            <Dialog.Title
                as="h3"
                className="w-full text-lg leading-6 text-right flex items-center justify-between pb-4  border-b-[1.5px]   border-gray_1 mb-4 ">
                <div className="flex items-center gap-4  ">
                    {header_icon}
                    <p className=" font-kalameh text-gray_6 text-[18px] font-semibold leading-normal">
                        {header_text}
                    </p>
                </div>
                {onCloseDialog && (
                    <div
                        className="cursor-pointer"
                        onClick={onCloseDialog}>
                        <CloseDialogButton/>
                    </div>
                )
                }
            </Dialog.Title>
</>
    )
}

export default DialogHeader
