import React, { useEffect } from "react";
import PlaylistMetaDataIcon from "../../../components/svg/search/PlaylistMetaDataIcon";
import {
  getCommitSearch,
  getCommitedSearchQuery,
  getPlayListResultData,
  getSearchQuery,
} from "../../../helper/ReduxSelectorContext";
import { CardActionArea } from "@mui/material";
import CategoryCard from "../../../components/playlist/CategoryCard";
import { useNavigate } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getSearchResultV8 } from "../../../services/ApiClient";
import InfiniteScroll from "react-infinite-scroll-component";
import SongListLoading from "../../../components/loading/SongListLoading";
import CollectionGridShimmer from "../../../components/Shimmers/CollegtionGridShimmer.jsx";
import { useStoreSearchHistory } from "../../../hooks/useStoreSearchHistory";
import { SearchItemType } from "../../../consts/SearchItemType";
import SearchNoResultView from "../SearchNoResultView";

function PlaylistSearchResultTab({ scrollTop }) {
  const playListResultData = getPlayListResultData();
  const navigate = useNavigate();
  const searchparam = getCommitedSearchQuery();
  const searchCommit = getCommitSearch();
  const [, storeSearchItemWithType] = useStoreSearchHistory();
  const storeInSearchHistory = (item) => {
    storeSearchItemWithType(
      { collection: item, id: item?.id },
      SearchItemType().collection
    );
  };
  const openCollection = (item) => {
    storeInSearchHistory(item);
    navigate(`/playlist-songs/${item?.id}`, {
      state: { collectionObject: item },
    });
  };
  const getSearchPlaylistDataQuery = useInfiniteQuery(
    [`getSearchPlaylistDataQuery` + searchparam],
    ({ pageParam = 0 }) =>
      getSearchResultV8({
        q: searchparam,
        type: 5,
        offset: pageParam,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.result?.end) return null;
        const allData = allPages?.flatMap(
          (page) => page?.data?.result?.collections
        );
        return allData?.length;
      },
      onSuccess: (res) => {
        console.log("dgsdjgkjhsfsdfsdjkgh_playlist", res);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const PlayListItems = useMemo(
    () =>
      getSearchPlaylistDataQuery?.data?.pages?.flatMap((page) => {
        if (page?.data?.error) return [];
        return page?.data?.result?.collections;
      }),
    [getSearchPlaylistDataQuery?.data]
  );
  const getMonthNewSongNextPage = () => {
    if (
      getSearchPlaylistDataQuery?.hasNextPage &&
      !getSearchPlaylistDataQuery?.isFetchingNextPage
    ) {
      getSearchPlaylistDataQuery.fetchNextPage();
    }
  };
  useEffect(() => {
    getSearchPlaylistDataQuery.refetch();
  }, [searchCommit]);
  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <div className="w-full">
      {getSearchPlaylistDataQuery.isLoading ? (
        <CollectionGridShimmer count={20} />
      ) : PlayListItems?.length == 0 ? (
        <div className="w-full h-full flex justify-center items-center mt-14">
          <SearchNoResultView />
        </div>
      ) : (
        <InfiniteScroll
          className="!overflow-hidden !pb-8"
          scrollableTarget="search-result-parent"
          dataLength={PlayListItems?.length || 0}
          next={getMonthNewSongNextPage}
          hasMore={getSearchPlaylistDataQuery?.hasNextPage}
          loader={
            <div className="w-full grid justify-center items-center mt-4 ">
              <SongListLoading />
            </div>
          }
        >
          <div className="grid grid-cols-2 mt-1 w-full    gap-4 px-4  py-3">
            {PlayListItems?.map((item, index) => (
              <div
                key={index + "collection-item"}
                onClick={() => openCollection(item)}
                className=" overflow-hidden"
              >
                <CategoryCard item={item} />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
}

export default React.memo(PlaylistSearchResultTab);
