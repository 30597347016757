import React from "react";

import { useAction } from '../../hooks/useAction';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import useWindowDimensions from '../../helper/ScreenSize';
import { CardActionArea } from '@mui/material';
import { getInitialData } from "../../helper/ReduxSelectorContext";
import { useMutation } from "@tanstack/react-query";
import { sendBannerVisit } from "../../services/ApiClient";

function HomeSliderCardITem({ slide,heightRelation,containerClass,contentClass,actionAreaClass}) {

  const { width } = useWindowDimensions();
  const action = useAction();
  const initialData = getInitialData();

  const sendBannerVisitApi = useMutation(sendBannerVisit);

  const goToLink = (type) => {
    if (
      initialData?.setting?.enable_visit_request &&
      slide?.should_send_request
    )
      sendBannerVisitApi.mutate({ id: slide?.id, type: slide?.type });
    action(type);
  };

  return (
    <div
      style={{ aspectRatio: heightRelation / 1 }}
      onClick={() => goToLink(slide?.action)}
      className={` ${containerClass}   mx-auto  relative overflow-hidden`}
    >
      <div
        className={` ${contentClass} w-full h-full   absolute  top-0 z-10 overflow-hidden cursor-pointer`}
      >
        <CardActionArea
          className={`!w-full !h-full !overflow-hidden  ${actionAreaClass}`}
        >
          <CustomLazyLoadImage image={slide?.image} />
        </CardActionArea>
      </div>
    </div>
  );
}

export default HomeSliderCardITem;
