import React from 'react';
import Loading from "../loading/Loading.jsx";
import {useFormik} from "formik";
import {BUG_REPORT_VALIDATION_SCHEMA} from "../../consts/Validation.js";
import {useSelector} from "react-redux";
import {selectUserData} from "../../redux/login/LoginSlice.js";

function BugReportBottomSheetContent({handelModalClose,itemData,itemType}) {
    const user = useSelector(selectUserData);
    const handleSendReport=(data)=>{
        const mail = document.createElement("a");
        const theProblemText = 'کد کاربری : ' + user?.id + '%0D%0A' +
            itemType + ' : ' + itemData?.id + '%0D%0A' +
            '%0D%0A' + 'توضیحات : ' + '%0D%0A' + data?.description
        mail.href = 'mailto:melodify.group@gmail.com?subject=گزارش خطا&body=' + theProblemText;
        mail.click();
        handelModalClose()
    }
    const formik=useFormik({
        initialValues:{
            description:""
        },
        validationSchema:BUG_REPORT_VALIDATION_SCHEMA,
        onSubmit:(data) => {
            handleSendReport(data)
        }
    })
    return (
        <div dir='rtl' className='flex flex-col gap-4 w-full px-4 pb-4 pt-4 '>
            <form
                onSubmit={formik.handleSubmit}
                dir="rtl"
                className=" flex flex-col gap-6 lg-1440:gap-r24">
                <div className=" flex flex-col gap-2 ">
                    <textarea
                        id="description"
                        placeholder='خطای پیش آمده را با جزئیات بنویسید...'
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        className="w-full placeholder:text-gray_3 placeholder:font-medium rounded-[5px]  bg-transparent !border-[1px] lg-1440:border-b1 !border-white focus:outline-none p-3  text-white fa-format-400 text-[16px] lg-1440:text-f16 min-h-[200px]  "
                    />
                </div>
                <button
                    type="submit"
                    className="bg-primary text-[16px] text-title_box font-dana font-[600] leading-6 flex justify-center items-center rounded-[100px] h-[48px] "
                >
                    ارسال
                </button>
            </form>



        </div>
    );
}

export default BugReportBottomSheetContent;
