import { memo } from "react";

const AboutUsShellSvg = (props) => (
  /**
   * This function returns an SVG element that displays a chevron pointing to the left.
   * 
  
   * @param props (object): Any additional props to be passed to the SVG element.
   * @returns An SVG element that displays a chevron pointing to the left.
   */
  <svg
    width={20}
    height={22}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 21C10 21 18 17 18 11V4L10 1L2 4V11C2 17 10 21 10 21Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default memo(AboutUsShellSvg);
