import React from "react";
import HomeMetaDataWithTitle from "../common/HomeMetaDataWithTitle";
import HomeWeekNew from "./HomeWeekNew";
import PlaylistMetaDataWithTitle from "../playlist/PlaylistMetaDataWithTitle.jsx";

function HomeCollectionWithCorner({
  item,
  artistCollection = false,
  fromMyMelodify,
  inPlaylistPage = false,
  cardStyle,
}) {
  return (
    <div
      class={`  ${
        item?.collection_meta_data?.has_background && "bg-artist-dark z-20 mt-3"
      } `}
    >
      <div
        className={`w-full pr-3 py-3 z-10 relative ${
          fromMyMelodify && "!px-4 !py-0"
        }`}
      >
        {fromMyMelodify ? (
          <PlaylistMetaDataWithTitle
            item={item?.collection_items}
            artistCollection={artistCollection}
            metaData={item?.collection_meta_data}
            eventClassName={{
              prev: "prev-button" + item?.collection_meta_data?.type,
              next: "next-button" + item?.collection_meta_data?.type,
            }}
            fromMyMelodify={fromMyMelodify}
          />
        ) : (
          <HomeMetaDataWithTitle
            item={item?.collection_items}
            artistCollection={artistCollection}
            metaData={item?.collection_meta_data}
            eventClassName={{
              prev: "prev-button" + item?.collection_meta_data?.type,
              next: "next-button" + item?.collection_meta_data?.type,
            }}
            fromMyMelodify={fromMyMelodify}
          />
        )}
      </div>
      {item?.collection_items?.map((collationItem, index) => (
        <HomeWeekNew
          key={index + "HomeWeekNew"}
          cardStyle={cardStyle}
          fromMyMelodify={fromMyMelodify}
          artistCollection={artistCollection}
          type={item?.collection_meta_data?.type}
          item={collationItem}
          metaData={item?.collection_meta_data}
        />
      ))}
    </div>
  );
}

export default HomeCollectionWithCorner;
