import React from 'react'

function PlaylistPauseButton({ratio = 'h-[48px]  '}) {
    return (
        <svg
            className={ratio}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
        >
            <g clipPath="url(#clip0_5821_35975)">
                <path
                    fill="#F2C94C"
                    d="M24 0C10.74 0 0 10.74 0 24s10.74 24 24 24 24-10.74 24-24S37.26 0 24 0zm-2.64 30c0 1.5-1.2 2.64-2.64 2.64-1.5 0-2.64-1.2-2.64-2.64V18c-.06-1.44 1.14-2.64 2.58-2.64 1.5 0 2.7 1.2 2.7 2.64v12zm10.62 0c0 1.5-1.2 2.64-2.64 2.64-1.5 0-2.64-1.2-2.64-2.64V18c-.06-1.44 1.14-2.64 2.58-2.64 1.5 0 2.7 1.2 2.7 2.64v12z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_5821_35975">
                    <path fill="#fff" d="M0 0H48V48H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default PlaylistPauseButton
