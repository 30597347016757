import React from "react";

function melodifyMasked() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="66"
            height="60"
            fill="none"
            viewBox="0 0 66 60"
        >
            <path
                fill="#4F4F4F"
                d="M42.087 27.33a9.567 9.567 0 01-.805 3.824c-1.462 3.3-4.776 5.608-8.613 5.608-3.838 0-7.152-2.307-8.614-5.608a9.344 9.344 0 01-.805-3.824c0-2.012.63-3.877 1.704-5.407l4.91 4.91a3.628 3.628 0 005.14 0l5.191-5.191a9.44 9.44 0 011.892 5.688z"
            ></path>
            <path
                fill="#4F4F4F"
                fillRule="evenodd"
                d="M56.673 5.165a33.241 33.241 0 00-3.234-3.059s-.013 0-.013-.013c-.228-.174-.443-.362-.671-.537 0 0-.014-.013-.027-.013A8.267 8.267 0 0047.925 0c-4.387 0-7.983 3.408-8.278 7.742v.322h6.319a3.257 3.257 0 011.852.617 3.235 3.235 0 011.342 2.616c0 .845-.323 1.623-.86 2.2l-2.777 2.831-2.187 2.187a13.739 13.739 0 013.18 8.815c0 1.328-.188 2.616-.537 3.824-1.663 5.783-6.99 10.009-13.296 10.009-6.305 0-11.645-4.226-13.296-10.01a13.786 13.786 0 01-.536-3.823c0-3.233 1.1-6.198 2.965-8.56l-2.335-2.308-2.683-2.683.013-.013a3.243 3.243 0 01-1.1-2.442c0-.926.39-1.771 1.006-2.362.175-.174.376-.322.59-.442 0 0 .014 0 .014-.014a3.1 3.1 0 011.342-.43c.094-.012.201-.012.295-.012.094 0 .188 0 .282.013h6.453v-.161C25.438 3.542 21.803.08 17.361.08a8.385 8.385 0 00-5.085 1.717 8.822 8.822 0 00-.402.335A31.783 31.783 0 008.439 5.42 32.547 32.547 0 000 27.33C0 45.376 14.624 60 32.67 60s32.67-14.624 32.67-32.67a32.568 32.568 0 00-8.667-22.165zM17.79 47.455c.322.215.724.35 1.154.35 1.14 0 2.08-.94 2.093-2.08 0-.645-.296-1.208-.738-1.597l.013.013c-.027-.026-.067-.053-.107-.08h-.014c-.026-.027-.067-.04-.094-.067A20.842 20.842 0 0112.17 31.18c-.04-.188-.067-.363-.094-.537-.013-.08-.027-.148-.04-.228v-.014a1.764 1.764 0 00-.215-.576c-.027-.054-.067-.094-.094-.148-.04-.04-.067-.094-.107-.134a1.21 1.21 0 00-.12-.134l-.068-.067a2.133 2.133 0 00-1.449-.577c-.147 0-.268.013-.402.04-.215.04-.416.107-.604.215a2.078 2.078 0 00-1.074 1.824c0 .054.014.121.014.175 0 .094.027.174.04.268a25.06 25.06 0 009.7 16.073c.054.027.094.067.135.094z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#4F4F4F"
                fillRule="evenodd"
                d="M32.551 41.162v18.837C14.561 59.935 0 45.336 0 27.33A32.546 32.546 0 018.44 5.42a31.783 31.783 0 013.434-3.287c.134-.12.268-.228.402-.336A8.385 8.385 0 0117.361.08c4.441 0 8.077 3.462 8.332 7.835v.161H19.24a1.982 1.982 0 00-.282-.013c-.094 0-.201 0-.295.013a3.1 3.1 0 00-1.342.43c0 .013-.013.013-.013.013-.215.121-.416.269-.59.443a3.27 3.27 0 00-1.007 2.361c0 .98.43 1.852 1.1 2.442l-.013.014 2.683 2.683 2.335 2.308a13.74 13.74 0 00-2.965 8.56c0 1.328.188 2.616.536 3.823 1.639 5.743 6.916 9.95 13.164 10.009zm-13.607 6.642a2.091 2.091 0 01-1.197-.382c-.027-.021-.056-.044-.09-.061a25.06 25.06 0 01-9.718-16.17c-.012-.056-.023-.111-.023-.172a.821.821 0 00-.007-.087c-.003-.03-.006-.06-.006-.087 0-.792.429-1.463 1.073-1.825.188-.107.389-.174.604-.215.134-.026.255-.04.402-.04.564 0 1.074.228 1.45.577l.066.067c.04.04.08.08.121.134.02.02.037.044.054.067a.525.525 0 00.053.068.55.55 0 00.047.073c.017.024.034.047.047.074.107.175.175.376.215.577v.014l.02.113.02.115c.027.174.054.348.094.536a20.842 20.842 0 007.93 12.813c.013.014.03.024.046.034.017.01.034.02.047.033h.014c.04.027.08.054.107.08l-.013-.013c.442.39.738.953.738 1.597a2.11 2.11 0 01-2.093 2.08z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#4F4F4F"
                fillRule="evenodd"
                d="M18.87 26.748H.011A32.542 32.542 0 018.446 5.42a31.793 31.793 0 013.434-3.287c.135-.12.269-.228.403-.336A8.385 8.385 0 0117.368.081c4.44 0 8.077 3.462 8.332 7.835v.161h-6.454c-.094-.013-.188-.013-.281-.013-.094 0-.202 0-.296.013a3.1 3.1 0 00-1.341.43c0 .013-.014.013-.014.013-.214.121-.416.269-.59.443a3.27 3.27 0 00-1.006 2.361c0 .98.43 1.852 1.1 2.442l-.014.014 2.684 2.683 2.334 2.308a13.727 13.727 0 00-2.953 7.977z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default melodifyMasked;