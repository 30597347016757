import { useLocation } from "react-router";
import { BottomNavigationInvisibleRoutes } from "../../consts/BottomNavigationInvisibleRoutes";


export const useBottomNavigation = () => {
  const location = useLocation();
  const InvisibleBottomSheet = () => {
    return BottomNavigationInvisibleRoutes.some((route) => {
      if (route.endsWith("**")) {
        return location.pathname.startsWith(route.slice(0, -3));
      }
      return route === location.pathname;
    });
  };
  return [InvisibleBottomSheet];
};