import React from "react";
import { usePlayedTrack } from "../../hooks/usePlayedTrack";
import { getPlayPushActionData } from "../../helper/ReduxSelectorContext";
import { useEffect } from "react";
import { PlayListType } from "../../consts/PlayListType";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { setPlayPushActionData } from "../../redux/player/PlayerSlice";

function PushNotificationGlobalComponent() {
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  const playerData = getPlayPushActionData();
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const clearPushData = () => {
    dispatch(setPlayPushActionData({ player_data: null }));
  };
  useEffect(() => {
    if (isMounted.current) {
      console.log("ghsdshsdhssdhs", playerData);
      if (!playerData) return;
      setPlayerTrack(
        playerData?.track,
        playerData?.playlist,
        PlayListType.PLAYLIST
      );
      clearPushData();
    } else {
      isMounted.current = true;
    }
  }, [playerData]);

  return <></>;
}

export default PushNotificationGlobalComponent;
