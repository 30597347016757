import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import CrownSvg from "../../components/svg/playList/CrownSvg";
import UnsuccessfulPaymentSvg from "../../components/plans/UnsuccessfulPaymentSvg";
function PaymentStatusDialog() {
  const param = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const handleInitializeRequest = () => {
    const channel = new BroadcastChannel("paymentBack");
    channel.postMessage("handle");
  };
  const handleRedirectUser = () => {
    handleInitializeRequest();
    window.open("", "_self", "");
    window.close();
  };
  useEffect(() => {
    setIsOpen(true);
    if (param?.status === "success") {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event to prevent the browser from closing the tab immediately
      event.preventDefault();

      // event.returnValue = "";
      if (status) handleInitializeRequest();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [status]);

  const closeModal = () => setIsOpen(false);
  return (
    <div className="w-full h-full flex flex-col gap-4 justify-center items-center">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel
                  dir="rtl"
                  className={`w-full border-[1px] border-opacity-40 ${status?"border-primary":"border-red"}  max-w-md lg-1440:max-w-[34.7vw] transform overflow-hidden rounded-[15px] lg-1440:rounded-br20 bg-[#242429] py-6 lg-1440:py-r24 text-left align-middle shadow-xl transition-all`}>
                  <Dialog.Title
                    as="h3"
                    className="text-lg eading-6  flex items-center justify-center px-6 lg-1440:px-r24">
                    {status ? (
                      <div
                          className='h-[86px]  min-[1440px]:h-[5.9722vw]'
                        >
                        <CrownSvg custom={true} />
                      </div>
                    ) : (
                      <div
                          className='h-[64px]  min-[1440px]:h-[4.4444vw]'
                        >
                        <UnsuccessfulPaymentSvg />
                      </div>
                    )}
                  </Dialog.Title>

                  <div className="px-6 lg-1440:px-r24">
                    <div className="mt-8 flex flex-col items-center gap-3 lg-1440:gap-r12 lg-1440:mt-r32 ">
                      <p
                        dir="rtl"
                        className={`${
                          status ? "text-green " : "text-red"
                        } font-dana font-[500] text-[24px] lg-1440:text-f24 text-center`}>
                        {status
                          ? " خرید شما با موفقیت انجام شد. "
                          : " پرداخت شما ناموفق بود."}
                      </p>
                    </div>
                  </div>
                  <div className="mt-8  flex items-center justify-center px-6 ">
                    <button
                      onClick={() => handleRedirectUser()}
                      type="button"
                      className="inline-flex text-primary text-[16px] w-full font-dana font-[500] lg-1440:text-f16 bg-yellow max-lg-1440:rounded-br100 justify-center rounded-[100px] border border-transparent bg-blue-100 px-4 lg-1440:px-r16 py-3 lg-1440:py-r12 focus:outline-none">
                      تایید
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default PaymentStatusDialog;
