import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import DialogHeader from './DialogHeader.jsx';
import { globalData } from '../../helper/ReduxSelectorContext.js';
import { useDispatch } from 'react-redux';
import { hideSurveyDialog } from '../../redux/globalData/globalDataSlice.js';
import { playStoreUrl } from '../../consts/GlobalConsts.js';
import HelpCircleSvg from '../svg/common/HelpCircleSvg.jsx';
import PositiveReaction from '../../assets/survey/positive-reaction.webp';
import NegativeReaction from '../../assets/survey/negetive-reaction.webp';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
function SurveyDialog(props) {
  const dispatch = useDispatch();
  const globalDataSelect = globalData();
  const showSurveyDialogState = globalDataSelect?.showSurveyDialogState;
  const handleCloseDialog = () => {
    dispatch(hideSurveyDialog());
  };

  const openGooglePlay = () => {
    handleCloseDialog();
    const linkEl = document.createElement('a');
    linkEl.setAttribute('href', playStoreUrl);
    linkEl.setAttribute('target', '_blank');
    linkEl.click();
  };
  return (
    <Transition appear show={showSurveyDialogState} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={handleCloseDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto font-dana">
          <div className="flex min-h-full items-center justify-center p-4 text-center pb-12">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-secondary p-4  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={'نظر سنجی'}
                  onCloseDialog={handleCloseDialog}
                />
                <div className="px-2 flex flex-col items-center">
                  <div>
                    <HelpCircleSvg />
                  </div>

                  <p className="text-white text-[18px] mt-6 text-center  ">
                    از تجربه‌ی خود در ملودیفای
                    <br />
                    رضایت دارید؟
                  </p>

                  <div
                    onClick={openGooglePlay}
                    className="flex flex-col gap-[20px] w-full mt-4"
                  >
                    <div className="relative  aspect-[259/46] bg-gradient-to-l from-[#282828] to-[#1D1D1D] rounded-[23px] px-[18px] flex justify-end items-center">
                      <div className="aspect-[90/50] h-[106%] absolute right-[18px] bottom-0 ">
                        <CustomLazyLoadImage
                          appendBaseUrl={false}
                          image={PositiveReaction}
                        />
                      </div>
                      <p className="text-center text-primary text-base font-medium font-dana leading-[35px]">
                        بله ستاره میدهم
                      </p>
                    </div>
                    <div className="relative  aspect-[259/46] bg-gradient-to-l from-[#282828] to-[#1D1D1D] rounded-[23px] px-[18px] flex justify-end items-center">
                      <div className="aspect-[64/45] h-[100%] absolute bottom-0  right-[18px]">
                        <CustomLazyLoadImage
                          appendBaseUrl={false}
                          image={NegativeReaction}
                        />
                      </div>
                      <p className="text-center text-primary text-base font-medium font-dana leading-[35px]">
                        خیر انتقاد دارم!
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default SurveyDialog;
