import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import * as api from "../../services/ApiClient.js";
import { useLocation, useParams } from "react-router-dom";
import { AboutUsItems } from "../../consts/AboutUs.jsx";
import MusicNoteLoading from "../../components/loading/MusicNoteLoading.jsx";
import Container from "../../components/PageParentContainer/Container.jsx";
import CommonHeader from "../../components/common/CommonHeader.jsx";

function SingleAbout({ itemKey }) {
  console.log({ itemKey });
  const { pageKey } = useParams();
  const aboutKey = itemKey ? itemKey : pageKey;
  const location = useLocation();
  const pageData = AboutUsItems.find((item) => item.key === aboutKey);
  const [pageContent, setPageContent] = useState("");
  const getAboutUsDataQuery = useQuery(
    [aboutKey],
    () => api.getAboutUsData(pageData.query),
    {
      onSuccess: (res) => {
        setPageContent(res?.data?.result?.content);
      },
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    setPageContent(getAboutUsDataQuery.data?.data?.result?.content);
  }, []);

  // console.log("DFgdfhgfdhfdhdfh", location?.pathname?.split("/")[2] === "p");
  return (
    <Container
      style=" bg-primary_dark "
      header={itemKey ? undefined : <CommonHeader title={pageData?.title} />}
    >
      <div className={` w-full pb-[80px]  px-4 pt-4 flex flex-col `}>
        {getAboutUsDataQuery?.isLoading && (
          <div className="mx-auto w-32 h-32">
            <MusicNoteLoading />
          </div>
        )}
        {getAboutUsDataQuery?.data && (
          <div
            dir="rtl"
            className="font-medium text-sm leading-7 text-gray_5 font-dana"
            dangerouslySetInnerHTML={{ __html: pageContent?.toString() }}
          ></div>
        )}
      </div>
    </Container>
  );
}

export default SingleAbout;
