import React from "react";
import HomeMetaDataWithTitle from "../common/HomeMetaDataWithTitle";
import HomeExclusiveTracksSlider from "./HomeExclusiveTracksSlider";
import HomeExclusiveTracksBanner from "./HomeExclusiveTracksBanner";

function HomeExclusiveTracksSection({ item }) {
  return (
    <div className="py-3 z-20">
      <div dir="rtl" className="flex flex-col pt-4 pb-8 gap-1 bg-artist-dark ">
        <div className="w-full  pr-3">
          <HomeMetaDataWithTitle listData={item?.exclusive_tracks_items} metaData={item?.exclusive_tracks_meta_data} />
        </div>
        <div className="flex flex-col gap-3  ">
          <HomeExclusiveTracksSlider item={item?.exclusive_tracks_items[0]} />
          {item?.exclusive_tracks_items[1]?.banner && (
            <div className="pr-4 pl-4">
              <HomeExclusiveTracksBanner
                banner={item?.exclusive_tracks_items[1]?.banner}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(HomeExclusiveTracksSection);
