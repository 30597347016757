import { useDispatch } from "react-redux";
import { setSearchHistory } from "../redux/search/SearchSlice";
import { useMutation } from "@tanstack/react-query";
import { searchHits } from "../services/ApiClient";
import { getCommitedSearchQuery } from "../helper/ReduxSelectorContext";
import { useLocation } from "react-router-dom";

export function useStoreSearchHistory(fromHistory) {
    const searchparam = getCommitedSearchQuery();
    const location=useLocation()
    const searchHitsMutation = useMutation((data) => searchHits({ ...data, search_text: searchparam }), {
        onSuccess: (res) => {

        }
    })
    const dispatch = useDispatch()
    const storeSearchItem = (item) => {
        console.log("gsdlkhjfdgdfggsdjhg", item);
        if(!fromHistory)
        searchHitsMutation.mutate({ model_type: item?.model_type, model_id: item?.id })
        dispatch(setSearchHistory({ searchQuery: item }));
    }

    const storeSearchItemWithType = (item, itemType) => {
        console.log("gsdlkhjfdgdfggsdjhg___", item);
        if(!fromHistory)
        searchHitsMutation.mutate({ model_type: itemType?.model_type, model_id: item?.id })
        const newItem = { ...item, ...itemType }
        dispatch(setSearchHistory({ searchQuery: newItem }));
    }

    return [storeSearchItem, storeSearchItemWithType]
} 