import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {globalData} from "../helper/ReduxSelectorContext.js";
import {useEffect, useRef, useState} from "react";
import {
    addItemToOpenModalList, disableBackClicked,
    enableBackClicked,
    removeItemOfOpenModalList
} from "../redux/globalData/globalDataSlice.js";


export const useNativeBack = (state, id, closeFunction) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const global = globalData()
    const itemRef = useRef(null)
    const openModalList = global?.openModalList
    const addItemToList = () => {
        dispatch(addItemToOpenModalList({item: {id: id}}))
    }
    const handleClickBackButton = (event) => {
        if (global?.backClicked) {
            dispatch(disableBackClicked())
            return
        }
        if (id === openModalList[openModalList.length - 1]?.id && state && location.pathname!=='/plans') {
            navigate(1)
            closeFunction()
            dispatch(removeItemOfOpenModalList({id}))
            dispatch(enableBackClicked())
        }
    }
    useEffect(() => {

        if(openModalList?.length ===0) {
            dispatch(disableBackClicked())
        }
        if (id === openModalList[openModalList.length - 1]?.id) {
            window.addEventListener('popstate', handleClickBackButton)
        } else {
            window.removeEventListener('popstate', handleClickBackButton)
        }
        return () => {
            window.removeEventListener('popstate', handleClickBackButton)
        };

    }, [openModalList,global?.backClicked]);
    useEffect(() => {
        if (state && itemRef.current) {
            addItemToList()

        }
        if (!state && itemRef.current ) {
            dispatch(removeItemOfOpenModalList({id}))
        }
        itemRef.current = true
    }, [state])
    return {addItemToList}
}
