import { memo } from 'react';
import AppBadge from '../common/AppBadge';
import ChevronLeft from '../svg/common/ChevronLeft';
import { CardActionArea } from '@mui/material';
import CustomImage from '../common/CustomImage';

const ProfileDetailCard = ({
  isLoading,
  avatar,
  title,
  subtitle,
  className = '',
  titleClassName = '',
  badge = null,
  onClick = null,
}) => {
  return (
    <CardActionArea onClick={onClick} className="!rounded-[20px]">
      <div
        className={`bg-primary_dark h-[76px] rounded-[20px] w-full flex justify-between items-center ps-2 pe-[25px] font-dana cursor-pointer  ${className} ${
          isLoading && '!bg-[rgba(33,33,33,0.7)]'
        } `}
      >
        <div className="flex items-center justify-start">
          <CustomImage
            alt={''}
            className="h-[60px] w-[60px] rounded-[20px] object-cover"
            src={avatar}
            appendBaseUrl={false}
          />
          <div className="flex flex-col ms-2">
            <span
              className={
                'text-white font-semibold text-sm leading-6 ' + titleClassName
              }
            >
              {title}
            </span>
            <span className="text-gray_3 font-medium text-xs leading-6">
              {subtitle}
            </span>
          </div>
        </div>
        <div className="flex justify-center items-center">
          {badge && <AppBadge>{badge}</AppBadge>}
          <div className="w-fit">
            <ChevronLeft className="w-[24px] h-[24px]" />
          </div>
        </div>
      </div>
    </CardActionArea>
  );
};

export default memo(ProfileDetailCard);
