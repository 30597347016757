import React from 'react';

function SuccessDownloadSvg(props) {
    return (
        <svg
            className="w-full h-full"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                stroke="#219653"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.563"
                d="M13.868 7.38L8.627 12.62l-2.495-2.495"
            ></path>
            <mask
                id="mask0_7837_21173"
                style={{ maskType: "luminance" }}
                width="20"
                height="20"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#fff" d="M0 0h20v20H0V0z"></path>
            </mask>
            <g mask="url(#mask0_7837_21173)">
                <path
                    stroke="#219653"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.563"
                    d="M19.218 10A9.219 9.219 0 11.78 10a9.219 9.219 0 0118.438 0z"
                ></path>
            </g>
        </svg>
    );
}

export default SuccessDownloadSvg;
