import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { formatTextWithStyles } from '../../helper/utils';

function GiftShareCard({ text, decorators }) {
  return (
    <div className="bg-secondary rounded-xl mt-4 p-4">
      <p
        className="font-dana text-white font-medium text-base"
        dangerouslySetInnerHTML={{
          __html: formatTextWithStyles(text, decorators),
        }}
      ></p>
    </div>
  );
}

GiftShareCard.propTypes = {
  text: PropTypes.string.isRequired,
  decorators: PropTypes.array.isRequired,
};

export default memo(GiftShareCard);
