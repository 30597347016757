import React, { useImperativeHandle, useState, useEffect } from 'react';
import SwipeBottomSheet from '../bottomSheets/SwipeBottomSheet.jsx';
import BottomSheetHeader from '../bottomSheets/BottomSheetHeader.jsx';
import { useFormik } from 'formik';
import {
  createUserCollection,
  renameUserCollection,
} from '../../services/ApiClient.js';
import { CREATE_COLLECTION_VALIDATION_SCHEMA } from '../../consts/Validation.js';
import { useMutation } from '@tanstack/react-query';
import {
  addUserCollection,
  editUserCollectionItem,
} from '../../redux/globalData/globalDataSlice.js';
import { useDispatch } from 'react-redux';
import MusicNoteLoading from '../loading/MusicNoteLoading.jsx';

function EditUserPlaylistBottomSheet({ collection }, ref) {
  const dispatch = useDispatch();
  const [openEditPlaylist, setOpenEditPlaylist] = useState(false);
  const editUserCollectionMutation = useMutation(data =>
    renameUserCollection(data),
  );
  const handleCreatUserCollection = data => {
    editUserCollectionMutation.mutate(data, {
      onSuccess: res => {
        if (
          res?.data?.error?.message ===
          'collection with this name already exists!'
        ) {
          formik.setErrors({ title: 'نام پلی لیست تکراری می‌باشد!' });
          return;
        }
        dispatch(
          editUserCollectionItem({
            collection: res?.data?.result?.user_collection,
            id: collection?.id,
          }),
        );

        closeCreatePlayList();
      },
    });
  };
  const formik = useFormik({
    initialValues: {
      title: collection?.title,
      user_collection_id: collection?.id,
    },
    validationSchema: CREATE_COLLECTION_VALIDATION_SCHEMA,
    onSubmit: data => {
      if (editUserCollectionMutation.isLoading) {
        return;
      }
      handleCreatUserCollection(data);
    },
    enableReinitialize: true,
  });
  useImperativeHandle(ref, () => ({
    openEditPlaylist() {
      setOpenEditPlaylist(true);
    },
  }));
  useEffect(() => {
    formik.resetForm();
  }, [openEditPlaylist]);
  function closeCreatePlayList() {
    setOpenEditPlaylist(false);
  }
  const toggleCreatePlaylistBottomSheet = newOpen => () => {
    setOpenEditPlaylist(newOpen);
  };
  return (
    <SwipeBottomSheet
      open={openEditPlaylist}
      toggleDrawer={toggleCreatePlaylistBottomSheet}
    >
      <BottomSheetHeader
        dividerLine={true}
        closeBottomSheet={closeCreatePlayList}
        title="ویرایش نام پلی‌لیست"
      />

      <form
        dir="rtl"
        className="flex flex-col  w-full px-4 pb-4 pt-3 relative "
        onSubmit={formik.handleSubmit}
      >
        {editUserCollectionMutation?.isLoading && (
          <div className="absolute bg-secondary opacity-80 top-0 left-0 w-full h-full flex justify-center items-center">
            <div className="w-[120px] h-[120px]">
              <MusicNoteLoading speed={2} />
            </div>
          </div>
        )}
        <div className="flex flex-col  w-full">
          <input
            dir={formik?.values?.title}
            id="title"
            value={formik.values.title}
            onChange={formik?.handleChange}
            className={`bg-transparent font-dana font-[500] w-full  !border-[1.5px]   rounded-[100px] text-gray_3 text-[16px]  placeholder:text-right  placeholder:text-gray_3 focus:outline-none h-[52px] px-4 mb-4 `}
            placeholder="نام پلی لیست را بنویسید"
          />
          {formik?.errors?.title && (
            <div className="!text-red font-dana text-[12px] font-[400] w-full flex justify-center ">
              <p className="!text-red ">{formik.errors.title}</p>
            </div>
          )}
        </div>
        <button
          type="submit"
          className="bg-primary text-[16px] text-title_box font-dana font-[500] leading-6 flex justify-center items-center rounded-[100px] h-[48px] mt-4 "
        >
          تایید
        </button>
      </form>
    </SwipeBottomSheet>
  );
}

export default React.forwardRef(EditUserPlaylistBottomSheet);
