import React from 'react';
import FileIcon from '../svg/common/FileIcon';
import RoundedCrownSVG from '../svg/support/RoundedCrownSVG';
import UserAvatarSvg from '../svg/support/UserAvatarSvg';
import { CardActionArea } from '@mui/material';
import TextMarquee from '../common/TextMarquee.jsx';

function UserMessage({ messageDetail, isPremium }) {
  const handleOpenFile = link => {
    window.open(link, 'toolbar=0,location=0,menubar=0');
  };


  return (
    <div className="flex w-full justify-start  font-dana">
      <div className="flex flex-col  gap-[2px] w-[calc(100%_-_54px)]">
        <div className="w-full flex py-2 bg-gray_2 items-center rounded-t-[10px]">
          <div className="h-[35px] w-[35px] ml-2 mr-2 bg-gray_1 flex justify-center items-center rounded-full">
            {isPremium ? <RoundedCrownSVG /> : <UserAvatarSvg />}
          </div>
          <div className="flex flex-col  drop-shadow-sm">
            <p className="text-sm text-white">
              {messageDetail?.user?.full_name}
            </p>
            <p className=" text-xs text-gray_4">{messageDetail?.date}</p>
          </div>
        </div>
        <div className="w-full flex flex-col p-4 bg-gray_2  rounded-b-[10px] !rounded-br-none items-start">
          <p
            dangerouslySetInnerHTML={{ __html: messageDetail?.description }}
            className="text-xs text-white leading-5  drop-shadow-sm  w-full"
          ></p>
          {messageDetail?.files &&
            messageDetail?.files?.map(item => (
              <CardActionArea className="!rounded-[5px]  !mt-4  !w-[calc(100%_-_16px)]">
                <div
                  onClick={() => handleOpenFile(item?.url)}
                  className="flex bg-secondary gap-[5px] items-center p-3 rounded-[5px]  w-full"
                >
                  <div
                    className="w-[20px]"
                    style={{
                      aspectRatio: 24 / 20,
                    }}
                  >
                    <FileIcon />
                  </div>

                  <p
                    dir={'ltr'}
                    className=" text-sm text-white cursor-pointer !w-[calc(100%_-_20px)] "
                  >
                    <TextMarquee direction="ltr">{item?.file_name}</TextMarquee>
                  </p>
                </div>
              </CardActionArea>
            ))}
          <div className="bottomSlot relative -bottom-[15px] -right-[16px] w-[30px] h-[0px]">
            <svg
              width="25"
              height="11"
              viewBox="0 0 25 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4435 3.40375L21.7925 9.97424C23.1258 10.7462 24.7946 9.78405 24.7946 8.24339V0.71228H0.42274C3.94159 0.71228 7.39815 1.64068 10.4435 3.40375Z"
                fill="#4F4F4F"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserMessage;
