import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dispatchIsPlaying } from "../redux/globalstore/GlobalStore";
import { selectInitialData } from "../redux/initialize/InitializeSlice";
import { selectUserToken } from "../redux/login/LoginSlice";
import Layout from "../pages/layout/Layout";
import { useNotification } from "../hooks/useNotification";


function PrivateRoute({ children,enableRedirect=true,redirectRoute="/" }) {
  const navigate = useNavigate();
  const token = useSelector(selectUserToken);
  const initialData = useSelector(selectInitialData);
  useNotification()



  useEffect(() => {
    if (token === null && !initialData) {
      navigate("/", { replace: true });
    }
    if(enableRedirect){
      navigate(redirectRoute, { replace: true });
    }
  }, [token]);

  return <Layout>{token !== null && children}</Layout>;
}

export default PrivateRoute;
