export const BottomNavigationItems = [
  {
    id: 0,
    name: "ملودیفای‌من",
    link: "/my-melodify",
    icon: ({ color = "#828282" }) =>
      color == "#828282" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="32"
          fill="none"
          viewBox="0 0 19 22"
        >
          <path
            fill="#828282"
            stroke="#828282"
            strokeWidth="0.3"
            d="M9.553 10.483h0a4.817 4.817 0 10-4.816-4.816h0a4.822 4.822 0 004.816 4.816zm0-8a3.184 3.184 0 110 6.368 3.184 3.184 0 010-6.368zM12.867 11.517h0H6.24h0A5.51 5.51 0 00.737 17.02v3.314a.816.816 0 00.816.816h16a.817.817 0 00.817-.816V17.02v0a5.51 5.51 0 00-5.503-5.503zm3.87 8H2.37V17.02s0 0 0 0a3.875 3.875 0 013.87-3.87s0 0 0 0h6.627s0 0 0 0a3.875 3.875 0 013.87 3.87v2.497z"
          ></path>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="32"
          fill="none"
          viewBox="0 0 19 20"
        >
          <path
            fill="#F2C94C"
            d="M9.514 9.333a4.667 4.667 0 100-9.333 4.667 4.667 0 000 9.333zM12.828 10.667H6.2A5.36 5.36 0 00.848 16.02v3.314a.667.667 0 00.666.666h16a.667.667 0 00.667-.666V16.02a5.36 5.36 0 00-5.353-5.353z"
          ></path>
        </svg>
      ),
  },
  {
    id: 1,
    name: "پلی‌لیست‌ها",
    link: "/playlist",
    icon: ({ color = "#828282" }) =>
      color == "#828282" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="32"
          fill="none"
          viewBox="0 0 22 22"
        >
          <path
            fill={color}
            stroke="#828282"
            strokeWidth="0.3"
            d="M18.85 2v11.302A4.11 4.11 0 0017 12.85h0A4.15 4.15 0 1021.15 17V2a1.15 1.15 0 10-2.3 0zM17 15.15a1.85 1.85 0 110 3.7 1.85 1.85 0 010-3.7zM12.15 12.5A1.15 1.15 0 0011 11.35H2a1.15 1.15 0 000 2.3h9a1.15 1.15 0 001.15-1.15zM2 17.85a1.15 1.15 0 000 2.3h8c.305 0 .598-.121.813-.337l-.106-.106.106.106a1.15 1.15 0 000-1.626l-.106.106.106-.106A1.15 1.15 0 0010 17.85H2z"
          ></path>
          <path
            fill={color}
            stroke={color}
            strokeWidth="0.3"
            d="M17.15 6A1.15 1.15 0 0016 4.85H2a1.15 1.15 0 100 2.3h14A1.15 1.15 0 0017.15 6z"
          ></path>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="32"
          fill="none"
          viewBox="0 0 22 22"
        >
          <path
            fill="#F2C94C"
            stroke="#F2C94C"
            strokeWidth="0.3"
            d="M18.85 13.302A4.11 4.11 0 0017 12.85h0A4.15 4.15 0 1021.15 17V2a1.15 1.15 0 10-2.3 0v11.302zM12.15 12.5A1.15 1.15 0 0011 11.35H2a1.15 1.15 0 000 2.3h9a1.15 1.15 0 001.15-1.15zM2 17.85a1.15 1.15 0 000 2.3h8a1.15 1.15 0 100-2.3H2z"
          ></path>
          <path
            fill="#F2C94C"
            stroke="#F2C94C"
            strokeWidth="0.3"
            d="M17.15 6A1.15 1.15 0 0016 4.85H2a1.15 1.15 0 100 2.3h14A1.15 1.15 0 0017.15 6z"
          ></path>
        </svg>
      ),
  },
  {
    id: 2,
    name: "خانه",
    link: "/home",
    icon: ({ color = "#828282" }) =>
      color == "#828282" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="32"
          fill="none"
          viewBox="0 0 22 22"
        >
          <path
            fill="#828282"
            stroke="#828282"
            strokeWidth="0.2"
            d="M17.875.9h-2.969a3.225 3.225 0 00-3.225 3.217v2.961a3.224 3.224 0 003.225 3.217h2.969A3.224 3.224 0 0021.1 7.078V4.117A3.225 3.225 0 0017.875.9zm1.462 6.178c0 .804-.655 1.458-1.462 1.458h-2.969a1.462 1.462 0 01-1.462-1.458V4.117c0-.804.656-1.459 1.462-1.459h2.969c.807 0 1.462.655 1.462 1.459v2.961zM17.875 11.705h-2.969a3.225 3.225 0 00-3.225 3.217v2.96a3.224 3.224 0 003.225 3.217h2.969a3.224 3.224 0 003.225-3.216v-2.961a3.225 3.225 0 00-3.225-3.217zm1.462 6.178c0 .803-.655 1.458-1.462 1.458h-2.969a1.462 1.462 0 01-1.462-1.458v-2.961c0-.804.656-1.459 1.462-1.459h2.969c.807 0 1.462.655 1.462 1.459v2.96zM7.094.9H4.125A3.224 3.224 0 00.9 4.117v2.961a3.224 3.224 0 003.225 3.217h2.969a3.225 3.225 0 003.225-3.217V4.117A3.225 3.225 0 007.094.9zm1.462 6.178c0 .804-.656 1.458-1.462 1.458H4.125a1.462 1.462 0 01-1.462-1.458V4.117c0-.804.655-1.459 1.462-1.459h2.969c.806 0 1.462.655 1.462 1.459v2.961zM7.094 11.653H4.125A3.225 3.225 0 00.9 14.87v2.96a3.225 3.225 0 003.225 3.218h2.969a3.225 3.225 0 003.225-3.217V14.87a3.225 3.225 0 00-3.225-3.217zm1.462 6.178c0 .804-.656 1.458-1.462 1.458H4.125a1.462 1.462 0 01-1.462-1.458V14.87c0-.804.655-1.459 1.462-1.459h2.969c.806 0 1.462.655 1.462 1.459v2.96z"
          ></path>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="32"
          fill="none"
          viewBox="0 0 22 22"
        >
          <path
            // fill="#F2C94C"
            fill={color}
            stroke={color}
            strokeWidth="0.2"
            d="M17.875.9h-2.969a3.225 3.225 0 00-3.225 3.217v2.961a3.224 3.224 0 003.225 3.217h2.969A3.224 3.224 0 0021.1 7.078V4.117A3.225 3.225 0 0017.875.9zM17.875 11.705h-2.969a3.225 3.225 0 00-3.225 3.217v2.96a3.224 3.224 0 003.225 3.217h2.969a3.224 3.224 0 003.225-3.216v-2.961a3.225 3.225 0 00-3.225-3.217zM7.094.9H4.125A3.224 3.224 0 00.9 4.117v2.961a3.224 3.224 0 003.225 3.217h2.969a3.225 3.225 0 003.225-3.217V4.117A3.225 3.225 0 007.094.9zM7.094 11.653H4.125A3.225 3.225 0 00.9 14.87v2.96a3.225 3.225 0 003.225 3.218h2.969a3.225 3.225 0 003.225-3.217V14.87a3.225 3.225 0 00-3.225-3.217z"
          ></path>
        </svg>
      ),
  },
  {
    id: 3,
    name: "جست و جو",
    link: "/search",
    icon: ({ color = "#828282" }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="32"
        fill="none"
        viewBox="0 0 20 21"
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 17.088c4.418 0 8-3.602 8-8.044C17 4.6 13.418 1 9 1S1 4.601 1 9.044c0 4.442 3.582 8.044 8 8.044zM19 19.099l-4.35-4.374"
        ></path>
      </svg>
    ),
  },
  {
    id: 4,
    name: "دانلودها",
    link: "/downloads",
    // link: "/pure-LayputTest",
    icon: ({ color = "#828282" }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="32"
        fill="none"
        viewBox="0 0 23 22"
      >
        <path
          fill={color}
          d="M9.78 2.097a8.819 8.819 0 012.442 0 .899.899 0 00.255-1.78A10.673 10.673 0 009.525.32a.899.899 0 00.254 1.78z"
        ></path>
        <path
          fill={color}
          fillRule="evenodd"
          d="M8.763 1.335A.899.899 0 019.526.318c.96-.136 1.99-.136 2.95 0 .491.07.833.525.763 1.017m.213.03a1.115 1.115 0 01-1.261.946l.03-.214-.03.214a8.603 8.603 0 00-2.38 0A1.115 1.115 0 019.495.105c.98-.14 2.03-.14 3.01 0h.001c.609.087 1.032.651.945 1.26z"
          clipRule="evenodd"
        ></path>
        <path
          fill={color}
          d="M15.903 1.391a.9.9 0 00-.818 1.6A8.958 8.958 0 0119.988 11c0 4.956-4.032 8.988-8.987 8.988-4.956 0-8.988-4.032-8.988-8.988a8.957 8.957 0 014.903-8.007.899.899 0 00-.818-1.6 10.746 10.746 0 00-5.882 9.607c0 5.947 4.838 10.785 10.785 10.785 5.946 0 10.785-4.838 10.785-10.785 0-4.071-2.254-7.753-5.883-9.608z"
        ></path>
        <path
          fill={color}
          d="M19.988 11a8.958 8.958 0 00-4.902-8.009h-.367c.077.076.167.14.268.192A8.742 8.742 0 0119.773 11c0 4.837-3.936 8.772-8.772 8.772-4.837 0-8.772-3.935-8.772-8.772a8.741 8.741 0 014.786-7.815c.1-.052.19-.117.268-.192h-.367a8.957 8.957 0 00-4.903 8.007c0 4.956 4.032 8.988 8.988 8.988 4.955 0 8.987-4.032 8.987-8.988z"
        ></path>
        <path
          fill={color}
          fillRule="evenodd"
          d="M14.878 1.263l1.124-.064-.54-.023-.513.044a1.09 1.09 0 00-.071.043z"
          clipRule="evenodd"
        ></path>
        <path
          fill={color}
          d="M22.001 11c0-4.154-2.3-7.909-6-9.8l-1.123.063a1.115 1.115 0 00-.159 1.728h.366A8.958 8.958 0 0119.988 11c0 4.956-4.032 8.988-8.987 8.988-4.956 0-8.988-4.032-8.988-8.988a8.957 8.957 0 014.903-8.007h.367c.343-.332.445-.86.216-1.308-.223-.42-.48-.55-.859-.6C6.26 1.032 6 1.2 6 1.2 2.3 3.09 0 6.846 0 11c0 6.065 4.935 11 11 11 6.066 0 11.001-4.935 11.001-11z"
        ></path>
        <path
          fill={color}
          d="M16.002 1.2a1.113 1.113 0 00-1.053.02l.512-.044.54.023zM6.77 11.982a.899.899 0 000 1.27l3.595 3.596a.895.895 0 001.271 0l3.595-3.595a.898.898 0 10-1.27-1.271l-2.062 2.06V4.709a.899.899 0 00-1.797 0v9.335l-2.06-2.061a.898.898 0 00-1.272 0z"
        ></path>
        <path
          fill={color}
          d="M6.618 11.83l.152.152.283.383-.435 1.04.822-1.729.088-.154c-.277-.046-.673.03-.91.307z"
        ></path>
        <path
          fill={color}
          d="M6.618 11.83a1.114 1.114 0 000 1.575l.435-1.04-.283-.383-.152-.153z"
        ></path>
        <path
          fill={color}
          fillRule="evenodd"
          d="M6.618 11.83a1.114 1.114 0 000 1.575l.152-1.423-.152-.153z"
          clipRule="evenodd"
        ></path>
        <path
          fill={color}
          d="M8.194 11.83s-.353-.287-.666-.308l-.088.154-.822 1.73L10.213 17a1.11 1.11 0 001.576 0l3.595-3.595a1.114 1.114 0 10-1.576-1.576l-1.693 1.693V4.708a1.115 1.115 0 00-2.229 0v8.814l-1.692-1.693z"
        ></path>
      </svg>
    ),
  },
];
