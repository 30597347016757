import { createSlice } from "@reduxjs/toolkit";

export const LimitationSlice = createSlice({
    name: 'limitations',
    initialState: {
        limitation: null
    },
    reducers: {

        setLimitation: (state, action) => {
            const { limitation } = action.payload
            state.limitation = limitation;
        }
    }
})

export const { setLimitation } = LimitationSlice.actions;

export const selectLimitation = (state) => state.limitations.limitation;

export default LimitationSlice.reducer;
