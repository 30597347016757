import { useSelector } from "react-redux";
import ProfileRecentlyMp3Placeholder from "./ProfileRecentlyMp3Placeholder";
import { SelectSeenArtist } from "../../redux/RecentlyHeard/RecentlyHeardSlice";
import HomeArtistSlider from "../home/HomeArtistSlider";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../svg/common/ChevronLeft";

function RecentlyHeardSingers() {
  const recentlySeenArtists = useSelector(SelectSeenArtist);
  const navigate = useNavigate();

  const showAll = () => {
    navigate("/my-melodify/recently-heard-singers");
  };
  return (
    <div className="flex flex-col justify-between px-3 pt-9 gap-1 ">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="h-2 w-2 bg-gray_6 me-2 rounded-full"></div>
          <p className="font-dana font-medium text-base text-gray_6">
            خواننده هایی که اخیراً دیده اید
          </p>
        </div>
        {recentlySeenArtists?.length >= 3 && (
          <div
            onClick={showAll}
            className="flex items-center justify-end cursor-pointer px-1 -mx-1"
          >
            <p className="font-dana font-medium text-sm leading-6 text-gray_6">
              همه
            </p>
            <ChevronLeft className="stroke-gray_6 w-6 h-6 -me-[2px]" />
          </div>
        )}
      </div>
      <div>
        {recentlySeenArtists?.length ? (
          <div className="-mx-4 pe-1">
            <HomeArtistSlider
              disabledTitle
              item={{ artists: recentlySeenArtists.slice(0, 15) }}
            />
          </div>
        ) : (
          <ProfileRecentlyMp3Placeholder
            title="هنوز خواننده ای را ندیده‌ اید!"
            subtitle="خواننده ی محبوبتان را جستجو کنید "
          />
        )}
      </div>
    </div>
  );
}

export default RecentlyHeardSingers;
