import AboutUsTermsSvg from "../components/svg/aboutUs/AboutUsTermsSvg.jsx";
import AboutUsPlayStoreSvg from "../components/svg/aboutUs/AboutUsPlayStoreSvg.jsx";
import AboutUsShellSvg from "../components/svg/aboutUs/AboutUsShellSvg.jsx";
import { playStoreUrl } from "./GlobalConsts.js";

export const AboutUsItems = [
  {
    key: "about-terms",
    title: "قوانین و شرایط استفاده",
    icon: <AboutUsTermsSvg />,
    action: "ROUTE",
    query: "getTerms",
  },
  {
    key: "about-play-store",
    title: "برنامه در پلی استور",
    icon: <AboutUsPlayStoreSvg />,
    action: "LINK",
    link: playStoreUrl,
  },
  {
    key: "about-copyright",
    title: "حق انتشار",
    icon: <AboutUsShellSvg />,
    action: "BOTTOM-SHEET",
    query: "getCopyright",
  },
  {
    key: "about-privacy",
    title: "حریم خصوصی",
    icon: <AboutUsShellSvg />,
    action: "BOTTOM-SHEET",
    query: "getPrivacyPolicy",
  },
];
