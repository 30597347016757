import React from 'react';

function DragDropSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="14"
            fill="none"
            viewBox="0 0 17 14"
        >
            <path
                stroke="#F2F2F2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 12.578V1.459M8.372 4l3.5-3 3.5 3M4.628 1v11.12M1 9.578l3.5 3 3.5-3"
            ></path>
        </svg>
    );
}

export default DragDropSvg;
