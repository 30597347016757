import CssShimmer from "../common/CssShimmer.jsx";
import React from "react";
function CollectionGridShimmer({count}) {
    return (
        <div className="grid grid-cols-2 justify-items-center gap-3 p-4">
            {[...Array(count).keys()].map((item) => (
                    <CssShimmer
                        key={`item${item}`}
                        classNames={"rounded-[10px] relative justify-center items-center aspect-square cursor-pointer w-full h-full"}
                    />
                ))
            }
        </div>
    );
}

export default React.memo(CollectionGridShimmer);
