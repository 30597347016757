import { memo } from "react";

function DeleteIconSvg(props) {
  return (
    <svg
      width={39}
      height={39}
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={38.655} height={38.655} rx={10} fill="#303030" />
      <path
        d="M11.5 14h15m-10.834 0v-1.666a1.667 1.667 0 0 1 1.667-1.667h3.333a1.667 1.667 0 0 1 1.667 1.667V14m2.5 0v11.667a1.667 1.667 0 0 1-1.667 1.667h-8.333a1.666 1.666 0 0 1-1.667-1.667V14h11.667Z"
        stroke="#F2C94C"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(DeleteIconSvg);
