import React from 'react'
import HomeMetaDataWithTitle from '../common/HomeMetaDataWithTitle';
import HomeTrackCardItem from './HomeTrackCardItem';

function HomeTrackCollection({item}) {

  return (
  <>
    <div dir="rtl" className="flex flex-col">
     <div className='w-full px-3'>
     <HomeMetaDataWithTitle metaData={item?.track_meta_data}/>
     </div>
      <div className='flex flex-col'>
          {item?.track_items?.map((item,index)=> <HomeTrackCardItem key={index+HomeTrackCardItem} item={item}/>)
      }
      </div>
    </div>
  </>
  )
}

export default React.memo(HomeTrackCollection)
