import React from 'react';
import PauseIconType2 from '../common/PauseIconType2.jsx';
import PlayIconType2Svg from '../svg/common/PlayIconType2Svg.jsx';
import { usePlayedTrack } from '../../hooks/usePlayedTrack.js';
import { getIsPlaying } from '../../helper/ReduxSelectorContext.js';

function SongCardDemoSection({handlePlayMusic,item }) {

  const isPlaying = getIsPlaying();
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  return (
    <div className="flex gap-2  items-center ">
      <div className="text-primary font-dana text-xs font-medium ">
        پخش دمو
      </div>
      {currentTrack?.id === item?.id && isPlaying ? (
        <div
          className="w-[32px] h-[32px]"
          onClick={() => handlePlayMusic(item)}
        >
          <PauseIconType2 />
        </div>
      ) : (
        <div
          className="w-[32px] h-[32px]"
          onClick={() => handlePlayMusic(item)}
        >
          <PlayIconType2Svg />
        </div>
      )}
    </div>
  );
}

export default React.memo(SongCardDemoSection);
