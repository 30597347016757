import React,{useEffect, useState}  from 'react';
import {mainToast} from "../../helper/ToastsEmitter.jsx";
import {useBottomNavigation} from "../BottomNavigation/useBottomNavigation.js";
import {addToPlaylistData, getPlayerMusic} from "../../helper/ReduxSelectorContext.js";

function SaveEditPlaylistButton({onSubmit,formik}) {
    const [bottom,setBottom]=useState(0)
    const [InvisibleBottomSheet] = useBottomNavigation();
    let playedTrackData = getPlayerMusic();
    const addToPlaylistDataState = addToPlaylistData()
    const computedStyle = getComputedStyle(document.querySelector('body'));
    const safeAreaInsetBottom = computedStyle.getPropertyValue('--safe-area-inset-bottom');
    const handleSubmit =()=>{
        if(formik.errors.title){
            mainToast('لطفا نام پلی لیست را بنویسید.')
        }
        onSubmit()

    }
    const calculateBottom = () => {
        let bottom = 8
        bottom += parseInt(safeAreaInsetBottom)
        if (!InvisibleBottomSheet()) {
            bottom += 66
        }
        if (playedTrackData) {
            bottom += 48
        }

        setBottom(bottom)
    }

    useEffect(() => {
        calculateBottom()
    }, [playedTrackData, InvisibleBottomSheet(),addToPlaylistDataState?.listSelectItems?.length,addToPlaylistDataState?.playerListSelectedItems?.length]);
    return (
        <button
            onClick={handleSubmit}
            style={{bottom:bottom+'px'}}
            type="button"
            className="inline-flex justify-center items-center w-[176px] !h-12 text-black text-base bg-primary fixed left-[50%] translate-x-[-50%] justify-center rounded-[22px] border border-transparent bg-blue-100 z-[5001] px-4 focus:outline-none"
        >
            <p className="font-semibold font-dana text-gray_1">
               ذخیره تغییرات
            </p>
        </button>
    );
}

export default SaveEditPlaylistButton;
