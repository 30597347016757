import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {disableSleepTimer, enableSleepTimer, setSleepTimerTime} from "../redux/player/PlayerSlice.js";
import {dispatchIsPlaying} from "../redux/globalstore/GlobalStore.js";

const useCountdown = () => {
    const [time, setTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const dispatch = useDispatch()
    const timeoutRef = useRef(null);
    const intervalRef=useRef(null);
    const handlePause = () => {
        // playerRef?.current?.player?.player.pause();
        dispatchIsPlaying(false);
    };
    const start = (minutes) => {
        clearTimeout(timeoutRef.current)
        setTime(minutes * 60);
        setIsRunning(true);
        dispatch(enableSleepTimer())
        timeoutRef.current = setTimeout(() => {
            setIsRunning(false);
            setTime(0);
            handlePause()
            dispatch(disableSleepTimer())
            // onCountdownEnd();

        }, minutes * 60 * 1000);

    };
    const clear = () => {
        dispatch(disableSleepTimer())
        setIsRunning(false);
        setTime(0);
    };
    const handleCountTime=() => {
        setTime((prevTime) => {
            if (prevTime <= 0) {
                setIsRunning(false);
                return 0;
            }
            return prevTime - 1;
        });
    }
    useEffect(() => {
        clearInterval(intervalRef.current);
        if (isRunning) {
         intervalRef.current=setInterval(handleCountTime, 1000);
        }
    }, [isRunning]);
    useEffect(() => {
        console.log('time changed :', time)
        dispatch(setSleepTimerTime({time}))
    }, [time]);

    return {
        start,
        clear,
        isRunning,
    };
};

export default useCountdown;
