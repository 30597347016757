import React,{useImperativeHandle, useState}  from 'react';
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import ErrorReportFlag from "../svg/artists/ErrorReportFlag.jsx";
import BugReportBottomSheetContent from "./BugReportBottomSheetContent.jsx";
import SwipeBottomSheet from "../bottomSheets/SwipeBottomSheet.jsx";
import {useNativeBack} from "../../hooks/useNativeBack.js";

function BugReportBottomSheet({itemType,data},ref) {
    const [bugReportDialogOpen, setBugReportDialogOpen] = useState(false);

    const toggleBugReportBottomSheet = (newOpen) => () => {
        setBugReportDialogOpen(newOpen)
    };
    const handleCloseBottomSheet = () =>{
        setBugReportDialogOpen(false)
    }
    const  closeBottomSheet=()=>{
        setBugReportDialogOpen(false)
    }
    useImperativeHandle(ref, () => ({
        openBugReportDialog (){
            setBugReportDialogOpen(true)
        }
    }));

   useNativeBack(bugReportDialogOpen,'bug-report',closeBottomSheet);
    return (
        <SwipeBottomSheet
            open={bugReportDialogOpen}
            toggleDrawer={toggleBugReportBottomSheet}
        >

            <BottomSheetHeader HeaderIcon={<ErrorReportFlag/>} dividerLine={true} closeBottomSheet={handleCloseBottomSheet} title="ارسال گزارش خطا"/>
            <BugReportBottomSheetContent itemType={itemType} itemData={data} handelModalClose={handleCloseBottomSheet}/>
        </SwipeBottomSheet>
    );
}

export default React.forwardRef(BugReportBottomSheet) ;
