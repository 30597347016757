import  React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {disLikeSong, likeSong, selectGlobalData} from "../redux/globalData/globalDataSlice.js";
import {useMutation} from "@tanstack/react-query";
import {trackDisLike, trackLike} from "../services/ApiClient.js";

export const useLike = (id) => {
    const dispatch=useDispatch()
    const {likedTracks} = useSelector(selectGlobalData);
    const requestData = {track_id: id}
    const findTrackIndex = () => {
        return likedTracks?.findIndex((itemId) => itemId === id);
    };
    const trackIndex = findTrackIndex();
    const likeTrackMutation = useMutation(() => trackLike(requestData), {});
    const disLikeTrackMutation = useMutation(() => trackDisLike(requestData), {});
    const handleLikeTrack=()=>{
        dispatch(likeSong({id}))
        likeTrackMutation.mutate()
    };
    const handleDisLikeTrack=()=>{
        dispatch(disLikeSong({trackIndex}));
        disLikeTrackMutation.mutate()
    };
    const toggleLike=()=>{
        if (trackIndex < 0) {
            handleLikeTrack()
        } else {
            handleDisLikeTrack()
        }
    };
    const isLiked = likedTracks?.find((itemId) => Number(itemId) === Number(id));
    return [isLiked,toggleLike]
}
