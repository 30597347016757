import React, {useRef,useEffect, useState,useImperativeHandle} from 'react';
import AddToPlayListSvg from "../svg/queue/AddToPlayListSvg.jsx";
import {useDispatch} from "react-redux";
import {disableListSelectMode, disablePlayerListSelectMode} from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import AddToPlaylistBottomSheet from "./AddToPlaylistBottomSheet";
import {addToPlaylistData, getPlayerMusic, globalData} from "../../helper/ReduxSelectorContext.js";

import {useBottomNavigation} from "../BottomNavigation/useBottomNavigation.js";


function AddToPlaylistModeButton({EnableEditMode,inPlayer=false,inPlayerSheet=false},ref) {
    const dispatch=useDispatch()
    const [bottom,setBottom]=useState(0)
    const addToPlaylistRef=useRef(null)

    const globalDataObject = globalData()
    const userCollections = globalDataObject?.userCollections
    const handleAddToPlaylist=()=>{
            addToPlaylistRef.current.openAddToPlaylist()
    }
    const closeSelectMode=()=>{
        dispatch(disableListSelectMode())
        dispatch(disablePlayerListSelectMode())
    }
    let playedTrackData = getPlayerMusic();

    const addToPlaylistDataState = addToPlaylistData()
    const computedStyle = getComputedStyle(document.querySelector('body'));
    const safeAreaInsetBottom = computedStyle.getPropertyValue('--safe-area-inset-bottom');
    useImperativeHandle(
        ref,
        () => ({
            openAddToPlaylistSheet() {
                addToPlaylistRef.current.openAddToPlaylist()
            }

        })
    );
    const [InvisibleBottomSheet] = useBottomNavigation();
    const calculateBottom = () => {
        let bottom = 8
        bottom += parseInt(safeAreaInsetBottom)

        if(inPlayer){
            setBottom(bottom)
            return
        }
        if (!InvisibleBottomSheet()) {
            bottom += 66
        }
        if (playedTrackData) {
            bottom += 48
        }

        setBottom(bottom)
    }
    useEffect(() => {
        calculateBottom()
    }, [playedTrackData, InvisibleBottomSheet(), inPlayer,addToPlaylistDataState?.listSelectItems?.length,addToPlaylistDataState?.playerListSelectedItems?.length]);
    return (
        <>
           <AddToPlaylistBottomSheet inPlayerSheet={inPlayerSheet} ref={addToPlaylistRef} />
            {EnableEditMode &&
                <div dir={'rtl'} style={{bottom:bottom+'px'}} className={`${inPlayer?' absolute' :' fixed'}  max-w-[520px] z-[50] px-4 w-full flex gap-3   left-[50%] translate-x-[-50%] `}>
                    <div onClick={handleAddToPlaylist} className='rounded-[33px]  flex gap-4 origin-top-left h-[46px] w-[calc(100%_-_58px)] items-center justify-center  bg-add-to-playlist-button-gradient shadow'>
                        <div className='w-[20px] h-[20px]'>
                            <AddToPlayListSvg fill={'#ffffff'}/>
                        </div>
                        <p className="text-[16px] font-dana font-medium text-white">
                            افزودن به پلی لیست
                        </p>
                    </div>
                    <div onClick={closeSelectMode} className='cursor-pointer flex justify-center items-center left-4   transition-all    rounded-full !w-[46px]   !h-[46px]   lg-1440:border-b1 bg-scroll-top-button-background'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 5L5 15M5 5l10 10"
                            ></path>
                        </svg>
                    </div>
                </div>

            }
        </>

    );
}

export default React.forwardRef(AddToPlaylistModeButton);
