import React, { useState } from 'react';
import ClickReaction from '../../helper/ClickReaction.jsx';
import { useFollow } from '../../helper/useFollow.js';
import { playStoreUrl } from '../../consts/GlobalConsts.js';
import Loading from '../loading/Loading.jsx';
import UnfollowDialog from '../common/UnfollowDialog.jsx';
import RenderShare from '../common/RenderShare.jsx';
import { shareTypes } from '../../consts/shareTypes.js';
import ShareSvgType2 from '../svg/common/ShareSvgType2.jsx';
import { getInitialData } from '../../helper/ReduxSelectorContext.js';

function ArtistProfileFollowArtistSection(artistId) {
  const [isFollow, toggleFollow, unfollowArtist, followArtistLoading] =
    useFollow(artistId?.artistId);
  const [followDialogState, setFollowDialogState] = useState(false);
  const { setting } = getInitialData() || {};
  const closeDialog = e => {
    console.log(e);
    if (typeof e === 'object') {
      e?.stopPropagation();
    }
    setFollowDialogState(false);
  };
  const openDialog = () => {
    setFollowDialogState(true);
  };
  const unfollowArtistHandler = e => {
    unfollowArtist();
    closeDialog(e);
  };
  const handleFollowArtist = e => {
    e?.stopPropagation();
    e?.preventDefault();
    if (followArtistLoading) {
      return;
    }
    if (isFollow) {
      openDialog();
    } else {
      toggleFollow();
    }
  };
  const shareArtist = () => {
    navigator
      .share({
        title: 'اشتراک',
        text:
          'دانلود و شنیدن  آهنگ های' +
          ' " ' +
          artistObject?.name +
          ' " ' +
          'در اپلیکیشن ملودیفای \n' +
          '\nلینک برنامه :' +
          '\n' +
          '\n' +
          playStoreUrl,
      })
      .then(() => console.log('Yay, you shared it :)'))
      .catch(error => {
        console.log(`this error happened during sharing : + ${error}`);
      });
  };

  return (
    <>
      <UnfollowDialog
        closeDialog={closeDialog}
        openState={followDialogState}
        confirmFunction={unfollowArtistHandler}
        type="artist"
      />
      <div className="w-full flex flex-row items-end justify-between relative px-4 gap-3">
        {setting?.enable_artist_share &&
          <div className="h-[46px] aspect-square">
            <RenderShare id={artistId?.artistId} type={shareTypes?.ARTIST}>
              <ShareSvgType2 />
            </RenderShare>
          </div>
        }
        <ClickReaction style={`${setting?.enable_artist_share ? ' w-[calc(100%_-_58px)]': 'w-full'}`}>
          <div
            onClick={handleFollowArtist}
            className={` ${
              isFollow
                ? ' text-white border-white '
                : ' border-primary text-title_box bg-primary'
            } border-[1px] font-dana text-base font-medium w-full rounded-[30px] h-[46px] flex justify-center items-center gap-4  `}
          >
            {followArtistLoading ? (
              <Loading bgColor={isFollow ? '#ffffff' : '#303030'} />
            ) : (
              <span>{isFollow ? 'دنبال میکنید' : 'دنبال‌کردن'}</span>
            )}
          </div>
        </ClickReaction>
      </div>
    </>
  );
}

export default ArtistProfileFollowArtistSection;
