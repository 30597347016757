import React, { useEffect } from "react";
import { createSearchMetadata } from "../../../helper/createSearchMetadata";
import ArtistMetaDataIcon from "../../../components/svg/search/ArtistMetaDataIcon";
import { getSearchItem } from "../../../consts/Search";
import SearchMetaData from "../../../components/search/SearchMetaData";
import {
  getArtistResultData,
  getCommitSearch,
  getCommitedSearchQuery,
  getSearchQuery,
} from "../../../helper/ReduxSelectorContext";
import HomeArtistCard from "../../../components/home/HomeArtistCard";
import { useNavigate } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getSearchResultV8 } from "../../../services/ApiClient";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMemo } from "react";
import SongListLoading from "../../../components/loading/SongListLoading";
import CssShimmer from "../../../components/common/CssShimmer";
import { useStoreSearchHistory } from "../../../hooks/useStoreSearchHistory";
import { SearchItemType } from "../../../consts/SearchItemType";
import SearchNoResultView from "../SearchNoResultView";

function ArtistsSearchResultTab({ scrollTop }) {
  const [, storeSearchItemWithType] = useStoreSearchHistory();
  const navigate = useNavigate();

  const searchparam = getCommitedSearchQuery();
  const searchCommit = getCommitSearch();
  const getSearchArtistsDataQuery = useInfiniteQuery(
    [`getSearchArtistsDataQuery` + searchparam],
    ({ pageParam = 0 }) =>
      getSearchResultV8({
        q: searchparam,
        type: 2,
        offset: pageParam,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.result?.end) return null;
        const allData = allPages?.flatMap(
          (page) => page?.data?.result?.artists
        );
        return allData?.length;
      },
      onSuccess: (res) => {
        console.log("dgsdjgkjhsfsdfsdjkgh", res);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const artistLists = useMemo(
    () =>
      getSearchArtistsDataQuery?.data?.pages?.flatMap((page) => {
        if (page?.data?.error) return [];
        return page?.data?.result?.artists;
      }),
    [getSearchArtistsDataQuery?.data]
  );
  const getMonthNewSongNextPage = () => {
    if (
      getSearchArtistsDataQuery?.hasNextPage &&
      !getSearchArtistsDataQuery?.isFetchingNextPage
    ) {
      getSearchArtistsDataQuery.fetchNextPage();
    }
  };

  const storeInSearchHistory = (item) => {
    storeSearchItemWithType(
      { artist: item, id: item?.id },
      SearchItemType()?.artist
    );
  };
  const openArtist = (id, item) => {
    storeInSearchHistory(item);
    navigate(`/artist-profile/${id}`, { state: { artistObject: item } });
  };

  useEffect(() => {
    getSearchArtistsDataQuery.refetch();
  }, [searchCommit]);
  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <div className="w-full">
      {getSearchArtistsDataQuery.isLoading ? (
        <div className="grid grid-cols-3 gap-4 mt-4">
          {Array.from(
            [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
              <div className="w-full h-full aspect-square flex flex-col gap-2 items-center">
                <CssShimmer classNames={"h-24 w-24 rounded-full"} />
                <CssShimmer classNames={"h-3 w-24 rounded-[6px]"} />
              </div>
            ))
          )}
        </div>
      ) : artistLists?.length == 0 ? (
        <div className="w-full h-full flex justify-center items-center mt-14">
          <SearchNoResultView />
        </div>
      ) : (
        <InfiniteScroll
          className="!overflow-hidden pb-8"
          scrollableTarget="search-result-parent"
          dataLength={artistLists?.length || 0}
          next={getMonthNewSongNextPage}
          hasMore={getSearchArtistsDataQuery?.hasNextPage}
          loader={
            <div className="w-full grid justify-center items-center mt-4">
              <SongListLoading />
            </div>
          }
        >
          <div
            dir="rtl"
            className="grid max-[360px]:px-4 grid-cols-3 w-full gap-4   mt-3 "
          >
            {artistLists?.map((item, index) => (
              <div
                key={index + "artist-item"}
                onClick={() => openArtist(item?.id, item)}
              >
                <HomeArtistCard item={item} />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
}

export default React.memo(ArtistsSearchResultTab);
