const ExitFullScreenSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    {...props}
  >
    <path
      d="M5.83333 2.7666V4.7666C5.83333 5.12022 5.69286 5.45936 5.44281 5.70941C5.19276 5.95946 4.85362 6.09993 4.5 6.09993H2.5M14.5 6.09993H12.5C12.1464 6.09993 11.8072 5.95946 11.5572 5.70941C11.3071 5.45936 11.1667 5.12022 11.1667 4.7666V2.7666M11.1667 14.7666V12.7666C11.1667 12.413 11.3071 12.0738 11.5572 11.8238C11.8072 11.5737 12.1464 11.4333 12.5 11.4333H14.5M2.5 11.4333H4.5C4.85362 11.4333 5.19276 11.5737 5.44281 11.8238C5.69286 12.0738 5.83333 12.413 5.83333 12.7666V14.7666"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ExitFullScreenSvg;
