import React from 'react';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';
import { useAction } from '../../hooks/useAction.js';
import PlaceHolderWithTitle from '../../components/common/PlaceHolderWithTitle.jsx';
import { CardActionArea } from '@mui/material';
import ClickReaction from '../../helper/ClickReaction';

function GeneralCollectionCardItem({ item }) {
  const action = useAction();

  const handleSelectItem = collection => {
    action({
      target_type: 'collection',
      view_type: 'general_collections',
      collection,
    });
  };
  return (
    <div
      onClick={() => {
        handleSelectItem(item);
      }}
      className=" rounded-[10px]  "
    >
      <ClickReaction>
        <CardActionArea className=" !rounded-[10px]">
          <div className="aspect-square  rounded-[10px]  flex items-center justify-center relative overflow-hidden">
            <CustomLazyLoadImage image={item?.image} />
            <PlaceHolderWithTitle title={item?.title} />
          </div>
        </CardActionArea>
      </ClickReaction>
    </div>
  );
}

export default GeneralCollectionCardItem;
