import React from 'react';

function TelegramSvgIcon(props) {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <g clipPath="url(#clip0_1098_784)">
                <path
                    fill="#fff"
                    d="M19.694 2.15c-.244-.3-.61-.465-1.032-.465-.227 0-.468.048-.716.141L1.014 8.242c-.9.341-1.022.855-1.014 1.13.008.275.157.777 1.063 1.067l3.591 1.316 1.893 4.332c.19.599.588 1.02 1.095 1.156.117.031.236.047.357.047.412 0 .833-.182 1.198-.524l1.568-1.473 3.825 2.633c.345.255.72.39 1.085.389.742 0 1.334-.543 1.508-1.384l2.77-13.393c.113-.55.021-1.044-.26-1.388zM5.844 11.55l4.887-2.62-3.406 3.124a.585.585 0 00-.189.431v.018l-.013 1.976-1.279-2.929zm2.55 4.36a1.11 1.11 0 01-.108.09l.015-2.404 1.476 1.016-1.383 1.299zm10.41-12.61l-2.768 13.393c-.028.135-.12.45-.36.45-.113 0-.253-.059-.394-.164a.562.562 0 00-.018-.013l-4.218-2.903-.002-.002-2.384-1.64 6.299-5.78a.586.586 0 00-.673-.947l-9.24 4.955-3.592-1.316a.561.561 0 00-.007-.003l16.915-6.409a.863.863 0 01.3-.065c.029 0 .099.003.123.033.031.038.07.167.02.41z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1098_784">
                    <path fill="#fff" d="M0 0H20V20H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default TelegramSvgIcon;
