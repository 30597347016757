import React, { memo } from 'react';
import EditPlaylistNameSvg from '../svg/playList/EditPlaylistNameSvg';
import { useNavigate } from 'react-router-dom';
import ShareGiftSvg from '../svg/gift/ShareGiftSvg';

function UserGiftCard({
  title,
  name,
  description,
  paid_at,
  isUse = false,
  code,
  status,
  id,
  share_text,
}) {
  const navigate = useNavigate();
  const goToShareGift = () => {
    navigate(`/share-gift`, { state: share_text });
  };

  return (
    <div className="bg-secondary w-full h-[148px] rounded-[10px] p-3 flex flex-col gap-3">
      <div className="border-b-[1px] border-b-gray_1 pb-2 flex h-[100px]">
        <div className="flex-1 flex flex-col justify-between max-w-[75%] ">
          <p className="text-primary font-dana text-base font-semibold line-clamp-2 break-words">
            {title}
          </p>
          <div className="inline-flex font-dana text-xs font-normal gap-1">
            <p className="text-gray_4 whitespace-nowrap ">هدیه به: </p>
            <p className="text-white line-clamp-2 break-words">{name}</p>
          </div>
          <div className="inline-flex font-dana text-xs font-normal gap-1">
            <p className="text-gray_4 whitespace-nowrap ">تاریخ: : </p>
            <p dir="ltr" className="text-white tracking-wider">
              {paid_at}
            </p>
          </div>
        </div>

        <div
          className={`flex-1 flex flex-col items-end ${
            isUse ? 'justify-end' : 'justify-between'
          }`}
        >
          {!isUse && (
            <div
              onClick={() =>
                navigate(`/edit-gift/${id}`, { state: { name, description } })
              }
              className="flex gap-2 items-center cursor-pointer"
            >
              <EditPlaylistNameSvg className="w-[18px] h-[18px]" />

              <p className="text-xs font-normal font-dana text-white">ویرایش</p>
            </div>
          )}
          <div className="h-6 w-[108px] bg-black_2 rounded-xl flex items-center justify-center text-center">
            <p
              style={{ color: status?.color }}
              className="w-fit  font-dana text-xs font-normal "
            >
              {status.title}
            </p>
          </div>
        </div>
      </div>
      <div className=" h-12 flex gap-6 items-center justify-between">
        <div className="inline-flex gap-1 items-center relative ">
          <p className="text-gray_4 text-xs font-normal font-dana absolute">
            کد:
          </p>
          <p className="text-white text-sm font-medium font-poppins absolute -top-3 right-5">
            {' '}
            {code}
          </p>
        </div>
        {!isUse && (
          <div
            onClick={goToShareGift}
            className="flex gap-2 items-center cursor-pointer"
          >
            <ShareGiftSvg className="fill-primary" />
            <p className="text-primary text-sm font-dana font-normal ">
              ارسال به دوست
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(UserGiftCard);
