import {createSlice} from "@reduxjs/toolkit";


const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        currentRoute: {
            pathName: '',
            key: '',
        },
        firstRouteObject: {
            pathName: '',
            key: '',
        },
        navigationList: [],
        activeForwardButton: false,
        activeBackButton: false,
    },
    reducers: {
        updateCurrentRoute: (state, action) => {
            const {key, pathName} = action.payload;
            state.currentRoute.pathName = pathName;
            state.currentRoute.key = key;
        },
        updateNavigation: (state, action) => {
            const {newRouteObject} = action.payload;
            if (state.navigationList.length === 0) {
                state.navigationList.push(newRouteObject);
                return;
            }
            const routeExist = doesRouteExist(state.navigationList, newRouteObject);
            if (routeExist) {
                return;
            }
            const currentRouteIndex = findRouteIndex(state.navigationList, state.currentRoute);
            state.navigationList = makeNewRouteList(state.navigationList, currentRouteIndex, newRouteObject);

        },
        setForwardButtonState: (state) => {
            if (state.navigationList.length === 0) {
                state.activeForwardButton = false;
                return
            }
            const currentRouteIndex = findRouteIndex(state.navigationList, state.currentRoute);
            if (currentRouteIndex === state.navigationList.length - 1) {
                state.activeForwardButton = false;
            } else {
                state.activeForwardButton = true;
            }
        },
        setBackButtonState: (state) => {
            if (state.navigationList.length === 0) {
                state.activeBackButton = false;
                return
            }
            const currentRouteIndex = findRouteIndex(state.navigationList, state.currentRoute);
            if (currentRouteIndex === 0) {
                state.activeBackButton = false;
            } else {
                state.activeBackButton = true;
            }
        },
    }
})
export const selectNavigation = (state) => state.navigation
export const {updateCurrentRoute, updateNavigation, setForwardButtonState, setBackButtonState} = navigationSlice.actions
export default navigationSlice.reducer;

const findRouteIndex = (array, routeObject) => {
    return array?.findIndex((routeItem) => routeItem?.key === routeObject?.key && routeItem?.pathName === routeObject?.pathName);
};
const doesRouteExist = (routeList, routeObject) => {
    return routeList.some((routeItem) => routeItem?.key === routeObject?.key && routeItem?.pathName === routeObject?.pathName);
};
const clearExtraRoute = (routeList, index) => {
    routeList = routeList.filter((item, itemIndex) => itemIndex < index + 1)
    return routeList
};
const makeNewRouteList = (list, currentRouteIndex, newRouteObject) => {
    if (currentRouteIndex === list.length - 1) {
        list.push(newRouteObject);
    } else {
        list = clearExtraRoute(list, currentRouteIndex)
        list.push(newRouteObject);
    }
    return list;
}
