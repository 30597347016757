import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const drawerBleeding = 56;

function SwipeBottomSheet(props) {
    const {children, toggleDrawer, open,keepMount=false} = props;
    return (
        <SwipeableDrawer
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: "520px",
                    margin: "auto",
                    borderRadius: 0,
                    backgroundColor: "#212121",
                },
            }}
            transitionDuration={{
                enter: 250,
                exit: 300,
            }}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
                keepMounted: keepMount,
            }}
        >
            {children}
        </SwipeableDrawer>

    );
}

export default React.memo(SwipeBottomSheet);
