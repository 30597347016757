import { useNavigate } from "react-router-dom";
import CssShimmer from "../common/CssShimmer";
import ProfileGeneratedCollectionCard from "./ProfileGeneratedCollectionCard";
import { memo, useEffect, useState } from "react";

const ProfileGeneratedCollectionsSlider = ({ collections, isLoading }) => {
  const _collections = [
    { title: null, image: null },
    { title: null, image: null },
    { title: null, image: null },
  ];

  return (
    <div className="flex overflow-x-auto scrollbar-none hide-scroll-bar my-3.5  ">
      <div className="flex gap-6 px-4">
        {/* <div className="flex-shrink-0 inline-flex gap-1.5"> */}
        <ProfileGeneratedCollectionCard
          data={isLoading ? _collections : collections}
          onClick={() => handleClickCategory}
        />
        {/* </div> */}
      </div>

      {/*}*/}
    </div>
  );
};

export default memo(ProfileGeneratedCollectionsSlider);
