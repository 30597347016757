import React from "react";
import HomeMetaDataWithTitle from "../common/HomeMetaDataWithTitle";
import HomeArtistSlider from "./HomeArtistSlider";

function HomeArtistsView({ item }) {
  return (
    <div className="w-full mt-4  bg-artist-dark z-20">
      <div className="w-full   pr-3    z-10  mt-2 relative">
        <HomeMetaDataWithTitle
          metaData={item?.artist_meta_data}
          eventClassName={{
            prev: "prev-button-artist",
            next: "next-button-artist",
          }}
        />
      </div>

      <div className="flex flex-col w-full pb-8 gap-[14px] ">
        {item?.artist_items?.map((artists,index) => (
          <HomeArtistSlider key={index+"HomeArtistSlider"} item={artists} />
        ))}
      </div>
    </div>
  );
}

export default HomeArtistsView;
