import React from "react";

function ClearSearchIconSvg() {
  return (
    <>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="12" r="12" fill="#D9D9D9"/>
<path d="M16 8L8 16" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 8L16 16" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    </>
  );
}

export default ClearSearchIconSvg;
