const AarrowLeft = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#F2C94C"
    {...props}
  >
    <path
      d="M19 12H5m7 7-7-7 7-7"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default AarrowLeft;
