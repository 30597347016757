import React from 'react';

function CloseBottomSheetIconSvg({fill}) {
    return (
        <svg
           className='w-full h-full'
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"

        >
            <path
                d="M11 1L1 11M1 1L11 11"
                stroke={fill?fill:"white"}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default CloseBottomSheetIconSvg;
