import React from 'react';
import { useAction } from '../../hooks/useAction';
import { CardActionArea } from '@mui/material';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

function HomeExclusiveTracksBanner({ banner }) {
  const action = useAction();

  const openBanners = () => {
    action(banner.action);
  };

  return (
    <div
      onClick={openBanners}
      className=" min-h-[76px] w-full drop-shadow-exclusive-tracks-banner rounded-[10px] bg-exclusive-tracks-banner-placeholder overflow-hidden  "
    >
      <CardActionArea className=" !h-full !w-full">
        <CustomLazyLoadImage
          alt=""
          image={banner?.image}
          imageClass="w-full h-full object-fill !relative "
        />
      </CardActionArea>
    </div>
  );
}

export default HomeExclusiveTracksBanner;
