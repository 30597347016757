import React from "react";

function VIPTracksSVG({color}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="21"
            fill="none"
            viewBox="0 0 16 21"
        >
            <path
                fill={` ${color}`}
                d="M12.299 5.453a.663.663 0 00-.728-.274.62.62 0 00-.474.593c0 .75-.643 1.361-1.433 1.361s-1.433-.61-1.433-1.361V.615a.616.616 0 00-.4-.568.673.673 0 00-.706.133C7.053.25 5.326 1.9 3.577 4.393 2.545 5.863 1.722 7.32 1.13 8.727.38 10.508 0 12.213 0 13.795 0 17.768 3.402 21 7.583 21c4.182 0 7.584-3.232 7.584-7.205 0-2.543-.965-5.35-2.868-8.342zM7.583 19.77c-3.467 0-6.288-2.68-6.288-5.975 0-3.243 1.82-6.52 3.345-8.7a31.119 31.119 0 012.296-2.884v3.56c0 1.43 1.224 2.593 2.728 2.593.92 0 1.735-.435 2.23-1.1 1.313 2.351 1.978 4.543 1.978 6.53 0 3.295-2.821 5.976-6.289 5.976z"
            ></path>
        </svg>
    );
}

export default VIPTracksSVG;