import { createSlice } from "@reduxjs/toolkit";

export const UploadFileSlice = createSlice({
  name: "UploadFile",
  initialState: {
    hasErrorApi: false,
  },
  reducers: {
    setHasErrorApi: (state, action) => {
      const { hasErrorApi } = action.payload;
      state.hasErrorApi = hasErrorApi;
    },
  },
});

export const { setHasErrorApi } = UploadFileSlice.actions;

export const getHasErrorApi = (state) => state.UploadFile?.hasErrorApi;

export default UploadFileSlice.reducer;
