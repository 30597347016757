import React, { useEffect, useState, Fragment } from 'react';
import {useFollow} from "../../helper/useFollow.js";
import UnfollowDialog from '../common/UnfollowDialog.jsx';

function  ExclusiveTrackCardFollowSection({item}) {

    const [isFollow, toggleFollow,unfollowArtist,followArtistLoading] = useFollow(item?.artists[0]?.id);
    const [followDialogState,setFollowDialogState] = useState(false);
    const [followed,setFollowed] =useState(isFollow)
    const closeDialog = (e) => {
        console.log(e);
        if( typeof e === 'object'){
            e?.stopPropagation()
        }
        setFollowDialogState(false);
    }
    const openDialog = () => {
        setFollowDialogState(true);
    };
    const handleFollow = (e) => {

        e?.stopPropagation();
        e?.preventDefault();
        if(followArtistLoading){
            return
        }
        if(followed){
            openDialog()
        }else{
            followArtist()
        }
    };
    const unfollowArtistHandler = (e) => {
        setFollowed(false)
        unfollowArtist()
        closeDialog(e)
    };
    const followArtist = () =>{
        setFollowed(true)
        toggleFollow()
    };
    useEffect(() => {
        setFollowed(isFollow)
    }, [isFollow]);

    return (
      <>
          <UnfollowDialog closeDialog={closeDialog} openState={followDialogState} confirmFunction={unfollowArtistHandler} type='artist'/>
          <div className=' px-4 h-full flex justify-center items-center' onClick={handleFollow}>
              {followed ?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  fill="none"
                  viewBox="0 0 18 20"
                >
                    <path
                      fill="#828282"
                      d="M8.44.81c-2.91 0-5.284 2.382-5.284 5.292 0 1.779.89 3.354 2.244 4.313C2.241 11.681 0 14.77 0 18.374a.833.833 0 001.667 0 6.896 6.896 0 016.909-6.908c1.335 0 2.574.381 3.63 1.034a.834.834 0 001.152-.27.837.837 0 00-.274-1.153 8.562 8.562 0 00-1.523-.722 5.277 5.277 0 002.17-4.253c0-2.91-2.382-5.291-5.292-5.291zm0 1.669a3.614 3.614 0 013.624 3.623 3.609 3.609 0 01-3.625 3.616 3.602 3.602 0 01-3.616-3.616 3.607 3.607 0 013.616-3.623zm8.433 10.001a.834.834 0 00-.677.383l-2.872 4.305-1.802-1.206a.837.837 0 10-.93 1.392l2.505 1.666a.835.835 0 001.156-.234l3.335-5.002a.833.833 0 00-.715-1.304z"
                    ></path>
                </svg>
                :
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  fill="none"
                  viewBox="0 0 18 20"
                >
                    <path
                      fill="#F2C94C"
                      d="M8.58.792c-2.91 0-5.29 2.382-5.29 5.291a5.28 5.28 0 002.25 4.313c-3.159 1.267-5.4 4.356-5.4 7.96-.024 1.136 1.693 1.136 1.668 0a6.896 6.896 0 016.909-6.908c1.335 0 2.574.38 3.63 1.033.948.582 1.82-.84.872-1.422a8.562 8.562 0 00-1.52-.721 5.279 5.279 0 002.166-4.255c0-2.91-2.375-5.291-5.285-5.291zm0 1.668a3.609 3.609 0 013.618 3.623A3.603 3.603 0 018.58 9.7a3.607 3.607 0 01-3.623-3.617A3.613 3.613 0 018.58 2.46zm5.112 14.18v1.667c.024 1.086 1.643 1.086 1.667 0V16.64h1.668c1.113 0 1.113-1.668 0-1.668H15.36v-1.667a.834.834 0 10-1.667 0v1.667h-1.706c-1.152.053-1.072 1.721.04 1.668h1.666z"
                    ></path>
                </svg>
              }

          </div>
      </>

    );
}

export default ExclusiveTrackCardFollowSection;
