import React, { useEffect, useRef } from "react";
import {
  getSearchResult,
  getSearchResultV8,
  getSearchSuggestion,
} from "../../services/ApiClient";
import { useMutation } from "@tanstack/react-query";
import {
  getCommitSearch,
  getSearchCashResult,
  getSearchQuery,
} from "../../helper/ReduxSelectorContext";
import { useDispatch } from "react-redux";
import {
  setCacheStored,
  setSearchResult,
  storeSearchResult,
} from "../../redux/search/SearchSlice";
import { EncodeObject } from "../../helper/Base64Cript";
let abortSearchController = new AbortController();
function SearchGlobalComponent() {
  const commitSearch = getCommitSearch();
  const searchparam = getSearchQuery();
  const SearchCashResult = getSearchCashResult();
  const searchLimit = 10;
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  const getSearchTrackDataMutation = useMutation(
    (searchData) => getSearchResultV8(searchData),
    {
      onSuccess: (res) => {
        if (!res?.data?.done) return;
        dispatch(
          setSearchResult({ result: res?.data?.result, type: "trackResult" })
        );
        handleStoreSearchResult(searchparam, res?.data?.result, "trackResult");
      },
      refetchOnWindowFocus: false,
    }
  );

  const getSearchArtistDataMutation = useMutation(
    (searchData) => getSearchResultV8(searchData),
    {
      onSuccess: (res) => {
        if (!res?.data?.done) return;
        dispatch(
          setSearchResult({ result: res?.data?.result, type: "artistResult" })
        );

        handleStoreSearchResult(searchparam, res?.data?.result, "artistResult");
      },
      refetchOnWindowFocus: false,
    }
  );

  const getSearchAlbumDataMutation = useMutation(
    (searchData) => getSearchResultV8(searchData),
    {
      onSuccess: (res) => {
        if (!res?.data?.done) return;
        dispatch(
          setSearchResult({ result: res?.data?.result, type: "albumResult" })
        );
        handleStoreSearchResult(searchparam, res?.data?.result, "albumResult");
      },
      refetchOnWindowFocus: false,
    }
  );

  const getSearchPlaylistDataMutation = useMutation(
    (searchData) => getSearchResultV8(searchData),
    {
      onSuccess: (res) => {
        if (!res?.data?.done) return;
        dispatch(
          setSearchResult({ result: res?.data?.result, type: "playlistResult" })
        );
        handleStoreSearchResult(
          searchparam,
          res?.data?.result,
          "playlistResult"
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  const getAllSearchMutation = useMutation(
    (searchData) => getSearchResultV8(searchData),
    {
      onSuccess: (res) => {
        if (!res?.data?.done) return;
        dispatch(
          setSearchResult({
            result: res?.data?.result,
            type: "allSearchResult",
          })
        );
        handleStoreSearchResult(
          searchparam,
          res?.data?.result,
          "allSearchResult"
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  const handleStoreSearchResult = (searchParam, result, type) => {
    let encodeObj = EncodeObject(result);
    const obj = {
      query: searchParam,
      type,
      encodeObj,
    };
    dispatch(storeSearchResult({ searchResult: obj }));
    console.log("SDgsdgdsgdsgsdg", obj);
  };

  const handleUseCashBeforeSearch = async () => {
    dispatch(setCacheStored({ query: searchparam }));
  };

  useEffect(() => {
    handleUseCashBeforeSearch();
    abortSearchController.abort();
    abortSearchController = new AbortController();

    if (isMounted.current) {
      handleSearchRequest();
    } else {
      isMounted.current = true;
    }
  }, [commitSearch]);

  const handleSearchRequest = () => {
    // getSearchTrackDataMutation.mutate({
    //   q: searchparam,
    //   offset: 0,
    //   type: 1,
    //   signal: abortSearchController.signal,
    // });
    // getSearchArtistDataMutation.mutate({
    //   q: searchparam,
    //   offset: 0,
    //   type: 2,
    //   limit: searchLimit,
    //   signal: abortSearchController.signal,
    // });
    // getSearchAlbumDataMutation.mutate({.
    //   q: searchparam,
    //   offset: 0,
    //   type: 3,
    //   limit: searchLimit,
    //   signal: abortSearchController.signal,
    // });
    // getSearchPlaylistDataMutation.mutate({
    //   q: searchparam,
    //   offset: 0,
    //   type: 5,
    //   limit: searchLimit,
    //   signal: abortSearchController.signal,
    // });

    // getAllSearchMutation.mutate({
    //   q: searchparam,
    //   offset: 0,
    //   type: 6,
    //   // limit: searchLimit,
    //   signal: abortSearchController.signal,
    // });
  };

  return <></>;
}

export default SearchGlobalComponent;
