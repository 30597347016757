import React from 'react';

import { setLanguageDirection } from '../../../helper/LanguageDetection';
import PlaylistAndSongSearchResultAvatar from './PlaylistAndSongSearchResultAvatar';
import ArtistSearchResultAvatar from './ArtistSearchResultAvatar';
import AlbumSearchResultAvatar from './AlbumSearchResultAvatar';
import { CardActionArea } from '@mui/material';
import { useAction } from '../../../hooks/useAction';
import { useStoreSearchHistory } from '../../../hooks/useStoreSearchHistory';
import AlbumCardPlaceHolder from '../../../components/svg/album/AlbumCardPlaceHolder';
import ArtistSearchIcon from '../../../components/svg/search/searchTypeIcon/ArtistSearchIcon';
import { textFontBasedLanguage } from '../../../helper/TextFontBasedLanguage.js';
function SearchResultAlbum({ item, fromArtistTab, fromHistory, index }) {
  const [storeSearchItem] = useStoreSearchHistory(fromHistory);
  const action = useAction();
  const openAlbum = albumItem => {
    action({ target_type: 'open_album', albumObject: albumItem });
    storeSearchItem({ ...item, id: albumItem?.id });
  };
  return (
    <CardActionArea
      onClick={() => openAlbum(item?.album)}
      className={`${
        fromHistory ? '' : '!py-[10px]'
      }  !my-2 !rounded-[5px] !px-3 ${
        fromHistory
          ? ''
          : index % 2 !== 0
          ? 'bg-song-detail-card-background-2'
          : 'bg-song-detail-card-background'
      }`}
    >
      <div dir="rtl" className="flex justify-between items-center ">
        <span
          className={`${
            fromArtistTab ? 'flex' : 'invisible'
          } text-[#9A9A9A] font-dana text-xs`}
        >
          {item?.album?.tracks_count} آهنگ
        </span>
        <div className="flex gap-2 items-center ">
          <div className="flex flex-col items-end gap-[6px] font-dana xl:ml-3">
            <p
              dir="ltr"
              className={`text-white text-sm font-medium line-clamp-1 max-w-[68vw] min-[520px]:max-w-sm ${textFontBasedLanguage(item?.album?.title)} `}
            >
              {item?.album?.title}
            </p>
            <div
              dir="rtl"
              className="w-full flex flex-row items-center justify-end gap-1"
            >
              <span className="pl-[2px]">
                <ArtistSearchIcon />
              </span>
              {/* { (
              item?.album?.artists?.map((artistName, index) => (
                <p className="text-gray_4 text-xs font-normal   ">
                  {artistName?.name}
                  {index !== item?.artists?.length - 1 &&
                    (setLanguageDirection(item?.title) === "rtl" ? "، " : ", ")}
                </p>
              ))
            ) } */}
              <div className="flex flex-row gap-[2px] items-center">
                <p dir="rtl" className="text-gray_4 text-xs font-normal   ">
                  {item?.model_type_title}
                </p>
                <p dir="rtl" className="text-gray_4 text-xs font-normal   ">
                  -
                </p>
                <p dir="rtl" className="text-gray_4 text-xs font-normal   ">
                  {''}
                  {item?.album?.artists && item?.album?.artists[0]?.name}
                </p>
              </div>
            </div>
          </div>
          <div
            className={`${
              fromArtistTab ? 'w-16 h-16 ' : 'w-[64px] h-[64px] '
            } relative`}
          >
            <AlbumSearchResultAvatar image={item?.album?.image} />
          </div>
        </div>
      </div>
    </CardActionArea>
  );
}

export default SearchResultAlbum;
