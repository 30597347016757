import React from 'react'

const UserAvatarSvg = () => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
      className='w-[18px] h-[18px]'
     >
      <path fill="Gray3" d="M1.5 20.286v-1.143a8 8 0 1116 0v1.143"></path>
      <path
        fill="#00000000"
        stroke="@color/colorGray3"
        strokeWidth="2.4"
        d="M1.5 20.286v-1.143a8 8 0 1116 0v1.143h-16z"
      ></path>
      <path
        fill="Gray3"
        stroke="@color/colorGray3"
        strokeWidth="2.4"
        d="M9.5 11.143A4.572 4.572 0 109.5 2a4.572 4.572 0 000 9.143z"
      ></path>
    </svg>
  );
}

export default UserAvatarSvg