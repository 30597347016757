import { CardActionArea } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../svg/common/SearchIcon";
import CommonHeader from "./CommonHeader";

/**
 * A button component that navigates to the search page when clicked
 *
 * @component
 * @param {object} props - The component props
 * @param {string} [props.className=""] - An optional class name for the button container
 * @param {string} [props.iconClassName=""] - An optional class name for the search icon
 * @param {object} [props.iconProps] - An optional object containing props to pass to the search icon
 * @returns {JSX.Element} A button element that navigates to the search page when clicked
 *
 * @example
 * // Example usage:
 * import SearchButton from './SearchButton';
 *
 * function MyComponent() {
 *   return (
 *     <div>
 *       <SearchButton className="my-2" />
 *     </div>
 *   );
 * }
 */
function SearchButton({
  className = "",
  iconClassName = "",
  iconProps,
  ...rest
}) {
  const navigate = useNavigate();

  const navToSearchPage = () => {
    navigate("/search");
  };

  return (
    <CardActionArea
      {...rest}
      className={`!w-9 !h-9 !p-2 !-m-2 !rounded-full ${className}`}
      onClick={navToSearchPage}
    >
      <SearchIcon className={`w-full h-full ${iconClassName}`} {...iconProps} />
    </CardActionArea>
  );
}

SearchButton.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconProps: PropTypes.object,
};

export default SearchButton;
