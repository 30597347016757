import { memo } from "react";

const AddTicketSvg = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.666 14.998c0 3.856 1.915 7.298 4.91 9.56V29.3l4.477-2.521a14.36 14.36 0 0 0 3.946.55c7.364 0 13.334-5.522 13.334-12.331 0-6.811-5.97-12.331-13.334-12.331S2.666 8.187 2.666 14.997Zm13.333-5.664a1.2 1.2 0 0 1 1.2 1.2v3.6h3.6a1.2 1.2 0 1 1 0 2.4h-3.6v3.6a1.2 1.2 0 1 1-2.4 0v-3.6h-3.6a1.2 1.2 0 0 1 0-2.4h3.6v-3.6a1.2 1.2 0 0 1 1.2-1.2Z"
      fill="#212121"
    />
  </svg>
);
export default memo(AddTicketSvg);
