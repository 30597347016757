import { memo } from "react";
import CardActionArea from '@mui/material/CardActionArea';
function SettingCard({ detail = null, icon, label , itemKey ,...rest }) {
  return (
   <CardActionArea className=" !mb-1">
     <div {...rest} className="flex justify-between items-center bg-black_2 cursor-pointer  px-4 h-14">
      <div className="flex  ">
        <div className=" me-4">{icon}</div>
        <span className=" text-sm text-gray_6 font-normal font-dana">
          {label}
        </span>
      </div>
      {detail && (
        <span dir={itemKey==='connectTelegram'?'ltr':'rtl'} className={`${itemKey==='connectTelegram' && 'w-[45%]'} text-gray_3 font-normal font-dana text-xs`}>
          {detail}
        </span>
      )}
    </div>
   </CardActionArea>

  );
}

export default memo(SettingCard);
