import React from 'react';

function EnableSleepTimerSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            fill="none"
            viewBox="0 0 21 21"
            {...props}
        >
            <g fill="#F2C94C" clipPath="url(#clip0_58_7)">
                <path d="M16.072 2.005L18.3 3.862a.817.817 0 001.155-.104.82.82 0 00-.105-1.155L17.123.745a.82.82 0 10-1.05 1.26zM2.176 4.053a.817.817 0 00.525-.19L4.93 2.004A.82.82 0 103.878.745L1.65 2.602a.82.82 0 00.526 1.45zM14.496 6.382a.82.82 0 00-1.153.121L10.756 9.7a1.643 1.643 0 00-1.897 1.62c0 .905.736 1.64 1.641 1.64a1.642 1.642 0 001.531-2.228l2.587-3.196a.82.82 0 00-.122-1.154z"></path>
                <path
                    fillRule="evenodd"
                    d="M11.32 1.675a9.607 9.607 0 016.025 2.8A9.612 9.612 0 0120.175 11h-1.642c-.169-4.285-3.708-7.719-8.033-7.719-4.433 0-8.04 3.607-8.04 8.04 0 3.612 2.396 6.676 5.682 7.686l-.436 1.586a9.63 9.63 0 01-4.05-2.428A9.616 9.616 0 01.82 11.32a9.616 9.616 0 012.835-6.844 9.607 9.607 0 016.025-2.8V.82a.82.82 0 111.64 0v.855z"
                    clipRule="evenodd"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_58_7">
                    <path fill="#fff" d="M0 0H21V21H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default EnableSleepTimerSvg;
