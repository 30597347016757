import { useCallback } from 'react';
import Container from '../../components/PageParentContainer/Container';
import CommonHeader from '../../components/common/CommonHeader';
import GiftForm from '../../components/gift/GiftForm';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

function BuyGift() {
  const navigate = useNavigate();

  const [giftData, setGiftData] = useState(null);

  const submitData = useCallback(data => {
    localStorage.setItem('buy_gift_data', JSON.stringify(data));
    navigate('/gift_plan', { state: data });
  }, []);
  useEffect(() => {
    if (localStorage.getItem('buy_gift_data'))
      setGiftData(JSON.parse(localStorage.getItem('buy_gift_data')));
  }, []);

  return (
    <Container
      style=" bg-primary_dark p-4"
      header={<CommonHeader title="خرید اشتراک هدیه" />}
    >
      <GiftForm
        description={giftData?.description}
        name={giftData?.name}
        onSubmit={submitData}
      />
    </Container>
  );
}

export default BuyGift;
