import { useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelectScrollPosition } from '../helper/ReduxSelectorContext.js';
import { setScrollPosition } from '../redux/scrollManageStore/scrollManageSlice.js';
import { useHashedPath } from './useHashedPath.js';

export const useScrollPosition = ({ shouldScroll = true, pageId }) => {
  const dispatch = useDispatch();
  const {hashedScrollKey} = useHashedPath()
  const scrollY = useSelectScrollPosition();

  useEffect(() => {
    const page = document.getElementById(pageId);

    const saveScrollPosition = () => {
      const currentScrollY = page?.scrollTop || 0;

      dispatch(
        setScrollPosition({
          pathname: hashedScrollKey,
          scrollY: currentScrollY,
        }),
      );
    };
    page?.addEventListener('scroll', saveScrollPosition);
    return () => {
      page?.removeEventListener('scroll', saveScrollPosition);
    };
  }, [hashedScrollKey]);

  useLayoutEffect(() => {
    if (!shouldScroll) {
      return;
    }
    if (scrollY !== undefined) {
      document.getElementById(pageId)?.scrollTo(0, scrollY);
    } else {
      document.getElementById(pageId)?.scrollTo(0, 0);
    }
  }, [hashedScrollKey]);
};
