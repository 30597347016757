import giftSvg from '../../assets/gift/gift.svg';
import Container from '../../components/PageParentContainer/Container';
import CommonHeader from '../../components/common/CommonHeader';
import { CardActionArea } from '@mui/material';
import CssShimmer from '../../components/common/CssShimmer';
import InfiniteScroll from 'react-infinite-scroll-component';
import SongListLoading from '../../components/loading/SongListLoading';
import { Virtuoso } from 'react-virtuoso';
import NoDataGift from '../../components/svg/gift/NoDataGift';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getGifts } from '../../services/ApiClient';
import ScrollTopButton from '../../components/common/ScrollTopButton';
import UserGiftCard from '../../components/gift/UserGiftCard';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import UserGiftListShimmers from '../../components/gift/UserGiftListShimmers';
import AarrowLeft from '../../components/svg/common/AarrowLeft';
import { useSelector } from 'react-redux';
import { selectGiftDialog } from '../../redux/dialog/DialogSlice';
import React from 'react';
import { IsIosDevice } from '../../helper/CheckDevice';
import CustomImage from '../../components/common/CustomImage';
// ManageGifts
function ManageGift() {
  const location = useLocation();
  const navigate = useNavigate();
  const dialogData = useSelector(selectGiftDialog);
  const [HasLoaded, setHasLoaded] = useState(false);

  const handleClickBackButton = () => {
    window.removeEventListener('popstate', handleClickBackButton);
    navigate('/home', { replace: true });
  };

  useEffect(() => {
    if (IsIosDevice() && location?.state?.navBackFromGateway)
      window.addEventListener('popstate', handleClickBackButton);
  }, [location?.state]);

  useEffect(() => {
    setHasLoaded(true);
    if (localStorage.getItem('buy_gift_data'))
      localStorage.removeItem('buy_gift_data');
  }, []);
  useEffect(() => {
    let timer = null;
    if (dialogData)
      timer = setTimeout(() => {
        navigate('/gift_successfully-buying', {
          replace: false,
          state: dialogData,
        });
      }, 50);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [dialogData]);

  const { isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, data } =
    useInfiniteQuery(
      ['getGiftsInfiniteQuery'],
      ({ pageParam = 0 }) => getGifts(pageParam),
      {
        getNextPageParam: (lastPage, allPages) => {
          const allData = allPages?.flatMap(page => page.data.result.gifts);
          return lastPage?.data?.result?.end ? false : allData?.length;
        },
        refetchOnWindowFocus: false,
        cacheTime: 0,
      },
    );
  const gifts = data?.pages?.flatMap(page => page.data.result.gifts);

  const getNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const navigateToBuyGift = () => {
    navigate('/buy-gift');
  };
  const navigateToTutorialGift = () => {
    navigate('/tutorial-gift');
  };

  return (
    <Container
      style="p-4 bg-primary_dark"
      header={<CommonHeader title="مدیریت اشتراک ها" />}
    >
      <div
        id="gifts-container"
        dir="rtl"
        className="bg-primary_dark w-full h-full  overflow-scroll hide-scroll-bar  pb-20 "
      >
        <ScrollTopButton id={'gifts-container'} />
        <div className="min-h-[202px] w-full relative p-4 rounded-xl bg-secondary overflow-hidden mt-4 ">
          <div className="bg-gift-card-hover absolute w-[182px] h-[182px] rounded-full -top-[81px] -right-[86px] "></div>
          <div className="bg-gift-card-hover absolute w-[150px] h-[150px] rounded-full -top-[118px] right-[29px] "></div>
          <div className=" h-[72px] z-10">
            <CustomImage
              appendBaseUrl={false}
              c
              className=" mx-auto h-full"
              src={giftSvg}
            />
          </div>
          <div className="w-full mt-4 mb-6">
            <p className="font-dana font-semibold text-base text-primary  text-center">
              برای دوستان خود اشتراک هدیه بفرستید!
            </p>
          </div>
          <CardActionArea
            onClick={navigateToBuyGift}
            className="!w-full !bg-primary !rounded-[23px] !h-[46px] !shadow-gift-submit-button !py-3 !text-center !text-title_box !font-medium !font-dana !text-base "
            type="submit"
          >
            خرید اشتراک
          </CardActionArea>
        </div>
        {isLoading ? (
          <CssShimmer classNames={'w-1/2 h-5 rounded-xl mt-1 mx-auto !mt-4'} />
        ) : (
          gifts?.length !== 0 && (
            <CardActionArea
              onClick={navigateToTutorialGift}
              className="!flex !justify-center !w-fit !items-center !gap-3 !mt-4 !-mb-1 !py-1 !px-2 !rounded-md !mx-auto"
            >
              <p className="text-lightBlue text-sm font-medium font-dana">
                آموزش ارسال کد هدیه به دوست
              </p>
              <AarrowLeft className="stroke-lightBlue" />
            </CardActionArea>
          )
        )}

        <div
          className={`flex flex-col ${
            gifts?.length !== 0 ? 'h-max' : 'h-full justify-center'
          }`}
        >
          {isLoading ? (
            <div className=" mt-4">
              <UserGiftListShimmers />
            </div>
          ) : gifts?.length !== 0 ? (
            <>
              <div className="flex justify-start w-full h-12 items-center pt-4 bg-primary_dark sticky z-30 top-0">
                <p className="text-gray_6 text-sm my-4 font-normal sticky font-dana  bg-transparent leading-4">
                  لیست اشتراک ها:
                </p>
              </div>
              <div id="gift-cards" className=" w-full h-full ">
                <InfiniteScroll
                  next={getNextPage}
                  hasMore={hasNextPage}
                  loader={
                    isFetchingNextPage && (
                      <div className="mt-4 grid justify-center">
                        <SongListLoading />
                      </div>
                    )
                  }
                  dataLength={gifts?.length || 0}
                  scrollableTarget={'gifts-container'}
                >
                  <div>
                    <Virtuoso
                      customScrollParent={document.getElementById(
                        'gifts-container',
                      )}
                      data={gifts}
                      itemContent={(index, gift) => (
                        <div key={gift?.id} className="py-3">
                          <UserGiftCard
                            {...gift}
                            title={gift?.plan_title}
                            isUse={gift?.is_used}
                          />
                        </div>
                      )}
                    />
                  </div>
                </InfiniteScroll>
              </div>
            </>
          ) : (
            <div className="px-4 gap-4  flex flex-col justify-center items-center h-full -mt-36">
              <NoDataGift />
              <p className="text-gray_2 font-semibold font-dana text-sm text-center">
                شما هنوز اشتراک هدیه ای خریداری نکرده اید.
              </p>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}

export default ManageGift;
