import React from "react";

function NavBarContainer({ children,shadow=true }) {
  return (
    <div className={`fixed translate-x-[-50%] z-30  w-[101%] left-[50%] max-w-[520px] top-0 h-[56px] bg-primary_dark  ${shadow&&" shadow-navShadow"}`}
    >
      {children}
    </div>
  );
}

export default NavBarContainer;
