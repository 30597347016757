import React from "react";

function RetrySVG({fill="#A7A7A9"}) {
  return (
    <svg
      class="w-full h-full"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.90625V7.36079H6.45455"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9975 16.4526V10.998H15.543"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7182 6.45239C18.2571 5.14947 17.4735 3.98458 16.4405 3.06641C15.4075 2.14824 14.1587 1.50673 12.8106 1.20172C11.4626 0.896714 10.0593 0.938159 8.73165 1.32219C7.40398 1.70622 6.19523 2.42032 5.21818 3.39785L1 7.36148M21 10.9978L16.7818 14.9615C15.8048 15.939 14.596 16.6531 13.2684 17.0371C11.9407 17.4212 10.5374 17.4626 9.18935 17.1576C7.84133 16.8526 6.59255 16.2111 5.55952 15.2929C4.52648 14.3748 3.74288 13.2099 3.28182 11.9069"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RetrySVG;
