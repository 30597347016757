import {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from 'react'


import SwipeBottomSheet from "../bottomSheets/SwipeBottomSheet.jsx";
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import ErrorReportFlag from "../svg/artists/ErrorReportFlag.jsx";
import ConfirmDialogs from "../dialogs/ConfirmDialogs.jsx";
import {mainToast, toastWithSvg} from "../../helper/ToastsEmitter.jsx";
import RedHeartSvg from "../svg/toast/RedHeartSvg.jsx";
import {useMutation} from "@tanstack/react-query";
import {lyricReport, sendReport} from "../../services/ApiClient.js";
import {CardActionArea} from "@mui/material";
import {useNativeBack} from "../../hooks/useNativeBack.js";
import {reportTypes} from "../../consts/ReportTypes.js";
import {getInitialData} from "../../helper/ReduxSelectorContext.js";
import LoadingOnSheet from "./loadingOnSheet.jsx";



function ReportBottomSheet({id,reportKey}, ref) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedError, setSelectedError] = useState(null)
    const dialogRef = useRef(null)
    const closeModal = () => setIsOpen(false);
    const initialData =getInitialData()
    console.log(initialData?.reports,'initial...')
    const reportObject=reportTypes.filter((reportItem)=>reportItem?.key===reportKey)[0]
    const dataItems = initialData?.reports?.filter((item) => item?.report_category_name === reportObject?.dataKey)
    console.log(dataItems,reportObject?.dataKey,'initial...')
    const sendReportMutation = useMutation((data) => sendReport(data),{
        onSuccess:(res) => {
            mainToast(<div dir='rtl' dangerouslySetInnerHTML={{__html:res?.data?.result?.message}} className='text-center flex'>

                </div>
            )
        }
    });
    const handelSendReport = () => {

        const requestData = {
            model_id: id,
            report_id: selectedError?.id,
        };
        sendReportMutation.mutateAsync(requestData).then(()=>{
            handelModalClose()
        })


    }
    const handelModalClose = (e) => {
        closeModal()

    };
    const toggleBugReportBottomSheet = (newOpen) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(newOpen)
    };
    const closeSheet = () => {
        setIsOpen(false)
    }
    const handelSelectItem = (event, data) => {
        event.stopPropagation()
        event.preventDefault()
        setSelectedError(data)
        dialogRef?.current?.openDialog()
    }
    useImperativeHandle(ref, () => ({
        openModal() {
                setIsOpen(true)
        },

    }));

    useNativeBack(isOpen,'lyric-report-sheet',closeSheet)
    return (
        <>
            <ConfirmDialogs
                title={reportObject?.headerTitle}
                text={selectedError?.message}
                buttonDirection={"dir-rtl"}
                confirmText={"ارسال گزارش"}
                confirmTextColor={"text-secondary"}
                confirmBGColor={"bg-primary"}
                confirmBorderColor={"transparent"}
                confirmAction={handelSendReport}
                cancelText={"لغو"}
                cancelTextColor={"text-primary"}
                cancelBGColor={"bg-transparent"}
                cancelBorderColor={"border-primary"}
                ref={dialogRef}
                confirmButtonWidth={'!w-[300px]'}

            />
            <SwipeBottomSheet
                open={isOpen}
                toggleDrawer={toggleBugReportBottomSheet}
            >
                <BottomSheetHeader HeaderIcon={<ErrorReportFlag/>} dividerLine={true}
                                   closeBottomSheet={handelModalClose} title={reportObject?.headerTitle}/>
                <div onClick={(e) => e.stopPropagation()} dir='rtl'

                     className='flex flex-col gap-4 w-full  pb-4 pt-4 relative '>
                    {sendReportMutation?.isLoading && <LoadingOnSheet/>}
                    <div className=" flex flex-col gap-6">
                        <p className="text-white font-dana font-medium text-[14px] lg-1440:text-f14 flex text-start px-4">
                            {reportObject?.title}
                        </p>
                        <div className="flex flex-col  ">
                            {dataItems?.map((item) => (
                                <CardActionArea>
                                    <p
                                        onClick={(event) => {
                                            handelSelectItem(event, item);
                                        }}
                                        className=" font-dana font-normal text-[14px] lg-1440:text-f16 flex  text-primary cursor-pointer py-4 px-4">
                                        {item?.message}
                                    </p>
                                </CardActionArea>
                            ))}
                        </div>
                    </div>
                </div>

            </SwipeBottomSheet>

        </>
    );

}

export default forwardRef(ReportBottomSheet)
