import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { notificationDB } from "../indexDB/DB";
import {setCustomDialog, setNewTrackDialog, setUpdateCollectionDialogData} from "../redux/dialog/DialogSlice";
import { ACTIONS } from "../consts/ActionsConstants";
import { getArtistTracks } from "../services/ApiClient";
import {appendBaseUrl, parseDynamicObject} from "../helper/utils";
export const useNotification = () => {
  const dispatch = useDispatch()
  const location = useLocation()


  let itemObject = {}
  const notifyNewNotification = async () => {
    const notificationItems = await notificationDB.notif?.toArray()
    if (notificationItems?.length != 0) {
      const item = notificationItems?.[0];
      if (item?.item?.data?.new_type == ACTIONS.NEW_TRACK_TYPE) {
        handleNewtrackType(item?.item?.data)

      }else if(item?.item?.data?.new_type == ACTIONS.COLLECTION_UPDATED_TYPE){
        handleCollectionUpdated(item?.item?.data)
      } else {

        const parsDialogData = item?.item?.data?.dialog_data && JSON?.parse(item?.item?.data?.dialog_data);
        const collection = item?.item?.data?.collection && JSON.parse(item?.item?.data?.collection);
        const plan = item?.item?.data?.plan && JSON.parse(item?.item?.data?.plan);
        Object.entries(item?.item?.data).map(item => {

          itemObject[item?.[0]] = parseDynamicObject(item?.[1])

        })
        // TODO MEMO appendBaseUrl(parsDialogData?.image) AND THEN USE IT
        const formatedNotifStructure = {
          ...parsDialogData,
          ...item?.item?.data,
          image: appendBaseUrl(parsDialogData?.image),
          action: {
            form_notification: true,
            target_type: item?.item?.data?.new_type, view_type: item?.item?.data?.view_type,
            // collection: { ...collection, image: IMAGE_BASE_URL + collection?.image, image_large: IMAGE_BASE_URL + collection?.image_large },
            // plan: plan
            ...itemObject
          },
        };

        dispatch(setCustomDialog({ custom_dialog: formatedNotifStructure }));
      }
    }
  };
  useEffect(() => {

    notifyNewNotification()
  }, [location.pathname]);

  const handleNewtrackType = (item) => {
    getArtistTracks({
      artist_id: item?.artistId,
      offset: 0,
      sort_by: "new",
    }).then(res => {
      const track = res?.data?.result?.tracks?.filter(itemm => itemm?.id == item?.trackId)
      if (track.length != 0)
        dispatch(setNewTrackDialog({ new_track_dialog: { track: track?.[0], playList: res?.data?.result?.tracks } }))
    })
  }
  const handleCollectionUpdated = (item) => {
        const formattedCollectionData = {
           image:item?.image,
           collection:item?.collection && JSON.parse(item.collection)
        }
        dispatch(setUpdateCollectionDialogData({collectionData: formattedCollectionData}))

  }
}
