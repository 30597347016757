import React from 'react';
import ThreeDotSvg from '../svg/common/ThreeDotSvg.jsx';
import RenderDownloadStatus from '../download/RenderDownloadStatus.jsx';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import { useContextPosition } from '../../helper/useContextPosition.js';
import { usePlayedTrack } from '../../hooks/usePlayedTrack.js';
import { useAction } from '../../hooks/useAction.js';
import { useDownload } from '../../hooks/useDownload.js';
import { useSelectTrackItem } from '../../hooks/useSelectTrackItem.js';
import { textFontBasedLanguage } from '../../helper/TextFontBasedLanguage.js';
import ExclusiveTrackDemoSection from './ExclusiveTrackDemoSection.jsx';

function ExclusiveTrackCardOptionSection({
  isSelectMode,
  inPlayer,
  item,
  showIsPlaying,
}) {
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  const { isSelected, selectItem, deSelectItem } = useSelectTrackItem(
    inPlayer,
    item,
  );
  const action = useAction();
  const [
    startDownloadTrack,
    isDownloaded,
    inQueue,
    inQueueItem,
    downloadedItem,
  ] = useDownload(item);
  const handleDownloadTrack = event => {
    event.stopPropagation();
    startDownloadTrack();
  };
  const openContextMenu = e => {
    e.stopPropagation();
    e?.preventDefault();
    const [vertical, horizontal] = useContextPosition(e);

    action({
      target_type: 'open_context_menu',
      data: { ...item },
      inPlayer,
      horizontal,
      vertical,
      isDownloadedTrack: isDownloaded,
    });
  };
  const handleSelectItem = e => {
    e.stopPropagation();
    e.preventDefault();
    if (isSelected) {
      deSelectItem(item);
    } else {
      selectItem(item);
    }
  };
  return (
    <div className="flex items-center w-full justify-between ">
      <div className={`flex items-center w-[50%]  `}>
        {item?.is_demo ? (
          <div
            dir="ltr"
            className="px-4  h-[60px] flex justify-start items-start pt-2"
          >
            <ExclusiveTrackDemoSection />
          </div>
        ) : (
          <>
            {isSelectMode ? (
              <div
                onClick={handleSelectItem}
                dir="rtl"
                className=" h-[60px] px-4 flex justify-start items-center "
              >
                <div
                  className={`w-[20px] h-[20px] border-[2px] rounded-[3px] ${
                    isSelected
                      ? 'bg-primary border-primary'
                      : 'bg-transparent border-gray_3'
                  }`}
                ></div>
              </div>
            ) : (
              <div
                onClick={e => {
                  openContextMenu(e);
                }}
                className="flex items-center px-4 relative "
              >
                <div className="  h-[60px] items-center flex ">
                  <ThreeDotSvg class=" " fill={'#828282'} />
                </div>
              </div>
            )}
            <div className="w-full h-[60px] ">
              <RenderDownloadStatus
                item={item}
                isDownloaded={isDownloaded}
                startDownloadTrack={handleDownloadTrack}
                inQueue={inQueue}
                downloadedItem={downloadedItem}
                inQueueItem={inQueueItem}
              />
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col gap-2 w-[50%] items-end pt-3">
        <p
          dir={setLanguageDirection(item?.title)}
          className={`${
            currentTrack?.id == item?.id && showIsPlaying
              ? 'text-yellow'
              : 'text-white'
          } text-[14px] max-w-full font-dana font-[500] text-gray_5  leading-[22px] whitespace-nowrap  truncate ${textFontBasedLanguage(
            item?.title,
          )} `}
        >
          {item?.title}
        </p>
        <p
          dir={setLanguageDirection(item?.title)}
          className="text-gray_3 text-[12px] font-medium font-dana leading-[18px] justify-end flex h-[18px]  whitespace-nowrap truncate max-w-full"
        >
          {item?.play_count}
        </p>
      </div>
    </div>
  );
}

export default ExclusiveTrackCardOptionSection;
