import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Container from '../../components/PageParentContainer/Container.jsx';
import PlansCardItem from '../../components/plans/PlansCardItem.jsx';
import { CardActionArea } from '@mui/material';
import RoundedDrawerHeader from '../../components/svg/common/RoundedDrawerHeader.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSwipePlayerStatus } from '../../redux/player/PlayMusicSlice';
import { useQuery } from '@tanstack/react-query';
import { getPlans } from '../../services/ApiClient';
import { getInitialData } from '../../helper/ReduxSelectorContext';

function PlansGift() {
  const initialDataSelector = getInitialData();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState(initialDataSelector?.plans);
  const handleClosePlanPage = () => {
    navigate(-1);
    if (location?.state?.openPlayerWhenBack)
      dispatch(setSwipePlayerStatus({ status: true }));
  };

  const getPlanDataQuery = useQuery(['getPlanDataQuery'], getPlans, {
    onSuccess: res => {
      setPlans(res?.data?.result?.plans);
    },
    refetchOnWindowFocus: false,
  });

  return (
    <Container style="bg-black_2">
      <div
        dir="rtl"
        className="w-full h-full bg-black_2  px-4 flex flex-col gap-5"
      >
        <div className="z-30 top-0 absolute left-0 right-0  ">
          <div
            onClick={() => handleClosePlanPage()}
            className="!w-fit mx-auto justify-center z flex bg-transparent"
          >
            <RoundedDrawerHeader classList={'!h-[20px]'} />
          </div>
        </div>
        <div className="mt-9">
          <p className="text-primary font-dana font-normal text-lg text-center  ">
            اشتراک مورد نظر را برای هدیه انتخاب کنید.
          </p>
        </div>
        <div className="grid grid-cols-2  gap-2 bg-black_2 w-full  pb-[16px] ">
          {plans?.map((item, index) => (
            <CardActionArea key={item?.id} className="h-full">
              <PlansCardItem
                index={index}
                item={item}
                giftData={{
                  name: location?.state?.name,
                  description: location?.state?.description,
                }}
              />
            </CardActionArea>
          ))}
        </div>
      </div>
    </Container>
  );
}

export default PlansGift;
