import React from 'react';

function PenSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="none"
            viewBox="0 0 19 19"
            {...props}
        >
            <g clipPath="url(#clip0_278_12070)">
                <path
                    fill="#fff"
                    d="M17.887 1.113A3.777 3.777 0 0015.198 0c-1.015 0-1.97.395-2.688 1.113L1.886 11.738a.742.742 0 00-.19.325L.027 18.06a.742.742 0 00.918.913l5.995-1.703a.742.742 0 00.323-1.238l-3.78-3.791 8.807-8.807 3.275 3.275-6.742 6.724a.742.742 0 101.049 1.05l8.015-7.992A3.776 3.776 0 0019 3.802c0-1.016-.395-1.97-1.113-2.689zM5.32 16.186l-3.51.996.984-3.53 2.526 2.534zM16.838 5.439l-.222.221-3.276-3.277.22-.22a2.302 2.302 0 011.638-.679c.62 0 1.201.241 1.639.68.438.437.679 1.019.679 1.638s-.241 1.2-.678 1.637z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_278_12070">
                    <path fill="#fff" d="M0 0H19V19H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default PenSvg;
