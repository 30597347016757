import { memo } from "react";

const UserNormalSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    {...props}
  >
    <path
      d="M14.96 30.17v-1.006a7.04 7.04 0 0 1 14.08 0v1.006"
      fill="#828282"
    />
    <path
      d="M14.96 30.17v-1.006a7.04 7.04 0 0 1 14.08 0v1.006H14.96Z"
      stroke="#828282"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.001 22.124a4.023 4.023 0 1 0 0-8.046 4.023 4.023 0 0 0 0 8.046Z"
      fill="#828282"
      stroke="#828282"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default memo(UserNormalSvg);
