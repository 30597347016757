import React from 'react';
import CssShimmer from "../common/CssShimmer.jsx";

function ExclusiveTrackCardShimmer({dir='ltr'}) {
    return (
        <div className='p-2 w-full'>
            <div dir={dir} className='flex w-full gap-3'>
                <CssShimmer

                    classNames="w-[104px] h-[104px]  rounded-[10px]"
                />
                <div className='flex flex-col gap-1 w-[calc(100%_-_116px)] justify-center'>
                    <CssShimmer

                        classNames="h-[20px] w-[80%]  rounded-xl mb-1"
                    />
                    <CssShimmer
                        classNames="h-[17px] w-[40%]  rounded-xl "
                    />
                    <div className='flex gap-2 items-center'>
                        <CssShimmer
                            classNames="h-[40px] w-[40px]  rounded-full "
                        />
                        <CssShimmer
                            classNames="h-[15px] w-[calc(80%_-_38px)]  rounded-xl "
                        />
                    </div>

                </div>

            </div>

        </div>

    );
}

export default ExclusiveTrackCardShimmer;
