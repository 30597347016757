import React from "react";

function SimpleEarSVG({fill="#B0B0B5"}) {
  return (
    <svg
      class="w-full h-full"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8906 6.44531C12.8906 2.89137 9.99926 0 6.44531 0C2.89137 0 0 2.89137 0 6.44531C0 8.0743 0.302969 9.69387 0.595937 11.2601C0.879102 12.7738 1.17188 14.3391 1.17188 15.8594C1.17188 18.1425 3.01184 20 5.27344 20C7.53504 20 9.375 18.1425 9.375 15.8594C9.375 15.5684 9.34465 15.2531 9.29164 14.9943C9.00574 13.5971 10.0348 11.9709 11.0029 11.0028C12.2202 9.78547 12.8906 8.16691 12.8906 6.44531ZM8.14359 15.2293C8.18086 15.4114 8.20312 15.647 8.20312 15.8594C8.20312 17.4963 6.88887 18.8281 5.27344 18.8281C3.65801 18.8281 2.34375 17.4963 2.34375 15.8594C2.34375 14.2304 2.04078 12.6108 1.74781 11.0446C1.46465 9.5309 1.17188 7.96562 1.17188 6.44531C1.17188 3.53754 3.53754 1.17188 6.44531 1.17188C9.35309 1.17188 11.7188 3.53754 11.7188 6.44531C11.7188 7.85391 11.1702 9.17816 10.1742 10.1742C8.94418 11.4043 7.76477 13.3782 8.14359 15.2293Z"
        fill={fill}
      />
      <path
        d="M7.2116 2.41386C4.63656 1.94226 2.34375 3.91218 2.34375 6.44523C2.34375 7.8557 2.61344 9.28898 2.89961 10.8098C2.99152 11.2981 3.08656 11.803 3.17184 12.306C3.34453 13.3237 4.2068 14.0624 5.22219 14.0624C6.2093 14.0624 7.06621 13.3599 7.25977 12.392C7.26121 12.2119 7.96676 10.3207 6.50074 8.53242C5.76176 7.63101 4.68898 7.09281 3.53234 7.03617C3.52145 6.83734 3.51562 6.64043 3.51562 6.44523C3.51562 4.67894 5.10785 3.22 7.00051 3.56656C9.2998 3.98765 10.1779 6.85504 8.51703 8.51734L9.34605 9.34562C11.6526 7.03707 10.4684 3.01031 7.2116 2.41386ZM5.59449 9.27535C6.66961 10.5868 6.11449 11.9616 6.11062 12.1622C6.02621 12.5842 5.65258 12.8905 5.22219 12.8905C4.78035 12.8905 4.40398 12.5623 4.32727 12.11C4.2402 11.5966 4.14414 11.0864 4.05066 10.5897C3.8973 9.77468 3.74871 8.98496 3.64719 8.22019C4.40672 8.30234 5.10367 8.67668 5.59449 9.27535Z"
        fill={fill}
      />
      <path d="M20 9.375H14.1016V10.5469H20V9.375Z" fill={fill} />
      <path
        d="M14.9518 14.1238L14.4277 15.1719L19.1148 17.5154L19.6388 16.4673L14.9518 14.1238Z"
        fill={fill}
      />
      <path
        d="M19.1168 2.40646L14.4297 4.75L14.9537 5.79809L19.6408 3.45455L19.1168 2.40646Z"
        fill={fill}
      />
    </svg>
  );
}

export default SimpleEarSVG;
