import React from 'react';

import FileIcon from '../svg/common/FileIcon';
import TextMarquee from '../common/TextMarquee';
import { CardActionArea } from '@mui/material';
import CustomImage from '../common/CustomImage';

function AdminMessage({ messageDetail }) {
  const handleOpenFile = link => {
    window.open(link, 'toolbar=0,location=0,menubar=0');
  };

  return (
    <div className="flex w-full justify-end font-dana">
      <div className="flex flex-col gap-[2px] w-[calc(100%_-_54px)] ">
        <div
          dir="rtl"
          className="w-full flex py-2 bg-gray_3 items-center rounded-t-[10px]"
        >
          <div className="h-[35px] w-[35px] ml-2 mr-2">
            <CustomImage src={messageDetail?.admin?.url} />
          </div>
          <div className="flex flex-col gap-r2 drop-shadow drop-shadow-sm">
            <p className="text-sm text-white">
              {messageDetail?.admin?.full_name}
            </p>
            <p className=" text-xs text-gray_4">{messageDetail?.date}</p>
          </div>
        </div>
        <div className="w-full flex flex-col pb-12 p-4 bg-gray_3  rounded-b-[10px] !rounded-bl-none items-start">
          <div className="flex flex-col gap-[42px]">
            <p
              dangerouslySetInnerHTML={{ __html: messageDetail?.description }}
              className="text-xs text-white leading-5 drop-shadow drop-shadow-sm"
            ></p>
          </div>
          {messageDetail?.files &&
            messageDetail?.files?.map(item => (
              <CardActionArea className="!rounded-[5px]  !mt-4  !w-[calc(100%_-_16px)]">
                <div
                  onClick={() => handleOpenFile(item?.url)}
                  className="flex bg-gray_2 gap-[5px] items-center p-3 rounded-[5px]  w-full"
                >
                  <div
                    className="w-[20px]"
                    style={{
                      aspectRatio: 24 / 20,
                    }}
                  >
                    <FileIcon />
                  </div>

                  <p
                    dir={'ltr'}
                    className=" text-sm text-white cursor-pointer !w-[calc(100%_-_20px)] "
                  >
                    <TextMarquee direction="ltr">{item?.file_name}</TextMarquee>
                  </p>
                </div>
              </CardActionArea>
            ))}
          <div className="bottomSlot relative -bottom-[15px] right-[calc(100%_-_9px)] w-[30px] h-[0px]">
            <svg
              width="25"
              height="11"
              viewBox="0 0 25 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.473 3.56598L3.12402 10.1365C1.79069 10.9084 0.121948 9.94628 0.121948 8.40562V0.874512H24.4938C20.9749 0.874512 17.5184 1.80291 14.473 3.56598Z"
                fill="#828282"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminMessage;
