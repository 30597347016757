import React from "react";

function MedalSVG({fill="#B0B0B5"}) {
  return (
    <svg
      class="w-full h-full"
      viewBox="0 0 18 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.63639 16.2728C12.8538 16.2728 16.2728 12.8538 16.2728 8.63639C16.2728 4.41893 12.8538 1 8.63639 1C4.41893 1 1 4.41893 1 8.63639C1 12.8538 4.41893 16.2728 8.63639 16.2728Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.49969 15.0617L3.17969 24.9999L8.63425 21.7272L14.0888 24.9999L12.7688 15.0508"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MedalSVG;
