import React, { useState } from "react";

function ClickReaction({ children ,scale="scale-95 ",style=""}) {
    const [changeAnim, setIsButtonChange] = useState(false);
  return (
    <div
      onMouseDown={() => setIsButtonChange(true)}
      onTouchStart={() => setIsButtonChange(true)}
      onTouchEnd={() => setIsButtonChange(false)}
      onMouseLeave={() => setIsButtonChange(false)}
      onMouseUp={() => setIsButtonChange(false)}
      className={`ease-linear transition-all h-full duration-75 ${style} ${
        changeAnim ? `${scale} opacity-70` : "scale-100"
      }`}
    >
      {children}
    </div>
  );
}

export default React.memo(ClickReaction);
