import React from 'react'

const AlertSvg = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="none"
                viewBox="0 0 32 32"
            >
                <path
                    stroke="#EB5757"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13.72 5.147L2.427 24a2.667 2.667 0 002.28 4h22.586a2.667 2.667 0 002.28-4L18.28 5.147a2.666 2.666 0 00-4.56 0v0zM16 12v5.333M16 22.667h.013"
                ></path>
            </svg>

        </div>
    )
}

export default AlertSvg