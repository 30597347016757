import { useFormik } from 'formik';
import giftSvg from '../../assets/gift/gift.svg';
import {
  ADD_GIFT_VALIDATION_SCHEMA,
  EDIT_GIFT_VALIDATION_SCHEMA,
} from '../../consts/Validation';
import { CardActionArea } from '@mui/material';
import { memo, useEffect } from 'react';
import CustomImage from '../common/CustomImage';

function GiftForm({ isEditPage, onSubmit, description = '', name = '' }) {
  const formik = useFormik({
    initialValues: {
      description,
      name,
    },
    onSubmit,
    validateOnChange: false,
    validationSchema: isEditPage
      ? EDIT_GIFT_VALIDATION_SCHEMA
      : ADD_GIFT_VALIDATION_SCHEMA,
  });

  useEffect(() => {
    formik.setValues({ description, name });
  }, [description, name]);

  return (
    <form
      dir="rtl"
      onSubmit={formik.handleSubmit}
      className="flex flex-col gap-4"
    >
      <div className="min-h-[460px] w-full relative p-4 rounded-xl bg-secondary overflow-hidden ">
        <div className="bg-gift-card-hover absolute w-[182px] h-[182px] rounded-full -top-[81px] -right-[86px] "></div>
        <div className="bg-gift-card-hover absolute w-[150px] h-[150px] rounded-full -top-[118px] right-[29px] "></div>
        <div className=" h-[72px] z-10">
          <CustomImage
            appendBaseUrl={false}
            className=" mx-auto h-full"
            src={giftSvg}
          />
        </div>
        <div className="w-full mt-4 mb-6">
          <p
            className={`font-dana font-semibold text-base text-primary  ${
              isEditPage ? ' text-right' : 'text-center'
            }`}
          >
            {isEditPage ? 'ویرایش:' : 'برای دوستان خود اشتراک هدیه بفرستید!'}
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col ">
            <label
              htmlFor="name-input"
              className="text-gray_4 font-dana font-normal text-xs leading-6 "
            >
              نام دوست:
            </label>
            <input
              onChange={formik.handleChange}
              value={formik.values.name}
              type="text"
              name="name"
              id="name-input"
              className="bg-transparent outline-none h-12 p-3 border border-gray_4 border-solid rounded-[5px] text-sm text-gray_6 font-dana font-normal"
            />
            {formik?.errors?.name && (
              <p className="font-dana text-[11px] text-red mt-[6px]">
                {formik?.errors?.name}
              </p>
            )}
          </div>
          <div className="flex flex-col  ">
            <label
              htmlFor="description"
              className="text-gray_4 font-dana font-normal text-xs leading-6 "
            >
              متن پیام:
            </label>
            <textarea
              id="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              type="text"
              name="description"
              className="bg-transparent outline-none p-3 mb-[6px] h-[120px] border border-gray_4 border-solid rounded-[5px] text-sm text-gray_6 font-dana font-normal resize-none"
            />
            {formik?.errors?.description && (
              <p className="font-dana text-[11px] text-red mb-[6px]">
                {formik?.errors?.description}
              </p>
            )}
            <p className="text-primary font-dana font-normal text-xs leading-6 ">
              این پیام بعد از فعال سازی اشتراک هدیه توسط دوست شما، به او نمایش
              داده می‌شود.
            </p>
          </div>
        </div>
      </div>
      <CardActionArea
        className="!w-full !bg-primary !rounded-[23px] !h-[46px] !shadow-gift-submit-button !py-3 !text-center !text-title_box !font-medium !font-dana !text-base "
        type="submit"
      >
        {isEditPage ? 'ذخیره' : 'خرید اشتراک هدیه'}
      </CardActionArea>
    </form>
  );
}

export default memo(GiftForm);
