import React, { memo } from 'react';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';

function UserPublishedTrackTextSection({
  item,
  currentTrack,
  ShowItemIsPlaying,
}) {
  return (
    <div className="flex flex-col w-full items-start ">
      <p
        dir={setLanguageDirection(item?.title)}
        className={`${
          currentTrack?.id == item?.id && ShowItemIsPlaying
            ? 'text-yellow'
            : 'text-white'
        } text-[14px] max-w-full font-dana font-[500] text-gray_5  leading-6 whitespace-nowrap  truncate `}
      >
        {item?.title}
      </p>
      <p
        dir={setLanguageDirection(item?.title)}
        className="text-gray_3 text-[12px] font-medium font-dana leading-6  whitespace-nowrap truncate max-w-full"
      >
        {' '}
        {item?.artists.map((artist, index) => {
          return (
            <span dir={setLanguageDirection(item?.title)} key={index}>
              {artist?.name}{' '}
              {index !== item?.artists?.length - 1 &&
                (setLanguageDirection(item?.title) === 'rtl' ? '، ' : ', ')}
            </span>
          );
        })}
      </p>
    </div>
  );
}

export default memo(UserPublishedTrackTextSection);
