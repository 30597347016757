import React from 'react';

function HelpCircleSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        stroke="#F2C94C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 27.5c6.904 0 12.5-5.596 12.5-12.5S21.904 2.5 15 2.5 2.5 8.096 2.5 15 8.096 27.5 15 27.5z"
      ></path>
      <path
        stroke="#F2C94C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.363 11.25a3.75 3.75 0 017.287 1.25c0 2.5-3.75 3.75-3.75 3.75M15 21.25h.012"
      ></path>
    </svg>
  );
}

export default HelpCircleSvg;
