import React, { useEffect, useState } from "react";
import ChevronNavigateBack from "../common/ChevronNavigateBack.jsx";
import TextMarquee from "../common/TextMarquee.jsx";
import { setLanguageDirection } from "../../helper/LanguageDetection.js";
import CommonHeader from "../common/CommonHeader.jsx";
import SearchIcon from "../svg/common/SearchIcon.jsx";
import SearchButton from "../common/SearchButton.jsx";
import { textFontBasedLanguage } from '../../helper/TextFontBasedLanguage.js';

function CollectionCategoryHeader({
  title,
  pageId,
  backgroundId,
  hasSearch,
  extraFunction,
  startAdornmentClassName,
}) {
  const [elementsOpacity, setElementsOpacity] = useState(0);
  const [page, setPage] = useState(null);
  const [background, setBackground] = useState(null);
  const calculatedElementsOpacity = () => {
    if (page.scrollTop < 100) {
      setElementsOpacity(0);
      return;
    }
    const opacity = page?.scrollTop / background?.offsetHeight;
    setElementsOpacity(opacity);
  };
  useEffect(() => {
    setPage(document.getElementById(pageId));
    console.log(page);
    setBackground(document.getElementById(backgroundId));
    page?.addEventListener("scroll", calculatedElementsOpacity);
    return () => {
      page?.removeEventListener("scroll", calculatedElementsOpacity);
    };
  }, [document.getElementById(pageId), page]);
  return (
    <CommonHeader
      title={
        <p
          dir={"ltr"}
          style={{ opacity: elementsOpacity }}
          className={`text-white text-lg  font-normal pointer-events-none  text-center w-[100%] ${textFontBasedLanguage(title)}`}
        >
          <TextMarquee
            direction={setLanguageDirection(title)}
            noMarqueeParentStyle="justify-center"
          >
            {title}
          </TextMarquee>
        </p>
      }
      style={{
        background: `rgba(33, 33, 33, ${
          elementsOpacity < 0.5 ? elementsOpacity : 0.5
        })`,
      }}
      className="flex justify-between items-center absolute w-full top-0 h-[60px] text-blue  z-[30]  px-4"
      startAdornment={
        extraFunction ? (
          extraFunction
        ) : (
          <div className="w-[20px] h-[21px] ">
            {hasSearch && <SearchButton />}
          </div>
        )
      }
      TitleClassName="text-white text-lg font-dana font-normal pointer-events-none w-full"
      TitleProps={{
        style: { opacity: elementsOpacity },
      }}
      ChevronNavigateBackShadow={true}
      startAdornmentClassName={startAdornmentClassName}
    />
  );
}

export default CollectionCategoryHeader;
