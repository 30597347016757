import { memo } from "react";

const AboutUsPlayStoreSvg = (props) => (
  /**
   * This function returns an SVG element that displays a chevron pointing to the left.
   * 
  
   * @param props (object): Any additional props to be passed to the SVG element.
   * @returns An SVG element that displays a chevron pointing to the left.
   */
  <svg
    width={20}
    height={22}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.0959 12.6058L3.81725 20.5598C3.53325 20.7238 3.21126 20.8106 2.88332 20.8115C2.55537 20.8124 2.2329 20.7275 1.94798 20.5651C1.66307 20.4027 1.42564 20.1685 1.25933 19.8858C1.09301 19.6032 1.0036 19.2819 1 18.954V3.04591C1.0036 2.71799 1.09301 2.39672 1.25933 2.11407C1.42564 1.83142 1.66307 1.59725 1.94798 1.43485C2.2329 1.27244 2.55537 1.18748 2.88332 1.18839C3.21126 1.18931 3.53325 1.27609 3.81725 1.44008L17.0959 9.39412C17.3719 9.56153 17.6001 9.79725 17.7584 10.0785C17.9168 10.3598 18 10.6772 18 11C18 11.3228 17.9168 11.6401 17.7584 11.9214C17.6001 12.2027 17.3719 12.4384 17.0959 12.6058V12.6058Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default memo(AboutUsPlayStoreSvg);
