import React from "react";


function DownloadSvg({fill='#828282'}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={'w-full h-full'}
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                fill={fill}
                d="M7.885 1.57a7.184 7.184 0 012.013 0 .744.744 0 00.838-.636.749.749 0 00-.628-.849 8.694 8.694 0 00-2.433 0 .749.749 0 00-.628.85c.057.409.433.695.838.635z"
            ></path>
            <path
                fill={fill}
                d="M12.934.98a.737.737 0 00-.997.327.755.755 0 00.322 1.01c2.493 1.29 4.042 3.85 4.042 6.682 0 4.135-3.324 7.5-7.41 7.5-4.085 0-7.41-3.365-7.41-7.5 0-2.832 1.55-5.392 4.043-6.682a.754.754 0 00.323-1.01.737.737 0 00-.997-.326C1.858 2.53 0 5.601 0 9c0 4.962 3.989 9 8.892 9 4.902 0 8.891-4.038 8.891-9 0-3.398-1.858-6.47-4.85-8.018z"
            ></path>
            <path
                fill={fill}
                d="M5.405 10.719a.756.756 0 000 1.06l2.963 3a.733.733 0 001.048 0l2.964-3a.756.756 0 000-1.06.734.734 0 00-1.048 0l-1.699 1.72v-8.69a.746.746 0 00-.74-.75.746.746 0 00-.742.75v8.69l-1.699-1.72a.734.734 0 00-1.047 0z"
            ></path>
        </svg>
    );
}

export default DownloadSvg;
