import { memo } from "react";
import ChevronLeft from "../svg/common/ChevronLeft";
import NoMusicSvg from "../svg/myMelodify/NoMusicSvg";
import { useNavigate } from "react-router-dom";

const ProfileNoMusicCard = ({
  title,
  path = "/search",
  navigateOptions = {},
  subtitle,
  className = "",
  titleClassName = "",
}) => {
  const navigate = useNavigate();

  const handelNavigate = () => {
    navigate(path, navigateOptions);
  };

  return (
    <div
      onClick={handelNavigate}
      className={`bg-primary_dark mt-3 h-[90px] rounded-[20px] w-full flex justify-between items-center ps-2 pe-6 cursor-pointer font-dana  ${className} `}
    >
      <div className="flex items-center justify-start w-full">
        {/* TODO
        CHANGE SVG IN THIS JSX AND USE LAYER HAVE GOOD SIZE
        */}
        <NoMusicSvg className="w-[62px] h-[62px]" />
        <div className="flex flex-col ms-2 w-full">
          <span
            className={
              "text-gray_4 font-medium text-xs leading-6 " + titleClassName
            }
          >
            {title}
          </span>
          <div className="flex justify-between items-center w-full">
            <span className="text-primary font-medium text-sm leading-6">
              {subtitle}
            </span>
            <div className="w-fit">
              <ChevronLeft
                stroke="primary"
                className="w-[30px] h-[30px] stroke-primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ProfileNoMusicCard);
