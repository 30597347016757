import { memo } from "react";

const cachSvg = (props) => (
  <svg
    width={18}
    height={32}
    viewBox="0 0 18 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.702 18.335h-3.474v-7.91a.641.641 0 1 0-1.283 0v4.425H9.406V1.865h1.54v2.788a.641.641 0 1 0 1.282 0v-3.43a.641.641 0 0 0-.641-.641H8.765a.641.641 0 0 0-.641.641v17.112H4.65a2.3 2.3 0 0 0-2.298 2.298v1.69c0 2.88-.781 5.688-2.259 8.12a.641.641 0 0 0 .548.975h14.366a.64.64 0 0 0 .548-.309A16.917 16.917 0 0 0 18 22.323v-1.69a2.3 2.3 0 0 0-2.298-2.298Zm-6.296-2.202h1.54v2.202h-1.54v-2.202ZM4.65 19.618h11.052c.56 0 1.015.455 1.015 1.015v1.045H3.634v-1.045c0-.56.456-1.015 1.016-1.015Zm9.991 10.517h-3.328a17.014 17.014 0 0 0 1.305-3.353.641.641 0 1 0-1.238-.337 15.64 15.64 0 0 1-1.528 3.69H6.525a17.01 17.01 0 0 0 1.304-3.353.641.641 0 1 0-1.237-.337 15.637 15.637 0 0 1-1.529 3.69H1.737a16.931 16.931 0 0 0 1.885-7.17h13.082a15.625 15.625 0 0 1-2.063 7.17Z"
      fill="#E0E0E0"
    />
    <path
      d="M11.587 6.898a.644.644 0 0 0-.642.642.645.645 0 0 0 .642.641.646.646 0 0 0 .453-.188.647.647 0 0 0 .188-.453.645.645 0 0 0-.641-.642Z"
      fill="#E0E0E0"
    />
  </svg>
);
export default memo(cachSvg) ;
