import React,{useImperativeHandle, useState}  from 'react';
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import ErrorReportFlag from "../svg/artists/ErrorReportFlag.jsx";

import SwipeBottomSheet from "../bottomSheets/SwipeBottomSheet.jsx";
import GoToArtistBottomSheetContent from "./GoToArtistBottomSheetContent.jsx";
import MicrophoneSvg from "../svg/common/MicrophoneSvg.jsx";
import {useNativeBack} from "../../hooks/useNativeBack.js";

function GoToArtistBottomSheet({itemType,data,closeParentBottomSheet=()=>{console.log('')}},ref) {
    const [goToArtistBottomSheetOpen, setGoToArtistBottomSheetOpen] = useState(false);

    const toggleGoToArtistBottomSheet = (newOpen) => (event) => {

        event?.stopPropagation()
        setGoToArtistBottomSheetOpen(newOpen)
    };
    const  closeBottomSheetHandler=(event)=>{
        event?.stopPropagation()
        closeBottomSheet()
    }
    useImperativeHandle(ref, () => ({
        openGoToArtistBottomSheet (){
            setGoToArtistBottomSheetOpen(true)
        }
    }));
    const closeBottomSheet  = () => {
        setGoToArtistBottomSheetOpen(false)
    }
    useNativeBack(goToArtistBottomSheetOpen,'go-to-artist',closeBottomSheet);
    return (
        <SwipeBottomSheet
            open={goToArtistBottomSheetOpen}
            toggleDrawer={toggleGoToArtistBottomSheet}
        >
            <BottomSheetHeader HeaderIcon={<MicrophoneSvg/>} dividerLine={true} closeBottomSheet={closeBottomSheetHandler} title="خوانندگان"/>
            <GoToArtistBottomSheetContent closeParentBottomSheet={closeParentBottomSheet} closeArtistBottomSheet={closeBottomSheetHandler} artists={data?.artists}/>
        </SwipeBottomSheet>
    );
}

export default React.forwardRef(GoToArtistBottomSheet) ;
