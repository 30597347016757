import { memo, useCallback, useMemo, useState } from 'react';
import { CardActionArea } from '@mui/material';
import giftSvg from '../../assets/gift/gift.svg';
import ChevronLeft from '../svg/common/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import GiftDialog from './GiftDialog';
import GiftSettingCardItems from '../../consts/GiftSettingCardItems';
import CustomImage from '../common/CustomImage';

function GiftSettingCard() {
  const navigate = useNavigate();

  const [IsOpenGiftDialog, setIsOpenGiftDialog] = useState(false);

  const GiftItemMemorizeList = useMemo(() => GiftSettingCardItems, []);

  const handelItemClick = useCallback(item => {
    if (item.route) navigate(item.route);
    else if (item.action === 'OPEN_DIALOG') setIsOpenGiftDialog(true);
  }, []);

  return (
    <>
      <div dir="rtl" className="overflow-hidden bg-secondary rounded-[20px] ">
        <div className="h-[72px] relative overflow-hidden flex px-4 py-3 gap-4 items-center ">
          <div className="bg-gift-card-hover absolute w-32 h-32 rounded-full -top-[80px] -left-[50px] "></div>
          <div className="bg-gift-card-hover absolute w-[76px] h-[76px] rounded-full -top-[57px] left-[47px] "></div>
          <div className="bg-gift-card-hover absolute w-[70px] h-[70px] rounded-full -top-[15px] -right-[13px] "></div>
          <div className=" min-w-[38px] h-[48px] z-10">
            <CustomImage appendBaseUrl={false} src={giftSvg} />
          </div>
          <p className="text-primary text-base font-dana font-medium z-10 ">
            اشتراک هدیه
          </p>
        </div>
        {GiftItemMemorizeList.map((item, index) => (
          <CardActionArea
            onClick={() => handelItemClick(item)}
            className="!h-[48px] !border-t-black_2 !border-t !border-solid !flex !items-center !justify-between !px-4 !py-[10px]"
            key={'GiftSettingCard' + index}
          >
            <p className="text-gray_6 text-[15px] font-dana font-normal">
              {item.label}
            </p>
            <ChevronLeft className="stroke-primary w-6 h-6" />
          </CardActionArea>
        ))}
      </div>
      <GiftDialog setOpen={setIsOpenGiftDialog} open={IsOpenGiftDialog} />
    </>
  );
}

export default memo(GiftSettingCard);
