import React from "react";

function UpdateSVG({classNames}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            className={`${classNames}`}
            fill="none"
            viewBox="0 0 25 24"
        >
            <path
                fill="#F2C94C"
                d="M12.28 3.457a.76.76 0 01-.767-.75V.75a.76.76 0 01.768-.75.76.76 0 01.768.75v1.957a.76.76 0 01-.768.75zM19.006 6.179a.77.77 0 01-.543-.22.738.738 0 010-1.061l1.417-1.384a.78.78 0 011.086 0c.3.293.3.768 0 1.061l-1.417 1.384a.779.779 0 01-.543.22zM23.794 12.75h-2.003a.76.76 0 01-.767-.75.76.76 0 01.767-.75h2.003a.76.76 0 01.768.75.76.76 0 01-.768.75zM20.422 20.705a.77.77 0 01-.542-.22l-1.417-1.384a.738.738 0 010-1.061.78.78 0 011.086 0l1.417 1.384c.3.293.3.768 0 1.061a.775.775 0 01-.544.22zM4.14 20.705a.77.77 0 01-.543-.22.738.738 0 010-1.061l1.417-1.384a.78.78 0 011.086 0c.3.293.3.768 0 1.061l-1.417 1.384a.775.775 0 01-.543.22zM2.77 12.75H.768A.76.76 0 010 12a.76.76 0 01.768-.75H2.77a.76.76 0 01.768.75.76.76 0 01-.768.75zM5.556 6.179a.77.77 0 01-.542-.22L3.597 4.575a.738.738 0 010-1.061.78.78 0 011.086 0L6.1 4.898c.3.293.3.768 0 1.061a.781.781 0 01-.544.22zM15.351 21v1.25c0 .96-.808 1.75-1.79 1.75H11c-.859 0-1.79-.64-1.79-2.04V21h6.14zM16.794 6.56c-1.678-1.33-3.889-1.85-6.048-1.4-2.712.55-4.913 2.71-5.476 5.36-.573 2.72.471 5.42 2.702 7.07.604.44 1.024 1.12 1.167 1.91v.01c.02-.01.051-.01.072-.01h6.14c.02 0 .03 0 .051.01v-.01c.143-.76.604-1.46 1.31-2 1.73-1.34 2.733-3.34 2.733-5.5 0-2.12-.962-4.1-2.651-5.44zm-.675 5.94a.764.764 0 01-.768-.75c0-1.52-1.259-2.75-2.814-2.75a.764.764 0 01-.768-.75c0-.41.348-.75.768-.75 2.394 0 4.35 1.91 4.35 4.25 0 .41-.349.75-.768.75z"
            ></path>
            <path
                fill="#F2C94C"
                d="M9.139 19.5h.072c-.021 0-.052 0-.072.01v-.01zM15.402 19.5v.01c-.02-.01-.03-.01-.051-.01h.051z"
            ></path>
        </svg>
    );
}

export default UpdateSVG;
