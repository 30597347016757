import React, {Fragment, useImperativeHandle, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {CardActionArea} from "@mui/material";
import DialogHeader from "./DialogHeader.jsx";
import {useNativeBack} from "../../hooks/useNativeBack.js";

function ConfirmDialogs(
    {
        dialogIcon = null,
        title,
        text,
        buttonDirection,
        confirmBGColor,
        confirmTextColor,
        confirmText,
        confirmAction,
        cancelBGColor,
        cancelTextColor,
        cancelText,
        PanelClassName = "",
        cancelAction = () => {
            console.log("cancel")
        },
        confirmButtonWidth='!w-[200px]'

    },
    ref
) {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        openDialog() {
            setOpen(true);
        },
    }));

    const handleConfirmDialog = () => {
        confirmAction();
        setOpen(false);
    };

    const handleCloseDialog = (e) => {
        cancelAction()
        setOpen(false);
    };
    const closeBottomSheet = () => {
        setOpen(false)
    }
    useNativeBack(open,'confirm-dialog',closeBottomSheet);
    return (
        <Transition  appear show={open} as={Fragment}>
            <Dialog as="div"  onClose={handleCloseDialog} className="relative z-[1400]">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div  className="fixed inset-0 bg-black bg-opacity-50 "/>
                </Transition.Child>

                <div onClick={(e)=>e.stopPropagation()} className="fixed inset-0 overflow-y-auto font-dana">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                dir="rtl"
                                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-secondary p-4  text-left align-middle shadow-xl transition-all">
                                <DialogHeader header_text={title} onCloseDialog={handleCloseDialog} />
                                {dialogIcon && (
                                    <div className="h-[30px] mt-6">{dialogIcon}</div>
                                )}
                                <div className="px-2 flex flex-col gap-3 pt-4">
                                    <p className="text-white font-semibold text-base text-center mb-4 mt-6">
                                        {text}
                                    </p>
                                </div>
                                <div
                                    className={`${buttonDirection} mt-4 flex items-center justify-center gap-3`}
                                >
                                    <CardActionArea className={`!rounded-[100px] ${confirmButtonWidth} `}>
                                        <button
                                            onClick={() => handleConfirmDialog()}
                                            type="button"
                                            className={`${confirmTextColor} ${confirmBGColor} text-center inline-flex text-[16px] font-semibold w-full justify-center rounded-[100px] border px-4 py-3 focus:outline-none`}
                                        >
                                            {confirmText}
                                        </button>
                                    </CardActionArea>
                                    <CardActionArea className="!rounded-[100px] !w-[200px]">
                                        <button
                                            onClick={handleCloseDialog}
                                            type="button"
                                            className={`${cancelTextColor} ${cancelBGColor}   text-center inline-flex text-[16px] font-semibold w-full border justify-center rounded-[100px] px-4 py-3 focus:outline-none`}
                                        >
                                            {cancelText}
                                        </button>
                                    </CardActionArea>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default React.forwardRef(ConfirmDialogs);
