import React from "react";
import "swiper/css";
import { FreeMode } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import CategoryCard from "../playlist/CategoryCard";
import { useNavigate } from "react-router-dom";
import ArtistCard from "../playlist/ArtistCard.jsx";
import { IsIosDevice } from "../../helper/CheckDevice";
import { FixedSizeList as List } from "react-window";
import {useInView} from "react-intersection-observer";

function HomeSongCardSliderType2({
  item,
  metaData,
  cardStyle,
  fromMyMelodify,
}) {
  const navigate = useNavigate();
  const openCollection = (item) => {
    navigate(`/playlist-songs/${item?.id}`, {
      state: { collectionObject: item },
    });
  };
  const Column = ({ data, index, style }) => {
    return (
      <div  style={{ ...style, right: style.right + 7 + "px" }}>
        <div className="!flex !items-baseline !justify-start  z-30 ">
          <div
            className="!w-fit !pb-6 last:!pl-4 first:!pr-1"
            key={data[index]?.id}
          >
            <div
              className={`w-full h-full ${cardStyle}`}
              onClick={() => openCollection(data[index])}
            >
              {item?.collection_items_meta_data?.artistView ? (
                <ArtistCard item={data[index]} />
              ) : (
                <CategoryCard
                  fromMyMelodify={fromMyMelodify}
                  metaData={metaData}
                  item={data[index]}
                  scale={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <div className="flex flex-col gap-4">
      <div ref={ref} className="slider">
        {IsIosDevice() ? (
          <div
            className={`!w-full !h-fit flex overflow-scroll  !select-none new-song-swiper`}
          >
            {inView
            ?
                <List
                    direction="rtl"
                    height={145}
                    itemCount={item?.collections?.slice(0, 14).length}
                    itemSize={130}
                    layout="horizontal"
                    width={400}
                    style={{ width: "100% !important" }}
                    itemData={item?.collections?.slice(0, 14)}
                    className="!pr-[14px] "
                >
                  {  Column }
                </List>
                :
                <div className='h-[145px] w-full'></div>
            }

          </div>
        ) : (
          <Swiper
            // lazy={true}
            slidesPerView={"auto"}
            spaceBetween={9}
            cssMode={IsIosDevice()}
            modules={[FreeMode]}
            freeMode={true}
            className={`!w-full !h-fit flex flex-col-reverse  !select-none new-song-swiper`}
          >
            {item?.collections?.slice(0, 14)?.map((collectionItem, index) => (
              <div
                key={index + "SwiperHomeSongCardSliderType2"}
                className="!flex !items-baseline !justify-start  z-30 "
              >
                <SwiperSlide
                  className="!w-fit !pb-6 last:!pl-4 first:!pr-4"
                  key={collectionItem?.id}
                >
                  <div
                    className={`w-full h-full ${cardStyle}`}
                    onClick={() => openCollection(collectionItem)}
                  >
                    {item?.collection_items_meta_data?.artistView ? (
                      <ArtistCard
                        fromMyMelodify={fromMyMelodify}
                        item={collectionItem}
                      />
                    ) : (
                      <CategoryCard
                        fromMyMelodify={fromMyMelodify}
                        metaData={metaData}
                        item={collectionItem}
                        scale={true}
                      />
                    )}
                  </div>
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
}

export default HomeSongCardSliderType2;
