import React from "react";
import DeleteIconSvg from "../svg/support/DeleteIconSvg.jsx";
import UploadingIconSvg from "../svg/tickets/UploadingIconSvg.jsx";

function ErrorUpload({ item, index, deleteItem, loading }) {
  return (
    <div
      dir="rtl"
      className="w-full bg-gray_1 flex justify-between items-center flex-row rounded-[10px]  p-2 "
    >
      <div className="flex  w-[60px]"></div>

      <div
        dir="rtl"
        className="flex flex-col flex-[3]  max-w-[calc(100%-120px)] me-1"
      >
        <div dir="ltr">
          <p className="text-gray_4 text-xs font-poppins font-medium">
            {item?.name}
          </p>
        </div>
        <p className="text-red text-left text-xs font-dana leading-6">
          {item?.typeError
            ? "فرمت فایل نامعتبر است."
            : item?.sizeError
            ? "حجم فایل بیش از حد مجاز است."
            : "خطا در بارگذاری فایل."}
        </p>
      </div>
      <div className="flex items-center justify-end gap-2 w-[60px]">
        {loading ? (
          <div className="h-[38px] w-[38px]">
            <UploadingIconSvg />
          </div>
        ) : (
          <div
            onClick={() => {
              deleteItem(index);
            }}
            className="h-[38px] w-[38px]"
          >
            <DeleteIconSvg />
          </div>
        )}
        <p className="text-sm text-gray_5 font-dana w-4">{index + 1}</p>
      </div>
    </div>
  );
}

export default ErrorUpload;
