import React from 'react';
import MusicNoteLoading from "../loading/MusicNoteLoading.jsx";

function LoadingOnSheet(props) {
    return (
        <div
            className='absolute bg-primary_dark bg-opacity-90 z-[10] top-0 left-0 w-full h-full flex justify-center items-center'>
            <div className='w-[120px] h-[120px]'>
                <MusicNoteLoading speed={2}/>
            </div>
        </div>
    );
}

export default LoadingOnSheet;
