import { createSlice } from "@reduxjs/toolkit";
import { DecodeObject, EncodeObject } from "../../helper/Base64Cript";
import { SearchTabBarItem } from "../../consts/SearchTabBarItem";

export const SearchSlice = createSlice({
    name: 'search',
    initialState: {
        commitSearch: null,
        searchQuery: "",
        commitedSearchQuery: "",
        searchType: null,
        searchResults: [],
        //search result
        trackResult: null,
        artistResult: null,
        albumResult: null,
        playlistResult: null,
        allSearchResult: null,
        loadingStatus: {
            trackResult: false,
            artistResult: false,
            albumResult: false,
            playlistResult: false,
            allSearchResult: false,
        },
        searchCacheData: [],
        searchHistory: [],
        searchQueryForShowMore: null,
        selectedTab: SearchTabBarItem.ALL

    },
    reducers: {
        setSearchTypeState: (state, action) => {
            const { type } = action.payload
            state.searchType = type
        },
        setSearchResult: (state, action) => {
            const { result, type } = action.payload
            state[type] = result
            state.loadingStatus[type] = false
        },
        setCommitSearch: (state, action) => {
            // if (!canButtonClick(state.loadingStatus)) {
            //     return
            // }
            state.searchQueryForShowMore = state.searchQuery
            state.commitSearch = Date.now()
            state.loadingStatus.trackResult = true
            state.loadingStatus.artistResult = true
            state.loadingStatus.albumResult = true
            state.loadingStatus.playlistResult = true
            state.loadingStatus.allSearchResult = true
        },
        setSearchQuery: (state, action) => {
            const { searchQuery } = action.payload
            state.searchQuery = searchQuery
        }, storeSearchResult: (state, action) => {
            const { searchResult } = action.payload
            let result = state.searchCacheData?.filter(item =>
                item?.query === searchResult?.query && item?.type === searchResult?.type
            )
            if (result && result[0]) {
                result = JSON.parse(JSON.stringify(result));
                if (EncodeObject(result[0]) === EncodeObject(searchResult)) {
                    state[result?.type] = result
                    state.loadingStatus[result?.type] = false
                } else {
                    let index = state.searchCacheData.findIndex(item => item?.query === searchResult?.query && item?.type === searchResult?.type)
                    console.log("gsdgsdgsdgsdgsdgsdgsged");
                    state.searchCacheData[index] = { ...state.searchCacheData[index], ...searchResult }
                }
                return
            }

            state.searchCacheData = [...state.searchCacheData, searchResult]
        },
        setCacheStored: (state, action) => {
            const { query } = action.payload
            let result = state.searchCacheData?.filter(item =>
                item?.query === query
            )
            if (result) {
                result = JSON.parse(JSON.stringify(result));
                result?.map(item => {
                    state[item?.type] = DecodeObject(item?.encodeObj)
                    state.loadingStatus[item?.type] = false
                })
            } else {
                state.loadingStatus[result[0]?.type] = true
            }
        },
        setSearchHistory: (state, action) => {
            const { searchQuery: searchItem } = action.payload
            let result = state.searchHistory.filter(item => item?.id === searchItem?.id)
            if (result.length !== 0) {
                let newArray = [...state.searchHistory]

                newArray = newArray.filter((item, index) => item?.id != searchItem?.id)
                newArray.push(searchItem)
                state.searchHistory = newArray
                return
            } else if (state.searchHistory.length >= 20) {
                let newArray = [...state.searchHistory]
                newArray = newArray.filter((item, index) => index != 0)
                newArray.push(searchItem)
                state.searchHistory = newArray

            } else {
                state.searchHistory = [...state.searchHistory, searchItem]
            }
        },
        removeSearchHistoryItem: (state, action) => {
            const { item } = action.payload
            let newArray = [...state.searchHistory]
            newArray = newArray.filter(itemm => itemm?.id != item?.id)
            state.searchHistory = newArray

        },
        removeAllSearchHistoryItem: (state, action) => {
            state.searchHistory = []
        },
        setSelectedTab: (state, action) => {
            const { tab } = action.payload
            state.selectedTab = tab
        }, setCommitedSearchQuery: (state, action) => {
            const { commitedSearchQuery } = action.payload
            state.commitedSearchQuery = commitedSearchQuery
        },

    }
})
function canButtonClick(loadingStatus) {
    return Object.values(loadingStatus).every(status => !status);
}


export const { setSearchResult, setSearchTypeState, setCommitSearch, setSearchQuery, storeSearchResult, getQueryResult, setCacheStored, setSearchHistory, removeSearchHistoryItem, removeAllSearchHistoryItem, setSelectedTab, setCommitedSearchQuery } = SearchSlice.actions
export const selectSearch = (state) => state.search;
export const selectCommitSearch = (state) => state.search.commitSearch;
export const selectSearchQuery = (state) => state.search.searchQuery;
export const selectSearchResult = (state) => state.search.trackResult;
export const selectAllSearchResult = (state) => state.search.allSearchResult;
export const selectSearchPlaylistResult = (state) => state.search.playlistResult;
export const selectSearchArtistResult = (state) => state.search.artistResult;
export const selectSearchAlbumResult = (state) => state.search.albumResult;
export const selectSearchLoading = (state) => state.search.loadingStatus;
export const selectSearchCacheResult = (state) => state.search.searchCacheData;
export const selectSearchHistory = (state) => state.search.searchHistory;
export const selectSearchQueryForShowMore = (state) => state.search.searchQueryForShowMore;
export const selectCommitedSearchQuery = (state) => state.search.commitedSearchQuery;
export const getSelectedTab = (state) => state.search.selectedTab;
export default SearchSlice.reducer
