import React, {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getFaq} from "../../services/ApiClient.js";
import {useDispatch} from "react-redux";
import {setFaqList} from "../../redux/support/SupportSlice.js";
import FaqItem from "../../components/support/FaqItem.jsx";
import CommonHeader from "../../components/common/CommonHeader.jsx";
import FolderIconSVG from "../../components/svg/support/FolderIconSVG.jsx";
import TicketsHeader from "../../components/Tickets/TicketsHeader.jsx";
import CssShimmer from "../../components/common/CssShimmer.jsx";
import Container from "../../components/PageParentContainer/Container.jsx";;

function Support() {
    const dispatch = useDispatch()
    const [faq, setFaq] = useState([])
    const getFaqQuery = useQuery(["getFaqQuery"], () => getFaq(), {
        onSuccess: (res) => {
            setFaq(res?.data?.result?.faq_categories)
            dispatch(setFaqList({faqs: res?.data?.result?.faq_categories}))
        }
    });
    useEffect(() => {
        setFaq(getFaqQuery?.data?.data?.result?.faq_categories)
    }, [])
    //todo:we need an icon  for each faq category item
    return (
        <Container style="bg-secondary" header={<CommonHeader title={'ثبت تیکت'}/>}>
            <TicketsHeader/>
            <div dir="rtl"
                 className="flex flex-col bg-primary_dark justify-start items-start my-4 mx-4 p-4 hide-scroll-bar">
                <div className="flex flex-col w-full">
                    <div className="inline-flex">
                        <FolderIconSVG classList={'w-[24px] h-[24px]'}/>
                        <p
                            dir="rtl"
                            className="font-dana font-semibold text-primary text-sm min-[345px]:text-base min-[376px]:text-[18px] mr-2"
                        >
                            دسته بندی تیکت خود را انتخاب کنید:
                        </p>
                    </div>

                    <>
                        {getFaqQuery?.isLoading ?
                            <div className='w-full mt-4'>
                                {[...Array(10).keys()].map((item) => (
                                            <CssShimmer classNames={"h-8 max-h-9 rounded-[13px] mt-6 bg-gray_3 bg-opacity-10"}
                                                delay={'0.0' + item + 's'}
                                                shimColor={"rgba(255,255,255,0.1)"}
                                            />
                                    )
                                )}
                            </div>
                            :
                            <div className='w-full mt-4'>
                                {faq && faq?.map((item, index) => (
                                    <FaqItem item={item} key={index}/>
                                ))
                                }
                            </div>
                        }
                    </>

                </div>
            </div>
        </Container>
    );
}

export default Support;
