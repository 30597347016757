import React, { useEffect, useState } from 'react';

import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import { CardActionArea } from '@mui/material';
import ClickReaction from '../../helper/ClickReaction';
import PlaceHolderWithTitle from '../common/PlaceHolderWithTitle.jsx';
function CategoryCard({ item }) {
  return (
    <div
      className={`relative   rounded-[20px]   justify-center items-center aspect-square cursor-pointer w-full h-full `}
    >
      {item?.is_updated && (
        <div className="flex items-center z-10 justify-center flex-row absolute bg-yellow  w-fit bg-primary  top-1 left-1  rounded-[10px]">
          <p className="text-secondary text-[10px] py-1 px-2 font-dana font-[600] leading-none">
            {item?.updated_tracks} جدید
          </p>
        </div>
      )}
      <ClickReaction>
        <CardActionArea
          className={` overflow-hidden !rounded-[10px] w-full h-full `}
        >
          <div
            className={`w-full h-full relative flex flex-col shadow-collection-shadow  rounded-[10px] overflow-hidden xl:min-h-[6vw]`}
          >
            <CustomLazyLoadImage
              hasEffect={!item?.is_updated}
              image={item?.image}
            />
            <PlaceHolderWithTitle
              style={'xl:min-h-[6vw]'}
              title={item?.title}
            />
          </div>
        </CardActionArea>
      </ClickReaction>
    </div>
  );
}

export default CategoryCard;
