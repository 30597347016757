import React from "react";
import HomeMetaDataWithTitle from "../common/HomeMetaDataWithTitle";
import HomeGeneralCollectionSlider from "./HomeGeneralCollectionSlider.jsx";
import HomeGeneralCollectionsBanner from "./HomeGeneralCollectionsBanner.jsx";

function HomeGeneralCollectionSection({ item }) {
    return (
        <div className="py-3 z-20">
            <div dir="rtl" className="flex flex-col pt-4 pb-8 gap-1 bg-artist-dark ">
                <div className="w-full  pr-3">
                    <HomeMetaDataWithTitle listData={item?.general_collections_items} metaData={item?.general_collections_meta_data} />
                </div>
                <div className="flex flex-col gap-3  ">
                    <HomeGeneralCollectionSlider item={item?.general_collections_items[0]} />
                    {item?.general_collections_items[1]?.banner && (
                        <div className="pr-4 pl-4">
                            <HomeGeneralCollectionsBanner
                                banner={item?.general_collections_items[1]?.banner}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default React.memo(HomeGeneralCollectionSection);
