import React, {useEffect, useState} from 'react';
import {Virtuoso} from "react-virtuoso";
import ExclusiveTrackCard from "./ExclusiveTrackCard.jsx";
import {addToPlaylistData} from "../../helper/ReduxSelectorContext.js";
import TrackLimit from "../playlist/TrackLimit.jsx";

function ExclusiveTrackList({list, collection, pageId,requestPayload,endOfPagination,apiUrl}) {
    const [loaded, setLoaded] = useState(false)
    const addToPlaylistDataState = addToPlaylistData()
    useEffect(() => {
        setLoaded(true)
    }, []);

    return (
        loaded &&
        <Virtuoso
            customScrollParent={document.getElementById(pageId)}
            data={list}
            itemContent={(index, item) => (
                <div className='px-2 pb-2'>
                    {item?.track_limit && item?.track_limit?.message &&
                        <TrackLimit message={item?.track_limit?.message}/>}
                    <ExclusiveTrackCard item={item} inPlayer={false}
                                        isSelectMode={addToPlaylistDataState?.listSelectItems?.length > 0}
                                        list={list} showIsPlaying={false}
                                        apiUrl={apiUrl}
                                        requestPayload={requestPayload}
                                        endOfPagination={endOfPagination}
                    />

                </div>
            )}
        />




    );
}

export default ExclusiveTrackList;
