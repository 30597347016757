import React from 'react'

function EnptySearchSVG() {
  return (
    <svg
     class="w-full h-full"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M116.348 67.8497C103.741 48.5463 105.081 28.7597 99.0045 19.4563C92.9278 10.153 74.8178 -5.88699 32.0278 22.063C-10.7622 50.013 0.121156 80.3963 6.80782 90.6263C38.1012 138.533 138.678 102.036 116.348 67.8497Z"
        fill="#4C4C50" />
      <path
        d="M97.5 62.5V84.1667C97.5 87.8333 94.5 90.8333 90.8333 90.8333H29.1667C25.5 90.8333 22.5 87.8333 22.5 84.1667V62.5H35.0333C36.5 62.5 37.8 63.4667 38.2 64.8667L41.8 76.8C42.2 78.2 43.5 79.1667 44.9667 79.1667H75.0333C76.5 79.1667 77.8 78.2 78.2 76.8L81.8 64.8667C82.2 63.4667 83.5 62.5 84.9667 62.5H97.5Z"
        fill="#4C4C50" />
      <path
        d="M97.5 62.4993H84.9667C83.5 62.4993 82.2 63.466 81.8 64.866L78.2 76.7994C77.8 78.1994 76.5 79.166 75.0333 79.166H44.9667C43.5 79.166 42.2 78.1994 41.8 76.7994L38.2 64.866C37.8 63.466 36.5 62.4993 35.0333 62.4993H22.5L37.5 29.166H82.5L97.5 62.4993Z"
        fill="#F2C94C" />
      <path
        d="M85.7018 76.8L89.3018 64.8667C89.7018 63.4667 91.0018 62.5 92.4685 62.5H84.9685C83.5018 62.5 82.2018 63.4667 81.8018 64.8667L78.2018 76.8C77.8018 78.2 76.5018 79.1667 75.0352 79.1667H82.5352C84.0018 79.1667 85.3018 78.2 85.7018 76.8Z"
        fill="#303036" />
      <path
        d="M30 84.1667V62.5H22.5V84.1667C22.5 87.8333 25.5 90.8333 29.1667 90.8333H36.6667C33 90.8333 30 87.8333 30 84.1667Z"
        fill="#303036" />
      <path d="M37.5 29.166L22.5 62.4994H30L45 29.166H37.5Z" fill="#F2994A" />
      <path
        d="M49.3018 76.8L45.7018 64.8667C45.3018 63.4667 44.0018 62.5 42.5352 62.5H35.0352C36.5018 62.5 37.8018 63.4667 38.2018 64.8667L41.8018 76.8C42.2018 78.2 43.5018 79.1667 44.9685 79.1667H52.4685C51.0018 79.1667 49.7018 78.2 49.3018 76.8Z"
        fill="#F2994A" />
      <path
        d="M92.4805 21.666C89.7238 21.666 87.4805 19.4227 87.4805 16.666C87.4805 13.9093 89.7238 11.666 92.4805 11.666C95.2371 11.666 97.4805 13.9093 97.4805 16.666C97.4805 19.4227 95.2371 21.666 92.4805 21.666ZM92.4805 14.9993C91.5605 14.9993 90.8138 15.7493 90.8138 16.666C90.8138 17.5827 91.5605 18.3327 92.4805 18.3327C93.4005 18.3327 94.1471 17.5827 94.1471 16.666C94.1471 15.7493 93.4005 14.9993 92.4805 14.9993Z"
        fill="#3F3F3F" />
      <path
        d="M23.4766 16.0645L25.8336 13.7074L30.5476 18.4215L28.1906 20.7785L23.4766 16.0645Z"
        fill="#3F3F3F" />
      <path
        d="M22.8828 31.9766L27.5969 27.2625L29.9539 29.6195L25.2398 34.3336L22.8828 31.9766Z"
        fill="#3F3F3F" />
      <path
        d="M36.4375 18.4238L41.1515 13.7098L43.5086 16.0668L38.7945 20.7809L36.4375 18.4238Z"
        fill="#3F3F3F" />
      <path
        d="M90.8333 93.3327H29.1667C24.11 93.3327 20 89.2227 20 84.166V62.4994C20 62.146 20.0733 61.796 20.22 61.4727L35.22 28.1394C35.6233 27.2427 36.5167 26.666 37.5 26.666H82.5C83.4833 26.666 84.3767 27.2427 84.78 28.1394L99.78 61.4727C99.9267 61.796 100 62.146 100 62.4994V84.166C100 89.2227 95.89 93.3327 90.8333 93.3327ZM25 63.036V84.166C25 86.4627 26.87 88.3327 29.1667 88.3327H90.8333C93.13 88.3327 95 86.4627 95 84.166V63.036L80.8833 31.666H39.1167L25 63.036Z"
        fill="black" />
      <path
        d="M75.02 81.6667H44.98C42.3867 81.6667 40.14 79.9967 39.3933 77.51L35.8167 65.5967C35.71 65.2467 35.3833 65 35.02 65H22.5V60H35.02C37.6133 60 39.86 61.67 40.6067 64.1567L44.1833 76.07C44.29 76.4233 44.6167 76.6667 44.98 76.6667H75.02C75.3833 76.6667 75.7133 76.4233 75.8167 76.07L79.3933 64.1567C80.14 61.67 82.3833 60 84.98 60H97.5V65H84.98C84.6167 65 84.2867 65.2433 84.1833 65.5967L80.6067 77.51C79.86 79.9967 77.6133 81.6667 75.02 81.6667Z"
        fill="black" />
      <path d="M6.66797 88.334H113.335V93.334H6.66797V88.334Z" fill="black" />
    </svg>
  )
}

export default EnptySearchSVG