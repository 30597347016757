import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  getArtistTracks,
  getCollectionData,
  getCollectionTracks,
} from '../../services/ApiClient.js';
import { useLocation, useParams } from 'react-router-dom';
import { playListSortTypes } from '../../consts/MenuItemsConsts.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import CompleteSongList from '../../components/ArtistProfile/CompleteSongList.jsx';

import { useFollowCollection } from '../../helper/useFollowCollection.js';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';
import Loading from '../../components/loading/Loading.jsx';
import { useDispatch } from 'react-redux';
import { setSeenCollection } from '../../redux/RecentlyHeard/RecentlyHeardSlice';

import { getInitialData, globalData } from '../../helper/ReduxSelectorContext';
import ClickReaction from '../../helper/ClickReaction';
import PlaylistUpdateDialog from '../../components/playlist/PlaylistUpdateDialog';
import { setPlaylistUpdateDialogShowed } from '../../redux/globalData/globalDataSlice.js';
import { useAction } from '../../hooks/useAction.js';
import ScrollTopButton from '../../components/common/ScrollTopButton.jsx';
import Container from '../../components/PageParentContainer/Container.jsx';
import CollectionCategoryHeader from '../../components/playlist/CollectionCategoryHeader.jsx';
import VisitCountIcon from '../../components/svg/playList/VisitCountIcon.jsx';
import ToggleFollowSvgIcon from '../../components/svg/playList/ToggleFollowSvgIcon.jsx';
import CssShimmer from '../../components/common/CssShimmer.jsx';
import { playStoreUrl } from '../../consts/GlobalConsts.js';
import SortSvg from '../../components/svg/playList/SortSvg.jsx';
import SongListLoading from '../../components/loading/SongListLoading.jsx';
import UnfollowDialog from '../../components/common/UnfollowDialog.jsx';
import RenderShare from "../../components/common/RenderShare.jsx";
import {shareTypes} from "../../consts/shareTypes.js";
import ShareSvgType2 from "../../components/svg/common/ShareSvgType2.jsx";

function PlayListSongs() {
  const { playlistUpdateDialogShowed } = globalData();
  const { id: collectionId } = useParams();
  const [isFollow, toggleFollow,unfollowCollection,followCollectionLoading] = useFollowCollection(collectionId);
  const [followDialogState, setFollowDialogState] = useState(false);
  const { setting } = getInitialData() || {};
  const location = useLocation();
  const collectionObject = location.state?.collectionObject;
  const dialogRef = useRef(null);
  const [sortType, setSortType] = useState(playListSortTypes[0].type);
  const [collectionData, setCollectionData] = useState('');
  const [collectionTrackList, setCollectionTrackList] = useState(null);
  const dispatch = useDispatch();
  const action = useAction();
  const [trackLimit, setTrackLimit] = useState();
  const collectionQueryData = {
    collection_id: collectionId,
    offset: collectionTrackList?.length || 0,
    sort: sortType,
  };
  const getCollectionDataQuery = useQuery(
    ['getCollectionData' + collectionId],
    () => getCollectionData(collectionId),
    {
      onSuccess: res => {
        setCollectionData(res?.data?.result?.collection);
        dispatch(
          setSeenCollection({ seen_Collection: res?.data?.result?.collection }),
        );
      },
      refetchOnWindowFocus: false,
    },
  );
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['getPlaylistTracks' + sortType + collectionId],
      ({ pageParam = 0 }) =>
        getCollectionTracks({
          ...collectionQueryData,
          offset: pageParam,
        }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const allData = allPages?.flatMap(page => page?.data?.result?.tracks);
          return lastPage?.data?.result?.end ? false : allData?.length;
        },
        refetchOnWindowFocus: false,
        enabled: !getCollectionDataQuery?.isLoading,
      },
    );
  const tracksList = data?.pages?.flatMap(page => page?.data?.result?.tracks);
  const reFetchData = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const changeSort = type => {
    setSortType(type);
  };
  useEffect(() => {
    setCollectionData(getCollectionDataQuery?.data?.data?.result?.collection);
  }, [getCollectionDataQuery?.data]);

  useEffect(() => {
    changeSort(playListSortTypes[0].type);
  }, [collectionId]);
  //todo:we dont have second title in collection data
  const closeDialog = e => {
    console.log(e);
    if (typeof e === 'object') {
      e?.stopPropagation();
    }
    setFollowDialogState(false);
  };
  const openDialog = () => {
    setFollowDialogState(true);
  };
  const sharePlaylist = () => {
    navigator
      .share({
        title: 'اشتراک',
        text:
          'دانلود و شنیدن  آهنگ های پلی لیست' +
          ' " ' +
          collectionObject?.title +
          ' " ' +
          'در اپلیکیشن ملودیفای \n' +
          '\nلینک برنامه :' +
          '\n' +
          '\n' +
          playStoreUrl,
      })
      .then(() => console.log('Yay, you shared it :)'))
      .catch(error => {
        console.log(`this error happened during sharing : + ${error}`);
      });
  };
  const hasLimit = () => {
    const result = collectionTrackList?.some(item => item?.track_limit != null);
    setTrackLimit(result);
  };
  const unfollowCollectionHandler = (e) => {
    closeDialog(e)
    unfollowCollection()
  };
  const handleFollowCollection = () => {
    if(followCollectionLoading){
      return
    }
    if(isFollow){
       openDialog()
    }else{
      toggleFollow()
    }
  }
  useEffect(() => {
    hasLimit();
  }, [collectionTrackList]);
  useEffect(() => {
    if (playlistUpdateDialogShowed) {
      return;
    }
    if (collectionData?.is_updated) {
      dispatch(setPlaylistUpdateDialogShowed());
      dialogRef?.current?.openModal();
    }
  }, [collectionData]);

  return (
    <>
      <UnfollowDialog closeDialog={closeDialog} openState={followDialogState} confirmFunction={unfollowCollectionHandler} type='playlist'/>
      <Container style="bg-primary_dark">
        <CollectionCategoryHeader
          hasSearch={false}
          title={collectionObject?.title}
          backgroundId="playlist-songs-background"
          pageId="playlist-song"
        />
        <div
          dir="rtl"
          id="playlist-song"
          className="w-full h-full overflow-scroll hide-scroll-bar pb-10"
        >
          <div
            id="playlist-songs-background"
            className=" w-full aspect-square relative  overflow-hidden xl:min-h-[30vw] "
          >
            <CustomLazyLoadImage
              imageClass={`object-cover block  `}
              image={
                collectionObject?.image_large
                  ? collectionObject?.image_large
                  : collectionObject?.image
              }
            />
            <div className="absolute  top-0 right-0 left-0 bottom-0 bg-playlist-song-overlay   h-[100.15%] "></div>
          </div>
          <ScrollTopButton id={'playlist-song'} />
          <PlaylistUpdateDialog ref={dialogRef} />
          <div
            id="page-infinite-scroll"
            className="relative px-4 flex flex-col gap-5 mt-[-75px] w-full"
          >
            <div className="flex flex-col`">
              <div className="flex w-full justify-between">
                <div className="flex flex-col gap-1">
                  <div className="flex gap-2 items-center  ">
                    <VisitCountIcon />
                    {collectionData?.visits_count ? (
                      <div className="flex items-center">
                        <p className="text-white text-base font-dana font-medium ">
                          {collectionData?.visits_count}
                        </p>
                        <p className="text-white text-base font-dana font-normal ">
                          مشاهده
                        </p>
                      </div>
                    ) : (
                      <CssShimmer classNames={'w-20 rounded-[10px] h-6'} />
                    )}
                  </div>
                  {collectionObject?.is_updated &&
                    (collectionData ? (
                      <div className="flex  items-center">
                        <p className="text-xs  text-gray_6 font-dana font-normal ">
                          <span>آخرین بروزرسانی: </span>
                          {collectionData?.updated_days > 0 ? (
                            <span>{collectionData?.updated_days} روز قبل </span>
                          ) : (
                            <span>امروز</span>
                          )}{' '}
                          <span className="text-primary">
                          (<span> {collectionData?.updated_tracks} </span>آهنگ
                          جدید)
                        </span>
                        </p>
                      </div>
                    ) : (
                      <CssShimmer classNames={'w-30 h-4 rounded-[10px]'} />
                    ))}
                </div>
                <div className="flex items-end">
                  {collectionData?.tracks_count ? (
                    <p className="text-gray_4 text-xs font-dana font-normal ">
                      {Number(collectionData?.tracks_count)?.toLocaleString(
                        'en-us',
                      )}{' '}
                      آهنگ
                    </p>
                  ) : (
                    <CssShimmer classNames={'w-24 h-6 rounded-[10px]'} />
                  )}
                </div>
              </div>
            </div>
            <div class="flex justify-between items-center w-full gap-3">
              {setting?.enable_playlist_share &&
                <div className='h-[46px] aspect-square'>
                  <RenderShare id={collectionId} type={shareTypes?.COLLECTION}>
                    <ShareSvgType2/>
                  </RenderShare>
                </div>
              }
              <ClickReaction style={`${setting?.enable_playlist_share ? ' w-[calc(100%_-_58px)]' :'w-full'}`}>
                <div
                  onClick={handleFollowCollection}
                  className={` ${
                    isFollow
                      ? ' text-white border-white '
                      : ' border-primary text-primary'
                  } border-[1px] font-dana text-base font-medium w-full rounded-[20px] h-[46px] flex justify-center items-center gap-4 flex items-center `}
                >
                  <ToggleFollowSvgIcon fill={isFollow ? '#ffffff' : '#F2C94C'} />
                  {followCollectionLoading ?
                    <div className='min-w-[85px]'>
                      <Loading parentClass='flex !justify-start' bgColor={isFollow ? '#ffffff' : '#F2C94C'}/>
                    </div> :
                    <span className='min-w-[85px]'>{isFollow ? 'دنبال میکنید' : 'دنبال‌کردن'}</span>
                  }
                </div>
              </ClickReaction>
            </div>
            <InfiniteScroll
              className="!overflow-visible"
              next={reFetchData}
              hasMore={hasNextPage}
              loader={
                <div className="mt-4 flex w-full justify-center">
                  <SongListLoading />
                </div>
              }
              scrollableTarget="playlist-song"
              dataLength={tracksList?.length || 0}
            >
              <div>
                <CompleteSongList
                  scrollId={'playlist-song'}
                  updated={collectionData?.is_updated}
                  updatedCount={collectionData?.updated_tracks}
                  onChangeSort={changeSort}
                  list={tracksList}
                  pageId={collectionId}
                  titleUnderLine={false}
                  collectionObject={collectionObject}
                  sortTypes={playListSortTypes}
                  sortButton={activeSortText => (
                    <div className="flex items-center gap-1">
                      <div className="flex gap-2   items-center">
                        <div className="flex w-[22px] h-[16px]  ">
                          <SortSvg />
                        </div>
                        <p className="text-primary font-dana text-[14px] font-medium">
                          ترتیب نمایش:
                        </p>
                      </div>
                      <p className="text-gray_3 font-dana text-[12px] font-medium">
                        {activeSortText}
                      </p>
                    </div>
                  )}
                  loading={isLoading && !tracksList}
                  apiUrl="/getCollectionTracks"
                  requestPayload={{
                    collection_id: collectionId,
                    sort: sortType,
                  }}
                  endOfPagination={!hasNextPage}
                />
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </Container>
    </>

  );
}

export default PlayListSongs;
