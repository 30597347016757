import React from "react";
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader";
import { Downloadqualities } from "../../consts/downloadQuality";
import { useDispatch } from "react-redux";
import {
  downloadData,
  getLimitations,
  useUserData,
} from "../../helper/ReduxSelectorContext";
import { useState } from "react";
import { useDownload } from "../../hooks/useDownload";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import DownloadQualitySvg from "../svg/setting/DownloadQualitySvg";
import QualityCard from "./QualityCard";
import { userData } from "../../redux/login/LoginSlice";
import { useMutation } from "@tanstack/react-query";
import { setDownloadQuality } from "../../services/ApiClient";
import MusicNoteLoading from "../loading/MusicNoteLoading.jsx";

function DownloadQuality({ openBottomSheetSetter }) {
  const dispatch = useDispatch();
  const user = useUserData();
  const limitations = getLimitations();
  const navigate = useNavigate();
  const { temporaryDownloadQueue } = downloadData();
  const [startDownloadTrack] = useDownload();

  // ANCHOR API
  const setDownloadQualityMutation = useMutation( 
    (data) => setDownloadQuality(data),
    {
      onSuccess: (res) => {
        const newUserData = {
          ...user,
          download_quality: res?.data?.result?.user?.download_quality,
        };
        openBottomSheetSetter(false);
        dispatch(userData({ user: newUserData }));
      },
    }
  );

  //   TODO  UNUSE CODE DLETE OFTER CHECK IT AND USESTATE IMPORT
  const [selectedDownloadQuality, setSelectedDownloadQuality] = useState("160");

  const dispatchUserData = (Data) => {
    dispatch(
      userData({
        user: Data,
      })
    );
  };

  useEffect(() => {
    if (temporaryDownloadQueue) {
      startDownloadTrack(temporaryDownloadQueue);
    }
  }, [user]);

  const canSelectQuality = (quality) => {
    const limitationKey = `download_quality_${quality}`;
    const limitationObject = limitations[limitationKey];
    if (limitationObject?.enable) {
      return false;
    } else {
      return true;
    }
  };

  const handleDownloadQuality = (quality) => {
    if(user?.download_quality === quality){
      openBottomSheetSetter(false);
      return;
    }
    if (canSelectQuality(quality)) {
      const data = { ...user, download_quality: quality };
      dispatchUserData(data);
      //   TODO  UNUSE CODE DLETE OFTER CHECK IT
      setSelectedDownloadQuality(quality);
      setDownloadQualityMutation.mutate({ quality });

      //   TODO  UNUSE CODE DLETE "return" OFTER CHECK IT

      return;
    } else {
      // handleCloseDialog()
      navigate("/plans");
      //   TODO  UNUSE CODE DLETE "return" OFTER CHECK IT
      return;
    }
  };

  return (
    <div className="flex flex-col gap-2 bg-primary_dark ">
      <BottomSheetHeader
        closeBottomSheet={() => openBottomSheetSetter(false)}
        HeaderIcon={<DownloadQualitySvg />}
        title="کیفیت دانلود"
        dividerLine
      />
      <div className='relative flex flex-col gap-2 px-4 pt-2  pb-4  '>
        {setDownloadQualityMutation?.isLoading &&
            <div
                className='absolute bg-primary_dark bg-opacity-90 z-[10] top-0 left-0 w-full h-full flex justify-center items-center'>
              <div className='w-[120px] h-[120px]'>
                <MusicNoteLoading speed={2}/>
              </div>
            </div>
        }
      {Downloadqualities.map((item, index) => (
        <div
          dir="rtl"
          onClick={() => handleDownloadQuality(item.quality)}
          key={index + "-quality" + item}
        >
          <QualityCard
            isChecked={item.quality === user?.download_quality}
            item={item}
            isPremiumlUser={user?.is_premium}
          />
        </div>
      ))}
      </div>
    </div>
  );
}

export default DownloadQuality;
