import React from "react";

function MusicCountSvg(props) {
    return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            fill="#fff"
            d="M19.097.576a2.493 2.493 0 00-2.058-.531L7.04 1.917A2.5 2.5 0 005 4.377v9.42c-.504-.3-1.08-.46-1.667-.463a3.334 3.334 0 103.334 3.333V7.85l11.666-2.183v5.634a3.299 3.299 0 00-1.666-.467A3.334 3.334 0 1020 14.167V2.5a2.499 2.499 0 00-.903-1.924zM3.333 18.334a1.667 1.667 0 110-3.334 1.667 1.667 0 010 3.334zM6.667 6.155V4.376a.833.833 0 01.679-.819l10-1.875a.834.834 0 01.987.818v1.467L6.667 6.155zm10 9.679a1.667 1.667 0 110-3.334 1.667 1.667 0 010 3.334z"
        ></path>
    </svg>
);

}

export default MusicCountSvg;
