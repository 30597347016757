import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { appendBaseUrl } from '../../helper/utils.js';
import CustomImage from '../common/CustomImage.jsx';

function PlansCardItem({ item, index, giftData }) {
  const navigate = useNavigate();

  const planImage = useMemo(
    () => appendBaseUrl(item?.plan_image),
    [item?.plan_image],
  );

  const handlePlanClick = item => {
    navigate('/payment', { state: { ...item, giftData } });
  };
  return (
    <div
      onClick={() => handlePlanClick(item)}
      key={index}
      className="flex flex-col h-full  items-center justify-center cursor-pointer "
    >
      <div
        style={{
          background:
            item?.month == 12
              ? `url('${planImage}')`
              : item?.plan_background_color,
        }}
        className="w-full rounded-[20px]  flex items-center flex-col h-full "
      >
        <div className="flex items-center p-4  gap-2 w-full min-h-[56px]">
          {item?.month == 12 && item?.plan_icon_image && (
            <CustomImage
              className="h-[24px]  w-[24px]  "
              src={item?.plan_icon_image}
            />
          )}
          <div
            style={{ color: item?.plan_titer_color }}
            className="text-[20px]   font-kalameh font-medium leading-5 "
          >
            {item?.plan_title}
          </div>
        </div>
        <hr className="h-[2px]  bg-primary_dark w-full" />
        <div className="p-4 !pt-2  w-full flex flex-col gap-2 h-full ">
          {item?.month !== 1 && (
            <div
              style={{
                color: item?.plan_discount_title_color,
                background: item?.plan_discount_background_color,
              }}
              className={`rounded-[10px]    text-[12px] w-full p-1 font-dana font-normal flex items-center justify-center text-center   `}
            >
              <span>{item?.plan_discount_title}</span>
            </div>
          )}
          {item?.month !== 1 ? (
            <div className=" w-full  flex   flex-col gap-2  ">
              <div className="flex flex-col items-start justify-between w-full gap-2  ">
                <span
                  style={{ color: item?.plan_text_color }}
                  className={` text-[12px]  font-kalameh font-medium leading-[24px] leading-5 `}
                >
                  {item?.plan_every_month_title}
                </span>
                <div className="flex flex-col items-center  justify-center w-full">
                  <div className="flex relative">
                    <div className="flex gap-1 ">
                      <span
                        style={{ color: item?.plan_old_price_color }}
                        className={` text-[24px] font-kalameh  font-black  flex gap-1 items-center leading-6 `}
                      >
                        {item?.old_monthly_price}
                      </span>
                      <span
                        style={{ color: item?.plan_old_price_color }}
                        className={` text-[12px] font-kalameh leading-5  font-medium `}
                      >
                        {item?.plan_unit_title}
                      </span>
                    </div>
                    <div
                      style={{ color: item?.plan_strikethrough_price_color }}
                      className={`absolute top-[60%] border-b-[1px] z-1 w-full `}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="flex gap-1 items-center justify-center ">
                <span
                  style={{ color: item?.plan_text_color }}
                  className={` text-[24px] font-kalameh  font-black leading-[24px]  `}
                >
                  {' '}
                  {item?.monthly_price}
                </span>
                <span
                  style={{ color: item?.plan_text_color }}
                  className={` text-[14px] font-kalameh  font-medium leading-[24px]  `}
                >
                  {' '}
                  {item?.plan_unit_title}
                </span>
              </div>
            </div>
          ) : (
            <div className="w-full  flex flex-col h-full justify-end  ">
              <div className="flex items-center gap-1 justify-center ">
                <span
                  style={{ color: item?.plan_text_color }}
                  className=" text-[24px] font-kalameh  font-black"
                >
                  {item?.monthly_price}
                </span>
                <span
                  style={{ color: item?.plan_text_color }}
                  className=" text-[12px] font-kalameh  font-medium"
                >
                  {item?.plan_unit_title}
                </span>
              </div>
            </div>
          )}
          <div className="w-full h-full flex items-end">
            <button
              style={{ background: item?.plan_button_buy_background_color }}
              className="rounded-[23px] min-h-[36px] !h-[36px] font-dana font-medium text-[14px]  flex items-center justify-center  w-full "
            >
              {item?.plan_buy_button_title}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlansCardItem;
