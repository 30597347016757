import { memo } from "react";

const NoDataGift = (props) => (
  <svg
    width={88}
    height={88}
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <mask
        id="b"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={-1}
        width={88}
        height={89}
      >
        <path d="M0-.001h88v88H0v-88Z" fill="#fff" />
      </mask>
      <g
        mask="url(#b)"
        stroke="#4F4F4F"
        strokeWidth={2.578}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M38.078 65.965V53.26m0 32.676V72.072m12.31-18.812v32.676M9.043 65.22l43.343-24.154a2.538 2.538 0 0 0 .98-3.439l-2.615-4.688a2.534 2.534 0 0 0-3.44-.975L3.964 56.12a2.533 2.533 0 0 0-.975 3.434l2.615 4.693a2.541 2.541 0 0 0 3.44.974Zm23.544-25.05 5.075 9.102M23.778 57.01l-5.075-9.101" />
        <path d="M15.946 49.447c-24.009 2.849-15.724-26.934 4.745-10.9M30.43 53.26h36.808c1.01 0 1.84.83 1.84 1.84v27.546c0 1.81-1.48 3.29-3.29 3.29H22.68c-1.81 0-3.29-1.48-3.29-3.29v-23.19" />
        <path d="m18.705 47.908 13.884-7.737-1.755-3.143a3.615 3.615 0 0 0-4.905-1.395l-7.584 4.228a3.607 3.607 0 0 0-1.395 4.899l1.755 3.148Z" />
        <path d="M16.506 42.72c-2.075-.755-3.67-1.15-6.455-1.455M25.44 25.032c-1.51 2.61-2.306 6.483-1.71 11.825m11.769 1.695c9.784-12.3.645-20.247-6.15-17.193m.128 14.298c.145-1.829 1.034-4.583 2.154-6.422m32.159-6.282s1.78 16.433-4.2 22.915m13.062-17.962s2.58-11.365 14.055-14.104m-33.49-9.391-1.985 3.314c-.215.36-.245.48-.69.59l-3.93.974c-.76.19-.8.365-.28.955l2.535 3.048c.27.325.31.705.275 1.165l-.305 3.768c-.085.78.08.875.81.58l3.455-1.44c.36-.15.925-.15 1.28 0l3.46 1.44c.73.295.89.2.804-.58l-.3-3.768c-.035-.46 0-.84.275-1.165l2.53-3.048c.52-.59.485-.765-.28-.955l-3.93-.975c-.445-.11-.47-.23-.69-.59l-1.984-3.313c-.4-.675-.646-.675-1.05 0Zm25.21 29.632-1.99 3.314c-.215.36-.245.48-.685.59l-3.93.974c-.764.19-.804.365-.28.955l2.53 3.048c.275.325.31.705.275 1.165l-.304 3.763c-.08.785.08.88.81.585l3.454-1.44c.36-.15.925-.15 1.285 0l3.455 1.44c.73.295.89.2.81-.585l-.305-3.763c-.035-.46 0-.84.275-1.165l2.53-3.048c.525-.59.485-.765-.28-.955l-3.93-.975c-.44-.11-.47-.23-.685-.59l-1.985-3.313c-.405-.675-.65-.675-1.05 0Zm-4.177-21.67a5.164 5.164 0 0 0 5.16-5.153 5.165 5.165 0 0 0-5.16-5.158 5.168 5.168 0 0 0-5.16 5.158c0 2.839 2.32 5.153 5.16 5.153Z" />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h88v88H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default memo(NoDataGift);
