import React from 'react'
const LoginHeaderBackgroundImage = ({ children,loginHeader }) => {
    const loginBG = {
        backgroundImage: `url("${loginHeader}")`
    };
    return (
        <div className='relative w-full z-0  min-h-[225px]'>
            <div style={loginBG}
                 className={`absolute w-full top-0 left-0 z-0 bg-cover bg-[top_center] bg-no-repeat min-h-[225px]`} />
            <div className={`bg-login-background absolute w-full h-full`} />
            <div className='relative z-20'>
                {children}
            </div>
        </div>
    )
}

export default LoginHeaderBackgroundImage