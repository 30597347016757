import React, {useImperativeHandle, useState} from 'react';
import SwipeBottomSheet from "../bottomSheets/SwipeBottomSheet";
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import AddToPlayListSvg from "../svg/queue/AddToPlayListSvg.jsx";
import AddToPlaylistYellowBox from "../MyMelodify/AddToPlaylistYellowBox.jsx";
import UserPlaylistShimmerContainer from "../MyMelodify/UserPlaylistShimmerContainer.jsx";
import UserPlaylistList from "../MyMelodify/UserPlaylistList.jsx";
import {useDispatch} from "react-redux";
import {addToPlaylistData, globalData} from "../../helper/ReduxSelectorContext.js";
import {useQuery} from "@tanstack/react-query";
import {getUserCollections} from "../../services/ApiClient.js";
import {setUserCollections} from "../../redux/globalData/globalDataSlice.js";
import {disableListSelectMode, disablePlayerListSelectMode} from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import MusicNoteLoading from "../loading/MusicNoteLoading.jsx";
import {useNativeBack} from "../../hooks/useNativeBack.js";

function AddToPlaylistBottomSheet({inPlayerSheet = false}, ref) {
    const dispatch = useDispatch()
    const globalDataObject = globalData()
    const addToPlaylistDataState = addToPlaylistData()
    const userCollections = globalDataObject?.userCollections
    const [openAddToPlaylist, setOpenAddToPlaylist] = useState(false);
    useImperativeHandle(
        ref,
        () => ({
            openAddToPlaylist() {
                setOpenAddToPlaylist(true);
            }

        })
    );
    const clearSelection = () => {
        dispatch(disableListSelectMode())
        dispatch(disablePlayerListSelectMode())
    }
    const closeAddToPlayList = () => {
        if (inPlayerSheet) {
            clearSelection()
        }
        setOpenAddToPlaylist(false);
    }
    const closeSheetInChild = () => {
        setOpenAddToPlaylist(false);
        clearSelection()
    }

    const toggleCreatePlaylistBottomSheet = (newOpen) => () => {
        if (inPlayerSheet && !newOpen) {
            clearSelection()
        }
        setOpenAddToPlaylist(newOpen);
    };
    const closeBottomSheet = () => {
        setOpenAddToPlaylist(false)
    }
    useNativeBack(openAddToPlaylist, 'go-to-artist', closeAddToPlayList);
    return (
        <SwipeBottomSheet
            keepMount={false}
            open={openAddToPlaylist}
            toggleDrawer={toggleCreatePlaylistBottomSheet}
        >
            <BottomSheetHeader dividerLine={true} closeBottomSheet={closeAddToPlayList}
                               HeaderIcon={<div className='w-[20px] h-[20px]'>
                                   <AddToPlayListSvg fill={'#ffffff'}/>
                               </div>} title="افزودن به پلی لیست"/>
            <div dir='rtl'
                 id='user-playlist-page-sheet'
                 className='flex w-full h-[70vh] flex flex-col overflow-scroll hide-scroll-bar gap-4 bg-[#212121] p-4 relative'>
                {addToPlaylistDataState?.addToPlaylistBottomSheetLoader &&
                    <div
                        className='absolute bg-secondary opacity-80 top-0 left-0 w-full h-full flex justify-center items-center z-[50]'>
                        <div className='w-[120px] h-[120px]'>
                            <MusicNoteLoading speed={2}/>
                        </div>
                    </div>
                }
                <AddToPlaylistYellowBox onCloseBottomSheet={closeSheetInChild} inBottomSheet={true}/>
                <div className="flex w-full min-h-[1.5px] h-[1.5px] bg-secondary"></div>
                {userCollections && userCollections.length > 0 &&
                    <UserPlaylistList onCloseBottomSheet={closeSheetInChild} inBottomSheet={true}
                                      collections={userCollections}/>
                }
            </div>

        </SwipeBottomSheet>
    );
}

export default React.forwardRef(AddToPlaylistBottomSheet);
