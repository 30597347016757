import React, { useEffect } from 'react';
import {
  addToDownloadedList,
  deActiveDownload,
  removeDownloadFromQueue,
  setDownloadActive,
  setDownloadActiveId,
  setDownloadProgressPercent,
} from '../../redux/download/downloadSlice.js';
import { useMutation } from '@tanstack/react-query';
import { downloadDone, downloadTrack } from '../../services/ApiClient.js';
import {
  downloadData,
  getLimitations,
} from '../../helper/ReduxSelectorContext.js';
import { useDispatch } from 'react-redux';
import FileSaver from 'file-saver';
import { insertDownloadedFile } from '../../indexDB/dbQuery';
import { setLimitationDialogData } from '../../redux/globalData/globalDataSlice.js';
import { downloadToast } from '../../helper/ToastsEmitter.jsx';
import DownloadLimitationImag from '../../assets/limitation/downloadImage.webp';
import { IsIosDevice } from '../../helper/CheckDevice.js';
import * as Sentry from '@sentry/react';
let controller = new AbortController();

function GlobalDownload(props) {
    const {downloadedFiles, downloadQueue, downloadActive} = downloadData();
    const limitations = getLimitations()
    const dispatch = useDispatch();
    const handlePercent = (event) => {
        const percentage = Math.round((100 * event.loaded) / event.total);
        dispatch(setDownloadProgressPercent({percent: percentage}))

    };
    const downloadDoneMutation = useMutation((data) => downloadDone(data))
    const downloadMutation = useMutation(({url, signal}) => downloadTrack(url, (e) => handlePercent(e), signal));
    const handleDownLoadQueue = async (queueList) => {
        dispatch(setDownloadActive());

        dispatch(setDownloadProgressPercent({percent: 0}));
        dispatch(setDownloadActiveId({id: queueList[0]?.id}));
        try {
            const response = await downloadMutation.mutateAsync({url: queueList[0].url, signal: controller.signal});
            // ArryBuffer
            // const blob = await response.data
            const blob = new Blob([response.data], {type: 'audio/mp3'});
            downloadDoneMutation.mutate({
                track_id: queueList[0]?.id,
                quality: queueList[0]?.quality[0]?.quality,
                total_bytes: blob?.size,
                folder_title: 'download-pwa',
            })
            if (!IsIosDevice()) {
                saveDownloadedFile(queueList[0]?.track?.download_title + '.mp3', blob);
            }
            await insertDownloadedFile(queueList[0]?.id, blob).then(data => {
              if (data) {
                dispatch(
                  addToDownloadedList({
                    newItem: {
                      id: queueList[0]?.id,
                      isDownloaded: true,
                      ...queueList[0].track,
                      quality: queueList[0]?.quality,
                    },
                  }),
                );
              }
            });
        } catch (error) {
            // Handle error
            dispatch(removeDownloadFromQueue({id: queueList[0]?.id}));
            controller = new AbortController();

            if (!error?.response) {
                if (error?.code === 'ERR_CANCELED') {
                    return
                } else {
                    downloadToast()
                }


            }
            if (Number(error?.response?.status) === 486 || Number(error?.response?.status) === 488)  {
                dispatch(setLimitationDialogData({limitationData: limitations?.download_per_day}))
                dispatch(setLimitationDialogData({
                    limitationData: {
                        message: limitations?.download_per_day?.message,
                        boxAlert: 'پایان تعداد دانلود',
                        image: DownloadLimitationImag
                    }
                }))
                return
            }
            if (Number(error?.response?.status) === 485 || Number(error?.response?.status) === 487) {
                dispatch(setLimitationDialogData({
                    limitationData: limitations?.download_per_day?.message,
                    boxAlert: 'پایان تعداد دانلود',
                    image: DownloadLimitationImag
                }))
                return
            }


        } finally {
            // dispatch(deActiveDownload());
            dispatch(removeDownloadFromQueue({id: queueList[0]?.id}));
        }


    };
    useEffect(() => {

        if (downloadQueue.length < 1 || downloadActive) {
            return;
        }

        handleDownLoadQueue(downloadQueue).then(() => {
            dispatch(deActiveDownload())
        })
    }, [downloadQueue, downloadActive]);


    return (
        <></>
    );
}

export default GlobalDownload;

// for handle cancel download
export const abortDownload = () => {
    controller.abort()
}

// for save file in user files
function saveDownloadedFile(name, blob) {
  try {
    FileSaver.saveAs(blob, name);
  } catch (error) {
    Sentry.captureMessage(error,{
      level:'log'
    })
    downloadToast('خطا در ذخیره فایل دانلود شده');
  }
}
