import React from 'react';

function EditPlaylistNameSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <g clipPath="url(#clip0_318_3623)">
                <path
                    fill="#fff"
                    d="M18.828 1.172A3.975 3.975 0 0015.998 0a3.975 3.975 0 00-2.83 1.172L1.986 12.355a.782.782 0 00-.2.343L.03 19.01a.781.781 0 00.966.961l6.31-1.792a.781.781 0 00.34-1.303l-3.978-3.991 9.27-9.27 3.447 3.447-7.096 7.078a.781.781 0 101.103 1.106l8.437-8.414A3.976 3.976 0 0020 4.001a3.976 3.976 0 00-1.172-2.829zM5.601 17.037l-3.694 1.05 1.035-3.717 2.66 2.667zM17.724 5.726l-.233.232-3.45-3.45.233-.231a2.423 2.423 0 011.724-.715c.652 0 1.264.254 1.725.715.46.46.715 1.073.715 1.725 0 .651-.254 1.264-.714 1.724z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_318_3623">
                    <path fill="#fff" d="M0 0H20V20H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default EditPlaylistNameSvg;
