import { Fragment } from "react";

function handelBreakSentence(sentence) {
    if (sentence?.split("\n").length === 1) return sentence;
    return sentence?.split("\n").map((word,index) => (
      <Fragment key={"br-"+index}>
        {word}
        <br />
      </Fragment>
    ));
  }
export default handelBreakSentence
