import React, { useState } from "react";
import YellowDeleteButtonSVG from "../../components/svg/search/YellowDeleteButtonSVG";
import GrayDeleteButtonSvg from "../../components/svg/search/GrayDeleteButtonSvg";
import CloseButtonSearchSvg from "../../components/svg/search/CloseButtonSearchSvg";
import { getSearchHistory } from "../../helper/ReduxSelectorContext";
import EnptySearchSVG from "../../components/svg/search/EnptySearchSVG";
import { useDispatch } from "react-redux";
import {
  removeAllSearchHistoryItem,
  removeSearchHistoryItem,
  setCommitSearch,
  setSearchHistory,
  setSearchQuery,
} from "../../redux/search/SearchSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SearchHistoryNoteIconSvg from "../../components/svg/search/SearchHistoryNoteIconSvg";
import { CardActionArea } from "@mui/material";
import SearchEmptyHistorySvg from "../../components/svg/search/SearchEmptyHistorySvg";
import RenderSearchViewType from "./tabs/RenderSearchViewType";

function SearchHistory({ searchInputRef }) {
  const [searchHistory, setSearchHistoryLocal] = useState("");
  const [canUpdate, setCanUpdate] = useState(true);
  const searchHistoryGlobal = getSearchHistory();

  useEffect(() => {
    if (canUpdate) {
      setSearchHistoryLocal(searchHistoryGlobal);
      setCanUpdate(false);
    }
  }, [searchHistoryGlobal, canUpdate]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRemoveItemFromSearchHistory = (e, item) => {
    setCanUpdate(true);
    e.stopPropagation();
    e.preventDefault();
    dispatch(removeSearchHistoryItem({ item }));
  };

  const handleRemoveAllItemFromSearchHistory = () => {
    setCanUpdate(true);
    dispatch(removeAllSearchHistoryItem());
  };

  return (
    <>
      <div className="w-[100.5%] fixed top-[120px] bg-secondary z-30 max-w-[520px] left-[50%] translate-x-[-50%]">
        <div className=" flex items-center justify-between   pt-3 pb-2 px-4 ">
          <div className="flex flex-row gap-2 items-center">
            <div className="h-[15px] w-[3px] rounded-full bg-gray_4 text-gray_4"></div>
            <p className="text-[14px] font-dana font-[500] text-gray_4">
              تاریخچه ی جست وجو
            </p>
          </div>
          {searchHistory?.length !== 0 && (
            <p
              onClick={() => handleRemoveAllItemFromSearchHistory()}
              className="text-gray_4 cursor-pointer text-[14px] font-dana font-[400]"
            >
              حذف همه
            </p>
          )}
        </div>
      </div>
      {searchHistory?.length === 0 && (
        <div className="flex flex-col items-center justify-center mt-3 lg-1440:mt-r12">
          <div className="">
            <SearchEmptyHistorySvg />
          </div>
          <p className="text-[14px]  text-gray_3 font-dana font-[500] mt-[10px]">
            عبارتی وجود ندارد.
          </p>
        </div>
      )}
      {searchHistory?.length !== 0 && (
        <div className="w-full flex flex-col rounded-[5px] overflow-hidden px-1">
          {searchHistory
            ?.slice()
            ?.reverse()
            ?.map((item, index) => (
              <div
                key={index + "history-item"}
                className="flex bg-secondary items-center relative text-white transition-colors ease-linear duration-100  cursor-pointer "
              >
                <CardActionArea className="!h-[30px] !w-[30px] !absolute z-20 right-3 !flex !justify-center !items-center !rounded-[5px]">
                  <div
                    className="h-full w-full flex justify-center items-center  rounded-[5px] "
                    onClick={(e) => handleRemoveItemFromSearchHistory(e, item)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        stroke="#BDBDBD"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13.5 4.5l-9 9M4.5 4.5l9 9"
                      ></path>
                    </svg>
                  </div>
                </CardActionArea>
                <RenderSearchViewType item={item} fromHistory />
              </div>
            ))}
        </div>
      )}
    </>
  );
}

export default SearchHistory;
