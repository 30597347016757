import { useEffect } from 'react';
import { getEmtyAudioStatus, getHaveAdvertise, getPlayerMusic, getSingleAdveItem } from "../helper/ReduxSelectorContext.js";
import { appendBaseUrl } from "../helper/utils.js";

const useMediaSession = (handlers) => {
    const emptyAudiStatus = getEmtyAudioStatus()

    let playedMusic = getPlayerMusic();
    let havAdvertise = getHaveAdvertise();
    const advertiseData = getSingleAdveItem()
    useEffect(() => {
        if (!playedMusic) return
        if (havAdvertise && !advertiseData) return
        const metadata = {
            title: havAdvertise ? advertiseData?.title : playedMusic?.title,
            artist: havAdvertise ? advertiseData?.description : playedMusic?.artists[0]?.name,
            artwork: [
                {
                    src: havAdvertise ? appendBaseUrl(advertiseData?.image) : appendBaseUrl(playedMusic?.image_large),
                    sizes: "32x32",
                    type: 'image/png'
                }, {
                    src: havAdvertise ? appendBaseUrl(advertiseData?.image) : appendBaseUrl(playedMusic?.image_large),
                    sizes: "64x64",
                    type: 'image/png'
                }, {
                    src: havAdvertise ? appendBaseUrl(advertiseData?.image) : appendBaseUrl(playedMusic?.image_large),
                    sizes: "96x96",
                    type: 'image/png'
                }, {
                    src: havAdvertise ? appendBaseUrl(advertiseData?.image) : appendBaseUrl(playedMusic?.image_large),
                    sizes: '128x128',
                    type: 'image/png'
                }, {
                    src: havAdvertise ? appendBaseUrl(advertiseData?.image) : appendBaseUrl(playedMusic?.image_large),
                    sizes: '192x192',
                    type: 'image/png'
                }, {
                    src: havAdvertise ? appendBaseUrl(advertiseData?.image) : appendBaseUrl(playedMusic?.image_large),
                    sizes: '256x256',
                    type: 'image/png'
                }, {
                    src: havAdvertise ? appendBaseUrl(advertiseData?.image) : appendBaseUrl(playedMusic?.image_large),
                    sizes: '384x384',
                    type: 'image/png'
                }, {
                    src: havAdvertise ? appendBaseUrl(advertiseData?.image) : appendBaseUrl(playedMusic?.image_large),
                    sizes: '512x512',
                    type: 'image/png'
                },
            ]
        };
        const handleMediaSessionPlay = () => {
            if (handlers.play) {
                handlers.play();
            }
        };

        const handleMediaSessionPause = () => {
            if (handlers.pause) {
                handlers.pause();
            }
        };

        const handleMediaSessionNextTrack = () => {
            if (handlers.nextTrack && !havAdvertise) {
                handlers.nextTrack();
            }
        };

        const handleMediaSessionPreviousTrack = () => {
            if (handlers.previousTrack && !havAdvertise) {
                handlers.previousTrack();
            }
        }; const handleSeek = (e) => {
            if (handlers.seekBar) {
                handlers.seekBar(e);
            }
        };

        // Set up media session metadata
        let mediaMetadata = null

        // Register media session
        if ('mediaSession' in navigator) {
            try {

                mediaMetadata = new window.MediaMetadata(metadata)
                if (emptyAudiStatus) {
                    console.log("emptyAudiStatus MediaSession is true", emptyAudiStatus);
                    navigator.mediaSession.setPositionState({
                        duration: 0,
                        playbackRate: 1,
                        position: 0
                    });
                    navigator.mediaSession.metadata = mediaMetadata;
                    navigator.mediaSession.setActionHandler('play', null);
                    navigator.mediaSession.setActionHandler('pause', null);
                    navigator.mediaSession.setActionHandler('nexttrack', handleMediaSessionNextTrack);
                    navigator.mediaSession.setActionHandler('previoustrack', handleMediaSessionPreviousTrack);
                    navigator.mediaSession.setActionHandler('seekto', null);
                } else {
                    console.log("emptyAudiStatus MediaSession is false", emptyAudiStatus);
                    navigator.mediaSession.metadata = mediaMetadata;
                    navigator.mediaSession.setPositionState();
                    navigator.mediaSession.setActionHandler('play', handleMediaSessionPlay);
                    navigator.mediaSession.setActionHandler('pause', handleMediaSessionPause);
                    navigator.mediaSession.setActionHandler('nexttrack', handleMediaSessionNextTrack);
                    navigator.mediaSession.setActionHandler('previoustrack', handleMediaSessionPreviousTrack);
                    // if(havAdvertis){}
                    navigator.mediaSession.setActionHandler('seekto', havAdvertise ? null : handleSeek);
                }
            } catch (error) {
                console.log("ERROR MEDIA SESSION", error);
            }
        }

        return () => {
            if ('mediaSession' in navigator) {
                navigator.mediaSession.metadata = null;
                navigator.mediaSession.setActionHandler('play', null);
                navigator.mediaSession.setActionHandler('pause', null);
                navigator.mediaSession.setActionHandler('nexttrack', null);
                navigator.mediaSession.setActionHandler('previoustrack', null);
                navigator.mediaSession.setActionHandler('seekto', null)
            }
        };
    }, [playedMusic, emptyAudiStatus, havAdvertise, advertiseData]);
};

export default useMediaSession;



// const useMediaSession = ( handlers) => {
// const emptyAudiStatus=getEmtyAudioStatus()

//     let playedMusic = getPlayerMusic();
//     let havAdvertise = getHaveAdvertise();
//     useEffect(() => {
//         if(!playedMusic)return
//         if(havAdvertise)return
//         const metadata = {
//             title: playedMusic?.title,
//             artist: playedMusic?.artists[0]?.name,
//             artwork: [
//                 {
//                     src: IMAGE_BASE_URL+ playedMusic?.image_large,
//                     sizes: "32x32",
//                     type: 'image/png'
//                 }, {
//                     src: IMAGE_BASE_URL+ playedMusic?.image_large,
//                     sizes: "64x64",
//                     type: 'image/png'
//                 }, {
//                     src: IMAGE_BASE_URL+ playedMusic?.image_large,
//                     sizes: "96x96",
//                     type: 'image/png'
//                 },{
//                     src: IMAGE_BASE_URL+playedMusic?.image_large,
//                     sizes: '128x128',
//                     type: 'image/png'
//                 },{
//                     src: IMAGE_BASE_URL+playedMusic?.image_large,
//                     sizes: '192x192',
//                     type: 'image/png'
//                 },{
//                     src: IMAGE_BASE_URL+playedMusic?.image_large,
//                     sizes: '256x256',
//                     type: 'image/png'
//                 },{
//                     src: IMAGE_BASE_URL+playedMusic?.image_large,
//                     sizes: '384x384',
//                     type: 'image/png'
//                 },{
//                     src: IMAGE_BASE_URL+playedMusic?.image_large,
//                     sizes: '512x512',
//                     type: 'image/png'
//                 },
//             ],
//         };
//         const handleMediaSessionPlay = () => {
//             if (handlers.play) {
//                 handlers.play();
//             }
//         };

//         const handleMediaSessionPause = () => {
//             if (handlers.pause) {
//                 handlers.pause();
//             }
//         };

//         const handleMediaSessionNextTrack = () => {
//             if (handlers.nextTrack) {
//                 handlers.nextTrack();
//             }
//         };

//         const handleMediaSessionPreviousTrack = () => {
//             if (handlers.previousTrack) {
//                 handlers.previousTrack();
//             }
//         }; const handleSeek = (e) => {
//             if (handlers.seekBar) {
//                 handlers.seekBar(e);
//             }
//         };

//         // Set up media session metadata
//         const mediaMetadata = new window.MediaMetadata(metadata)

//         // Register media session
//         if ('mediaSession' in navigator) {
//             if(emptyAudiStatus){
//                 navigator.mediaSession.setPositionState({
//                     duration: 0,
//                     playbackRate: 1,
//                     position: 0
//                 });
//                 navigator.mediaSession.metadata = mediaMetadata;
//                 navigator.mediaSession.setActionHandler('play', null);
//                 navigator.mediaSession.setActionHandler('pause', null);
//                 navigator.mediaSession.setActionHandler('nexttrack', handleMediaSessionNextTrack);
//                 navigator.mediaSession.setActionHandler('previoustrack', handleMediaSessionPreviousTrack);
//                 navigator.mediaSession.setActionHandler('seekto',null );
//             }else{
//                 navigator.mediaSession.metadata = mediaMetadata;
//                 navigator.mediaSession.setPositionState();
//                 navigator.mediaSession.setActionHandler('play', handleMediaSessionPlay);
//                 navigator.mediaSession.setActionHandler('pause', handleMediaSessionPause);
//                 navigator.mediaSession.setActionHandler('nexttrack', handleMediaSessionNextTrack);
//                 navigator.mediaSession.setActionHandler('previoustrack', handleMediaSessionPreviousTrack);
//                 navigator.mediaSession.setActionHandler('seekto', handleSeek);
//             }
//         }

//         return () => {
//             if ('mediaSession' in navigator) {
//                 navigator.mediaSession.metadata = null;
//                 navigator.mediaSession.setActionHandler('play', null);
//                 navigator.mediaSession.setActionHandler('pause', null);
//                 navigator.mediaSession.setActionHandler('nexttrack', null);
//                 navigator.mediaSession.setActionHandler('previoustrack', null);
//                 navigator.mediaSession.setActionHandler('seekto', null)
//             }
//         };
//     }, [ playedMusic,emptyAudiStatus,havAdvertise]);
// };

// export default useMediaSession;
