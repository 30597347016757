import React from "react";
function FollowersCountSvg(props) {
  return  (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="19"
          fill="none"
          viewBox="0 0 21 19"
          {...props}
      >
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M11.29 11.887c-.768-.385-.768-.385-1.132-.554a3.67 3.67 0 001.194-2.421 5.373 5.373 0 013.782-1.535c1.116 0 2.188.336 3.1.972.912.636 1.134 2 1.134 3.153v6.154a.77.77 0 001.538 0v-6.154c0-1.538-.384-3.461-1.847-4.336a6.851 6.851 0 00-1.438-.768 3.669 3.669 0 001.206-2.72A3.682 3.682 0 0015.149 0a3.681 3.681 0 00-3.677 3.677c0 1.073.462 2.04 1.197 2.713a6.788 6.788 0 00-1.57.853 3.683 3.683 0 00-3.413-2.308A3.682 3.682 0 004.01 8.612c0 1.07.46 2.034 1.19 2.707-1.955.733-3.541 2.307-4.187 4.314-.214.666-.1 1.372.311 1.937.412.565 1.05.89 1.749.89h6.273a.721.721 0 000-1.443H3.072a.714.714 0 01-.583-.296.715.715 0 01-.104-.646c.713-2.216 2.886-3.763 5.286-3.763 1.116 0 1.946.202 2.85.729.905.526 1.54 1.154 1.54 2.308v2.307s0 .77.768.77c.77 0 .77-.77.77-.77V15.35c0-1.924-1.539-3.077-2.308-3.462zm6.095-8.21a2.238 2.238 0 00-2.236-2.235 2.238 2.238 0 00-2.235 2.235 2.238 2.238 0 002.235 2.236 2.238 2.238 0 002.236-2.236zM9.922 8.612a2.238 2.238 0 00-2.236-2.235 2.238 2.238 0 00-2.235 2.235 2.238 2.238 0 002.235 2.236 2.238 2.238 0 002.236-2.236z"
            clipRule="evenodd"
        ></path>
      </svg>
  );
}

export default FollowersCountSvg;
