import React from "react";


function ResendOtpSVG({ loading }) {
  return (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="17"
            fill="none"
            viewBox="0 0 21 17"
        >
            <path
                stroke="#BDBDBD"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19.338 1.832v5.001h-5.001M1 15.168v-5h5.001"
            ></path>
            <path
                stroke="#BDBDBD"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M3.092 6a7.502 7.502 0 0112.379-2.801l3.867 3.634M1 10.167l3.868 3.634a7.502 7.502 0 0012.378-2.8"
            ></path>
        </svg>

    </>
  );
}

export default ResendOtpSVG;
