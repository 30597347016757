import { memo } from 'react';

function shareGiftWithoutBorderSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#404040"
      {...props}
    >
      <g clipPath="url(#clip0_6537_7194)">
        <path d="M.833 18.89a.733.733 0 01-.184-.023A.823.823 0 010 18.07v-1.668C0 10.423 4.864 5.56 10.842 5.56h.278V1.946a.835.835 0 011.434-.579l7.228 7.506a.833.833 0 010 1.157l-7.228 7.506a.835.835 0 01-1.434-.579v-3.614H9.799a9.122 9.122 0 00-8.206 5.072.859.859 0 01-.76.474zM10.842 7.227c-4.67 0-8.538 3.508-9.104 8.028a10.782 10.782 0 018.06-3.58h2.156c.46 0 .834.373.834.833v2.38l5.236-5.438-5.236-5.437v2.38c0 .46-.374.834-.834.834h-1.112z"></path>
      </g>
      <defs>
        <clipPath id="clip0_6537_7194">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(shareGiftWithoutBorderSvg);
