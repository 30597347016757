import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { store } from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import { Setting } from "./Setting";
import { dispatchUpdateDialog } from "./redux/globalstore/GlobalStore";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { updateFirebaseToken } from "./services/ApiClient";

Setting();
const persistor = persistStore(store);
const queryClient = new QueryClient();

const channel = new BroadcastChannel("sw-messages");
channel.addEventListener("message", (event) => {
  dispatchUpdateDialog();
});
// main.js or index.js
if ("serviceWorker" in navigator) {
  window?.addEventListener("load", () => {
    navigator?.serviceWorker
      //   .register("/Service-Worker.js") // Path to your custom service worker file
      .register("service-worker.js") // Path to your custom service worker file
      //   .register("/w.js") // Path to your custom service worker file
      .then((registration) => {
        console.log("Service worker registered successfully:", registration);
      })
      .catch((error) => {
        console.error("Service worker registration failed:", error);
      });
  });
}

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
  console.info = () => {};
}

const handleUpdateDialog = async () => {
  const registration = await navigator?.serviceWorker?.getRegistration();

  if (registration) {
    registration?.addEventListener("updatefound", (e) => {
      dispatchUpdateDialog();
    });
  }
};

handleUpdateDialog();


ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} children persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
