import React from 'react';
import MusicNoteLoading from "./MusicNoteLoading.jsx";

function FullPageNoteLoading({speed='2'}) {
    return (
        <div
            className='fixed bg-secondary z-[5002] opacity-80 top-0 left-0 w-full h-full flex justify-center items-center'>
            <div className='w-[120px] h-[120px]'>
                <MusicNoteLoading speed={speed}/>
            </div>
        </div>
    );
}

export default FullPageNoteLoading;
