import React,{ Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import DialogHeader from './DialogHeader.jsx';
import ClickReaction from '../../helper/ClickReaction.jsx';
import { CardActionArea } from '@mui/material';
import { globalData } from '../../helper/ReduxSelectorContext.js';
import { useDispatch } from 'react-redux';
import { hideRedirectToTelegramDialog } from '../../redux/globalData/globalDataSlice.js';
import TelegramSvg from '../svg/setting/TelegramSvg.jsx';

function RedirectToTelegramDialog(props) {
  const dispatch = useDispatch()
  const global = globalData()
  const closeModal = () => {
    dispatch(hideRedirectToTelegramDialog())
  }

  return (
    <Transition appear show={global?.redirectToTelegramOpenState} as={Fragment}>
      <Dialog as="div" className="relative z-[1200]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-black bg-opacity-50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className="w-[80vw] max-w-[400px] transform overflow-hidden rounded-[20px]  bg-[#303030] py-6 text-left align-middle shadow-xl transition-all px-4 "
              >
                <DialogHeader
                  header_text={'ارسال به تلگرام'}
                  onCloseDialog={closeModal}
                />
                <div className="flex flex-col gap-4 justify-center items-center">
                  {/* <img src={`${}`} /> */}
                  <TelegramSvg className=" h-8 w-8 " />
                  <p className="text-gray_6 font-dana text-[16px] font-[400] text-center  ">
                    برای ورود به تلگرام روی دکمه زیر بزنید.
                  </p>
                  <ClickReaction>
                    <CardActionArea className="!rounded-[23px]">
                      <div className=" flex items-center justify-center  ">
                        <a

                          target="_blank"
                          type="button"
                          href={global?.generatedLink}
                          className="inline-flex text-title_box text-[16px] font-dana font-[500] bg-primary justify-center rounded-[23px] border border-transparent  py-2  px-5  focus:outline-none"
                        >
                         ورود به تلگرام
                        </a>
                      </div>
                    </CardActionArea>
                  </ClickReaction>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default RedirectToTelegramDialog;
