import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCustomDialog } from '../../redux/dialog/DialogSlice';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useAction } from '../../hooks/useAction';
import DialogHeader from './DialogHeader.jsx';
import { CardActionArea } from '@mui/material';
import { notificationDB } from '../../indexDB/DB';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

function CustomDialog() {
  const [open, setOpen] = useState(false);
  const dialogData = useSelector(selectCustomDialog);
  const action = useAction();

  const handleCloseLogout = () => {
    if (dialogData?.dismissible === false) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (dialogData) setOpen(true);
    return () => {
      notificationDB.notif.clear();
    };
  }, [dialogData]);

  const handleButtonAction = () => {
    action(dialogData?.action);
    handleCloseLogout();
  };
  const CloseCustomDialog = () => {
    console.log(dialogData);
    if (dialogData?.dismissible === false) {
      return;
    }
    setOpen(false);
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={CloseCustomDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto font-dana">
          <div className="flex min-h-full items-center justify-center p-4 text-center pb-12">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-secondary p-4  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={dialogData?.header}
                  onCloseDialog={CloseCustomDialog}
                />
                <div className="px-2 flex flex-col items-center">
                  {dialogData?.main_title && (
                    <p className="text-primary text-[18px] text-center mb-4 mt-8">
                      {dialogData?.main_title}
                    </p>
                  )}
                  {dialogData?.image && (
                    <CustomLazyLoadImage
                      imageClass="!w-[200px] !h-[200px] rounded-3xl !relative"
                      image={dialogData?.image}
                    />
                  )}
                  {dialogData?.title && (
                    <p className="text-white text-[22px] mt-6">
                      {dialogData?.title}
                    </p>
                  )}
                  {dialogData?.description && (
                    <p className="text-white text-[18px] mt-3 text-center">
                      {dialogData?.description}
                    </p>
                  )}
                </div>
                <div className={'flex items-center justify-center mt-6   mb-4'}>
                  <CardActionArea className="mt-4 !h-12 !rounded-[22px] !w-[180px]">
                    <div className="w-[180px] flex items-center justify-center">
                      <button
                        onClick={handleButtonAction}
                        type="button"
                        className="inline-flex justify-center items-center !h-12 text-black text-base bg-primary w-full justify-center rounded-[22px] border border-transparent bg-blue-100 px-4 focus:outline-none"
                      >
                        <p className="font-semibold text-gray_1">
                          {dialogData?.btn_text}
                        </p>
                      </button>
                    </div>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default CustomDialog;
