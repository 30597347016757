import React from "react";
import { CircularProgress } from "@mui/material";
import DownloadSvg from "../svg/common/DownloadSvg.jsx";

import { downloadData } from "../../helper/ReduxSelectorContext.js";
import SuccessDownloadSvg from "../svg/download/SuccessDownloadSvg.jsx";
import CancelSvg from "../svg/common/CancelSvg.jsx";
import { useDispatch } from "react-redux";
import { removeDownloadFromQueue } from "../../redux/download/downloadSlice.js";
import { abortDownload } from "./GlobalDownload.jsx";
import DisableDownloadButton from "./DisableDownloadButton.jsx";



function RenderDownloadStatus({startDownloadTrack, isDownloaded, inQueue, inQueueItem, item,fill='#A7A7A9',width,height,downloadIconStyle='aspect-square  h-[23px]',iconParentStyle='!w-full !h-full px-4 flex items-center'}) {
    const {downloadedFiles, downloadQueue, downloadActiveId, downloadPercent} = downloadData();
    const dispatch=useDispatch()

  const startDownload = (event) => {
    getPersistPermision()
    startDownloadTrack(event);
  };

  const removeFromQueue = (e) => {
    e.stopPropagation();
    dispatch(removeDownloadFromQueue({ id: item?.id }));
  };
  const cancelDownloadHandler = (e) => {
    e.stopPropagation();
    abortDownload();
  };


  // file downloaded and exist
  if (isDownloaded) {
    return      (
      <div  className={iconParentStyle} >
        <div className={downloadIconStyle}>
          <SuccessDownloadSvg />
        </div>
      </div>

    )

  }
  const getPersistPermision=()=>{
    console.log("request for persist Data in click");
    if (navigator.storage && navigator.storage.persist) {
        navigator.storage.persist().then(granted => {
          if (granted)
          console.log("Granted Persist ",granted);

          else
          console.log("UnGranted Persist ",granted);
        });
      }
  }

    if(!item?.downloadable){

        return <DisableDownloadButton   fill={fill} iconParentStyle={iconParentStyle} downloadIconStyle={downloadIconStyle} />
    }

    // file in queue and is downloading
    if (inQueue&&item?.id===downloadActiveId ) {
        return (

            <div
                style={{width:width+'px',height:height+'px'}}
                className={iconParentStyle} onClick={(e)=>{
                cancelDownloadHandler(e)
            }}>
              <div className={`relative ${downloadIconStyle}`}>
                <CircularProgress className='!w-full !h-full ' thickness={4} sx={{color: '#FFB300'}} style={{
                    position: 'absolute',
                    zIndex: '1'
                }}
                                  variant="determinate" value={downloadPercent}
                />
                <CircularProgress className='!w-full !h-full ' thickness={4} sx={{color: '#4C4C50'}} style={{
                    position: 'absolute',
                    zIndex: '0'
                }}
                                  variant="determinate" value={100}
                >


                </CircularProgress>
                <div className="absolute top-0 left-0 right-0 bottom-0 scale-[40%]">
                    <CancelSvg/>
                </div>
              </div>
            </div>
        )
    }
    // file in queue and wait for start download
    if (inQueue) {
        return (
            <div
                style={{width:width+'px',height:height+'px'}}
                onClick={(e)=>{
                removeFromQueue(e)
            }} className={iconParentStyle}>
              <div className={`relative ${downloadIconStyle}`}>
                <CircularProgress className='!w-full !h-full ' thickness={4} sx={{color: '#FFB300'}}
                                  style={{
                                      position: 'absolute',
                                      zIndex: '0'
                                  }}
                />
                <div className="absolute top-0 left-0 right-0 bottom-0 scale-[40%]">
                    <CancelSvg/>
                </div>
              </div>
            </div>

        )
    }

    // render download icon
    return (
        <div
          className={iconParentStyle}
            onClick={(e) => {
            startDownload(e)
        }}>
          <div className={downloadIconStyle}>
            <DownloadSvg  fill={fill}/>
          </div>
        </div>
    );
}

export default RenderDownloadStatus;
