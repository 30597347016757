import React, { useEffect, useState } from 'react';
import SongDetailCard from '../../components/playlist/SongDetailCard';

import {
  addToPlaylistData,
  getRecentlyPlays,
} from '../../helper/ReduxSelectorContext';
import Container from '../../components/PageParentContainer/Container';
import CollectionCategoryHeader from '../../components/playlist/CollectionCategoryHeader';
import AbsoluteBackground from '../../components/common/AbsoluteBackground';
import { useLocation, useNavigate } from 'react-router-dom';
import NoMusicSvg from '../../components/svg/myMelodify/NoMusicSvg';
import ChevronLeft from '../../components/svg/common/ChevronLeft';
import handelBreakSentence from '../../helper/handelBreakSentence';
import { Virtuoso } from 'react-virtuoso';
import { disableListSelectMode } from '../../redux/adToPlaylist/AddToPlaylistSlice.js';
import { useDispatch } from 'react-redux';
import AddToPlaylistModeButton from '../../components/playlist/AddToPlaylistModeButton.jsx';

function RecentlyPlaysMyMelodify() {
  const getRecentlyList = getRecentlyPlays();
  const location = useLocation();
  const navigate = useNavigate();
  const [item, setItem] = useState({});
  const addToPlaylistDataState = addToPlaylistData();
  const dispatch = useDispatch();
  const handleClickSearch = () => {
    navigate('/search');
  };

  useEffect(() => {
    setItem(location?.state?.item);
    return () => {
      dispatch(disableListSelectMode());
    };
  }, []);

  return (
    <Container style="bg-primary_dark overflow-hidden">
      <AddToPlaylistModeButton
        EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}
      />
      <CollectionCategoryHeader
        title={item?.title}
        hasSearch={false}
        backgroundId="absolute-background"
        pageId="category-page"
      />
      <AbsoluteBackground image={item?.image} animation={true} />
      <div
        id="category-page"
        className=" relative overflow-scroll hide-scroll-bar z-10 "
        dir="rtl"
      >
        <div className="h-full pt-[208px] w-full mb-10  ">
          <div className="!z-50 absolute font-kalameh font-medium text-lg text-white top-[100px] w-full flex justify-center items-center mx-auto">
            <p className="text-center">{handelBreakSentence(item?.title)}</p>
          </div>

          <div className="flex gap-3 flex-col mt-6 p-4">
            {getRecentlyList?.length !== 0 && (
              <Virtuoso
                customScrollParent={document.getElementById('category-page')}
                data={getRecentlyList?.slice()?.reverse()}
                itemContent={(index, item) => (
                  <div
                    key={'RecentlyPlaysMyMelodify SongDetailCard' + index}
                    className="my-[6px]"
                  >
                    <SongDetailCard
                      isSelectMode={
                        addToPlaylistDataState?.listSelectItems?.length > 0
                      }
                      index={index}
                      number={true}
                      data={item}
                      playList={getRecentlyList?.slice()?.reverse()}
                    />
                  </div>
                )}
              />
            )}

            {getRecentlyList?.length === 0 && (
              <div className="flex flex-col items-center justify-between ">
                <div className="h-[120px]  ">
                  <div className="flex flex-col  items-center">
                    <NoMusicSvg
                      fill="gray_3"
                      className="w-[68px] h-[41px] fill-gray_3 "
                    />
                  </div>
                  <p className="text-gray_4 bg-transparent text-center mt-[9px] font-dana font-medium text-sm mx-auto w-[255px]">
                    هنوز آهنگی گوش ندادید
                    {/* <br />
            به زودی با گوش دادن آهنگ ها، این پلی‌لیست برایتان ساخته میشود */}
                  </p>
                </div>

                <div
                  onClick={handleClickSearch}
                  className="flex justify-around items-center  w-[255px] mx-auto"
                >
                  <span className="text-primary font-medium font-dana text-sm leading-6">
                    آهنگ مورد نظرتان را جست‌و‌جو کنید
                  </span>
                  <div className="w-fit">
                    <ChevronLeft
                      stroke="primary"
                      className="w-[24px] h-[24px] stroke-primary"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default RecentlyPlaysMyMelodify;
