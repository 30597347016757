import React, {useEffect, useRef, useState} from 'react';
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import {playerBottomSheetItems, playerOptionsBottomSheetKeys} from "../../consts/MenuItemsConsts.js";
import BugReportBottomSheet from "../common/BugReportBottomSheet.jsx";
import { getInitialData, getPlayerMusic, useUserData } from '../../helper/ReduxSelectorContext.js';
import GoToArtistBottomSheet from "./GoToArtistBottomSheet.jsx";
import ConfirmDialogs from "../dialogs/ConfirmDialogs.jsx";
import TrashSVG from "../svg/common/TrashSVG.jsx";
import {showBugReportDialog} from "../../redux/player/DropDownSlice.js";
import {removeFromDownloaded} from "../../redux/download/downloadSlice.js";
import {deleteDownloadedFile} from "../../indexDB/dbQuery.js";
import {useDispatch, useSelector} from "react-redux";
import {useDownload} from "../../hooks/useDownload.js";
import {playStoreUrl} from "../../consts/GlobalConsts.js";
import SwipeBottomSheet from "../bottomSheets/SwipeBottomSheet.jsx";
import StreamQuality from "../setting/StreamQuality.jsx";
import {useTelegram} from "../../hooks/useTelegram.jsx";
import {selectInitialData} from "../../redux/initialize/InitializeSlice.js";
import {CardActionArea} from "@mui/material";
import AddToPlaylistModeButton from "../playlist/AddToPlaylistModeButton.jsx";
import {addItemToPlayerSelectedListItem} from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import {resetPlayer} from "../../redux/player/PlayMusicSlice.js";
import {useNativeBack} from "../../hooks/useNativeBack.js";
import ReportBottomSheet from "../common/ReportBottomSheet";
import {reportKeys, reportTypes} from "../../consts/ReportTypes.js";
import {useShare} from "../../hooks/useShare.js";
import {shareTypes} from "../../consts/shareTypes.js";

function PlayerOptionBottomSheet({closeBottomSheet}) {
    const dispatch = useDispatch()
    const bottomSheetRef = useRef()
    const user = useUserData();
    const addToPlaylistButtonRef = useRef(null)
    const {setting} = getInitialData() || {};
    let playedTrack = getPlayerMusic();
    const {telegram_data} = useSelector(selectInitialData);
    const {generateLinkLoading,handleGetShareLink} = useShare(playedTrack?.id, shareTypes?.TRACK)
    const {connectToTelegramLoading, connectToTelegram} = useTelegram()
    const telegramRequestData = {
        track_id: playedTrack?.id,
        quality: user?.streaming_quality,
        is_demo: playedTrack?.is_demo
    }
    useEffect(() => {
        console.log(connectToTelegramLoading)
    }, [connectToTelegramLoading])
    const removeDownloadFileDialogRef = useRef(null)
    const [StreamQualityBottomSheetOpen, setStreamQualityBottomSheetOpen] = useState(false);
    const goToArtistBottomSheetRef = useRef()

    const handleToggleShowBugReportDialog = () => {
        dispatch(showBugReportDialog())
    }
    const handleRemoveDownloadFile = async () => {
        dispatch(resetPlayer())
        await dispatch(removeFromDownloaded({id: playedTrack?.id}))
        await deleteDownloadedFile(playedTrack?.id)
        dispatch(showBugReportDialog());
        closeBottomSheet()


    }
    const openBugReportBottomSheet = () => {
        bottomSheetRef?.current?.openModal()
    }
    const openGoToArtistBottomSheet = () => {
        goToArtistBottomSheetRef?.current?.openGoToArtistBottomSheet()
    }
    const handleShareTrack = () => {
        navigator
            .share({
                title: "اشتراک",
                text:
                    "دانلود آهنگ" +
                    ' " ' +
                    playedTrack?.title +
                    ' " ' +
                    "در اپلیکیشن ملودیفای \n" +
                    "\nلینک برنامه :" +
                    "\n"+
                    "\n"+
                    playStoreUrl,
            })
            .then(() => console.log("Yay, you shared it :)"))
            .catch((error) => {
                console.log(`this error happened during sharing : + ${error}`);
            });
    };
    const toggleStreamQualityBottomSheet = (newOpen) => () => {
        setStreamQualityBottomSheetOpen(newOpen);
    };
    const handleSelectItem = (item) => {
        if (item.key === playerOptionsBottomSheetKeys.SEND_REPORT) {
            openBugReportBottomSheet()
        }
        if (item.key === playerOptionsBottomSheetKeys.GO_TO_ARTIST) {
            openGoToArtistBottomSheet()
        }
        if (item.key === playerOptionsBottomSheetKeys.REMOVE_FROM_DOWNLOAD) {
            console.log("delete from")
            removeDownloadFileDialogRef.current.openDialog();
            dispatch(showBugReportDialog());
        }
        if (item.key === playerOptionsBottomSheetKeys.SEND_TO_TELEGRAM) {
            connectToTelegram(telegramRequestData, playedTrack?.title)

        }
        if (item.key === playerOptionsBottomSheetKeys.STREAM_QUALITY) {
            setStreamQualityBottomSheetOpen(true)
        }
        if (item.key === playerOptionsBottomSheetKeys.SHARE_TRACK) {
            if(setting?.enable_track_share){
                handleGetShareLink()
            }else{
                handleShareTrack()
            }

        }
        if (item.key === playerOptionsBottomSheetKeys.ADD_TO_PLAYLIST) {
            dispatch(addItemToPlayerSelectedListItem({selectedItem: playedTrack}))
            addToPlaylistButtonRef.current.openAddToPlaylistSheet()
        }
    }
    const renderItemInfo = (key) => {
        if (key === playerOptionsBottomSheetKeys.SEND_TO_TELEGRAM && telegram_data?.connected_title) {
            return <span dir="ltr">{telegram_data?.connected_title}</span>;
        }
        if (key === playerOptionsBottomSheetKeys.STREAM_QUALITY) {
            return <span dir="ltr">{user?.streaming_quality} kbps</span>;
        }
    }
    const [
        ,
        isDownloaded,
        ,
        ,
        ,
    ] = useDownload(playedTrack)
    const closeStreamBottomSheet = ( )=>{
        setStreamQualityBottomSheetOpen(false)
    }
    useNativeBack(StreamQualityBottomSheetOpen, 'create-playlist', closeStreamBottomSheet);
    return (
        <>
            <AddToPlaylistModeButton ref={addToPlaylistButtonRef} inPlayer={true} EnableEditMode={false}
                                     inPlayerSheet={true}/>
            <ReportBottomSheet ref={bottomSheetRef} id={playedTrack?.id} reportKey={reportKeys?.trackReport}/>
            <GoToArtistBottomSheet closeParentBottomSheet={closeBottomSheet} ref={goToArtistBottomSheetRef}
                                   data={playedTrack}/>
            <SwipeBottomSheet
                open={StreamQualityBottomSheetOpen}
                toggleDrawer={toggleStreamQualityBottomSheet}
            >
                <StreamQuality closeParent={closeBottomSheet} openBottomSheetSetter={setStreamQualityBottomSheetOpen}/>
            </SwipeBottomSheet>
            <ConfirmDialogs
                dialogIcon={
                    <TrashSVG fill={"#EB5757"}/>
                }
                title={"حذف فایل"}
                text={"مطمئن هستید که میخواهیداین فایل را از لیست دانلود ها حذف کنید؟"}
                buttonDirection={"dir-ltr"}
                confirmText={"بلی"}
                confirmTextColor={"text-secondary"}
                confirmBGColor={"bg-primary"}
                confirmBorderColor={"transparent"}
                confirmAction={handleRemoveDownloadFile}
                cancelText={"خیر"}
                cancelAction={handleToggleShowBugReportDialog}
                cancelTextColor={"text-primary"}
                cancelBGColor={"bg-transparent"}
                cancelBorderColor={"border-primary"}
                ref={removeDownloadFileDialogRef}
            />
            <BottomSheetHeader hasCloseButton={true} dividerLine={true}
                               closeBottomSheet={closeBottomSheet} title=" گزینه ها"/>
            <div dir='rtl' className='flex flex-col gap-4 w-full  '>
                <div className='flex flex-col w-full  '>
                    {playerBottomSheetItems?.map((item, index) =>
                        <>
                            {!(!isDownloaded && item?.key === playerOptionsBottomSheetKeys.REMOVE_FROM_DOWNLOAD) &&
                                <CardActionArea>
                                    <div onClick={() => handleSelectItem(item)}
                                         className={`flex w-full justify-between cursor-pointer ${item?.paddingClass}`}>
                                        <div

                                            className={`flex gap-6 items-center    `}>
                                            {
                                                item?.icon &&
                                                <div className='w-[24px] h-[24px] flex justify-center items-center '>
                                                    {connectToTelegramLoading && item?.key === playerOptionsBottomSheetKeys.SEND_TO_TELEGRAM ?
                                                        <item.secondIcon fill={item?.iconColor}/>
                                                        :
                                                        <item.icon fill={item?.iconColor}/>
                                                    }
                                                </div>
                                            }
                                            <p
                                                style={{color: item?.textColor}}
                                                className={` font-dana text-[16px] font-medium`}>
                                                {item?.title}
                                            </p>
                                        </div>

                                        {item?.defaultConfig &&
                                            <div
                                                style={{color: item?.defaultConfigColor}}
                                                className={'font-dana text-[16px]'}
                                            >
                                                {renderItemInfo(item?.key)}
                                            </div>
                                        }
                                    </div>
                                </CardActionArea>

                            }
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default PlayerOptionBottomSheet;
