import { memo } from 'react';
import CssShimmer from '../common/CssShimmer';

const array = new Array(5).fill(0);

function UserGiftListShimmers() {
  return (
    <div className="gap-6 flex flex-col">
      {array.map((value, index) => (
        <div key={'UserGiftListShimmers-' + index}>
          <CssShimmer classNames={'w-[30%] h-6 rounded-xl mb-4'} />
          <CssShimmer classNames={'w-full h-7 rounded-xl'} />
          <CssShimmer classNames={'w-[80%] h-5 rounded-xl mt-1'} />
          <CssShimmer classNames={'w-[95%] h-5 rounded-xl mt-2'} />
        </div>
      ))}
    </div>
  );
}

export default memo(UserGiftListShimmers);
