import { createSlice } from '@reduxjs/toolkit';
import sha256 from 'crypto-js/sha256';
const initialState = {
  positions: {}
};

export const ScrollPositionSlice = createSlice({
  name: 'scrollPosition',
  initialState,
  reducers: {
    setScrollPosition: (state, action) => {
      const { pathname, scrollY } = action.payload;
      state.positions[pathname] = scrollY;
    },

    clearScrollPosition: (state, action) => {
      const { pathname } = action.payload;
      const hashedPathname = sha256(pathname).toString();
      delete state.positions[hashedPathname];
    }
  }
});

export const selectScrollPosition = (pathname) => (state) => state.scrollPosition.positions[pathname];

export default ScrollPositionSlice.reducer;
export const { setScrollPosition, clearScrollPosition } = ScrollPositionSlice.actions;
