import React, { useEffect, useRef, useState } from 'react';
import {
  getInitialData,
  getPlayerMusic,
} from '../../helper/ReduxSelectorContext';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

import { useDownload } from '../../hooks/useDownload.js';
import { useAction } from '../../hooks/useAction';
import TextMarquee from '../common/TextMarquee.jsx';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import { useDispatch } from 'react-redux';
import { setSwipePlayerStatus } from '../../redux/player/PlayMusicSlice';
import { CardActionArea } from '@mui/material';
import ClickReaction from '../../helper/ClickReaction';
import { useLocation, useNavigate } from 'react-router-dom';
import GoToArtistBottomSheet from './GoToArtistBottomSheet.jsx';
import { textFontBasedLanguage } from '../../helper/TextFontBasedLanguage.js';

function MusicDetail() {
  const [isLiked, setIsLiked] = useState(false);
  const goToArtistBottomSheetRef = useRef(null);
  const initialData = getInitialData();
  const action = useAction();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let playedTrackData = getPlayerMusic();
  const handleDownloadTrack = event => {
    event.stopPropagation();
    startDownloadTrack();
  };
  const [
    startDownloadTrack,
    isDownloaded,
    inQueue,
    inQueueItem,
    downloadedItem,
  ] = useDownload(playedTrackData);

  function handleLikeMusic() {
    setIsLiked(!isLiked);
  }
  const openGoToArtistBottomSheet = () => {
    goToArtistBottomSheetRef?.current?.openGoToArtistBottomSheet();
  };
  // const handleGoToArtist = (artist) => {
  //   const artistObject = {
  //     target_type: "artist",
  //     artist: artist,
  //   };
  //   action(artistObject);
  // };
  const openPlans = item => {
    dispatch(setSwipePlayerStatus({ status: false }));
    if (location?.pathname !== '/plans')
      navigate('/plans', { state: { openPlayerWhenBack: true } });
  };

  return (
    <>
      <GoToArtistBottomSheet
        ref={goToArtistBottomSheetRef}
        data={playedTrackData}
      />
      <div className="  short-screen: flex flex-col items-center select-none w-full ">
        <div
          key={playedTrackData?.image_large}
          className=" relative shadow-playlist-shadow  overflow-hidden rounded-[20px] lg-1920:rounded-br10 min-h-[33.5vh] w-[34.25vh] max-w-full  aspect-[274/268]"
        >
          <CustomLazyLoadImage
            image={playedTrackData?.image_large}
            loadStrategy={'lazy'}
          />

          {playedTrackData?.is_demo && (
            <div
              className="w-full  absolute  flex flex-col justify-start items-center z-30 pt-2"
              style={{ background: '#00000080' }}
            >
              <span className="text-white text-base font-kalameh font-[500]">
                {initialData?.demo_track_box?.title}
              </span>
              <div className="flex flex-col mt-2 gap-[2px]">
                {initialData?.demo_track_box?.subtitle
                  ?.split('\n')
                  ?.map(item => (
                    <span className="text-white text-xs font-dana font-[400] text-center">
                      {item}
                    </span>
                  ))}
              </div>

              <ClickReaction>
                <CardActionArea className="!w-[28vh] !h-[36px] !my-4  !rounded-[23px]">
                  <button
                    onClick={() => {
                      openPlans(playedTrackData);
                    }}
                    className="bg-primary w-full h-full rounded-[23px] text-black font-dana font-[500] text-[16px]  cursor-pointer "
                  >
                    ویژه شوید
                  </button>
                </CardActionArea>
              </ClickReaction>
            </div>
          )}
        </div>

        <div className="flex w-full flex-col gap-1 lg-1440:gap-r3 mt-[7.5vh]">
          <div
            dir="rtl"
            className={`font-dana font-[500]   w-full  text-[18px]  text-white leading-6 overflow-hidden flex flex-row gap-2 items-center ${textFontBasedLanguage(
              playedTrackData?.title,
            )} `}
          >
            <div className="w-[8px] h-[8px] min-h-[8px] min-w-[8px]  bg-white rounded-full"></div>
            <span
              dir={'ltr'}
              className="max-w-full w-[calc(100%-16px)] h-[27px] "
            >
              <TextMarquee
                noMarqueeParentStyle={'justify-end'}
                direction={setLanguageDirection(playedTrackData?.title)}
                key={playedTrackData?.id}
              >
                {playedTrackData?.title}
              </TextMarquee>
            </span>
          </div>
          <div
            onClick={openGoToArtistBottomSheet}
            dir="rtl"
            className=" w-full flex flex-row justify-between items-center gap-2 "
          >
            <div className="w-[8px] h-[8px] min-h-[8px] min-w-[8px] bg-primary rounded-full"></div>
            <span
              dir={'ltr'}
              className="font-dana font-[500]   w-[calc(100%-16px)]  text-[12px]  h-[36px]   text-primary"
            >
              <TextMarquee
                noMarqueeParentStyle={'justify-end'}
                direction={setLanguageDirection(playedTrackData?.title)}
                key={`${playedTrackData?.id} artist `}
              >
                {playedTrackData?.artists?.map((artist, index) => {
                  return (
                    <span
                      className={`${textFontBasedLanguage(artist?.name)}`}
                      dir={setLanguageDirection(playedTrackData?.title)}
                      key={index}
                    >
                      {artist?.name}{' '}
                      {index !== playedTrackData?.artists?.length - 1 &&
                        (setLanguageDirection(playedTrackData?.title) === 'rtl'
                          ? '، '
                          : ', ')}
                    </span>
                  );
                })}
              </TextMarquee>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(MusicDetail);
