import { memo } from "react";

const MessengerSvg = (props) => (
  <svg
    width={46}
    height={46}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.893 35.3c-4.307-3.25-7.059-8.199-7.059-13.742 0-9.79 8.58-17.725 19.167-17.725 10.585 0 19.166 7.935 19.166 17.725 0 9.789-8.58 17.726-19.166 17.726a20.643 20.643 0 0 1-5.674-.79l-6.434 3.624v-6.817Zm10.063-18.597L10.617 27.552l9.407-5.118 4.916 5.118 10.277-10.849-9.301 5.026-4.962-5.026h.002Z"
      fill="url(#a)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={38.001}
        y1={6.5}
        x2={11.001}
        y2={44}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFB82B" />
        <stop offset={1} stopColor="#D8875A" />
      </linearGradient>
    </defs>
  </svg>
);
export default memo(MessengerSvg);
