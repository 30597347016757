import React from 'react'

function TrackSearchIcon() {
  return (
<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.819 12.7988L12.819 12.7977L12.819 12.6082C12.7004 11.8457 12.0072 11.3068 11.2375 11.3813L12.819 12.7988ZM12.819 12.7988C12.8192 12.8369 12.8242 12.8748 12.8339 12.9115C12.7918 13.6825 12.1542 14.2899 11.3781 14.2908C10.6003 14.2909 9.95945 13.6802 9.92186 12.9033M12.819 12.7988L9.92186 12.9033M9.92186 12.9033C9.88427 12.1263 10.4633 11.4565 11.2375 11.3813L9.92186 12.9033ZM3.15586 12.7741H3.15601C4.45633 12.7727 5.51001 11.719 5.51142 10.4187V10.4184V6.59201L12.819 5.1117V8.15198V9.7944V10.9701C12.0496 10.373 10.9697 10.3009 10.1162 10.8365C9.12229 11.4601 8.73681 12.7188 9.21108 13.7921C9.6852 14.8654 10.8754 15.428 12.0059 15.113C13.132 14.7992 13.8586 13.7091 13.7165 12.5501V2.07803C13.7165 1.69473 13.5445 1.33152 13.2477 1.08881C12.9509 0.845934 12.5607 0.749383 12.1851 0.825401L12.185 0.825409L5.63816 2.15162L5.63766 2.15173C5.04301 2.27376 4.61567 2.79629 4.61413 3.40341V3.40392V8.56962C3.91048 8.01432 2.93429 7.89833 2.10612 8.31047L2.1061 8.31048C1.12739 8.79762 0.612419 9.89403 0.862803 10.9583C1.11303 12.0225 2.06261 12.7744 3.15586 12.7741ZM12.819 4.19609L5.51158 5.6764L5.51158 3.40455C5.51158 3.40449 5.51158 3.40443 5.51158 3.40437C5.51209 3.22341 5.63936 3.06772 5.81669 3.03117C5.81676 3.03115 5.81684 3.03114 5.81691 3.03112L12.3632 1.70518L12.3633 1.70516C12.4751 1.68246 12.5912 1.71121 12.6794 1.78344L12.6795 1.78351C12.7678 1.85575 12.819 1.96372 12.819 2.07803V4.19609ZM3.15617 8.96027C3.96135 8.96038 4.61413 9.6131 4.61413 10.4184C4.61413 11.2237 3.96122 11.8766 3.15596 11.8766C2.35058 11.8766 1.69784 11.2238 1.69778 10.4185C1.6987 9.6135 2.35125 8.96113 3.15617 8.96027Z" fill="#F2C94C" stroke="#F2C94C" stroke-width="0.4"/>
</svg>

  )
}

export default TrackSearchIcon