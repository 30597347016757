import React from "react";
import FullScreenSvg from "../lyrics/svg/FullScreenSvg";
import { useDispatch } from "react-redux";
import { toggleLyricFullScreen } from "../../redux/player/PlayMusicSlice";
import ExitFullScreenSvg from "../lyrics/svg/ExitFullScreenSvg";

function OpenLyricFullScreen({ style = "absolute", close }) {
  const dispatch = useDispatch();

  const handleOpenFullScreen = (e) => {
    e.stopPropagation();
    dispatch(toggleLyricFullScreen());
  };
  return (
    <div>
      <div
        onClick={(e) => handleOpenFullScreen(e)}
        className={`${style} flex flex-row gap-1 bottom-3 rounded-[20px] font-kalameh font-[500] text-xs p-1 right-3 z-30`}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
      >
        {close ? <ExitFullScreenSvg /> : <FullScreenSvg />}
      </div>
    </div>
  );
}

export default React.memo(OpenLyricFullScreen);
