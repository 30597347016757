import React from "react";
import { useBottomNavigation } from "../BottomNavigation/useBottomNavigation";

function FloatButtonContainer({ children, className = "" }) {
  const [InvisibleBottomSheet] = useBottomNavigation();

  return (
    <div
      className={`z-50 !absolute !right-4 ${
        InvisibleBottomSheet()
          ? " safe-area invisible-sheet-bottom-container-float-button"
          : " bottom-container-float-button"
      } ${className}`}
    >
      {children}
    </div>
  );
}

export default FloatButtonContainer;
