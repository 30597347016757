import React, {useEffect, useState} from 'react';
import {downloadData, useUserData} from "../helper/ReduxSelectorContext";
import {useDispatch} from "react-redux";
import {addToDownloadQueue, clearTemporaryDownloadQueue, openDownloadDialog} from "../redux/download/downloadSlice.js";
import {mainToast, warningToast} from "../helper/ToastsEmitter.jsx";

export const useDownload = (trackItem=null, newUrl = '') => {

    const dispatch = useDispatch();
    let userData = useUserData();
    const {downloadedFiles, downloadQueue} = downloadData();
    const [downloadState, setDownloadState] = useState({
        isDownloaded: false,
        inQueue: false,
        inQueueItem: null,
        downloadedItem: null
    });
    const openDownloadDialogHandler = (track) => {
        dispatch(openDownloadDialog({track}))
    }

    // add to download lis
    const startDownloadTrack = (trackData) => {

        dispatch(clearTemporaryDownloadQueue())
        const track=trackData?trackData:trackItem
        if(track?.is_demo){
            mainToast('!برای دانلود این آهنگ باید کاربر ویژه شوید')
            return;
        }
        const quality = getQuality(track, userData)
        if (!quality || quality?.length < 1) {
            console.log('we are here...',track)
            openDownloadDialogHandler(track)
            return
        }
        const queueObject = {
            id: track?.id,
            track,
            quality,
            isDownloading: false,
            downloadError: false,
            isCancelled: false,
            downloadPercent: 0,
            url: trackUrl(track, userData, quality)
        }
        dispatch(addToDownloadQueue({newItem: queueObject}))
    };

    const updateStates = () => {
        const inQueue = doesExist(downloadQueue, trackItem?.id)
        const isDownloaded = doesExist(downloadedFiles, trackItem?.id)
        setDownloadState({
            isDownloaded,
            inQueue,
            inQueueItem: inQueue ? findItem(downloadQueue, trackItem?.id) : null,
            downloadedItem: isDownloaded ? findItem(downloadedFiles, trackItem?.id) : null
        })
    }


    useEffect(() => {
        updateStates();
    }, [downloadQueue, downloadedFiles, trackItem]);
    return [startDownloadTrack, ...Object.values(downloadState)];
};


const trackUrl = (trackItem, userData, quality) => {
    return `${userData?.download_quality ? quality[0]?.name : trackItem?.mp3s[2]?.name}` + '?type=download&timestamp=' + Date.now()
};
const doesExist = (array, id) => {
    return array.some((trackItem) => trackItem?.id === id);
};
const findItem = (array, id) => {
    return array.find((trackItem) => trackItem?.id === id);
};
const getQuality = (trackItem, userData) => {
    const quality = trackItem?.mp3s.filter(item => item?.quality == userData?.download_quality)
    return quality
}





