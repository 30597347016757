import React, {Fragment, useRef} from 'react';
import {Menu} from '@headlessui/react'
import ThreeDotSvg from "../svg/common/ThreeDotSvg.jsx";
import ErrorReportFlag from "../svg/artists/ErrorReportFlag.jsx";
import BugReportBottomSheet from "../common/BugReportBottomSheet";
import {IconButton} from "@mui/material";
import {reportKeys} from "../../consts/ReportTypes.js";
import ReportBottomSheet from "../common/ReportBottomSheet.jsx";

function ErrorReportDropDown({data}) {
    const bottomSheetRef = useRef()
    const openBugReportDialog = () => {
        bottomSheetRef.current.openModal()
    }
    return (
        <>
            <ReportBottomSheet ref={bottomSheetRef} id={data?.id} reportKey={reportKeys?.artistReport}/>
            <Menu>
                <Menu.Button>
                    <IconButton
                     className='!-mr-4'
                        disableRipple={false}
                    >
                        <div className='py-2 px-4  rounded-full'>
                            <ThreeDotSvg fill={'#F2F2F2'}/>
                        </div>
                    </IconButton>
                </Menu.Button>
                <Menu.Items className='right-[30px] top-[55px] absolute '>
                    <Menu.Item as={Fragment}>
                        <div onClick={openBugReportDialog}
                             className='flex bg-gray_1 !rounded-tr-[0px]  rounded-[15px] w-[224px] gap-4 items-center px-3 shadow-sort-button  h-[56px]  '>
                            <ErrorReportFlag/>
                            <p className=' text-white font-dana font-normal text-[16px] '>ارسال گزارش خطا</p>
                        </div>
                    </Menu.Item>
                </Menu.Items>
            </Menu>
        </>

);
}

export default ErrorReportDropDown;
