import React, { memo, useEffect, useState } from 'react';
import SongsDetailCardShimmer from "../Tickets/SongsDetailCardShimmer.jsx";
import {addToPlaylistData} from "../../helper/ReduxSelectorContext.js";


import TrackLimit from "../playlist/TrackLimit.jsx";
import {Virtuoso} from 'react-virtuoso'
import EmptySongListSvg from "../svg/playList/EmptySongListSvg.jsx";
import UserPublishedSongCard from "./UserPublishedSongCard.jsx";

function UserPublishedSongList({
                                   list,
                                   loading,
                                   scrollId,
                                   apiUrl = null,
                                   requestPayload = null,
                                   endOfPagination = true,
                               }) {
    const addToPlaylistDataState = addToPlaylistData()
    const [loaded,setLoaded] = useState(false)
    useEffect(() => {
      setLoaded(true)
    }, []);

    return (
        <>
            <div className="flex flex-col w-full  relative py-2">
                {loading && !list && (
                    <div className={"flex flex-col gap-3"}>
                        {Array.from(
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                                <SongsDetailCardShimmer/>
                            ))
                        )}
                    </div>
                )}
                {list && list?.length > 0 &&
                    <Virtuoso
                        customScrollParent={document.getElementById(scrollId)}
                        data={list}
                        itemContent={(index, item) => (
                            <div
                                style={{opacity: 1}}
                            >
                                <div>
                                    {/*{ item?.title }*/}
                                    {item?.track_limit && item.track_limit.message &&
                                        <TrackLimit message={item.track_limit.message}/>}
                                    <div className={`${item?.track_limit && 'px-2 bg-black_2 '} pb-2`}>
                                        <UserPublishedSongCard
                                            key={index} id={item}
                                            number={false}
                                            index={index}
                                            data={item}
                                            apiUrl={apiUrl}
                                            requestPayload={requestPayload}
                                            endOfPagination={endOfPagination}
                                            trackLimit={item?.track_limit}
                                            isSelectMode={addToPlaylistDataState?.listSelectItems?.length > 0}
                                            inPlayer={false}
                                            playList={list}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                }
                {!loading && list?.length === 0 && (
                    <div className="flex flex-col items-center mt-5   w-full h-[500px] ">
                        <EmptySongListSvg/>
                        <span
                            className={"text-gray_3 font-medium font-dana text-[18px] leading-6 "}
                        >
                              {'آهنگی در این پلی‌لیست وجود ندارد.'}
                         </span>
                    </div>
                )}
            </div>
        </>
    );
}

export default memo(UserPublishedSongList);
