export const PlayListType = {
  QUEUE: "queue",
  PLAYLIST: "playlist",
};


export const LOOP_STATUS = {
  OFF: "off",
  SINGLE: "single",
  MULTI: "multi",
};