import { useUserData } from './ReduxSelectorContext';
import { isIOS, browserVersion } from 'react-device-detect';
import * as Sentry from '@sentry/react';

export const CheckVideoPermision = () => {
  let userData = useUserData();

  const IsCanUseVideoForplay = () => {
    const isCanPlayVideo =
      isIOS && browserVersion >= '17' && userData?.is_premium;

    console.log('has Persimion To Play video Context :  ', isCanPlayVideo);
    Sentry.captureMessage(
      `user has permision to Play Video SafariBrowser: ${browserVersion} permision=${isCanPlayVideo} `,
      {
        level: 'log',
      },
    );
    return isCanPlayVideo;
  };

  return IsCanUseVideoForplay;
};
