import React from 'react';

function AddToPlaylistButtonSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className='w-full h-full'
            fill="none"
            viewBox="0 0 70 70"
        >
            <path
                fill="url(#paint0_linear_11_949)"
                d="M0 0h65a5 5 0 015 5v60a5 5 0 01-5 5H0V0z"
            ></path>
            <path
                fill="#fff"
                d="M48.365 19.833H24.607a2.96 2.96 0 00-2.556 1.485h24.83a2.973 2.973 0 012.969 2.97v24.829a2.96 2.96 0 001.485-2.557V22.803a2.973 2.973 0 00-2.97-2.97z"
            ></path>
            <path
                fill="#fff"
                d="M51.982 16.333H28.225a2.96 2.96 0 00-2.557 1.485h24.83a2.973 2.973 0 012.97 2.97v24.829a2.96 2.96 0 001.484-2.557V19.303a2.973 2.973 0 00-2.97-2.97z"
            ></path>
            <path
                fill="#fff"
                d="M45.395 22.803H21.638a2.973 2.973 0 00-2.97 2.97V49.53a2.973 2.973 0 002.97 2.97h23.757a2.973 2.973 0 002.97-2.97V25.772a2.973 2.973 0 00-2.97-2.97zM33.516 49.53c-6.55 0-11.878-5.329-11.878-11.879s5.329-11.879 11.878-11.879c6.55 0 11.88 5.33 11.88 11.88 0 6.55-5.33 11.878-11.88 11.878z"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M33.253 33.152v9.528M28.488 37.917h9.528"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_11_949"
                    x1="35"
                    x2="35"
                    y1="0"
                    y2="70"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFB82B"></stop>
                    <stop offset="1" stopColor="#D8875A"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default AddToPlaylistButtonSvg;
