import React from "react";

function safariShareIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                stroke="#F2F2F2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 9v6a1.5 1.5 0 001.5 1.5h9A1.5 1.5 0 0015 15V9M12 4.5l-3-3-3 3M9 1.5v9.75"
            ></path>
        </svg>
    );
}

export default safariShareIcon;
