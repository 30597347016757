import React from 'react'
import Lottie from "react-lottie";
import animationData from "../../assets/lottie-file/melodifyLoader.json";


function MusicNoteLoading({speed='1'}) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
    };
    return (
        <div>
            <Lottie
                speed={speed}
                isClickToPauseDisabled={true}
                options={defaultOptions}
            />
        </div>
    )
}

export default MusicNoteLoading
