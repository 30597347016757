import React, { useEffect, useRef, useState } from 'react';
import AdvertiseSeekBar from './AdvertiseSeekBar';
import { useDispatch } from 'react-redux';
import {
  setAdvertiseData,
  setEmitGetAdvertise,
  setSingleAdveItem,
  toggleAdvertise,
} from '../../../redux/player/AdvertiseSlice';
import {
  setIsPlaying,
  setSeekTime,
  setTrackDuration,
} from '../../../redux/player/PlayMusicSlice';
import {
  getAdvertiseData,
  getIsPlaying,
  getPlayerMusic,
  getWaitForAdv,
} from '../../../helper/ReduxSelectorContext';
import {
  getDownloadedAdvAsync,
  removeDownloadedAdv,
} from '../../../indexDB/dbQuery';
import AdveBoxComponent from '../../common/AdveBoxComponent';
import { getAdvertise } from '../../../services/ApiClient';
import CustomLazyLoadImage from '../../common/CustomLazyLoadImage';
import MelodifyWebP from '../../../assets/logo/splash-logo.webp';
import CustomImage from '../../common/CustomImage';
import { GetPlayerRef } from '../../../helper/GetPlayerRef';

function AdsPlayer() {
  const [changePlayPauseAnim, setIsButtonChange] = useState();
  const [adverse, setAdverse] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  let advertiseData = getAdvertiseData();
  const waitForAdv = getWaitForAdv();
  let playedTrackData = getPlayerMusic();
  const isPlaying = getIsPlaying();

  // const mainPlayer = document.querySelector('#playerDDD video');
const [getPlayer] = GetPlayerRef();
const mainPlayer = getPlayer();

  const handleEndAdve = () => {
    dispatch(setTrackDuration({ duration: 0.1 }));

    // remove played advertise
    removeDownloadedAdv();

    dispatch(setSeekTime({ seekTime: 0 }));
    dispatch(setAdvertiseData({ advertiseData: null }));
    dispatch(toggleAdvertise({ advertise: false }));
    // load track after advewrtise end
    mainPlayer.src = playedTrackData?.cdn_url;
    mainPlayer.play();
    dispatch(setIsPlaying({ isPlay: true }));
    dispatch(setEmitGetAdvertise());
  };

  const handlePlayPause = () => {
    if (isPlaying) mainPlayer?.pause();
    else mainPlayer?.play();
    dispatch(setIsPlaying({ isPlay: !isPlaying }));
  };
  useEffect(() => {
    mainPlayer.src = '';
    console.log('trigger Ads Player ', mainPlayer);
    // wait for adv when whe don't have adv and server error
    if (waitForAdv) {
    } else {
      handlePlayAdvertise();
    }
  }, [waitForAdv]);

  const handlePlayAdvertise = async () => {
    try {
      const DownloadedAdvData = await getDownloadedAdvAsync();
      if (DownloadedAdvData && DownloadedAdvData?.length >= 1) {
        setAdverse(DownloadedAdvData);
        mainPlayer.src = URL.createObjectURL(
          DownloadedAdvData[activeIndex]?.blob,
        );
        mainPlayer.play();
      } else {
        handleEndAdve();
      }
    } catch (error) {
      handleEndAdve();
    }
  };

  useEffect(() => {
    const handleAdver = () => {
      if (activeIndex === adverse?.length - 1) {
        handleEndAdve();
      } else {
        setActiveIndex(activeIndex + 1);
        mainPlayer.src = URL.createObjectURL(adverse[activeIndex]?.blob);

        mainPlayer.play();
      }
    };
    // Add the event listener for the "ended" event
    mainPlayer.addEventListener('ended', handleAdver);

    // Clean up the event listener when the component unmounts
    return () => {
      mainPlayer.removeEventListener('ended', handleAdver);
    };
  }, [activeIndex, adverse, playedTrackData]);

  useEffect(() => {
    if (
      advertiseData &&
      advertiseData?.[activeIndex] &&
      advertiseData?.[activeIndex]?.close_player
    ) {
      handleEndAdve();
    }
  }, [activeIndex]);

  useEffect(() => {
    if (advertiseData && advertiseData?.[activeIndex])
      dispatch(
        setSingleAdveItem({ singleAdveItem: advertiseData?.[activeIndex] }),
      );
  }, [activeIndex]);

  // const handlers = {
  //   play: () => {
  //     mainPlayer?.play();
  //   },
  //   pause: () => {
  //     mainPlayer?.pause();
  //     // Logic to handle the pause event
  //   },
  //   nextTrack: () => {},
  //   previousTrack: () => {},
  //   seekBar: (e) => {},
  // };

  return (
    <div className=" w-full h-full flex flex-col">
      <div className="w-full h-full flex flex-col justify-between items-center">
        <div class=" relative shadow-playlist-shadow  overflow-hidden rounded-[20px] lg-1920:rounded-br10 w-[32.25vh] max-w-full  aspect-[214/465] xl:min-h-[28.5vh]">
          {advertiseData && (
            <CustomLazyLoadImage
              key={advertiseData[activeIndex]?.image}
              image={advertiseData?.[activeIndex]?.image}
            />
          )}
        </div>
        <div className="w-full flex flex-col justify-center  items-center -m-7 z-10 ">
          <CustomImage
            className="w-1/5  min-w-[60px]"
            src={MelodifyWebP}
            appendBaseUrl={false}
          />
          {advertiseData && (
            <AdveBoxComponent advData={advertiseData?.[activeIndex]} />
          )}
        </div>

        <div className="flex flex-col items-center h-full justify-center w-full">
          {/* advertise SeekBar */}

          <AdvertiseSeekBar />

          <div
            onMouseDown={() => setIsButtonChange(true)}
            onMouseLeave={() => setIsButtonChange(false)}
            onTouchStart={() => setIsButtonChange(true)}
            onTouchEnd={() => setIsButtonChange(false)}
            onMouseUp={() => setIsButtonChange(false)}
            className={`h-[70px] w-fit  select-none   ease-linear transition-all duration-75   ${
              changePlayPauseAnim ? 'scale-95' : 'scale-100'
            }`}
          >
            {!isPlaying ? (
              <svg
                onClick={() => handlePlayPause()}
                class=" h-full"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_4009_14011)">
                  <path
                    d="M20 0C8.95445 0 0 8.9543 0 20C0 31.0457 8.95445 40 20 40C31.0455 40 40 31.0457 40 20C40 8.9543 31.0455 0 20 0ZM26.9125 21.0602L16.9125 27.3102C16.7102 27.4365 16.4801 27.5 16.25 27.5C16.0416 27.5 15.8328 27.4481 15.6439 27.3431C15.2466 27.1228 15 26.7047 15 26.25V13.75C15 13.2953 15.2466 12.8772 15.6439 12.6569C16.0413 12.4353 16.5271 12.4487 16.9125 12.6898L26.9125 18.9398C27.2778 19.1687 27.5 19.5691 27.5 20C27.5 20.4309 27.2778 20.8313 26.9125 21.0602Z"
                    fill="#F2C94C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4009_14011">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                onClick={() => handlePlayPause()}
                class=" h-full"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1031_3710)">
                  <path
                    d="M20 0C8.95 0 0 8.95 0 20C0 31.05 8.95 40 20 40C31.05 40 40 31.05 40 20C40 8.95 31.05 0 20 0ZM17.8 25C17.8 26.25 16.8 27.2 15.6 27.2C14.35 27.2 13.4 26.2 13.4 25V15C13.35 13.8 14.35 12.8 15.55 12.8C16.8 12.8 17.8 13.8 17.8 15V25ZM26.65 25C26.65 26.25 25.65 27.2 24.45 27.2C23.2 27.2 22.25 26.2 22.25 25V15C22.2 13.8 23.2 12.8 24.4 12.8C25.65 12.8 26.65 13.8 26.65 15V25Z"
                    fill="#F2C94C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1031_3710">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdsPlayer;
