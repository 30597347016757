import React from "react";
import { getInitialData, getPlayerMusic } from '../../helper/ReduxSelectorContext';
import MusicDetail from "./MusicDetail";

import ReactPlayerComponent from "./ReactPlayerComponent";
import { useContextPosition } from "../../helper/useContextPosition.js";
import { useAction } from "../../hooks/useAction.js";
import RenderShare from "../common/RenderShare.jsx";
import {shareTypes} from "../../consts/shareTypes.js";
import ShareSvgType2 from "../svg/common/ShareSvgType2.jsx";
import ShareAnimation from "../common/ShareAnimation.jsx";

function MinPlayer({ adv }) {
  let playedMusic = getPlayerMusic();
  const {setting} = getInitialData() || {};
  return (
    <div
      class={`w-full  bg-transparent h-full  ${
        adv ? "hidden" : "flex"
      }  flex-col justify-start items-center text-3xl  `}
    >
      {/* <!-- music detail --> */}
      <MusicDetail />

      {/* <!-- player --> */}
      <div className='  h-[9vh] w-full flex  justify-end'>
        {setting?.enable_track_share &&
          <div className='h-[52px] w-[52px] max-w-[52px] max-h-[52px] aspect-square'>
            <RenderShare  type={shareTypes?.TRACK} id={playedMusic?.id} shareLoadingStyle='w-[50%] h-[50%]'>
              <ShareAnimation/>
            </RenderShare>
          </div>
        }
      </div>

      <ReactPlayerComponent />

      {/* <!-- setting --> */}
    </div>
  );
}

export default React.memo(MinPlayer);
