import React from 'react';
// width="22"
// height="24"
function AddToPlayListSvg({fill}) {
    return (
        <svg
            className="w-full h-full"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 22 24"
        >
            <path
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
                d="M7.666 19.333v-6.32m13.333 4.097V2.666l-3.333.556-1.667.277-.833.14m-7.5 5.138V4.888l3.333-.555M3.778 5.444l4.445-.555M7.666 5.444V1M4.333 22.667a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667z"
            ></path>
            <path
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
                d="M17.667 20.444a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667z"
            ></path>
        </svg>
    );
}

export default AddToPlayListSvg;
