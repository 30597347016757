import { useLocation, useNavigate } from 'react-router-dom';
import CommonHeader from '../../components/common/CommonHeader';
import Container from '../../components/PageParentContainer/Container';
import { formatTextWithStyles } from '../../helper/utils';
import { CardActionArea } from '@mui/material';
import CopyIconSvg from '../../components/svg/common/CopyIconSvg';
import ShareGiftWithoutBorderSvg from '../../components/svg/gift/shareGiftWithoutBorderSvg';
import { useCallback } from 'react';
import { mainToast } from '../../helper/ToastsEmitter';
import { CopyText } from '../../helper/CopyText';
import GiftShareCard from '../../components/gift/GiftShareCard';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGiftDialog } from '../../redux/dialog/DialogSlice';
import { IsIosDevice } from '../../helper/CheckDevice';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage';

function BuyingGiftSuccessfully() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickBackButton = () => {
    window.removeEventListener('popstate', handleClickBackButton);
    navigate('/manage-gift', {
      replace: true,
      state: { navBackFromGateway: true },
    });
  };

  useEffect(() => {
    if (IsIosDevice())
      window.addEventListener('popstate', handleClickBackButton);
    return () => {
      dispatch(
        setGiftDialog({
          gift_dialog: null,
        }),
      );
    };
  }, [location]);

  const handleCopy = useCallback(() => {
    CopyText(location?.state?.gift_share_text?.description?.text);
    mainToast('کپی شد', 3000, 'bottom-center');
  }, [location.state]);

  const handleShareText = useCallback(() => {
    window.navigator
      .share({
        title: 'اشتراک با',
        text: location?.state?.gift_share_text?.description?.text,
      })
      .then(() => console.log('Yay, you shared it :)'))
      .catch(error => console.log('Oh noh! You couldn'));
  }, [location.state]);

  return (
    <Container
      style="p-4 bg-primary_dark"
      header={<CommonHeader />}
      shadow={false}
    >
      <div dir="rtl">
        <div className="min-h-[118px]">
          <CustomLazyLoadImage
            imageClass="mx-auto !relative !h-auto !w-[140px]"
            image={location?.state?.image_url}
          />
        </div>
        <h1 className="font-semibold font-dana text-base text-primary text-center leading-6 mb-8 mt-4 mx-auto min-h-[24px] ">
          {location?.state?.header}
        </h1>
        <h2
          dangerouslySetInnerHTML={{
            __html: formatTextWithStyles(
              location?.state?.gift_share_text?.title?.text,
              location?.state?.gift_share_text?.title?.decorators,
            ),
          }}
          className="font-dana text-white font-medium text-base"
        ></h2>

        <GiftShareCard
          text={location?.state?.gift_share_text?.description?.text}
          decorators={location?.state?.gift_share_text?.description?.decorators}
        />

        <div className="flex gap-3 mt-4">
          <CardActionArea
            className=" !flex !justify-center items-center !border !border-primary !border-solid  gap-2 !w-1/2 !bg-[#f2c94c33] !rounded-[23px] !h-[46px] !shadow-gift-submit-button !py-3 !text-center !text-primary !font-medium !font-dana !text-sm "
            type="submit"
            onClick={handleCopy}
          >
            <CopyIconSvg className="fill-primary" />
            کپی
          </CardActionArea>
          <CardActionArea
            onClick={handleShareText}
            className=" !flex !justify-center items-center gap-2 !w-1/2 !bg-primary !rounded-[23px] !h-[46px] !shadow-gift-submit-button !py-3 !text-center !text-title_box !font-medium !font-dana !text-base "
            type="submit"
          >
            <ShareGiftWithoutBorderSvg />
            اشتراک گذاری
          </CardActionArea>
        </div>
      </div>
    </Container>
  );
}

export default BuyingGiftSuccessfully;
