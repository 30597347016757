import { memo } from "react";

const TicketCategory = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="#F2C94C"
    {...props}
  >
    <path d="M2 9V7a3 3 0 0 1 3-3h5c1.306 0 2.417.835 2.83 2H19a3 3 0 0 1 3 3H2Zm0 2h20v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-6Z" />
  </svg>
);
export default memo(TicketCategory);
