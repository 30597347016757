import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import MessageBoard from "../../components/support/MessageBoard";
import NewAnswer from "../../components/support/NewAnswer";
import ShowTicketTitle from "../../components/support/ShowTicketTitle";
import { getTicketsMessage } from "../../services/ApiClient";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCategory } from "../../redux/support/SelectedCategorySlice.js";
import { setTicketReadCount } from "../../redux/initialize/InitializeSlice";
import { getInitialData } from "../../helper/ReduxSelectorContext";
import Container from "../../components/PageParentContainer/Container.jsx";
import CommonHeader from "../../components/common/CommonHeader.jsx";
import { CardActionArea } from "@mui/material";
import MessageIconSVG from "../../components/svg/support/MessageIconSVG.jsx";
import SwipeBottomSheet from "../../components/bottomSheets/SwipeBottomSheet.jsx";
import BottomSheetHeader from "../../components/bottomSheets/BottomSheetHeader.jsx";
import handelBreakSentence from "../../helper/handelBreakSentence";

function ShowMessage() {
  const activeCategory = useSelector(selectCategory);
  const [ticket, setTicket] = useState(null);
  const [message, setMessage] = useState([]);
  const dispatch = useDispatch();
  const initialData = getInitialData();
  const [showNewAnswer, setShowNewAnswer] = useState();
  const handleShowNewAnswer = (newOpen) => () => {
    setShowNewAnswer(newOpen);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const handleRedirect = () => {
    if (ticket?.is_new) {
      ticket.is_new = false;
      if (activeCategory?.direct_ticket) {
        navigate(-2);
      } else {
        navigate(-3);
      }
    }
  };
  const getTicketMessageQuery = useQuery(
    ["getTicketMessageQuery"],
    () => getTicketsMessage(location?.state?.ticket_code),
    {
      onSuccess: (res) => {
        setMessage(res?.data?.result?.ticket?.ticket_reply);
        setTicket(res?.data?.result?.ticket)
      },
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    window.addEventListener("popstate", handleRedirect);
  }, [ticket]);
  // useEffect(() => {
  //   setTicket(location?.state);
  // }, [location?.state]);

  useEffect(() => {
    if (ticket && ticket?.unread_count !== 0) {
      if (initialData?.unread_ticket_count === 0) return;
      dispatch(
        setTicketReadCount({ count: initialData?.unread_ticket_count - 1 })
      );
    }
  }, [ticket]);

  return (
    <Container header={<CommonHeader title={"مشاهده ی تیکت"} />}>
      <div
        dir="rtl"
        className="flex flex-col h-full max-h-full overflow-y-scroll overflow-x-hidden hide-scroll-bar"
      >
        <ShowTicketTitle
          onShowNewAnswer={handleShowNewAnswer}
          buttonState={showNewAnswer}
          ticket={ticket}
        />
        <div
          className={`${
              getTicketMessageQuery?.data?.data?.result?.ticket?.show_response_duration_box  ? "pb-28" : ""
          } flex flex-col gap-10 w-full h-full`}
        >
          <div
            className={
              "fixed top-0 w-full h-screen bg-show-ticket bg-repeat max-w-[520px]"
            }
          ></div>
          <MessageBoard
            message={message}
            ticket={ticket}
            loading={getTicketMessageQuery?.isFetching}
          />
        </div>
      </div>
      {getTicketMessageQuery?.data?.data?.result?.ticket?.show_response_duration_box && (
        <div
          dir="rtl"
          className="absolute flex items-center w-[calc(100%_-_32px)] bottom-16 right-4  pr-8 py-4 pl-6 bg-primary_dark rounded-[5px] h-24"
        >
          <div className="aspect-square h-[44px]">
            <MessageIconSVG />
          </div>
          <div className="flex flex-col gap-2 font-dana">
            <p className="text-sm text-primary font-danaBold">
              {getTicketMessageQuery?.data?.data?.result?.ticket?.response_duration_box?.response_title}
            </p>
            <p className="text-xs text-gray_4">
              {getTicketMessageQuery?.data?.data?.result?.ticket?.response_duration_box?.response_time}
            </p>
          </div>
        </div>
      )}

      <CardActionArea className="!absolute !bottom-4 !right-4 !w-[130px] !h-10 !rounded-full !rounded-br-none before:!content-['\A']  before:!absolute before:!right-0 before:!-bottom-2 before:!border-r-[20px] before:!border-b-[15px] before:!rounded-sm before:!border-transparent before:!border-r-primary">
        <div
          id="replyMessageButton"
          onClick={() => {
            setShowNewAnswer(true);
          }}
          className="font-dana w-[130px] h-10 rounded-full bg-primary !rounded-br-none "
        >
          <div className="flex w-full h-full justify-center items-center text-sm font-[500]">
            پیام جدید
          </div>
        </div>
      </CardActionArea>

      <SwipeBottomSheet open={showNewAnswer} toggleDrawer={handleShowNewAnswer}>
        <BottomSheetHeader
          title=" متن پیام "
          dividerLine
          // closeBottomSheet={handleShowNewAnswer}
          closeBottomSheet={() => {
            setShowNewAnswer(false);
          }}
        />
        <NewAnswer
          ticket={ticket}
          onCompleteRequestEvent={() => setShowNewAnswer((prev) => !prev)}
        />
      </SwipeBottomSheet>
    </Container>
  );
}

export default ShowMessage;
