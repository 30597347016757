import {createSlice} from "@reduxjs/toolkit";


export const DropDownSlice = createSlice(
    {
        name: 'dropDown',
        initialState: {
            isContextMenuOpen: false,
            activeIndex: null,
            showingStreamQualityDialog: false,
            showingBugReportDialog: false,
            createPlayListDialog: false,
            countDownObject:null,
            activeCountDown:false,
            trackData:null,
            horizontal:null,
            vertical:null,
            inPlayer:false,
            isDownloadedTrack:false,
        },
        reducers: {
            setActiveIndex(state, action) {

                const {index} = action.payload
                console.log('store:', state.activeIndex, action, index)
                if (state.activeIndex === index) {
                    state.activeIndex = null;
                    return
                }
                state.activeIndex = index
            },
            toggleDropDownMenu(state) {

                state.activeIndex = null;
                state.isContextMenuOpen = !state.isContextMenuOpen;

            },
            openContextMenu (state,action){
                const {data,vertical,horizontal,inPlayer,isDownloadedTrack}=action.payload;
                state.activeIndex=null;
                state.vertical=vertical;
                state.horizontal=horizontal;
                state.trackData=data;
                state.isContextMenuOpen = true;
                state.inPlayer=inPlayer
                state.isDownloadedTrack=isDownloadedTrack

            },
            closeDropDownMenu(state) {
                state.trackData=null;
                state.activeIndex = null;
                state.isContextMenuOpen = false;

            },
            showStreamQualityDialog(state) {
                state.showingStreamQualityDialog = !state.showingStreamQualityDialog;
            },
            showBugReportDialog(state) {
                state.showingBugReportDialog = !state.showingBugReportDialog;
            },
            toggleCreatePlayListOpenState(state) {
                state.createPlayListDialog = !state.createPlayListDialog;
            },
            toggleCountDown(state) {
                state.activeCountDown=!state.activeCountDown;
            },
            disableCountDown(state) {
                state.activeCountDown=false
            },
            setCountDownObject(state,action) {
              const {object}=action.payload;
              state.activeCountDown=true;
              state.countDownObject=object;
            },

        }
    }
)
export const {
    setActiveIndex,
    toggleDropDownMenu,
    closeDropDownMenu,
    showStreamQualityDialog,
    showBugReportDialog,
    toggleCreatePlayListOpenState,
    toggleCountDown,
    setCountDownObject,
    openContextMenu,
    disableCountDown
} = DropDownSlice.actions
export const selectDropDown = (state) => state.dropDown
export default DropDownSlice.reducer;
