
import { Button } from '@mui/material';
import * as React from 'react';

const PrimaryButton = (
    { style = "!w-full !h-full !bg-primary", children, ...props }

) => {

    return (
        <Button
            size='small'
            className={`${style} !-tracking-[.005rem]`}

            variant="contained"  {...props}  >
            {children}
        </Button>
    );
}

export default PrimaryButton;