import { memo, useCallback, useEffect, useRef, useState } from "react";
import Container from "../../components/PageParentContainer/Container";
import SettingCard from "../../components/setting/SettingCard";
import settingItems from "../../consts/Setting";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../helper/ReduxSelectorContext";
import { useAction } from "../../hooks/useAction";
import { ACTIONS } from "../../consts/ActionsConstants";
import ConfirmDialogs from "../../components/dialogs/ConfirmDialogs";
import CachSvg from "../../components/svg/setting/cachSvg";
import CardActionArea from "@mui/material/CardActionArea";
import ChevronLeft from "../../components/svg/common/ChevronLeft";
import CrownSvg from "../../components/svg/common/CrownSvg";
import CommonHeader from "../../components/common/CommonHeader";
import StreamQuality from "../../components/setting/StreamQuality";
import SwipeBottomSheet from "../../components/bottomSheets/SwipeBottomSheet";
import { useDispatch, useSelector } from "react-redux";
import { clearTemporaryDownloadQueue } from "../../redux/download/downloadSlice";
import DownloadQuality from "../../components/setting/DownloadQuality";
import SendApp from "../../components/setting/SendApp";
import UserNormalSvg from "../../components/svg/setting/UserNormalSvg";
import { selectInitialData } from "../../redux/initialize/InitializeSlice.js";
import GiftSettingCard from "../../components/gift/GiftSettingCard";

const Detail = memo(({ keyName }) => {
  const user = useUserData();
  const { telegram_data } = useSelector(selectInitialData);
  if (keyName === "downloadQuality")
    return user?.download_quality ? (
      <span dir="ltr">{user?.download_quality} kps</span>
    ) : (
      "انتخاب کیفیت دانلود"
    );
  if (keyName === "broadcastQuality")
    return <span dir="ltr">{user?.streaming_quality} kps</span>;
  if (keyName === "manageActiveAccount")
    return user?.user_sessions_count + "  دستگاه ";
  if (keyName === "connectTelegram" && telegram_data?.connected_title) {
    return (
      <p className="font-poppins text-[#169de2] text-[16px] font-medium leading-6 truncate   ">
        {telegram_data?.connected_title}
      </p>
    );
  }
  return null;
});

export default function Setting() {
  // ANCHOR hook
  const navigate = useNavigate();
  const user = useUserData();
  const dialogRef = useRef();
  const action = useAction();
  const dispatch = useDispatch();

  // useState
  const [ComponentBottomSheet, setComponentBottomSheet] = useState(null);
  const [IsOpenBottomSheet, setIsOpenBottomSheet] = useState(false);

  //   TODO  UNUSE CODE DLETE THIS BLOCK AND "useDispatch" HOOK AND DEPENDENCY IMPORT OFTER CHECK IT
  // ALIREZA AAGHAEE
  useEffect(() => {
    if (!ComponentBottomSheet) dispatch(clearTemporaryDownloadQueue());
  }, [ComponentBottomSheet]);

  useEffect(() => {
    if (localStorage.getItem('buy_gift_data')) localStorage.removeItem("buy_gift_data");
  }, []);


  // ANCHOR handeler
  const cardClickHandeler = useCallback(({ key, link }) => {
    if (link) navigate(link);
    else BottomSheetSelector(key);
  }, []);

  const handelClaenCach = () => {
    action({ target_type: ACTIONS.CLEAR_CACHE });
  };

  const handleOpenDialog = () => {
    dialogRef.current.openDialog();
  };
  const handleEditProfile = () => {
    navigate("/setting/edit-profile");
  };
  const toggleDiscountCodeBottomSheet = (newOpen) => () => {
    setIsOpenBottomSheet(newOpen);
  };
  const BottomSheetSelector = useCallback((key) => {
    if (key === "broadcastQuality")
      setComponentBottomSheet(
        <StreamQuality openBottomSheetSetter={setIsOpenBottomSheet} />
      );
    if (key === "downloadQuality")
      setComponentBottomSheet(
        <DownloadQuality openBottomSheetSetter={setIsOpenBottomSheet} />
      );
    if (key === "sendApp")
      setComponentBottomSheet(
        <SendApp openBottomSheetSetter={setIsOpenBottomSheet} />
      );
    setIsOpenBottomSheet(true);
  }, []);

  return (
    <Container
      header={<CommonHeader title="تنظیمات" />}
      style="bg-primary_dark"
    >
      <div dir="rtl" className=" w-full px-4 pb-10 pt-4  ">
        <div
          onClick={handleEditProfile}
          className="h-[125px] flex flex-col rounded-[20px] overflow-hidden bg-black_2"
        >
          <div className="flex justify-between items-center   border-b-2 border-primary_dark  px-4 h-[84px]">
            <div className="flex  w-[calc(100%-100px)] ">
              {/* TODO FOR NON VIP USER CHNAGE ICON  */}
              <div className="h-11 w-11 rounded-full bg-gray_1 me-2 flex justify-center items-center">
                {user?.is_premium ? <CrownSvg /> : <UserNormalSvg />}
              </div>
              <div className="flex flex-col font-kalameh text-gray_6 w-[85%] ">
                <span className=" text-base font-medium w-full truncate">
                  {user?.full_name}
                </span>
                <span className="leading-6 text-sm mt-1 font-medium w-full truncate">
                  {user?.phone}
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <span className=" text-primary font-dana text-xs font-medium">
                ویرایش
              </span>

              <ChevronLeft className="stroke-primary w-6 h-6" />
            </div>
          </div>
          <div className="flex font-medium text-gray_5 justify-between h-[40px] px-4 items-center font-dana">
            <span className="text-sm">کد کاربری</span>
            <span className="text-xs">{user?.user_code}</span>
          </div>
        </div>
        <div className="mt-6">
          <GiftSettingCard />
        </div>
        <div
          id="list"
          className="overflow-hidden mt-6 rounded-[20px] first:rounded-t-[20px] last:rounded-b-[20px]"
        >
          {settingItems.map((item) => (
            <SettingCard
              key={item.key}
              itemKey={item?.key}
              onClick={() => cardClickHandeler(item)}
              label={item.label}
              icon={item.icon}
              detail={<Detail keyName={item.key} />}
            />
          ))}
        </div>
        <CardActionArea
          onClick={handleOpenDialog}
          className=" !rounded-[20px] !mt-[20px]"
        >
          <div className="flex h-[124px] items-center px-4  rounded-[20px]  bg-black_2 py-6">
            <div className=" me-4 ">
              <CachSvg />
            </div>
            <div className="flex flex-col font-dana">
              <span className="font-normal text-base text-gray_6">
                پاک کردن حافظه کَش
              </span>
              <span className="font-normal leading-6 text-xs text-gray_3">
                با پاک کردن حافظه کَش، اطلاعات کش شده، آهنگ های اخیرا شنیده شده
                ، خواننده های و پلی لیست های اخیرا دیده شده حذف میشود.
              </span>
            </div>
          </div>
        </CardActionArea>
        <SwipeBottomSheet
          open={IsOpenBottomSheet}
          toggleDrawer={toggleDiscountCodeBottomSheet}
        >
          {ComponentBottomSheet}
        </SwipeBottomSheet>
      </div>
      <ConfirmDialogs
        title={
          <span className=" font-semibold text-lg"> پاک کردن حافظه کَش </span>
        }
        text={
          <span className=" font-normal text-base">
            با پاک کردن کش عکس های شما دوباره بارگذاری می‌شود
          </span>
        }
        ref={dialogRef}
        dialogIcon={<CachSvg className="mx-auto" />}
        buttonDirection={"dir-ltr"}
        confirmText={"پاک کردن"}
        confirmTextColor={"text-primary"}
        confirmBGColor={"bg-transparent"}
        confirmBorderColor={"border-primary"}
        confirmAction={handelClaenCach}
        cancelText={"لغو"}
        cancelTextColor={"text-secondary"}
        cancelBGColor={"bg-primary"}
        cancelBorderColor={"transparent"}
        PanelClassName="!w-72"
      />
    </Container>
  );
}
