import { useMutation } from "@tanstack/react-query";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import AttachmentSvg from "../svg/support/AttachmentSvg.jsx";
import { Dialog, Transition } from "@headlessui/react";
import CloseDialogButton from "../svg/common/CloseDialogButton.jsx";
import DangerSignSupportSvg from "../svg/support/DangerSignSupportSvg.jsx";
import SuccessfulUpload from "./SuccessfulUpload.jsx";
import SelectedItem from "./SelectedItem.jsx";
import UploadingItem from "./UploadingItem.jsx";
import ErrorUpload from "./ErrorUpload.jsx";
import { sendMessageFile } from "../../services/ApiClient.js";
import { useDispatch, useSelector } from "react-redux";
import { selectInitialData } from "../../redux/initialize/InitializeSlice.js";
import TriangleSvg from "../svg/tickets/TriangleSvg.jsx";
import { CardActionArea, IconButton } from "@mui/material";
import CloseSvg from "../svg/tickets/CloseSvg.jsx";
import SwipeBottomSheet from "../bottomSheets/SwipeBottomSheet.jsx";
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import {
  getHasErrorApi,
  setHasErrorApi,
} from "../../redux/UploadFile/UploadFileSlice.js";

let controller = new AbortController();
let maxUpload = 11;

function UploadFile(
  { sendCompleteRequest, isOpen = false, setIsOpen, loading },
  ref
) {
  const initialDataSelector = useSelector(selectInitialData);
  const Data = { ...initialDataSelector };
  const allowedFormats = Data.ticket_files?.allowed_formats;
  const allowedMaxSize = Data.ticket_files?.allowed_max_size;
  const allowedCount = Data.ticket_files?.allowed_count;
  const hasErrorUploadApi = useSelector(getHasErrorApi);

  const [selectedFile, setSelectedFile] = useState([]);
  // const [isOpen, setIsOpen] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [error, setError] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const openInput = () => {
    inputRef.current.click();
  };
  //function for call upload file with ref in other component
  useImperativeHandle(ref, () => ({
    uploadFiles(replyID) {
      if (
        !(
          selectedFile.find((f) => f?.typeError) ||
          selectedFile.find((f) => f?.sizeError)
        )
      )
        handleSendMessage(replyID);
    },
    isValid: () =>
      !(
        selectedFile.find((f) => f?.typeError) ||
        selectedFile.find((f) => f?.sizeError)
      ),

    resetData() {
      setSelectedFile([]);
      setError(false);
    },
  }));

  //get selected item
  const handleSelectedFile = (selectedItem) => {
    closeModal();
    if (selectedItem.length >= maxUpload) {
      setError(true);
      return;
    }
    setError(false);

    // add object status fields

    Object.entries(selectedItem)?.map((item) => {
      item[1].uploading = false;
      item[1].hasError = false;
      item[1].sizeError = item[1].size / 1024 > allowedMaxSize ? true : false;
      item[1].typeError = !allowedFormats?.includes(
        item[1].name.substring(item[1].name.lastIndexOf(".") + 1)
      );
      item[1].errorMessage = "";
      item[1].cancelFile = false;
      item[1].UploadedSuccessful = false;
      setSelectedFile((prev) => [...prev, item[1]]);
    });
    maxUpload = maxUpload - selectedItem.length;
  };

  // delete selected item
  const handleDeleteFile = (itemIndex) => {
    setSelectedFile((prev) => prev.filter((item, index) => index != itemIndex));
    maxUpload++;
  };

  useEffect(() => {
    if (selectedFile.find((file) => file.hasError !== hasErrorUploadApi))
      dispatch(
        setHasErrorApi({
          hasErrorApi: selectedFile.find((file) => file.hasError == true)
            ? true
            : false,
        })
      );
    if (!selectedFile?.length) dispatch(setHasErrorApi({ hasErrorApi: false }));
  }, [selectedFile]);

  //send selected data to endpoint
  const sendMessageFileQuery = useMutation(
    ({ id, file, signal }) =>
      sendMessageFile(id, { file }, (e) => handlePercent(e), signal),
    {
      onSuccess: (res) => {
        dispatch(setHasErrorApi({ hasErrorApi: false }));
        setPercentage(0);
      },
      onError: (error) => {
        setPercentage(0);
        dispatch(setHasErrorApi({ hasErrorApi: true }));
        setApiErrror(true);
      },
    }
  );

  //percent for show upload status
  const handlePercent = (event) => {
    const percentage = Math.round((100 * event.loaded) / event.total);
    setPercentage(percentage);
  };
  const closeCountError = () => {
    setError(false);
  };

  //send item to request queue and handle upload status or cancel item
  const handleSendMessage = async (id) => {
    let isValid = selectedFile.length ? false : true;
    
    for (let index = 0; index < selectedFile.length; index++) {
      if (selectedFile[index].typeError || selectedFile[index].sizeError) {
        // continue;
        return;
      }

      selectedFile[index].uploading = true;
      const formData = new FormData();
      formData.append("file", selectedFile[index]);
      setPercentage(0);
      await sendMessageFileQuery
        .mutateAsync({ id, file: formData, signal: controller.signal })
        .then((res) => {
          if (res?.data?.done) {
            selectedFile[index].uploading = false;
            selectedFile[index].UploadedSuccessful = true;
            isValid = true;
          } else {
            selectedFile[index].uploading = false;
            selectedFile[index].hasError = true;
            selectedFile[index].errorMessage = res?.data?.error?.message;
            dispatch(setHasErrorApi({ hasErrorApi: true }));
          }
          setPercentage(0);
        })
        .catch((err) => {
          selectedFile[index].uploading = false;
          selectedFile[index].hasError = true;
          selectedFile[index].cancelFile = true;
          controller = new AbortController();
          dispatch(setHasErrorApi({ hasErrorApi: true }));
          setPercentage(0);
        });
    }
    maxUpload = 11;
    // if(hasErrorUploadApi)
    sendCompleteRequest(isValid);
  };
  const toggleDiscountCodeBottomSheet = (newOpen) => () => {
    setIsOpen(newOpen);
  };
  //function for set jsx item with status code
  const setUploadStatusItem = (item, index) => {
    if (item.uploading) {
      return (
        <UploadingItem
          controller={controller}
          percentage={percentage}
          deleteItem={(item) => handleDeleteFile(item)}
          index={index}
          item={item}
        />
      );
    } else if (item.UploadedSuccessful) {
      return (
        <SuccessfulUpload
          deleteItem={(item) => handleDeleteFile(item)}
          index={index}
          item={item}
        />
      );
    } else if (item.hasError || item.typeError || item.sizeError) {
      return (
        <ErrorUpload
          deleteItem={(item) => handleDeleteFile(item)}
          index={index}
          item={item}
          loading={loading}
        />
      );
    } else {
      return (
        <SelectedItem
          deleteItem={(item) => handleDeleteFile(item)}
          index={index}
          item={item}
          loading={loading}
        />
      );
    }
  };

  const selectedFilesComponent = useMemo(
    () =>
      selectedFile?.map((item, index) => (
        <Fragment key={index + "selectedFilesComponent" + item?.uploading}>
          {setUploadStatusItem(item, index)}
        </Fragment>
      )),
    [selectedFile, percentage, controller]
  );

  return (
    <div
      dir="rtl"
      className={`w-full mx-auto flex flex-col gap-4 rounded-[20px] !z-30 relative ${
        selectedFile.length > 0 ? "mt-3" : "mt-0"
      }`}
    >
      <SwipeBottomSheet
        open={isOpen}
        toggleDrawer={toggleDiscountCodeBottomSheet}
      >
        <BottomSheetHeader
          dividerLine
          closeBottomSheet={closeModal}
          title="فایل ضمیمه  "
          HeaderIcon={
            <AttachmentSvg className="stroke-gray_6 w-[20px] h-[20px]" />
          }
        />

        <div dir="rtl" className=" flex flex-col gap-4 py-3 min-h-[130px]">
          <div className="flex items-center px-6 ">
            <p className="text-gray_3 text-base font-dana font-medium  text-right">
              بیشترین حجم مجاز: <span dir="ltr">100Mb</span>
            </p>
          </div>
          <input
            ref={inputRef}
            onChange={(e) => handleSelectedFile(e.target.files)}
            multiple
            className="hidden"
            id="uploadFile"
            type={"file"}
          />
          <CardActionArea
            onClick={() => {
              openInput();
            }}
            className=" !h-14 !pb-0 !w-full"
          >
            <div className="flex items-center px-6">
              <p className=" text-[13px] font-normal text-right leading-6  font-dana">
                <span className="text-primary  ">
                  انتخاب از فایل ها
                </span>{" "}
                <span className="text-gray_3 ">
                  (حداکثر {allowedCount} فایل)
                </span>
              </p>
            </div>
          </CardActionArea>
        </div>
      </SwipeBottomSheet>

      {selectedFile && selectedFile?.length > 0 && (
        <div className="flex flex-col w-full gap-4 ">
          {selectedFilesComponent}
        </div>
      )}
      {error && (
        <div className="shadow-upload-file-error-card flex justify-between items-start w-full rounded-[10px] border border-red  bg-black_2  gap-[14px] py-3  px-[18px] mt-4 ">
          <div className="flex items-center gap-4">
            <div
              className="h-[27px]  min-[1440px]:h-[1.8750vw]"
              style={{ aspectRatio: 30 / 27 }}
            >
              <TriangleSvg fill={"#EB5757"} />
            </div>
            <div className="flex flex-col gap-r5">
              <p className="text-sm font-bold font-dana leading-6 text-red ">
                بیش از حد مجاز انتخاب کرده اید.
              </p>
              <p className="text-xs font-medium text-red font-dana leading-6 ">
                شما مجاز به اپلود حداکثر {allowedCount} فایل هستید.
              </p>
            </div>
          </div>
          <IconButton onClick={closeCountError} className="!-m-2">
            <CloseSvg className="w-[35px] h-[35px]" />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default React.forwardRef(UploadFile);
