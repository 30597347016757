// React imports
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Third-party library imports
import { useInfiniteQuery } from '@tanstack/react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
// Local modules/components
import CompleteSongList from '../../components/ArtistProfile/CompleteSongList.jsx';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';
import PlaylistPlaysButton from '../../components/svg/playList/PlaylistPlaysButton';
import { getUserCollectionTracks } from '../../services/ApiClient.js';
import { userPlaylistSortTypes } from '../../consts/MenuItemsConsts.js';

import { usePlayedTrack } from '../../hooks/usePlayedTrack';
import {
  getIsPlaying,
  getPlayedPlaylist,
  globalData,
} from '../../helper/ReduxSelectorContext';
import {
  setIsPlaying,
  setPlayedPlaylist,
  setPlayerPaginationData,
  setPlayList,
} from '../../redux/player/PlayMusicSlice';
import PlaylistPauseButton from '../../components/svg/playList/PlaylistPauseButton';
import ClickReaction from '../../helper/ClickReaction';
// Constants
import SortSvg from '../svg/playList/SortSvg.jsx';
import CollectionCategoryHeader from './CollectionCategoryHeader.jsx';
import Container from '../PageParentContainer/Container.jsx';
import ScrollTopButton from '../common/ScrollTopButton.jsx';
import CssShimmer from '../common/CssShimmer.jsx';
import UserPlaylistContextMenu from './UserPlaylistContextMenu.jsx';
import DisableEditMode from './DisableEditMode.jsx';
import DragDropContainer from '../svg/playList/DragDropContainer.jsx';
import EditPlaylistAbsoluteView from './EditPlaylistAbsoluteView.jsx';
import { toggleDragging } from '../../redux/globalData/globalDataSlice.js';
import UserCollectionHintDialog from '../dialogs/UserCollectionHintDialog.jsx';
import { PlayListType } from '../../consts/PlayListType';
import { mainToast } from '../../helper/ToastsEmitter.jsx';
import SongListLoading from '../loading/SongListLoading.jsx';

function UserPlaylist() {
  // hooks
  const { id: collectionId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const globalDataObject = globalData();
  const isDragging = globalDataObject?.isDragging;
  const currentPlaylist = getPlayedPlaylist();
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  // Refs
  const editCollectionRef = useRef(null);
  //variable
  let isPlaying = getIsPlaying();
  //state
  const [sortType, setSortType] = useState('my_sort');
  const [loaded, setLoaded] = useState(false);
  const [editPlaylistOpenState, setEditPlaylistOpenState] = useState(false);
  const [collectionObject, setCollectionObject] = useState(
    location?.state?.playlistData,
  );
  const collectionQueryData = {
    user_collection_id: collectionId,
    sort: sortType,
  };
  const handleUpdateCollection = newCollection => {
    setCollectionObject(newCollection);
  };

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['getUserPlaylistTracks' + sortType + collectionId],
      ({ pageParam = 0 }) =>
        getUserCollectionTracks({
          user_collection_id: collectionId,
          sort: sortType,
          offset: pageParam,
        }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const allData = allPages?.flatMap(page => page?.data?.result?.tracks);
          return lastPage?.data?.result?.end ? false : allData?.length;
        },
        refetchOnWindowFocus: false,
      },
    );
  const tracksList = data?.pages?.flatMap(page => page?.data?.result?.tracks);
  const reFetchData = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const changeSort = type => {
    setSortType(type);
  };
  const handlePlayPlaylist = play => {
    if (tracksList?.length === 0 || !tracksList) {
      mainToast('.پلی لیست خالی است');
      return;
    }
    if (currentPlaylist?.id != collectionObject?.id) {
      setPlayerTrack(tracksList[0], tracksList, PlayListType.PLAYLIST);
      dispatch(setPlayedPlaylist({ playlist: collectionObject }));
    }
    dispatch(setPlayList({ playList: tracksList }));
    dispatch(
      setPlayerPaginationData({
        playerPaginationData: {
          url: '/getUserCollectionTracks',
          payload: collectionQueryData,
          end: !hasNextPage,
        },
      }),
    );
    dispatch(setIsPlaying({ isPlay: play }));
  };
  useEffect(() => {
    const hashEditMode = location.hash === '#edit';
    dispatch(toggleDragging({ dragState: hashEditMode }));
  }, [location]);
  useEffect(() => {
    changeSort(userPlaylistSortTypes[0].type);
  }, [collectionId]);
  useEffect(() => {
    setCollectionObject(location?.state?.playlistData);
  }, [location?.state]);
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Container style="bg-primary_dark">
      {isDragging && <UserCollectionHintDialog />}
      <CollectionCategoryHeader
        extraFunction={
          isDragging ? (
            <DisableEditMode />
          ) : (
            <UserPlaylistContextMenu
              trackList={tracksList}
              collectionData={collectionObject}
            />
          )
        }
        hasSearch={false}
        title={collectionObject?.title}
        backgroundId="playlist-songs-background"
        pageId="user-playlist-song"
      />
      <div
        dir="rtl"
        id="user-playlist-song"
        className="w-full h-full overflow-scroll hide-scroll-bar pb-10"
      >
        <div
          id="playlist-songs-background"
          className=" w-full aspect-square relative  overflow-hidden  "
        >
          <CustomLazyLoadImage
            imageClass={`object-cover block`}
            image={collectionObject?.image_large}
          />
          <div
            className={`absolute  top-0 right-0 left-0 bottom-0 flex justify-center items-center    ${
              isDragging
                ? 'bg-drag-and-drop-overlay'
                : 'bg-playlist-song-overlay'
            } h-[100.15%] `}
          >
            {isDragging && (
              <EditPlaylistAbsoluteView
                onUpdateCollectionData={newCollection =>
                  handleUpdateCollection(newCollection)
                }
                sortType={sortType}
                collectionId={collectionId}
                collectionObject={collectionObject}
              />
            )}
          </div>
        </div>
        <ScrollTopButton id={'user-playlist-song'} />
        <div
          id="page-infinite-scroll-user"
          className="relative px-4 flex flex-col gap-5 mt-[-75px] w-full"
        >
          <div className="flex flex-col`">
            <div className="flex w-full justify-between items-end">
              <div className="flex flex-col gap-1">
                {collectionObject &&
                  (collectionObject ? (
                    <div className="flex  items-center">
                      <p className="text-xs  text-gray_6 font-dana font-normal ">
                        <span>آخرین بروزرسانی: </span>
                        {collectionObject?.updated_days > 0 ? (
                          <span>{collectionObject?.updated_days} روز قبل </span>
                        ) : (
                          <span>امروز</span>
                        )}{' '}
                      </p>
                    </div>
                  ) : (
                    <CssShimmer classNames={'w-30 h-4 rounded-[10px]'} />
                  ))}
              </div>
              {!isDragging && (
                <div className="flex justify-between items-center  ">
                  <div className="flex  gap-3  cursor-pointer   ">
                    <ClickReaction>
                      <div>
                        {currentPlaylist?.id === collectionObject?.id &&
                        isPlaying ? (
                          <div onClick={() => handlePlayPlaylist(false)}>
                            <PlaylistPauseButton ratio="!h-[60px]" />
                          </div>
                        ) : (
                          <div onClick={() => handlePlayPlaylist(true)}>
                            <PlaylistPlaysButton ratio="!h-[60px]" />
                          </div>
                        )}
                      </div>
                    </ClickReaction>
                  </div>
                </div>
              )}
            </div>
          </div>
          {isDragging ? (
            <DragDropContainer list={tracksList} />
          ) : (
            <InfiniteScroll
              next={reFetchData}
              hasMore={hasNextPage}
              loader={
                <div className=" mt-2  flex w-full justify-center text-yellow  ">
                  <SongListLoading />
                </div>
              }
              scrollableTarget="user-playlist-song"
              dataLength={tracksList?.length || 0}
            >
              <div className="">
                <CompleteSongList
                  scrollId={'user-playlist-song'}
                  onChangeSort={changeSort}
                  list={tracksList}
                  pageId={collectionId}
                  userPlaylist={true}
                  trackCount={tracksList?.length}
                  title={' آهنگ ها'}
                  titleUnderLine={false}
                  collectionObject={collectionObject}
                  sortTypes={userPlaylistSortTypes}
                  sortButton={activeSortText => (
                    <div className="flex items-center gap-1">
                      <div className="flex gap-2   items-center">
                        <div className="flex w-[22px] h-[16px]  ">
                          <SortSvg />
                        </div>
                        <p className="text-primary font-dana text-[14px] font-medium">
                          ترتیب نمایش:
                        </p>
                      </div>
                      <p className="text-gray_3 font-dana text-[12px] font-medium">
                        {activeSortText}
                      </p>
                    </div>
                  )}
                  apiUrl={'/getUserCollectionTracks'}
                  requestPayload={collectionQueryData}
                  endOfPagination={!hasNextPage}
                  loading={isLoading && !tracksList}
                />
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </Container>
  );
}

export default UserPlaylist;
