import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAction} from "../../hooks/useAction.js";
import useWindowDimensions from "../../helper/ScreenSize.js";


function PlayListCard({
                          children,
                          itemData
                      }) {

    const action = useAction();
    const openSubCategory = () => {
        if (!itemData) {
            return;
        }
        action({target_type: "collection_category", children: itemData});
    };
    return (
        <div

            onClick={openSubCategory}
            className={`z-30  overflow-hidden w-[49%] shadow-collection-shadow  relative rounded-[10px]  aspect-[162/77]`}
        >
            {children}

        </div>
    );
}

export default PlayListCard;
