import React from 'react'

function RoundedDrawerHeader({classList=''}) {
    return (

        <svg className={classList} width="161" height="40" viewBox="0 0 161 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M43.0615 19.7436C31.3867 8.31134 16.3408 0 0 0H160.962C144.729 0 129.761 8.19446 118.127 19.5162C107.628 29.733 93.6084 40.1184 80.0771 40C66.8809 39.8845 53.3018 29.771 43.0615 19.7436Z"
                  fill="#171717"/>
            <path d="M90 15L80 25L70 15" stroke="white" stroke-width="3" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>


    )
}

export default RoundedDrawerHeader
