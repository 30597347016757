import { createSlice } from "@reduxjs/toolkit";

export const AdvertiseSlice = createSlice({
    name: 'advertise',
    initialState: {
        haveAdvertise: false,
        advertiseData: null,
        emitGetAdvertise: null,
        waitForAdv: false,
        singleAdveItem: null,
        advertiseStatusCode: 481,
    },
    reducers: {
        toggleAdvertise: (state, action) => {
            const { advertise } = action.payload
            state.haveAdvertise = advertise
        },
        setAdvertiseData: (state, action) => {
            const { advertiseData } = action.payload
            state.advertiseData = advertiseData
        }, setSingleAdveItem: (state, action) => {
            const { singleAdveItem } = action.payload
            console.log("Dsgdsgdsgsdgdsgsdg",singleAdveItem);
            state.singleAdveItem = singleAdveItem
        }, setEmitGetAdvertise: (state, action) => {
            state.emitGetAdvertise = Date.now()
        }, setWaitForAdv: (state, action) => {
            const { waitForAdv } = action.payload
            state.waitForAdv = waitForAdv
        }, setAdvertiseStatusCode: (state, action) => {
            const { advertise_status } = action.payload
            state.advertiseStatusCode = advertise_status
        },


    },
})
export const { toggleAdvertise, setAdvertiseData, setEmitGetAdvertise, setWaitForAdv, setSingleAdveItem,setAdvertiseStatusCode } = AdvertiseSlice.actions;
export const selectHaveAdvertise = (state) => state.advertise.haveAdvertise;
export const selectAdvertiseData = (state) => state.advertise.advertiseData;
export const selectEmitAdvertise = (state) => state.advertise.emitGetAdvertise;
export const selectWaitForAdv = (state) => state.advertise.waitForAdv;
export const selectSingleAdveItem = (state) => state.advertise.singleAdveItem;
export const selectAdvertiseStatusCode = (state) => state.advertise.advertiseStatusCode;
export default AdvertiseSlice.reducer;
