import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { IsIosDevice } from "../../helper/CheckDevice";
import { FreeMode } from "swiper";
import "swiper/css/free-mode";
import { SearchTabBarItem } from "../../consts/SearchTabBarItem";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../redux/search/SearchSlice";
import { geSearchSelectedTab } from "../../helper/ReduxSelectorContext";
import { useEffect } from "react";
import { CardActionArea } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
function SearchTabBar() {
  const dispatch = useDispatch();
  const selectedTab = geSearchSelectedTab();
  const navigate = useNavigate();
  const location = useLocation();
  const tabBarItem = [
    {
      title: "همه",
      Key: SearchTabBarItem.ALL,
    },
    {
      title: "آهنگ",
      Key: SearchTabBarItem.TRACKS,
    },
    {
      title: "پلی لیست",
      Key: SearchTabBarItem.PLAYLISTS,
    },
    {
      title: "خواننده",
      Key: SearchTabBarItem.ARTISTS,
    },
    {
      title: "آلبوم",
      Key: SearchTabBarItem.ALBUMS,
    },
  ];
  const handleSelectedTab = (item) => {
    dispatch(setSelectedTab({ tab: item?.Key }));
    navigate("#" + item?.Key, { replace: true });
  };

  useEffect(() => {
    if (location?.hash)
      dispatch(setSelectedTab({ tab: location?.hash?.split("#")[1] }));
  }, [location]);

  return (
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={8}
      modules={[FreeMode]}
      freeMode={true}
      className=" mb-4 !h-fit !w-full !select-none new-song-swiper  !overflow-visible "
      cssMode={IsIosDevice()}
    >
      {tabBarItem?.map((item, index) => (
        <div className="flex flex-row " key={index}>
          <SwiperSlide className="!w-fit last:!pl-4 first:!pr-4">
            <CardActionArea
              className="!rounded-[10px]"
              onClick={() => handleSelectedTab(item)}
            >
              <div
                className={`${
                  selectedTab === item.Key
                    ? "bg-primary text-black_2"
                    : "bg-black_2 text-gray_4"
                } transition-colors duration-150 ease-in-out  whitespace-nowrap  font-dana text-sm font-[500] flex justify-center items-center py-[6px] px-4 rounded-[10px]`}
              >
                {item?.title}
              </div>
            </CardActionArea>
          </SwiperSlide>
        </div>
      ))}
    </Swiper>
  );
}

export default SearchTabBar;
