import { useMemo } from 'react';
import followedArtistAvatar from '../../assets/myMelodify/followed-artist.webp';
import followedPlaylistAvatar from '../../assets/myMelodify/followed-playlist.webp';
import myPlaylistAvatar from '../../assets/myMelodify/my-playlist.webp';
import likedTracksAvatar from '../../assets/myMelodify/liked-tracks.webp';
import userPublishedTracksAvatar from '../../assets/myMelodify/user-published-avatar.webp';
import ProfileDetailCard from './ProfileDetailCard';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import CssShimmer from '../common/CssShimmer';
import slikedTracksAvatar from '../../assets/myMelodify/liked-tracks.webp';
import { getInitialData } from '../../helper/ReduxSelectorContext';

const ProfileCardList = ({
  userPublishedTracksCount = 0,
  userCollectionsCount = 0,
  likedTracksCount = 0,
  followedCollectionsCount = 0,
  followedArtistsCount = 0,
  userPublishedTracksBanners,
  isLoading,
}) => {
  // ANCHOR HOOK
  const navigate = useNavigate();
  const initialtData = getInitialData();

  // ANCHOR variable
  const cardList = useMemo(
    () => [
      {
        avatar: userPublishedTracksAvatar,
        title: 'آثار منتشر شده ی من',
        subtitle: `${userPublishedTracksCount} آهنگ`,
        action: '/my-melodify/user-published',
        bannerData: userPublishedTracksBanners,
        enable: initialtData?.has_published_tracks,
      },
      {
        avatar: likedTracksAvatar,
        title: 'آهنگ های لایک شده',
        subtitle: `${likedTracksCount} آهنگ`,
        action: '/my-melodify/user-liked',
        enable: true,
      },
      {
        avatar: myPlaylistAvatar,
        title: 'پلی‌لیست‌های من',
        subtitle: `${userCollectionsCount} پلی‌لیست`,
        action: '/my-melodify/user-playlists',
        enable: true,
      },
      {
        avatar: followedPlaylistAvatar,
        title: 'پلی‌لیست‌های دنبال شده',
        subtitle: `${followedCollectionsCount} پلی‌لیست`,
        action: '/my-melodify/followed-playLists',
        enable: true,
      },
      {
        avatar: followedArtistAvatar,
        title: 'هنرمندان دنبال شده',
        subtitle: `${followedArtistsCount} خواننده`,
        action: '/my-melodify/followed-artists',
        enable: true,
      },
    ],
    [
      userCollectionsCount,
      likedTracksCount,
      followedCollectionsCount,
      followedArtistsCount,
    ],
  );

  // ANCHOR handeler
  const handleNavigate = ({ action, title, bannerData }) => {
    console.log(action, bannerData);
    navigate(action, {
      state: { item: { title, bannerData } },
    });
  };

  return (
    <div className="flex flex-col  px-4 ">
      {cardList.map((card, index) =>
        card?.enable ? (
          <div key={index + 'card-detail'} className="my-1">
            <ProfileDetailCard
              isLoading={isLoading}
              onClick={() => handleNavigate(card)}
              avatar={card.avatar}
              subtitle={card.subtitle}
              title={card.title}
              bannerData={card?.bannerData}
            />
          </div>
        ) : null,
      )}
    </div>
  );
};

export default memo(ProfileCardList);
