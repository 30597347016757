import { useEffect, useState } from 'react';
import RouteSystem from './routes/RouteSystem';
import DialogsContainer from './components/dialogs/DialogsContainer';
import { selectIsBanned, setIsBanned } from './redux/dialog/DialogSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomDialog from './components/dialogs/CustomDialog';
import IntroductionDialog from './components/dialogs/IntroductionDialog';
import ExpireDialog from './components/dialogs/ExpireDialog';
import { Toaster } from 'react-hot-toast';
import DownloadQualityBottomSheet from './components/download/DownloadQualityBottomSheet.jsx';
import LimitationDialog from './components/common/LimitationDialog.jsx';
import GlobalUnfollowDialog from './components/common/GlobalUnfollowDialog.jsx';
import { downloadData } from './helper/ReduxSelectorContext.js';
import { closeDownloadDialog } from './redux/download/downloadSlice.js';
import AccountThresholdDialog from './components/common/AccountThresholdDialog.jsx';
// import InstallPWAButton from "./components/dialogs/InstallPWAButton";
import UpdateDialog from './components/dialogs/UpdateDialog';
import { useUpkeep } from './hooks/useUpkeep.js';
import BannedDialog from './components/dialogs/BannedDialog.jsx';
import AddToHomeScreen from './pages/iOS/AddToHomeScreen.jsx';
import UseSafariOnIOS from './pages/iOS/UseSafariOnIOS.jsx';
import { CentryConfig } from './helper/CentryConfig';
import NewTrackDialog from './components/dialogs/NewTrackDialog';
import UpdateCollectionDialog from './components/dialogs/UpdateCollectionDialog.jsx';
import SurveyDialog from './components/dialogs/SurveyDialog.jsx';
import DisableDownloadDialog from './components/dialogs/DisableDownloadDialog.jsx';
import RedirectToTelegramDialog from './components/dialogs/RedirectToTelegramDialog.jsx';
// import UserCollectionHintDialog from "./components/dialogs/UserCollectionHintDialog.jsx";
// import NotifiedCollectionDialog from "./components/dialogs/NotifiedCollectionDialog.jsx";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isBanned = useSelector(selectIsBanned);
  const [a2hNeeded, setA2hNeeded] = useState(false);
  const [safariNeeded, setSafariNeeded] = useState(false);
  const { maintainMutation, upkeepEnable } = useUpkeep();
  const { downloadQualityDialogState } = downloadData();
  const closeDownloadDialogHandler = () => {
    dispatch(closeDownloadDialog());
  };
  //handling to redirect to support if user is banned
  useEffect(() => {
    console.log('app loaded...');
    maintainMutation.mutate();
    // step 1 :check for upkeep in database
    // if it didnt exist in the database insert default url
    //send maintain request
    if (isBanned) {
      navigate('/tickets', { state: { banned: true } });
      dispatch(setIsBanned({ is_banned: false }));
    }
  }, [isBanned]);

  useEffect(() => {
    CentryConfig();
  }, []);

  return (
    <>
      {!upkeepEnable && !a2hNeeded && !safariNeeded && <RouteSystem />}

      <DialogsContainer>
        <BannedDialog />
        <AddToHomeScreen setA2hNeeded={setA2hNeeded} />
        <UseSafariOnIOS setSafariNeeded={setSafariNeeded} />
        <RedirectToTelegramDialog/>
        {/*<InstallPWAButton/>*/}
        <UpdateDialog />
        <NewTrackDialog />
        <CustomDialog />
        <IntroductionDialog />
        <AccountThresholdDialog />
        <ExpireDialog />
        <DownloadQualityBottomSheet
          OpenDownloadQualityModal={downloadQualityDialogState}
          CloseDownloadQualityModal={closeDownloadDialogHandler}
        />
        <LimitationDialog />
        <GlobalUnfollowDialog />
        <UpdateCollectionDialog />
        {/*<UserCollectionHintDialog />*/}
        <SurveyDialog />
        <DisableDownloadDialog />
      </DialogsContainer>
      {/*<NotifiedCollectionDialog />*/}
      <div className=" mb-[100px]">
        <Toaster containerClassName="mb-[100px]" reverseOrder={false} />
      </div>
    </>
  );
}

export default App;
