import React from 'react';
import MusicPlaceholderSvg from '../svg/common/MusicPlaceholderSvg.jsx';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';

function ExclusiveTrackCardImage({item}) {
    return (
      <div className='pt-2'>
        <div className='w-[104px] h-[104px]  rounded-[10px] relative'>
          <div
            className="rounded-[10px]  overflow-hidden top-0 left-0 absolute  ">
            <MusicPlaceholderSvg className="absolute top-0 left-0"/>
          </div>
          {item?.image && (
              <CustomLazyLoadImage
                  hasEffect={true}
                  image={item?.image}
                  imageClass=" object-cover rounded-[10px] z-1"
              />
          )}
        </div>
      </div>
    );
}

export default ExclusiveTrackCardImage;
