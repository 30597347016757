import React from "react";
import FileAttachmentSvg from "../svg/support/FileAttachmentSvg.jsx";
import { formatBytes } from "../../helper/ConvertBytes.js";
import DeleteIconSvg from "../svg/support/DeleteIconSvg.jsx";
import Marquee from "react-easy-marquee";
import { memo } from "react";
import UploadingIconSvg from "../svg/tickets/UploadingIconSvg.jsx";
import {setLanguageDirection} from "../../helper/LanguageDetection.js";
import TextMarquee from "../common/TextMarquee.jsx";

function SelectedItem({ item, index, deleteItem, loading }) {
  return (
    <div
      dir="rtl"
      className="w-full bg-gray_1 flex justify-between items-center flex-row rounded-[10px]  p-2 "
    >
      <div className="flex  w-[70px]">
        <p
          dir="ltr"
          className=" text-left font-dana font-medium text-gray_4 text-xs w-max"
        >
          {formatBytes(item?.size, 2)}
        </p>
      </div>
      <div className="flex flex-[3]  max-w-[calc(100%-120px)] me-1">
        {/* TODO ADD Marquee */}
        {/* <Marquee height={"25px"} duration={8000} className="marquee-container"> */}

        <p dir='ltr' className=" line-clamp-1 text-gray_4 text-left text-[10px] font-dana font-medium w-full">
          <TextMarquee direction={'ltr'} >
          {item?.name}
          </TextMarquee>
        </p>

        {/* </Marquee> */}
      </div>
      <div className="flex items-center justify-end gap-2 w-[60px]">
        {loading ? (
          <div className="h-[38px] w-[38px]">
            <UploadingIconSvg />
          </div>
        ) : (
          <div
            className="h-[38px] w-[38px]"
            onClick={() => {
              deleteItem(index);
            }}
          >
            <DeleteIconSvg />
          </div>
        )}

        <p className="text-sm font-dana text-gray_5 w-4">{index + 1}</p>
      </div>
    </div>
  );
}

export default memo(SelectedItem);
