import React from "react";

const MelodifySvgNoText = ({ opacity = false, logoStyle }) => {
    return (<div className={`${logoStyle}  ${opacity ? "opacity-60" : "opacity-100"}`}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="85"
            fill="none"
            viewBox="0 0 70 85"
        >
            <g filter="url(#filter0_dd_62_1587)">
                <path
                    fill="#F2C94C"
                    d="M32.044 61.866c16.531 0 29.933-13.401 29.933-29.933S48.575 2 32.044 2C15.512 2 2.11 15.402 2.11 31.933c0 16.532 13.402 29.933 29.934 29.933z"
                ></path>
            </g>
            <g filter="url(#filter1_f_62_1587)">
                <path
                    fill="#823600"
                    fillOpacity="0.95"
                    fillRule="evenodd"
                    d="M53.052 10.608c11.506 11.704 11.445 30.52-.184 42.147-11.629 11.63-30.447 11.69-42.15.18l.183.185c11.69 11.69 30.642 11.69 42.332 0 11.69-11.69 11.69-30.642 0-42.332l-.181-.18z"
                    clipRule="evenodd"
                ></path>
            </g>
            <g filter="url(#filter2_f_62_1587)">
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M11.32 53.494C-.166 41.79-.098 22.99 11.523 11.368c11.636-11.636 30.47-11.69 42.171-.16a30.254 30.254 0 00-.203-.205c-11.69-11.69-30.642-11.69-42.332 0-11.69 11.69-11.69 30.642 0 42.331l.16.16z"
                    clipRule="evenodd"
                ></path>
            </g>
            <path
                fill="url(#paint0_linear_62_1587)"
                d="M41.087 31.692c0 .856-.121 1.686-.346 2.464-1.072 3.727-4.505 6.45-8.57 6.45a8.915 8.915 0 01-8.914-8.914c0-2.084.709-3.995 1.91-5.517l2.033 2.032a6.046 6.046 0 00-1.098 3.485c0 .873.19 1.712.518 2.464a6.078 6.078 0 005.552 3.614 6.078 6.078 0 005.551-3.614 6.02 6.02 0 00.519-2.464 6.047 6.047 0 00-1.228-3.658l2.023-2.023a8.855 8.855 0 012.05 5.68z"
            ></path>
            <path
                fill="url(#paint1_linear_62_1587)"
                d="M38.238 31.692c0 .873-.19 1.712-.519 2.464a6.078 6.078 0 01-5.551 3.614 6.078 6.078 0 01-5.552-3.614 6.02 6.02 0 01-.518-2.464c0-1.297.406-2.5 1.098-3.485l3.165 3.165a2.338 2.338 0 003.311 0l3.347-3.347a6.084 6.084 0 011.219 3.667z"
            ></path>
            <path
                fill="url(#paint2_linear_62_1587)"
                fillRule="evenodd"
                d="M47.638 17.407c-.649-.7-1.34-1.357-2.084-1.971 0 0-.009 0-.009-.009-.147-.112-.285-.233-.432-.346 0 0-.009-.008-.017-.008A5.328 5.328 0 0042 14.078a5.35 5.35 0 00-5.335 4.99v.207h4.073a2.1 2.1 0 011.193.398c.112.078.207.173.303.268a2.085 2.085 0 01.008 2.836l-1.79 1.824-1.41 1.41a8.854 8.854 0 012.05 5.68c0 .857-.12 1.687-.346 2.465-1.072 3.727-4.505 6.45-8.569 6.45a8.915 8.915 0 01-8.915-8.914c0-2.084.71-3.995 1.911-5.517l-1.504-1.487-1.73-1.73.009-.008a2.108 2.108 0 01-.06-3.096c.112-.112.242-.207.38-.285 0 0 .008 0 .008-.009.26-.155.554-.25.865-.276.06-.01.13-.01.19-.01s.121 0 .182.01h4.16v-.104a5.373 5.373 0 00-5.37-5.05c-1.229 0-2.37.415-3.278 1.107-.086.069-.173.138-.26.216a20.486 20.486 0 00-2.213 2.119 20.975 20.975 0 00-5.439 14.12c0 11.63 9.425 21.055 21.056 21.055 11.63 0 21.055-9.425 21.055-21.055a20.99 20.99 0 00-5.586-14.285zM22.579 44.662c.208.139.467.225.744.225a1.36 1.36 0 001.349-1.34c0-.415-.19-.778-.476-1.03l.009.01a.477.477 0 00-.07-.052h-.008c-.017-.018-.043-.026-.06-.044a13.432 13.432 0 01-5.111-8.257c-.026-.121-.043-.234-.06-.346-.01-.052-.018-.095-.026-.147v-.009c-.026-.13-.07-.26-.139-.372-.017-.034-.043-.06-.06-.095-.026-.026-.044-.06-.07-.086-.025-.035-.051-.06-.077-.087l-.044-.043a1.375 1.375 0 00-.933-.372c-.096 0-.173.009-.26.026-.138.026-.268.07-.389.138a1.339 1.339 0 00-.692 1.176c0 .035.009.078.009.113 0 .06.017.112.026.173a16.15 16.15 0 006.252 10.359c.034.017.06.043.086.06z"
                clipRule="evenodd"
            ></path>
            <path
                fill="url(#paint3_linear_62_1587)"
                fillRule="evenodd"
                d="M32.092 40.606v12.14c-11.595-.04-20.979-9.45-20.979-21.054 0-5.43 2.058-10.385 5.44-14.12a20.476 20.476 0 012.213-2.12c.086-.077.173-.146.26-.215a5.403 5.403 0 013.276-1.107 5.373 5.373 0 015.37 5.05v.103h-4.159c-.06-.008-.121-.008-.182-.008-.06 0-.13 0-.19.008a2 2 0 00-.865.277c0 .009-.008.009-.008.009a1.752 1.752 0 00-.38.285 2.108 2.108 0 00.06 3.096l-.009.008 1.73 1.73 1.504 1.487a8.854 8.854 0 00-1.91 5.517 8.915 8.915 0 008.83 8.914zm-8.77 4.281c-.276 0-.535-.087-.743-.225-.01-.006-.018-.013-.028-.021a.34.34 0 00-.058-.04 16.15 16.15 0 01-6.263-10.42.548.548 0 01-.015-.111.522.522 0 00-.004-.056c-.002-.02-.005-.04-.005-.057 0-.51.277-.942.692-1.176.121-.069.25-.112.39-.138.086-.017.163-.026.259-.026.363 0 .691.147.933.372l.044.043c.026.026.052.052.078.087a.338.338 0 01.034.043c.01.015.022.03.035.043.008.017.02.032.03.047.01.016.022.03.03.048.07.113.113.242.139.372v.009a5.111 5.111 0 00.025.147c.018.112.035.224.061.345a13.432 13.432 0 005.11 8.258c.009.009.02.015.03.022a.15.15 0 01.03.021h.01a.484.484 0 01.069.052l-.009-.008c.285.25.476.614.476 1.029a1.36 1.36 0 01-1.35 1.34z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#212121"
                fillRule="evenodd"
                d="M23.271 31.316H11.118a20.973 20.973 0 015.436-13.745 20.476 20.476 0 012.213-2.118c.087-.078.173-.147.26-.216a5.403 5.403 0 013.277-1.107 5.373 5.373 0 015.37 5.05v.103h-4.16c-.06-.008-.12-.008-.181-.008-.06 0-.13 0-.19.008a2 2 0 00-.865.277c0 .009-.009.009-.009.009a1.75 1.75 0 00-.38.285 2.108 2.108 0 00.06 3.096l-.008.008 1.73 1.73 1.504 1.487a8.847 8.847 0 00-1.904 5.141z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F2C94C"
                d="M5.93 83.187a3.816 3.816 0 01-1.973-.534 3.877 3.877 0 01-1.44-1.423A3.908 3.908 0 012 79.258v-3.752h1.989v3.639c0 .539.188.997.566 1.374.388.377.846.566 1.374.566h3.638c.313 0 .56-.102.744-.307.194-.194.291-.442.291-.744 0-.237-.07-.448-.21-.63a.936.936 0 00-.582-.373l-4.043-.937V76.12c0-.582.151-1.127.453-1.633.302-.507.712-.911 1.23-1.213a3.25 3.25 0 011.665-.453h2.845v1.99H9.082c-.366 0-.679.134-.938.403a1.26 1.26 0 00-.388.922v.372l2.7.614a2.896 2.896 0 011.585 1.116c.42.56.63 1.18.63 1.86 0 .56-.14 1.072-.42 1.536-.28.474-.657.851-1.131 1.131-.475.28-.992.42-1.553.42H5.93zM17.148 79.549c-.538 0-1.034-.13-1.487-.388a3.02 3.02 0 01-1.051-1.067 2.946 2.946 0 01-.388-1.488v-7.875h1.989v7.746c0 .27.091.5.275.695.183.183.41.275.679.275h.824c.108 0 .162.054.162.162v1.778c0 .108-.054.162-.162.162h-.84z"
            ></path>
            <path
                fill="#F2C94C"
                d="M22.09 69.588a.93.93 0 110 1.86.93.93 0 010-1.86zm4.083 7.859c.108 0 .162.054.162.162v1.778c0 .108-.054.162-.162.162H17.99c-.108 0-.162-.054-.162-.162V77.61c0-.108.054-.162.162-.162h.954a3.321 3.321 0 01-.275-1.342c0-.626.151-1.191.453-1.698a3.274 3.274 0 011.229-1.197 3.526 3.526 0 011.73-.436c.614 0 1.18.145 1.698.436.528.291.943.69 1.245 1.197.312.507.469 1.073.469 1.698 0 .474-.092.921-.275 1.342h.954zm-5.4-1.278c0 .367.123.674.371.922.248.237.56.356.938.356.377 0 .69-.119.938-.356.248-.248.372-.555.372-.922 0-.366-.13-.673-.388-.921a1.252 1.252 0 00-.922-.372c-.367 0-.68.124-.938.372a1.252 1.252 0 00-.372.921z"
            ></path>
            <path
                fill="#F2C94C"
                d="M26.17 79.549c-.107 0-.161-.054-.161-.162V77.61c0-.108.054-.162.162-.162h1.568c.259 0 .48-.092.663-.275a.977.977 0 00.275-.695V73.29h1.989v3.315c0 .539-.13 1.035-.388 1.488a2.977 2.977 0 01-1.068 1.067 2.858 2.858 0 01-1.471.388h-1.568zm-.68 2.369a.93.93 0 01.93-.93h1.567v1.86H26.42a.93.93 0 01-.93-.93zm.77-.93h3.497a.93.93 0 110 1.86h-3.496v-1.86zM36.104 77.447c.28 0 .512-.087.695-.259a.87.87 0 00.275-.663.774.774 0 00-.162-.485c-.097-.15-.27-.307-.517-.469l-3.267-2.247 1.132-1.633 3.59 2.473c.388.302.684.636.89 1.003.215.356.323.787.323 1.293a3.027 3.027 0 01-1.569 2.685 3.178 3.178 0 01-1.568.404h-3.752v-2.102h3.93zM47.902 77.447c.108 0 .162.054.162.162v1.778c0 .108-.054.162-.162.162h-.792c-.087.733-.302 1.342-.647 1.827-.345.496-.836.932-1.471 1.31-.636.388-1.526.83-2.668 1.326l-.615-1.747c.981-.42 1.709-.765 2.183-1.035.474-.258.803-.517.986-.776.194-.248.291-.55.291-.905h-1.277c-.625 0-1.202-.14-1.73-.42a3.313 3.313 0 01-1.229-1.181 3.14 3.14 0 01-.453-1.665c0-.615.151-1.18.453-1.698a3.447 3.447 0 011.245-1.245 3.306 3.306 0 011.714-.47 3.03 3.03 0 011.65.47c.506.302.9.717 1.18 1.245.29.517.436 1.083.436 1.698v1.164h.744zm-2.733-1.164c0-.367-.123-.674-.371-.922a1.207 1.207 0 00-.906-.388c-.367 0-.68.124-.938.372-.248.248-.372.56-.372.938 0 .366.124.673.372.921.259.248.572.372.938.372h1.277v-1.293z"
            ></path>
            <path
                fill="#F2C94C"
                d="M47.898 79.549c-.108 0-.161-.054-.161-.162V77.61c0-.108.053-.162.161-.162h.712c.27 0 .496-.092.679-.275a.922.922 0 00.275-.68v-7.76h1.989v7.76c0 .27.086.497.258.68.184.183.41.275.68.275h.71c.109 0 .163.054.163.162v1.778c0 .108-.054.162-.162.162h-.712c-.42 0-.797-.086-1.132-.259a2.236 2.236 0 01-.808-.744c-.205.313-.474.56-.808.744a2.431 2.431 0 01-1.132.259h-.712z"
            ></path>
            <path
                fill="#F2C94C"
                d="M58.427 72.903c.614 0 1.18.146 1.697.437.529.28.944.673 1.245 1.18a3.03 3.03 0 01.47 1.65c0 .614-.157 1.185-.47 1.713a3.446 3.446 0 01-1.245 1.245 3.31 3.31 0 01-1.697.453c-.453 0-.874-.08-1.262-.242a3.006 3.006 0 01-1.018-.696 2.42 2.42 0 01-.841.663c-.323.162-.69.243-1.1.243h-1.002c-.108 0-.162-.054-.162-.162V77.61c0-.108.054-.162.162-.162h1.002c.27 0 .496-.092.68-.275a.923.923 0 00.274-.68V72.904h3.267zm0 4.576c.377 0 .69-.124.937-.372.248-.258.372-.571.372-.938 0-.366-.124-.668-.372-.905a1.271 1.271 0 00-.937-.372h-1.294v1.277a1.271 1.271 0 001.294 1.31z"
            ></path>
            <defs>
                <filter
                    id="filter0_dd_62_1587"
                    width="69.866"
                    height="69.866"
                    x="0.11"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.491667 0 0 0 0 0.256666 0 0 0 0 0.0397431 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_62_1587"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.5925 0 0 0 0 0.0933187 0 0 0 0 0.0933187 0 0 0 0.2 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_62_1587"
                        result="effect2_dropShadow_62_1587"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_62_1587"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_f_62_1587"
                    width="57.282"
                    height="57.279"
                    x="7.718"
                    y="7.608"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feGaussianBlur
                        result="effect1_foregroundBlur_62_1587"
                        stdDeviation="1.5"
                    ></feGaussianBlur>
                </filter>
                <filter
                    id="filter2_f_62_1587"
                    width="55.303"
                    height="55.259"
                    x="0.392"
                    y="0.235"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feGaussianBlur
                        result="effect1_foregroundBlur_62_1587"
                        stdDeviation="1"
                    ></feGaussianBlur>
                </filter>
                <linearGradient
                    id="paint0_linear_62_1587"
                    x1="32.172"
                    x2="32.172"
                    y1="26.011"
                    y2="40.607"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E8B007"></stop>
                    <stop offset="0.066" stopColor="#E6A80B"></stop>
                    <stop offset="0.43" stopColor="#FFCA27"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_62_1587"
                    x1="37.089"
                    x2="27.761"
                    y1="27.985"
                    y2="37.313"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.167" stopColor="#333"></stop>
                    <stop offset="0.426" stopColor="#656565"></stop>
                    <stop offset="0.726" stopColor="#292929"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_62_1587"
                    x1="53.413"
                    x2="29.427"
                    y1="28.651"
                    y2="52.638"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.144" stopColor="#333"></stop>
                    <stop offset="0.352" stopColor="#515151"></stop>
                    <stop offset="0.506" stopColor="#868686"></stop>
                    <stop offset="0.68" stopColor="#545252"></stop>
                    <stop offset="0.831" stopColor="#292929"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_62_1587"
                    x1="16.101"
                    x2="32.092"
                    y1="50.639"
                    y2="40.644"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#898989"></stop>
                    <stop offset="0.055" stopColor="#343434"></stop>
                    <stop offset="0.816" stopColor="#292929"></stop>
                    <stop offset="1" stopColor="#4F4E4E"></stop>
                </linearGradient>
            </defs>
        </svg>
    </div>)
}
export default MelodifySvgNoText
