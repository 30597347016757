import React from "react";

function AlbumCardPlaceHolder(props) {
  return (
    // <svg
    //     className="w-full h-full "
    //     xmlns="http://www.w3.org/2000/svg"
    //     fill="none"
    //     viewBox="0 0 52 52"
    // >
    //     <rect
    //         width="52"
    //         height="52"
    //         fill="#242429"

    //         transform="matrix(-1 0 0 1 52 0)"
    //     ></rect>
    //     <path
    //         stroke="#4C4C50"
    //         strokeLinecap="round"
    //         strokeWidth="2"
    //         d="M35.793 23.966c.136.657.207 1.337.207 2.034 0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10a9.996 9.996 0 018.69 5.05M23.227 27.852a3.333 3.333 0 112.261 1.443"
    //     ></path>
    // </svg>

    <svg
      className="w-full h-full "
      viewBox="0 0 119 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H114C116.761 0 119 2.23858 119 5V114C119 116.761 116.761 119 114 119H5C2.23858 119 0 116.761 0 114V5Z"
        fill="url(#pattern0)"
      />
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H114C116.761 0 119 2.23858 119 5V114C119 116.761 116.761 119 114 119H5C2.23858 119 0 116.761 0 114V5Z"
        fill="#212121"
      />
      <path
        d="M80.1545 54.6924C79.4038 54.6924 78.7554 54.9661 78.207 55.5136C77.6588 56.0625 77.3852 56.712 77.3852 57.461V63.0005C77.3852 68.3383 75.4879 72.9037 71.695 76.6966C67.9014 80.4894 63.3362 82.3858 57.9999 82.3858C52.6629 82.3858 48.0975 80.4894 44.3047 76.6966C40.511 72.9037 38.6146 68.3383 38.6146 63.0005V57.4618C38.6146 56.7128 38.3401 56.0642 37.7927 55.5143C37.2445 54.967 36.595 54.6932 35.8452 54.6932C35.0946 54.6932 34.4451 54.967 33.8979 55.5143C33.3496 56.0633 33.0759 56.7128 33.0759 57.4618V63.0005C33.0759 69.3763 35.2023 74.9216 39.4584 79.6374C43.7127 84.355 48.9705 87.0597 55.2305 87.7502V93.463H44.1533C43.4027 93.463 42.7532 93.7352 42.2058 94.2842C41.6576 94.8315 41.3839 95.4809 41.3839 96.2315C41.3839 96.9805 41.6576 97.6291 42.2058 98.179C42.7532 98.7265 43.4026 99.0001 44.1533 99.0001H71.8465C72.5965 99.0001 73.2459 98.7265 73.794 98.179C74.3414 97.63 74.6158 96.9805 74.6158 96.2315C74.6158 95.4809 74.3414 94.8323 73.794 94.2842C73.2459 93.7352 72.5965 93.463 71.8465 93.463H60.7693V87.7502C67.0286 87.0586 72.2853 84.3542 76.5415 79.6374C80.7958 74.9215 82.9239 69.3763 82.9239 63.0005V57.4618C82.9239 56.7128 82.6492 56.0642 82.1018 55.5143C81.5531 54.9668 80.9044 54.6924 80.1545 54.6924Z"
        fill="#4F4F4F"
      />
      <path
        d="M51.4734 53.958H44.1533V59.0366H51.4734C52.5917 59.0366 53.4978 59.9427 53.4978 61.061C53.4978 62.1793 52.5917 63.0853 51.4734 63.0853H44.1574C44.1776 66.8563 45.53 70.0888 48.2207 72.7796C50.9317 75.4923 54.1925 76.8461 57.9999 76.8461C61.8074 76.8461 65.0674 75.4923 67.7793 72.7796C70.4692 70.0888 71.8223 66.8563 71.8424 63.0853H64.7816C63.6632 63.0853 62.7572 62.1793 62.7572 61.061C62.7572 59.9427 63.6632 59.0366 64.7816 59.0366H71.8465V53.958H64.7816C63.6632 53.958 62.7572 53.0518 62.7572 51.9336C62.7572 50.8155 63.6632 49.9093 64.7816 49.9093H71.8465V44.5115H64.7816C63.6632 44.5115 62.7572 43.6053 62.7572 42.4871C62.7572 41.369 63.6632 40.4628 64.7816 40.4628H71.8296C71.7407 36.8215 70.398 33.6869 67.7802 31.0665C65.0682 28.3555 61.8082 27 58.0007 27C54.1925 27 50.9325 28.3555 48.2215 31.0665C45.6028 33.6867 44.261 36.8213 44.172 40.4628H51.475C52.5933 40.4628 53.4994 41.3689 53.4994 42.4871C53.4994 43.6054 52.5933 44.5115 51.475 44.5115H44.1549V49.9093H51.475C52.5933 49.9093 53.4994 50.8153 53.4994 51.9336C53.4994 53.0519 52.5917 53.958 51.4734 53.958Z"
        fill="#4F4F4F"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlink:href="#image0_1_27" transform="scale(0.000705219)" />
        </pattern>
        <image id="image0_1_27" width="1418" height="1418" />
      </defs>
    </svg>
  );
}

export default AlbumCardPlaceHolder;
