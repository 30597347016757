import React from "react";
import MedalSVG from "../components/svg/common/MedalSVG.jsx";
import RetrySVG from "../components/svg/common/RetrySVG.jsx";
import SimpleEarSVG from "../components/svg/common/SimpleEarSVG.jsx";
import EarSVGType2 from "../components/svg/common/EarSVGType2.jsx";
import HeartSvg from "../components/svg/common/HeartSvg.jsx";
import FireFlameSVG from "../components/svg/common/FireFlameSVG.jsx";
import AddToPlayListSvg from "../components/svg/queue/AddToPlayListSvg.jsx";
import ClockSvg from "../components/svg/common/ClockSvg.jsx";
import TrashSVG from "../components/svg/common/TrashSVG.jsx";
import TelegramSvgIcon from "../components/svg/common/TelegramSvgIcon.jsx";
import SaveInDeviceSvgIcon from "../components/svg/common/SaveInDeviceSvgIcon.jsx";
import SendToTelegramLoader from "../components/loading/SendToTelegramLoader.jsx";
import ErrorReportFlag from "../components/svg/artists/ErrorReportFlag.jsx";
import PlayerOptionBottomSheet from "../components/player/PlayerOptionBottomSheet.jsx";
import GoArtistSvg from "../components/svg/queue/GoArtistSvg.jsx";
import BroadcastQualitySvg from "../components/svg/setting/BroadcastQualitySvg.jsx";
import SendAppSvg from "../components/svg/setting/SendAppSvg.jsx";

export const ContextMenuKeys = {
    REMOVE_FROM_DOWNLOAD: "remove_from_download",
    SAVE_IN_DEVICE: "save_in_device",
    LIKE_TRACK: "like_track",
    SEND_TO_TELEGRAM: "send_to_telegram",
    ADD_TO_PLAYLIST: "add_to_playlist",

}
export const playerOptionsBottomSheetKeys = {
    SEND_REPORT: "send_report",
    ADD_TO_PLAYLIST: "add_to_playlist",
    GO_TO_ARTIST: "go_to_artist",
    REMOVE_FROM_DOWNLOAD: "remove_from_download",
    SEND_TO_TELEGRAM: "send_to_telegram",
    STREAM_QUALITY: "stream_quality",
    SHARE_TRACK: "share_track",
}
export const playListSortTypes = [
    {
        id: "1",
        title: "پیشنهادی ملودیفای ",
        subtitle: "به ترتیب پیشنهادی ملودیفای گوش دهید",
        icon: MedalSVG,
        type: "melodify"
    },
    {
        id: "2",
        title: "اخیرا افزوده شده ",
        subtitle: "جدیدترین اهنگهای پلی لیست را بشنوید",
        icon: RetrySVG,
        type: "newly_added"
    },
    {
        id: "5",
        title: "محبوب ترین‌های من ",
        subtitle: "به ترتیب آهنگ‌های موردعلاقه شما",
        icon: HeartSvg,
        type: "my_favorite"
    },
    {
        id: "3",
        title: "بیشتر شنیده های من ",
        subtitle: "به ترتیب آهنگ‌هایی که بیشتر شنیده‌اید",
        icon: SimpleEarSVG,
        type: "most_listened"
    },
    {
        id: "4",
        title: "کمتر شنیده‌های من ",
        subtitle: "به ترتیب آهنگ هایی که کمتر شنیده‌اید یا نشنیده‌اید",
        icon: EarSVGType2,
        type: "not_listened",
    },

    {
        id: "6",
        title: "محبوب ترین‌ها ",
        subtitle: "به ترتیب محبوب ترین آهنگ‌های کاربران ملودیفای",
        icon: FireFlameSVG,
        type: "hot"
    },
];
export const simplePlayListSortTypes = [
    {
        id: "1",
        title: "پیشنهادی ملودیفای ",
        subtitle: "به ترتیب پیشنهادی ملودیفای گوش دهید",
        icon: MedalSVG,
        type: "melodify"
    },
    {
        id: "6",
        title: "محبوب ترین‌ها ",
        subtitle: "به ترتیب محبوب ترین آهنگ‌های کاربران ملودیفای",
        icon: FireFlameSVG,
        type: "hot"
    },

];
export const userPlaylistSortTypes = [
    {
        id: "1",
        title: "ترتیب دلخواه من ",
        subtitle: 'با ویرایش پلی‌لیست میتوانید این ترتیب را تعیین کنید.',
        icon: MedalSVG,
        type: "my_sort"
    },
    {
        id: "2",
        title: "اخیرا افزوده شده ",
        subtitle: "جدیدترین اهنگهای پلی لیست را بشنوید",
        icon: RetrySVG,
        type: "newly_added"
    },
    {
        id: "5",
        title: "محبوب ترین‌های من ",
        subtitle: "به ترتیب آهنگ‌های موردعلاقه شما",
        icon: HeartSvg,
        type: "my_favorite"
    },
    {
        id: "3",
        title: "بیشتر شنیده های من ",
        subtitle: "به ترتیب آهنگ‌هایی که بیشتر شنیده‌اید",
        icon: SimpleEarSVG,
        type: "most_listened"
    },
    {
        id: "4",
        title: "کمتر شنیده‌های من ",
        subtitle: "به ترتیب آهنگ هایی که کمتر شنیده‌اید یا نشنیده‌اید",
        icon: EarSVGType2,
        type: "not_listened",
    },

    {
        id: "6",
        title: "محبوب ترین‌ها ",
        subtitle: "به ترتیب محبوب ترین آهنگ‌های کاربران ملودیفای",
        icon: FireFlameSVG,
        type: "hot"
    },

];

export const sortTypesArtist = [
    {
        id: "2",
        title: "جدیدترین ها",
        icon: RetrySVG,
        subtitle: "جدیدترین آهنگ های خواننده را بشنوید",
        type: "new"
    },
    {
        id: "7",
        title: "قدیمی ترین ها ",
        subtitle: "قدیمی‌ترین آهنگ های خواننده را بشنوید",
        icon: ClockSvg,
        type: "old"
    },
    {
        id: "6",
        title: "محبوب ترین ها ",
        subtitle: "محبوب ترین آهنگ‌های کاربران ملودیفای",
        icon: HeartSvg,
        type: "popular"
    }

];
export const menuItemsGroups = [
    {
        id: 9,
        key: ContextMenuKeys.REMOVE_FROM_DOWNLOAD,
        title: "حذف از لیست دانلود ها",
        svg: TrashSVG,
        iconColor: '#EB5757',
        textColor: '#EB5757',
        active: false,
        actionType: "removeDownloadList",
        submenu: null,
        defaultConfig: null,
    },
    {
        id: 10,
        key: ContextMenuKeys.SAVE_IN_DEVICE,
        title: "ذخیره در دستگاه",
        svg: SaveInDeviceSvgIcon,
        active: false,
        actionType: "saveDownloadedFile",
        submenu: null,
        defaultConfig: null,
    },
    {
        id: 11,
        key: ContextMenuKeys.LIKE_TRACK,
        title: "لایک کردن",
        secondTitle: "دیس لایک کردن",
        svg: HeartSvg,
        active: false,
        actionType: "likeTrack",
        submenu: null,
        defaultConfig: null,
    },

    {
        id: 12,
        key: ContextMenuKeys.SEND_TO_TELEGRAM,
        title: "ارسال به تلگرام من",
        secondTitle: "ارسال به تلگرام من",
        svg: TelegramSvgIcon,
        secondSvg: SendToTelegramLoader,
        active: false,
        actionType: "sendToTelegram",
        submenu: null,
        defaultConfig: null,
    },
    {
        id: 2,
        key: ContextMenuKeys.ADD_TO_PLAYLIST,
        title: "افزودن به پلی لیست",
        svg: AddToPlayListSvg,
        iconColor: '#E0E0E0',
        active: true,
        actionType: "openAddPlaylist",
        submenu: null,
        defaultConfig: null,
    },
]

export const playerBottomSheetItems = [
    {
        id: 1,
        key: playerOptionsBottomSheetKeys.SEND_REPORT,
        title: "گزارش خطا آهنگ",
        icon: ErrorReportFlag,
        iconColor: '#E0E0E0',
        textColor: '#E0E0E0',
        active: false,
        submenu: null,
        defaultConfig: null,
        paddingClass: 'pt-9 pb-4 px-4'
    },
    {
        id: 2,
        key: playerOptionsBottomSheetKeys.ADD_TO_PLAYLIST,
        title: "افزودن به پلی لیست",
        icon: AddToPlayListSvg,
        iconColor: '#E0E0E0',
        textColor: '#E0E0E0',
        active: false,
        submenu: null,
        defaultConfig: null,
        paddingClass: 'py-5 px-4'
    },
    {
        id: 3,
        key: playerOptionsBottomSheetKeys.GO_TO_ARTIST,
        title: "رفتن به خواننده",
        icon: GoArtistSvg,
        iconColor: '#E0E0E0',
        textColor: '#E0E0E0',
        active: false,
        submenu: null,
        defaultConfig: null,
        paddingClass: 'py-5 px-4'
    },
    {
        id: 4,
        key: playerOptionsBottomSheetKeys.REMOVE_FROM_DOWNLOAD,
        title: "حذف از لیست دانلود ها",
        icon: TrashSVG,
        iconColor: '#EB5757',
        textColor: '#EB5757',
        active: false,
        actionType: "removeDownloadList",
        submenu: null,
        defaultConfig: null,
        paddingClass: 'py-5 px-4'
    },
    {
        id: 5,
        key: playerOptionsBottomSheetKeys.SEND_TO_TELEGRAM,
        title: "ارسال به تلگرام من",
        secondTitle: "ارسال به تلگرام من",
        icon: TelegramSvgIcon,
        secondIcon: SendToTelegramLoader,
        iconColor: '#E0E0E0',
        textColor: '#E0E0E0',
        active: false,
        actionType: "sendToTelegram",
        submenu: null,
        defaultConfig: true,
        defaultConfigColor: '#169de2',
        paddingClass: 'py-5 px-4'

    },
    {
        id: 5,
        key: playerOptionsBottomSheetKeys.STREAM_QUALITY,
        title: "کیفیت پخش",
        icon: BroadcastQualitySvg,
        secondSvg: SendToTelegramLoader,
        iconColor: '#E0E0E0',
        textColor: '#E0E0E0',
        active: false,
        actionType: "sendToTelegram",
        submenu: null,
        defaultConfig: true,
        defaultConfigColor: '#828282',
        paddingClass: 'py-5 px-4'
    },
    {
        id: 7,
        key: playerOptionsBottomSheetKeys.SHARE_TRACK,
        title: "اشتراک گذاری آهنگ",
        icon: SendAppSvg,
        iconColor: '',
        textColor: '#E0E0E0',
        active: false,
        submenu: null,
        defaultConfig: null,
        paddingClass: 'pb-9 pt-4 px-4'
    },

];


