import React from "react";

function AllNewTracksSVG({color}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="none"
            viewBox="0 0 19 19"
        >
            <rect
                width="7.444"
                height="7.444"
                x="0.5"
                y="0.5"
                stroke={` ${color}`}
                rx="0.5"
            ></rect>
            <rect
                width="7.444"
                height="7.444"
                x="0.5"
                y="11.056"
                stroke={` ${color}`}
                rx="0.5"
            ></rect>
            <rect
                width="7.444"
                height="7.444"
                x="11.056"
                y="11.056"
                stroke={` ${color}`}
                rx="0.5"
            ></rect>
            <rect
                width="7.444"
                height="7.444"
                x="11.056"
                y="0.5"
                stroke={` ${color}`}
                rx="0.5"
            ></rect>
        </svg>
    );
}

export default AllNewTracksSVG;
