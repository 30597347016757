import React,{Fragment} from 'react';
import {Dialog, Transition} from "@headlessui/react";
import {globalData, useUserData} from "../../helper/ReduxSelectorContext.js";
import {useDispatch} from "react-redux";
import {useAction} from "../../hooks/useAction.js";
import {hideExpireSoonDialog} from "../../redux/globalData/globalDataSlice.js";
import ExpireSoonSvg from "../svg/common/ExpireSoonSvg.jsx";
import {CardActionArea} from "@mui/material";
import DialogHeader from "../dialogs/DialogHeader.jsx";

function AccountThresholdDialog() {
    const dispatch = useDispatch();
    const action = useAction();
    const {expireSoonDialog } = globalData();
    const data=useUserData()
    const closeDialog = () => {
        dispatch(hideExpireSoonDialog());
    };
    const openPlans = () => {
        dispatch(hideExpireSoonDialog());
        action({ target_type: "plans" });
    };
    return (
        <Transition appear show={expireSoonDialog} as={Fragment}>
            <Dialog as="div" className="relative z-[91]" onClose={closeDialog}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-50 " />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto bg-black bg-opacity-50">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel
                                dir="rtl"
                                className=" font-dana w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-secondary p-4  text-left align-middle shadow-xl transition-all">
                                <DialogHeader header_text={'تمدید اشتراک'} onCloseDialog={closeDialog} />
                                <div className="px-6 flex flex-col gap-4 mt-9 mt-r32 items-center text-white ">
                                    <div className='w-[46px] aspect-square'>
                                        <ExpireSoonSvg/>

                                    </div>
                                    <p className="text-lg text-center text-primary font-semibold  ">
                                        کاربر ویژه ملودیفای
                                    </p>
                                    <p className="text-base text-center font-medium ">
                                        برای استفاده از امکانات ویژه ملودیفای تنها
                                        <span className='text-red'> {data?.premium_days_left} </span>
                                        روز اعتبار دارید.
                                    </p>
                                </div>
                                <div
                                    className={"my-4 flex items-center justify-center gap-3"}
                                >
                                    <CardActionArea className="!rounded-[100px] !w-[200px]">
                                        <button
                                            onClick={() => openPlans()}
                                            type="button"
                                            className={"text-center inline-flex text-[16px] bg-primary font-semibold w-full justify-center rounded-[100px] px-4 py-3 focus:outline-none"}
                                        >
                                            تمدید اشتراک
                                        </button>
                                    </CardActionArea>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default AccountThresholdDialog;
