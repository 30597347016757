import React from 'react'

function LeftArrowSvg() {
  return (
      <svg
         className='w-full h-full'
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"

      >
          <path
              d="M15.5 8.01172H1.5M1.5 8.01172L8.5 15.0117M1.5 8.01172L8.5 1.01172"
              stroke="#F2C94C"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
          />
      </svg>
  );
}

export default LeftArrowSvg
