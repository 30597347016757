import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCustomDialog } from '../../redux/dialog/DialogSlice';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useAction } from '../../hooks/useAction';
import DialogHeader from './DialogHeader.jsx';
import { CardActionArea } from '@mui/material';
import { notificationDB } from '../../indexDB/DB';
import { getNewTrackDialog } from '../../helper/ReduxSelectorContext';
import ClickReaction from '../../helper/ClickReaction';
import { usePlayedTrack } from '../../hooks/usePlayedTrack';
import { PlayListType } from '../../consts/PlayListType';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

function NewTrackDialog() {
  const [open, setOpen] = useState(false);
  const dialogData = getNewTrackDialog();

  const [, setPlayerTrack] = usePlayedTrack();
  const handleCloseLogout = () => {
    if (dialogData?.dismissible === false) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
  
    if (dialogData) setOpen(true);
    return () => {
      notificationDB.notif.clear();
    };
  }, [dialogData]);

  const CloseCustomDialog = () => {
    console.log(dialogData);
    if (dialogData?.dismissible === false) {
      return;
    }
    setOpen(false);
  };
  const handlePlayNewTrack = () => {
    console.log('sdgsdgdsgdsgdsg', dialogData?.track);
    setTimeout(() => {
      setPlayerTrack(
        dialogData?.track,
        dialogData?.playList,
        PlayListType.PLAYLIST,
      );
    }, 100);
    setOpen(false);
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={CloseCustomDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto font-dana">
          <div className="flex min-h-full items-center justify-center p-4 text-center pb-12">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-secondary p-4  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={'آهنگ جدیدی منتشر شد'}
                  onCloseDialog={CloseCustomDialog}
                />
                <div className="px-2 flex flex-col items-center">
                  {dialogData?.main_title && (
                    <p className="text-primary text-[18px] text-center mb-4 mt-8">
                      {dialogData?.main_title}
                    </p>
                  )}
                  {dialogData?.track?.image && (
                    <CustomLazyLoadImage
                      alt=""
                      imageClass="!w-[200px] !h-[200px] rounded-3xl !relative"
                      image={dialogData?.track?.image}
                    />
                  )}
                  {dialogData?.track?.title && (
                    <p className="text-white text-[22px] mt-6">
                      {dialogData?.track?.title}
                    </p>
                  )}
                  {dialogData?.track?.artists?.length != 0 && (
                    <p className="text-gray_4 text-[18px] mt-3 text-center">
                      {dialogData?.track?.artists.map((artist, index) => {
                        return (
                          <span key={index}>
                            {artist?.name}{' '}
                            {index !== dialogData?.track?.artists?.length - 1 &&
                              (setLanguageDirection(
                                dialogData?.track?.title,
                              ) === 'rtl'
                                ? '، '
                                : ', ')}
                          </span>
                        );
                      })}
                    </p>
                  )}
                </div>
                <div
                  className={
                    'flex items-center justify-center mt-6   mb-4 z-30'
                  }
                >
                  <CardActionArea
                    onClick={() => handlePlayNewTrack()}
                    className="mt-4 !rounded-full !w-16"
                  >
                    <ClickReaction>
                      <div className="w-full flex items-center justify-center">
                        <svg
                          class="w-full  h-full"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1031_3710)">
                            <path
                              d="M20 0C8.95445 0 0 8.9543 0 20C0 31.0457 8.95445 40 20 40C31.0455 40 40 31.0457 40 20C40 8.9543 31.0455 0 20 0ZM26.9125 21.0602L16.9125 27.3102C16.7102 27.4365 16.4801 27.5 16.25 27.5C16.0416 27.5 15.8328 27.4481 15.6439 27.3431C15.2466 27.1228 15 26.7047 15 26.25V13.75C15 13.2953 15.2466 12.8772 15.6439 12.6569C16.0413 12.4353 16.5271 12.4487 16.9125 12.6898L26.9125 18.9398C27.2778 19.1687 27.5 19.5691 27.5 20C27.5 20.4309 27.2778 20.8313 26.9125 21.0602Z"
                              fill="#F2C94C"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1031_3710">
                              <rect width="40" height="40" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </ClickReaction>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default NewTrackDialog;
