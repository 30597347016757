import React from "react";

function ShowTicketTitle({ ticket, onShowNewAnswer, buttonState }) {
  return (
    <div class="flex flex-col gap-6 w-full relative z-10">
      <div
        dir="rtl"
        className="flex bg-ticket-header min-h-[10rem] items-center p-5 text-right box-content"
      >
        <div
          dir="rtl"
          id="ticketDetails"
          className="w-full font-dana flex flex-col gap-4"
        >
          <div>
            <p className="text-sm text-gray_4">عنوان :</p>
            <p className="text-sm text-white font-[700] break-words">
              {ticket?.subject}
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray_4">دسته بندی :</p>
              <p className="text-sm text-white font-[700]">
                {ticket?.faq_category?.title}
              </p>
            </div>
            <div className="status">
              <p className="text-sm text-gray_4">وضعیت :</p>
              <p
                className={`text-sm text-white font-[700]`}
                style={{ color: ticket?.ticket_status?.color }}
              >
                {ticket?.ticket_status?.title}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray_4">کد تیکت :</p>
              <p className="text-sm text-white font-[700] ">
                {ticket?.ticket_code}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray_4">تاریخ ایجاد :</p>
              <p className="text-sm text-white font-[700]">{ticket?.date}</p>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="flex justify-between items-center">*/}
      {/*  {!buttonState&&*/}
      {/*      <button*/}
      {/*          onClick={() => {*/}
      {/*            onShowNewAnswer()*/}
      {/*          }}*/}
      {/*          type="button"*/}
      {/*          className="inline-flex text-primary text-[16px]  bg-yellow w-full items-center  justify-center items-center rounded-[100px] border border-transparent  focus:outline-none gap-4 h-[48px] w-[155px]">*/}
      {/*        پاسخ جدید*/}
      {/*      </button>*/}
      {/*  }*/}
      {/*</div>*/}
    </div>
  );
}

export default ShowTicketTitle;
