import React from 'react';

function DisableSleepTimerSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                stroke="#F2C94C"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 5H8a7 7 0 000 14h8a7 7 0 100-14z"
            ></path>
            <path
                stroke="#F2C94C"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 15a3 3 0 100-6 3 3 0 000 6z"
            ></path>
        </svg>
    );
}

export default DisableSleepTimerSvg;
