import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { APP_VERSION } from "../../BASE.js";
import PrimaryButton from "../../components/common/PrimaryButton.jsx";
import Loading from "../../components/loading/Loading.jsx";
import LoginHeaderBackgroundImage from "../../components/login/LoginHeaderBackgroundImage.jsx";
import registerHeader from "../../assets/login/registerBackground.webp"
import { userData } from "../../redux/login/LoginSlice.js";
import { register } from "../../services/ApiClient.js";
import {CardActionArea} from "@mui/material";
import {mainToast} from "../../helper/ToastsEmitter.jsx";

function Register(props) {
  const [fullName, setfullName] = useState(null);
  const [refCode, setRefCode] = useState(null);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mutateRegister = useMutation((registerData) => register(registerData), {
    onSuccess: (res) => {
      if (res?.data?.done) {
        dispatchUserData(res?.data?.result?.user);
        navigate("/", { replace: true });
      }
    },
    onError:() => {
      setError('خطایی پیش آمده');
    }
  });
  const handleRegister = () => {
    if (!fullName) {
      mainToast('نام خود را وارد کنید')
      return;
    }
    setError(null)
    mutateRegister.mutate({
      full_name: fullName,
      app_version: APP_VERSION,
      ref_number: refCode,
    });
  };
  const dispatchUserData = (Data) => {
    dispatch(
      userData({
        user: Data,
      })
    );
  };

  return (
    <div className="font-danaMedium text-white bg-[#212121]  overflow-scroll max-w-[520px] h-screen">
      <header>
        <LoginHeaderBackgroundImage loginHeader={registerHeader}>
          <div className="h-[220px] px-[1.18rem] bg-cover  flex justify-end items-end ">
            <div dir="rtl" >
              <p className="font-[700] font-dana " >
                خوش آمدید
              </p>
              <p className="font-dana font-[400] text-[.870rem]">
                یک نام برای حساب کاربری خود وارد کنید.
              </p>
            </div>
          </div>
        </LoginHeaderBackgroundImage>
      </header>
      <main>
        <div className=" px-[1.18rem] flex flex-col mt-4 relative z-20">
          <div dir="rtl" className=" border flex items-center justify-start h-[3rem] border-gray-6 overflow-hidden rounded-[1.3rem] py-[.7rem]	 text-gray_6">
            <input
              onKeyPress={(e) => e.key === "Enter" && handleRegister()}
              dir="rtl"
              type="text"
              placeholder="نام کاربری"
              onChange={(e) => setfullName(e.target.value)}
              value={fullName}
              className="font-dana mx-[2.25rem]  font-[550] text-[.89rem] grow bg-transparent outline-none  "
            />

          </div>
          {error && (
            <div className="text-red font-[500] font-dana text-[.89rem]  mt-2 text-center ">
              {error}
            </div>
          )}

          <CardActionArea className={"!mt-2 !rounded-[1.3rem]"}>
            <PrimaryButton
            className="!text-[#333333] !font-dana !font-[600] !bg-primary !h-[2.875rem] !w-full !flex !items-center !tracking-tight !justify-center !text-[1.01rem] !rounded-[1.3rem]"
            onClick={() => handleRegister()}
          >
            {mutateRegister.isLoading ? <Loading bgColor={"#000000"} /> : "تایید"}
          </PrimaryButton>
          </CardActionArea>
        </div>
      </main>
    </div>
  );
}

export default Register;
