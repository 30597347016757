import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import DownloadWithHeadSvg from "../setting/DownloadWithHeadSvg.jsx";
import CloseDialogButton from "../svg/common/CloseDialogButton.jsx";
import { Downloadqualities } from "../../consts/downloadQuality.js";
import GreenCheck from "../svg/common/GreenCheck.jsx";
import SettingCrownSvg from "../svg/setting/SettingCrownSvg.jsx";
import { userData } from "../../redux/login/LoginSlice.js";
import {
  downloadData,
  getLimitations,
  useUserData,
} from "../../helper/ReduxSelectorContext.js";
import { useDispatch } from "react-redux";
import { useDownload } from "../../hooks/useDownload.js";
import {
  clearTemporaryDownloadQueue,
  closeDownloadDialog,
} from "../../redux/download/downloadSlice.js";
import { useNavigate } from "react-router-dom";
import { useAction } from "../../hooks/useAction.js";
import { setLimitationDialogData } from "../../redux/globalData/globalDataSlice.js";
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import DownloadQualitySvg from "../svg/setting/DownloadQualitySvg.jsx";
import QualityCard from "../setting/QualityCard.jsx";
import SwipeBottomSheet from "../bottomSheets/SwipeBottomSheet.jsx";
import Loading from "../loading/Loading.jsx";
import { dispatchSwipePlayerStatus } from "../../redux/globalstore/GlobalStore.js";
import { useMutation } from "@tanstack/react-query";
import { setDownloadQuality } from "../../services/ApiClient.js";
import MusicNoteLoading from "../loading/MusicNoteLoading.jsx";

function DownloadQualityBottomSheet({
  OpenDownloadQualityModal,
  CloseDownloadQualityModal,
}) {
  const [selectedDownloadQuality, setSelectedDownloadQuality] = useState("160");
  const { temporaryDownloadQueue } = downloadData();
  const { downloadQualityDialogState } = downloadData();
  const [startDownloadTrack] = useDownload();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limitations = getLimitations();
  const action = useAction();
  const limitationData = {
    message: "کاربر گرامی برای دانلود با کیفیت عالی ویژه شوید.",
  };
  const user = useUserData();

  // ANCHOR API
  const setDownloadQualityMutation = useMutation(
    (data) => setDownloadQuality(data),
    {
      onSuccess: (res) => {
        CloseDownloadQualityModal();
        const newUserData = {
          ...user,
          download_quality: res?.data?.result?.user?.download_quality,
        };
        dispatch(userData({ user: newUserData }));
      },
    }
  );

  const handleCloseDialog = () => {
    CloseDownloadQualityModal();
    dispatch(clearTemporaryDownloadQueue());
  };
  const dispatchUserData = (Data) => {
    dispatch(
      userData({
        user: Data,
      })
    );
  };
  const canSelectQuality = (quality) => {
    const limitationKey = `download_quality_${quality}`;
    console.log(limitationKey, "download quality ");
    const limitationObject = limitations[limitationKey];
    if (limitationObject?.enable) {
      return false;
    } else {
      return true;
    }
  };
  const handleDownloadQuality = (quality) => {
    if( user?.download_quality === quality){
      CloseDownloadQualityModal();
      return;
    }
    if (canSelectQuality(quality)) {
      const data = { ...user, download_quality: quality };
      dispatchUserData(data);
      setSelectedDownloadQuality(quality);
      setDownloadQualityMutation.mutate({ quality });

      return;
    } else {
      handleCloseDialog();
      dispatchSwipePlayerStatus(false);
      action({ target_type: "plans" });
      return;
    }
  };
  useEffect(() => {
    if (temporaryDownloadQueue) {
      startDownloadTrack(temporaryDownloadQueue);
    }
  }, [user]);
  const toggleQualityBottomSheet = (newOpen) => () => {
    if (!newOpen) {
      handleCloseDialog();
    } else {
      dispatch(OpenDownloadQualityModal());
    }
  };

  return (
    <SwipeBottomSheet
      open={downloadQualityDialogState}
      toggleDrawer={toggleQualityBottomSheet}
    >
      <div className="flex flex-col gap-2 bg-primary_dark ">
        <BottomSheetHeader
          closeBottomSheet={handleCloseDialog}
          HeaderIcon={<DownloadQualitySvg />}
          title="کیفیت دانلود"
          dividerLine
        />
        <div className='relative flex flex-col gap-2 px-4 pt-2  pb-4  '>
          {setDownloadQualityMutation?.isLoading &&
              <div
                  className='absolute bg-primary_dark bg-opacity-90 z-[10] top-0 left-0 w-full h-full flex justify-center items-center'>
                <div className='w-[120px] h-[120px]'>
                  <MusicNoteLoading speed={2}/>
                </div>
              </div>
          }
        {Downloadqualities.map((item, index) => (
          <div
            dir="rtl"
            onClick={() => handleDownloadQuality(item.quality)}
            key={index + "-quality" + item}
          >
            <QualityCard
              isChecked={item.quality === user?.download_quality}
              item={item}
              isPremiumlUser={user?.is_premium}
            />
          </div>
        ))}
        </div>
      </div>
    </SwipeBottomSheet>
  );
}

export default DownloadQualityBottomSheet;
