import React from 'react';

function PauseIconType2(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className='w-full h-full'
            fill="none"
            viewBox="0 0 40 40"
        >
            <path
                fill="#F2C94C"
                d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20z"
            ></path>
            <path
                fill="#212121"
                d="M15.732 12h-1.136A2.6 2.6 0 0012 14.596V26.02a2.6 2.6 0 002.596 2.597h1.136a2.6 2.6 0 002.597-2.597V14.596A2.6 2.6 0 0015.732 12zm1.298 14.02a1.3 1.3 0 01-1.298 1.298h-1.136a1.3 1.3 0 01-1.298-1.298V14.596a1.3 1.3 0 011.298-1.298h1.136a1.3 1.3 0 011.298 1.298V26.02zM27.35 23.424a.65.65 0 00.65-.65v-8.178A2.6 2.6 0 0025.402 12h-1.104a2.6 2.6 0 00-2.596 2.596V26.02a2.6 2.6 0 002.596 2.597h1.104a2.6 2.6 0 002.596-2.597.65.65 0 10-1.298 0 1.3 1.3 0 01-1.298 1.298h-1.104a1.3 1.3 0 01-1.298-1.298V14.596a1.3 1.3 0 011.298-1.298h1.104a1.3 1.3 0 011.298 1.298v8.179c0 .358.29.649.65.649z"
            ></path>
        </svg>
    );
}

export default PauseIconType2;
