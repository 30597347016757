import Dexie from "dexie";
import { db, oldPwaDB } from "./DB.js";
import * as Sentry from '@sentry/react';
import { downloadToast } from '../helper/ToastsEmitter.jsx';


export const insertDownloadedFile = async (trackId, blob) => {
    getPersistPermision()
    await checkStorege("in covert")
    return db?.downloadedFiles.put({ trackId, blob }).catch(error => {
        Sentry.captureMessage('Save downloaded file error:'+' '+error,{
            level:'log'
        })
        downloadToast('خطا در ذخیره فایل دانلود شده');
        removeFileWhenNeedSpace()

    })
}
const checkStorege = async (from) => {
    if (navigator?.storage && navigator.storage.estimate) {
        const space = await navigator.storage.estimate()
        console.log(`check user space ${from}`, space);
    } else {
        console.log(`check user space ${from}`, "not Supported");

    }
}
const getPersistPermision = () => {
    console.log("request for persist Data In DB_QUERY");
    if (navigator.storage && navigator.storage.persist) {
        navigator.storage.persist().then(granted => {
            if (granted)
                console.log("Granted Persist ", granted);

            else
                console.log("UnGranted Persist ", granted);
        });
    }
}


export const getDownloadedFile = async (trackId) => {
    // Retrieve a file from the "files" object store
    return db?.downloadedFiles?.get(trackId)
}
export const deleteDownloadedFile = async (trackId) => {
    // remove a file from the "files" object store
    return db?.downloadedFiles?.delete(trackId)
}


export const storeAdvFileToDB = (id, blob) => {
    return db?.advFiles.put({ id, blob: blob })
}

export const getDownloadedAdv = () => {
    // Retrieve a file from the "files" object store
    return db?.advFiles?.toArray()
}
export const getDownloadedAdvAsync = async () => {
    // Retrieve a file from the "files" object store
    return await db?.advFiles?.toArray()
}

export const removeDownloadedAdv = async () => {
    await db?.advFiles?.clear();
};

export const removeIndexedDB = async (dbName) => {
    try {
        const dab = new Dexie("melodify");
        await dab.delete();

    } catch (error) {
    }
};

export const deleteConvertItem = async (id) => {
    return oldPwaDB?.dlTracks?.delete(id);
}


const removeFileWhenNeedSpace = async () => {
    const items = await oldPwaDB.dlTracks?.toArray()
    console.log("come For delete ITem Quto");

    items?.slice(0, 2)?.map((item, index) => {
        console.log("dsfsdgsdgsdgsdgsdg", item);
        deleteConvertItem(item?.id)
    })

}
