import {createSlice} from "@reduxjs/toolkit";

const downloadSlice = createSlice({
    name: 'download',
    initialState: {
        downloadedFiles: [],
        downloadQueue: [],
        downloadActive: false,
        downloadPercent: 0,
        downloadActiveId: null,
        downloadQualityDialogState: false,
        temporaryDownloadQueue:null,
        convertDownloadedFile:false

    },

    reducers: {
        addToDownloadedList: (state, action) => {
            const {newItem} = action.payload
            state.downloadedFiles=[newItem,...state?.downloadedFiles];
        },
        addToDownloadQueue: (state, action) => {
            const {newItem} = action.payload
            state.downloadQueue.push(newItem);
        },
        clearDownloadQueue: (state, action) => {
            state.downloadQueue = [];
            state.downloadActive = false;
        },
        setDownloadActive: (state, action) => {
            state.downloadActive = true;
        },
        removeDownloadFromQueue: (state, action) => {
            const {id} = action.payload
            state.downloadQueue = removeArrayItem(state.downloadQueue, id)
        },
        removeFromDownloaded: (state, action) => {
            const {id} = action.payload
            state.downloadedFiles = removeArrayItem(state.downloadedFiles, id)
        },
        deActiveDownload: (state) => {
            state.downloadActive = false;
        },
        setDownloadActiveId: (state, action) => {
            const {id} = action.payload
            state.downloadActiveId = id
        },
        clearDownloadActiveId: (state, action) => {
            state.downloadActiveId = null
        },
        setDownloadProgressPercent: (state, action) => {
            const {percent} = action.payload;
            state.downloadPercent = percent

        },
        toggleDownloadActive: (state, action) => {
            state.downloadActive = !state.downloadActive
        },
        openDownloadDialog: (state, action) => {
            state.downloadQualityDialogState = true
            const {track}=action.payload
            state.temporaryDownloadQueue=track;

        },
        closeDownloadDialog: (state, action) => {
            state.downloadQualityDialogState = false
        },
        clearTemporaryDownloadQueue: (state, action)=>{
            state.temporaryDownloadQueue=null;
        },
        setConvertDownloadedFileStatus: (state, action)=>{
            const {status}=action.payload
            state.convertDownloadedFile=status;
        }
    }
})
export const selectDownloadData = (state) => state.download
export const {
    addToDownloadedList,
    addToDownloadQueue,
    removeDownloadFromQueue,
    removeFromDownloaded,
    toggleQueueItemDownloadState,
    setDownloadProgressPercent,
    clearDownloadActiveId,
    setDownloadActiveId,
    clearDownloadQueue,
    setDownloadActive,
    deActiveDownload,
    openDownloadDialog,
    closeDownloadDialog,
    clearTemporaryDownloadQueue,
    setConvertDownloadedFileStatus
} = downloadSlice.actions
export default downloadSlice.reducer


const removeArrayItem = (array, id) => {
    return array.filter((item => item.id != id))
}
