import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useParams } from 'react-router';
import SongDetailCard from '../../components/playlist/SongDetailCard';
import { MyMelodifyItemConstant } from '../../consts/MyMelodifyItemConstant';
import { getMyMelodifyItems } from '../../services/ApiClient';
import Loading from '../../components/loading/Loading';
import SongsDetailCardShimmer from '../../components/Tickets/SongsDetailCardShimmer.jsx';
import { useInfiniteQuery } from '@tanstack/react-query';
import Container from '../../components/PageParentContainer/Container';
import AbsoluteBackground from '../../components/common/AbsoluteBackground';
import CollectionCategoryHeader from '../../components/playlist/CollectionCategoryHeader';
import CommonHeader from '../../components/common/CommonHeader';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import ChevronLeft from '../../components/svg/common/ChevronLeft';
import handelBreakSentence from '../../helper/handelBreakSentence';
import ScrollTopButton from '../../components/common/ScrollTopButton';
import SongListLoading from '../../components/loading/SongListLoading';
import { Virtuoso } from 'react-virtuoso';
import AddToPlaylistModeButton from '../../components/playlist/AddToPlaylistModeButton.jsx';
import { addToPlaylistData } from '../../helper/ReduxSelectorContext.js';
import { disableListSelectMode } from '../../redux/adToPlaylist/AddToPlaylistSlice.js';
import { useDispatch } from 'react-redux';

function MyMelodifyCategory() {
  const param = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToPlaylistDataState = addToPlaylistData();
  const [item, setItem] = useState({});
  const [HasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    setHasLoaded(true);
  }, []);

  useEffect(() => {
    setItem(location?.state?.item);
  }, [param]);
  useEffect(() => {
    return () => {
      dispatch(disableListSelectMode());
    };
  }, []);

  const getMyMelodifyItemQuery = useInfiniteQuery(
    [MyMelodifyItemConstant[`${param?.key}`]?.api],
    ({ pageParam = 1 }) =>
      getMyMelodifyItems({
        apiKey: MyMelodifyItemConstant[`${param?.key}`]?.api,
        offset: pageParam - 1,
        type: MyMelodifyItemConstant[`${param?.key}`]?.type,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.result?.end) return null;
        const allData = allPages?.flatMap(
          page =>
            page.data?.[
              param?.key === 'followed-artists-tracks' ? 'error' : 'result'
            ]?.tracks,
        );
        return allData?.length;
      },

      refetchOnWindowFocus: false,
    },
  );
  const tracks = useMemo(() => {
    return getMyMelodifyItemQuery?.data?.pages?.flatMap(page => {
      if (page.data.result) return page.data?.result?.tracks;
      return [];
    });
  }, [getMyMelodifyItemQuery?.data]);

  const handleLoadMore = () => {
    getMyMelodifyItemQuery.fetchNextPage();
  };
  const handleClickSearch = () => {
    navigate('/search');
  };
  console.log({ tracks });
  return (
    <Container
      header={
        MyMelodifyItemConstant[`${param?.key}`]?.fixHeader && (
          <CommonHeader
            title={
              item?.title || MyMelodifyItemConstant[`${param?.key}`]?.title
            }
          />
        )
      }
      style="bg-primary_dark !overflow-hidden"
    >
      <AddToPlaylistModeButton
        EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}
      />
      {!MyMelodifyItemConstant[`${param?.key}`]?.fixHeader && (
        <>
          <CollectionCategoryHeader
            title={
              item?.title || MyMelodifyItemConstant[`${param?.key}`]?.title
            }
            hasSearch={false}
            backgroundId="absolute-background"
            pageId="category-page"
          />
          <AbsoluteBackground image={item?.image} animation={true} />
        </>
      )}
      <ScrollTopButton id={'category-page'} />
      <div
        id="category-page"
        className=" relative overflow-scroll h-full hide-scroll-bar z-10 "
        dir="rtl"
      >
        <div
          className={`h-full  w-full mb-10 ${
            MyMelodifyItemConstant[`${param?.key}`]?.fixHeader
              ? ''
              : ' pt-[208px]'
          } `}
        >
          {!MyMelodifyItemConstant[`${param?.key}`]?.fixHeader && (
            <p className="text-center !z-50 absolute font-kalameh font-medium text-lg text-white top-[100px] w-full flex justify-center items-center mx-auto">
              {handelBreakSentence(
                item?.title || MyMelodifyItemConstant[`${param?.key}`]?.title,
              )}
            </p>
          )}

          <div className="flex gap-2 flex-col mt-2 p-4 ">
            {getMyMelodifyItemQuery?.isLoading ? (
              <div className={'flex flex-col gap-3 mt-2'}>
                {Array.from(
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
                    <SongsDetailCardShimmer />
                  )),
                )}
              </div>
            ) : tracks.length !== 0 ? (
              <InfiniteScroll
                className={`!overflow-hidden ${
                  getMyMelodifyItemQuery.isFetchingNextPage ? '' : ' pb-28'
                } `}
                scrollableTarget="category-page"
                dataLength={getMyMelodifyItemQuery?.data?.pages?.length}
                next={handleLoadMore}
                hasMore={getMyMelodifyItemQuery?.hasNextPage}
                loader={
                  getMyMelodifyItemQuery?.isFetchingNextPage && (
                    <div className="w-full grid justify-center items-center mt-24">
                      <SongListLoading />
                    </div>
                  )
                }
              >
                <Virtuoso
                  customScrollParent={document.getElementById('category-page')}
                  data={tracks}
                  itemContent={(index, item) => (
                    <div key={'SongDetailCard' + index} className="my-[6px]">
                      <SongDetailCard
                        index={index}
                        isSelectMode={
                          addToPlaylistDataState?.listSelectItems?.length > 0
                        }
                        inPlayer={false}
                        number={
                          MyMelodifyItemConstant[`${param?.key}`]
                            ?.showIndexOfCard
                        }
                        key={item?.id}
                        data={item}
                        playList={tracks}
                        apiUrl={MyMelodifyItemConstant[`${param?.key}`]?.api}
                        requestPayload={{
                          type: MyMelodifyItemConstant[`${param?.key}`]?.type,
                        }}
                        endOfPagination={!getMyMelodifyItemQuery?.hasNextPage}
                      />
                    </div>
                  )}
                  // overscan={500}
                  // components={{
                  //   ScrollSeekPlaceholder: (rest) => (
                  //     <div className="!my-3">
                  //       <CssShimmer {...rest} />
                  //     </div>
                  //   ),
                  // }}
                  // scrollSeekConfiguration={{
                  //   enter: (velocity) => Math.abs(velocity) > 900,
                  //   exit: (velocity) => Math.abs(velocity) < 10,
                  // }}
                />
              </InfiniteScroll>
            ) : (
              <div className="flex !flex-col !items-center !justify-center relative top-[25vh]  ">
                <div
                  className={`${
                    param?.key === 'user-liked' ? 'h-[120px]' : 'h-[50px]'
                  }`}
                >
                  {MyMelodifyItemConstant[`${param?.key}`]?.noneDataImg}
                </div>
                <p className="text-gray_4 bg-transparent text-center mt-[9px] font-dana font-medium text-sm">
                  {handelBreakSentence(
                    MyMelodifyItemConstant[`${param?.key}`]?.noneDataText || '',
                  )}
                </p>
                {MyMelodifyItemConstant[`${param?.key}`]
                  ?.showSearchWhenNull && (
                  <div
                    onClick={handleClickSearch}
                    className="flex mt-[48px] justify-around items-center  w-[255px] mx-auto"
                  >
                    <span className="text-primary font-medium font-dana text-sm leading-6">
                      آهنگ مورد نظرتان را جست‌و‌جو کنید
                    </span>
                    <div className="w-fit">
                      <ChevronLeft
                        stroke="primary"
                        className="w-[24px] h-[24px] stroke-primary"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default MyMelodifyCategory;
