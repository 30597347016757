import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAction } from '../../hooks/useAction';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import { FreeMode } from 'swiper';
import { IsIosDevice } from '../../helper/CheckDevice';
import { CardActionArea } from '@mui/material';
import PlayListPlaceHolderSvg from '../svg/common/PlayListPlaceHolderSvg';
import ClickReaction from '../../helper/ClickReaction';
import { FixedSizeList as List } from 'react-window';
import { useInView } from 'react-intersection-observer';
import CustomImage from '../common/CustomImage';

function HomePlayLists({ metadata, data, index }) {
  const action = useAction();
  const handleAction = data => {
    action({ target_type: 'collection_category', children: data });
  };
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const Column = ({ data, index, style }) => {
    return (
      <div style={style}>
        <div key={index} className=" !w-fit  !my-4 first:!pr-2 last:!pl-2">
          <ClickReaction>
            <CardActionArea onClick={() => handleAction(data[index])}>
              <div className="text-white w-[80px]  aspect-square relative overflow-hidden  shadow-home-playlist-shadow rounded-[5px] flex flex-col justify-center items-center  cursor-pointer">
                <CustomLazyLoadImage image={data[index]?.image} />

                <div className="w-full flex flex-col justify-center h-full items-center">
                  <PlayListPlaceHolderSvg />

                  <p className="font-dana w-full h-7 text-center text-xs text-[#505050]">
                    {data[index]?.title}
                  </p>
                </div>
              </div>
            </CardActionArea>
          </ClickReaction>
        </div>
      </div>
    );
  };
  const EmptyColumn = () => {
    return <div></div>;
  };

  return (
    <div
      class="w-full flex flex-row h-full rounded-r-[5px] items-center relative"
      style={{
        background: `linear-gradient(${
          index == 1 ? '90deg' : '270deg'
        },#1d1d1d,#282828)`,
      }}
    >
      <div className="bg-transparent  w-[85%] min-[392px]:w-[85%] h-ful ">
        <div className="flex flex-col w-full h-fit z-20">
          {IsIosDevice() ? (
            <div
              ref={ref}
              dir={'rtl'}
              slidesPerView={'auto'}
              freeMode={true}
              cssMode={IsIosDevice()}
              spaceBetween={8}
              className="!w-full !h-fit  flex flex-col-reverse    !justify-center  !select-none   relative"
              modules={[FreeMode]}
            >
              <List
                direction="rtl"
                height={112}
                itemCount={data?.collection_categories?.length}
                itemSize={88}
                layout="horizontal"
                width={400}
                style={{ width: '100% !important' }}
                itemData={data?.collection_categories}
                className="!pr-[14px] "
              >
                {inView ? Column : EmptyColumn}
              </List>
            </div>
          ) : (
            <Swiper
              dir={'rtl'}
              slidesPerView={'auto'}
              freeMode={true}
              cssMode={IsIosDevice()}
              spaceBetween={8}
              className="!w-full !h-fit  flex flex-col-reverse    !justify-center  !select-none   relative"
              modules={[FreeMode]}
            >
              {data?.collection_categories?.map((item, index) => (
                <SwiperSlide
                  key={index + 'HomePlayListSwiperSlide'}
                  className=" !w-fit  !my-4 first:!pr-2 last:!pl-2"
                >
                  <ClickReaction>
                    <CardActionArea onClick={() => handleAction(item)}>
                      <div className="text-white w-[80px]  aspect-square relative overflow-hidden  shadow-home-playlist-shadow rounded-[5px] flex flex-col justify-center items-center  cursor-pointer">
                        <CustomLazyLoadImage image={item?.image} />

                        <div className="w-full flex flex-col justify-center h-full items-center">
                          <PlayListPlaceHolderSvg />

                          <p className="font-dana w-full h-7 text-center text-xs text-[#505050]">
                            {item?.title}
                          </p>
                        </div>
                      </div>
                    </CardActionArea>
                  </ClickReaction>
                  {/* <!-- persian --> */}
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
      <div class="text-white h-full w-full flex flex-row justify-center items-center  ">
        {/* <!-- title --> */}
        <div class={`w-[38px]  items-center  `}>
          <CustomImage
            className="w-[36px] h-full"
            src={data?.collection_category_items_meta_data?.icon}
          />

          {data?.collection_category_items_meta_data?.title && (
            <span class="text-[18px]  text-white fa-format-400">
              {data?.collection_category_items_meta_data?.title}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(HomePlayLists);
