import React, { useEffect, useState } from 'react';
import premiumImg from '../../assets/setting/free-premium.webp';
import { useQuery } from '@tanstack/react-query';
import { getFreeAcount } from '../../services/ApiClient';
import { useUserData } from '../../helper/ReduxSelectorContext';
import { useNavigate } from 'react-router-dom';
import { playStoreUrl } from '../../consts/GlobalConsts.js';
import { initialData } from '../../redux/initialize/InitializeSlice';
import Container from '../PageParentContainer/Container';
import { useMemo } from 'react';
import { CopyText } from '../../helper/CopyText';
import CommonHeader from '../common/CommonHeader';
import { CardActionArea } from '@mui/material';
import Loading from '../loading/Loading.jsx';
import FreePremiumSvg from '../svg/setting/FreePremiumSvg';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

function FreePremium() {
  const [FreeUserData, setFreeUserData] = useState();
  const user = useUserData();
  const { direct_link, slogan } = initialData();
  const navigate = useNavigate();
  const getFreeAccountQuery = useQuery(
    ['getFreeAccountQuery'],
    () => getFreeAcount(),
    {
      onSuccess: res => {
        setFreeUserData(res?.data?.result);
      },
    },
  );
  //handle cach data
  useEffect(() => {
    setFreeUserData(getFreeAccountQuery?.data?.data?.result);
  }, []);
  const doShareInfo = () => {
    window.navigator
      .share({
        title: 'اشتراک با',
        text:
          slogan +
          '\n\nدریافت از پلی استور\n\n' +
          playStoreUrl +
          '\n\nدریافت به صورت مستقیم\n\n' +
          direct_link +
          '\n\nکد معرف : ' +
          user?.ref_number,
      })
      .then(() => console.log('Yay, you shared it :)'))
      .catch(error => console.log('Oh noh! You couldnt'));
  };
  const handleHowtoSpecial = () => {
    navigate('/plans');
  };

  const rewardDetail = useMemo(
    () => [
      {
        label: 'اشتراک تا جایزه:',
        text: FreeUserData?.remaining + ' کاربر',
      },
      {
        label: 'معرفی شده ها تاکنون:',
        text: FreeUserData?.introductions + ' کاربر',
      },
      {
        label: 'جایزه شما:',
        text: FreeUserData?.rewards + ' ماه',
      },
    ],
    [FreeUserData],
  );
  return (
    <Container
      header={<CommonHeader title="حساب ویژه رایگان" />}
      style="bg-primary_dark"
    >
      <div dir="rtl" className="flex  flex-col w-full px-4  pb-6 mx-auto ">
        <div className="flex w-full">
          <CustomLazyLoadImage
            appendBaseUrl={false}
            image={premiumImg}
            alt=""
            imageClass="flex !w-full !relative"
          />
        </div>
        <div className="flex flex-col mt-6 ">
          <div className="flex flex-col ">
            <p className="  text-lg  text-white font-kalameh font-semibold mb-4">
              با اشتراک گذاری اپ،به صورت رایگان ویژه شوید.
            </p>
            <p className=" text-sm  text-gray_5 font-dana  mb-4">
              به ازای هر 7 کاربر معرفی شده با کد معرف شما،یک ماه به صورت رایگان
              کاربر ویژه شوید.
            </p>
          </div>
          <CardActionArea>
            <div
              onClick={handleHowtoSpecial}
              className="flex items-center cursor-pointer"
            >
              <div className="flex aspect-square me-4 w-5">
                <FreePremiumSvg />
              </div>
              <p className=" text-sm  text-primary font-dana font-normal">
                چرا کاربر ویژه ی ملودیفای شوم؟
              </p>
            </div>
          </CardActionArea>
        </div>
        <div className="flex flex-col mt-[42px] w-full cursor-pointer">
          <div
            onClick={() => CopyText(user?.ref_number)}
            className="w-full text-white text-sm font-medium h-14 px-6 py-4 flex justify-between bg-black_2  rounded-[20px] font-dana items-center cursor-copy"
          >
            <p>کد معرف</p>
            <p>{user?.ref_number}</p>
          </div>
          <div className="mt-2 overflow-hidden first:rounded-t-[20px] rounded-t-[20px] last:rounded-b-[20px] w-full">
            {
              FreeUserData ? (
                rewardDetail.map(item => (
                  <div className="w-full mb-1 text-white text-sm font-normal h-10 px-6 py-4 flex justify-between bg-black_2   font-dana items-center">
                    <p>{item.label}</p>
                    <p>{item?.text}</p>
                  </div>
                ))
              ) : (
                <div className="w-full mb-1 h-[132px] px-6 py-4 flex justify-between items-center">
                  <Loading bgColor={'#F2C94C'} />
                </div>
              )
              // [1, 2, 3].map((domy) => (
              //   <div className="w-full mb-1   ">
              //     <CssShimmer height="40px" width="100%" />
              //   </div>
              // ))
            }
          </div>
          {/*<div className="w-full px-6 lg-1440:px-r24 py-3 lg-1440:py-r12 flex justify-between bg-gray-1 border-[1px] border-gray-2 border-r1 rounded-[10px] lg-1440:rounded-br10 items-center">
            <p className=" text-[16px] lg-1440:text-f16 text-white fa-format-500">
              اشتراک تا جایزه:
            </p>
            <p className=" text-[16px] lg-1440:text-f16 text-white fa-format-500">
              {FreeUserData?.remaining} کاربر
            </p>
          </div>
          <div className="w-full px-6 lg-1440:px-r24 py-3 lg-1440:py-r12 flex justify-between bg-gray-1 border-[1px] border-gray-2 border-r1 rounded-[10px] lg-1440:rounded-br10 items-center">
            <p className=" text-[16px] lg-1440:text-f16 text-white fa-format-500">
              معرفی شده ها تاکنون:
            </p>
            <p className="text-[16px] lg-1440:text-f16  text-white fa-format-500">
              {FreeUserData?.introductions} کاربر
            </p>
          </div>
          <div className="w-full px-6 lg-1440:px-r24 py-3 lg-1440:py-r12 flex justify-between bg-gray-1 border-[1px] border-gray-2 border-r1 rounded-[10px] lg-1440:rounded-br10 items-center">
            <p className="text-[16px] lg-1440:text-f16  text-white fa-format-500">
              جایزه شما:
            </p>
            <p className="text-[16px] lg-1440:text-f16  text-white fa-format-500">
              {FreeUserData?.rewards} ماه
            </p>
          </div> */}
        </div>
        <div
          onClick={() => {
            doShareInfo();
          }}
          className="w-full flex cursor-pointer"
        >
          <button
            type="button"
            className="inline-flex bg-primary text-base  w-full  justify-center items-center rounded-3xl   focus:outline-none h-[46px] mt-16 font-dana font-medium text-title_box  "
          >
            اشتراک برنامه با کد من
          </button>
        </div>
      </div>
    </Container>
  );
}

export default FreePremium;
