import nonevipAvatar from '../../assets/myMelodify/normalUser.svg';
import vipSupportAvatar from '../../assets/myMelodify/vip-support.webp';
import noneVipSupportAvatar from '../../assets/myMelodify/nonevip-support.webp';
import vipAvatar from '../../assets/myMelodify/vipUserAvatar.svg';
import CrownIconSvg from '../svg/myMelodify/CrownIconSvg';
import ProfileButtonHeader from './ProfileButtonHeader';
import ProfileDetailCard from './ProfileDetailCard';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getInitialData, useUserData } from '../../helper/ReduxSelectorContext';
import CustomImage from '../common/CustomImage';

const ProfileHeader = () => {
  // ANCHOR HOOK
  const navigate = useNavigate();
  const initialData = getInitialData();
  const user = useUserData();

  // ANCHOR HANDELER
  const handleClickSpecialUser = () => {
    navigate('/plans');
  };
  const handleClickSuport = () => {
    navigate('/tickets');
  };

  return (
    <div className="bg-black_2 h-[315px] items-center w-full rounded-br-[30px] rounded-bl-[30px] ">
      <div class="flex flex-col h-full">
        <div className="h-[130px] flex">
          <div className="flex w-1/2 justify-end items-end">
            <CustomImage
              appendBaseUrl={false}
              className={`min-h-full h-[131px]  ${
                user?.is_premium ? 'w-[141px]' : 'w-[124px]'
              }`}
              src={user?.is_premium ? vipAvatar : nonevipAvatar}
            />
          </div>
          <div className="flex w-1/2 justify-start items-center">
            {user?.is_premium ? (
              <div className="w-fit flex flex-col items-center  ms-[13px]">
                <CrownIconSvg />
                <span className="block text-primary font-medium text-xs font-dana  mt-2">
                  اعتبار ویژه:
                </span>
                <span
                  className={`block font-semibold text-[40px] font-kalameh ${
                    user?.premium_days_left <= 3 ? 'text-red' : 'text-primary'
                  }`}
                >
                  {`${user?.premium_days_left} `}

                  <span
                    className={`font-medium text-xs font-dana  mt-2 ${
                      user?.premium_days_left <= 3 ? 'text-red' : 'text-primary'
                    }`}
                  >
                    روز
                  </span>
                </span>
              </div>
            ) : (
              <span className="text-gray_5  text-sm w-[100px] ms-[29px] font-dana font-medium">
                کاربر عادی
                <br />
                هستید
              </span>
            )}
          </div>
        </div>
        <div className="w-full flex items-center justify-center mt-3.5">
          <ProfileButtonHeader onClick={handleClickSpecialUser}>
            {user?.is_premium ? 'افزایش اعتبار' : 'خرید اشتراک'}
          </ProfileButtonHeader>
        </div>
        <div className=" px-4 mt-8">
          <ProfileDetailCard
            avatar={user?.is_premium ? vipSupportAvatar : noneVipSupportAvatar}
            title={
              <span
                style={{
                  color:
                    initialData?.ticket_page?.my_melodify_ticket_title_color,
                }}
                className="text-xs font-dana font-medium"
              >
                {initialData?.ticket_page?.my_melodify_ticket_title}
              </span>
            }
            subtitle={
              <span
                style={{
                  color:
                    initialData?.ticket_page?.my_melodify_ticket_subtitle_color,
                }}
                className="text-[10px] font-dana font-medium"
              >
                {initialData?.ticket_page?.my_melodify_ticket_subtitle}
              </span>
            }
            className="border border-secondary"
            titleClassName={user?.is_premium ? '!text-primary' : '!text-gray_5'}
            badge={
              initialData?.unread_ticket_count === 0
                ? null
                : initialData?.unread_ticket_count
            }
            onClick={handleClickSuport}
          ></ProfileDetailCard>
        </div>
      </div>
    </div>
  );
};

export default memo(ProfileHeader);
