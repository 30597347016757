import { memo } from 'react';
import DownloadSvg from '../svg/myMelodify/DownloadSvg.jsx';
import CssShimmer from '../common/CssShimmer';

import { CardActionArea } from '@mui/material';
import handelBreakSentence from '../../helper/handelBreakSentence.jsx';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';
import { useNavigate } from 'react-router-dom';

const ProfileGeneratedCollectionCard = ({ data }) => {
  const navigate = useNavigate();
  // ANCHOR HANDELER
  const handleClickCategory = item => {
    if (item?.collection_type === 'recently_listened') {
      navigate('/my-melodify/recently_listened', {
        state: { item },
      });
    } else {
      let formatCollectionTypeForUrl = item?.collection_type?.replaceAll(
        '_',
        '-',
      );
      navigate(`/my-melodify/${formatCollectionTypeForUrl}`, {
        state: { item },
      });
    }
  };

  return (
    <div className="flex gap-6 ">
      {data?.map((slide, index) => (
        <CardActionArea
          key={index}
          disabled={!slide?.image}
          className="!rounded-[20px] !overflow-hidden "
          onClick={() => handleClickCategory(slide)}
        >
          <div className=" relative rounded-[20px] w-[152px] h-[152px] overflow-hidden cursor-pointer">
            <CustomLazyLoadImage
              image={slide?.image && slide?.image}
              hasEffect={true}
              imageClass={'w-[152px] h-[152px] object-cover !block'}
            />
            <div className="absolute top-0 ">
              {slide?.image ? (
                <DownloadSvg className="w-full h-full" />
              ) : (
                <>
                  <DownloadSvg className="w-full h-full bg-[rgba(255,255,255,0.15)]" />
                  <CssShimmer
                    classNames={'absolute top-0 w-full min-h-[152px] h-full'}
                  />
                </>
              )}
            </div>
            <span className="absolute text-white text-base font-kalameh font-semibold bottom-3.5	right-2.5	 ">
              {handelBreakSentence(slide?.title)}
            </span>
          </div>
        </CardActionArea>
      ))}
    </div>
  );
};

export default memo(ProfileGeneratedCollectionCard);
