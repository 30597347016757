import React from 'react'

function UnsuccessfulPaymentSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64">
      <g clipPath="url(#clip0_1_2)">
        <mask
          id="mask0_1_2"
          style={{ maskType: "luminance" }}
          width="64"
          height="64"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse">
          <path fill="#fff" d="M64 0H0v64h64V0z"></path>
        </mask>
        <g mask="url(#mask0_1_2)">
          <path
            fill="#EB5757"
            d="M32 0C14.356 0 0 14.356 0 32s14.356 32 32 32 32-14.356 32-32S49.644 0 32 0zM16 24c0-2.204 1.796-4 4-4 2.204 0 4 1.796 4 4 0 2.204-1.796 4-4 4-2.204 0-4-1.796-4-4zm30.14 24.688a2.009 2.009 0 01-2.832 0c-6.048-6.048-16.584-6.048-22.628 0-.78.78-2.048.78-2.828 0a2.001 2.001 0 010-2.828C21.636 42.084 26.656 40 32 40a19.867 19.867 0 0114.14 5.86c.78.78.78 2.048 0 2.828zM44 28c-2.204 0-4-1.796-4-4 0-2.204 1.796-4 4-4 2.204 0 4 1.796 4 4 0 2.204-1.796 4-4 4z"></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <path fill="#fff" d="M0 0H64V64H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default UnsuccessfulPaymentSvg