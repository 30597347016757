import React from 'react';

function ErrorReportFlag(props) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 19C2.4 19 2 18.6 2 18V2C2 1.4 2.4 1 3 1H17C17.4 1 17.8 1.2 17.9 1.6C18.1 2 18 2.4 17.7 2.7L13.9 6.5L17.7 10.3C18 10.6 18.1 11 17.9 11.4C17.8 11.8 17.4 12 17 12H4V18C4 18.6 3.6 19 3 19ZM4 10H14.6L11.8 7.2C11.4 6.8 11.4 6.2 11.8 5.8L14.6 3H4V10Z" fill="#F2F2F2"/>
        </svg>

    );
}

export default ErrorReportFlag;
