import React from "react";
import TicketsSkeleton from "../Tickets/TicketsSkeleton";
import PlaySkeleton from "../home/PlaySkeleton";
import CssShimmer from "../common/CssShimmer.jsx";

function HomeCssShimmer() {
    return (
        <div dir="rtl" className="w-full h-full flex flex-col">
            <div className="w-full flex px-4 flex-row mt-6 min-[375px]:mt-8">
                {/* <!-- slider --> */}
                    <CssShimmer classNames={"w-full rounded-xl relative w-full min-w-[160px] min-h-[160px] min-[375px]:min-w-[200px] min-[375px]:min-h-[200px]"}
                                duration={'0.9s'}
                                delay={'0s'}
                                reverse={true} />
            </div>
            <div className="inline-flex items-center relative gap-1.5 px-4 mt-6 min-[375px]:mt-8">
                {[...Array(5).keys()].map((item) => (
                        <CssShimmer classNames={"relative rounded-lg w-full h-full min-w-[80px] min-h-[80px] min-[375px]:min-w-[105px] min-[375px]:min-h-[105px]"}
                                    duration={'0.9s'}
                                    reverse={true} />
                    )
                )}
            </div>
            <div className="inline-flex items-center relative gap-1.5 px-4 mt-4 min-[375px]:mt-8">
                {[...Array(5).keys()].map((item) => (
                <CssShimmer classNames={"relative rounded-lg w-full h-full min-w-[80px] min-h-[80px] min-[375px]:min-w-[105px] min-[375px]:min-h-[105px]"}
                            duration={'0.9s'}
                            reverse={true} />
                    )
                )}
            </div>
            <div className="flex mt-8 min-[375px]:mt-16 gap-1.5 px-4 hide-scroll-bar w-full  ">
                {[...Array(5).keys()].map((item) => (
                        <div className="flex flex-col gap-1.5">
                            <CssShimmer classNames={"relative rounded-lg mb-2 min-w-[100px] min-h-[100px] min-[375px]:min-w-[145px] min-[375px]:min-h-[145px]"}
                                        duration={'0.9s'}
                                        reverse={true} />
                            <CssShimmer classNames={"relative rounded-lg min-w-[100px] min-h-[27.5px] min-[375px]:min-w-[145px] min-[375px]:min-h-[32.5px]"}
                                        duration={'0.9s'}
                                        reverse={true} />
                            <CssShimmer classNames={"relative rounded-lg min-w-[100px] min-h-[20px] min-[375px]:min-w-[145px] min-[375px]:min-h-[22.5px]"}
                                        duration={'0.9s'}
                                        reverse={true} />
                        </div>
                    )
                )}
            </div>
            <div className="flex mt-10 min-[375px]:mt-20 gap-3 min-[375px]:gap-6 px-4 hide-scroll-bar w-full  ">
                {
                    [...Array(5).keys()].map((item) => (
                        <div className="flex flex-col gap-4">
                            <CssShimmer classNames={"relative rounded-full min-w-[80px] min-h-[80px] min-[375px]:min-w-[105px] min-[375px]:min-h-[105px]"}
                                        duration={'0.9s'}
                                        reverse={true} />
                            <CssShimmer classNames={"relative rounded-full min-w-[80px] min-h-[15px] min-w-[80px] min-h-[15px] min-[375px]:min-w-[105px] min-[375px]:min-h-[20px]"}
                                        duration={'0.9s'}
                                        reverse={true} />
                        </div>
                    ))
                }
            </div>
            <div className="flex mt-8 gap-3 min-[375px]:gap-6 px-4 hide-scroll-bar w-full  ">
                {
                    [...Array(5).keys()].map((item) => (
                        <div className="flex flex-col gap-4">
                            <CssShimmer classNames={"relative rounded-full min-w-[80px] min-h-[80px] min-[375px]:min-w-[105px] min-[375px]:min-h-[105px]"}
                                        duration={'0.9s'}
                                        reverse={true} />
                            <CssShimmer classNames={"relative rounded-full min-w-[80px] min-h-[15px] min-w-[80px] min-h-[15px] min-[375px]:min-w-[105px] min-[375px]:min-h-[20px]"}
                                        duration={'0.9s'}
                                        reverse={true} />
                        </div>
                    ))
                }
            </div>
            <div className="flex mt-8 min-[375px]:mt-16 gap-1.5 px-4 hide-scroll-bar w-full">
                {[...Array(5).keys()].map((item) => (
                        <div className="flex flex-col gap-1.5  mb-28">
                            <CssShimmer classNames={"relative rounded-lg mb-2 min-w-[100px] min-h-[100px] min-[375px]:min-w-[145px] min-[375px]:min-h-[145px]"}
                                        duration={'0.9s'}
                                        reverse={true} />
                            <CssShimmer classNames={"relative rounded-lg min-w-[100px] min-h-[27.5px] min-[375px]:min-w-[145px] min-[375px]:min-h-[32.5px]"}
                                        duration={'0.9s'}
                                        reverse={true} />
                            <CssShimmer classNames={"relative rounded-lg min-w-[100px] min-h-[20px] min-[375px]:min-w-[145px] min-[375px]:min-h-[22.5px]"}
                                        duration={'0.9s'}
                                        reverse={true} />
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

export default React.memo(HomeCssShimmer);
