import React from 'react';
import CustomLazyLoadImage from '../../../components/common/CustomLazyLoadImage';

function ArtistSearchResultAvatar({ image }) {
  return (
    <div className="w-[84px] h-[84px] rounded-full overflow-hidden">
      <CustomLazyLoadImage
        image={image}
        imageClass="object-cover rounded-full h-full w-full"
      />
    </div>
  );
}

export default ArtistSearchResultAvatar;
