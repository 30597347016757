import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import DialogHeader from './DialogHeader';
import { CardActionArea } from '@mui/material';
import { FireBaseConf } from '../../helper/FireBaseConf';
import notifImg from '../../assets/intro/notif-permision-intro.png';
import CustomImage from '../common/CustomImage';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

function NotifyPermision() {
  const [open, setOpen] = useState(false);
  const CloseCustomDialog = () => {
    setOpen(false);
  };
  const handleButtonAction = () => {
    requestNotificationPermission();
    CloseCustomDialog();
  };
  useEffect(() => {
    if (window.Notification && Notification.permission === 'granted') {
      FireBaseConf();
    } else if (window.Notification && Notification.permission === 'denied') {
      requestNotificationPermission();
      FireBaseConf();
      setOpen(false);
    } else if (window.Notification && Notification.permission === 'default') {
      setOpen(true);
    }
  }, []);

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        FireBaseConf();
        console.log('permision status', permission);
        // Permission granted, you can now show notifications
      } else if (permission === 'denied') {
        // FireBaseConf();
        console.log('permision status', permission);
        // Permission denied
      } else {
        console.log('permision status', permission);
        // Permission dismissed or default (usually on the first visit)
      }
    } catch (error) {
      setOpen(false);
    }
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={CloseCustomDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto  font-dana">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className="w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-secondary p-4  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={'کاربر گرامی'}
                  onCloseDialog={CloseCustomDialog}
                />
                <div className="flex flex-col items-center gap-2">
                  <div className="w-full flex justify-center items-center">
                    <CustomImage
                      appendBaseUrl={false}
                      width="64"
                      height="64"
                      src="https://img.icons8.com/arcade/64/bell.png"
                      alt="bell"
                    />
                  </div>
                  <p className="text-primary font-dana   text-[16px] text-center">
                    کاربر گرامی برای با خبر شدن از منتشر شدن آخرین آهنگ ها و پلی
                    لیست ها بازدن دکمه تایید در مرحله بعد روی دکمه Allow کلیک
                    کنید.
                  </p>
                  <div className="w-full flex justify-center">
                    <CustomLazyLoadImage
                      image={notifImg}
                      appendBaseUrl={false}
                      imageClass="!relative"
                    />
                  </div>
                </div>

                <div className={'flex items-center justify-center mt-6   mb-4'}>
                  <CardActionArea className="mt-4 !h-10 !rounded-full !w-[180px]">
                    <div className="w-[180px] flex items-center justify-center">
                      <button
                        onClick={handleButtonAction}
                        type="button"
                        className="inline-flex items-center !h-10 text-black text-base bg-primary w-full justify-center rounded-full border border-transparent bg-blue-100 px-4 focus:outline-none"
                      >
                        <p className="font-semibold">تایید</p>
                      </button>
                    </div>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default React.memo(NotifyPermision);
