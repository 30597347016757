import React from 'react';
import { useAction } from '../../hooks/useAction';
import { CardActionArea } from '@mui/material';
import CustomImage from '../common/CustomImage';

function HomeGeneralCollectionsBanner({ banner }) {
  const action = useAction();
  const openBanners = () => {
    action(banner.action);
  };

  return (
    <div
      onClick={openBanners}
      className=" min-h-[76px] w-full drop-shadow-exclusive-tracks-banner rounded-[10px] bg-exclusive-tracks-banner-placeholder overflow-hidden  "
    >
      <CardActionArea className=" !h-full !w-full">
        <CustomImage
          className="w-full h-full object-fill"
          src={banner?.image}
          alt=""
        />
      </CardActionArea>
    </div>
  );
}

export default HomeGeneralCollectionsBanner;
