import { memo } from "react";

function CloseSvg(props) {
  return (
    <svg
      class="w-full h-full"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6048_34977)">
        <path
          d="M10.1375 20.9251C9.84334 20.9422 9.554 20.8444 9.33053 20.6523C8.88982 20.209 8.88982 19.493 9.33053 19.0497L18.9807 9.39949C19.439 8.97057 20.1583 8.99441 20.5872 9.45279C20.9751 9.8673 20.9977 10.5044 20.6402 10.9453L10.9332 20.6523C10.7126 20.8417 10.4279 20.9393 10.1375 20.9251Z"
          fill="#EB5757"
        />
        <path
          d="M19.7777 20.9252C19.4796 20.9239 19.1938 20.8055 18.9821 20.5955L9.33191 10.9453C8.92361 10.4685 8.97912 9.75099 9.45591 9.34266C9.88147 8.97823 10.5091 8.97823 10.9346 9.34266L20.6416 18.9928C21.0998 19.4219 21.1235 20.1412 20.6945 20.5994C20.6774 20.6177 20.6598 20.6353 20.6416 20.6524C20.4039 20.8591 20.0911 20.9578 19.7777 20.9252Z"
          fill="#EB5757"
        />
      </g>
      <defs>
        <clipPath id="clip0_6048_34977">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(9 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(CloseSvg);
