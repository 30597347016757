import  { memo } from 'react';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useAction } from '../../hooks/useAction.js';
import { useInView } from 'react-intersection-observer';
import { Splide, SplideSlide } from '@splidejs/react-splide';
// Default theme
import '@splidejs/splide/dist/css/splide.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import { useMutation } from "@tanstack/react-query";
import { sendBannerVisit } from "../../services/ApiClient";
import { getInitialData } from "../../helper/ReduxSelectorContext";
import { IsIosDevice } from '../../helper/CheckDevice.js';

function HomeBannerView({ item, metaData }) {
  // const customBannerRef = useRef();
  // PauseBounceOnSwipe(customBannerRef);
  const action = useAction();
  const initialData = getInitialData();

    const sendBannerVisitApi = useMutation(sendBannerVisit);

    const openBanners = actionData => {
        if (
            initialData?.setting?.enable_visit_request &&
            actionData?.should_send_request
        )
            sendBannerVisitApi.mutate({ id: actionData?.id, type: actionData?.type });
        action(actionData.action);
    };
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <div ref={ref}>
      {(!IsIosDevice() || IsIosDevice() && inView )  ? (
        <Splide
          dir="rtl"
          style={{ padding: "0 !important" }}
          className="!p-0"
          options={{
            arrows: false,
            pagination: false,
            perPage: 1,
            perMove: 1,
            autoplay: true,
            interval: metaData?.auto_slide_time * 1000,
            padding: 0,
            className:
              "!w-full !h-fit flex flex-col-reverse !select-none new-song-swiper !p-0  ",
            direction: "rtl",
            drag: item?.banners.length <= 1 ? false : true,
            breakpoints: {
              370: {
                gap: -27,
              },
              430: {
                gap: -30,
              },
              490: {
                gap: -35,
              },
            },

                rewind: true,
                lazyLoad: true,
                focus: 'center',
              }}
            >
              {item?.banners?.map((item, index) => (
                <SplideSlide key={index + 'HomeBannerViewSplideSlide'}>
                  <div
                    onClick={() => openBanners(item)}
                    dir="rtl"
                    style={{ aspectRatio: metaData?.height_relation / 1 }}
                    class="flex  w-[90%] mx-auto   "
                  >
                    <div class="w-full flex overflow-hidden rounded-[20px] cursor-pointer">
                      <CustomLazyLoadImage
                    image={item?.image}
                    imageClass="w-full h-full  !relative "
                  />
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      ) : (
        <div
          style={{ aspectRatio: metaData?.height_relation / 1 }}
          className={` w-[90%] mx-auto `}
        ></div>
      )}
    </div>
  );
}

export default memo(HomeBannerView);
