import React from 'react';

function CrownIconType2Svg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className='w-full h-full'
            fill="none"
            viewBox="0 0 24 19"
        >
            <path
                fill="#F2C94C"
                stroke="#F2C94C"
                strokeWidth="0.1"
                d="M21.902 2.778L16.33 6.733 12.614.799h0A.728.728 0 0012 .45a.728.728 0 00-.614.35h0L7.67 6.731 2.098 2.778a.703.703 0 00-.819-.002h0a.803.803 0 00-.316.796L2.98 14.914v2.854c0 .429.327.782.738.782h16.5c.41 0 .737-.353.737-.782v-2.852l2.081-11.339a.803.803 0 00-.313-.8.703.703 0 00-.82 0l.028.042-.029-.041zM7.465 8.468h0c.34.242.8.143 1.024-.216L12 2.646l3.512 5.606a.712.712 0 001.024.217l4.715-3.346-1.64 8.936h-4.48c-.41 0-.738.353-.738.782 0 .429.328.782.738.782h4.35v1.364H4.455v-1.364h4.488c.41 0 .737-.353.737-.782 0-.43-.327-.782-.737-.782H4.329L2.738 5.114 7.465 8.47zm4.571 7.155c.41 0 .738-.353.738-.782 0-.43-.327-.782-.738-.782-.41 0-.737.353-.737.782 0 .429.327.782.737.782z"
            ></path>
        </svg>
    );
}

export default CrownIconType2Svg;
