import React from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import CollectionCardItem from "./CollectionCardItem.jsx";
import Container from "../../components/PageParentContainer/Container.jsx";
import CommonHeader from "../../components/common/CommonHeader";
import {useInfiniteQuery} from "@tanstack/react-query";
import {getCollectionCategoryChildren} from "../../services/ApiClient.js";
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollTopButton from "../../components/common/ScrollTopButton.jsx";
import SongListLoading from "../../components/loading/SongListLoading.jsx";
import CollectionGridShimmer from "../../components/Shimmers/CollegtionGridShimmer.jsx";
import { useScrollPosition } from '../../hooks/useScrollPosition.js';

function CollectionSubCategory() {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const title = searchParams.get("title");
    const pageData = location?.state?.categoryData;
    const id = location?.state?.id;
    let itemData = pageData?.data
    //infinite scroll
    useScrollPosition({pageId:'collection-sub-category-page'})
    const {
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        data
    } = useInfiniteQuery([`getCollectionCategoryChildrenQuery${id}`], ({pageParam = location?.state?.categoryData?.data?.length}) => getCollectionCategoryChildren({
            collection_category_id: id,
            offset: pageParam
        }),
        {
            getNextPageParam: (lastPage, allPages) => {
                const allData = allPages?.flatMap((page) => page?.data?.result?.data);
                return lastPage?.data?.result?.end ? false : allData?.length + location?.state?.categoryData?.data?.length;
            },
            refetchOnWindowFocus: false
        })
    const requestCollection = data?.pages?.flatMap((page) => page?.data?.result?.data);
    if (requestCollection) {
        itemData = [...itemData, ...requestCollection]
    }

    const getNextPage = () => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }

    };
    return (
        <>
            <ScrollTopButton id={"collection-sub-category-page"}/>
            <Container
                header={<CommonHeader title={title}/>}
            >
                <div id='collection-sub-category-page' dir="rtl" class="w-full h-full overflow-scroll hide-scroll-bar">
                    {itemData?.length ===0 && isLoading ?
                        <CollectionGridShimmer count={20} />
                    :
                        <InfiniteScroll
                            className='!overflow-visible'
                            dataLength={requestCollection?.length || 0}
                            hasMore={hasNextPage}
                            loader={<div className="mt-4 flex w-full justify-center">
                                <SongListLoading/>
                            </div>}
                            scrollableTarget="collection-sub-category-page"
                            next={() => getNextPage()}>
                            <div className="grid grid-cols-2 px-4 mt-4  w-full gap-4  pb-4 ">
                                {itemData?.map((item, index) => (
                                    <CollectionCardItem item={item}/>
                                ))}
                            </div>
                        </InfiniteScroll>
                    }

                </div>
            </Container>
        </>

    );
}

export default CollectionSubCategory;
