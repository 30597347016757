import React, { useEffect } from "react";
import SearchMetaDataShimmer from "../../../components/search/SearchMetaDataShimmer";
import CssShimmer from "../../../components/common/CssShimmer";
import TrackListResult from "../../../components/search/TrackListResult";
import {
  getAlbumResultData,
  getAlllSearchData,
  getArtistResultData,
  getCommitSearch,
  getCommitedSearchQuery,
  getPlayListResultData,
  getSearchLoading,
  getTrackResultData,
} from "../../../helper/ReduxSelectorContext";
import PlayListSearchResult from "../../../components/search/PlayListSearchResult";
import ArtistSearchResult from "../../../components/search/ArtistSearchResult";
import AlbumSvg from "../../../components/svg/album/AlbumSvg";
import AlbumSearchResult from "../../../components/search/AlbumSearchResult";
import RenderSearchViewType from "./RenderSearchViewType";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import SongListLoading from "../../../components/loading/SongListLoading";
import { getSearchResultV8 } from "../../../services/ApiClient";
import { CardActionArea } from "@mui/material";
import SongsDetailCardShimmer from "../../../components/Tickets/SongsDetailCardShimmer";
import SearchNoResultView from "../SearchNoResultView";

function AllSearchResultTab({ scrollTop }) {
  const shimmerArray = [1, 2, 3, 4, 5, 6, 6, 7, 8, 9];
  const searchparam = getCommitedSearchQuery();
  const searchCommit = getCommitSearch();
  const getSearchAllDataQuery = useInfiniteQuery(
    [`getSearchAllDataQuery` + searchparam],
    ({ pageParam = 0 }) =>
      getSearchResultV8({
        q: searchparam,
        type: 6,
        offset: pageParam,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.result?.end) return null;
        const allData = allPages?.flatMap((page) => page?.data?.result?.all);
        return allData?.length;
      },
      onSuccess: (res) => {
        console.log("dgsdjgkjhsfsdfsdjkgh", res);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const allSearchData = useMemo(
    () =>
      getSearchAllDataQuery?.data?.pages?.flatMap((page) => {
        if (page?.data?.error) return [];
        return page?.data?.result?.all;
      }),
    [getSearchAllDataQuery?.data]
  );
  const getMonthNewSongNextPage = () => {
    if (
      getSearchAllDataQuery?.hasNextPage &&
      !getSearchAllDataQuery?.isFetchingNextPage
    ) {
      getSearchAllDataQuery.fetchNextPage();
    }
  };
  useEffect(() => {
    getSearchAllDataQuery.refetch();
  }, [searchCommit]);
  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <>
      {/* track list result */}
      <div className="gap-4 flex flex-col pb-8">
        <div className="flex flex-col px-4 pt-2">
          {getSearchAllDataQuery.isLoading ? (
            <div className="flex flex-col w-full gap-2">
              <div className="flex w-full flex-col hide-scroll-bar overflow-scroll gap-4 pb-3 mt-4">
                {shimmerArray?.map((item, index) => (
                  <div dir="ltr" className="flex flex-row w-full  gap-4 ">
                    <CssShimmer
                      classNames={`h-16 w-16 min-[375px]:h-16 min-[375px]:w-16  
                      ${index % 2 !== 0 ? "rounded-full" : "rounded-[10px]"}
                      `}
                    />
                    <div className="flex flex-1 flex-col justify-center items-start gap-2">
                      <CssShimmer
                        classNames="w-full rounded-full h-5 "
                      />
                      <CssShimmer
                        classNames="w-1/2 rounded-full h-5 "
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : allSearchData?.length == 0 ? (
            <div className="w-full h-full flex justify-center items-center mt-14">
              <SearchNoResultView />
            </div>
          ) : (
            <InfiniteScroll
              className="!overflow-hidden"
              scrollableTarget="search-result-parent"
              dataLength={allSearchData?.length || 0}
              next={getMonthNewSongNextPage}
              hasMore={getSearchAllDataQuery?.hasNextPage}
              loader={
                <div className="w-full grid justify-center items-center mt-4">
                  <SongListLoading />
                </div>
              }
            >
              {allSearchData?.map((item, index) => (
                <RenderSearchViewType
                  key={index + "search-item"}
                  item={item}
                  index={index}
                />
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </>
  );
}

export default React.memo(AllSearchResultTab);
