import { useQuery } from '@tanstack/react-query';
import Container from '../../components/PageParentContainer/Container';
import CommonHeader from '../../components/common/CommonHeader';
import { getGiftTutorial } from '../../services/ApiClient';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LoaderContainer from '../../components/loading/LoaderContainer';
import HomeBannerView from '../../components/home/HomeBannerView';

function TutorialGift() {
  const navigate = useNavigate();

  const { data, isLoading, isFetched } = useQuery(
    ['getTutorialData'],
    getGiftTutorial,
    { refetchOnWindowFocus: false },
  );

  const goToMangeGift = () => {
    navigate(-1);
  };

  return (
    <Container
      style=" bg-primary_dark"
      header={<CommonHeader />}
      shadow={false}
    >
      <LoaderContainer loading={isLoading}>
        {!isLoading && (
          <div className="flex flex-col justify-center h-full">
            <div
              dir="rtl"
              className="flex flex-col gap-2 px-4 justify-center font-dana "
            >
              <h1 className="text-center text-white text-base font-semibold leading-6">
                {data?.data?.result?.gift_tutorial?.title}
              </h1>
              <p className="text-center text-gray_5 text-sm font-normal leading-6">
                {' '}
                {data?.data?.result?.gift_tutorial?.description}
              </p>
            </div>
            <div className="mt-9">
              {data?.data?.result?.gift_tutorial?.banners?.length !== 0 && (
                <HomeBannerView
                  metaData={data?.data?.result?.gift_tutorial?.banner_setting}
                  item={data?.data?.result?.gift_tutorial}
                />
              )}
            </div>
            <CardActionArea
              onClick={goToMangeGift}
              className="!w-44 !bg-primary !rounded-[23px] !h-[46px] !shadow-gift-submit-button !py-3 !text-center !text-gray_1 !font-medium !font-dana !text-base !mx-auto !mt-6"
              type="button"
            >
              متوجه شدم
            </CardActionArea>
          </div>
        )}
      </LoaderContainer>
    </Container>
  );
}

export default TutorialGift;
