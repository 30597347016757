import { useMemo } from "react";
import PropTypes from "prop-types";
import { appendBaseUrl } from "../../helper/utils";
import { memo } from "react";

/**
 * CustomImage
 *
 * Description: A memoized image component that appends a base URL to the source URL if specified.
 *
 * Props:
 * - appendBaseUrl: (optional) A boolean flag indicating whether to append the base URL to the source URL. Default is `true`.
 * - src: The source URL of the image.
 * - rest: Any additional props to be passed to the underlying `img` element.
 *
 * Usage Example:
 *
 * ```jsx
 * import React from 'react';
 * import CustomImage from './CustomImage';
 *
 * const Example = () => (
 *   <CustomImage
 *     appendBaseUrl={true}
 *     src="/image.jpg"
 *     alt="Example Image"
 *     className="image"
 *   />
 * );
 * ```
 */
const CustomImage = ({
  appendBaseUrl: isAppendBaseUrl = true,
  src,
  ...rest
}) => {
  // NOTE we useMemo to don`t call function in any rerender and in this check if don`t want to append base url return src exactly otherwise use appendBaseUrl function andd append base url to src and then return to use in img tag
  const imageUrl = useMemo(
    () => (isAppendBaseUrl ? appendBaseUrl(src) : src),
    [src, isAppendBaseUrl]
  );
  return <img {...rest} src={imageUrl} />;
};

CustomImage.propTypes = {
  appendBaseUrl: PropTypes.bool,
  src: PropTypes.string.isRequired,
};

export default memo(CustomImage);
