import React, {useEffect, useRef} from 'react';
import Container from "../../components/PageParentContainer/Container.jsx";
import CollectionCategoryHeader from "../../components/playlist/CollectionCategoryHeader.jsx";
import ScrollTopButton from "../../components/common/ScrollTopButton.jsx";
import PlaylistUpdateDialog from "../../components/playlist/PlaylistUpdateDialog.jsx";
import {useLocation} from "react-router-dom";
import {useInfiniteQuery} from "@tanstack/react-query";
import {getExclusiveTracks} from "../../services/ApiClient.js";
import AddToPlaylistModeButton from "../../components/playlist/AddToPlaylistModeButton";
import {addToPlaylistData} from "../../helper/ReduxSelectorContext.js";
import {disableListSelectMode} from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import {useDispatch} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import SongListLoading from "../../components/loading/SongListLoading.jsx";
import ExclusiveTrackList from "../../components/Exclusive/ExclusiveTrackList.jsx";
import AbsoluteBackground from "../../components/common/AbsoluteBackground.jsx";
import ExclusiveTrackCardShimmer from "../../components/Exclusive/ExclusiveTrackCardShimmer.jsx";
import HomeSlider from '../../components/home/HomeSlider.jsx';

function ExclusiveTracks(props) {
    const location = useLocation();
    const dispatch = useDispatch()
    const requestUrl = location.state?.requestUrl;
    const trackTypes = location.state?.trackTypes;
    const pageData = location.state?.pageData;
    const addToPlaylistDataState = addToPlaylistData()
    const dialogRef = useRef(null);
    const {
        isLoading: getExclusiveTracksIsLoading,
        isFetchingNextPage: getExclusiveTracksIsFetchingNextPage,
        fetchNextPage: getExclusiveTracksIsFetchNextPage,
        hasNextPage: getExclusiveTracksHasNextPage,
        data: getExclusiveTracksData

    } = useInfiniteQuery([`getExclusiveTracks${trackTypes}`], ({pageParam = 0}) => getExclusiveTracks(requestUrl, {
            tracks_type: trackTypes,
            offset: pageParam,
        }),
        {
            getNextPageParam: (lastPage, allPages) => {
                const allData = allPages?.flatMap((page) => page.data.result.tracks);
                return lastPage?.data?.result?.end ? false : allData?.length
            },
            refetchOnWindowFocus: false
        })
    const tracks = getExclusiveTracksData?.pages?.flatMap((page) => page.data.result.tracks);

    const getExclusiveTracksNextPage = () => {
        if (getExclusiveTracksHasNextPage && !getExclusiveTracksIsFetchingNextPage) {
            getExclusiveTracksIsFetchNextPage();
        }
    };
    useEffect(() => {
        return () => {
            dispatch(disableListSelectMode())
        };
    }, []);

    return (
        <Container style="bg-primary_dark !overflow-hidden">
            <CollectionCategoryHeader hasSearch={false} title={pageData?.title}
                                      backgroundId='absolute-background'
                                      pageId='exclusive-playlist-song'/>
            <ScrollTopButton id={"exclusive-playlist-song"}/>
            <div id="exclusive-playlist-song" className=" relative overflow-scroll h-full hide-scroll-bar  ">
                <AddToPlaylistModeButton EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}/>
                <PlaylistUpdateDialog ref={dialogRef}/>
                <div
                    id="collection-page-exclusive"
                    dir="rtl"
                    className="w-full h-full  relative z-[20]  ">
                    <div id='playlist-songs-background'
                         className=' w-full aspect-square relative  overflow-hidden flex justify-center items-start   '>


                        <p className="font-kalameh font-[600] text-[18px] text-white relative mt-[19%] ">
                            {pageData?.title}
                        </p>
                        <div className='w-full absolute  bottom-[24%] px-2'>
                            <HomeSlider
                                        heightRelation={pageData?.banner_setting?.height_relation}
                                        plan={true}
                                        data={pageData?.banners}
                                        metaData={{auto_slide_time: 5}}
                                        slideItemContainerClass={'w-full'}
                                        slideItemContentClass={'rounded-[10px] '}
                                        slideItemActionAreaClass={'!rounded-[10px]'}
                            />

                        </div>

                    </div>
                    {tracks ?

                        <InfiniteScroll
                            className='!overflow-visible !-mt-[17%]'
                            next={getExclusiveTracksNextPage}
                            hasMore={getExclusiveTracksHasNextPage}
                            dataLength={tracks?.length || 0}
                            loader={
                                getExclusiveTracksIsFetchingNextPage &&
                                <div className="w-full flex justify-center items-center -mt-8">
                                    <SongListLoading/>
                                </div>
                            }
                            scrollableTarget="exclusive-playlist-song">
                            <div className='pb-10'>
                                <ExclusiveTrackList
                                    apiUrl={requestUrl}
                                    requestPayload={{
                                        tracks_type: trackTypes,
                                    }}
                                    endOfPagination={!getExclusiveTracksHasNextPage}
                                    pageId={'exclusive-playlist-song'} list={tracks}/>

                            </div>

                        </InfiniteScroll>
                    :
                        <div className={"flex flex-col gap-2 px-2 !-mt-[17%]"}>
                            {Array.from(
                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                                    <ExclusiveTrackCardShimmer/>
                                ))
                            )}
                        </div>
                    }
                </div>
            </div>
            <AbsoluteBackground
                image={pageData?.background}
                animation={true}
            ></AbsoluteBackground>
        </Container>
    );
}

export default ExclusiveTracks;
