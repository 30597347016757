import React from "react";
import LyricErrorReportDialog from "./LyricReportBottomSheet.jsx";
import { getPlayerMusic } from "../../helper/ReduxSelectorContext";
import { useRef } from "react";
import ReportFlagSvg from "./svg/ReportFlagSvg";

function LyricReportBox({ style = "absolute" }) {
  let playedTrack = getPlayerMusic();
  const lyricReportBottomSheetRef = useRef(null);

  const handleReport = (e) => {
    e.preventDefault();
    e.stopPropagation();
    lyricReportBottomSheetRef?.current?.openModal();
  };
  return (
    <>
      <LyricErrorReportDialog
        ref={lyricReportBottomSheetRef}
        trackData={playedTrack}
      />
      <div
        onClick={handleReport}
        className={`${style} flex flex-row gap-1 bottom-3 rounded-[20px] font-kalameh font-[500] text-xs py-1 px-3 left-3 z-30 text-white`}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
      >
        گزارش خطا
        <ReportFlagSvg />
      </div>
    </>
  );
}

export default React.memo(LyricReportBox);
