import React from 'react';
import AddToPlaylistYellowBox from "../../components/MyMelodify/AddToPlaylistYellowBox.jsx";
import {useQuery} from "@tanstack/react-query";
import {getUserCollections} from "../../services/ApiClient.js";
import CommonHeader from "../../components/common/CommonHeader.jsx";
import Container from "../../components/PageParentContainer/Container.jsx";
import NoUserPlaylists from "../../components/MyMelodify/NoUserPlaylists.jsx";
import UserPlaylistList from "../../components/MyMelodify/UserPlaylistList.jsx";
import {useDispatch} from "react-redux";
import {setUserCollections} from "../../redux/globalData/globalDataSlice.js";
import {globalData} from "../../helper/ReduxSelectorContext.js";
import UserPlaylistShimmerContainer from "../../components/MyMelodify/UserPlaylistShimmerContainer.jsx";

function UserPlaylists(props) {
    const dispatch = useDispatch()
    const globalDataObject = globalData()
    const userCollections = globalDataObject?.userCollections
    const getUserCollectionQuery = useQuery(['getUserCollectionQuery'], () => getUserCollections(), {
            onSuccess: (res) => {
                dispatch(
                    setUserCollections({collections: res?.data?.result?.user_collections})
                );
            },
            refetchOnWindowFocus: false,
        }
    );
    return (
        <Container
            header={<CommonHeader hasChevronNavigateBack={true} title={'پلی لیست‌های من '}/>}
        >
            <div dir='rtl'
                 id='user-playlist-page'
                 className='flex w-full h-full flex flex-col overflow-scroll hide-scroll-bar gap-4 bg-[#212121] p-4'>

                <AddToPlaylistYellowBox/>
                <div className="flex w-full min-h-[1.5px] h-[1.5px] bg-secondary"></div>
                {getUserCollectionQuery?.isLoading && !userCollections  && <UserPlaylistShimmerContainer/> }
                {userCollections && userCollections.length>0 &&
                    <UserPlaylistList collections={userCollections}/>
                }
                {userCollections && userCollections.length < 1 &&
                    (
                        <div className='flex flex-col gap-8 h-[50vh] justify-center items-center'>
                        <NoUserPlaylists/>
                        <span
                            className={"text-gray_3 font-medium font-dana text-[18px] leading-6 "}
                        >
                              هنوز پلی‌لیستی نساخته‌اید.
                         </span>

                    </div>
                    )

                }
            </div>
        </Container>
    );
}

export default UserPlaylists;
