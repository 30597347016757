import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";
import { selectSidebar, setShowHeader } from "../../redux/player/PlayerSlice";
import { useQuery } from "@tanstack/react-query";
import { getMyMelodify } from "../../services/ApiClient";
import { useUserData } from "../../helper/ReduxSelectorContext";
import { useNavigate } from "react-router";
import Container from "../../components/PageParentContainer/Container";
import ProfileHeader from "../../components/MyMelodify/ProfileHeader";
import ProfileGeneratedCollectionsSlider from "../../components/MyMelodify/ProfileGeneratedCollectionsSlider";
import ProfileCardList from "../../components/MyMelodify/ProfileCardList";
import CommonHeader from "../../components/common/CommonHeader";
import SettingIconSvg from "../../components/svg/myMelodify/SettingIconSvg";
import { CardActionArea } from "@mui/material";
import RecentlyHeardPlaylist from "../../components/MyMelodify/RecentlyHeardPlaylist";
import RecentlyHeardSingers from "../../components/MyMelodify/RecentlyHeardSingers";
import { useEffect } from "react";
import { useScrollPosition } from '../../hooks/useScrollPosition.js';

function MyMelodify() {
  const user = useUserData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showSideBar = useSelector(selectSidebar);

  useEffect(() => {
    dispatch(setShowHeader({ header: !showSideBar }));
  }, []);

  const getMyMelodifyQuery = useQuery(
    ["getMyMelodifyQuery"],
    () => getMyMelodify(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleClickSetting = () => {
    navigate("/setting");
  };
  useScrollPosition({pageId:'page-header'})
  return (
    <>
      <Container
        header={
          <CommonHeader
            title="ملودیفای من"
            startAdornment={
              <CardActionArea onClick={handleClickSetting}>
                <SettingIconSvg
                  className={user?.is_premium ? "fill-primary" : "fill-gray_6"}
                />
              </CardActionArea>
            }
            hasChevronNavigateBack={false}
          />
        }
        style="bg-secondary !overflow-x-hidden"
      >
        <div dir="rtl" className="min-h-full pb-32 ">
          <ProfileHeader />

          <ProfileGeneratedCollectionsSlider
            isLoading={getMyMelodifyQuery?.isLoading}
            collections={
              getMyMelodifyQuery?.data?.data?.result?.generated_collections
            }
          />

          <ProfileCardList
            isLoading={getMyMelodifyQuery?.isLoading}
            userPublishedTracksCount={
              getMyMelodifyQuery?.data?.data?.result?.user_published_tracks_count
            }
            userPublishedTracksBanners={
                getMyMelodifyQuery?.data?.data?.result?.user_published_tracks_banners
            }
            followedArtistsCount={
              getMyMelodifyQuery?.data?.data?.result?.followed_artists_count
            }
            followedCollectionsCount={
              getMyMelodifyQuery?.data?.data?.result?.followed_collections_count
            }
            likedTracksCount={
              getMyMelodifyQuery?.data?.data?.result?.liked_tracks_count
            }
            userCollectionsCount={
              getMyMelodifyQuery?.data?.data?.result?.user_collections_count
            }
          />
          <RecentlyHeardSingers />
          <RecentlyHeardPlaylist />
        </div>
      </Container>
    </>
  );
}

export default MyMelodify;
