import React from 'react';

function MicrophoneSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="27"
            fill="none"
            viewBox="0 0 20 27"
            {...props}
        >
            <path
                stroke="#E0E0E0"
                strokeWidth="1.75"
                d="M5.8 4.906A4.03 4.03 0 019.83.875h.49a3.54 3.54 0 013.54 3.54v8.83a3.54 3.54 0 01-3.54 3.54h-.49a4.03 4.03 0 01-4.03-4.03v-7.85z"
            ></path>
            <path
                stroke="#E0E0E0"
                strokeLinecap="round"
                strokeWidth="1.75"
                d="M18.66 11.773a8.83 8.83 0 11-17.66 0M10.075 23.547V26m-3.188 0h6.377"
            ></path>
        </svg>
    );
}

export default MicrophoneSvg;
