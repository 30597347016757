import React from 'react';
// width="20"
// height="20"
function GoArtistSvg({fill}) {
    return (
        <svg
            className="w-full h-full"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill={fill}
                d="M9.587 9.584l-1.562 1.562a.586.586 0 10.829.828l1.562-1.562a.586.586 0 10-.829-.828z"
            ></path>
            <path
                fill={fill}
                d="M18.512 8.502c1.986-1.99 1.983-5.024-.004-7.01-1.998-1.998-5.049-1.98-7.014 0a4.957 4.957 0 00-1.403 4.196L.637 15.708c-.87.928-.846 2.359.053 3.258l.344.344c.9.9 2.33.922 3.26.052l10.018-9.453c1.6.222 3.176-.353 4.2-1.407zM17.68 2.32c1.385 1.386 1.514 3.41.385 4.914l-5.3-5.299c1.506-1.13 3.53-1 4.915.385zM3.492 18.508a1.167 1.167 0 01-1.63-.027l-.343-.343a1.166 1.166 0 01-.028-1.628L10.463 7a4.957 4.957 0 002.536 2.537l-9.507 8.97zm8.83-10.83a3.794 3.794 0 01-.382-4.911l5.293 5.293a3.794 3.794 0 01-4.91-.382z"
            ></path>
        </svg>
    );
}

export default GoArtistSvg;
