import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { getPlayerMusic, useUserData } from "../../helper/ReduxSelectorContext";
import { useDispatch } from "react-redux";
import { getTrackLyrics } from "../../services/ApiClient";
import { setPlayedTrackLyric } from "../../redux/player/PlayMusicSlice";
import { DecryptLyric } from "../../helper/DecryptLyric";

let controller = new AbortController();
function LyricRequestGlobal() {
  let userData = useUserData();
  let playedTrack = getPlayerMusic();
  const dispatch = useDispatch();
  const getTrackLyricQuery = useQuery(
    ["getTrackLyricQuery"],
    ({ signal }) =>
      getTrackLyrics(
        { track_id: playedTrack?.id, is_demo: playedTrack?.is_demo }
        
      ),
    {
      onSuccess: (res) => {
        dispatch(
          setPlayedTrackLyric({
            lyric: DecryptLyric(
              res?.data?.result?.lyrics,
              userData,
              playedTrack
            ),
          })
        );
      },
      refetchOnWindowFocus: false,
      refetchOnMount:false,
      enabled:false

    }
  );
  // useEffect(() => {
  //   if (playedTrack?.lyricData) return;
  //   controller.abort();
  //   getTrackLyricQuery.refetch(["getTrackLyricQuery"], {
  //     signal: controller?.signal,
  //   });
  // }, [playedTrack]);

  return <></>;
}

export default LyricRequestGlobal;
