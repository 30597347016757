import React, { useEffect } from "react";
import Container from "../../components/PageParentContainer/Container";
import SearchInput from "../../components/header/SearchInput";
import SearchHistory from "./SearchHistoryAbsolute";
import CommonHeader from "../../components/common/CommonHeader";
import { getInitialData } from "../../helper/ReduxSelectorContext";
import SearchBannerSwiper from "../../components/search/SearchBannerSwiper";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../redux/search/SearchSlice";
import { SearchTabBarItem } from "../../consts/SearchTabBarItem";

function Search() {
  const initData = getInitialData();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSelectedTab({ tab: SearchTabBarItem.ALL }));
  }, []);
  return (
    <Container
      header={<CommonHeader hasChevronNavigateBack={false} title={"جست و جو"} />}
    >
      <div className="w-full h-full  flex flex-col justify-between">
        <div className="w-full relative">
          <div className="w-[100.5%] fixed top-[56px] bg-secondary z-30 max-w-[520px] left-[50%] translate-x-[-50%]">
            <div className="w-full mt-4 px-3 mb-3">
              <SearchInput title={"title"} />
            </div>
          </div>

          <div dir="rtl" className="w-full  rounded-[10px]  mt-[110px]">
            <SearchHistory setShowSearchHistory={true} searchInputRef={true} />
          </div>
        </div>
        {/* <div class="w-full pb-8  px-4 ">
          <SearchBannerSwiper data={initData?.search_banner} />
        </div> */}
      </div>
    </Container>
  );
}

export default Search;
