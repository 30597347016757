import { useNavigate } from "react-router-dom";
import ChevronLeft from "../svg/common/ChevronLeft";
import { memo } from "react";
import { CardActionArea } from "@mui/material";
import IconButton from "@mui/material/IconButton";

/**
 * A component that renders a ChevronLeft icon that, when clicked, navigates back to the previous page.
 * @param {number} path - The path to navigate to. Default is -1, which navigates back to the previous page.
 * @param {object} options - Additional options for navigation.
 * @param {string} className - The CSS class name to apply to the component.
 * @param {string} iconClassName - The CSS class name to apply to the ChevronLeft icon.
 * @param {boolean} disableRipple - Whether or not to disable ripple effect. Default is false.
 * @param {boolean} shadow - Whether or not to show shadow. Default is false.
 * @returns {JSX.Element} - A React component that renders a ChevronLeft icon that, when clicked, navigates back to the previous page.
 */
function ChevronNavigateBack({
  path = -1,
  options = {},
  className = "",
  iconClassName = "",
  disableRipple = false,
  shadow = false,
}) {
  // ANCHOR HOOK
  const navigate = useNavigate();

  // Return a div that, when clicked, navigates back to the previous page
  return (
    <IconButton
      onClick={() => navigate(path, options)}
      className={` !-m-2 ${className}`}
      disableRipple={disableRipple}
    >
      <ChevronLeft className={iconClassName} shadow={shadow} />
    </IconButton>
  );
}

export default memo(ChevronNavigateBack);
