import React from 'react';

function ShareSvgType2(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className='w-full h-full'
            fill="none"
            viewBox="0 0 50 50"
        >
            <circle cx="25" cy="25" r="25" fill="#F2C94C"></circle>
            <path
                fill="#212121"
                d="M30.782 28.485a3.956 3.956 0 00-3.049 1.434l-3.083-1.533a.921.921 0 00-.82 1.65l3.088 1.535a3.967 3.967 0 003.865 4.838 3.967 3.967 0 003.962-3.962 3.967 3.967 0 00-3.963-3.962zm0 6.081a2.122 2.122 0 01-1.955-2.937.946.946 0 00.112-.227 2.12 2.12 0 013.963 1.045c0 1.169-.951 2.12-2.12 2.12zM30.782 12.82a3.967 3.967 0 00-3.862 4.851l-8.77 4.398a3.956 3.956 0 00-3.033-1.416 3.967 3.967 0 00-3.963 3.962 3.967 3.967 0 003.963 3.962c1.21 0 2.296-.546 3.024-1.404l1.488.757a.918.918 0 001.239-.403.921.921 0 00-.404-1.24l-1.491-.759a3.956 3.956 0 00.003-1.812l8.765-4.395a3.956 3.956 0 003.04 1.424 3.967 3.967 0 003.963-3.962 3.967 3.967 0 00-3.962-3.963zM15.117 26.735c-1.169 0-2.12-.951-2.12-2.12 0-1.168.951-2.12 2.12-2.12 1.168 0 2.119.951 2.119 2.12s-.95 2.12-2.12 2.12zm15.665-7.833c-1.169 0-2.12-.95-2.12-2.12 0-1.168.951-2.119 2.12-2.119 1.168 0 2.119.951 2.119 2.12 0 1.168-.95 2.119-2.12 2.119z"
            ></path>
        </svg>
    );
}

export default ShareSvgType2;
