import React from 'react';

function RedHeartSvg(props) {
    return (
        <svg
            className="h-[16px]  min-[1440px]:h-[1.1111vw]"
            xmlns="http://www.w3.org/2000/svg"
            style={{aspectRatio:18/18}}
            fill="none"
            viewBox="0 0 20 18"
        >
            <path
                fill="#EB5757"
                d="M9.556 17.314a.621.621 0 00.89 0l7.996-8.101C21.776 5.835 19.407 0 14.665 0c-2.848 0-4.154 2.093-4.664 2.483C9.49 2.091 8.191 0 5.338 0 .61 0-1.788 5.82 1.562 9.213l7.994 8.101z"
            ></path>
        </svg>
    );
}

export default RedHeartSvg;
