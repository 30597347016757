import React from 'react';
import { useLocation } from 'react-router-dom';
import { dispatchSwipePlayerStatus } from '../../redux/globalstore/GlobalStore.js';
import { useAction } from '../../hooks/useAction.js';
import { CardActionArea } from '@mui/material';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';

function AddsLimit({ item, inPlayer }) {
  const action = useAction();
  const location = useLocation();
  const openPlans = () => {
    dispatchSwipePlayerStatus(false);
    if (location?.pathname !== '/plans')
      action({
        target_type: item.add_on?.action?.target_type,
        openPlayerWhenBack: inPlayer,
      });
  };

  return (
    <div
      onClick={openPlans}
      className={`relative w-full min-h-[10px] mb-2`}
      style={{ aspectRatio: item?.add_on?.height_relation / 1 }}
    >
      <div
        className={` 
                   rounded-[5px]
                 w-full h-full    absolute  top-0 z-10 overflow-hidden cursor-pointer`}
      >
        <CardActionArea className="!w-full !h-full">
          <CustomLazyLoadImage
            imageClass="object-cover"
            image={item?.add_on?.image}
          />
        </CardActionArea>
      </div>
    </div>
  );
}

export default AddsLimit;
