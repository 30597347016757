import React from 'react'

function RoundedCrownSVG() {
  return (
    <svg  viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="22" cy="22" r="22" fill="#242429"/>
    <path d="M31.6567 28.153H12.0765C11.635 28.153 11.2487 27.8562 11.1349 27.4297L8.40012 17.174C8.30138 16.8036 8.4289 16.4097 8.72604 16.1676C9.02318 15.9254 9.43473 15.88 9.77747 16.0513L17.2559 19.7905L20.995 12.3121C21.1601 11.982 21.4975 11.7734 21.8666 11.7734C22.2358 11.7734 22.5732 11.982 22.7382 12.3121L26.4774 19.7905L33.9558 16.0513C34.2987 15.8798 34.7102 15.9254 35.0072 16.1676C35.3044 16.4097 35.4319 16.8036 35.3332 17.174L32.5983 27.4297C32.4845 27.8562 32.0981 28.153 31.6567 28.153Z" fill="#FFD961"/>
    <path d="M32.5949 27.4296L35.3297 17.1739C35.4285 16.8036 35.301 16.4097 35.0038 16.1675C34.7068 15.9253 34.2953 15.8798 33.9524 16.0512L26.474 19.7904L22.7348 12.3121C22.5698 11.982 22.2324 11.7734 21.8633 11.7734V28.153H31.6533C32.0948 28.153 32.4812 27.8562 32.5949 27.4296Z" fill="#FFB300"/>
    <path d="M30.2114 32.4099H13.5312C13.0903 32.4099 12.7044 32.243 12.5908 32.0032L11.1523 28.9668H32.5903L31.1519 32.0032C31.0382 32.243 30.6524 32.4099 30.2114 32.4099Z" fill="#FFB300"/>
    <path d="M31.1438 32.0032L32.5822 28.9668H21.8633V32.4099H30.2034C30.6444 32.4099 31.0302 32.243 31.1438 32.0032Z" fill="#FF8D00"/>
  </svg>
  )
}

export default RoundedCrownSVG