import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {getUserCollections} from "../../services/ApiClient.js";
import {setUserCollections} from "../../redux/globalData/globalDataSlice.js";
import {useDispatch} from "react-redux";

function GlobalUserCollection(props) {
    const dispatch=useDispatch()
    const getUserCollectionQuery = useQuery(['getUserCollectionQuery'], () => getUserCollections(), {
        onSuccess: (res) => {
            dispatch(
                setUserCollections({collections: res?.data?.result?.user_collections})
            );
        },
        refetchOnWindowFocus: false,
    })
    return (
        <></>
    );
}

export default GlobalUserCollection;
