import React from "react";
import MaskLayout from "./MaskLayout";
import BottomNavigationComponent from "../../components/BottomNavigation/BottomNavigationComponent";
import { useEffect } from "react";
import { useBottomNavigation } from "../../components/BottomNavigation/useBottomNavigation";
import SwipePlayer from "../../components/player/SwipePlayer";
import { getPlayerMusic } from "../../helper/ReduxSelectorContext";
import MiniBottomPlayer from "../../components/player/MiniBottomPlayer";
import LyricFullScreenDrawer from "../../components/lyrics/LyricFullScreenDrawer";

function PageContainer({ children }) {
  const [InvisibleBottomSheet] = useBottomNavigation();
  let playedTrackData = getPlayerMusic();
  const handleContainerPadding = () => {
    if (playedTrackData == null) {
      return "container-padding";
    } else {
      return "container-padding-with-player ";
    }
  };
  return (
    <div className="max-w-[520px] h-full w-full flex flex-col overflow-hidden">
      <MaskLayout />
      <div
        className={`w-full h-full  max-w-[520px]  ${
          InvisibleBottomSheet() ? "safe-area" : handleContainerPadding()
        }   overflow-hidden  relative`}
      >
        {children}
      </div>

      {/* Players */}
      <SwipePlayer />

      <LyricFullScreenDrawer />
      {/* button navigation */}
      {InvisibleBottomSheet() ? (
        ""
      ) : (
        <>
          <MiniBottomPlayer />
          <BottomNavigationComponent />
        </>
      )}
    </div>
  );
}

export default PageContainer;
