import React from 'react';

function ExpireSoonSvg({classNames}) {
    // width="34"
    // height="34"
    return (
        <svg
            className={`w-full h-full ${classNames} `}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 34 34"
        >
            <path
                fill="#F2C94C"
                d="M30.868 24.262a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM27.596 29.093a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM31.995 18.506a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM22.738 32.37a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM17 30.5a13.5 13.5 0 118.469-24H23a1.5 1.5 0 000 3h6A1.5 1.5 0 0030.5 8V2a1.5 1.5 0 10-3 0v2.296A16.493 16.493 0 1017 33.5a1.5 1.5 0 000-3z"
            ></path>
            <path
                fill="#F2C94C"
                d="M22.56 22.56a1.5 1.5 0 000-2.12l-4.06-4.061V8a1.5 1.5 0 10-3 0v9c0 .398.158.78.44 1.06l4.5 4.5a1.5 1.5 0 002.12 0z"
            ></path>
        </svg>
    );
}

export default ExpireSoonSvg;
