import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useRef } from "react";
import { removeSingleSession } from "../../services/ApiClient";
import ConfirmDialogs from "../dialogs/ConfirmDialogs";
import TrashSVG from "../svg/common/TrashSVG";

function DeviceItem({ item, getActiveSessionQuery }) {
  console.log(item);
  const deleteSelectedDeviceDialogRef = useRef();
  const deleteDeviceMutation = useMutation(
    (token) => removeSingleSession(token),
    {
      onSuccess: (res) => {
        // Toast(res?.data?.result?.message)
        getActiveSessionQuery.refetch();
      },
    }
  );
  const handleDeleteDevice = () => {
    deleteDeviceMutation.mutate({ device_token: item?.device_token });
  };

  const handleConfirmDeleteDevice = () => {
    deleteSelectedDeviceDialogRef.current.openDialog();
  };

  return (
    <div className="flex justify-between bg-secondary px-4 py-2 rounded-[10px] items-center">
      <div className="flex items-center gap-4">
        <div
          className="rounded-full bg-gray_6 aspect-square h-[14px]"></div>
        <div className="flex flex-col gap-r4">
          <p className="text-sm text-white font-poppins dir-ltr mb-1">
            {item?.name}
          </p>
          <div className="gap-2 flex items-center">
            {/* <p className=" text-sm text-gray_5">
              آخرین بازدید:
            </p> */}
            <p className="text-sm text-gray_3">
              {item?.last_activity}
            </p>
          </div>
        </div>
      </div>
      <div
        onClick={handleConfirmDeleteDevice}
        className="h-[22px]"
        style={{  aspectRatio: 20 / 22 }}>
        <TrashSVG fill={"#EB5757"} />
      </div>
      <ConfirmDialogs
          dialogIcon={
              <TrashSVG fill={"#EB5757"} />
          }
          title={"حذف"}
          text={"مطمئن هستید که می خواهید این دستگاه را از لیست دستگاههای متصل به حساب کاربری خود حذف کنید؟"}
          buttonDirection={"dir-ltr"}
          confirmText={"حذف"}
          confirmTextColor={"text-primary"}
          confirmBGColor={"bg-transparent"}
          confirmBorderColor={"border-primary"}
          confirmAction={handleDeleteDevice}
          cancelText={"لغو"}
          cancelTextColor={"text-secondary"}
          cancelBGColor={"bg-primary"}
          cancelBorderColor={"transparent"}
        ref={deleteSelectedDeviceDialogRef}
      />
    </div>
  );
}

export default DeviceItem;
