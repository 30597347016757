import React from 'react'

function SearchYellowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        stroke="#F2C94C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M12.741 23.333c5.86 0 10.609-4.775 10.609-10.666S18.6 2 12.74 2 2.133 6.776 2.133 12.667c0 5.89 4.75 10.666 10.608 10.666zM26.003 26l-5.769-5.8"
      ></path>
    </svg>
  )
}

export default SearchYellowIcon