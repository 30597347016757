import React from "react";


function ThreeDotSvg({fill,home=false}) {
  return (
    <svg
      style={{  aspectRatio: home?'3 / 17':'4 / 20' }}
      className={`${home?'h-[17px]':'h-[20px] '}`}
      viewBox="0 0 4 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="2" cy="2" r="2" fill={fill} />
      <circle cx="2" cy="10" r="2" fill={fill} />
      <circle cx="2" cy="18" r="2" fill={fill} />
    </svg>
  );
}

export default ThreeDotSvg;
