import React from 'react';
import HomeCollectionWithCorner from "../home/HomeCollectionWithCorner.jsx";
import {convertPlayListObject} from "../../helper/ConvertPlayListObject.js";

function RenderPlayListData({item,fromMyMelodify=false}) {
    let newObject=convertPlayListObject(item);
    return  <HomeCollectionWithCorner cardStyle={'!w-[120px] !h-[120px]'} inPlaylistPage={true} fromMyMelodify={true} item={newObject}/>
}

export default RenderPlayListData;
