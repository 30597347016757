import React, {useState} from 'react';
import {useFormik} from "formik";
import {CREATE_COLLECTION_VALIDATION_SCHEMA} from "../../consts/Validation.js";
import {editUserCollection} from "../../services/ApiClient.js";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import EditPlaylistNameSvg from "../svg/playList/EditPlaylistNameSvg.jsx";
import {setLanguageDirection} from "../../helper/LanguageDetection.js";
import SaveEditPlaylistButton from "./SaveEditPlaylistButton.jsx";
import {globalData} from "../../helper/ReduxSelectorContext.js";
import FullPageNoteLoading from "../loading/FullPageNoteLoading.jsx";
import {toggleDragging} from "../../redux/globalData/globalDataSlice.js";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

function EditPlaylistAbsoluteView({collectionObject,onUpdateCollectionData,sortType,collectionId}) {
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const globalObject = globalData()
    const queryClient = useQueryClient()
    const tracks = globalObject?.dragList
    const [getNewListLoading,setGetNewListLoading] =useState(false)
    const formik = useFormik({
        initialValues: {
            user_collection_id: collectionObject?.id,
            title: collectionObject?.title,
            sort: "my_sort",
        },
        enableReinitialize: true,
        validationSchema: CREATE_COLLECTION_VALIDATION_SCHEMA,
        onSubmit: (data) => {
            if (editUserCollectionMutation.isLoading) {
                return
            }

            const tracksIdList = [];
            tracks?.map((track) => {
                tracksIdList.push(track?.id);
            });
            handleEditUserCollection({data, query: tracksIdList});
        },
    });
    const editUserCollectionMutation = useMutation((data) =>
        editUserCollection(data)
    );
    const handleEditUserCollection = (data) => {
        editUserCollectionMutation.mutate(data, {
            onSuccess: (res) => {
                onUpdateCollectionData(res?.data?.result?.user_collection);
                setGetNewListLoading(true)
                queryClient.invalidateQueries(['getUserPlaylistTracks' + sortType + collectionId]).then(()=>{
                    navigate(-1)
                    setGetNewListLoading(false)
                })


                // getUserCollectionMutation.mutate();
                // closeDialog();
            },
        });
    };

    return (
        <>
            {(editUserCollectionMutation?.isLoading || getNewListLoading)  &&
            <FullPageNoteLoading/>
            }
            <SaveEditPlaylistButton formik={formik} onSubmit={formik.handleSubmit}/>
            <form className="flex gap-1 border-b-[2px] border-b-gray_2 relative ">

                <EditPlaylistNameSvg/>
                <input id='title' value={formik.values.title} onChange={formik.handleChange} dir={setLanguageDirection(collectionObject?.title)} type="text"
                       className='bg-transparent font-dana text-lg font-normal text-white focus:outline-none'/>
            </form>
        </>

    );
}

export default EditPlaylistAbsoluteView;
