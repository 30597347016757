import React from 'react';

import SwiperChevronLeft from '../svg/common/SwiperChevronLeft';
import SwiperChevronRight from '../svg/common/SwiperChevronRight';
import { useAction } from '../../hooks/useAction';
import CustomImage from './CustomImage';

function HomeChildMetaData({
  metaData = {
    title: 'پلی لیست ها',
    show_more: true,
    title_color: '#FFFFFF',
    show_more_color: '#FFFFFF',
    type: 'suggested_categories',
    has_background: false,
    show_more_text: '  دیدن همه',
    action: {
      target_type: 'home_collection',
    },
  },
  hasChevronButton = true,
  eventClassName = { prev: 'prev-button', next: 'next-button' },
}) {
  const action = useAction();
  const handleAction = () => {
    console.log('SDgsdgsdgsdgsdg', metaData);
    action(metaData?.action);
  };
  return (
    <div
      dir="rtl"
      className="flex w-full justify-between bg-primary_dark rounded-full p-[2px] px-2   mt-4 lg-1440:mt-r16 items-center relative"
    >
      <div className="flex flex-col gap-1 lg-1440:gap-r4">
        <div className="flex items-center gap-2 pr-1  ">
          <span className="w-[20px]">
            <CustomImage className="w-full h-full " src={metaData?.icon} />
          </span>
          <span className="text-[16px]  text-gray_3 font-kalameh font-[500]">
            {metaData?.title}
          </span>
        </div>
        <div className="h-[2px] lg-1440:h-r2 rounded-full w-[110%]  bg-yellow"></div>
      </div>

      <div className="flex items-center ">
        <div className="" onClick={() => metaData?.show_more && handleAction()}>
          <p
            className={`${
              metaData?.show_more ? 'visible' : 'invisible'
            }   flex items-center justify-center cursor-pointer w-[90px] h-[30px] rounded-[30px] text-xs  font-kalameh font-[400] !text-gray_5 `}
            style={{
              color: metaData?.title_color,
              // ,backgroundColor: metaData?.show_more_color
            }}
          >
            {metaData?.show_more_text}
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 18.8809L9 12.8809L15 6.88086"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomeChildMetaData;
