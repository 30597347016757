import React from 'react';

import { setLanguageDirection } from '../../../helper/LanguageDetection';
import PlaylistAndSongSearchResultAvatar from './PlaylistAndSongSearchResultAvatar';
import ArtistSearchResultAvatar from './ArtistSearchResultAvatar';
import AlbumSearchResultAvatar from './AlbumSearchResultAvatar';
import { CardActionArea } from '@mui/material';
import { useAction } from '../../../hooks/useAction';
import { useStoreSearchHistory } from '../../../hooks/useStoreSearchHistory';
import { textFontBasedLanguage } from '../../../helper/TextFontBasedLanguage.js';
function SearchResultAlbumSingleTab({ item, fromArtistTab, index }) {
  const [storeSearchItem] = useStoreSearchHistory();
  const action = useAction();
  const openAlbum = albumItem => {
    console.log('gsdhjgsdjhg', albumItem);
    action({ target_type: 'open_album', albumObject: albumItem });
    // storeSearchItem({
    //   album: item,
    //   model_type: "album",
    //   model_type_title: "آلبوم",
    //   id: item?.id,
    // });
  };
  return (
    <CardActionArea
      className={`h-[84px] !my-1 !rounded-[5px] !px-3 ${
        index % 2 !== 0
          ? 'bg-song-detail-card-background-2'
          : 'bg-song-detail-card-background'
      }`}
    >
      <div
        onClick={() => openAlbum(item)}
        dir="rtl"
        className="flex justify-between items-center "
      >
        <span
          className={`${
            fromArtistTab ? 'flex' : 'invisible'
          } text-[#9A9A9A] font-dana text-xs`}
        >
          {item?.tracks_count} آهنگ
        </span>
        <div className="flex gap-2 items-center ">
          <div className="flex flex-col items-end gap-2 font-dana">
            <p
              dir="ltr"
              className={`text-white text-sm font-medium line-clamp-1 max-w-[68vw] min-[520px]:max-w-sm ${textFontBasedLanguage(item?.title)} `}
            >
              {item?.title}
            </p>
            <div
              dir="rtl"
              className="w-full flex flex-row items-center justify-end gap-1"
            >
              {/* { (
              item?.album?.artists?.map((artistName, index) => (
                <p className="text-gray_4 text-xs font-normal   ">
                  {artistName?.name}
                  {index !== item?.artists?.length - 1 &&
                    (setLanguageDirection(item?.title) === "rtl" ? "، " : ", ")}
                </p>
              ))
            ) } */}
              {/* <div className="flex flex-row gap-[2px] items-center"> */}
              {/* <p dir="rtl" className="text-gray_4 text-xs font-normal   ">
                {item?.model_type_title}
              </p>
              <p dir="rtl" className="text-gray_4 text-xs font-normal   ">
                -
              </p> */}
              {/* <p dir="rtl" className="text-gray_4 text-xs font-[500]   ">
                  {""}
                  {item?.artists && item?.artists[0]?.name}
                </p> */}
              <div className="w-full text-gray_4 flex flex-row items-center justify-end gap-1 ">
                <div
                  dir="rtl"
                  className="w-fit max-w-[45vw] min-[520px]:max-w-sm flex flex-row items-center line-clamp-1 justify-end "
                >
                  {item?.artists?.map((artistName, index) => (
                    <span  className={`text-gray_4 text-xs font-[500] ${textFontBasedLanguage(artistName?.name)} `}>
                      {artistName?.name}
                      {index !== item?.artists?.length - 1 &&
                        (setLanguageDirection(item?.track?.title) === 'rtl'
                          ? '، '
                          : ', ')}
                    </span>
                  ))}
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className={`${fromArtistTab ? 'w-16 h-16 ' : 'w-16 h-16 '}`}>
            <AlbumSearchResultAvatar image={item?.image} />
          </div>
        </div>
      </div>
    </CardActionArea>
  );
}

export default SearchResultAlbumSingleTab;
