import { memo } from "react";

const noTicketSvg = (props) => (
  <svg
    width={96}
    height={96}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m84.692 48.769-14.798-12.63a24.477 24.477 0 0 0 2.569-7.938 1.406 1.406 0 0 0-2.784-.4c-.675 4.689-2.81 8.946-6.175 12.312a1.406 1.406 0 0 0 0 1.988l3.857 3.856a.302.302 0 0 1 .07.355.301.301 0 0 1-.3.2h-8.756a1.406 1.406 0 0 0 0 2.813h8.756c1.273 0 2.411-.76 2.898-1.937a3.128 3.128 0 0 0-.68-3.42l-2.89-2.889a24.793 24.793 0 0 0 1.961-2.5l14.446 12.329c.03.024.053.054.078.082L56.477 68.796l-5.858-5.001a3.955 3.955 0 0 0-5.13 0l-5.859 5-9.399-6.323a1.406 1.406 0 1 0-1.57 2.334l8.748 5.885-24.432 20.854v-37.29l9.96 6.7a1.405 1.405 0 1 0 1.57-2.334l-11.343-7.63a.775.775 0 0 1 .079-.083l14.433-12.32a24.684 24.684 0 0 0 6.361 6.367 6.38 6.38 0 0 0-.727 2.963c0 3.54 2.88 6.42 6.42 6.42a6.43 6.43 0 0 0 6.284-5.1c.678.056 1.36.087 2.04.087h3.378a1.406 1.406 0 1 0 0-2.813h-3.378c-.694 0-1.392-.033-2.082-.098-.679-2.817-3.219-4.917-6.241-4.917a6.387 6.387 0 0 0-3.868 1.3c-6.064-4.076-9.659-10.79-9.659-18.134 0-5.83 2.275-11.316 6.405-15.446 4.13-4.13 9.615-6.405 15.445-6.405a21.85 21.85 0 0 1 21.588 18.462 1.407 1.407 0 0 0 2.78-.431A24.666 24.666 0 0 0 48.053 0C41.472 0 35.281 2.567 30.62 7.228c-4.662 4.661-7.228 10.853-7.228 17.435 0 4.092.99 8.01 2.813 11.483L11.417 48.77a3.566 3.566 0 0 0-1.253 2.715V92.43a3.558 3.558 0 0 0 1.234 2.694 3.552 3.552 0 0 0 2.337.877h68.639a3.553 3.553 0 0 0 2.479-1.007 3.557 3.557 0 0 0 1.091-2.564V51.484a3.565 3.565 0 0 0-1.252-2.715Zm-1.56 5.485v37.292L58.698 70.691l24.434-16.437Zm-35.817 11.68a1.139 1.139 0 0 1 1.478 0l31.93 27.253H15.385l31.93-27.253Z"
      fill="#4F4F4F"
    />
    <path
      d="M59.328 18.04a4.622 4.622 0 0 0-1.363-3.29 4.622 4.622 0 0 0-3.29-1.362 4.621 4.621 0 0 0-3.29 1.363l-3.331 3.332-3.332-3.332a4.621 4.621 0 0 0-3.29-1.363 4.623 4.623 0 0 0-3.29 1.363 4.622 4.622 0 0 0-1.363 3.29c0 1.242.484 2.41 1.363 3.29l3.332 3.332-3.332 3.331a4.658 4.658 0 0 0 0 6.58 4.622 4.622 0 0 0 3.29 1.363 4.621 4.621 0 0 0 3.29-1.363l3.332-3.331 3.331 3.331a4.622 4.622 0 0 0 3.29 1.363 4.623 4.623 0 0 0 3.29-1.363 4.658 4.658 0 0 0 0-6.58l-3.331-3.331 3.331-3.332a4.622 4.622 0 0 0 1.363-3.29Zm-3.351 1.302-4.326 4.326c-.55.55-.55 1.44 0 1.989l4.326 4.326a1.842 1.842 0 0 1-1.302 3.141c-.491 0-.953-.191-1.3-.539l-4.327-4.326a1.406 1.406 0 0 0-1.988 0l-4.327 4.326c-.347.348-.81.54-1.3.54a1.842 1.842 0 0 1-1.301-3.141l4.325-4.327a1.405 1.405 0 0 0 0-1.99l-4.326-4.325a1.828 1.828 0 0 1-.539-1.301c0-.492.191-.954.54-1.302a1.828 1.828 0 0 1 1.3-.539c.492 0 .954.192 1.302.54l4.326 4.325c.549.55 1.44.55 1.988 0l4.326-4.326c.348-.348.81-.539 1.301-.539.492 0 .954.191 1.302.54.347.347.539.809.539 1.3 0 .492-.192.954-.54 1.302ZM84.23 31.5a1.409 1.409 0 0 0-1.989 0l-1.324 1.325-1.325-1.324a1.406 1.406 0 1 0-1.989 1.988l1.325 1.325-1.325 1.325a1.406 1.406 0 1 0 1.989 1.988l1.325-1.324 1.324 1.324a1.402 1.402 0 0 0 1.989 0c.55-.549.55-1.44 0-1.988l-1.325-1.325 1.325-1.325c.55-.549.55-1.44 0-1.988Zm-3.313-15.375c3.54 0 6.421-2.88 6.421-6.42a6.429 6.429 0 0 0-6.421-6.422c-3.54 0-6.42 2.88-6.42 6.421 0 3.54 2.88 6.421 6.42 6.421ZM14.521 34.5a5.867 5.867 0 0 0 5.86-5.86 5.867 5.867 0 0 0-5.86-5.859 5.866 5.866 0 0 0-5.859 5.86 5.866 5.866 0 0 0 5.86 5.859Zm-.994-16.956a1.402 1.402 0 0 0 1.989 0l.76-.761.762.76a1.401 1.401 0 0 0 1.989 0 1.406 1.406 0 0 0 0-1.988l-.762-.76.761-.762a1.406 1.406 0 1 0-1.988-1.988l-.761.76-.761-.76a1.406 1.406 0 1 0-1.989 1.988l.761.761-.76.761c-.55.55-.55 1.44 0 1.989Z"
      fill="#4F4F4F"
    />
  </svg>
);
export default memo(noTicketSvg);
