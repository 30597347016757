import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import MusicPlaceholderSvg from '../svg/common/MusicPlaceholderSvg.jsx';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import TrashType2Svg from '../svg/playList/TrashType2Svg.jsx';
import DragDropSvg from '../svg/playList/DragDropSvg.jsx';
import { Draggable } from 'react-beautiful-dnd';
import DraggableCardContainer from '../svg/playList/DraggabelCardContainer';
import { useDispatch } from 'react-redux';
import { deleteItemOfDragList } from '../../redux/globalData/globalDataSlice.js';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

function DraggableSongDetailCard({ item, index }) {
  const dispatch = useDispatch();
  const deleteItemHandler = () => {
    dispatch(deleteItemOfDragList({ id: item?.id }));
  };
  return (
    <Draggable draggableId={item?.id.toString()} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            dir="ltr"
            className={`p-[1px]  cursor-pointer group   rounded-[5px] relative overflow-visible `}
          >
            <div
              className={`   ${
                index % 2 !== 0
                  ? 'bg-song-detail-card-background-2'
                  : 'bg-song-detail-card-background'
              } py-2   flex justify-between rounded-[5px]  pr-4  w-full items-center`}
            >
              <div className={`flex items-center   w-[60%]`}>
                <div
                  onClick={deleteItemHandler}
                  className="flex items-center  justify-center  min-w-[50px]"
                >
                  <TrashType2Svg />
                </div>

                <div className="flex items-center gap-[6px]  w-full">
                  <div className="relative flex justify-center items-center  h-[52px] w-[52px]  min-w-[52px]   ">
                    {item?.image && (
                      <CustomLazyLoadImage
                        hasEffect={true}
                        image={item?.image}
                        imageClass="object-cover rounded-[5px] lg-1440:rounded-br10"
                      />
                    )}
                    <div className="rounded-[5px]  overflow-hidden h-[52px] ">
                      <MusicPlaceholderSvg className="absolute top-0 left-0" />
                    </div>
                  </div>
                  <div className="flex flex-col w-full items-start ">
                    <p
                      dir={setLanguageDirection(item?.title)}
                      className={` text-[14px] max-w-full font-dana font-[500] text-gray_5  leading-6 whitespace-nowrap  truncate `}
                    >
                      {item?.title}
                    </p>
                    <p
                      dir={setLanguageDirection(item?.title)}
                      className="text-gray_3 text-[12px] font-medium font-dana leading-6  whitespace-nowrap truncate max-w-full"
                    >
                      {item?.artists[0]?.name}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex gap-2  items-center ">
                <DragDropSvg />
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default DraggableSongDetailCard;
