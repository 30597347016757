import React from "react";
import QualityCard from "./QualityCard";
import { streamQualityList } from "../../consts/StreamQuality";

import { selectUserData, userData } from "../../redux/login/LoginSlice.js";
import { setLimitationDialogData } from "../../redux/globalData/globalDataSlice.js";
import {
  closeDropDownMenu,
  setActiveIndex,
} from "../../redux/player/DropDownSlice.js";
import { changeTrackQuality, setIsPlaying } from "../../redux/player/PlayMusicSlice.js";
import { createMusicCdnLink } from "../../helper/createMusicCdnLink";
import { setStreamingQuality } from "../../services/ApiClient";
import {
  getPlayerMusic, getSwipePlayerStatus,
  getUserToken,
} from "../../helper/ReduxSelectorContext";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { selectLimitation } from "../../redux/limitation/LimitationSlice";
import CommonHeader from "../common/CommonHeader";
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader";
import { useNavigate } from "react-router-dom";
import BroadcastQualitySvg from "../svg/setting/BroadcastQualitySvg";
import {dispatchSwipePlayerStatus} from "../../redux/globalstore/GlobalStore.js";
import {useAction} from "../../hooks/useAction.js";
import MusicNoteLoading from "../loading/MusicNoteLoading.jsx";
import { GetPlayerRef } from "../../helper/GetPlayerRef.jsx";

function StreamQuality({ openBottomSheetSetter,closeParent=()=>{
  console.log('log')} }) {
  // ANCHOR HOOK
  const dispatch = useDispatch();
  const limitation = useSelector(selectLimitation);
  const navigate = useNavigate();
  let playedTrack = getPlayerMusic();
  const user = useSelector(selectUserData);
  let userToken = getUserToken();
  const action = useAction();
  const playerSwipeStatus = getSwipePlayerStatus();
   const [getPlayer] = GetPlayerRef();
  // ANCHOR API
  const setStreamQualityMutation = useMutation(
    (data) => setStreamingQuality(data),
    {
      onSuccess: (res) => {
        const newUserData = {
          ...user,
          streaming_quality: res?.data?.result?.user?.streaming_quality,
        };
        dispatch(userData({ user: newUserData }));
        if (playedTrack) {
          let cdn_url = createMusicCdnLink(
            playedTrack,
            res?.data?.result?.user,
            userToken
          );
          dispatch(changeTrackQuality({ cdn_url }));
          
          // const mainPlayer = document.querySelector('#playerDDD video');
           const mainPlayer = getPlayer();
          mainPlayer.src = cdn_url
          mainPlayer.play()
          dispatch(setIsPlaying({ isPlay: true }));
        }
        openBottomSheetSetter(false);
      },
    }
  );

  // ANCHOR HANDELER
  const handleSelectStreamQuality = (quality) => {
    if(user?.streaming_quality === quality) {
      openBottomSheetSetter(false);
      return
    }
    const streamKey = `stream_quality_${quality}`;
        const requestData = { quality };
    if (limitation[streamKey]?.enable) {
      dispatch(closeDropDownMenu());
      dispatch(setActiveIndex({ index: null }));
      // dispatch(setLimitationDialogData({ limitationData }));
      closeParent()
      openBottomSheetSetter(false)
      dispatchSwipePlayerStatus(false);
      action({ target_type: "plans",openPlayerWhenBack:playerSwipeStatus });
    } else {
      const newUserData = { ...user, streaming_quality: quality };
      dispatch(userData({ user: newUserData }));

      setStreamQualityMutation.mutate(requestData);
    }
  };

  return (
    <div className="flex flex-col  bg-primary_dark    ">
      <BottomSheetHeader
        closeBottomSheet={() => openBottomSheetSetter(false)}
        HeaderIcon={<BroadcastQualitySvg/>}
        title={<span className="font-semibold text-base">کیفیت پخش</span>}
        dividerLine
      />
      <div className='relative flex flex-col gap-2 px-4 pt-2  pb-4  '>
        {setStreamQualityMutation?.isLoading &&
            <div
                className='absolute bg-primary_dark bg-opacity-90 z-[10] top-0 left-0 w-full h-full flex justify-center items-center'>
              <div className='w-[120px] h-[120px]'>
                <MusicNoteLoading speed={2}/>
              </div>
            </div>
        }
        {streamQualityList.map((item, index) => (
            <div
                dir="rtl"
                onClick={() => {
                  handleSelectStreamQuality(item?.quality);
                }}
                key={index + "-quality" + item}
            >
              <QualityCard
                  isChecked={user?.streaming_quality == item.quality}
                  item={item}
                  isPremiumlUser={user?.is_premium}
              />
            </div>
        ))}

      </div>

    </div>
  );
}

export default StreamQuality;
