import React, {useState, useRef,useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useInfiniteQuery} from "@tanstack/react-query";
import {getPopularTracks} from "../../services/ApiClient";
import SongDetailCard from "../../components/playlist/SongDetailCard.jsx";
import Container from "../../components/PageParentContainer/Container.jsx";
import CommonHeader from "../../components/common/CommonHeader.jsx";
import InfiniteScroll from "react-infinite-scroll-component";
import SongsDetailCardShimmer from "../../components/Tickets/SongsDetailCardShimmer.jsx";
import {Virtuoso} from "react-virtuoso";
import SongListLoading from "../../components/loading/SongListLoading.jsx";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperTabs from "./SwiperTabs.jsx";
import {isDesktop} from "react-device-detect";
import {useDispatch} from "react-redux";
import {addToPlaylistData} from "../../helper/ReduxSelectorContext.js";
import {disableListSelectMode} from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import AddToPlaylistModeButton from "../../components/playlist/AddToPlaylistModeButton.jsx";
import ScrollTopButton from "../../components/common/ScrollTopButton.jsx";


function PopularSongs() {
    const [HasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
        setHasLoaded(true);
    }, []);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const languages = params.get("language");
    const dispatch=useDispatch()
    const addToPlaylistDataState = addToPlaylistData()
    const {
        isLoading: getMonthPopularSongsIsLoading,
        isFetchingNextPage: getMonthPopularSongsIsFetchingNextPage,
        fetchNextPage: getMonthPopularSongsIsFetchNextPage,
        hasNextPage: getMonthPopularSongsHasNextPage,
        data: getMonthPopularSongsData
    } = useInfiniteQuery([`getMonthPopularSongsInfiniteQuery${languages}`], ({pageParam = 0}) => getPopularTracks({
            type: "month",
            language: languages,
            offset: pageParam,
        }),
        {
            getNextPageParam: (lastPage, allPages) => {
                const allData = allPages?.flatMap((page) => page.data.result.tracks);
                return lastPage?.data?.result?.end ? false : allData?.length;
            },
            refetchOnWindowFocus: false
        })
    const getMonthPopularSongsTracks = getMonthPopularSongsData?.pages?.flatMap((page) => page.data.result.tracks);
    const getMonthPopularSongsNextPage = () => {
        if (getMonthPopularSongsHasNextPage && !getMonthPopularSongsIsFetchingNextPage) {
            getMonthPopularSongsIsFetchNextPage();
        }

    };
    const {
        isLoading: getWeekPopularSongsIsLoading,
        isFetchingNextPage: getWeekPopularSongsIsFetchingNextPage,
        fetchNextPage: getWeekPopularSongsIsFetchNextPage,
        hasNextPage: getWeekPopularSongsHasNextPage,
        data: getWeekPopularSongsData
    } = useInfiniteQuery([`getWeekPopularSongsInfiniteQuery${languages}`], ({pageParam = 0}) => getPopularTracks({
            type: "week",
            language: languages,
            offset: pageParam,
        }),
        {
            getNextPageParam: (lastPage, allPages) => {
                const allData = allPages?.flatMap((page) => page.data.result.tracks);
                return lastPage?.data?.result?.end ? false : allData?.length;
            },
            refetchOnWindowFocus: false
        })
    const getWeekPopularSongsTracks = getWeekPopularSongsData?.pages?.flatMap((page) => page.data.result.tracks);
    const getWeekPopularSongsNextPage = () => {
        if (getWeekPopularSongsHasNextPage && !getWeekPopularSongsIsFetchingNextPage) {
            getWeekPopularSongsIsFetchNextPage();
        }

    };
    const [popSlideIndex, setPopSlideIndex] = useState(1)

    function handleIndex(swiper) {
        setPopSlideIndex(swiper.activeIndex)
    }
    const collectionObject = {
        id: "1111",
    }
    useEffect(() => {
        return () => {
            dispatch(disableListSelectMode())
        };
    }, []);
    return (
        <Container header={<CommonHeader title={'محبوب ترین ها'}/>}
                   scroll={false}
                   style={"!overscroll-none"}>
            <AddToPlaylistModeButton EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}/>
            <ScrollTopButton show={popSlideIndex === 1} id={"scrollbarPopWeek"} />
            <ScrollTopButton show={popSlideIndex === 0} id={"scrollbarPopMonth"} />
            <Swiper cssMode={!isDesktop} className={"w-full"}
                    onSlideChange={handleIndex}
                    initialSlide={1}>
                <span slot="container-start">
                    <SwiperTabs slideIndex={popSlideIndex} setSlideIndex={setPopSlideIndex}
                                tabContents={
                                    {
                                        first: <>این ماه</>,
                                        second: <>این هفته</>
                                    }
                                }/>
                </span>

                <SwiperSlide className={"px-4 w-fit !webkit-overflow-scrolling-touch  overflow-hidden"}>
                    <div id="scrollbarPopMonth"
                         className={"w-full mx-auto h-full !overflow-y-scroll  !overflow-x-hidden !webkit-overflow-scrolling-touch"}>
                        {getMonthPopularSongsTracks ?
                            <InfiniteScroll
                                className={"!overflow-hidden"}
                                next={getMonthPopularSongsNextPage}
                                hasMore={getMonthPopularSongsHasNextPage}
                                dataLength={getMonthPopularSongsTracks?.length || 0}
                                loader={
                                  getMonthPopularSongsIsFetchingNextPage &&
                                    <div className="w-full flex justify-center items-start h-[100px] -mt-20 ">
                                        <SongListLoading/>
                                    </div>
                                }
                                scrollableTarget="scrollbarPopMonth">

                                <div className={"w-full h-full overflow-hidden pb-28 mt-3"}>
                                    <Virtuoso
                                        style={{overflowY: 'scroll !important'}}
                                        customScrollParent={document.getElementById('scrollbarPopMonth')}
                                        data={getMonthPopularSongsTracks}
                                        itemContent={(index, item) => (
                                            <div className={"flex flex-col py-[0.175rem]"}>
                                                <SongDetailCard
                                                    isSelectMode={addToPlaylistDataState?.listSelectItems?.length > 0}
                                                    inPlayer={false}
                                                    id={item}
                                                    number={false}
                                                    index={index}
                                                    data={item}
                                                    playList={getMonthPopularSongsTracks}
                                                    collectionObject={collectionObject}
                                                    apiUrl={'/getTrendingTracks'}
                                                    requestPayload={{
                                                        type: "month",
                                                        language: languages,
                                                    }}
                                                    endOfPagination={!getMonthPopularSongsHasNextPage}
                                                />
                                            </div>
                                        )}/>
                                </div>
                            </InfiniteScroll>
                            :
                        <div className={"flex flex-col gap-3 mt-2"}>
                            {Array.from(
                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                                    <SongsDetailCardShimmer/>
                                ))
                            )}
                        </div>
                        }

                    </div>
                </SwiperSlide>
                <SwiperSlide className={"px-4 w-fit !webkit-overflow-scrolling-touch  overflow-hidden"}>
                    <div id="scrollbarPopWeek"
                         className={"h-full !overflow-y-scroll !overflow-x-hidden !webkit-overflow-scrolling-touch"}>
                        {getWeekPopularSongsTracks ?
                            <InfiniteScroll
                                className={"!overflow-hidden"}
                                next={getWeekPopularSongsNextPage}
                                hasMore={getWeekPopularSongsHasNextPage}
                                dataLength={getWeekPopularSongsTracks?.length || 0}
                                loader={
                                  getWeekPopularSongsIsFetchingNextPage &&
                                    <div className="w-full flex justify-center items-start h-[100px] -mt-20 ">
                                        <SongListLoading/>
                                    </div>
                                }
                                scrollableTarget="scrollbarPopWeek">
                                <div className={"w-full h-full !overflow-hidden pb-28 mt-3"}>
                                    <Virtuoso
                                        style={{overflowY: 'scroll !important'}}
                                        customScrollParent={document.getElementById('scrollbarPopWeek')}
                                        data={getWeekPopularSongsTracks}
                                        itemContent={(index, item) => (
                                            <div className={"flex flex-col py-[0.175rem]"}>
                                                <SongDetailCard
                                                    isSelectMode={addToPlaylistDataState?.listSelectItems?.length > 0}
                                                    inPlayer={false}
                                                    id={item}
                                                    number={false}
                                                    index={index}
                                                    data={item}
                                                    playList={getWeekPopularSongsTracks}
                                                    collectionObject={collectionObject}
                                                    apiUrl={'/getTrendingTracks'}
                                                    requestPayload={{
                                                        type: "week",
                                                        language: languages,
                                                    }}
                                                    endOfPagination={!getWeekPopularSongsHasNextPage}
                                                />
                                            </div>
                                        )}/>
                                </div>
                            </InfiniteScroll>
                            :
                            <div className={"flex flex-col gap-3 mt-2"}>
                                {Array.from(
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                                        <SongsDetailCardShimmer/>
                                    ))
                                )}
                            </div>
                        }
                    </div>
                </SwiperSlide>
            </Swiper>
        </Container>

    );
}

export default PopularSongs;
