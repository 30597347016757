import React from "react";
import { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { getUpdateDialog } from "../../helper/ReduxSelectorContext";
import updateIcon from "../../components/svg/common/UpdateSVG.jsx";
import ClickReaction from "../../helper/ClickReaction";
import Loading from "../loading/Loading";
import DialogHeader from "./DialogHeader.jsx";
import UpdateSVG from "../../components/svg/common/UpdateSVG.jsx";

function UpdateDialog() {
  const [showLoading, setShowLoading] = useState(false);
  let updateDialog = getUpdateDialog();

  const handleUpdate = () => {
    setShowLoading(true);
    window.location.reload();
  };
  const handleCloseDialog = () => {
    updateDialog = false;
  };
  return (
    <Transition appear show={updateDialog} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4  text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-secondary p-4  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={"بروزرسانی جدید"}
                  // onCloseDialog={handleCloseDialog}
                />
                <div className="px-6 w-[90%] mx-auto flex flex-col items-center mt-۲ font-dana">
                  <div className="h-[60px]">
                    <UpdateSVG classNames={"h-full w-full"} />
                    {/*<img alt={''} className="w-full h-full" src={updateIcon} />*/}
                  </div>
                  <p className="text-primary text-lg font-semibold text-center mt-1">
                    تغییرات جدید
                  </p>
                </div>
                <ClickReaction>
                  <div className=" w-full flex items-center justify-center px-6 mb-2 mt-1">
                    <button
                      onClick={() => handleUpdate()}
                      type="button"
                      className="flex text-black bg-primary h-[40px] items-center  w-[200px]  text-[16px] font-dana font-[600]  justify-center rounded-[100px] border border-transparent  px-4  py-3  focus:outline-none"
                    >
                      {showLoading ? (
                        <Loading bgColor="#303030" />
                      ) : (
                        <p className="text-secondary font-semibold text-lg">
                          بروزرسانی
                        </p>
                      )}
                    </button>
                  </div>
                </ClickReaction>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default UpdateDialog;
