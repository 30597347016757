import React, { memo } from 'react';
import { getIsPlaying } from '../../helper/ReduxSelectorContext.js';
import UserPublishedTrackTextSection from './UserPublishedTrackTextSection.jsx';
import UserPublishedTrackImageSection from './UserPublishedTrackImageSection.jsx';

function UserPublishedTrackInfoSection({
  item,
  number,
  index,
  currentTrack,
  ShowItemIsPlaying,
}) {
  const isPlaying = getIsPlaying();
  return (
    <div
      className={`flex items-center relative   ${
        item?.is_demo ? '  w-[40%] max-w-[40%]' : ' w-[40%] max-w-[40%]'
      } `}
    >
      {number && (
        <div className="flex items-center  justify-center  min-w-[50px]">
          <p className="text-xs font-dana font-medium text-gray_4 ">
            {index + 1}
          </p>
        </div>
      )}
      <div className="flex items-center gap-[6px]  w-full">
        <UserPublishedTrackImageSection
          item={item}
          ShowItemIsPlaying={ShowItemIsPlaying}
          currentTrack={currentTrack}
          isPlaying={isPlaying}
        />
        <UserPublishedTrackTextSection
          currentTrack={currentTrack}
          item={item}
          ShowItemIsPlaying={ShowItemIsPlaying}
        />
      </div>
    </div>
  );
}

export default memo(UserPublishedTrackInfoSection);
