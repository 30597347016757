import { memo } from "react";

const TelegramSvg = (props) => (
  <svg
    width={20}
    height={18}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.6084 0.638304C19.2866 0.343499 18.8293 0.245257 18.4148 0.381859L0.829345 6.17877C0.350087 6.33674 0.025087 6.76455 0.00137607 7.26858C-0.0222958 7.77264 0.261103 8.22908 0.723368 8.43139L5.07239 10.3342C5.0936 10.4326 6.34208 16.2231 6.38454 16.4199C6.43903 16.6728 6.56735 16.898 6.83919 16.9672C7.11415 17.0373 7.30841 16.887 7.51356 16.7334C7.62774 16.6479 10.7409 14.3168 10.7409 14.3168L14.5139 17.4042C14.7337 17.5842 15.0023 17.678 15.2761 17.678C15.4065 17.678 15.5382 17.6567 15.6662 17.6133C16.0633 17.4786 16.3577 17.1545 16.4537 16.7464L19.968 1.80487C20.0679 1.38006 19.9301 0.933109 19.6084 0.638304ZM7.7947 11.4246C7.79255 11.4296 7.79044 11.4354 7.78845 11.4422L7.06095 13.984L6.24907 10.2185L11.8318 7.11678L7.9286 11.198C7.86712 11.2622 7.82188 11.34 7.7947 11.4246ZM8.02559 14.8826L8.35602 13.7281L8.67208 12.6238L9.80454 13.5505L8.02559 14.8826ZM18.8245 1.53588L15.3103 16.4774C15.3085 16.4848 15.3062 16.495 15.289 16.5008C15.272 16.5066 15.2637 16.5 15.2578 16.4952L11.1293 13.1167C11.1291 13.1167 11.1291 13.1165 11.129 13.1165L9.2161 11.5512L15.3615 5.12545C15.5622 4.91557 15.5794 4.59053 15.402 4.36065C15.2244 4.13073 14.9056 4.06506 14.6518 4.20616L5.55216 9.26198L1.19423 7.35522C1.18095 7.34944 1.17364 7.34619 1.1747 7.32373C1.17575 7.30135 1.18333 7.29881 1.19708 7.29428L18.7825 1.49741C18.7911 1.49463 18.8007 1.49143 18.8148 1.50432C18.829 1.51729 18.8266 1.52713 18.8245 1.53588Z"
      fill="#E0E0E0"
    />
  </svg>
);
export default memo(TelegramSvg);
