import React from 'react';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import TextMarquee from '../common/TextMarquee.jsx';

function CollectionsListTitleSection({ title,subtitle }) {
  return (
    <div className="flex flex-col w-full ">
      <div className="flex  flex-col  w-full ">
        <p
          dir={setLanguageDirection(title)}
          className="font-medium text-[40px] font-kalameh text-white leading-[68px] w-full"
        >
          <TextMarquee direction={setLanguageDirection(title)}>
            {title}
          </TextMarquee>
        </p>
        <p
          dir={setLanguageDirection(title)}
          className="font-dana font-normal text-[12px] leading-6 text-white text-right w-full "
        >
          <TextMarquee
            noMarqueeParentStyle={
              setLanguageDirection(title) === "ltr" && "justify-end"
            }
            direction={setLanguageDirection(title)}
          >
            {subtitle}
          </TextMarquee>
        </p>
      </div>
    </div>
  );
}

export default CollectionsListTitleSection;
