import { memo } from "react";

const BroadcastQualitySvg = (props) => (
  <svg
    width={20}
    height={24}
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.0711 9.21408C15.1823 7.32533 12.6711 6.28516 10 6.28516C7.32888 6.28516 4.81768 7.32533 2.92893 9.21408C1.04018 11.1028 0 13.614 0 16.2852V19.4994C0 21.6656 1.76237 23.428 3.92857 23.428C6.09478 23.428 7.85714 21.6656 7.85714 19.4994C7.85714 17.3332 6.09478 15.5709 3.92857 15.5709C2.97969 15.5709 2.1083 15.909 1.42857 16.4712V16.2852C1.42857 11.5589 5.27371 7.71373 10 7.71373C14.7263 7.71373 18.5714 11.5589 18.5714 16.2852V16.4712C17.8917 15.909 17.0203 15.5709 16.0714 15.5709C13.9052 15.5709 12.1429 17.3332 12.1429 19.4994C12.1429 21.6656 13.9052 23.428 16.0714 23.428C18.2376 23.428 20 21.6656 20 19.4994V16.2852C20 13.614 18.9598 11.1028 17.0711 9.21408ZM3.92857 16.9994C5.30705 16.9994 6.42857 18.121 6.42857 19.4994C6.42857 20.8779 5.30705 21.9994 3.92857 21.9994C2.55009 21.9994 1.42857 20.8779 1.42857 19.4994C1.42857 18.121 2.55009 16.9994 3.92857 16.9994ZM16.0714 21.9994C14.6929 21.9994 13.5714 20.8779 13.5714 19.4994C13.5714 18.121 14.6929 16.9994 16.0714 16.9994C17.4499 16.9994 18.5714 18.121 18.5714 19.4994C18.5714 20.8779 17.4499 21.9994 16.0714 21.9994Z"
      fill="#E0E0E0"
    />
    <path
      d="M14.1624 2.83843C14.0697 2.83843 13.9756 2.82031 13.8849 2.782C12.6555 2.26303 11.3488 1.99986 10.0011 1.99986C8.65331 1.99986 7.34661 2.26303 6.11728 2.782C5.75384 2.93544 5.33487 2.76522 5.18143 2.40178C5.02799 2.03834 5.19822 1.61937 5.56165 1.46593C6.96777 0.872271 8.46139 0.571289 10.0011 0.571289C11.5408 0.571289 13.0344 0.872271 14.4405 1.46593C14.8039 1.61937 14.9742 2.03834 14.8207 2.40178C14.7056 2.67446 14.4409 2.83839 14.1624 2.83843Z"
      fill="#E0E0E0"
    />
    <path
      d="M12.2035 4.7987C12.1402 4.7987 12.0759 4.79022 12.0119 4.77245C11.3609 4.59156 10.6847 4.49986 10.0018 4.49986C9.31885 4.49986 8.64256 4.59156 7.99166 4.77245C7.61153 4.87803 7.21782 4.65557 7.1122 4.27549C7.00657 3.8954 7.22907 3.50165 7.60916 3.39602C8.38461 3.18053 9.18961 3.07129 10.0018 3.07129C10.8139 3.07129 11.6189 3.18053 12.3943 3.39602C12.7744 3.50165 12.9969 3.8954 12.8913 4.27549C12.8034 4.59156 12.5163 4.7987 12.2035 4.7987Z"
      fill="#E0E0E0"
    />
  </svg>
);
export default memo(BroadcastQualitySvg) ;
