import {useEffect, useRef, useState} from "react";

export const useTimer = () => {
    const intervalRef = useRef(null);
    const [startTime, setStartTime] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    const startTimer = () => {
        if (!isRunning) {
            setStartTime(Date.now() - elapsedTime);
            setIsRunning(true);
        }
    }

    const stopTimer = () => {
        clearInterval(intervalRef.current)
        setIsRunning(false);
    }

    const resetTimer = () => {
        clearInterval(intervalRef.current)
        setStartTime(0)
        setElapsedTime(0)
        setIsRunning(false);
    }

    const addTime = (addedTime) => {
        setElapsedTime((prevState) => prevState + addedTime)
    }
    const getElapsedSeconds = () => {
        return Math.floor(elapsedTime / 1000);
    };
    useEffect(() => {
        if (isRunning) {
            intervalRef.current = setInterval(() => {
                const currentElapsedTime = Date.now() - startTime
                setElapsedTime(currentElapsedTime)
            }, 1000)
        }

        return () => {
            clearInterval(intervalRef.current)
        };
    }, [isRunning, startTime]);

    return {
        startTimer, stopTimer, resetTimer, addTime, elapsedTime:getElapsedSeconds(elapsedTime),isRunning
    }
}

