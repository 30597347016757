import * as React from "react";
const DownloadQualitySvg = (props) => (
  <svg
    width={20}
    height={24}
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.869 5.011a8.178 8.178 0 0 1 2.264 0 .834.834 0 0 0 .236-1.65 9.896 9.896 0 0 0-2.735 0 .833.833 0 0 0 .235 1.65Z"
      fill="#E0E0E0"
    />
    <path
      d="M14.546 4.356a.834.834 0 0 0-.758 1.484 8.306 8.306 0 0 1 4.545 7.425c0 4.595-3.738 8.333-8.333 8.333-4.595 0-8.333-3.738-8.333-8.333A8.305 8.305 0 0 1 6.213 5.84a.833.833 0 0 0-.759-1.483A9.964 9.964 0 0 0 0 13.265c0 5.514 4.486 10 10 10s10-4.486 10-10a9.965 9.965 0 0 0-5.454-8.909Z"
      fill="#E0E0E0"
    />
    <path
      d="M6.076 15.177a.833.833 0 0 0 0 1.178L9.41 19.69a.83.83 0 0 0 1.178 0l3.334-3.334a.833.833 0 0 0-1.179-1.178l-1.91 1.91V7.433a.834.834 0 0 0-1.668 0v9.656l-1.91-1.911a.833.833 0 0 0-1.179 0Zm8.199-12.91a.712.712 0 0 1-.277-.056 9.931 9.931 0 0 0-3.884-.783 9.93 9.93 0 0 0-3.884.783.714.714 0 1 1-.555-1.316A11.35 11.35 0 0 1 10.114 0c1.54 0 3.033.301 4.44.895a.714.714 0 0 1-.279 1.372Z"
      fill="#E0E0E0"
    />
  </svg>
);
export default DownloadQualitySvg;
