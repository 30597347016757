import React from "react";

function SearchIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 21"
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 17.088c4.418 0 8-3.602 8-8.044C17 4.6 13.418 1 9 1S1 4.601 1 9.044c0 4.442 3.582 8.044 8 8.044zM19 19.099l-4.35-4.374"
      ></path>
    </svg>
  );
}

export default SearchIcon;
