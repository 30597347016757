import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { hideUnfollowDialog } from "../../redux/globalData/globalDataSlice.js";
import { globalData } from "../../helper/ReduxSelectorContext.js";
import { useFollowCollection } from "../../helper/useFollowCollection.js";
import { useFollow } from "../../helper/useFollow.js";
import DialogHeader from "../dialogs/DialogHeader.jsx";
import UnfollowDialog from './UnfollowDialog.jsx';

function GlobalUnfollowDialog() {
  const { unfollowDialogState, unfollowItem } = globalData();
  const [, , unfollowArtist] = useFollow(unfollowItem?.id, unfollowItem?.queryKey);
  const [, , unfollowCollection] = useFollowCollection(unfollowItem?.id);
  const handleUnfollow = () => {
    if (unfollowItem?.key === "artist") {
      unfollowArtist();
    } else {
      unfollowCollection();
    }
  };
  const dispatch = useDispatch();
  const closeDialog = () => {
    dispatch(hideUnfollowDialog());
  };

  return (
      <UnfollowDialog closeDialog={closeDialog} type={unfollowItem?.key} confirmFunction={handleUnfollow} openState={unfollowDialogState}/>
  );
}

export default GlobalUnfollowDialog;
