import React from 'react';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import { usePlayedTrack } from '../../hooks/usePlayedTrack.js';
import { textFontBasedLanguage } from '../../helper/TextFontBasedLanguage.js';

function SongCardTitleSection({ item, ShowItemIsPlaying }) {
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  return (
    <div className="flex flex-col w-full items-start xl:ml-3 ">
      <p
        dir={setLanguageDirection(item?.title)}
        className={`${
          currentTrack?.id == item?.id && ShowItemIsPlaying
            ? 'text-yellow'
            : 'text-white'
        } text-[14px] max-w-full ${textFontBasedLanguage(item?.title)} font-[500] text-gray_5  leading-6 whitespace-nowrap  truncate `}
      >
        {item?.title}
      </p>
      <p
        dir={setLanguageDirection(item?.title)}
        className="text-gray_3 text-[12px] font-medium font-dana leading-6  whitespace-nowrap truncate max-w-full"
      >
        {' '}
        {item?.artists.map((artist, index) => {
          return (
            <span className={textFontBasedLanguage(item?.title)} dir={setLanguageDirection(item?.title)} key={index}>
              {artist?.name}{' '}
              {index !== item?.artists?.length - 1 &&
                (setLanguageDirection(item?.title) === 'rtl' ? '، ' : ', ')}
            </span>
          );
        })}
      </p>
    </div>
  );
}

export default React.memo(SongCardTitleSection);
