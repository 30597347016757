import React, { useState } from "react";
import { useSelector } from "react-redux";
import CheckIconSvg from "../svg/common/CheckIconSvg.jsx";
import MinusIconSvg from "../svg/common/MinusIconSvg.jsx";
import PlusIconSvg from "../svg/common/PlusIconSvg.jsx";
import { selectInitialData } from "../../redux/initialize/InitializeSlice.js";

function PlansFeature(props) {
  const [showAllFeature, setShowAllFeature] = useState();
  const toggleShowMore = () => {
    setShowAllFeature((prevState) => !prevState);
  };
  const initialDataSelector = useSelector(selectInitialData);
  return (
    <div className="flex flex-col gap-2 px-4">
      <div className="flex gap-2 items-center">
        <div className="bg-primary w-[8px] h-[31px] rounded-[4px]"></div>
        <p className="font-kalameh font-[450] text-[19px] text-primary">
          {initialDataSelector?.premium_feature_data?.title}

        </p>
      </div>
      <div className="flex  w-full flex-col gap-2 ">
        <div className="flex gap-2 items-center">
          <div className="w-2 h-2 rounded-full bg-gray_5"></div>
          <p className="text-[14px] font-dana font-medium text-gray_5 leading-6">
            {initialDataSelector?.premium_feature_data?.subtitle}
          </p>
        </div>
        <div
          className={`flex  w-full flex-col gap-2 transition-all  duration-300 overflow-hidden ${
            showAllFeature ? "max-h-[400px]" : "max-h-[180px]"
          }`}
        >
          {initialDataSelector?.premium_feature_data?.features.map((item) => (
            <div className="w-full flex  gap-3 items-center pr-4 leading-6 ">
              <div className="w-[16px] h-[12px]">
                <CheckIconSvg />
              </div>
              <p className="text-[12px]  font-dana font-medium text-gray_5 ">
                {item?.feature}
              </p>
            </div>
          ))}
        </div>
      </div>
      {showAllFeature ? (
        <div onClick={toggleShowMore} className="flex gap-2 pr-4 items-center">
          <div className="w-[14px] h-[2px]">
            <MinusIconSvg />
          </div>
          <p className="text-[12px] font-dana font-semibold text-primary">
            بستن
          </p>
        </div>
      ) : (
        <div
          onClick={toggleShowMore}
          className="flex gap-2 pr-4 items-center leading-6"
        >
          <div className="w-[14px] h-[14px]">
            <PlusIconSvg />
          </div>
          <p className="text-[12px] font-dana font-semibold text-primary ">
            مشاهده‌ی بیشتر
          </p>
        </div>
      )}
    </div>
  );
}

export default PlansFeature;
