import { memo } from "react";

function NoMusicSvg({ fill = "#4F4F4F", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="34"
      viewBox="0 0 56 34"
      fill={fill}
      {...props}
    >
      <path
        fill="inherit"
        d="M28.011.24C17.558.24 9.016 8.45 8.421 18.754h-.377c-4.128 0-7.492 3.374-7.492 7.514 0 4.129 3.364 7.49 7.492 7.49h1.44c.63 0 1.145-.515 1.145-1.144V19.898c0-9.572 7.787-17.371 17.371-17.371s17.372 7.787 17.372 17.371v12.717c0 .629.514 1.143 1.143 1.143h1.441c4.129 0 7.492-3.36 7.492-7.5 0-4.129-3.363-7.503-7.492-7.503h-.353C47.01 8.45 38.453.239 28.011.239zm-.01 6.998c-6.989 0-12.673 5.684-12.673 12.671 0 6.988 5.684 12.661 12.672 12.661s12.672-5.684 12.672-12.672S34.988 7.238 28 7.238zm0 2.276c5.717 0 10.384 4.655 10.384 10.384 0 5.73-4.666 10.385-10.385 10.385-5.73 0-10.384-4.655-10.384-10.385 0-5.73 4.654-10.384 10.384-10.384zm-3.827 5.419a1.14 1.14 0 00-.806 1.945l3.02 3.02-3.02 3.02a1.14 1.14 0 00.813 1.944c.297 0 .582-.114.81-.331l3.01-3.009 3.02 3.02c.228.229.513.33.81.33.297 0 .584-.113.813-.33a1.14 1.14 0 000-1.613l-3.02-3.02 3.02-3.02a1.164 1.164 0 00-.011-1.623 1.14 1.14 0 00-1.613 0L28 18.285l-3.02-3.02a1.135 1.135 0 00-.806-.333z"
      ></path>
    </svg>
  );
}

export default memo(NoMusicSvg);
