import React from "react";


function ChevronMainSvg() {
  return (
      <svg
          className='w-full h-full'
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M7 13L1 7L7 1"
              stroke="#F2C94C"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
          />
      </svg>
  );
}

export default ChevronMainSvg;
