import React from "react";
import {useAction} from "../../hooks/useAction";


function PlaylistMetaDataWithTitle({
                                       metaData = {
                                           title: "پلی لیست ها",
                                           show_more: true,
                                           title_color: "#FFFFFF",
                                           show_more_color: "#FFFFFF",
                                           type: "suggested_categories",
                                           has_background: false,
                                           show_more_text: "  دیدن همه",
                                           action: {
                                               target_type: "home_collection",
                                           },
                                       },
                                       hasChevronButton = true,
                                       eventClassName = {prev: "prev-button", next: "next-button"},
                                       item,
                                       artistCollection = false,
                                       fromMyMelodify,
                                       fromAlbums = false,
                                   }) {
    const action = useAction();
    const actionData = () => {
        if (metaData?.type === "open_album") {
            return metaData?.action;
        }
        if (metaData?.action?.target_type === "home_collection") {
            return metaData?.action;
        } else {

                return metaData.action;

        }
    };
    const handleAction = () => {
        console.log("DGsdgsdgsdgg", metaData);
        console.log(item, "metaData");
        action(actionData());
    };
    // console.log("fsdfsdfssdasdfd", metaData?.action);
    return (
        <div dir="rtl" className="flex justify-between   items-center relative mb-2">
            <div
                className={`flex flex-row  flex-col w-full   `}
            >
              <div className="flex justify-between">
                  <p className="text-white font-dana font-normal text-lg leading-6   ">
                      {metaData?.title}
                  </p>
                  <div className="flex   ">

                      <div onClick={() => metaData?.show_more && handleAction()} className={` ${
                          metaData?.show_more ? "visible" : "invisible"
                      } flex items-center cursor-pointer gap-2 `}>
                          <p
                              className={`  font-medium text-xs  font-dana   `}
                              style={{color: metaData?.show_more_color?metaData?.show_more_color:'#fff'}}
                          >
                              {metaData?.show_more_text}
                          </p>
                          <div className={`w-[6px] h-[10px]`}>

                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className='w-full h-full'
                                  fill="none"
                                  viewBox="0 0 6 10"
                              >
                                  <path
                                      stroke="#E0E0E0"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.333"
                                      d="M5 9L1 5l4-4"
                                  ></path>
                              </svg>

                          </div>
                      </div>
                  </div>
              </div>

                <p
                    className={`  font-dana text-gray_4 text-xs font-normal leading-6   `}
                    style={{color: metaData?.subtitle_color}}
                >
                    {metaData?.subtitle}
                </p>
            </div>


        </div>
    );
}

export default PlaylistMetaDataWithTitle;
