import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getUserIdFromGlobalStore } from "../redux/globalstore/GlobalStore";
import { APP_VERSION_NAME } from "../BASE";

export const CentryConfig = () => {

    Sentry.init({
        dsn: process.env.NODE_ENV !== "development" ? "https://ec7156acc97648ac824bfe10f4f03327@sentry.acecoders.net/37" : "https://8a3174af7ac34d6f95c90af52a268a42@sentry.acecoders.net/38",
        integrations: [new BrowserTracing({
            tracingOrigins: ["192.168.1.106", "localhost", "test-pwa-react.melodify.pw", "debugpwa.melodify.pw", "pwa.melodify.app", /^\//],
        })],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.01,
    });
    Sentry.setContext("token", {
        token: getUserIdFromGlobalStore()
    })

    Sentry.setTag("user_id", getUserIdFromGlobalStore());
    Sentry.setTag("pwa_version", APP_VERSION_NAME);
}
