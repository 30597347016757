import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import LumpSvg from "../svg/support/LumpSvg.jsx";
import {CardActionArea} from "@mui/material";
import CheckIconSvg from "../svg/common/CheckIconSvg.jsx";
import SwipeBottomSheet from "../bottomSheets/SwipeBottomSheet.jsx";
import React from "react";
import {useNavigate} from "react-router-dom";

export default function ShowAnswerSheet({ open, setOpen, sheetData,navigateToCreateTicket}) {
    const navigate = useNavigate()
    const toggleShowAnswerBottomSheet = (newOpen) => () => {
        setOpen(newOpen);
    };
    const navigateToProfile = () => {
        navigate("/my-melodify",{replace:true});
    };
    return (
        <SwipeBottomSheet
            open={open}
            toggleDrawer={toggleShowAnswerBottomSheet}>
            <BottomSheetHeader title=""
                               dividerLine
                               closeBottomSheet={() => setOpen(false)}
            />
            <div dir="rtl" className={"text-sm font-dana mb-4 font-[600]"}>
                <div className={"question w-full py-4 px-8"}>
                    <p className={"text-primary mb-2"}>
                        پرسش:
                    </p>
                    <p className={"text-gray_4"}>
                        {sheetData?.question}
                    </p>
                </div>
                <div className={"question w-full py-4 px-8"}>
                    <div className={"mb-4"}>
                        <LumpSvg classList={'w-6 h-6 inline ml-2'}/>
                        <p className={"text-primary mb-4 inline"}>
                            پاسخ:
                        </p>
                    </div>
                    <p className={"text-white font-bold !leading-6"}
                       dangerouslySetInnerHTML={{
                           __html: sheetData?.answer,
                       }}>
                    </p>
                </div>
                <div className={"w-full inline-flex justify-between gap-3 p-4 mt-4"}>
                    <CardActionArea className="!rounded-full !w-[40%] shadow shadow-md">
                        <button
                            onClick={() => navigateToProfile()}
                            type="button"
                            className={`text-primary_dark text-center bg-primary inline-flex h-[50px] text-sm font-semibold w-full justify-center items-center rounded-full focus:outline-none`}
                        >
                            <CheckIconSvg fill={"#212121"} classList={"w-[15px] h-[20px] ml-2"}/>
                            متوجه شدم
                        </button>
                    </CardActionArea>
                    <CardActionArea className="!rounded-full !w-[60%] shadow shadow-md">
                        <button
                            onClick={() => navigateToCreateTicket(sheetData)}
                            type="button"
                            className={`text-primary_dark text-center bg-primary inline-flex h-[50px] text-sm font-semibold w-full justify-center items-center rounded-full focus:outline-none`}
                        >
                            جوابم را نگرفتم | ‌ثبت تیکت
                        </button>
                    </CardActionArea>
                </div>
            </div>
        </SwipeBottomSheet>

    )
}