import React from 'react';

function NoUserPlaylists(props) {

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="94"
                height="98"
                fill="none"
                viewBox="0 0 94 98"
            >
                <g
                    fill="#4F4F4F"
                    clipPath="url(#clip0_12_1305)"
                    filter="url(#filter0_d_12_1305)"
                >
                    <path d="M46.293 32.826a9.232 9.232 0 00-5.79-2.035c-5.118 0-9.282 4.164-9.282 9.282 0 5.117 4.164 9.282 9.282 9.282 4.856 0 8.85-3.75 9.247-8.506l.035-24.684 13.942.002a2.79 2.79 0 002.712-2.057 2.79 2.79 0 00-1.25-3.166L48.946 1.076c-1.143-.695-2.652.157-2.652 1.491v30.259zm3.492-27.154l11.526 7.002H49.785V5.671zm-9.282 40.19a5.796 5.796 0 01-5.79-5.79 5.796 5.796 0 015.79-5.79 5.797 5.797 0 015.79 5.79 5.796 5.796 0 01-5.79 5.79zM66.46 57.26a7.822 7.822 0 00-4.376-1.332c-4.34 0-7.87 3.53-7.87 7.868 0 4.339 3.531 7.868 7.87 7.868 4.114 0 7.498-3.175 7.837-7.203l.03-19.76h11c2.563 0 3.582-3.498 1.358-4.85l-13.197-8.017c-1.129-.685-2.652.161-2.652 1.492V57.26zm3.492-20.83l7.866 4.779h-7.867V36.43zm-7.868 31.742a4.381 4.381 0 01-4.377-4.376 4.381 4.381 0 014.377-4.377 4.381 4.381 0 014.376 4.377 4.381 4.381 0 01-4.376 4.376zM17.613 69.754a7.802 7.802 0 00-4.358-1.324c-4.327 0-7.849 3.521-7.849 7.85 0 4.328 3.522 7.85 7.85 7.85 4.104 0 7.48-3.168 7.82-7.187l.03-19.695 10.96.002a2.588 2.588 0 002.515-1.908 2.588 2.588 0 00-1.158-2.938l-13.158-7.993c-1.143-.694-2.652.158-2.652 1.493v23.85zm-4.358 10.883a4.362 4.362 0 01-4.357-4.357 4.362 4.362 0 014.357-4.358 4.362 4.362 0 014.358 4.358 4.362 4.362 0 01-4.358 4.357zm7.85-26.881v-4.748l7.818 4.75-7.818-.002zM8.857 27.863c1.163 0 2.014-1.162 1.667-2.269a12.337 12.337 0 013.053-12.451 12.338 12.338 0 0112.451-3.054c2.14.672 3.187-2.66 1.045-3.332a15.817 15.817 0 00-15.965 3.917 15.818 15.818 0 00-3.916 15.965 1.746 1.746 0 001.665 1.224z"></path>
                    <path d="M24.243 16.965c2.14.672 3.187-2.66 1.045-3.332a8.926 8.926 0 00-9.01 2.21 8.926 8.926 0 00-2.21 9.01c.66 2.106 4.007 1.107 3.333-1.045a5.443 5.443 0 011.347-5.495 5.444 5.444 0 015.495-1.348zM88.156 68.214c-.67-2.14-4.004-1.097-3.332 1.044A12.337 12.337 0 0181.77 81.71a12.334 12.334 0 01-12.45 3.053c-2.14-.67-3.187 2.661-1.045 3.333 1.572.492 3.178.733 4.77.733a15.81 15.81 0 0011.195-4.65 15.817 15.817 0 003.916-15.965z"></path>
                    <path d="M71.106 77.887c-2.14-.67-3.188 2.66-1.046 3.332a8.923 8.923 0 009.01-2.21A8.925 8.925 0 0081.28 70c-.67-2.139-4.004-1.097-3.332 1.045a5.443 5.443 0 01-1.348 5.495 5.445 5.445 0 01-5.494 1.348zM55.961 35.072c.964 0 1.746-.782 1.746-1.746V21.278c0-2.241-3.492-2.245-3.492 0v12.048c0 .965.782 1.746 1.746 1.746zM55.96 42.187c.934 0 1.733-.78 1.745-1.716.013-.97-.78-1.77-1.748-1.77-.964-.002-1.76.818-1.74 1.782.019.926.817 1.704 1.743 1.704z"></path>
                </g>
                <defs>
                    <filter
                        id="filter0_d_12_1305"
                        width="98"
                        height="98"
                        x="-2"
                        y="0"
                        colorInterpolationFilters="sRGB"
                        filterUnits="userSpaceOnUse"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                        <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        ></feColorMatrix>
                        <feOffset dy="4"></feOffset>
                        <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                        <feBlend
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_12_1305"
                        ></feBlend>
                        <feBlend
                            in="SourceGraphic"
                            in2="effect1_dropShadow_12_1305"
                            result="shape"
                        ></feBlend>
                    </filter>
                    <clipPath id="clip0_12_1305">
                        <path fill="#fff" d="M0 0H88V88H0z" transform="translate(3 1)"></path>
                    </clipPath>
                </defs>
            </svg>
        );
}

export default NoUserPlaylists;
