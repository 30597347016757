import React, { useState } from "react";
import { setLanguageDirection } from "../../helper/LanguageDetection";
import MusicPlaceholderSvg from "../svg/common/MusicPlaceholderSvg";
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import { useContextPosition } from "../../helper/useContextPosition.js";
import { dispatchIsPlaying } from "../../redux/globalstore/GlobalStore";
import { getIsPlaying } from "../../helper/ReduxSelectorContext";
import { usePlayedTrack } from "../../hooks/usePlayedTrack";
import { useAction } from "../../hooks/useAction.js";
import { useDownload } from "../../hooks/useDownload.js";
import {setPlayedPlaylist, setPlayerPaginationData} from "../../redux/player/PlayMusicSlice";
import { useDispatch } from "react-redux";
import { CardActionArea } from "@mui/material";
import ClickReaction from "../../helper/ClickReaction";
import { textFontBasedLanguage } from '../../helper/TextFontBasedLanguage.js';

function PopularSongsCard({ item, queueList }) {
  const [contextMenu, setContextMenu] = useState(false);
  const [top, setTop] = useState(false);
  const [right, setRight] = useState(false);
  const dispatch = useDispatch();
  const action = useAction();
  const openContextMenu = (e) => {
    e.preventDefault();
    const [vertical, horizontal] = useContextPosition(e);

    action({
      target_type: "open_context_menu",
      data: item,
      horizontal,
      vertical,
    });
  };
  const closeContextMenu = () => {
    setContextMenu(false);
  };
  const [
    startDownloadTrack,
    isDownloaded,
    inQueue,
    inQueueItem,
    downloadedItem,
  ] = useDownload(item);
  let [currentTrack, setPlayerTrack] = usePlayedTrack();

  let isPlaying = getIsPlaying();
  const handlePlayMusic = (music) => {
    setPlayerTrack(downloadedItem ? downloadedItem : music, queueList);
    dispatch(setPlayerPaginationData({
      playerPaginationData: {
        url: null,
        payload: null,
        end: true
      }
    }))
    dispatch(setPlayedPlaylist({ playlist: null }));
  };
  const handleDownloadTrack = (event) => {
    event.stopPropagation();
    startDownloadTrack();
  };
  const handlePauseMusic = () => {
    dispatchIsPlaying(false);
  };
  const handleGoToArtist = (artist) => {
    const artistObject = {
      target_type: "artist",
      artist: artist,
    };
    action(artistObject);
  };

  return (
    <div
      class="z-10  bg-Gray-4 text-White flex justify-center items-center relative  "
      onClick={() => handlePlayMusic(item)}
    >
      <div
        // style={{
        //   backgroundImage: `linear-gradient(180deg, ${item?.background_color} 0%, rgba(197, 148, 0, 0.1) 100%)`,
        // }}
        class="w-full h-full rounded-[5px]  flex flex-col justify-start items-center cursor-pointer"
      >
        <ClickReaction>
          <CardActionArea className=" !mt-3 !mb-2 !rounded-[5px] overflow-hidden">
            <div
              className="w-[100px] h-[100px] shadow-track  relative overflow-hidden flex flex-col justify-center
          items-center "
            >
              <CustomLazyLoadImage image={item?.image} />
              <MusicPlaceholderSvg className="absolute top-0 left-0" />
            </div>
          </CardActionArea>
        </ClickReaction>

        <div
          dir={setLanguageDirection(item?.title)}
          class={`text-gray_5 max-w-[100px] text-center leading-[24px]  font-[600] line-clamp-1 overflow-hidden  flex justify-center  text-[12px] ${textFontBasedLanguage(item?.title)}`}
        >
          {item?.title}
        </div>
        <div
          dir={setLanguageDirection(item?.title)}
          class="text-gray_4 max-w-[100px] text-center  line-clamp-1 overflow-hidden flex justify-center  text-[10px] font-dana !font-[500] "
        >
          <span className="w-full">
            {item?.artists.map((artist, index) => {
              return (
                <span
                  className={`${setLanguageDirection(artist?.name) ==='ltr' ?'var-font-en-dana':'font-dana'} ${textFontBasedLanguage(artist?.name)}`}
                  onClick={() => {
                    handleGoToArtist(artist);
                  }}
                  key={index}
                >
                  {artist?.name} {index !== item?.artists?.length - 1 && "، "}
                </span>
              );
            })}
          </span>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PopularSongsCard);
