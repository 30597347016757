import React from 'react'

function ShareSvg() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    fill="none"
    viewBox="0 0 26 26"
  >
    <g filter="url(#filter0_b_74_12385)">
      <rect
        width="26"
        height="26"
        fill="#000"
        fillOpacity="0.3"
        rx="13"
      ></rect>
      <path
        fill="#E0E0E0"
        stroke="#E0E0E0"
        strokeWidth="0.2"
        d="M16.648 15.297c-.726 0-1.377.331-1.809.851l-1.83-.91a.547.547 0 00-.487.98l1.833.91A2.354 2.354 0 0016.649 20 2.354 2.354 0 0019 17.65a2.354 2.354 0 00-2.352-2.352zm0 3.61a1.26 1.26 0 01-1.16-1.744.558.558 0 00.067-.135 1.259 1.259 0 012.351.62 1.26 1.26 0 01-1.258 1.258zM16.648 6a2.354 2.354 0 00-2.291 2.879l-5.205 2.61a2.348 2.348 0 00-1.8-.84A2.354 2.354 0 005 13a2.354 2.354 0 002.352 2.352c.718 0 1.363-.325 1.794-.834l.883.45a.544.544 0 00.736-.24.547.547 0 00-.24-.736l-.885-.45a2.35 2.35 0 00.002-1.075l5.202-2.61c.432.517 1.08.846 1.804.846A2.354 2.354 0 0019 8.352 2.354 2.354 0 0016.648 6zm-9.296 8.258A1.26 1.26 0 016.094 13a1.26 1.26 0 011.258-1.258A1.26 1.26 0 018.609 13a1.26 1.26 0 01-1.257 1.258zm9.296-4.649a1.26 1.26 0 01-1.257-1.257 1.26 1.26 0 011.257-1.258 1.26 1.26 0 011.258 1.258 1.26 1.26 0 01-1.258 1.257z"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_b_74_12385"
        width="36"
        height="36"
        x="-5"
        y="-5"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="2.5"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_74_12385"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_74_12385"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
  )
}

export default ShareSvg