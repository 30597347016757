import React from "react";
import HomeMetaDataWithTitle from "../common/HomeMetaDataWithTitle";
import HomeBannerView from "./HomeBannerView";

function HomeCustomBanner({ item }) {
  return (
    <div>
      <div class="mb-3 mt-4">
        {(item?.custom_banner_meta_data?.title ||
          item?.custom_banner_meta_data?.show_more) && (
          <HomeMetaDataWithTitle metaData={item?.custom_banner_meta_data} />
        )}
        {item?.custom_banner_items?.map((bannerItem, index) => (
          <HomeBannerView
            key={index}
            item={bannerItem}
            metaData={item?.custom_banner_meta_data}
          />
        ))}
      </div>
    </div>
  );
}

export default HomeCustomBanner;
