import { memo } from "react";

const UploadingIconSvg = (props) => (
  <svg
    width={39}
    height={39}
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={38.655} height={38.655} rx={10} fill="#303030" />
    <path
      d="M14.02 8.71h8.494l5.31 4.247v13.804a3.185 3.185 0 0 1-3.186 3.186H14.019a3.186 3.186 0 0 1-3.185-3.186V11.896A3.185 3.185 0 0 1 14.02 8.71Z"
      fill="#828282"
    />
  </svg>
);
export default memo(UploadingIconSvg);
