import React, { memo } from 'react';

function UserPublishedTrackStatisticSection({ item }) {
  return (
    <div
      className={
        'flex font-dana text-xs gap-2 text-center absolute h-[calc(100%_-_2px)] right-0 bg-secondary rounded-r-[5px]'
      }
    >
      <div
        className={
          'flex flex-col border-l-[3px] border-primary_dark px-3 items-center  justify-center gap-2 w-[65px]'
        }
      >
        <span className={'text-gray_3'}>:دانلود</span>
        <span className={'text-primary font-[600]'}>
                  {item?.download_count}
                </span>
      </div>
      <div
        className={
          'flex flex-col border-l-[3px] border-primary_dark px-3 items-center justify-center gap-2  w-[65px]'
        }
      >
        <span className={'text-gray_3'}>:پخش</span>
        <span className={'text-primary font-[600]'}>
                  {item?.stream_count}
                </span>
      </div>
    </div>
  );
}

export default memo(UserPublishedTrackStatisticSection);
