import React from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toggleDragging} from "../../redux/globalData/globalDataSlice.js";

function DisableEditMode(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const disableEditMode = () => {
        navigate(-1)
    }

    return (
        <div className='w-[12px] h-[12px]' onClick={disableEditMode}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 14 14"
            >
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 1L1 13M1 1l12 12"
                ></path>
            </svg>
        </div>
    );
}

export default DisableEditMode;
