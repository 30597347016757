import React from 'react'

function FullScreenSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.292 2.906H4.26A1.354 1.354 0 002.906 4.26v2.032m12.188 0V4.26a1.354 1.354 0 00-1.354-1.354h-2.032m0 12.188h2.032a1.354 1.354 0 001.354-1.354v-2.032m-12.188 0v2.032a1.354 1.354 0 001.354 1.354h2.032"
        opacity="0.9"
      ></path>
    </svg>
  )
}

export default FullScreenSvg