import React from "react";

function AlbumSvg(props) {
  return (
<svg className="w-full h-full " viewBox="0 0 44 86" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_74_10)">
<path d="M43.9902 43.0065C43.9883 54.4759 39.469 65.2583 31.2625 73.3682C23.0541 81.479 12.1421 85.9464 0.534856 85.9445L0.53681 67.6173L0.538765 43.0009L0.540723 19.6986L0.542677 0.0573425C12.1499 0.0610415 23.0619 4.52934 31.2693 12.6411C39.4729 20.7528 43.9902 31.5361 43.9893 43.0065H43.9902Z" fill="#2D2D2D"/>
<path d="M31.2713 12.6337C23.0658 4.52194 12.1538 0.0527131 0.547565 0.0517883L0.545606 22.3656L0.543652 42.9954L43.9941 42.9991C43.9951 31.5287 39.4768 20.7454 31.2733 12.6337H31.2713Z" fill="black"/>
<path d="M18.306 43.0074C18.306 52.6847 10.3371 60.5579 0.542681 60.5569L0.544635 43.0056L0.545612 25.4551C10.34 25.456 18.3069 33.3301 18.306 43.0074Z" fill="#F2C94C"/>
<path d="M0.543662 25.444L0.542685 42.9954L18.304 42.9963C18.305 33.319 10.3381 25.4449 0.544639 25.4449L0.543662 25.444Z" fill="#DFA800"/>
<path d="M3.09369 43.0074C3.09369 43.6704 2.82187 44.3178 2.34667 44.7875C2.11342 45.0182 1.83354 45.2023 1.52365 45.3287C1.21376 45.4552 0.880162 45.5214 0.542665 45.5236V44.523V42.9418V41.6592V40.4903C1.2144 40.4903 1.87147 40.7594 2.34764 41.2273C2.82728 41.709 3.09417 42.3458 3.09369 43.0074Z" fill="#2E2C27"/>
<path d="M2.34667 41.2153C2.1135 40.9844 1.83366 40.8002 1.52377 40.6736C1.21387 40.547 0.880232 40.4806 0.542666 40.4783V42.9954H3.09369C3.09369 42.3324 2.82089 41.685 2.34667 41.2153Z" fill="#181B1F"/>
<path d="M27.8843 60.9185C27.8843 61.4345 27.7239 61.9542 27.3915 62.4027C22.3696 69.169 15.0402 73.7492 6.75353 75.2999C5.369 75.5589 4.03433 74.6619 3.7713 73.297C3.50926 71.9321 4.41761 70.6152 5.80215 70.3563C12.8138 69.0432 19.0188 65.1639 23.275 59.4306C24.1061 58.3089 25.7029 58.0657 26.84 58.8869C27.1674 59.1269 27.432 59.435 27.6131 59.7873C27.7942 60.1397 27.887 60.5268 27.8843 60.9185Z" fill="black"/>
<path opacity="0.2" d="M43.9902 43.0065C43.9883 54.4759 39.469 65.2583 31.2625 73.3682C23.0541 81.479 12.1421 85.9464 0.534856 85.9445L0.53681 67.6173L0.538765 43.0009L0.540723 19.6986L0.542677 0.0573425C12.1499 0.0610415 23.0619 4.52934 31.2693 12.6411C39.4729 20.7528 43.9902 31.5361 43.9893 43.0065H43.9902Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_74_10">
<rect width="44" height="86" fill="white" transform="matrix(-1 0 0 1 44 0)"/>
</clipPath>
</defs>
</svg>



  );
}

export default AlbumSvg;
