import {createSlice} from "@reduxjs/toolkit";

export const PlayerSlice = createSlice({
    name: "player",
    initialState: {
        isPlay: null,
        isQueueOpen: false,
        isLyricOpen: false,
        showSideBar: true,
        showHeader: true,
        activeComponentId: null,
        absoluteComponentList: [],
        sleepTimerEnable: false,
        sleepTimerTime : null,
        emtyAudioStatus:false,
        playPushActionData:null
    },

    reducers: {
        setPLaying: (state, action) => {
            const {isPlay} = action.payload
            state.isPlay = isPlay;
        },
        setQueueOpen: (state, action) => {
            const {isQueue} = action.payload
            state.isQueueOpen = isQueue;
        },
        setLyricOpen: (state, action) => {
            const {isLyric} = action.payload
            state.isLyricOpen = isLyric;
        },
        setShowSidebar: (state, action) => {
            const {sidebar} = action.payload
            state.showSideBar = sidebar;
        },
        setShowHeader: (state, action) => {
            const {header} = action.payload
            state.showHeader = header;
        },
        clearComponentsList: (state) => {
            state.activeComplonentId = null;
            state.absoluteComponentList = [];
        },
        addComponentToList: (state, action) => {
            const {component, id} = action.payload;
            const existInList = doesComponentExist(state.absoluteComponentList, id);
            const active = state.absoluteComponentList.find((item) => item.id === id)?.active;
            // make a new list of components based on conditions
            let newList = makeFinalList(existInList, active, state.absoluteComponentList, id, component);
            // Update state with the new list and set the active component ID
            newList = newList.filter((item) => item);
            state.activeComponentId = newList.find((item) => item.active)?.id || null;
            state.absoluteComponentList = newList
        },
        enableSleepTimer:(state,action)=>{
            state.sleepTimerEnable=true
        },
        disableSleepTimer:(state,action)=>{
            state.sleepTimerEnable=false
        },
        setSleepTimerTime:(state,action)=>{
            const {time}=action.payload
            state.sleepTimerTime=time
        },  setEmtyAudioStatus:(state,action)=>{
            const {status}=action.payload
            state.emtyAudioStatus=status
        },setPlayPushActionData:(state,action)=>{
            const {player_data}=action.payload
            state.playPushActionData=player_data
        },
    }
})

export const {
    setPLaying,
    setQueueOpen,
    setLyricOpen,
    setShowSidebar,
    setShowHeader,
    addComponentToList,
    clearComponentsList,
    enableSleepTimer,
    disableSleepTimer,
    setSleepTimerTime,
    setEmtyAudioStatus,
    setPlayPushActionData
} = PlayerSlice.actions;

export const selectIsPlay = (state) => state.player.isPlay;
export const selectIsQueue = (state) => state.player.isQueueOpen;
export const selectIsLyric = (state) => state.player.isLyricOpen;
export const selectSidebar = (state) => state.player.showSideBar;
export const selectHeader = (state) => state.player.showHeader;
export const selectComponentList = (state) => state.player.absoluteComponentList;
export const selectActiveComponentId = (state) => state.player.activeComponentId;
export const selectPlayerTimer = (state) => state.player
export const selectEmtyAudioStatus = (state) => state.player.emtyAudioStatus
export const selectPlayPushActionData = (state) => state.player.playPushActionData

export default PlayerSlice.reducer;

// Helper function to return a new array with the specified component added or updated
function getNewListComponent(componentList, id, component) {
    return componentList.map((item) => {
        if (item.id === id) {
            return {
                id: item.id,
                component: component,
                active: true,
            };
        } else {
            return {
                id: item.id,
                component: item.component,
                active: false,
            };
        }
    });
};

// Helper function to return a new array for remove a component
function getNewListComponentType2(componentList, id) {
    return componentList.map((item) => {
        if (item.id === id) {
            return null;
        } else {
            return {
                id: item.id,
                component: item.component,
                active: true,
            };
        }
    });
};

// Helper function to check if a component with the specified ID already exists in the list
function doesComponentExist(componentList, id) {
    return componentList.some((item) => item.id === id);
};

// Helper function to make component list based on conditions
function makeFinalList(existInList, active, componentList, id, component) {
    // Component doesn't exist, so add it to the list
    if (!existInList) {
        componentList.push({id, component, active: false})
        return getNewListComponent(componentList, id, component)
    }
    // If the component exist and  is not active, update the list with the new component
    if (existInList && !active) {
        return getNewListComponent(componentList, id, component)
    }
    // If the component is already active, remove it from the list
    if (existInList && active) {
        return getNewListComponentType2(componentList, id)
    }

}



