import { memo } from 'react';

function ShareGiftSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="#F2C94C"
      viewBox="0 0 18 18"
      {...props}
    >
      <g clipPath="url(#clip0_5599_5759)">
        <path d="M15.937 18H2.062A2.064 2.064 0 010 15.937V5.062C0 3.925.925 3 2.062 3h2.625a.563.563 0 010 1.125H2.062a.939.939 0 00-.937.937v10.875c0 .517.42.938.937.938h13.875c.517 0 .938-.421.938-.938V9.562a.563.563 0 011.125 0v6.375A2.064 2.064 0 0115.937 18z"></path>
        <path d="M5.062 11.99a.499.499 0 01-.124-.015.555.555 0 01-.438-.537v-1.125C4.5 6.28 7.78 3 11.812 3H12V.563a.563.563 0 01.967-.39l4.875 5.062c.21.218.21.563 0 .78l-4.875 5.063a.563.563 0 01-.967-.39V8.25h-.891c-2.36 0-4.48 1.311-5.534 3.42a.58.58 0 01-.513.32zm6.75-7.865a6.198 6.198 0 00-6.14 5.415 7.272 7.272 0 015.437-2.415h1.453c.31 0 .563.252.563.563v1.605l3.532-3.668-3.532-3.667v1.605c0 .31-.252.562-.563.562h-.75z"></path>
      </g>
      <defs>
        <clipPath id="clip0_5599_5759">
          <path fill="#fff" d="M0 0H18V18H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(ShareGiftSvg);
