import React from 'react'

function PlayListPlaceHolderSvg() {
  return (
    <svg
    className="w-[45px] h-[40px]"
    xmlns="http://www.w3.org/2000/svg"
    // width="74"
    // height="46"
    fill="none"
    viewBox="0 0 74 46"
  >
    <path
      fill="#4F4F4F"
      fillRule="evenodd"
      d="M40.282 4.76c0 1.314-1.372 2.38-3.064 2.38-1.691 0-3.064-1.066-3.064-2.38V2.38c0-1.314 1.373-2.38 3.064-2.38 1.692 0 3.064 1.066 3.064 2.38v2.38zm-3.064 40.458c-1.691 0-3.064-1.066-3.064-2.38V14.28c0-1.314 1.373-2.38 3.064-2.38 1.692 0 3.064 1.066 3.064 2.38v28.559c0 1.313-1.372 2.38-3.064 2.38zM.45 18.691c0-1.314 1.373-2.38 3.064-2.38 1.692 0 3.064 1.066 3.064 2.38V42.49c0 1.313-1.372 2.38-3.064 2.38-1.691 0-3.064-1.066-3.064-2.38v-23.8zm29.11 23.7V23.35c0-1.314-1.373-2.38-3.064-2.38-1.692 0-3.064 1.066-3.064 2.38v19.04c0 1.313 1.372 2.38 3.064 2.38 1.691 0 3.064-1.067 3.064-2.38zm-10.724.248c0 1.314-1.373 2.38-3.064 2.38s-3.064-1.066-3.064-2.38v-9.52c0-1.313 1.373-2.38 3.064-2.38s3.064 1.067 3.064 2.38v9.52zm32.173-.249V23.35c0-1.313-1.373-2.379-3.064-2.379s-3.064 1.066-3.064 2.38v19.04c0 1.313 1.373 2.38 3.064 2.38s3.064-1.067 3.064-2.38zm16.852-23.7c0-1.313 1.373-2.38 3.064-2.38 1.692 0 3.065 1.067 3.065 2.38v23.8c0 1.313-1.373 2.38-3.065 2.38-1.69 0-3.064-1.066-3.064-2.38v-23.8zm-9.192 12.05c-1.692 0-3.065 1.066-3.065 2.38v9.52c0 1.313 1.373 2.38 3.065 2.38 1.691 0 3.064-1.067 3.064-2.38v-9.52c0-1.314-1.373-2.38-3.064-2.38z"
      clipRule="evenodd"
    ></path>
  </svg>
  )
}

export default PlayListPlaceHolderSvg