import React, { useEffect, useState } from "react";
import { useLike } from "../../../helper/useLike.js";

function HeartSvg({
  fill = "#A7A7A9",
  id,
  height = 'h-[18px]  min-[1440px]:h-[1.2500vw]',
  aspectRatio =20 / 18,
}) {
  const [isLiked, toggleLikes] = useLike(id);
  const [startAnim, setStartAnim] = useState(false);
  const toggleLikeHandler = (e) => {
    e.stopPropagation();
    toggleLikes();
    setStartAnim(true);

  };

  useEffect(() => {
    const animInterval = setInterval(() => {
      setStartAnim(false);
    }, 100);

    return () => {
      clearInterval(animInterval);
    };
  }, [startAnim]);

  const handleAnim = () => {
    if (isLiked) {
      // unliked
      return "translate-y-[-10px] lg-1440:-translate-y-r10 ";
    } else {
      // liked
      return "animate-shake";
    }
  };

  return (

    <svg
      onClick={(e) => {
        toggleLikeHandler(e);
      }}
      style={{ aspectRatio: aspectRatio }}
      className={`cursor-pointer transition-all  duration-75 ease-linear ${startAnim && handleAnim()} ${height}  `}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isLiked ? (
        <path
          fill="#F2C94C"
          d="M9.556 17.314a.621.621 0 00.89 0l7.996-8.101C21.776 5.835 19.407 0 14.665 0c-2.848 0-4.154 2.093-4.664 2.483C9.49 2.091 8.191 0 5.338 0 .61 0-1.788 5.82 1.562 9.213l7.994 8.101z"
        ></path>
      ) : (
          <svg
             
              viewBox="0 0 26 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M23.2808 2.41732C22.0525 1.18093 20.3588 0.5 18.5117 0.5C15.2824 0.5 13.6127 2.15173 12.7807 3.53733C12.7704 3.55451 12.7601 3.57174 12.75 3.58892C12.7399 3.57174 12.7296 3.55451 12.7193 3.53733C11.8873 2.15173 10.2176 0.5 6.98828 0.5C5.14116 0.5 3.44751 1.18093 2.21924 2.41737C0.930957 3.71424 0.25 5.4895 0.25 7.55122C0.25 11.9292 4.16509 14.8319 7.61924 17.3929C9.34038 18.6689 10.9661 19.8742 11.9893 21.1379C12.1746 21.3669 12.4543 21.5 12.75 21.5C13.0457 21.5 13.3254 21.3669 13.5108 21.138C14.0371 20.488 14.7686 19.7969 15.8131 18.963C16.2336 18.6272 16.3007 18.0162 15.9629 17.5982C15.6252 17.1803 15.0105 17.1135 14.59 17.4492C13.8531 18.0376 13.2504 18.5697 12.7504 19.0754C11.6465 17.9567 10.2493 16.9208 8.78696 15.8366C5.55015 13.4368 2.20312 10.9552 2.20312 7.55122C2.20312 4.54268 4.17085 2.4415 6.98828 2.4415C8.82026 2.4415 10.173 3.1266 11.0088 4.47774C11.6724 5.55027 11.7756 6.66668 11.7763 6.67357C11.8154 7.17928 12.2396 7.5719 12.7498 7.5719C13.2601 7.5719 13.6841 7.18355 13.7233 6.67784C13.7243 6.66668 13.8275 5.55027 14.4911 4.47774C15.327 3.1266 16.6797 2.4415 18.5117 2.4415C21.3292 2.4415 23.2969 4.54268 23.2969 7.55122C23.2969 10.3682 20.9896 12.5523 18.4963 14.4929C18.0717 14.8234 17.997 15.4336 18.3295 15.8557C18.6619 16.2778 19.2757 16.3521 19.7004 16.0215C20.8865 15.0984 22.3897 13.8509 23.4955 12.386C24.6761 10.822 25.25 9.24052 25.25 7.55122C25.25 5.4895 24.569 3.71424 23.2808 2.41732Z"
                fill={fill}
            />
          </svg>
      )}
    </svg>
  );
}

export default HeartSvg;
