import React from "react";
import CssShimmer from "../../components/common/CssShimmer.jsx";

function TicketsShimmer() {
    return (
        <>
                <CssShimmer
                    classNames={"mx-4 mt-5 w-[120px] h-[28px] min-[375px]:w-[160px] min-[375px]:h-9 bg-gray_3 bg-opacity-10 rounded-full"}
                    shimStart={"-250%"}
                    shimEnd={"250%"}
                    reverse={true}
                />
            <div className={"mt-7 min-[375px]:mt-9"}>
                {[...Array(5).keys()].map((item) => (
                    <div className="flex flex-col mb-9 pr-6 min-[375px]:pr-8">
                        <div className={"inline-flex"}>
                            <CssShimmer
                                classNames={"bg-gray_2 bg-opacity-10 rounded-full min-w-[55px] min-h-[55px] min-[375px]:min-w-[60px] min-[375px]:h-[60px]"}
                                shimStart={"-250%"}
                                shimEnd={"250%"}
                                reverse={true}
                            />
                            <div className={"flex flex-col w-full gap-2 mr-2 justify-center"}>
                                <CssShimmer
                                    classNames={"h-6 bg-gray_2 bg-opacity-10 rounded-full w-[80%] h-6"}
                                    shimStart={"-200%"}
                                    shimEnd={"200%"}
                                    reverse={true}
                                />
                                <CssShimmer
                                    classNames={"h-6 bg-gray_2 bg-opacity-10 rounded-full w-[60%] h-6"}
                                    shimStart={"-200%"}
                                    shimEnd={"200%"}
                                    reverse={true}
                                />
                            </div>
                        </div>
                        <div className={"inline-flex mt-4 min-[375px] mt-6 w-full"}>
                            <CssShimmer
                                classNames={"h-6 bg-gray_2 bg-opacity-10 rounded-full w-[60%] h-6"}
                                shimStart={"-200%"}
                                shimEnd={"200%"}
                                reverse={true}
                            />
                            <CssShimmer
                                classNames={"mr-4 h-6 bg-gray_2 bg-opacity-10 rounded-full w-[30%] h-6"}
                                shimStart={"-200%"}
                                shimEnd={"200%"}
                                reverse={true}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}


export default React.memo(TicketsShimmer);
