import React, { useEffect, useState } from "react";
import { getLimitations, getPlayerMusic } from "./helper/ReduxSelectorContext";
import {
  errorToast,
  notifyAddToQueue,
  successToast,
  warningToast,
} from "./helper/ToastsEmitter";
import { removeIndexedDB } from "./indexDB/dbQuery";
import ReactPlayer from "react-player";
import { IsIosDevice } from "./helper/CheckDevice";
import { useSelector } from "react-redux";
import { selectSearchHistory } from "./redux/search/SearchSlice";
import { useInView } from "react-intersection-observer";

function Test() {

  const requestNotificationPermission = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          alert("Dsfds___",permission)
            // Permission granted, you can now show notifications
        } else if (permission === 'denied') {
          alert("Dsfdseee",permission)
            // Permission denied
        } else {
          alert("Dsfds")
            // Permission dismissed or default (usually on the first visit)
        }
    } catch (error) {
        console.error('Error requesting notification permission:', error);
    }
  };
  return (
    <div className="w-full h-full flex flex-col overflow-scroll">
     <div onClick={()=>requestNotificationPermission()} className="bg-green text-green">
sdfdsfdsfds
     </div>
      </div>

  );
}

export default Test;
