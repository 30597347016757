import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  getSwipePlayerStatus,
  globalData,
} from '../../helper/ReduxSelectorContext.js';
import { useDispatch } from 'react-redux';
import { clearLimitationDialogData } from '../../redux/globalData/globalDataSlice.js';
import { useAction } from '../../hooks/useAction.js';
import CustomLazyLoadImage from './CustomLazyLoadImage';
import DialogHeader from '../dialogs/DialogHeader.jsx';
import { CardActionArea } from '@mui/material';
import { dispatchSwipePlayerStatus } from '../../redux/globalstore/GlobalStore.js';

function LimitationDialog() {
  const { limitationDialogData, limitationDialogState } = globalData();
  const dispatch = useDispatch();
  const action = useAction();
  const closeDialog = () => {
    dispatch(clearLimitationDialogData());
  };
  const playerSwipeStatus = getSwipePlayerStatus();
  const openPlans = () => {
    dispatch(clearLimitationDialogData());
    dispatchSwipePlayerStatus(false);
    action({ target_type: 'plans', openPlayerWhenBack: playerSwipeStatus });
  };
  return (
    <Transition appear show={limitationDialogState} as={Fragment}>
      <Dialog as="div" className="relative z-[1200]" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0" />
        </Transition.Child>

        <div
          dir="rtl"
          className="fixed inset-0 overflow-y-auto bg-black bg-opacity-50   font-dana"
        >
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-sm  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-secondary p-4  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={' ویژه شوید'}
                  onCloseDialog={closeDialog}
                />
                <div className="px-6 flex flex-col gap-6 pt-4 ">
                  <div className="flex flex-col gap-6  mx-auto justify-center items-center">
                    <div className="flex h-[155px]   w-[150px] relative">
                      <CustomLazyLoadImage
                        image={limitationDialogData?.image}
                        appendBaseUrl={false}
                      />
                    </div>
                    <div className="flex rounded-[20px]  text-gray_6 text-[14px] font-normal py-1 bg-black_2 items-center justify-center text-center px-3">
                      {limitationDialogData?.boxAlert}
                    </div>
                  </div>
                  <div className=" text-gray_6 text-[16px] font-normal items-center text-center  ">
                    {limitationDialogData?.message}
                  </div>
                </div>
                <div className={'flex items-center justify-center mt-6 mb-4'}>
                  <CardActionArea className="mt-4 !h-12 !rounded-[22px] !w-[180px]">
                    <div className="w-[180px] flex items-center justify-center">
                      <button
                        onClick={() => {
                          openPlans();
                        }}
                        type="button"
                        className="inline-flex justify-center items-center !h-12 text-black text-base bg-primary w-full justify-center rounded-[22px] border border-transparent bg-blue-100 px-4 focus:outline-none"
                      >
                        <p className="font-semibold text-gray_1">ویژه شوید</p>
                      </button>
                    </div>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default LimitationDialog;
