import React, { useEffect, useState } from 'react';
import getNumberHeader from '../../assets/login/login-header.webp';
import MelodifyLogoNoText from '../../assets/logo/melodify-logo-notext.svg';
const loginBG = {
  backgroundImage: `url("${getNumberHeader}")`,
};
import { isIOS, isMobileSafari } from 'react-device-detect';
import AppleLogoSVG from '../../components/svg/common/AppleLogoSVG.jsx';
import SafariShareIconSVG from '../../components/svg/common/SafariShareIconSVG.jsx';
import SafariNavigationBar from '../../assets/common/SafariNavigationBar.webp';
import SafariShareMenu from '../../assets/common/SafariShareMenu.webp';
import CustomImage from '../../components/common/CustomImage';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage';

const AddToHomeScreen = ({ setA2hNeeded }) => {
  const [addToHomeScreenNeeded, setAddToHomeScreenNeeded] = useState(false);
  useEffect(() => {
    const checkAddToHomeCondition = () => {
      const isStandalone = window.matchMedia(
       [ '(display-mode: fullscreen)',
        '(display-mode: standalone)',]
      ).matches;
      if (!isStandalone && isIOS && isMobileSafari) {
        setAddToHomeScreenNeeded(true);
        setA2hNeeded(true);
      } else {
        setA2hNeeded(false);
      }
    };
    checkAddToHomeCondition();
  }, []);

  return (
    <>
      {addToHomeScreenNeeded && (
        <div className="relative bg-secondary text-white   w-screen h-screen max-w-[520px]">
          <div>
            <header>
              <div className="relative w-full z-0  h-[100vw] max-h-[225px]">
                <div
                  style={loginBG}
                  className={`absolute w-full top-0 left-0 z-0 bg-cover bg-[top_center] bg-no-repeat min-h-[225px]`}
                />
                <div className={`bg-login-background absolute w-full h-full`} />
              </div>
            </header>
          </div>
          <section
            className={
              'absolute w-full rounded-t-3xl bg-secondary justify-center items-start flex bottom-[60px]'
            }
          >
            <div className="flex flex-col font-dana w-full justify-center items-start dir-rtl relative px-4">
              <div
                className={
                  'relative w-full justify-center flex -top-7 mb-1 -right-2'
                }
              >
                <CustomImage
                  src={MelodifyLogoNoText}
                  alt={''}
                  appendBaseUrl={false}
                />
              </div>
              <div className={'h-full relative  -top-7'}>
                <div
                  className={
                    'text-white flex dir-rtl gap-1.5 text-base  justify-start items-center'
                  }
                >
                  <AppleLogoSVG classNames={'-mt-2'} />
                  <span
                    className={
                      'min-[360px]:text-xl min-[415px]:text-2xl font-[700]'
                    }
                  >
                    {' '}
                    نصب اپلیکیشن ملودیفای برای آیفون{' '}
                  </span>
                </div>
                <div
                  className={
                    '!text-[10px] min-[340px]:!text-[11px] min-[362px]:!text-[12px]  min-[390px]:!text-[14px] inline-flex  text-white dir-rtl gap-1 font-[600]  justify-start items-center mt-1'
                  }
                >
                  <span className={'!text-2xl font-[700]'}>۱</span>
                  <p className={'inline-flex gap-1 justify-between'}>
                    در منوی مرورگر (نوار پایین صفحه) گزینه‌ی
                    <span>
                      <SafariShareIconSVG />
                    </span>
                    را انتخاب کنید.
                  </p>
                </div>
                <div
                  className={'w-7/12 rounded-[15px] bg-black overflow-hidden'}
                >
                  <CustomLazyLoadImage
                    imageClass="!relative"
                    appendBaseUrl={false}
                    alt={''}
                    image={SafariNavigationBar}
                  />
                </div>
                <div
                  className={
                    'text-white flex dir-rtl gap-1.5 text-base  font-[600]  justify-start items-center mt-1.5'
                  }
                >
                  <span
                    className={
                      'min-[375px]:text-2xl font-[700] min-[360px]:text-xl'
                    }
                  >
                    ۲
                  </span>
                  <span
                    className={
                      '!text-[10px] min-[340px]:!text-[11px] min-[362px]:!text-[12px] min-[390px]:!text-[14px]'
                    }
                  >
                    به پایین رفته و گزینه ی Add to homescreen را لمس کنید.
                  </span>
                </div>
                <div
                  className={'w-7/12 rounded-[15px] bg-black overflow-hidden'}
                >
                  <CustomLazyLoadImage
                    imageClass="!relative"
                    alt={''}
                    appendBaseUrl={false}
                    image={SafariShareMenu}
                  />
                </div>
                <div
                  className={
                    '!text-[10px] min-[340px]:!text-[11px] min-[362px]:!text-[12px] min-[390px]:!text-[14px] text-white flex dir-rtl gap-1.5 font-[600]  justify-start items-center mt-3'
                  }
                >
                  اکنون ملودیفای در کنار دیگر اپلیکیشن‌های شماست.
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default AddToHomeScreen;
