import axios from 'axios'
import {useMutation} from "@tanstack/react-query";
import {upKeepData} from "../helper/ReduxSelectorContext.js";
import {UPKEEP_DEFAULT_BASE_URL} from "../BASE.js";
import {setCustomDialog} from "../redux/dialog/DialogSlice.js";
import {useDispatch} from "react-redux";
import {useState} from "react";

export const useUpkeep = () => {
    const upKeepDataState = upKeepData()
    const upKeep = upKeepDataState?.upkeep
    const [upkeepEnable,setUpkeepEnable]=useState(false)
    const dispatch=useDispatch()
    const requestUrl = () => {
        if (upKeep?.upkeep_url) {
            return upKeep?.upkeep_url
        } else {
            return UPKEEP_DEFAULT_BASE_URL
        }
    };
    const axiosRequest = () => {
        return axios({
            method: 'get',
            url: requestUrl()
        })
    };
    const maintainMutation = useMutation(() => axiosRequest(), {
        onSuccess: (res) => {
            handleUpkeepResponse(res?.data?.upkeep)
        }
    });
    const handleUpkeepResponse=(upKeep)=>{
        if(upKeep){
            setUpkeepEnable(true)
            dispatch(
                setCustomDialog({
                    custom_dialog: upKeep,
                }))
        }else{
            console.log('upkeep disabled')
        }
    }
    return {maintainMutation,upkeepEnable};
}
