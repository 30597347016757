export function isEnglishText(text) {
  const englishRegex = /^[A-Za-z0-9\s.,?!'"()$%&*+/:;<=>@[\\\]^_`{|}~-]+$/;
  return englishRegex.test(text);
}


export function setLanguageDirection(text) {
  // const englishRegex = /^[A-Za-z0-9-Ç-\s.,?!'"()$%&*+/:;<=>@[\\\]^_`{|}~-]+$/;
  // const persianRegex = /^[\u0600-\u06FF]+([\u0600-\u06FF\s\-']*[\u0600-\u06FF]+)*/g;
  // const persianRegex =  /^[\u0600-\u06FF\s]+([\u0600-\u06FF\s\-']*[\u0600-\u06FF]+)*/g;
  const persianRegex =  /^(?![\s\S]*[a-z]).*[\u0600-\u06FF\s]+([\u0600-\u06FF\s\-']*[\u0600-\u06FF]+)*/g  ;
  return persianRegex.test(text)?"rtl":'ltr';
}