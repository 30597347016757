import React, { useEffect, useState } from 'react';
import getNumberHeader from '../../assets/common/goToSafariCover.webp';
import MelodifyLogoNoText from '../../assets/logo/melodify-logo-notext.svg';
const loginBG = {
  backgroundImage: `url("${getNumberHeader}")`,
};
import { isIOS, isMobileSafari } from 'react-device-detect';
import AppleLogoSVG from '../../components/svg/common/AppleLogoSVG.jsx';
import { CardActionArea } from '@mui/material';
import SafariLogo from '../../assets/common/SafariLogo.webp';
import { CopyText } from '../../helper/CopyText.js';
import { mainToast } from '../../helper/ToastsEmitter.jsx';
import CustomImage from '../../components/common/CustomImage';

const UseSafariOnIOS = ({ setSafariNeeded }) => {
  const [safariOnIOSNeeded, setSafariOnIOSNeeded] = useState(false);
  const [btnText, setBtnText] = useState('کپی کردن');
  useEffect(() => {
    const checkAddToHomeCondition = () => {
      const isStandalone = window.matchMedia(
        ['(display-mode: fullscreen)',
        '(display-mode: standalone)',]
      ).matches;
      if (!isStandalone && isIOS && !isMobileSafari) {
        setSafariOnIOSNeeded(true);
        setSafariNeeded(true);
      } else {
        setSafariOnIOSNeeded(false);
      }
    };
    checkAddToHomeCondition();
  }, []);

  return (
    <>
      {safariOnIOSNeeded && (
        <div className="relative text-white   w-screen h-screen max-w-[520px]">
          <div>
            <header>
              <div className="relative w-full z-0  h-[50vh]">
                <div
                  style={loginBG}
                  className={`absolute w-full top-0 left-0 z-0 bg-cover bg-[top_center] bg-no-repeat h-full`}
                />
                <div
                  className={`bg-safari-on-ios-background absolute w-full h-full`}
                />
                <div
                  className={`bg-safari-on-ios-background-2 absolute w-full h-full`}
                />
              </div>
              <div class={'relative w-full z-0 h-[50vh] bg-black_2'}></div>
            </header>
          </div>
          <section
            className={
              'absolute w-full rounded-t-3xl justify-around top-0 left-0 h-screen items-center flex bottom-[60px] flex-col font-dana text-[19px] font-medium'
            }
          >
            <div></div>
            <div className={`flex flex-col items-center`}>
              <div
                className={
                  'flex dir-rtl p-2 -mt-12 gap-2 justify-center items-center'
                }
              >
                <CustomImage
                  appendBaseUrl={false}
                  src={MelodifyLogoNoText}
                  class={'h-20'}
                  alt={''}
                />
                <p>
                  برای نصب ملودیفای لینک زیر را کپی‌کنید و در مرورگر Safari
                  <CustomImage
                    appendBaseUrl={false}
                    className={'inline w-7 h-7 relative px-1 box-content'}
                    src={SafariLogo}
                  />
                  وارد‌کنید.
                </p>
              </div>
              <div
                class={
                  'flex flex-col justify-center items-center w-[90%] mx-auto'
                }
              >
                <span
                  className={
                    'h-12 w-full rounded-full bg-gray_1 flex justify-center items-center w-[90%] font-poppins font-[400]'
                  }
                >
                  https://pwa.melodify.app
                </span>
                <div className={'w-full'}>
                  <CardActionArea className="!w-full !h-[46px] !mt-3  !rounded-[23px]">
                    <button
                      onClick={() => {
                        CopyText('https://pwa.melodify.app');
                        setBtnText('کپی شد');
                        mainToast('کپی شد', 3000, 'bottom-center');
                      }}
                      className="bg-primary w-full h-full rounded-[23px] text-black font-dana font-[600] text-[16px]  cursor-pointer "
                    >
                      {btnText}
                    </button>
                  </CardActionArea>
                </div>
              </div>
            </div>
            <div
              className={
                'flex w-full justify-center items-center relative -top-14 opacity-20'
              }
            >
              <AppleLogoSVG classNames={'w-12 h-12'} />
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default UseSafariOnIOS;
