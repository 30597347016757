import React from 'react';

function ArtistProfileBackground({artistData}) {
    return (
        <div>
            <div style={{backgroundColor: artistData?.background_color}} className="absolute top-0 right-0 left-0 bottom-0"></div>
            <div className="absolute bg-bg-artist top-0 right-0 left-0 bottom-0  h-[100.15%] "></div>
        </div>
    );
}

export default ArtistProfileBackground;
