import React, { useEffect, useRef, useState } from "react";
import TrackListResult from "../../components/search/TrackListResult";
import PlayListSearchResult from "../../components/search/PlayListSearchResult";
import ArtistSearchResult from "../../components/search/ArtistSearchResult";
import AlbumSearchResult from "../../components/search/AlbumSearchResult";
import TicketsSkeleton from "../../components/Tickets/TicketsSkeleton";
import SearchMetaDataShimmer from "../../components/search/SearchMetaDataShimmer";
import {
  geSearchSelectedTab,
  getAlbumResultData,
  getArtistResultData,
  getCommitSearch,
  getCommitedSearchQuery,
  getPlayListResultData,
  getSearchLoading,
  getTrackResultData,
} from "../../helper/ReduxSelectorContext";
import SearchInput from "../../components/header/SearchInput";
import Container from "../../components/PageParentContainer/Container";
import CommonHeader from "../../components/common/CommonHeader";
import SearchTabBar from "./SearchTabBar";
import CssShimmer from "../../components/common/CssShimmer";
import AlbumSvg from "../../components/svg/album/AlbumSvg";
import AllSearchResultTab from "./tabs/AllSearchResultTab";
import { SearchTabBarItem } from "../../consts/SearchTabBarItem";
import TrackSearchResultTab from "./tabs/TrackSearchResultTab";
import ArtistsSearchResultTab from "./tabs/ArtistsSearchResultTab";
import AlbumSearchResultTab from "./tabs/AlbumSearchResultTab";
import PlaylistSearchResultTab from "./tabs/PlaylistSearchResultTab";
import { useMemo } from "react";
import { setSearchQuery } from "../../redux/search/SearchSlice";
import { useDispatch } from "react-redux";
import SearchResultHeader from "./SearchResultHeader";
import ScrollTopButton from "../../components/common/ScrollTopButton.jsx";

function SearchResult() {
  const commitSearch = getCommitSearch();
  const selectedTab = geSearchSelectedTab();
  const searchparam = getCommitedSearchQuery();
  const childRef = useRef(null);
  const dispatch = useDispatch();

  const scrollToChild = () => {
    const childElement = childRef.current;
    if (childElement) {
      const parentContainer = childElement.parentElement;
      parentContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    scrollToChild();
  }, [commitSearch]);
  const scrollTop = () => {
    const page = document.getElementById('search-result-parent');
    page?.scrollTo({top: 0});
  }
  useEffect(() => {
    scrollTop()
  }, []);

  const renderSearchResultWithSelectedTab = useMemo(() => {
    if (selectedTab === SearchTabBarItem.ALL) return <AllSearchResultTab scrollTop={scrollTop} />;
    if (selectedTab === SearchTabBarItem.TRACKS)
      return <TrackSearchResultTab scrollTop={scrollTop} />;
    if (selectedTab === SearchTabBarItem.PLAYLISTS)
      return <PlaylistSearchResultTab scrollTop={scrollTop} />;
    if (selectedTab === SearchTabBarItem.ARTISTS)
      return <ArtistsSearchResultTab scrollTop={scrollTop} />;
    if (selectedTab === SearchTabBarItem.ALBUMS)
      return <AlbumSearchResultTab scrollTop={scrollTop} />;
  }, [selectedTab]);

  return (
    <Container header={<SearchResultHeader title={`جستجو برای "${searchparam}"`} />}>
      <div
        ref={childRef}
        dir="rtl"
        className="w-full h-full  overflow-scroll"
        id="search-result-parent"
      >
        <ScrollTopButton id='search-result-parent'/>
        <div className="w-[100.5%] fixed top-[56px] bg-secondary z-30 max-w-[520px] left-[50%] translate-x-[-50%]">
          <div className="w-full mt-4 px-3 mb-3">
            <SearchInput title={"title"} />
          </div>
          <SearchTabBar />
        </div>
        <div className="flex flex-col gap-6  mt-[115px]">
          {renderSearchResultWithSelectedTab}
        </div>
      </div>
    </Container>
  );
}

export default React.memo(SearchResult);
