import React from "react";

function AppleLogoSVG({classNames}) {
    return (
        <svg className={`${classNames}`}
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="24"
            fill="none"
            viewBox="0 0 19 24"
        >
            <path
                fill="#fff"
                d="M13.996.614h.162c.13 1.606-.483 2.806-1.228 3.675-.731.863-1.732 1.7-3.351 1.573-.108-1.583.506-2.694 1.25-3.561.69-.808 1.955-1.527 3.167-1.687zM18.897 17.33v.045c-.455 1.378-1.104 2.558-1.896 3.654-.723.995-1.609 2.334-3.191 2.334-1.367 0-2.275-.878-3.676-.902-1.482-.024-2.297.735-3.652.926H6.02c-.995-.145-1.798-.933-2.383-1.643C1.912 19.646.579 16.936.331 13.47v-1.02c.105-2.482 1.311-4.5 2.914-5.478.846-.52 2.009-.963 3.304-.765.555.086 1.122.276 1.619.464.471.181 1.06.502 1.618.485.378-.01.754-.208 1.135-.347 1.116-.403 2.21-.865 3.652-.648 1.733.262 2.963 1.032 3.723 2.22-1.466.933-2.625 2.34-2.427 4.74.176 2.182 1.444 3.458 3.028 4.21z"
            ></path>
        </svg>
    );
}

export default AppleLogoSVG;
