import {useMutation} from "@tanstack/react-query";
import {generateShareLink} from "../services/ApiClient.js";
import {mainToast} from "../helper/ToastsEmitter.jsx";
import {CopyText} from "../helper/CopyText.js";


export const useShare = (id,type) => {
    const requestPayload = {
        id,
        type
    }
    const generateShareLinkMutation = useMutation(() => generateShareLink(requestPayload),{
        onSuccess:(res) =>{
            handleShare(res?.data?.result?.link)
        }
    })
    const handleGetShareLink = () => {
        if(generateShareLinkMutation.isLoading){
            return
        }
        generateShareLinkMutation.mutate()
    }
    const handleShare = (link) => {
        const shareData = {
            title:'اشتراک گذاری',
            text: link
        }
        if (window.navigator.share) {
            window.navigator
                .share(shareData)
                .then(() => console.log("Yay, you shared it :)"))
                .catch((error) => console.log("Oh noh! You couldn't",error));
        } else {
            mainToast(".با موفقیت کپی شد ");
            CopyText(link);
        }
    };

    return {handleGetShareLink,generateLinkLoading:generateShareLinkMutation?.isLoading}
}
