import React from 'react';
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import {countDown} from "../../consts/CountDown.js";
import SleepTimerSvg from "../svg/player/SleepTimerSvg.jsx";
import DisableSleepTimerSvg from "../svg/player/DisableSleepTimerSvg.jsx";
import useCountdown from "../../hooks/useCountdown.jsx";
import {getPlayerTimer} from "../../helper/ReduxSelectorContext.js";
import {CardActionArea} from "@mui/material";

function TimerBottomSheet({closeBottomSheet, clear, start}) {
    const {sleepTimerEnable} = getPlayerTimer()
    const handleSelectItem = (time) => {
        if (time) {
            clear()
            start(time)
            closeBottomSheet()
        } else {
            clear()
            closeBottomSheet()
        }
    }
    return (
        <>
            <BottomSheetHeader HeaderIcon={<SleepTimerSvg/>} hasCloseButton={true} dividerLine={true}
                               closeBottomSheet={closeBottomSheet} title="تایمر خواب"/>
            <div dir='rtl' className='flex flex-col gap-4 w-full pt-3  '>
                <div className="w-full flex flex-col gap-4">
                    <p className='text-white font-dana text-[14px] px-4 font-normal text-gray_5'>متوقف کردن پخش پس از:</p>
                    <div className='flex flex-col w-full '>
                        {countDown?.map((item, index) =>
                                (!(!item?.time && !sleepTimerEnable)) && (
                               <CardActionArea>
                                   <div
                                       onClick={() => handleSelectItem(item?.time)}
                                       className={`flex   px-4  `}>
                                       <div className={`${item?.borderBottom && 'border-b-[1px] border-b-secondary'} w-full flex h-full gap-2 items-center py-4 `}>
                                           {
                                               item?.icon &&
                                               <div className='w-[24px] h-[24px] flex justify-center items-center '>
                                                   <DisableSleepTimerSvg/>
                                               </div>
                                           }
                                           <p
                                               className={`text-primary font-dana text-[14px] font-semibold leading-[24px]  `}>
                                               {item?.text}
                                           </p>
                                       </div>
                                   </div>
                               </CardActionArea>

                                )
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimerBottomSheet;
