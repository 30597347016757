import * as React from "react";
const SendAppSvg = (props) => (
  <svg
    width={18}
    height={20}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.33 6.445a2.667 2.667 0 1 0 0-5.334 2.667 2.667 0 0 0 0 5.334ZM3.667 12.667a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333Zm10.663 6.222a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333Zm-8.361-7.547 6.07 3.538m-.008-9.76L5.969 8.658"
      stroke="#E0E0E0"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SendAppSvg;
