import { memo } from 'react';

import { getInitialData } from '../../helper/ReduxSelectorContext.js';
import CustomImage from '../common/CustomImage.jsx';

function TicketsHeader() {
  const initialDataRedux = getInitialData();

  return (
    <div
      dir="rtl"
      className="flex bg-ticket-header min-h-[153px]  items-end p-7 text-right "
    >
      <div
        className={
          'w-[94px] h-[94px] bg-[#353535] rounded-full me-[13px] flex justify-center items-center '
        }
      >
        <CustomImage
          alt=""
          src={initialDataRedux?.ticket_page?.ticket_page_top_image}
          className="min-w-[96px] min-h-[96px]"
        />
      </div>
      <div className="flex flex-col">
        <div
          id="icon"
          className={
            initialDataRedux?.ticket_page?.ticket_page_top_icon === null
              ? ''
              : 'w-7 h-7 bg-[#353535] rounded-full '
          }
        >
          {initialDataRedux?.ticket_page?.ticket_page_top_icon && (
            <CustomImage
              alt=""
              src={initialDataRedux?.ticket_page?.ticket_page_top_icon}
            />
          )}
        </div>
        <p
          id="title"
          style={{
            color: initialDataRedux?.ticket_page?.ticket_page_first_color,
          }}
          className=" text-base font-medium font-dana"
        >
          {initialDataRedux?.ticket_page?.ticket_page_first_text}
        </p>
        <p
          id="subtitle"
          style={{
            color: initialDataRedux?.ticket_page?.ticket_page_second_color,
          }}
          className=" text-xs leading-6  font-medium font-dana"
        >
          {initialDataRedux?.ticket_page?.ticket_page_second_text}
        </p>
      </div>
    </div>
  );
}

export default memo(TicketsHeader);
