import { memo } from "react";

const CrownSvg = (props) => (
  <svg
    width={30}
    height={19}
    viewBox="0 0 30 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.904 17.342H2.344v.926h25.56v-.926Zm-.206-.912 1.939-9.833h-.57s-.919 5.816-4.631 5.2c-3.401-.569-2.389-6.127-2.389-6.127h-.794s.192 4.513-2.646 4.348c-3.699-.206-3.13-8.516-3.13-8.516h-.662s1.152 8.119-2.547 8.33c-2.865.16-3.255-4.162-3.255-4.162h-.788s1.026 5.24-2.375 5.81c-3.739.615-4.672-4.883-4.672-4.883H.61l1.94 9.832h25.149Z"
      fill="#F2C94C"
    />
    <path
      d="M16.467 1.323a1.323 1.323 0 1 1-2.647 0 1.323 1.323 0 0 1 2.647 0ZM22.61 5.38a.873.873 0 1 1-1.746 0 .873.873 0 0 1 1.746 0Zm-13.113 0a.873.873 0 1 1-1.747 0 .873.873 0 0 1 1.747 0ZM30 6.657a.873.873 0 1 1-1.746 0 .873.873 0 0 1 1.747 0ZM.873 7.53a.873.873 0 1 0 0-1.747.873.873 0 0 0 0 1.747Z"
      fill="#F2C94C"
    />
  </svg>
);
export default memo(CrownSvg) ;
