import React from 'react';

function EmptySongListSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="88"
            height="88"
            fill="none"
            viewBox="0 0 102 102"
            {...props}
        >
            <g
                fill="#4F4F4F"
                clipPath="url(#clip0_3103_4535)"
                filter="url(#filter0_d_3103_4535)"
            >
                <path d="M10.198 31.118c1.55 0 2.685-1.55 2.222-3.025a16.45 16.45 0 014.071-16.602A16.45 16.45 0 0133.092 7.42c2.852.896 4.25-3.548 1.393-4.443A21.09 21.09 0 0013.2 8.2a21.09 21.09 0 00-5.222 21.286 2.328 2.328 0 002.221 1.632z"></path>
                <path d="M30.712 16.588c2.853.895 4.25-3.548 1.393-4.443a11.9 11.9 0 00-12.012 2.947 11.902 11.902 0 00-2.948 12.013c.88 2.808 5.343 1.476 4.444-1.393a7.258 7.258 0 011.796-7.327 7.259 7.259 0 017.327-1.797zM46.793 53.827a10.43 10.43 0 00-5.835-1.777c-5.785 0-10.491 4.706-10.491 10.49 0 5.786 4.707 10.492 10.491 10.492 5.486 0 9.999-4.233 10.45-9.605l.041-26.347 14.667.001c3.416 0 4.775-4.665 1.81-6.467L50.33 19.925c-1.506-.914-3.537.215-3.537 1.99v31.912zm4.656-27.773l10.489 6.37H51.449v-6.37zm-10.49 42.322a5.842 5.842 0 01-5.836-5.836 5.841 5.841 0 015.835-5.834 5.842 5.842 0 015.835 5.834 5.842 5.842 0 01-5.835 5.836zM93.742 62.233c-.894-2.853-5.339-1.463-4.443 1.392a16.449 16.449 0 01-4.072 16.602 16.445 16.445 0 01-16.6 4.072c-2.853-.895-4.25 3.547-1.393 4.443 2.095.656 4.237.977 6.358.977a21.08 21.08 0 0014.927-6.2 21.09 21.09 0 005.223-21.286z"></path>
                <path d="M71.008 75.131c-2.853-.894-4.25 3.547-1.394 4.443a11.897 11.897 0 0012.012-2.947 11.9 11.9 0 002.948-12.013c-.894-2.853-5.338-1.464-4.443 1.392a7.257 7.257 0 01-1.797 7.328 7.26 7.26 0 01-7.326 1.797z"></path>
            </g>
            <defs>
                <filter
                    id="filter0_d_3103_4535"
                    width="101.333"
                    height="101.333"
                    x="0.333"
                    y="0.667"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="5.333"></feOffset>
                    <feGaussianBlur stdDeviation="3.333"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3103_4535"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_3103_4535"
                        result="shape"
                    ></feBlend>
                </filter>
                <clipPath id="clip0_3103_4535">
                    <path fill="#fff" d="M0 0H88V88H0z" transform="translate(7 2)"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default EmptySongListSvg;
