import {useDispatch} from "react-redux";
import {useMutation} from "@tanstack/react-query";
import { followingCollectionsData, globalData } from './ReduxSelectorContext.js';
import {
    followCollection,
    hideUnfollowDialog,
    showUnfollowDialog,
    unFollowCollection,
} from '../redux/globalData/globalDataSlice.js';
import {setFollowCollection, setUnFollowCollection} from "../services/ApiClient.js";
import { useState } from 'react';

export const useFollowCollection = (id) => {

    const dispatch = useDispatch();
    const followingCollections = followingCollectionsData();
    const [followCollectionLoading,setFollowCollectionLoading] = useState(false)
    const enableFollowCollectionLoading = () =>{
        setFollowCollectionLoading(true)
    }
    const disableFollowCollectionLoading = () =>{
        setFollowCollectionLoading(false)
    }
    const requestData = {collection_id: id};
    const findIndex = () => {
        return followingCollections?.findIndex((itemId) => Number(itemId) === Number(id));
    };
    const collectionIndex = findIndex();
    const followCollectionMutation = useMutation(() => setFollowCollection(requestData), {
        onSuccess:()=>{
            dispatch(followCollection({id}))
        },
        onSettled:()=>{
            disableFollowCollectionLoading()
        },
    });
    const unFollowCollectionMutation = useMutation(() => setUnFollowCollection(requestData), {
        onSuccess:()=>{
            dispatch(unFollowCollection({collectionIndex}));
        },
        onSettled:()=>{
            disableFollowCollectionLoading()

        },
    });
    const handleFollowCollection = () => {
        enableFollowCollectionLoading()
        followCollectionMutation.mutate()
        dispatch(hideUnfollowDialog())
    };
    const handleUnFollowCollection = () => {

        dispatch(hideUnfollowDialog())
        enableFollowCollectionLoading()
        unFollowCollectionMutation.mutate()
    };
    const toggleFollow = () => {
        if(followCollectionLoading){
            return
        }
        if (collectionIndex < 0) {
            handleFollowCollection()
        } else {
            dispatch(showUnfollowDialog({itemObject:{id, key:'playlist'}}))

        }
    };
    const unfollowCollection=()=>{
        handleUnFollowCollection()
    }

    const isFollow = followingCollections?.find((itemId) => Number(itemId) === Number(id));

    return [isFollow, toggleFollow,unfollowCollection,followCollectionLoading]
}
