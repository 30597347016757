import React from 'react';
import animationData from "../../assets/lottie-file/shareLottie.json";
import Lottie from "react-lottie";

function ShareAnimation({speed=1}) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
    };
    return (
        <div>
            <Lottie
                speed={speed}
                isClickToPauseDisabled={true}
                options={defaultOptions}
            />
        </div>
    )
}

export default ShareAnimation;
