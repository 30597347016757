import { toast } from "react-hot-toast";
import CloseToastSvg from "../components/svg/toast/CloseToastSvg";
import SuccessToastSvg from "../components/svg/toast/SuccessToastSvg";
import ErrorToastSvg from "../components/svg/toast/ErrorToastSvg";
import WarningToastSvg from "../components/svg/toast/WarningToastSvg";
export const successToast = (title, subTitle) => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-success-toast  flex flex-col ring-1 ring-black ring-opacity-5 py-5 px-8 rounded-[24px]`}
    >
      <div className="w-full flex justify-between items-center flex-row-reverse">
        <div className="flex flex-row gap-3 items-center">
          <p className="text-sm font-medium text-white fa-format-500 text-[18px] ">
            {title}
          </p>
          <SuccessToastSvg />
        </div>
        <div onClick={() => toast.remove(t.id)}>
          <CloseToastSvg />
        </div>
      </div>
      <div>
        <p className="mt-2  text-gray-7  text-right text-[14px] px-9 fa-format-300 ">
          {subTitle}
        </p>
      </div>
    </div>
  ));
};

export const errorToast = (title, subTitle) => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-error-toast  flex flex-col ring-1 ring-black ring-opacity-5 py-5 px-8 rounded-[24px]`}
    >
      <div className="w-full flex justify-between items-center flex-row-reverse">
        <div className="flex flex-row gap-3 items-center">
          <p className="text-sm font-medium text-white fa-format-500 text-[18px] ">
            {title}
          </p>
          <ErrorToastSvg />
        </div>
        <div onClick={() => toast.remove(t.id)}>
          <CloseToastSvg />
        </div>
      </div>
      <div>
        <p className="mt-2  text-gray-7  text-right text-[14px] px-9 fa-format-300 ">
          {subTitle}
        </p>
      </div>
    </div>
  ));
};
export const mainToast = (title,duration = 3000,position = 'bottom-center') => {
  toast.remove()
    toast.custom(t => {
      return (
          <div
              className={`${
                  t.visible ? "animate-enter" : "animate-leave"
              } max-w-sm w-full bg-white  flex flex-col py-3 px-8 rounded-[24px] font-dana`}
          >
            <div className="w-full flex justify-center items-center flex-row-reverse">
              <div className="flex flex-row gap-3 items-center">
                <p className="text-sm font-medium text-black_2 text-[18px] ">
                  {title}
                </p>
              </div>
            </div>
          </div>
      )
    }, {
      duration: duration,
      position:position
    });
};
export const warningToast = (title, subTitle) => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-warning-toast  flex flex-col ring-1 ring-black ring-opacity-5 py-5 px-8 rounded-[24px]`}
    >
      <div className="w-full flex justify-between items-center flex-row-reverse">
        <div className="flex flex-row gap-3 items-center">
          <p className="text-sm font-medium text-white fa-format-500 text-[18px] ">
            {title}
          </p>
          <WarningToastSvg />
        </div>
        <div onClick={() => toast.remove(t.id)}>
          <CloseToastSvg />
        </div>
      </div>
      <div>
        <p className="mt-2  text-gray-7  text-right text-[14px] px-9 fa-format-300 ">
          {subTitle}
        </p>
      </div>
    </div>
  ));
};

export const notifyAddToQueue = (text='آهنگ به صف اضافه شد') => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      }  bg-green  flex flex-col justify-center items-center ring-1 ring-black ring-opacity-5 py-3 px-12 rounded-br100`}
    >
      <p className="text-sm font-medium text-white fa-format-500 text-[16px] ">
        {text}
      </p>
    </div>
  ));
};
export const downloadToast = (text='خطا در دانلود',backgroundColor='#EB5757',textColor='#FAFAFA') => {
  toast.custom((t) => (
      <div
          style={{backgroundColor:backgroundColor}}
          className={`${
              t.visible ? "animate-enter" : "animate-leave"
          }  bg-green  flex flex-col justify-center items-center ring-1 ring-black ring-opacity-5 py-3 px-12 rounded-[24px]`}
      >
        <p style={{color:textColor}} className="text-sm font-medium  fa-format-500 text-[16px] ">
          {text}
        </p>
      </div>
  ));
};
export const toastWithSvg = (title, subTitle,Svg) => {
  toast.custom((t) => (
      <div
          className={`${
              t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-success-toast  flex flex-col ring-1 ring-black ring-opacity-5 py-5 px-8 rounded-[24px]`}
      >
        <div className="w-full flex justify-between items-center flex-row-reverse">
          <div className="flex flex-row gap-3 items-center">
            <p className="text-sm font-medium text-white fa-format-500 text-[18px] ">
              {title}
            </p>
            <SuccessToastSvg />
          </div>
          <div onClick={() => toast.remove(t.id)}>
            <CloseToastSvg />
          </div>
        </div>
        <div>
          <div dir='rtl' className='flex items-center mt-2 gap-[2px] lg-1440:gap-r2  '>
            <p className="  text-gray-7  text-right text-[14px] pr-9 fa-format-300 ">
              {subTitle}
            </p>
            <Svg/>
          </div>

        </div>
      </div>
  ));
};
