import { useInfiniteQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { selectSidebar, setShowHeader } from "../../redux/player/PlayerSlice";
import { getTickets } from "../../services/ApiClient";
import {
  selectBannedDialogData,
  selectIsShow,
  setBandialogOpen,
} from "../../redux/dialog/DialogSlice";
import { dispatchBannedDialog } from "../../redux/globalstore/GlobalStore";
import InfiniteScroll from "react-infinite-scroll-component";
import Container from "../../components/PageParentContainer/Container";
import CommonHeader from "../../components/common/CommonHeader";
import TicketsHeader from "../../components/Tickets/TicketsHeader";
import TicketsCard from "../../components/Tickets/TicketsCard";
import AddTicketSvg from "../../components/svg/tickets/AddTicketSvg";
import { CardActionArea } from "@mui/material";
import NoTicketSvg from "../../components/svg/tickets/noTicketSvg";
import FloatButtonContainer from "../../components/common/FloatButtonContainer";
import ScrollTopButton from "../../components/common/ScrollTopButton";
import SongListLoading from "../../components/loading/SongListLoading";
import TicketsShimmer from "./TicketsShimmer.jsx";
import { Virtuoso } from "react-virtuoso";

function Tickets() {
  const location = useLocation();
  const isShow = useSelector(selectIsShow);
  const bannedDialogData = useSelector(selectBannedDialogData);
  const { isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, data } =
    useInfiniteQuery(
      ["getTicketsInfiniteQuery"],
      ({ pageParam = 0 }) => getTickets({ offset: pageParam }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const allData = allPages?.flatMap((page) => page.data.result.tickets);
          return lastPage?.data?.result?.end ? false : allData?.length;
        },
        refetchOnWindowFocus: false,
        cacheTime: 0,
      }
    );
  const tickets = data?.pages?.flatMap((page) => page.data.result.tickets);

  const [HasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    setHasLoaded(true);
  }, []);

  const getNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  useEffect(() => {
    if (isShow) {
      dispatch(setBandialogOpen({ is_bannedOpen: true }));
      dispatchBannedDialog(bannedDialogData);
    }
  }, [isShow, bannedDialogData]);
  const showSideBar = useSelector(selectSidebar);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShowHeader({ header: !showSideBar }));
  }, []);

  const handleNewTicket = () => {
    navigate("/support");
  };
  return (
    <Container
      style="bg-secondary"
      header={
        <CommonHeader
          title="پشتیبانی"
          hasChevronNavigateBack={!location?.state?.banned}
        />
      }
    >
      <div
        id="tickets-container"
        dir="rtl"
        className="  bg-secondary w-full h-full  overflow-scroll hide-scroll-bar  pb-20 "
      >
        <ScrollTopButton id={"tickets-container"} />
        <TicketsHeader />
        <div
          className={`flex flex-col ${
            tickets?.length !== 0 ? "h-max" : "h-full"
          }`}
        >
          {isLoading ? (
            <TicketsShimmer />
          ) : tickets?.length !== 0 ? (
            <>
              <div className="flex justify-start w-full h-12 items-center px-4  py-4 bg-secondary sticky z-30 top-0">
                <p className="text-gray_6 text-sm my-4 font-normal sticky font-dana  bg-transparent leading-4">
                  لیست تیکت ها:
                </p>
              </div>
              <div id="ticket-cards" className="px-4 w-full h-full ">
                <InfiniteScroll
                  next={getNextPage}
                  hasMore={hasNextPage}
                  loader={
                    isFetchingNextPage &&
                    <div className="mt-4 grid justify-center">
                      <SongListLoading />
                    </div>
                  }
                  dataLength={tickets?.length || 0}
                  scrollableTarget={"tickets-container"}
                >
                  <div className=" pt-[10px]  ">
                    <Virtuoso
                      customScrollParent={document.getElementById(
                        "tickets-container"
                      )}
                      data={tickets}
                      itemContent={(index, ticket) => (
                        <div key={ticket.id} className="py-3">
                          <TicketsCard ticket={ticket} />
                        </div>
                      )}
                    />
                  </div>
                </InfiniteScroll>
              </div>
            </>
          ) : (
            <div className="px-4 gap-4  flex flex-col justify-center items-center h-full -mt-36">
              <NoTicketSvg />
              <p className="text-gray_2 font-semibold font-dana text-sm text-center">
                شما هنوز تیکتی ثبت نکردید
                <br />
                برای ثبت اولین تیکت
                <br />
                دکمه ثبت تیکت را لمس کنید
              </p>
            </div>
          )}
        </div>
      </div>
      <FloatButtonContainer>
        <CardActionArea
          onClick={() => handleNewTicket()}
          dir="rtl"
          className=" !h-12 !w-[156px] !rounded-3xl"
        >
          <div className=" rounded-3xl bg-primary h-[50px] w-[156px] flex justify-around items-center p-4 cursor-pointer">
            <AddTicketSvg />
            <p className="text-title_box text-sm font-dana font-medium">
              ثبت تیکت
            </p>
          </div>
        </CardActionArea>
      </FloatButtonContainer>
    </Container>
  );
}

export default Tickets;
