import React from "react";
import CloseBottomSheetIconSvg from "../svg/common/CloseBottomSheetIconSvg.jsx";
import { Icon, IconButton } from "@mui/material";

function BottomSheetHeader({
  title,
  hasCloseButton = true,
  closeBottomSheet,
  HeaderIcon,
  dividerLine,
}) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex flex-col  w-full justify-center"
    >
      <div
        dir="rtl"
        className="flex w-full justify-between items-center px-4 py-4"
      >
        <div className="flex gap-4 items-center">
          {HeaderIcon && HeaderIcon}

          <p
            className={`font-[500] font-kalameh text-white text-[18px] leading-6  pb-0 ${
              HeaderIcon && "pr-0"
            }`}
          >
            {title}
          </p>
        </div>
        {hasCloseButton && (
          <div onClick={closeBottomSheet} className="w-4 h-4 ml-1">
            <IconButton  className="!p-4 !-m-4" >
              <Icon className="!w-[14PX] !h-[14PX] ">
                <CloseBottomSheetIconSvg />
              </Icon>
            </IconButton>
          </div>
        )}
      </div>
      {dividerLine && (
        <div className="flex w-[calc(100%_-_32px)] h-[1.5px] bg-secondary mx-auto"></div>
      )}
    </div>
  );
}

export default BottomSheetHeader;
