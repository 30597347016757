import { CardActionArea } from "@mui/material";
import { memo } from "react";

const ProfileButtonHeader = ({ children, ...rest }) => {
  return (
    <CardActionArea className="!rounded-3xl !h-[46px] !w-[180px]">
      <button
        {...rest}
        className="bg-primary rounded-3xl h-full w-full  text-sm font-dana font-medium text-primary_dark "
      >
        {children}
      </button>
    </CardActionArea>
  );
};

export default memo(ProfileButtonHeader);
