
export const reportKeys = {
    artistReport: 'ARTIST_REPORT',
    trackReport: 'TRACK_REPORT',
    lyricReport: 'LYRIC_REPORT',
}
export const reportTypes = [
    {
        key: reportKeys?.artistReport,
        headerTitle: 'گزارش خطا خواننده',
        title: 'کدام خطا را در این خواننده مشاهده میکنید؟',
        apiUrl: '/submitReport',
        dataKey:'artist',
    },
    {
        key: reportKeys?.trackReport,
        headerTitle: 'گزارش خطاآهنگ',
        title: 'کدام خطا را در این آهنگ مشاهده میکنید؟',
        apiUrl: '/submitReport',
        dataKey: 'track',
    },
]

