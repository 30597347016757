import React from 'react';
import LeftArrowSvg from "../svg/plans/LeftArrowSvg.jsx";

function PaymentCard({plan}) {
    return (
        <div className='flex flex-col gap-1 w-full relative '>
            <div className="flex rounded-t-[20px] py-2 px-4 bg-gray_1 w-full flex-col gap-[0.5px]">
                <div className="flex gap-2 items-center">
                    <div className="flex bg-white rounded-full w-2 h-2"></div>
                    <p className="text-white font-kalameh font-medium text-[16px] leading-6">{plan?.invoice_title}</p>
                </div>
                {plan?.month == 12 && (
                    <div className="flex  items-center">
                        <p className="text-primary font-kalameh font-medium text-[12px] leading-6">{plan?.invoice_subtitle}</p>
                    </div>
                )}
            </div>
            {/*bottom section*/}
            <div className="flex rounded-b-[20px] px-2 py-4 bg-gray_1 flex-col gap-4 w-full">
                {plan?.old_monthly_price &&
                    <div className="flex flex-col w-full  items-start px-4">
                        <p className="text-white font-dana font-[400] text-[12px] leading-6">{plan?.invoice_month_title}</p>
                        <div className="flex justify-between w-full items-center">
                            <div className="flex flex-col items-center relative">
                                <div className="flex gap-1 ">
                                    <span
                                        className={`text-gray_4 text-[18px] font-kalameh  font-semibold   flex gap-1 items-center leading-6 `}>
                                    {plan?.old_monthly_price}
                                    </span>
                                    <span
                                        className={`text-gray_4 text-[14px] font-kalameh leading-5  font-medium  `}>
                                         {plan?.plan_unit_title}
                                    </span>
                                </div>
                                <div className={`absolute top-[60%] border-b-[1px] w-full  border-gray_4  `}></div>
                            </div>
                            <div className='w-[17px] h-[17px]'><LeftArrowSvg/></div>
                            <div className="flex gap-1 items-center ">
                                <span
                                    className={`text-primary text-[18px] font-kalameh  font-semibold leading-[24px]   `}>
                                    {plan?.monthly_price}
                                </span>
                                <span
                                    className={`text-primary text-[14px] font-kalameh  font-[400] leading-[24px] `}>
                                    {plan?.plan_unit_title}
                                </span>
                            </div>
                        </div>
                    </div>}
                <div className="w-full p-4 bg-secondary rounded-[20px] flex flex-col justify-center items-center">
                    <p className="text-gray_6 font-dana font-[400] text-[12px] leading-6">{plan?.invoice_price_final_title}</p>
                    <div className="flex flex-col items-center relative">
                        {plan?.old_monthly_price && <div className="flex gap-1 ">
                            <span
                                    className={`text-gray_5 text-[16px] font-kalameh  font-semibold  flex gap-1 items-center leading-6  `}>
                                     {plan?.old_price}
                            </span>
                            <span className={`text-gray_5 text-[12px] font-kalameh leading-5  font-medium `}>
                                         {plan?.invoice_unit_title}
                            </span>
                        </div>}
                        {plan?.month != 1 && <div className={`absolute top-[60%] border-b-[1px] w-full border-gray_5`}></div>}
                    </div>
                    <div className="flex gap-1 items-center ">
                        <span
                            className={`text-primary text-[24px] font-kalameh  font-black leading-[28px]`}>
                            {plan?.price}
                        </span>
                        <span
                            className={`text-primary text-[14px] font-kalameh  font-[400] leading-[24px]`}>
                                    {plan?.invoice_unit_title}
                        </span>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default PaymentCard;
