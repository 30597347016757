import React, {memo} from "react";
import {useAction} from "../../hooks/useAction";

function HomeMetaDataWithTitle({
                                   metaData = {
                                       title: "پلی لیست ها",
                                       show_more: true,
                                       title_color: "#FFFFFF",
                                       show_more_color: "#FFFFFF",
                                       type: "suggested_categories",
                                       has_background: false,
                                       show_more_text: "  دیدن همه",
                                       action: {
                                           target_type: "home_collection",
                                       },
                                   },
                                   hasChevronButton = true,
                                   eventClassName = {prev: "prev-button", next: "next-button"},
                                   item,
                                   artistCollection = false,
                                   fromMyMelodify,
                                   fromAlbums = false,
                                   listData = null
                               }) {
    const action = useAction();
    const actionData = () => {

        if (metaData?.type === "open_album") {
            return metaData?.action;
        }
        if (metaData?.action?.target_type === "exclusive_tracks") {

            return {...metaData?.action,listData};
        }
        if (metaData?.action?.target_type === "home_collection") {
            return metaData?.action;
        } else {

                return metaData.action;

        }
    };
    const handleAction = () => {
        action(actionData());
    };
    // console.log("fsdfsdfssdasdfd", metaData?.action);
    return (
        <div dir="rtl" className="flex justify-between   items-center relative">
            <div
                className={`flex flex-row gap-2   ${
                    fromMyMelodify ? "flex-col" : "flex-row items-baseline"
                }`}
            >
                {fromMyMelodify ? (
                    <p className="text-white font-dana font-semibold text-[18px]  ">
                        {metaData?.title}
                    </p>
                ) : fromAlbums ? (
                    <p className="text-white font-dana font-normal text-[18px] border-b-[2px]  border-yellow ">
                        {metaData?.title}
                    </p>
                ) : (
                    <p className="text-white font-kalameh font-[700] text-[26px]">
                        {metaData?.title}
                    </p>
                )}
                <p
                    className={`  ${
                        fromMyMelodify
                            ? " font-kalameh font-[400]  text-[14px] text-gray_4 "
                            : "font-kalameh font-[400] text-[14px] text-gray "
                    } `}
                    style={{color: metaData?.subtitle_color}}
                >
                    {metaData?.subtitle}
                </p>
            </div>

            <div className="flex gap-6  ">
                <div onClick={() => metaData?.show_more && handleAction()} className="">
                    <p
                        className={`${
                            metaData?.show_more ? "visible" : "invisible"
                        }  flex items-center justify-center w-[90px] h-[30px]  rounded-[30px]  text-xs font-kalameh font-[500] cursor-pointer`}
                        style={{color: metaData?.title_color}}
                    >
                        {metaData?.show_more_text}
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15 18.8809L9 12.8809L15 6.88086"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default memo(HomeMetaDataWithTitle);
