import { createSlice } from "@reduxjs/toolkit";

export const AuthErrorSlice = createSlice({
    name: 'authErrorSlice',
    initialState: {
        inactiveSession: null
    },
    reducers: {

        setInactiveSession: (state, action) => {
            const { inactiveSession } = action.payload
            state.inactiveSession = inactiveSession;
           
        }
    }
})

export const {setInactiveSession } = AuthErrorSlice.actions;

export const selectInactiveSession = (state) => state.authErrorSlice.inactiveSession;

export default AuthErrorSlice.reducer;
