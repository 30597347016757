import { memo } from "react";
import PropTypes from "prop-types";
import ChevronNavigateBack from "../../components/common/ChevronNavigateBack";
import TextMarquee from "../../components/common/TextMarquee";
import {setLanguageDirection} from "../../helper/LanguageDetection.js";

/**
 * A header component that displays a title and a back button
 *
 * @component
 * @param {object} props - The component props
 * @param {string} props.title - The title to display in the header
 * @param {ReactNode} [props.startAdornment=null] - An optional start adornment to display in the header
 * @param {boolean} [props.disableRipple=false] - Whether to disable the ripple effect on the back button
 * @param {boolean} [props.ChevronNavigateBackShadow=false] -have drop shadow effect on the back button
 * @param {boolean} [props.hasChevronNavigateBack=true] - Whether to display a back button with a chevron icon
 * @param {string | number} [props.backPath=-1] - The path to navigate back to when the back button is clicked
 * @param {string} [props.className=""] - An optional class name for the header container
 * @param {string} [props.TitleClassName=""] - An optional class name for the title container
 * @param {string} [props.navBtnClassName=""] - An optional class name for the back button container
 * @param {string} [props.navBtnIconClassName=""] - An optional class name for the back button icon
 * @param {object} [props.TitleProps] - An optional object containing props to pass to the title container
 * @returns {JSX.Element} A header element with the provided title and back button
 *
 * @example
 * // Example usage:
 * import CommonHeader from './CommonHeader';
 *
 * function MyComponent() {
 *   return (
 *     <div>
 *       <CommonHeader title="My Title" />
 *     </div>
 *   );
 * }
 */

function SearchResultHeader({
  title,
  startAdornment = null,
  disableRipple = false,
  ChevronNavigateBackShadow = false,
  hasChevronNavigateBack = true,
  backPath = -1,
  className = "",
  TitleClassName = "",
  navBtnClassName = "",
  navBtnIconClassName = "",
  startAdornmentClassName = "",
  TitleProps,
  ...rest
}) {
  return (
    <div
      dir="rtl"
      className={`flex bg-primary_dark h-14 items-center justify-between w-full px-4 ${className}`}
      {...rest}
    >
      <div className={`w-6 h-6  ${startAdornmentClassName}`}>
        {startAdornment}
      </div>
      <div className={` text-center w-[calc(90%-24px)]  ${TitleClassName}`} {...TitleProps}>
        <h1 dir={'ltr'} className="text-white text-lg font-dana font-normal pointer-events-none w-full text-center">
          <TextMarquee
              direction={'rtl'}
              noMarqueeParentStyle="justify-center"
          >{title}</TextMarquee>
        </h1>
      </div>
      <div className="w-6 h-6">
        {hasChevronNavigateBack && (
          <ChevronNavigateBack
            iconClassName={navBtnIconClassName}
            className={navBtnClassName}
            path={backPath}
            disableRipple={disableRipple}
            shadow={ChevronNavigateBackShadow}
          />
        )}
      </div>
    </div>
  );
}

SearchResultHeader.propTypes = {
  title: PropTypes.string,
  startAdornment: PropTypes.node,
  disableRipple: PropTypes.bool,
  hasChevronNavigateBack: PropTypes.bool,
  backPath: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  TitleClassName: PropTypes.string,
  navBtnClassName: PropTypes.string,
  navBtnIconClassName: PropTypes.string,
  TitleProps: PropTypes.object,
};

export default memo(SearchResultHeader);
