import React from 'react';
import {usePlayedTrack} from "../../hooks/usePlayedTrack.js";
import {useSelectTrackItem} from "../../hooks/useSelectTrackItem.js";
import {useDownload} from "../../hooks/useDownload.js";
import AddsLimit from "../playlist/AddsLimit.jsx";
import {CardActionArea} from "@mui/material";
import ExclusiveTrackCardImage from "./ExclusiveTrackCardImage.jsx";
import ExclusiveTrackCardInfo from "./ExclusiveTrackCardInfo.jsx";
import {setPlayerPaginationData} from "../../redux/player/PlayMusicSlice.js";
import {useDispatch} from "react-redux";

function ExclusiveTrackCard({item, index, list, showIsPlaying, isSelectMode = false, inPlayer, type = 'playlist',endOfPagination,requestPayload, apiUrl}) {
    const {isSelected} = useSelectTrackItem(inPlayer, item)
    const [currentTrack, setPlayerTrack] = usePlayedTrack();
    const [
        ,
        ,
        ,
        ,
        downloadedItem,
    ] = useDownload(item);
    const dispatch = useDispatch()
    const handlePlayMusic = (event, item) => {
        event?.stopPropagation()
        setPlayerTrack(
            downloadedItem ? downloadedItem : item,
            list,
            type,
        );
        dispatch(setPlayerPaginationData({
            playerPaginationData: {
                url: apiUrl,
                payload: requestPayload,
                end: endOfPagination
            }
        }))
    };

    return (
        <>

            {item?.add_on && <AddsLimit inPlayer={inPlayer} item={item}/>}
            <CardActionArea className=' !rounded-[10px]'>
                <div className={`p-[1px]  cursor-pointer group   rounded-[10px] relative overflow-visible ${
                    currentTrack?.id == item?.id && "bg-queue-border"
                }`}>
                    {currentTrack?.id == item?.id &&
                        <div
                            dir='ltr'
                            className="absolute flex w-[55%] top-[1px] bottom-[1px] left-0 bg-queue-border  opacity-20 rounded-[10px]"></div>
                    }
                    <div onClick={(event) => handlePlayMusic(event, item)}
                         className={`bg-black_2 w-full max-w-full   rounded-[10px] pl-2 pb-2 ${isSelected && 'bg-primary bg-opacity-20'}`}>
                        <div className='w-full flex gap-3'>
                            <ExclusiveTrackCardInfo showIsPlaying={showIsPlaying} item={item}
                                                    isSelectMode={isSelectMode} inPlayer={inPlayer}/>
                            <ExclusiveTrackCardImage item={item}/>
                        </div>
                    </div>
                </div>
            </CardActionArea>
        </>
    );
}

export default ExclusiveTrackCard;
