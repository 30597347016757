import React, { useState } from 'react';
import {
  checkTelegramConnection,
  generateTelegramLink,
  sendMusicToTelegram,
  unlinkTelegram,
} from '../services/ApiClient.js';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  initialData,
  selectInitialData,
} from '../redux/initialize/InitializeSlice.js';
import { IsIosDevice } from '../helper/CheckDevice.js';
import { errorToast, mainToast } from '../helper/ToastsEmitter.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { setLimitationDialogData, showRedirectToTelegramDialog } from '../redux/globalData/globalDataSlice.js';
import DownloadLimitationImag from '../assets/limitation/downloadImage.webp';
export const useTelegram = () => {
  const dispatch = useDispatch();
  const initialDataObjects = useSelector(selectInitialData);
  const [connectToTelegramLoading, setConnectToTelegramLoading] =
    useState(false);
  // has_connected(pin):false
  // connected_title(pin):null
  const openModal =(link)=>{
    dispatch(showRedirectToTelegramDialog({generatedLink: link}));
  }
  const connectToTelegram = async (requestData, trackTitle) => {
    if (connectToTelegramLoading) return;
    setConnectToTelegramLoading(true);
    console.log(initialDataObjects?.telegram_data);
    if (initialDataObjects?.telegram_data?.has_connected) {
      await handleSendMusicToTelegram(requestData, trackTitle);
      setConnectToTelegramLoading(false);
    } else {
      await handleGenerateTelegramLink(requestData);
      setConnectToTelegramLoading(false);
    }
  };
  const disconnectFromTelegram = async requestData => {
    await unlinkTelegramMutation.mutateAsync();
    await handleGenerateTelegramLink(requestData);
  };
  const ChangeTelegramAccount = async () => {
    if (connectToTelegramLoading) return;
    setConnectToTelegramLoading(true);
    await disconnectFromTelegram();
    setConnectToTelegramLoading(false);
  };
  const handleGenerateTelegramLink = async data => {
    mainToast('در حال اتصال به تلگرام... لطفاً صبور باشید');
    await generateTelegramLinkMutation.mutateAsync(data, {
      onSuccess: response => {
        if (response?.data?.done) {
          console.log(response);
          handleRedirectToTelegramLink(response?.data?.result?.link);
        } else {
          mainToast(response?.data?.error?.message);
        }
      },
    });
  };
  const handleRedirectToTelegramLink = link => {
    openModal(link)
  };
  const handleSendMusicToTelegram = async (data, trackTitle) => {
    await sendMusicToTelegramMutation.mutateAsync(data, {
      onSuccess: response => {
        console.log(response);
        if (response?.data?.error) {
          dispatch(
            setLimitationDialogData({
              limitationData: {
                message: response?.data?.error?.message,
                boxAlert: 'پایان تعداد دانلود',
                image: DownloadLimitationImag,
              },
            }),
          );
        } else {
          mainToast(
            <div dir="rtl" className="text-center">
              موزیک
              <span className="text-[#ffc000] mx-[2px]   "> {trackTitle} </span>
              به تلگرام ارسال شد...
            </div>,
          );
        }
      },
    });
  };

  // api call
  const checkTelegramConnectionQuery = useQuery(
    ['checkTelegramConnectionQuery'],
    () => checkTelegramConnection(),
    {
      onSuccess: response => {
        dispatch(
          initialData({
            initialData: {
              ...initialDataObjects,
              telegram_data: response?.data?.result?.telegram_data,
            },
          }),
        );
      },
    },
  );
  const generateTelegramLinkMutation = useMutation(
    data => generateTelegramLink(data),
    {},
  );
  const sendMusicToTelegramMutation = useMutation(data =>
    sendMusicToTelegram(data),
  );
  const unlinkTelegramMutation = useMutation(() => unlinkTelegram());

  return { connectToTelegram, ChangeTelegramAccount, connectToTelegramLoading };
};
