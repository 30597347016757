import React from 'react';

function CheckIconSvg({props,classList ,fill = "#F2F2F2"}) {
    return (
        <svg
            className={`${classList}`}
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.6663 1L5.49967 10.1667L1.33301 6"
                stroke={`${fill}`}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default CheckIconSvg;
