import React from 'react'

function OtherIconSVG({classList}) {
    return (
        <svg className={`${classList}`}
             fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 4.16699V15.8337" stroke="#F2C94C" strokeLinecap="round" strokeWidth="2"/>
            <path d="M4.16602 10H15.8327" stroke="#F2C94C" strokeLinecap="round" strokeWidth="2"/>
        </svg>
    )
}

export default OtherIconSVG