import React from 'react';

import { setLanguageDirection } from '../../../helper/LanguageDetection';
import PlaylistAndSongSearchResultAvatar from './PlaylistAndSongSearchResultAvatar';
import ArtistSearchResultAvatar from './ArtistSearchResultAvatar';
import SearchResultPlaylist from './SearchResultPlaylist';
import { useNavigate } from 'react-router-dom';
import { useStoreSearchHistory } from '../../../hooks/useStoreSearchHistory';
import { CardActionArea } from '@mui/material';
import ArtistPlaceHolderSvg from '../../../components/svg/common/ArtistPlaceHolderSvg';
import AlbumCardPlaceHolder from '../../../components/svg/album/AlbumCardPlaceHolder';
import ArtistProfilePlaceHolderSvg from '../../../components/svg/common/ArtistProfilePlaceHolderSvg';
import ArtistSearchIcon from '../../../components/svg/search/searchTypeIcon/ArtistSearchIcon';
import { textFontBasedLanguage } from '../../../helper/TextFontBasedLanguage.js';
function SearchResultArtist({ item, fromHistory, index }) {
  const [storeSearchItem] = useStoreSearchHistory(fromHistory);
  const navigate = useNavigate();
  const openArtist = artistItem => {
    navigate(`/artist-profile/${artistItem?.id}`, {
      state: { artistObject: artistItem },
    });
    storeSearchItem({ ...item, id: artistItem?.id });
  };
  return (
    <CardActionArea
      onClick={() => openArtist(item?.artist)}
      className={`${
        fromHistory ? '' : '!py-[10px]'
      }  !my-2 !rounded-[5px] !px-3 ${
        fromHistory
          ? ''
          : index % 2 !== 0
          ? 'bg-song-detail-card-background-2'
          : 'bg-song-detail-card-background'
      }`}
    >
      <div dir="rtl" className="flex justify-end items-center ">
        <div className="flex gap-2 items-center ">
          <div className="flex flex-col items-end gap-2 font-dana xl:ml-3">
            <p
              dir="ltr"
              className={`text-white text-sm font-medium line-clamp-1 max-w-[68vw] min-[520px]:max-w-sm ${textFontBasedLanguage(item?.artist?.name)} `}
            >
              {item?.artist?.name}
            </p>
            <div className="w-full flex flex-row items-center justify-end gap-[6px]">
              <ArtistSearchIcon />
              <p className="text-gray_4 text-xs font-normal   ">
                {item?.model_type_title}
              </p>
            </div>
          </div>
          <div className="w-[64px] h-[64px] relative">
            <div className="absolute w-full h-full">
              <ArtistPlaceHolderSvg />
            </div>

            <ArtistSearchResultAvatar image={item?.artist?.image} />
          </div>
        </div>
      </div>
    </CardActionArea>
  );
}

export default SearchResultArtist;
