import { memo } from "react";

function AppBadge({ children, className }) {
  return (
    <span
      className={`font-dana font-medium text-xs bg-red text-center h-[24px] w-[24px] text-white flex justify-center items-center rounded-full ${className} `}
    >
      {children}
    </span>
  );
}

export default memo(AppBadge);
