import {useDispatch} from "react-redux";
import {
    followArtist,
    hideUnfollowDialog,
    showUnfollowDialog,
    unFollowArtist,
} from '../redux/globalData/globalDataSlice.js';
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {setFollowArtist, setUnFollowArtist} from "../services/ApiClient.js";
import { followedArtistsData, globalData } from './ReduxSelectorContext.js';
import { useState } from 'react';

export const useFollow = (id, queryKey = null) => {
    id=Number(id)
    const [followArtistLoading,setFollowArtistLoading] = useState(false)
    const disableFollowArtistLoading = () =>{
        setFollowArtistLoading(false)
    }

    const enableFollowArtistLoading = () =>{
        setFollowArtistLoading(true)
    }
    const queryClient = useQueryClient()
    const dispatch = useDispatch();
    const followedArtists = followedArtistsData();
    const requestData = {artist_id: id};
    const findIndex = () => {
        return followedArtists?.findIndex((itemId) => Number(itemId) === Number(id));
    };
    const artistIndex = findIndex();
    const followArtistMutation = useMutation(() => setFollowArtist(requestData), {
        onSuccess: () =>{
            dispatch(followArtist({id}))
        },
        onSettled:()=>{
            disableFollowArtistLoading()
        }
    });
    const unFollowArtistMutation = useMutation(() => setUnFollowArtist(requestData), {
        onSuccess:()=>{
            dispatch(unFollowArtist({artistIndex}));
        },
        onSettled:()=>{
            disableFollowArtistLoading()

        }
    });
    const handleFollowArtist = () => {
        enableFollowArtistLoading()

        followArtistMutation.mutate()
    };
    const handleUnfollowArtist = () => {

        dispatch(hideUnfollowDialog())
        enableFollowArtistLoading()
        unFollowArtistMutation.mutateAsync(
            {},
            {
                onSuccess: () => {
                    if(queryKey) {
                        queryClient.invalidateQueries([queryKey])
                    }
                }
            }
        )
    };
    const toggleFollow = () => {
        if(followArtistLoading){
            return
        };
        if (artistIndex < 0) {
            handleFollowArtist()
        } else {
           dispatch(showUnfollowDialog({itemObject:{id,
                   key:'artist', queryKey}
           }))
        }
    };
    const unfollowArtist = () => {
        if(followArtistLoading){
            return
        }
        handleUnfollowArtist()
    }
    const isFollow = followedArtists?.find((itemId) =>Number( itemId) === Number(id));
    return [isFollow, toggleFollow,unfollowArtist,followArtistLoading]
}
