import React from "react";

function SortDropDownItem({
  title,
  subtitle,
  active,
  Icon,
  index,
  selectSort,
  updated = false,
  updatedCount = 0,
  type,
}) {
  return (
    <div
      dir="rtl"
      onClick={() => selectSort(index, title)}
      class="flex w-full justify-between pb-5 items-center px-[21px]  "
    >
      <div class="flex gap-3  items-center cursor-pointer">
        <div class="aspect-square h-[24px] ">
          {<Icon fill={active === index ? "#FFFFFF" : "#FFFFFF"} />}
        </div>
        <div className="flex flex-col ">
          <div className="flex items-center  gap-2">
            <p
              className={`${
                active == index ? "text-white" : "text-white"
              } font-dana text-[15px] font-normal leading-6`}
            >
              {title}
            </p>
            <div>
              {updated && type === "newly_added" && (
                <span className="text-primary font-dana font-normal    text-[14px] ">
                  (<span>{updatedCount} </span>آهنگ جدید)
                </span>
              )}
            </div>
          </div>
          <p
            className={`${
              active == index ? "text-gray_4" : "text-gray_4"
            } font-dana text-[12px] font-normal leading-6`}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SortDropDownItem;
