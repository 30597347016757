import React, {useEffect, useRef, useState} from 'react';
import {setLanguageDirection} from "../../helper/LanguageDetection.js";
import Marquee from "react-fast-marquee";

function TextMarquee({
                         children,
                         speed = 15,
                         direction = setLanguageDirection(children),
                         pauseOnHover = true,
                         parentClass = '',
                         noMarqueeParentStyle = '',
                     }) {

    const parentRef = useRef(null);
    const textRef = useRef(null);
    const [textIsOverflowing, setTextIsOverflowing] = useState(false);
    const [textWidth, setTextWidth] = useState(textRef?.current?.getBoundingClientRect()?.width)
    useEffect(() => {
        const parentWidth = parentRef?.current?.offsetWidth;
        // console.log('change overflow....', textIsOverflowing, children, parentWidth, textWidth)
        setTextIsOverflowing(textWidth > parentWidth);
        // console.log(textRef?.current)
        // return () => console.log('destroy...', children)
    }, [children, textWidth]);
    useEffect(() => {
        if (textRef.current) {
            let width = textRef.current.getBoundingClientRect().width;
            setTextWidth(width)
        }
    }, [children]);

    return (
        <div
            className={`${!textIsOverflowing && `overflow-hidden  ${noMarqueeParentStyle}`} w-full h-full  flex  ${parentClass} `}
            ref={parentRef}>
            {textIsOverflowing ?
                <Marquee direction={direction === 'rtl' ? 'right' : 'left'} className='line-clamp-1'>
                     <span dir={direction}  className={`${direction === 'rtl' ? 'ml-16' : 'mr-16'} ` } ref={textRef}>
                         {children}
                    </span>
                </Marquee> :
                <span dir={direction} ref={textRef} className='whitespace-nowrap '>
                    {children}
                </span>
            }
        </div>
    );
}

export default TextMarquee;
