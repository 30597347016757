import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserData } from "../helper/ReduxSelectorContext";
import { selectUserData, selectUserToken } from "../redux/login/LoginSlice";

function PublicRoute({ children }) {
  const navigate = useNavigate();
  const token = useSelector(selectUserToken);
  const user = useSelector(selectUserData);
  let userData = useUserData();

  useEffect(() => {
    console.log('public route called')
    redirectUser();
  }, [token]);

  const redirectUser = () => {
    if (token !== null && user !== null && userData?.is_registered) {
      navigate("/", { replace: true });
    }
  };
  return (
    <>
      <div className="w-full flex flex-col h-full relative select-none">
        {children}
      </div>
    </>
  );
}

export default PublicRoute;
