import React from 'react';


function EditButtonSvg({ style = "h-[1.31rem] w-[1.31rem]" ,fill='#F2C94C'}) {

    return (
        <div
            className={`${style}`}
        >
            <svg
                className="w-full h-full"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 21"
            >
                <path
                    stroke={fill}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 17.166h7.5M13.75 3.416a1.768 1.768 0 012.5 2.5L5.833 16.333l-3.333.833.833-3.333L13.75 3.416z"
                ></path>
            </svg>
        </div>
    );
}

export default EditButtonSvg;
