import { CDN_BASE_URL } from "../BASE";
import { IsIosDevice } from "./CheckDevice";

export const createMusicCdnLink = (playedMusic, userData, userToken) => {
    let quality = playedMusic?.mp3s?.filter(item => item?.quality == userData?.streaming_quality)

    let cdn_url = CDN_BASE_URL + `${userData?.streaming_quality && quality[0] ? quality[0]?.name : playedMusic?.mp3s[0]?.name}`
        + "?type=pwa&melodify_token=" +
        userData.id +
        "&download_token=" +
        userToken +
        //  "&stamp" + Date.now();
        `${IsIosDevice() ? "&hls=1" : ""}`
    // + "&stamp" + Date.now();

    console.log("Music Cdn Url", cdn_url);
    return cdn_url
}


// export const createMusicCdnLinkFromGlobalStor = (playedMusic, state) => {
//     let quality = playedMusic?.mp3s?.filter(item => item?.quality == state?.user?.user?.streaming_quality)
//     console.log("dsgsdgdsgdsggsdg", quality);
//     let cdn_url = CDN_BASE_URL + `${state?.user?.user?.streaming_quality && quality[0] ? quality[0]?.name : playedMusic?.mp3s[0]?.name}`
//         + "?type=pwa&melodify_token=" +
//         state?.user?.user.id +
//         "&download_token=" +
//         state?.user?.token +
//         //  "&stamp" + Date.now();
//         `${IsIosDevice() ? "&hls=1" : ""}`
//     // + "&stamp" + Date.now();

//     console.log("SDFsdfsdgsdgdsg_ll", cdn_url);
//     return cdn_url
// }


export const createAdvertiseCdnLink = (url) => {
    let cdn_url = CDN_BASE_URL + url + "?stamp" + Date.now();
    return cdn_url
}



