import DisableSleepTimerSvg from "../components/svg/player/DisableSleepTimerSvg.jsx";

export const countDown = [
    {
        endOfTrack: false,
        time: null,
        icon:DisableSleepTimerSvg,
        text: 'غیرفعال کردن',
        borderBottom: true,

    },
    {
        endOfTrack: false,
        time: 5,
        text: '5 دقیقه',
        borderBottom: true,

    },
    {
        endOfTrack: false,
        time: 10,
        text: '10 دقیقه',
        borderBottom: true,

    },
    {
        endOfTrack: false,
        time: 15,
        text: '15 دقیقه',
        borderBottom: true,
    },
    {
        endOfTrack: false,
        time: 30,
        text: '30 دقیقه',
        borderBottom: true,
    },
    {
        endOfTrack: false,
        time: 45,
        text: '45 دقیقه',
        borderBottom: true,
    },
    {
        endOfTrack: false,
        time: 60,
        text: '1 ساعت',
        borderBottom: false,
    },
    // {
    //     endOfTrack: true,
    //     time: 0,
    //     text: 'پایان این ترک',
    //     borderBottom: false,
    // },

]
