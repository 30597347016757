import HomeSliderCardITem from "./HomeSliderCardITem";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

function HomeSlider({ metaData, data, plan = false, heightRelation = null,slideItemContainerClass,slideItemContentClass,slideItemActionAreaClass, }) {
  return (
    <Splide
      style={{ padding: 0 }}
      options={{
        arrows: false,
        pagination: false,
        perPage: 1,
        perMove: 1,
        focus: "center",
        lazyLoad: true,
        drag: data?.length <= 1 ? false : true,
        interval: metaData?.auto_slide_time * 1000,
        autoplay: true,
        padding: 0,
        breakpoints: plan
          ? {
              370: {
                gap: -1,
              },
              430: {
                gap: -1,
              },
              490: {
                gap: -1,
              },
            }
          : {
              370: {
                gap: -27,
              },
              430: {
                gap: -30,
              },
              490: {
                gap: -35,
              },
            },
        direction: "rtl",
        //  type:"loop"
        rewind: true,
        // gap: "auto",
      }}
    >
      {data?.map((item, index) => (
        <SplideSlide key={index}>
          <HomeSliderCardITem
            heightRelation={heightRelation}
            slide={item}
            containerClass={slideItemContainerClass}
            contentClass={slideItemContentClass}
            actionAreaClass={slideItemActionAreaClass}
          />
        </SplideSlide>
      ))}
    </Splide>
  );
}

export default HomeSlider;
