import React, { useEffect, useState } from "react";
import MelodifySvg from "../../components/lyrics/svg/MelodifySvg.jsx";
import { useFormik } from "formik";
import { PHONE_VALIDATION_SCHEMA } from "../../consts/Validation.js";
import {
  checkPhoneWhitRegex,
  fixedPhone,
} from "../../helper/NumberFormatter.js";
import { useLocation, useNavigate } from "react-router-dom";
import { getOtp } from "../../services/ApiClient.js";
import { useMutation } from "@tanstack/react-query";
import Loading from "../../components/loading/Loading.jsx";
import LoginHeaderBackgroundImage from "../../components/login/LoginHeaderBackgroundImage.jsx";
import getNumberHeader from "../../assets/login/login-header.webp";
import {CardActionArea} from "@mui/material";
import PrimaryButton from "../../components/common/PrimaryButton.jsx";
import CommonHeader from "../../components/common/CommonHeader.jsx";

function GetPhoneMergeNumber(props) {
  const [showError, setShowError] = useState(false);
  const [emptyNumber, setEmptyNumber] = useState(false);
  const [falseNumber, setFalseNumber] = useState();
  const [error, setError] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();

  // const [message,setMessage]=useState()
  const mutateOtp = useMutation((phone) => getOtp(phone), {
    onSuccess: (res) => {
      if (res?.data?.done) {
        navigate("/update-phone", {
          replace: true,
          state: { phone: fixedPhone(formik.values.phone.toEnglishDigits()) },
        });
      }
      if (res?.data?.error?.message.includes('later')) {
        setError('تعداد درخواست های ورود شما بیش از حد مجاز است. لطفا چند دقیقه بعد امتحان کنید')
      }
      else {
        setError(res?.data?.error?.message)
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
    },

    onSubmit: (values) => {
      handleSendPhoneNumber(values.phone.toEnglishDigits());
    },
  });

  // send phone number to web service
  const handleSendPhoneNumber = (phone) => {
    formatPhone(phone) && mutateOtp.mutate({ phone: formatPhone(phone) });
  };

  const formatPhone = (phone) => {
    // check number validation
    if (checkPhoneWhitRegex(phone)) {
      setEmptyNumber(false);
      setFalseNumber(false);
      return fixedPhone(phone);
    } else if (!phone) {
      setEmptyNumber(true); 
      setFalseNumber(false);
    } else {
      setEmptyNumber(false);
      setFalseNumber(true);
    }
  };

  //   get phone number if come from edit number
  useEffect(() => {
    if (state?.phone !== undefined) {
      formik.setFieldValue("phone", state?.phone);
    }
  }, []);
  useEffect(() => {
    return () => localStorage.removeItem("message");
  }, []);
  return (
      <>
        <CommonHeader disableRipple={true} title={''}
                      className="fixed z-30 bg-transparent" />
      <div className=" bg-[#212121] text-white   w-screen h-screen max-w-[520px]">
        <div  >
          <header  >
            <LoginHeaderBackgroundImage loginHeader={getNumberHeader}>
              <div className=" h-[100px]  flex justify-center items-end">
              </div>
            </LoginHeaderBackgroundImage>
          </header>
          <main className={`px-[1.187rem] relative z-30 -mt-16`}>
            <div dir="rtl" className=" mt-3">
                    <div >
                  <span className="font-dana font-[700] ">
                    ویرایش شماره موبایل
                  </span>
                      <p className="font-dana font-[400] text-sm mt-1">
                        شماره موبایل جدید را وارد کنید،برای شماره ی جدید شما یک کد تایید ارسال خواهد شد.
                      </p>
                    </div>
            </div>
            <div className={" flex flex-col justify-center items-center"}>
              <div
                  className='mt-[.8125rem]  px-[1rem] py-[.75rem]  h-12 justify-center  items-center  flex w-full  border border-gray-4 rounded-[1.3rem]  overflow-hidden text-white border-gray-1 '>
                <div className="font-dana h-full font-[500] leading-7 mr-[.5rem] text-base">
                  +98
                </div>
                <span className="h-[2.06rem] border bg-gray_3 border-gray_3 w-px" ></span>

                <input
                    onKeyPress={(e) => e.key === "Enter" && formik.handleSubmit()}
                    autoComplete="off"
                    type="tel"
                    placeholder="شماره تلفن خود را وارد کنید"
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    maxLength="13"
                    className="h-full text-white font-dana  font-[500] text-base grow bg-transparent outline-none mx-[.5rem] placeholder-gray_4"
                />
              </div>
              <div>
                {emptyNumber && (
                    <p className="text-red text-[.875rem] font-dana  font-[400] mt-2  ">
                      شماره تلفن را وارد کنید
                    </p>
                )}
                {falseNumber && (
                    <div>
                      <p className="text-red text-[.875rem] font-dana  font-[400] mt-2 ">
                        . فرمت شماره موبایل اشتباه است
                      </p>
                    </div>
                )}
                {error && (
                    <div>
                      <p className="text-red text-[.875rem] font-dana  font-[400] mt-2 text-center ">
                        { error }
                      </p>
                    </div>
                )}
              </div>
              <CardActionArea className={"!mt-2 !rounded-[1.3rem]"}>
                <PrimaryButton
                    style="!text-[#333333] !font-dana !text-[1.04rem] !font-[510] h-[2.87rem] !w-full  !bg-primary !rounded-[1.3rem] !m-auto "
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    type="button"
                    disabled={mutateOtp.isLoading}>
                  {mutateOtp.isLoading ? (
                      <div>
                        <Loading bgColor={"#000000"} />
                      </div>
                  ) : (
                      showError ? "بعدی" : "تایید"
                  )}
                </PrimaryButton>
              </CardActionArea>
            </div>
          </main>
        </div >
      </div >
      </>
  );
}

export default GetPhoneMergeNumber;
