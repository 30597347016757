import { APP_VERSION, DEVICE_ID, DEVICE_NAME } from '../BASE';
import { dispatchAdvData } from '../redux/globalstore/GlobalStore';
import useAxios from './ApiService';

export const getOtp = phone => {
  return useAxios.post(`/getSms`, phone);
};

export const verifyCode = verifyData => {
  return useAxios.post('/verifyCode', verifyData);
};
export const updatePhone = verifyData => {
  return useAxios.post('/updatePhone', verifyData);
};
export const mergeAccounts = verifyData => {
  return useAxios.post('/mergeAccounts', verifyData);
};

export const register = registerData => {
  return useAxios.post('/register', registerData);
};

export const initializeRequest = checkSumSelector => {
  return useAxios.post(`/initializeRequest`, {
    ...checkSumSelector,
    app_version: APP_VERSION,
    device_id: DEVICE_ID,
    device_name: DEVICE_NAME,
    notification_permission_status:
      window?.Notification && Notification?.permission,
  });
};

export const getTickets = ticketData => {
  return useAxios.post('/ticketList', ticketData);
};

export const getAboutUsData = query => {
  return useAxios.get(query);
};
export const getFaq = () => {
  return useAxios.get('/getFaqs');
};
export const addNewTicket = data => {
  return useAxios.post(`/addTicket`, data);
};
export const sendCompleteRequest = ticket_reply_id => {
  return useAxios.post(`/completeTicket?ticket_reply_id=${ticket_reply_id}`);
};

export const sendMessageFile = (
  ticket_reply_id,
  data,
  progressEvent,
  signal,
) => {
  return useAxios.post(
    `/addTicketFile?ticket_reply_id=${ticket_reply_id}`,
    data.file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent,
      signal: signal,
    },
  );
};

export const getTicketsMessage = ticket_code => {
  return useAxios.get(`showTicket?ticket_code=${ticket_code}`);
};
export const addMessageReply = data => {
  return useAxios.post(`/addTicketReply`, data);
};
export const getSearchSuggestion = type => {
  return useAxios.post(`/getSearchSuggestions?type=${type}`);
};
export const getSearchResult = data => {
  return useAxios.post(`/search`, data, { signal: data?.signal });
};
export const getSearchResultV8 = data => {
  return useAxios.post(`/search`, data, { signal: data?.signal });
};

export const getArtistData = artistId => {
  return useAxios.get(`/getArtistData?artist_id=${artistId}`);
};
export const getArtistTracks = data => {
  return useAxios.post(`/getArtistTracks`, data);
};

export const getHomeData = () => {
  return useAxios.get(`/getHomeData`);
};

export const getMyMelodify = () => {
  return useAxios.get('/getMyMelodify');
};
export const getLikedTracks = offset => {
  return useAxios.post('/getUserLikedTracks', offset);
};
export const getUserMostListenedTracks = offset => {
  return useAxios.post('/getUserMostListenedTracks', offset);
};
export const getPlaylistTracksByType = offset => {
  return useAxios.post('/getPlaylistTracksByType', offset);
};
export const getUserLikedTracks = offset => {
  return useAxios.post('/getUserLikedTracks', offset);
};
export const getFollowedArtists = offset => {
  return useAxios.post('/getFollowedArtists', offset);
};
export const getFollowedCollections = offset => {
  return useAxios.post('/getFollowedCollections', offset);
};

export const getMyMelodifyItems = ({ apiKey, offset, type }) => {
  return useAxios.post(`${apiKey}`, { offset, type });
};
export const applyToken = TokenData => {
  return useAxios.post('/applyCoupon', TokenData);
};
export const createInvoice = invoiceData => {
  return useAxios.post('/createInvoice', invoiceData);
};
export const getUserActivity = () => {
  return useAxios.get('/getUserActivity');
};
export const trackLike = data => {
  return useAxios.post('/trackLike', data);
};
export const trackDisLike = data => {
  return useAxios.post('/trackDislike', data);
};
export const getUserCollections = () => {
  return useAxios.get('/getUserCollections');
};
export const addTrackToUserCollection = data => {
  return useAxios.post('/addTrackToUserCollection', data);
};
export const createUserCollection = data => {
  return useAxios.post('/createUserCollection', data);
};
export const setStreamingQuality = data => {
  return useAxios.post('/setStreamingQuality', data);
};
export const setDownloadQuality = data => {
  return useAxios.post('/setDownloadQuality', data);
};
export const setFollowArtist = data => {
  return useAxios.post('/followArtist', data);
};
export const setUnFollowArtist = data => {
  return useAxios.post('/unFollowArtist', data);
};
// collection_id:'dkf'
export const getCollectionData = collectionId => {
  return useAxios.get(`/getCollectionData?collection_id=${collectionId}`);
};
export const getCollectionTracks = data => {
  return useAxios.post('/getCollectionTracks', data);
};
export const setFollowCollection = data => {
  return useAxios.post('/followCollection', data);
};
export const setUnFollowCollection = data => {
  return useAxios.post('/unFollowCollection', data);
};
export const getFreeAcount = () => {
  return useAxios.post('/getShareInfo');
};
export const updateName = editedName => {
  return useAxios.post('/updateProfile', editedName);
};
export const getSession = () => {
  return useAxios.post('/getAllSessions');
};

export const removeSingleSession = data => {
  return useAxios.post('/removeSingleSession', data);
};
export const removeAllSessions = () => {
  return useAxios.post('/removeAllSessions');
};
export const PostQualityStream = quality => {
  return useAxios.post('/setStreamingQuality', quality);
};
export const getCollectionHome = () => {
  return useAxios.post('/getCollectionHome');
};

export const getArtists = data => {
  return useAxios.post('/getArtists', data);
};
export const getArtistsGroup = data => {
  return useAxios.post('/getArtistGroupArtists', data);
};

export const getPopularArtist = artis => {
  return useAxios.post('/getArtists', artis);
};
export const getPopularTracks = track => {
  return useAxios.post('/getTrendingTracks', track);
};
export const getNewTracks = track => {
  return useAxios.post('/getNewestTracks', track);
};
export const getDialogs = () => {
  return useAxios.post('/getDialogs');
};
export const getTrackLyrics = (track, signal) => {
  return useAxios.post('/getTrackLyrics', track);
};
export const getCollectionCategory = data => {
  return useAxios.post('/getCollectionCategory', data);
};

export const downloadTrack = (url, progressEvent, signal) => {
  return useAxios.get(url, {
    onDownloadProgress: progressEvent,
    signal: signal,
    responseType: 'arraybuffer',
  });
};

export const getArtistsAlbum = id => {
  return useAxios.post('/getArtistAlbums', id);
};
export const getUserCollectionTracks = (data, signal) => {
  return useAxios.post('/getUserCollectionTracks', data, {
    signal,
  });
};
export const getUserCollectionData = id => {
  return useAxios.post(`/getCollectionData?collection_id=${id}`);
};
export const getMyFavorites = data => {
  return useAxios.post(`/getMyFavorites`, data);
};
export const getHomeCustomCollection = () => {
  return useAxios.get(`/getHomeCustomCollection`);
};
export const lyricReport = data => {
  return useAxios.post(`/lyricReport`, data);
};
export const sendReport = data => {
  return useAxios.post('/submitReport', data);
};
export const getAlbumTracks = data => {
  return useAxios.post(`/getAlbumTracks`, data);
};
export const upkeepRequest = () => {
  return useAxios.post(`/upkeep`);
};
// export const editUserCollection = (dataObject) => {
//
//     return useAxios.post(`/updateUserCollection`, dataObject?.data,
//
//
//         {
//             headers: {
//                 "Content-Type": "multipart/form-data",
//             },
//             params: {tracks: dataObject?.query}
//         }
//     );
// };
export const editUserCollection = dataObject => {
  const formData = new FormData();
  formData.append('tracks', JSON.stringify(dataObject.query));

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      tracks: dataObject.query,
    },
  };

  return useAxios.post('/updateUserCollection', dataObject?.data, config);
};

export const checkAdvertise = (url, signal) => {
  if (url)
    return useAxios.get(url, {
      signal: signal,
      headers: {
        Range: 'bytes=0-0',
      },
    });
};
export const listenTrack = data => {
  return useAxios.post('/listens', data);
};
export const getAdvertise = type => {
  const api = useAxios.post('/getAdvertise', { type: type }).then(res => {
    dispatchAdvData(res?.data?.result?.advertises);
  });
  return api;
};
export const checkTelegramConnection = () => {
  return useAxios.post('/checkIfTelegramConnected');
};
export const unlinkTelegram = () => {
  return useAxios.post('/unlinkTelegram');
};
export const generateTelegramLink = data => {
  return useAxios.post('/generateTelegramLink', data);
};
export const sendMusicToTelegram = data => {
  return useAxios.post('/sendMusicToTelegram', data);
};
export const getCategoryCollections = data => {
  return useAxios.post(`/getCategoryCollections`, data);
};
export const getSectionCollections = data => {
  return useAxios.post(`/getSectionCollections`, data);
};
export const deleteUserCollection = data => {
  return useAxios.post(`/deleteUserCollection`, data);
};
export const renameUserCollection = data => {
  return useAxios.post(`/renameUserCollection`, data);
};
export const getCollectionCategoryChildren = data => {
  return useAxios.post(`/getCollectionCategoryChildren`, data);
};

export const getPlans = () => {
  return useAxios.get('/getPlans');
};
export const getExclusiveTracks = (url, data) => {
  return useAxios.post(`/${url}`, data);
};
export const getArtistTracksWithExcludedTrackId = data => {
  return useAxios.post(`/getMoreTracksByTrackId`, data);
};
export const searchHits = data => {
  return useAxios.post(`/searchHits`, data);
};
export const downloadDone = data => {
  return useAxios.post(`/downloads`, data);
};
export const sendBannerVisit = data => {
  return useAxios.post('/visit', data);
};
export const getGifts = offset => {
  return useAxios.post('/getGiftsList', { offset });
};
export const activateGiftApi = giftData => {
  return useAxios.post('/activateGift', giftData);
};
export const updateGiftApi = giftData => {
  return useAxios.post('/updateGift', giftData);
};
export const getPlayerPaginationTrack = (url, data) => {
  return useAxios.post(url, data);
};
export const createGiftInvoice = invoiceData => {
  return useAxios.post('/createGiftInvoice', invoiceData);
};
export const getGiftTutorial = () => {
    return useAxios.get(`/getGiftTutorial`);
};

export const updateFirebaseToken = (data) => {
    return useAxios.post('/updateFirebaseToken', data)
}
export const getGeneralCollectionTracks = (data) => {
    return useAxios.post("/getGeneralCollectionTracks", data);
};

export const getGeneralCollections = (data) => {
    return useAxios.post("/getGeneralCollections", data);
};
export const getSurveyInfo = () => {
    return useAxios.get('/getSurveyInfo')
}
export const getUserPublishedTracks = (data) => {
    return useAxios.post('/getUserPublishedTracks',data)
}
export const generateShareLink = (data) => {
    return useAxios.post('/generateShareLink',data)
}
