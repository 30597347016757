import React from 'react'


function ChevronSVG({classList,fill}) {
  return (
    <svg  className={`${classList}`} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.283007 9.99997C0.283007 9.64153 0.419868 9.28313 0.693014 9.00985L9.29267 0.410283C9.83971 -0.136761 10.7267 -0.136761 11.2735 0.410284C11.8203 0.957107 11.8203 1.84387 11.2735 2.39096L3.66402 9.99997L11.2732 17.609C11.82 18.1561 11.82 19.0427 11.2732 19.5895C10.7264 20.1368 9.83945 20.1368 9.2924 19.5895L0.692748 10.9901C0.419557 10.7167 0.283007 10.3583 0.283007 9.99997Z"
          fill={`${fill}`}/>
    </svg>
  )
}

export default ChevronSVG
