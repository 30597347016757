import React from 'react'

function LumpSvg ({classList,fill}) {
  return (
      <svg  className={`${classList}`}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3.457C11.586 3.457 11.25 3.121 11.25 2.707V0.75C11.25 0.336 11.586 0 12 0C12.414 0 12.75 0.336 12.75 0.75V2.707C12.75 3.121 12.414 3.457 12 3.457Z"
      fill="#F2C94C" />
    <path
      d="M18.5701 6.17967C18.3781 6.17967 18.1861 6.10667 18.0401 5.95967C17.7471 5.66667 17.7471 5.19167 18.0401 4.89867L19.4241 3.51467C19.7171 3.22167 20.1921 3.22167 20.4851 3.51467C20.7781 3.80767 20.7781 4.28267 20.4851 4.57567L19.1011 5.95967C18.9541 6.10567 18.7621 6.17967 18.5701 6.17967Z"
      fill="#F2C94C" />
    <path
      d="M23.25 12.75H21.293C20.879 12.75 20.543 12.414 20.543 12C20.543 11.586 20.879 11.25 21.293 11.25H23.25C23.664 11.25 24 11.586 24 12C24 12.414 23.664 12.75 23.25 12.75Z"
      fill="#F2C94C" />
    <path
      d="M19.9541 20.7051C19.7621 20.7051 19.5701 20.6321 19.4241 20.4851L18.0401 19.1011C17.7471 18.8081 17.7471 18.3331 18.0401 18.0401C18.3331 17.7471 18.8081 17.7471 19.1011 18.0401L20.4851 19.4241C20.7781 19.7171 20.7781 20.1921 20.4851 20.4851C20.3381 20.6321 20.1461 20.7051 19.9541 20.7051Z"
      fill="#F2C94C" />
    <path
      d="M4.04663 20.7051C3.85463 20.7051 3.66263 20.6321 3.51663 20.4851C3.22362 20.1921 3.22362 19.7171 3.51663 19.4241L4.90063 18.0401C5.19363 17.7471 5.66863 17.7471 5.96163 18.0401C6.25463 18.3331 6.25463 18.8081 5.96163 19.1011L4.57763 20.4851C4.43063 20.6321 4.23863 20.7051 4.04663 20.7051Z"
      fill="#F2C94C" />
    <path
      d="M2.707 12.75H0.75C0.336 12.75 0 12.414 0 12C0 11.586 0.336 11.25 0.75 11.25H2.707C3.121 11.25 3.457 11.586 3.457 12C3.457 12.414 3.121 12.75 2.707 12.75Z"
      fill="#F2C94C" />
    <path
      d="M5.43063 6.17967C5.23863 6.17967 5.04663 6.10667 4.90063 5.95967L3.51663 4.57567C3.22362 4.28267 3.22362 3.80767 3.51663 3.51467C3.80963 3.22167 4.28463 3.22167 4.57763 3.51467L5.96163 4.89867C6.25463 5.19167 6.25463 5.66667 5.96163 5.95967C5.81363 6.10567 5.62263 6.17967 5.43063 6.17967Z"
      fill="#F2C94C" />
    <path
      d="M15 21V22.25C15 23.21 14.21 24 13.25 24H10.75C9.91 24 9 23.36 9 21.96V21H15Z"
      fill="#F2C94C" />
    <path
      d="M16.4113 6.56058C14.7713 5.23058 12.6113 4.71058 10.5013 5.16058C7.85129 5.71058 5.70129 7.87058 5.15129 10.5206C4.59128 13.2406 5.61129 15.9406 7.79129 17.5906C8.38129 18.0306 8.79129 18.7106 8.93129 19.5006V19.5106C8.95129 19.5006 8.98129 19.5006 9.00129 19.5006H15.0013C15.0213 19.5006 15.0313 19.5006 15.0513 19.5106V19.5006C15.1913 18.7406 15.6413 18.0406 16.3313 17.5006C18.0213 16.1606 19.0013 14.1606 19.0013 12.0006C19.0013 9.88058 18.0613 7.90058 16.4113 6.56058ZM15.7513 12.5006C15.3413 12.5006 15.0013 12.1606 15.0013 11.7506C15.0013 10.2306 13.7713 9.00058 12.2513 9.00058C11.8413 9.00058 11.5013 8.66058 11.5013 8.25058C11.5013 7.84058 11.8413 7.50058 12.2513 7.50058C14.5913 7.50058 16.5013 9.41058 16.5013 11.7506C16.5013 12.1606 16.1613 12.5006 15.7513 12.5006Z"
      fill="#F2C94C" />
    <path
      d="M8.92969 19.5H8.99969C8.97969 19.5 8.94969 19.5 8.92969 19.51V19.5Z"
      fill="#F2C94C" />
    <path
      d="M15.05 19.5V19.51C15.03 19.5 15.02 19.5 15 19.5H15.05Z"
      fill="#F2C94C" />
  </svg>
  )
}

export default LumpSvg