import React, {useEffect, useState} from 'react';
import ContextMenu from "../common/ContextMenu.jsx";
import {useDispatch} from "react-redux";
import {closeDropDownMenu} from "../../redux/player/DropDownSlice.js";
import {contextMenuData} from "../../helper/ReduxSelectorContext.js";
import {useDownload} from "../../hooks/useDownload.js";
import {ContextMenuKeys, menuItemsGroups} from "../../consts/MenuItemsConsts.js";

function ContextMenuContainer({open}) {
    const dispatch = useDispatch()
    const {isDownloadedTrack} = contextMenuData();
    const [menuItems, setMenuItems] = useState([])
    const closeMenu = (e) => {
        e?.stopPropagation();
        dispatch(closeDropDownMenu())
    }
    const filterMenuItems = () => {
        if (isDownloadedTrack) {
            setMenuItems(menuItemsGroups)
            return
        }
        const newMenuItems = menuItemsGroups.filter((item, index) => (item?.key != ContextMenuKeys.REMOVE_FROM_DOWNLOAD && item?.key != ContextMenuKeys.SAVE_IN_DEVICE))
        setMenuItems(newMenuItems)
    };
    useEffect(() => {
        filterMenuItems()
    }, [isDownloadedTrack]);


    return (
        <div dir='rtl' onClick={(e) => closeMenu(e)} className='fixed  top-0 right-0  left-0 bottom-0  z-[100]'>
            <ContextMenu menuItems={menuItems} openState={open} closeContext={closeMenu}/>
        </div>
    );
}

export default React.memo(ContextMenuContainer);
