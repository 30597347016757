import axios from "axios";
// import {json} from 'react-router-dom';
// import {toast} from 'react-toastify';
import { APP_VERSION, APP_VERSION_NAME, BASE_URL, CDN_BASE_URL, DEVICE_ID, DEVICE_NAME, os, USER_DEVICE_INFO, } from "../BASE";
import { AuthAction } from "../consts/AuthAction";
import {
    dispatchBannedUser,
    dispatchInactiveSession,
    dispatchUnauthenticatedUser,
    getDeviceTokenFromGlobalStore,
    getTokenFromGlobalStore,
    getUserIdFromGlobalStore,
    toggleHaveAdvertise,
} from "../redux/globalstore/GlobalStore";
// import {ACTIONS} from '../constant/AuthAction';

const useAxios = axios.create({
    baseURL: BASE_URL,
});
const getToken = () => {
    const token = getTokenFromGlobalStore();
    return token;
};
const getUserId = () => {
    const userId = getUserIdFromGlobalStore();
    return userId;
};
useAxios.interceptors.request.use(
    (config) => {
        (config.headers["Accept"] = "application/json"),
            (config.headers["Access-Control-Allow-Origin"] = "*"),
            (config.headers["authorization"] = getToken());
        config.headers["app-version"] = APP_VERSION;
        config.headers["pwa-version"] = APP_VERSION_NAME;
        config.headers["device-id"] = DEVICE_ID;
        config.headers["device-name"] = DEVICE_NAME;

        config.headers["device-token"] = getDeviceTokenFromGlobalStore();
        config.headers["user-id"] = getUserId();
        config.headers["user-device-info"] = USER_DEVICE_INFO;
        config.headers["platform"] = os;
        if (config.headers["Content-Type"] === "multipart/form-data") {
            // Set the Content-Type header to multipart/form-data
            config.headers["Content-Type"] = "multipart/form-data";
        } else {
            config.headers["Content-Type"] = "application/json";
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    });

useAxios.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            if (response?.data?.done) {
                // dispatchActiveSessionsDialog(null)
                dispatchInactiveSession(null);
            }
        }
        return response;
    },
    (error) => {
        if (error?.code === 'ERR_CANCELED'){
          return Promise.reject({code:error?.code,error})
        }
        if (error.response.status === 500) {
        }
        if (error?.response?.status === 401) {
            if (error?.response?.config?.headers?.Range === "bytes=0-0") {

            } else {
                // decide remove forever in 401
                // dispatchUnauthenticatedUser();
            }
        }
        if (error.response.status === 403) {
            // toast("دسترسی غیرمجاز");
        }
        if (error.response.status === 490) {
            if (error?.response.data && error?.response.data instanceof ArrayBuffer) {
                errorActions(parseArryBufferResponse(error?.response.data))
            } else {
                errorActions(parseToJson(error?.response?.data?.message))
            }
        }
        if (error.response.status === 406) {
            errorActions(parseToJson(error?.response?.data?.message))
        }
        if (error.response.status >= 480 && error.response.status <= 489) {
            if (error.response.status >= 485 && error.response.status <= 488) {
              //this handle download error in global download component
                return Promise.reject(error);
            }
            if (error.response.status == 489) window.location.reload()
            else toggleHaveAdvertise("SERVER")

        }

        return Promise.reject(error);
    }
);
export default useAxios;

const errorActions = (message) => {
    if (message?.type == AuthAction.INVALID_TOKEN) {
        //remove for convert
        dispatchUnauthenticatedUser();
        if (message?.logout_action.target_type === AuthAction.LOGOUT) {
            localStorage.setItem("message", JSON.stringify(message?.logout_action?.logout_text));
        }
    } else if ( message?.type == AuthAction.INACTIVE_SESSION) {
        dispatchInactiveSession(message);
    } else if (message?.type == AuthAction.BANNED) {
        dispatchBannedUser(message);
    } else if ( message?.type == AuthAction.INVALID_USER_CREDENTIAL) {
        //remove for convert
        dispatchUnauthenticatedUser();
    }
}

const parseArryBufferResponse = (response) => {
    const decoder = new TextDecoder();
    const decodedResponse = decoder.decode(response);
    const ParsedResponse = JSON.parse(JSON.parse(decodedResponse)?.message)
    return ParsedResponse
}

const parseToJson = (data) => JSON.parse(data)
