import React, {Fragment, useRef, useState} from 'react';
import {Menu, Transition} from "@headlessui/react";
import {IconButton} from "@mui/material";
import ThreeDotSvg from "../svg/common/ThreeDotSvg.jsx";
import ConfirmDialogs from "../dialogs/ConfirmDialogs.jsx";
import TrashSVG from "../svg/common/TrashSVG.jsx";
import EditButtonSvg from "../svg/common/EditButtonSvg.jsx";
import TrashType2Svg from "../svg/playList/TrashType2Svg.jsx";
import {useMutation} from "@tanstack/react-query";
import {deleteUserCollection} from "../../services/ApiClient.js";
import {useDispatch} from "react-redux";
import {deleteUserCollectionItem, setDragList, toggleDragging} from "../../redux/globalData/globalDataSlice";
import {useNavigate} from "react-router-dom";
import {mainToast} from "../../helper/ToastsEmitter.jsx";
import {useContextPosition} from "../../helper/useContextPosition.js";
import EditUserPlaylistBottomSheet from "./EditUserPlaylistBottomSheet";
import FullPageNoteLoading from "../loading/FullPageNoteLoading.jsx";
import PenSvg from "../svg/playList/PenSvg.jsx";

function UserPlaylistContextMenu({collectionData, editName, trackList}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const editPlaylistRef = useRef(null)
    const deletePlaylistRef = useRef(null)
    const [verticalPositionObject, setVerticalPositionObject] = useState(null)
    const [horizontalPositionObject, setHorizontalPositionObject] = useState(null)
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)
    const enableEditMode = () => {
        // Add the #edit hash to the current route
        navigate('#edit',{state:{playlistData:collectionData}})
    };
    const openContextMenu = (e) => {
        const [vertical, horizontal] = useContextPosition(e);
        console.log(vertical, horizontal)
        setVerticalPositionObject(vertical)
        setHorizontalPositionObject(horizontal)
        setIsContextMenuOpen(true)

    }
    const closeContextMenu = (e) => {
        setIsContextMenuOpen(false)
    }
    const deleteUserPlaylistMutation = useMutation(() => deleteUserCollection({user_collection_id: collectionData?.id}), {
        onSuccess: (response) => {
            dispatch(deleteUserCollectionItem({id: collectionData?.id}))
            mainToast('.با موفقیت حذف شد')
            navigate('/my-melodify/user-playlists', {replace: true})
        }
    })
    const handleDeletePlaylist = () => {
        deleteUserPlaylistMutation.mutate()
    }
    const openDeletePlaylistH = () => {
        deletePlaylistRef.current.openDialog()
    }

    const editPlaylistHandler = () => {
        if (editName) {
            editPlaylistRef.current.openEditPlaylist()
        } else {
            enableEditMode()
            dispatch(setDragList({list: trackList}))

        }
    }
    return (
        <>
            {deleteUserPlaylistMutation?.isLoading &&
                <FullPageNoteLoading/>
            }
            <EditUserPlaylistBottomSheet collection={collectionData} ref={editPlaylistRef}/>
            <ConfirmDialogs
                dialogIcon={<TrashType2Svg style={'!w-full !h-full'} fill={'#fff'}/>}
                title={"حذف"}
                text={`پلی لیست 
                 ${collectionData?.title}
                 را
                 حذف میکنید؟ `}
                buttonDirection={"dir-ltr"}
                confirmText={"حذف"}
                confirmTextColor={"text-primary"}
                confirmBGColor={"bg-transparent"}
                confirmBorderColor={"border-primary"}
                confirmAction={handleDeletePlaylist}
                cancelText={"لغو"}
                cancelTextColor={"text-secondary"}
                cancelBGColor={"bg-primary"}
                cancelBorderColor={"transparent"}
                ref={deletePlaylistRef}
            />


            <Menu>
                {({open}) => (
                    <>
                        <Menu.Button>
                            <IconButton
                                onClick={openContextMenu}
                                className='!-mr-4'
                                disableRipple={false}
                            >
                                <div className='py-2 px-4  rounded-full'>
                                    <ThreeDotSvg fill={'#F2F2F2'}/>
                                </div>
                            </IconButton>
                        </Menu.Button>
                        {isContextMenuOpen && (
                            <div dir='rtl' onClick={closeContextMenu}
                                 className='fixed top-0 right-0  left-0 bottom-0  z-[100]'>
                                <Transition
                                    show={isContextMenuOpen}
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Menu.Items static className=' fixed z-[1] '
                                                style={{
                                                    ...verticalPositionObject?.top ? {top: verticalPositionObject?.top + 'px'} : {bottom: verticalPositionObject?.bottom + 'px'},
                                                    ...horizontalPositionObject?.left ? {left: horizontalPositionObject?.left + 'px'} : {right: horizontalPositionObject?.right + 'px'}

                                                }}
                                    >
                                        <Menu.Item as={Fragment}>
                                            <div className={` overflow-hidden rounded-[15px]
                                         ${verticalPositionObject?.top && horizontalPositionObject?.left && 'rounded-tl-[0px]'}
                                         ${verticalPositionObject?.top && horizontalPositionObject?.right && 'rounded-tr-[0px]'}
                                         ${verticalPositionObject?.bottom && horizontalPositionObject?.left && 'rounded-bl-[0px]'}
                                         ${verticalPositionObject?.bottom && horizontalPositionObject?.right && 'rounded-br-[0px]'}
                                         
                                         `}>
                                                <div
                                                    onClick={editPlaylistHandler}
                                                    className='flex bg-gray_1   w-[180px] gap-4 items-center px-3 cursor-pointer  h-[56px]  '>
                                                    <div>
                                                        <PenSvg/>
                                                    </div>
                                                    <p className=' text-white font-dana font-normal text-[16px] '>{editName ? 'تغییر نام' : 'ویرایش'}</p>
                                                </div>
                                                <div
                                                    onClick={openDeletePlaylistH}
                                                    className='flex bg-gray_1   w-[180px] gap-4 items-center px-3 cursor-pointer  h-[56px]  '>
                                                    <TrashType2Svg/>
                                                    <p className=' text-white font-dana font-normal text-[16px] '>حذف</p>
                                                </div>
                                            </div>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </div>
                        )}
                    </>
                )}
            </Menu>
        </>

    );
}

export default UserPlaylistContextMenu;
