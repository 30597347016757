import React, {useState} from 'react';
import {useLocation} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../components/loading/Loading.jsx";
import {useDispatch} from "react-redux";
import {getSearchQuery} from "../../helper/ReduxSelectorContext.js";
import AlbumCardItem from "../../components/album/AlbumCardItem.jsx";
import {useAction} from "../../hooks/useAction.js";
import {useQuery} from "@tanstack/react-query";
import {getArtistsAlbum} from "../../services/ApiClient.js";
import TicketsSkeleton from "../../components/Tickets/TicketsSkeleton.jsx";

function AlbumList(props) {
    const location = useLocation()
    const artistId = location?.state?.artistId;
    const action = useAction()
    const [artistAlbum, setArtistAlbum] = useState([]);
    const [endList, setEndList] = useState();
    const openAlbum = (item) => {
        action({target_type: 'open_album', albumObject: item})
    }
    const shimmerArray=[
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 12, 12, 13, 12, 2, 2,
        2, 2, 2, 2, 2, 2,
    ]
    const albumData = {
        artist_id: artistId,
        offset: artistAlbum?.length || 0,
    };
    const getAlbumsQuery = useQuery(
        ["getAlbumsQuery"],
        () => getArtistsAlbum(albumData),
        {
            onSuccess: (res) => {
                if (artistAlbum) {
                    setArtistAlbum((prevState) => {
                        const updatedState = Array.isArray(prevState) ? [...prevState] : [];
                        return [...updatedState, ...res?.data?.result?.albums];
                    });
                } else {
                    setArtistAlbum(res?.data?.result?.albums);
                }
                setEndList(res?.data?.result?.end);
            },
            refetchOnWindowFocus: false,
        }
    );
    const reFetchData = () => {
        getAlbumsQuery.refetch();
    };
    return (
        <div
            id="album-page"
            dir="rtl"
            class="w-full h-full bg-black px-6 lg-1440:px-r24 overflow-scroll hide-scroll-bar">
            <p class="text-[24px] flex gap-1 lg-[1440px]:gap-r4 mt-6 fa-format-500 text-yellow lg-1440:text-f24 lg-1440:mt-r24 mb-8 lg-1440:mb-r32">
                <span>
                      آلبوم ها
                </span>
            </p>
            {albumData?.offset===0&&getAlbumsQuery.isFetching&& <div className="flex gap-6 lg-1440:gap-r24 flex-wrap pb-4 lg-1440:pb-r16 ">
            {shimmerArray.map((item, index) => (
                <div >
                    <TicketsSkeleton height="11.11vw" width="11.11vw" />
                </div>
            ))}
            </div>}
            <InfiniteScroll
                className={'!overflow-visible'}
                next={reFetchData}
                hasMore={!endList}
                loader={
                    albumData.offset === 0 ? (
                        <></>
                    ) : (
                        <div className="mt-4 flex w-full justify-center">
                            <Loading bgColor={"#F2C94C"}/>
                        </div>
                    )
                }
                scrollableTarget="album-page"
                dataLength={artistAlbum?.length || 0}>
                <div className="flex gap-6 lg-1440:gap-r24 flex-wrap pb-4 lg-1440:pb-r16 ">
                    {artistAlbum.map((item, index) => (
                        <div onClick={() => {
                            openAlbum(item)
                        }}>
                            <AlbumCardItem item={item}/>

                        </div>
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    );
}

export default AlbumList;
