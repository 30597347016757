import React from 'react';
import {
  getSingleAdveItem,
  getTrackDuration,
} from '../../../helper/ReduxSelectorContext';
import CustomLazyLoadImage from '../../common/CustomLazyLoadImage';
import TextMarquee from '../../common/TextMarquee';
import { Slider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSeekTime,
  setIsPlaying,
  setSwipePlayerStatus,
} from '../../../redux/player/PlayMusicSlice';
import { setLanguageDirection } from '../../../helper/LanguageDetection';
import ChevronSVG from '../../svg/common/ChevronSVG';
import ChevronLeft from '../../svg/common/ChevronLeft';
import { useAction } from '../../../hooks/useAction';
import { GetPlayerRef } from '../../../helper/GetPlayerRef';

function MiniAdsBox() {
  let trackDuration = getTrackDuration();
  let seekTime = useSelector(selectSeekTime);
  const singleAdvertiseItem = getSingleAdveItem();
  // const mainPlayer = document.querySelector('#playerDDD video');
  const [getPlayer] = GetPlayerRef();
  const mainPlayer = getPlayer();

  const action = useAction();
  const dispatch = useDispatch();
  const handleOpenSwipePlayer = () => {
    dispatch(setIsPlaying({ isPlay: true }));
    dispatch(setSwipePlayerStatus({ status: true }));
    mainPlayer.play();
  };
  const handleButtonClick = (e, item) => {
    e?.stopPropagation();
    action(item?.btn_action);
  };
  return (
    <div
      onClick={() => handleOpenSwipePlayer()}
      className="w-full fixed flex flex-col max-w-[520px]  h-[48px]  z-20 min-player-position "
      style={{
        background:
          'linear-gradient(270deg, rgb(40, 40, 40) 0%, rgb(48, 48, 48) 100%)',
      }}
    >
      <div className="w-full h-full flex flex-row justify-between items-center">
        <div className="flex flex-row justify-s items-center w-full gap-2">
          <div className="w-[48px] h-[48px] relative">
            <CustomLazyLoadImage image={singleAdvertiseItem?.image} />
          </div>
          <div className="flex flex-row max-w-full h-full justify-center items-center">
            <p className="font-dana font-[400] text-center w-full text-[12px] text-gray_3 line-clamp-1">
              <TextMarquee
                direction={setLanguageDirection(
                  singleAdvertiseItem?.bottom_player_text,
                )}
              >
                {singleAdvertiseItem?.bottom_player_text}
              </TextMarquee>
            </p>
          </div>
        </div>
        <button
          onClick={e => handleButtonClick(e, singleAdvertiseItem)}
          className="w-[45%] min-[450px]:w-[35%] max-[350px]:w-2/3  flex flex-row justify-end items-center gap-2 pr-3"
        >
          <span className="text-primary max-[350px]:text-xs text-base font-[400] font-dana ">
            {singleAdvertiseItem?.btn_text}
          </span>
          <ChevronSVG classList={'w-[10px] h-[10px] fill-primary rotate-180'} />
        </button>
      </div>
      <div className="w-full h-[2px] relative">
        <Slider
          aria-label="time-indicator"
          className="!w-full !h-[2px] !absolute top-0  !m-0 !p-0 "
          value={seekTime}
          min={0}
          // step={0.01}
          max={trackDuration}
          sx={{
            color: '#F2C94C',
            height: 4,
            '& .MuiSlider-thumb': {
              display: 'none',
            },
            '& .MuiSlider-rail': {
              backgroundColor: 'transparent',
              opacity: 1,
            },
          }}
        />
      </div>
    </div>
  );
}

export default React.memo(MiniAdsBox);
