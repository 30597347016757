import React, {forwardRef,Fragment, useImperativeHandle, useState} from 'react';
import {Dialog, Transition} from "@headlessui/react";


import UpdatedPlaylistSvg from "../svg/playList/UpdatedPlaylistSvg.jsx";
import {CardActionArea} from "@mui/material";
import DialogHeader from "../dialogs/DialogHeader.jsx";

function PlaylistUpdateDialog({},ref) {


    const [isOpen, setIsOpen] = useState(false);
    const closeModal = () => setIsOpen(false);

    useImperativeHandle(ref, () => ({
        openModal() {
                setIsOpen(true)
        },

    }));
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[91]"
                onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto bg-black bg-opacity-50">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel
                                dir="rtl"
                                className="w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-secondary p-4  text-left align-middle shadow-xl transition-all">
                                    <DialogHeader header_text={'پلی لیست بروز شده'} onCloseDialog={closeModal} />
                                <div >
                                    <div className="flex flex-col gap-4  mt-8  justify-center">
                                        <div className="flex flex-col gap-2 " >
                                            <p className="text-primary leading-8 text-gray_6 text-[16px] font-medium text-center font-dana  ">
                                                این پلی لیست به روز شده است.
                                            </p>
                                            <p className="text-gray_6 text-[14px] font-medium text-center font-dana leading-8  ">
                                                برای نمایش آهنگ های به روز شده،ترتیب نمایش را به اخیرا افزوده شده تغییر
                                                دهید.
                                            </p>
                                        </div>
                                        <div className='h-[59px] ' style={{aspectRatio:244/59}}>
                                          <UpdatedPlaylistSvg/>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={"my-4 flex items-center justify-center gap- font-dana"}
                                >
                                    <CardActionArea className="!rounded-[100px] !w-[200px]">
                                        <button
                                            onClick={() => closeModal()}
                                            type="button"
                                            className={"text-center inline-flex text-[16px] bg-primary font-semibold w-full justify-center rounded-[100px] px-4 py-3 focus:outline-none"}
                                        >
                                            متوجه شدم
                                        </button>
                                    </CardActionArea>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default forwardRef(PlaylistUpdateDialog) ;
