import React from 'react';
import CssShimmer from '../common/CssShimmer.jsx';

function PlaylistCardsShimmer(props) {
  return (
    <div className="w-full   justify-start px-4 gap-1 flex flex-wrap xl:min-h-[12vw]">

      {Array.from(
        [1, 2, 3, 4].map(() => (
          <div
            className={`z-30  overflow-hidden w-[49%] shadow-collection-shadow  relative rounded-[10px]  aspect-[162/77]`}
          >
            <CssShimmer
              shimStart={"-250%"}
              shimEnd={"250%"}
              reverse={true}
              classNames={"h-full w-full"}
              duration={'0.7s'}/>
          </div>
        ))
      )}
    </div>
  );
}

export default PlaylistCardsShimmer;
