// import { checkSum, initialData } from "../initialize/InitializeSlice";
import { CDN_BASE_URL } from "../../BASE";
import {
  createAdvertiseCdnLink,
  createMusicCdnLink,
} from "../../helper/createMusicCdnLink";
import { getDownloadedAdv, removeIndexedDB } from "../../indexDB/dbQuery";
import { downloadTrack, getAdvertise } from "../../services/ApiClient";
import { setInactiveSession } from "../autherror/AuthErrorSlice";
import {
  setBandialogOpen,
  setBannedDialog,
  setBannedDialogData,
  setIsBanned,
  setIsShow,
  setUpdateDialog,
} from "../dialog/DialogSlice";
import {
  checkSum,
  initialData,
  setDeviceToken,
} from "../initialize/InitializeSlice";
import { login, logout, userData } from "../login/LoginSlice";
import { setAdvertiseData, setEmitGetAdvertise, setWaitForAdv, toggleAdvertise } from "../player/AdvertiseSlice";
import {
  resetPlayer,
  resetRecentlyListen,
  resetWeightCount,
  setIsPlaying,
  setMusic,
  setPlayList,
  setSwipePlayerStatus
} from "../player/PlayMusicSlice";
import { store } from "../Store";
import { removeAllSearchHistoryItem } from "../search/SearchSlice.js";

export const getTokenFromGlobalStore = () => {
  const state = store.getState();
  return state?.user?.token;
};
export const getDeviceTokenFromGlobalStore = () => {
  const state = store.getState();
  // console.log("DSfsfsdfsdfsdf",state?.initialize?.initialData?.device_token);
  // return state?.initialize?.initialData?.device_token;
  return state?.initialize?.deviceToken ? state?.initialize?.deviceToken : "";
};
export const getUserIdFromGlobalStore = () => {
  const state = store.getState();
  return state?.user?.user?.id;
};

export const dispatchUnauthenticatedUser = () => {
  store.dispatch(removeAllSearchHistoryItem());
  store.dispatch(logout());
  store.dispatch(resetPlayer())
  store.dispatch(resetRecentlyListen())
  // store.dispatch(userData({ user: null }));
  // store.dispatch(login({ token: null }));
  store.dispatch(initialData({ initialData: null }));
  store.dispatch(setDeviceToken({ deviceToken: null }));
  store.dispatch(checkSum({ checkSum: null }));
  store.dispatch(resetWeightCount())
  store.dispatch(toggleAdvertise({ advertise: false }));
  localStorage.clear();
  removeIndexedDB()

};

export const dispatchInactiveSession = (inactiveSessionData) => {
  store.dispatch(setInactiveSession({ inactiveSession: inactiveSessionData }));
};

export const dispatchIsPlaying = (isPlay) => {
  store.dispatch(setIsPlaying({ isPlay }));
};
export const dispatchSwipePlayerStatus = (status) => {
  store.dispatch(setSwipePlayerStatus({ status: status }));
};

export const dispatchBannedUser = (data) => {
  store.dispatch(setIsShow({ is_show: data?.is_show }));
  store.dispatch(setBannedDialogData({ Banned_dialogData: data }));
  store.dispatch(setIsBanned({ is_banned: true }));
  store.dispatch(setBandialogOpen({ is_bannedOpen: false }));
};
export const dispatchBannedDialog = (data) => {
  store.dispatch(setBannedDialog({ Banned_dialog: data }));
};


export const dispatchUpdateDialog = () => {
  store.dispatch(setUpdateDialog({ updateDialog: true }))
}
export const toggleHaveAdvertise = (from) => {
  const state = store.getState();
  getDownloadedAdv().then(DownloadedAdvData => {
    // wait for adv when adv from server
    if ((!state?.advertise?.advertiseData || DownloadedAdvData?.length == 0) && from === "SERVER") {
      store.dispatch(setWaitForAdv({ waitForAdv: true }))
      store.dispatch(resetWeightCount())
      store.dispatch(toggleAdvertise({ advertise: true }));
      store.dispatch(setEmitGetAdvertise())
      return
    }
    if (!state?.advertise?.advertiseData || DownloadedAdvData?.length == 0) {
      store.dispatch(resetWeightCount())
      store.dispatch(toggleAdvertise({ advertise: false }));
      store.dispatch(setEmitGetAdvertise())
      return
    }
    store.dispatch(resetWeightCount())
    store.dispatch(toggleAdvertise({ advertise: true }));
    if (state?.advertise?.advertiseData && state?.advertise?.advertiseData[0]) {
      downloadTrack(createAdvertiseCdnLink(state?.advertise?.advertiseData[0]?.ads_track))

    }
  })
};

export const dispatchAdvData = (data) => {
  store.dispatch(setAdvertiseData({ advertiseData: data }))
}
export const getSearchHistoryModels = (data) => {
  const state = store.getState();
  return state?.initialize?.initialData?.search_history_titles
}
export const getImageBaseUrl = () => {
  const state = store.getState();
  try {
    const decodedUrl = atob(state?.initialize?.initialData?.i_b_u)
    return decodedUrl
  } catch (error) {
    console.error("Error decoding base64:", error.message);
    return "";
  }

}