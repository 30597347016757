import React from 'react';
import ShowMessageSkeleton from '../Tickets/ShowMessageSkeleton';
import AdminMessage from './AdminMessage';
import UserMessage from './UserMessage';
import MusicNoteLoading from '../loading/MusicNoteLoading.jsx';
import { useUserData } from '../../helper/ReduxSelectorContext.js';

function MessageBoard({ message, ticket, loading }) {
  const data = useUserData();
  return (
    <div className="flex flex-col hide-scroll-bar px-4 py-4 gap-14 relative">
      <div
        className={`flex flex-col gap-5 w-full ${
          ticket?.show_response_duration_box ? ' mb-48' : ' mb-16'
        } `}
      >
        {loading ? (
          <>
            <div className="fixed flex justify-center items-center w-full h-full bg-secondary  left-0 top-0 z-10">
              <div className="h-[120px]  w-[120px] left-0 top-0 mt-[56px]">
                <MusicNoteLoading />
              </div>
            </div>
          </>
        ) : (
          message?.map(item =>
            item?.admin == null ? (
              <UserMessage messageDetail={item} isPremium={data?.is_premium} />
            ) : (
              <AdminMessage messageDetail={item} />
            ),
          )
        )}
      </div>
    </div>
  );
}

export default MessageBoard;
