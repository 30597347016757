import React, {Fragment, useEffect, useState} from 'react';
import {Transition} from "@headlessui/react";
import ChevronLeft from "../svg/common/ChevronLeft";
import {useBottomNavigation} from "../BottomNavigation/useBottomNavigation.js";
import {addToPlaylistData, getPlayerMusic} from "../../helper/ReduxSelectorContext.js";

function ScrollTopButton({id, player = false, show = true }) {
    const [showButton, setShowButton] = useState(false)
    let playedTrackData = getPlayerMusic();
    const [scrollButtonBottom, setScrollButtonBottom] = useState(0)
    const page = document.getElementById(id);
    const addToPlaylistDataState = addToPlaylistData()
    const computedStyle = getComputedStyle(document.querySelector('body'));
    const safeAreaInsetBottom = computedStyle.getPropertyValue('--safe-area-inset-bottom');
    const scrollToTop = () => {
        page.scrollTo({top: 0, behavior: 'smooth'});
    };
    const [InvisibleBottomSheet] = useBottomNavigation();
    const calculateScrollTopBottom = () => {
        let bottom = 8
        bottom += parseInt(safeAreaInsetBottom)
        if (player) {
            if (addToPlaylistDataState?.playerListSelectedItems?.length > 0) {
                bottom += 50
            }
            setScrollButtonBottom(bottom)
            return
        }
        if (!InvisibleBottomSheet()) {
            bottom += 66
        }
        if (playedTrackData && !InvisibleBottomSheet() ) {
            bottom += 48
        }
        if (addToPlaylistDataState?.listSelectItems?.length > 0) {
            bottom += 50
        }
        setScrollButtonBottom(bottom)
    }
    useEffect(() => {
        const page = document.getElementById(id);
        const toggleScrollButton = () => {
            const top = Math.abs(page.scrollTop)
            if (top > 1000) {
                setShowButton(true)
            } else {
                setShowButton(false)
            }
        };

        page?.addEventListener('scroll', toggleScrollButton)

        return () => {
            setShowButton(false)
            document.removeEventListener('scroll', toggleScrollButton)
        }
    }, [])
    useEffect(() => {
        calculateScrollTopBottom()
    }, [playedTrackData, InvisibleBottomSheet(), player,addToPlaylistDataState?.listSelectItems?.length,addToPlaylistDataState?.playerListSelectedItems?.length]);

    return (
        <Transition
            as={Fragment}
            show={showButton && show}
            enter="transform transition duration-[200ms]"
            enterFrom="opacity-0   "
            enterTo="opacity-100 "
            leave="transform duration-200 transition ease-in-out "
            leaveFrom="opacity-100  "
            leaveTo="opacity-0 "
        >

            <div onClick={scrollToTop}
                 style={{bottom: scrollButtonBottom + 'px'}}
                 className='absolute cursor-pointer flex justify-center items-center left-4 z-[50]  transition-all    rounded-full !w-[46px]   !h-[46px]    bg-scroll-top-button-background'>
                <div className='rotate-[90deg]'>
                    <ChevronLeft width='24px' height='24px'/>
                </div>
            </div>
        </Transition>
    );
}

export default ScrollTopButton;
