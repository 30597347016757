import { useDispatch } from "react-redux";
import { downloadData, getAdvLimitationData, getAdvertiseData, getPlayerMusic, getUserToken, getWeightCount, useUserData } from "../helper/ReduxSelectorContext";
import { resetWeightCount, setWeightCount } from "../redux/player/PlayMusicSlice";
import { checkAdvertise, getAdvertise } from "../services/ApiClient";
import { toggleHaveAdvertise } from "../redux/globalstore/GlobalStore";
import { useDownload } from "./useDownload";
import { useMutation } from "@tanstack/react-query";
import useAxios from "../services/ApiService";
import { createMusicCdnLink } from "../helper/createMusicCdnLink";
import { setAdvertiseStatusCode, setEmitGetAdvertise } from "../redux/player/AdvertiseSlice";

let controller = new AbortController();
export function useAdvertise() {
    const dispatch = useDispatch()
    const playedMusic = getPlayerMusic();
    const advLimitationData = getAdvLimitationData()
    const weighCount = getWeightCount()
    const { downloadedFiles } = downloadData();
    const userData = useUserData();
    const userToken = getUserToken();
    let advertiseData = getAdvertiseData();

    // const checkAdvertiseMutation = useMutation(({ signal }) => checkAdvertise(createMusicCdnLink(playedMusic, userData, userToken), signal))
    const checkAdvertiseMutation = useMutation(({ signal }) => checkAdvertise(playedMusic?.cdn_url, signal), {
        onError: (err) => {
            if (err?.response && (err.response.status >= 480 && err.response.status <= 489))
                dispatch(setAdvertiseStatusCode({ advertise_status: err?.response?.status }))

        }
    })

    const AdvCountWeight = () => {
        if (playedMusic?.is_demo) {

            if (advLimitationData?.limitations?.demo_track_ads?.enable) {
                dispatch(setWeightCount({ trackCount: advLimitationData?.limitations?.demo_track_ads?.weight }))
            }

        } else if (doesExist(downloadedFiles, playedMusic?.id)) {
            if (advLimitationData?.limitations?.offline_track_ads?.enable) {
                dispatch(setWeightCount({ trackCount: advLimitationData?.limitations?.offline_track_ads?.weight }))
            }

        } else if (playedMusic?.is_demo == false) {
            if (advLimitationData?.limitations?.track_ads?.enable) {
                dispatch(setWeightCount({ trackCount: advLimitationData?.limitations?.track_ads?.weight }))
            }

        }
    }
    const checkWeighCount = () => {

        if (Number(weighCount) >= advLimitationData?.ads_limit_counts - 1) {
            toggleHaveAdvertise()
            dispatch(resetWeightCount())
            return true
        } else {
            // get adv when user don't have adv
            if (Number(weighCount) >= advLimitationData?.ads_limit_counts_to_call_request && advertiseData == null) {

                dispatch(setEmitGetAdvertise())

            }
        }
    }

    const checkTrackAdvertises = () => {
        controller.abort()
        controller = new AbortController()
        checkAdvertiseMutation.mutate({ signal: controller.signal })

    }

    return [AdvCountWeight, checkWeighCount, checkTrackAdvertises]

}

const doesExist = (array, id) => {
    return array.some((trackItem) => trackItem?.id === id);
};