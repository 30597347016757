import React from "react";
import HomeArtistCard from "../home/HomeArtistCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { dispatchSwipePlayerStatus } from "../../redux/globalstore/GlobalStore.js";
import { getLyricFullScreen } from "../../helper/ReduxSelectorContext.js";
import { toggleLyricFullScreen } from "../../redux/player/PlayMusicSlice.js";
import { useDispatch } from "react-redux";

function GoToArtistBottomSheetContent({
  artists,
  closeParentBottomSheet,
  closeArtistBottomSheet,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLyricFullScreen = getLyricFullScreen();
  const location = useLocation();
  const param = useParams();
  const openArtist = (event, item) => {
    event?.stopPropagation();
    closeArtistBottomSheet();
    closeParentBottomSheet();
    dispatchSwipePlayerStatus(false);
    if (isLyricFullScreen) {
      dispatch(toggleLyricFullScreen());
    }

    if (
      !location?.pathname?.split("/").find((x) => x === "artist-profile") ||
      param?.id != item.id
    ) {
      navigate(`/artist-profile/${item?.id}`, {
        state: { artistObject: item },
      });
    } else {
      document
        .getElementById("artist-page")
        .scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return (
    <div
      dir="rtl"
      className=" gap-x-5 gap-y-1 p-4 grid  items-start max-[360px]:grid-cols-2 grid-cols-3"
    >
      {artists?.map((item, index) => (
        <div onClick={(event) => openArtist(event, item)}>
          <HomeArtistCard item={item} />
        </div>
      ))}
    </div>
  );
}

export default GoToArtistBottomSheetContent;
