import React from "react";
import HomeSlider from "./HomeSlider";
import HomeCustomCollection from "./HomeSuggestion";

function HomeSectionOne(item) {
  return (
    <>
      <div class="w-full flex flex-row gap-4  mt-[24px]">
        <div class="w-full">
          <HomeSlider heightRelation={item?.item?.main_banner_meta_data?.height_relation}
                      metaData={item?.item?.main_banner_meta_data}
                      data={item?.item?.main_banner_items?.[0]?.banners}
                      slideItemContainerClass={'w-[90%]'}
                      slideItemContentClass={'rounded-[20px]'}
                      slideItemActionAreaClass={'!rounded-[20px]'}
          />
        </div>
      </div>
    </>
  );
}

export default React.memo(HomeSectionOne);
