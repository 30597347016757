import React from 'react';
import {getLimitations, globalData} from "../helper/ReduxSelectorContext.js";

export const  useLimitation=(props)=> {
    const limitations = getLimitations()
    const {userCollections} = globalData();
    const hasPlaylistCountLimit=()=>{
        if (!limitations?.create_user_playlist?.enable) {
            return false;
        }
        if (Number(limitations?.create_user_playlist?.limit) <= Number(userCollections?.length)) {
            return true;
        }
        return false;
    }
    const hasPlayListTrackCountLimit=(playlist)=>{
        if (!limitations?.user_playlist_tracks_limit?.enable) {
            return false;
        }
        if (Number(limitations?.user_playlist_tracks_limit?.limit) <= Number(playlist?.tracks_count)) {
            return true;
        }
        return false;
    }


    return{hasPlaylistCountLimit,hasPlayListTrackCountLimit}
}

