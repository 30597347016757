import React from 'react';
import SongCardImageSection from './SongCardImageSection.jsx';
import SongCardTitleSection from './SongCardTitleSection.jsx';

function SongCardInfoSection({ number,item,index,ShowItemIsPlaying }) {

  return (

      <div
        className={`flex items-center relative   ${
          item?.is_demo ? "  w-[30%] max-w-[30%]" : " w-[40%] max-w-[40%]"
        } `}
      >
      {number && (
        <div className="flex items-center  justify-center  min-w-[50px]">
          <p className="text-xs font-dana font-medium text-gray_4 ">
            {index + 1}
          </p>
        </div>
      )}
      <div className="flex items-center gap-[6px]  w-full">
        <SongCardImageSection item={item} ShowItemIsPlaying={ShowItemIsPlaying}/>
        <SongCardTitleSection item={item} ShowItemIsPlaying={ShowItemIsPlaying}/>
      </div>
      </div>

  );
}

export default React.memo(SongCardInfoSection);
