import React from 'react';
import RenderCollectionsPage from "../../components/collections/RenderCollectionsPage.jsx";
import { useSearchParams } from 'react-router-dom';
import { getCategoryCollections, getGeneralCollections, getSectionCollections } from '../../services/ApiClient.js';
import { useLocation } from 'react-router';
import { useAction } from '../../hooks/useAction.js';

function GeneralCollectionPage(props) {

    const location = useLocation()
    const pageData = location?.state?.actionData
    const action = useAction()
    let itemData = location?.state?.categoryData;
    const subtitle = pageData?.general_collections_page_data?.sub_title;
    const title = pageData?.general_collections_page_data?.title;
    const id = pageData?.general_collections_page_data?.group_id;
    const image = pageData?.general_collections_page_data?.background_image;


    const makeRequest = (data) => {
            return getGeneralCollections(data);
    };
    const requestData = () => {
            return {
                requestData: {
                    group_id: id,
                },
                requestKey: `getGeneralCollections${id}`,
            };
    };
    const handleSelectItem = (collection) => {
        action({target_type:'collection', view_type: "general_collections", collection});
    };
    return (
        <RenderCollectionsPage pageParams={0} openCollection={handleSelectItem} apiDataKey={'general_collections'} requestData={requestData().requestData} requestKey={requestData().requestKey} subtitle={subtitle} pageId={'general-collection-page'} apiFunction={makeRequest} pageTitleText={title} title={title} image={image} />
    );
}

export default GeneralCollectionPage;
