import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import NavBarContainer from "../header/NavBarContainer";
import { useScrollPosition } from '../../hooks/useScrollPosition.js';

function Container({ children, scroll = true, header = null, style ,shadow}) {


  return (
    <div
      id='page-header'
      className={`w-full h-full flex flex-col overflow-scroll  ${style} ${
        scroll ? "overflow-scroll" : ""
      }  ${header ? "pt-[56px]" : ""}`}
    >
      {header && <NavBarContainer shadow={shadow}>{header}</NavBarContainer>}

      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  hasScroll: PropTypes.bool,
};

export default Container;
