import React from 'react';

function TriangleSvg({fill}) {
    return (
        <svg
            className="w-full h-full"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 30 27"
        >
            <path
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 10v5.333m0 5.334h.012M12.72 3.147L1.427 22a2.667 2.667 0 002.28 4h22.586a2.667 2.667 0 002.28-4L17.28 3.147a2.666 2.666 0 00-4.56 0z"
            ></path>
        </svg>
    );
}

export default TriangleSvg;
