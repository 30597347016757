import React, { useState } from 'react';
import Container from '../../components/PageParentContainer/Container.jsx';
import AddToPlaylistModeButton from '../../components/playlist/AddToPlaylistModeButton.jsx';
import CollectionCategoryHeader from '../../components/playlist/CollectionCategoryHeader.jsx';
import AbsoluteBackground from '../../components/common/AbsoluteBackground.jsx';
import ScrollTopButton from '../../components/common/ScrollTopButton.jsx';
import SongsDetailCardShimmer from '../../components/Tickets/SongsDetailCardShimmer.jsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import SongListLoading from '../../components/loading/SongListLoading.jsx';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  addToPlaylistData,
  getIsPlaying,
  getPlayedPlaylist,
} from '../../helper/ReduxSelectorContext.js';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  getCollectionData,
  getCollectionTracks,
} from '../../services/ApiClient.js';
import CompleteSongList from '../../components/ArtistProfile/CompleteSongList.jsx';
import { simplePlayListSortTypes } from '../../consts/MenuItemsConsts.js';
import SortSvg from '../../components/svg/playList/SortSvg.jsx';
import { ACTIONS } from '../../consts/ActionsConstants.js';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';
import ClickReaction from '../../helper/ClickReaction.jsx';
import PlaylistPauseButton from '../../components/svg/playList/PlaylistPauseButton.jsx';
import PlaylistPlaysButton from '../../components/svg/playList/PlaylistPlaysButton.jsx';
import { mainToast } from '../../helper/ToastsEmitter.jsx';
import { PlayListType } from '../../consts/PlayListType.js';
import {
  setIsPlaying,
  setPlayedPlaylist,
  setPlayerPaginationData,
  setPlayList,
} from '../../redux/player/PlayMusicSlice.js';
import { usePlayedTrack } from '../../hooks/usePlayedTrack.js';
import { useDispatch } from 'react-redux';
import { setSeenCollection } from '../../redux/RecentlyHeard/RecentlyHeardSlice.js';

function CollectionTrackList({ showIndex = false }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [collectionData, setCollectionData] = useState('');
  const type = searchParams.get('type');
  const location = useLocation();
  const collection = location?.state?.collection;
  const addToPlaylistDataState = addToPlaylistData();
  let isPlaying = getIsPlaying();
  const currentPlaylist = getPlayedPlaylist();
  const [sortType, setSortType] = useState(simplePlayListSortTypes[0]?.type);
  const dispatch = useDispatch();
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  const getCollectionDataQuery = useQuery(
    ['getCollectionData' + collection?.id],
    () => getCollectionData(collection?.id),
    {
      onSuccess: res => {
        setCollectionData(res?.data?.result?.collection);
      },
      refetchOnWindowFocus: false,
      enabled: ACTIONS.SIMPLE_PLAY_LIST_VIEW_TYPE === type,
    },
  );
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['getCollectionTrack' + collection?.id + sortType],
      ({ pageParam = 0 }) =>
        getCollectionTracks({
          collection_id: collection?.id,
          sort: sortType,
          offset: pageParam,
        }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const allData = allPages?.flatMap(page => page?.data?.result?.tracks);
          return lastPage?.data?.result?.end ? false : allData?.length;
        },
        refetchOnWindowFocus: false,
      },
    );
  const tracks = data?.pages?.flatMap(page => page?.data?.result?.tracks);
  const reFetchData = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const handlePlayPlaylist = play => {
    if (tracks?.length === 0 || !tracks) {
      mainToast('.پلی لیست خالی است');
      return;
    }
    if (currentPlaylist?.id != collection?.id) {
      setPlayerTrack(tracks[0], tracks, PlayListType.PLAYLIST);
      dispatch(setPlayedPlaylist({ playlist: collection }));
    }
    dispatch(setPlayList({ playList: tracks }));
    dispatch(
      setPlayerPaginationData({
        playerPaginationData: {
          url: '/getCollectionTracks',
          payload: {
            collection_id: collection?.id,
            sort: sortType,
          },
          end: !hasNextPage,
        },
      }),
    );
    dispatch(setIsPlaying({ isPlay: play }));
  };
  const changeSort = type => {
    setSortType(type);
  };
  return (
    <Container style="bg-primary_dark !overflow-hidden">
      {type === ACTIONS.TRACK_LIST_VIEW_TYPE && (
        <AbsoluteBackground image={collection?.image} animation={true} />
      )}
      <AddToPlaylistModeButton
        EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}
      />
      <CollectionCategoryHeader
        title={collection?.title}
        hasSearch={false}
        backgroundId={`${
          ACTIONS.SIMPLE_PLAY_LIST_VIEW_TYPE === type
            ? 'playlist-songs-background'
            : 'absolute-background'
        }`}
        pageId="collection-page"
      />

      <ScrollTopButton id={'collection-page'} />
      <div
        id="collection-page"
        className=" relative overflow-scroll h-full hide-scroll-bar z-10 "
        dir="rtl"
      >
        {type === ACTIONS.SIMPLE_PLAY_LIST_VIEW_TYPE && (
          <div
            id="playlist-songs-background"
            className=" w-full aspect-square relative  overflow-hidden  "
          >
            <CustomLazyLoadImage
              imageClass={`object-cover block  `}
              image={
                collection?.image_large
                  ? collection?.image_large
                  : collection?.image
              }
            />
            <div className="absolute  top-0 right-0 left-0 bottom-0 bg-playlist-song-overlay   h-[100.15%] "></div>
            <div className="bottom-[20%] absolute right-[50%] translate-x-[50%]">
              <ClickReaction>
                <div>
                  {currentPlaylist?.id === collection?.id && isPlaying ? (
                    <div onClick={() => handlePlayPlaylist(false)}>
                      <PlaylistPauseButton ratio="!h-[60px]" />
                    </div>
                  ) : (
                    <div onClick={() => handlePlayPlaylist(true)}>
                      <PlaylistPlaysButton ratio="!h-[60px]" />
                    </div>
                  )}
                </div>
              </ClickReaction>
            </div>
          </div>
        )}
        <div
          className={`h-full  w-full mb-10  ${
            type === ACTIONS.TRACK_LIST_VIEW_TYPE && 'pt-[208px]'
          }   `}
        >
          {type === ACTIONS.TRACK_LIST_VIEW_TYPE && (
            <p className="text-center !z-50 absolute font-kalameh font-medium text-lg text-white top-[100px] w-full flex justify-center items-center mx-auto">
              {collection?.title}
            </p>
          )}

          <div
            className={`flex gap-2 flex-col mt-2 p-4 ${
              type === ACTIONS.SIMPLE_PLAY_LIST_VIEW_TYPE && 'mt-[-75px]'
            }`}
          >
            {isLoading ? (
              <div className={'flex flex-col gap-3 mt-2'}>
                {Array.from(
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
                    <SongsDetailCardShimmer />
                  )),
                )}
              </div>
            ) : (
              <InfiniteScroll
                className={`!overflow-hidden ${
                  isFetchingNextPage ? '' : ' pb-28'
                } `}
                scrollableTarget="collection-page"
                dataLength={tracks?.length}
                next={reFetchData}
                hasMore={hasNextPage}
                loader={
                  <div className="w-full grid justify-center items-center mt-24">
                    <SongListLoading />
                  </div>
                }
              >
                <div className="relative">
                  <CompleteSongList
                    scrollId={'collection-page'}
                    className="!overflow-visible"
                    sortLimit={false}
                    list={tracks}
                    trackCount={collectionData?.tracks_count}
                    hasPlayButton={false}
                    pageId={collection?.id}
                    sortTypes={simplePlayListSortTypes}
                    apiUrl="/getCollectionTracks"
                    onChangeSort={changeSort}
                    showSort={type === ACTIONS.SIMPLE_PLAY_LIST_VIEW_TYPE}
                    userPlaylist={type === ACTIONS.SIMPLE_PLAY_LIST_VIEW_TYPE}
                    sortButton={activeSortText => (
                      <div className="flex items-center gap-1">
                        <div className="flex gap-2   items-center">
                          <div className="flex w-[22px] h-[16px]  ">
                            <SortSvg />
                          </div>
                          <p className="text-primary font-dana text-[14px] font-medium">
                            ترتیب نمایش:
                          </p>
                        </div>
                        <p className="text-gray_3 font-dana text-[12px] font-medium">
                          {activeSortText}
                        </p>
                      </div>
                    )}
                    requestPayload={{
                      collection_id: collection?.id,
                      sort: sortType,
                    }}
                    endOfPagination={!hasNextPage}
                    loading={isLoading && !tracks}
                    collectionObject={collection}
                  />
                </div>
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default CollectionTrackList;
