import { useDispatch } from "react-redux";
import { deleteConvertItem, insertDownloadedFile } from "../indexDB/dbQuery";
import { addToDownloadedList, setConvertDownloadedFileStatus } from "../redux/download/downloadSlice";
import { downloadData } from "../helper/ReduxSelectorContext";
import { oldPwaDB } from "../indexDB/DB";

export const useConvertDownload = () => {
    const dispatch = useDispatch();
    const { downloadedFiles, convertDownloadedFile } = downloadData();

    const addItemToDownloadList = async (downloadedItem) => {
        const newitem = { ...downloadedItem, isDownloaded: true };
        const existItem = downloadedFiles.some((item) => item?.id === newitem?.id);
        if (!existItem) {
            dispatch(addToDownloadedList({ newItem: newitem }));
        }
    };
    const addItemBlobToDB = async (id, blob) => {
        // insertDownloadedFile(queueList[0]?.id, blob)
        console.log("befor Insert Item");
        console.log("downloaded ID", id);
        console.log("downloaded blob", blob);
        if (blob instanceof ArrayBuffer) {
            console.log("if instanceof ArrayBuffer blob", blob);
            blob = new Blob([blob], { type: 'audio/mp3' })
            console.log("if coverted to blob", blob);

        }
        await insertDownloadedFile(id, blob);
        console.log("after Insert Item");
    };

    const readDataFromDb = async () => {
        const items = await oldPwaDB.dlTracks?.toArray()
        const sortedItems = items.sort(
            (a, b) => a.item.dateAdded - b.item.dateAdded
        );
        console.log("got To insert Item", items);
        insetItemToDb(sortedItems);


    };

    const insetItemToDb = async (items) => {
        for (let index = 0; index < items.length; index++) {
            const track = items[index];
            const newTrack = { ...track?.item, image: track?.item?.image, image_large: track?.item?.image }
            await addItemBlobToDB(track?.id, track?.file);
            await addItemToDownloadList(newTrack);
            await deleteConvertItem(track?.id)

        }
        convertDowloadSuccess()
    };

    const convertDowloadSuccess = () => {
        dispatch(setConvertDownloadedFileStatus({ status: true }))

    }
    const startConverDownloads = () => {
        console.log("canConvertDownload", convertDownloadedFile);
        if (!convertDownloadedFile)
            readDataFromDb()
        else
            oldPwaDB.dlTracks.clear()
    }

    return [startConverDownloads]
}