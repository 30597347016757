import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setDisableDownloadDialogStatus } from '../../redux/dialog/DialogSlice';
import DisableDownloadSvg from '../svg/common/DisableDownloadSvg';
import { memo } from 'react';

function DisableDownloadButton({ iconParentStyle, downloadIconStyle, fill }) {
  const dispatch = useDispatch();

  const handleOpenDialog = useCallback(e => {
    e.stopPropagation();
    dispatch(setDisableDownloadDialogStatus({ disableDownloadStatus: true }));
  }, []);

  return (
    <div className={iconParentStyle} onClick={handleOpenDialog}>
      <div className={downloadIconStyle}>
        <DisableDownloadSvg
          className="w-full h-full opacity-50"
          lineColor={fill}
          fill={fill}
        />
      </div>
    </div>
  );
}

export default memo(DisableDownloadButton);
