import React from 'react';

function MinusIconSvg() {
    return (
        <svg
            className='w-full h-full '
            viewBox="0 0 16 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 1H15"
                stroke="#F2C94C"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default MinusIconSvg;
