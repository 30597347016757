import React from 'react';

function HillSvg({className,fill='#171717'}) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="92"
            height="20"
            fill="none"
            viewBox="0 0 92 20"
        >
            <path
                fill={fill}
                d="M91.042 19.242H0c10.616-.177 20.849-4.56 28.366-12.077 9.52-9.52 25.112-9.59 34.55.01a40.383 40.383 0 0028.126 12.067z"
            ></path>
        </svg>
    );
}

export default HillSvg;
