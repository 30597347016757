export const convertTime=(time)=>{
    let minus = Math.floor(time / 60);
    if (minus < 10) {
        minus = '0' + String(minus);
    }
    let secs = Math.floor(time % 60);
    if (secs < 10) {
        secs = '0' + String(secs);
    }
    return minus + ':' + secs;
}

export const convertTimeForTrack=(time)=>{
    let minus = Math.floor(time / 60);
    if (minus < 10) {
        minus = '' + String(minus);
    }
    let secs = Math.floor(time % 60);
    if (secs < 10) {
        secs = '0' + String(secs);
    }
    return minus + ':' + secs;
}

