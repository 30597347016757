import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getAlbumTracks } from '../../services/ApiClient.js';
import { useLocation } from 'react-router-dom';

import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';
import { useDispatch } from 'react-redux';
import { usePlayedTrack } from '../../hooks/usePlayedTrack';
import { PlayListType } from '../../consts/PlayListType';
import {
  addToPlaylistData,
  getIsPlaying,
  getPlayedPlaylist,
} from '../../helper/ReduxSelectorContext';
import {
  setIsPlaying,
  setPlayedPlaylist,
  setPlayerPaginationData,
  setPlayList,
} from '../../redux/player/PlayMusicSlice';
import ClickReaction from '../../helper/ClickReaction';
import SongDetailCard from '../../components/playlist/SongDetailCard.jsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import Container from '../../components/PageParentContainer/Container';
import CollectionCategoryHeader from '../../components/playlist/CollectionCategoryHeader';
import ScrollTopButton from '../../components/common/ScrollTopButton';
import { Virtuoso } from 'react-virtuoso';
import SongListLoading from '../../components/loading/SongListLoading';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import SongsDetailCardShimmer from '../../components/Tickets/SongsDetailCardShimmer.jsx';
import AddToPlaylistModeButton from '../../components/playlist/AddToPlaylistModeButton.jsx';
import { disableListSelectMode } from '../../redux/adToPlaylist/AddToPlaylistSlice.js';
import PlayIconType2Svg from '../../components/svg/common/PlayIconType2Svg';
import TextMarquee from '../../components/common/TextMarquee';
import GoToArtistBottomSheet from '../../components/player/GoToArtistBottomSheet';
import PauseIconType2 from '../../components/common/PauseIconType2';
import { textFontBasedLanguage } from '../../helper/TextFontBasedLanguage.js';

function AlbumTracks() {
  const location = useLocation();
  const [hasLoaded, setHasLoaded] = useState(false);
  const albumObject = location.state?.albumObject;
  const addToPlaylistDataState = addToPlaylistData();
  const dispatch = useDispatch();
  const currentPlaylist = getPlayedPlaylist();
  let isPlaying = getIsPlaying();

  const goToArtistBottomSheetRef = useRef();

  const [currentTrack, setPlayerTrack] = usePlayedTrack();

  const getAlbumTracksQuery = useInfiniteQuery(
    [`getAlbumTracksQuery${albumObject?.id}`],
    ({ pageParam = 1 }) =>
      getAlbumTracks({
        album_id: albumObject?.id,
        offset: pageParam - 1,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.result?.end) return null;
        const allData = allPages?.flatMap(page => page.data.result.tracks);
        return allData?.length;
      },
      refetchOnWindowFocus: false,
    },
  );
  const albumTrackList = useMemo(
    () =>
      getAlbumTracksQuery?.data?.pages?.flatMap(
        page => page.data.result.tracks,
      ),
    [getAlbumTracksQuery?.data],
  );

  const handleLoadMore = () => {
    getAlbumTracksQuery.fetchNextPage();
  };

  const handlePlayPlaylist = play => {
    if (currentPlaylist?.id != albumObject?.id) {
      setPlayerTrack(albumTrackList[0], albumTrackList, PlayListType.PLAYLIST);
      dispatch(setPlayedPlaylist({ playlist: albumObject }));
    }
    dispatch(setPlayList({ playList: albumTrackList }));
    dispatch(
      setPlayerPaginationData({
        playerPaginationData: {
          url: '/getAlbumTracks',
          payload: { album_id: albumObject?.id },
          end: !getAlbumTracksQuery?.hasNextPage,
        },
      }),
    );
    dispatch(setIsPlaying({ isPlay: play }));
  };

  const openGoToArtistBottomSheet = () => {
    goToArtistBottomSheetRef?.current?.openGoToArtistBottomSheet();
  };
  useEffect(() => {
    setHasLoaded(true);
    return () => {
      dispatch(disableListSelectMode());
    };
  }, []);

  return (
    <>
      <GoToArtistBottomSheet
        ref={goToArtistBottomSheetRef}
        data={albumObject}
      />
      <Container style="bg-primary_dark !overflow-hidden">
        <AddToPlaylistModeButton
          EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}
        />
        <CollectionCategoryHeader
          hasSearch={false}
          title={albumObject?.title}
          backgroundId="album-tracks-background"
          pageId="album-tracks-container"
        />
        <div
          dir="rtl"
          id="album-tracks-container"
          className="w-full h-full overflow-scroll hide-scroll-bar "
        >
          <div
            id="album-tracks-background"
            className=" w-full aspect-square relative  overflow-hidden flex justify-center items-center  xl:min-h-[30vw] "
          >
            <CustomLazyLoadImage
              imageClass={`object-cover block  `}
              image={albumObject?.image}
            />
            <div className="absolute  top-0 right-0 left-0 bottom-0 bg-album-poster-gradient  h-full "></div>
            <div
              id="title"
              className="flex flex-col justify-between items-center relative w-full h-[68px] px-2"
            >
              <p className={` font-semibold text-[26px] text-white  ${textFontBasedLanguage(albumObject?.title,'font-kalameh','en-kalameh')}  `}>
                {albumObject?.title}
              </p>
              <div
                onClick={openGoToArtistBottomSheet}
                dir="ltr"
                className="w-full text-center"
              >
                <TextMarquee dir="ltr" noMarqueeParentStyle="justify-center">
                  <p className="font-dana font-medium text-base text-primary leading-6 mt-1 px-4  ">
                    {albumObject?.artists.map((artist, index) => {
                      return (
                        <span
                          dir={setLanguageDirection(albumObject?.title)}
                          key={index}
                        >
                          {setLanguageDirection(albumObject?.title) === 'ltr' &&
                            index !== albumObject?.artists?.length - 1 &&
                            ', '}
                          {artist?.name}
                          {setLanguageDirection(albumObject?.title) === 'rtl' &&
                            index !== albumObject?.artists?.length - 1 &&
                            '، '}
                        </span>
                      );
                    })}
                  </p>
                </TextMarquee>
              </div>
            </div>
          </div>
          <ScrollTopButton id={'album-tracks-container'} />
          <div className="relative ">
            <div className="flex items-center justify-between absolute -top-6 w-full px-[9px]">
              <ClickReaction>
                <div>
                  {currentPlaylist?.id === albumObject?.id && isPlaying ? (
                    <div
                      className="w-[38px] h-[38px]"
                      onClick={() => handlePlayPlaylist(false)}
                    >
                      <PauseIconType2 />
                    </div>
                  ) : (
                    <div
                      className="w-[38px] h-[38px]"
                      onClick={() => handlePlayPlaylist(true)}
                    >
                      <PlayIconType2Svg />
                    </div>
                  )}
                </div>
              </ClickReaction>

              <p className="text-xs text-gray_4 font-dana font-medium leading-6 ">
                {albumObject?.tracks_count} آهنگ
              </p>
            </div>
            <div className="absolute w-full mt-10 px-2 ">
              {getAlbumTracksQuery?.isLoading ? (
                <>
                  <div className="flex flex-col w-full gap-2">
                    {Array.from(
                      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => (
                        <SongsDetailCardShimmer />
                      )),
                    )}
                  </div>
                </>
              ) : (
                hasLoaded && (
                  <InfiniteScroll
                    className="!overflow-hidden"
                    scrollableTarget="album-tracks-container"
                    dataLength={getAlbumTracksQuery?.data?.pages?.length}
                    next={handleLoadMore}
                    hasMore={getAlbumTracksQuery?.hasNextPage}
                    loader={
                      <div className="w-full grid justify-center items-center mt-4">
                        <SongListLoading />
                      </div>
                    }
                  >
                    <Virtuoso
                      customScrollParent={document.getElementById(
                        'album-tracks-container',
                      )}
                      data={albumTrackList}
                      className="!mb-16"
                      itemContent={(index, item) => (
                        <div
                          key={'SongDetailCard' + index}
                          className="my-[6px]"
                        >
                          <SongDetailCard
                            number={false}
                            data={item}
                            playList={albumTrackList}
                            collectionObject={albumObject}
                            apiUrl={'/getAlbumTracks'}
                            requestPayload={{ album_id: albumObject?.id }}
                            endOfPagination={!getAlbumTracksQuery?.hasNextPage}
                          />
                        </div>
                      )}
                    />
                  </InfiniteScroll>
                )
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default AlbumTracks;
