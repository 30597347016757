import React from 'react'
import MusicNoteLoading from './MusicNoteLoading'

function LoaderContainer({children,loading=false ,loadingComponent=<MusicNoteLoading  /> }) {
  return (
    <>
    {loading&&<div className='z-50 h-full w-full bg-black_2 bg-opacity-80 absolute flex justify-center items-center top-0 '>
      <div className='w-32 h-32'>
        {
            loadingComponent
        }

      </div>
    </div>}
        {
            children
        }

    </>
  )
}

export default LoaderContainer