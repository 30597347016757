import React from "react";

function CrownSvg({custom,classList='',ratio='h-[32px]  min-[1440px]:h-[1.7582vw]'}) {
  return (
    <svg
      viewBox="0 0 52 52"
      className={`${classList} ${custom?'h-full':ratio}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="52" height="52" rx="7" fill="#F2C94C" fillOpacity="0.2" />
      <path
        d="M35.4181 30.7793H16.5804C16.1557 30.7793 15.784 30.4937 15.6745 30.0833L13.0434 20.2165C12.9484 19.8602 13.0711 19.4813 13.357 19.2483C13.6428 19.0153 14.0388 18.9716 14.3685 19.1364L21.5633 22.7338L25.1607 15.539C25.3195 15.2214 25.6442 15.0208 25.9993 15.0208C26.3544 15.0208 26.679 15.2214 26.8378 15.539L30.4352 22.7338L37.63 19.1364C37.9599 18.9714 38.3558 19.0153 38.6416 19.2483C38.9275 19.4813 39.0502 19.8602 38.9552 20.2165L36.324 30.0833C36.2145 30.4937 35.8428 30.7793 35.4181 30.7793Z"
        fill="#FFD961"
      />
      <path
        d="M36.3247 30.0833L38.9558 20.2164C39.0509 19.8601 38.9282 19.4812 38.6423 19.2482C38.3565 19.0152 37.9606 18.9714 37.6307 19.1363L30.4359 22.7338L26.8385 15.539C26.6797 15.2214 26.3551 15.0208 26 15.0208V30.7792H35.4188C35.8436 30.7793 36.2153 30.4937 36.3247 30.0833Z"
        fill="#FFB300"
      />
      <path
        d="M34.0238 34.875H17.9762C17.5519 34.875 17.1807 34.7144 17.0714 34.4837L15.6875 31.5625H36.3125L34.9286 34.4837C34.8193 34.7144 34.4481 34.875 34.0238 34.875Z"
        fill="#FFB300"
      />
      <path
        d="M34.9286 34.4837L36.3125 31.5625H26V34.875H34.0239C34.4481 34.875 34.8193 34.7144 34.9286 34.4837Z"
        fill="#FF8D00"
      />
    </svg>
  );
}

export default CrownSvg;
