import React from "react";

function Icon() {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="none"
          viewBox="0 0 50 50"
      >
        <g
            stroke="#F2C94C"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            clipPath="url(#clip0_120_10)"
        >
          <path d="M43.919 25v11.824M6.08 25v23.649h37.838V45.27M48.649 13.176H1.35V25H48.65V13.176zM25 48.649V13.176M25 13.176H14.358a5.912 5.912 0 110-11.825C22.635 1.351 25 13.176 25 13.176zM25 13.176h10.642a5.912 5.912 0 100-11.825C27.365 1.351 25 13.176 25 13.176z"></path>
        </g>
        <defs>
          <clipPath id="clip0_120_10">
            <path fill="#fff" d="M0 0H50V50H0z"></path>
          </clipPath>
        </defs>
      </svg>
  );
}

export default Icon;
