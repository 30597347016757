import { createSlice } from "@reduxjs/toolkit";

const RecentlyHeardSlice = createSlice({
  name: "RecentlyHeardData",
  initialState: {
    seenCollection: [],
    seenArtist: [],
  },
  reducers: {
    setSeenCollection: (state, action) => {
      let newArray = state.seenCollection;
      const { seen_Collection } = action.payload;
      const existingData = state.seenCollection.find(
        (item) => item.id === seen_Collection.id
      );
      if (!existingData) {
        newArray = [seen_Collection, ...newArray];
      } else {
        const filterData = newArray.filter(
          (item) => item.id !== seen_Collection.id
        );

        newArray = [seen_Collection, ...filterData];
      }

      state.seenCollection = newArray;
    },
    setSeenArtist: (state, action) => {
      let newArray = state.seenArtist;
      const { seen_Artist } = action.payload;
      const existingData = state.seenArtist.find(
        (item) => item.id === seen_Artist.id
      );
      if (!existingData) {
        newArray = [seen_Artist, ...newArray];
      } else {
        const filterData = newArray.filter(
          (item) => item.id !== seen_Artist.id
        );

        newArray = [seen_Artist, ...filterData];
      }

      state.seenArtist = newArray;
    },
  },
});
export const { setSeenCollection, setSeenArtist } = RecentlyHeardSlice.actions;
export const SelectSeenCollection = (state) =>
  state.RecentlyHeardData.seenCollection;
export const SelectSeenArtist = (state) => state.RecentlyHeardData.seenArtist;
export default RecentlyHeardSlice.reducer;
