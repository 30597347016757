import React from 'react';
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import DraggableSongDetailCard from "../../playlist/DraggableSongDetailCard.jsx";
import {globalData} from "../../../helper/ReduxSelectorContext.js";
import {setDragList} from "../../../redux/globalData/globalDataSlice.js";
import {useDispatch} from "react-redux";

function DragDropContainer() {
    const dispatch = useDispatch()
    const reorderTracks = (tracks, sourceIndex, destinationIndex) => {
        // Ensure the sourceIndex and destinationIndex are within the bounds of the array
        if (
            sourceIndex < 0 ||
            sourceIndex >= tracks.length ||
            destinationIndex < 0 ||
            destinationIndex >= tracks.length
        ) {
            throw new Error("Invalid source or destination index");
        }

        // Remove the track from the sourceIndex
        let trackList=[...tracks]
        const [trackToMove] = trackList.splice(sourceIndex, 1);

        // Insert the track at the destinationIndex
        trackList.splice(destinationIndex, 0, trackToMove);

        // Return the updated array of tracks
        return trackList;
    }
    const globalObject = globalData()
    const list = globalObject?.dragList
    const onDragEnd = (result) => {
        let newList = list
        const {destination, source, draggableId} = result
        if (!destination) {
            return
        }
        if (destination?.droppableId === source?.droppableId && destination?.index === source?.index) {
            return;
        }
        newList = reorderTracks(newList, source?.index, destination?.index)
        dispatch(setDragList({list: newList}))


    }
    const onDragStart = () => {
        console.log('log start drag...')
    }
    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="list">
                {provided => (
                    <div className='flex flex-col gap-2' ref={provided.innerRef} {...provided.droppableProps} >
                        {list?.map((item, index) => <DraggableSongDetailCard key={`item-${item?.id}`} item={item} index={index}/>)}
                        {provided?.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default DragDropContainer;
