import React from 'react';
import AlbumCardPlaceHolder from '../../../components/svg/album/AlbumCardPlaceHolder';
import CustomLazyLoadImage from '../../../components/common/CustomLazyLoadImage';

function AlbumSearchResultAvatar({ image }) {
  return (
    <div className="w-full h-full flex flex-col relative ">
      <div className="h-14 w-14 border-gray_1 border-[1px] rounded-[5px] top-0 z-0 absolute"></div>
      <div className="h-14 w-14 border-gray_2 border-[1px] rounded-[5px] top-1 right-1 z-10 absolute"></div>
      <div className="w-14 h-14 rounded-[5px] overflow-hidden absolute top-2 right-2 z-20  ">
        <div className=" ">
          <AlbumCardPlaceHolder />
        </div>
        <CustomLazyLoadImage
          image={image}
          imageClass="object-cover rounded-[5px] h-14 w-14"
        />
      </div>
    </div>
  );
}

export default AlbumSearchResultAvatar;
