import React from 'react';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';
import PlayListPagePlaceHolderSvg from '../svg/common/PlayListPagePlaceHolderSvg.jsx';

import HomeCollectionPlaceHolderSvg from '../svg/common/HomeCollectionPlaceHolderSvg.jsx';
import PlaceHolderWithTitle from '../common/PlaceHolderWithTitle.jsx';

function ArtistCard({ item }) {
  return (
    <>
      <div
        className={`a relative   rounded-[10px] overflow-hidden h-full w-full`}
      >
        <div
          className={`w-full overflow-hidden flex flex-col  z-10 justify-around h-[120px] rounded-[10px] items-center bg-[#404040] `}
        ></div>

        <div className="  z-20  w-full h-full  overflow-hidden ">
          <PlaceHolderWithTitle
            style={'absolute top-0 w-full left-0   '}
            title={item?.title}
          />
          <CustomLazyLoadImage loadStrategy="eager" image={item?.image} />
        </div>
      </div>
    </>
  );
}

export default ArtistCard;
