import React from 'react'

function SortSvg({fill="#F2C94C"}) {
  return  (
      <svg
        className='w-full h-full'
          fill="none"
          xmlns="http://www.w3.org/2000/svg"

      >
        <path
            d="M9 8H1M9 14H1M14 2H1M18 1v14M23 10l-5 5-5-5"
            stroke={fill}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
      </svg>
  );
}

export default SortSvg
