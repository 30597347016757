import React from "react";

function PlaylistPlaysButton({ratio = 'h-[48px] '}) {
    return (
        <div>
            <svg
                className={ratio}
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill="#F2C94C"/>
                <path
                    d="M31.6902 22.2352C33.1022 22.9881 33.1022 25.0119 31.6902 25.7648L21.699 31.0921C20.3667 31.8024 18.758 30.8371 18.758 29.3273L18.758 18.6727C18.758 17.1629 20.3667 16.1976 21.699 16.9079L31.6902 22.2352Z"
                    fill="#242429"
                />
            </svg>
        </div>
    );
}

export default PlaylistPlaysButton;
