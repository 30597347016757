import React, { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { createGiftInvoice, createInvoice } from '../../services/ApiClient.js';
import Loading from '../../components/loading/Loading.jsx';
import { useSelector } from 'react-redux';
import { selectInitialData } from '../../redux/initialize/InitializeSlice.js';
import PaymentCard from '../../components/plans/PaymentCard.jsx';
import SpecialOffer from '../../components/plans/SpecialOffer.jsx';
import Container from '../../components/PageParentContainer/Container.jsx';
import MelodifySvg from '../../components/lyrics/svg/MelodifySvg.jsx';
import { isIOS } from 'react-device-detect';
import DiscountCodeBottomSheet from '../../components/plans/DiscountCodeBottomSheet.jsx';
import CommonHeader from '../../components/common/CommonHeader';
import CustomImage from '../../components/common/CustomImage.jsx';
import OpenPaymentPageDialog from '../../components/plans/OpenPaymentPageDialog.jsx';

function Payment() {
  const location = useLocation();
  const dialogRef = useRef();
  const [discountCode, setDiscountCode] = useState(null);
  const [plan, setPlan] = useState({});
  const navigate = useNavigate();
  const initialDataSelector = useSelector(selectInitialData);
  const plansData = initialDataSelector?.plans;
  const OneYearData = plansData.filter(item => item.id === 5);
  useEffect(() => {
    setPlan(location?.state);
  }, [location?.state]);
  const openPaymentDialog = url => {
    dialogRef?.current?.openModal(url);
  };
  useEffect(() => {
    const channel = new BroadcastChannel('paymentBack');
    const handleMessage = event => {
      navigate('/', { replace: true });
    };
    channel.addEventListener('message', handleMessage);
    return () => {
      channel.removeEventListener('message', handleMessage);
      channel.close();
    };
  }, []);
  // make request when user click the buy button
  const createPaymentMutation = useMutation(
    planData => createInvoice(planData),
    {
      onSuccess: res => {
        openPaymentDialog(res?.data?.result?.url);
      },
    },
  );
  //   gift req
  const createGiftPaymentMutation = useMutation(createGiftInvoice, {
    onSuccess: res => {
      openPaymentDialog(res?.data?.result?.url);
    },
  });
  // onClick handling for buying plan
  const handleBuyingPlan = () => {
    if (location?.state?.giftData) {
      if (createGiftPaymentMutation.isLoading) {
        return;
      }
      createGiftPaymentMutation.mutate({
        plan_id: plan?.id,
        coupon_code: discountCode,
        ...location?.state?.giftData,
      });
    } else {
      if (createPaymentMutation.isLoading) {
        return;
      }
      createPaymentMutation.mutate({
        plan_id: plan?.id,
        coupon_code: discountCode,
      });
    }
  };
  const handleSpecialOffer = () => {
    setPlan(OneYearData[0]);
  };
  const handleNavigateTerms = () => {
    navigate('/about-us/p/about-terms');
  };
  //todo:change melodify icon ,author:Main Alireza...
  //todo:add custom header ...
  //todo:check image load problem...

    return (
        <>
            <Container header={<CommonHeader title={plan?.invoice_title}/>}>
                {Object?.keys(plan)?.length !== 0 && (
                    <div
                        dir="rtl"
                        className={`w-full h-full bg-black px-8 overflow-auto pb-4  hide-scroll-bar flex flex-col gap-4 relative pt-4   `}
                    >
                        <MelodifySvg opacity={false} height={'h-[82px]'}/>
                        {plan?.invoice_image &&
                            <div
                                className={`absolute  top-0 left-[10px] right-[10px] overflow-hidden  rounded-b-[30px] } `}>
                              <CustomImage
                                className="w-full h-full"
                                src={plan?.invoice_image}
                              />
                            </div>

                        }
                        <PaymentCard plan={plan} />
                        {/*discount part*/}
                        <div className='relative'>
                            <DiscountCodeBottomSheet  key={plan?.id} plan={plan}  onSetDiscountCode={(code)=>{setDiscountCode(code)}} onSetPlan={(plan) => {
                                setPlan(plan)
                            }}/>
                        </div>
                        {/*button*/}
                        <div className="w-full flex flex-col items-center mx-auto relative">
                            <button
                                onClick={handleBuyingPlan}
                                className=" lg-1440:rounded-br30 bg-yellow flex items-center justify-center w-full h-[46px] bg-primary rounded-[23px] text-[16px] font-dana font-[500] text-title_box "
                            >
                                {createPaymentMutation?.isLoading||createGiftPaymentMutation?.isLoading ? <Loading bgColor='#333333'/> : "پرداخت"}
                            </button>
                        </div>

            {plan?.month !== 12 && (
              <SpecialOffer
                onHandleSpecialOffer={handleSpecialOffer}
                plan={plan}
              />
            )}
            <div
              onClick={handleNavigateTerms}
              className="w-full flex items-center justify-center"
            >
              <p className="text-[12px] font-dana font-[300] text-primary  cursor-pointer border-b-[1px] border-primary">
                قوانین و شرایط استفاده از ملودیفای
              </p>
            </div>
          </div>
        )}
        <OpenPaymentPageDialog
          isGift={Boolean(location?.state?.giftData)}
          ref={dialogRef}
        />
      </Container>
    </>
  );
}

export default Payment;
