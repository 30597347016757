import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { initialData } from "../../redux/initialize/InitializeSlice";
import { getSession, removeAllSessions } from "../../services/ApiClient";
import ConfirmDialogs from "../dialogs/ConfirmDialogs";
import LogOutSVG from "../svg/common/LogOutSVG";
import TicketsSkeleton from "../Tickets/TicketsSkeleton";
import DeviceItem from "./DeviceItem";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/login/LoginSlice";
import { setDeviceToken } from "../../redux/initialize/InitializeSlice";
import ActiveDevicesSkeleton from "../Tickets/ActiveDevicesSkeleton";
import TrashSVG from "../svg/common/TrashSVG";
import { dispatchUnauthenticatedUser } from "../../redux/globalstore/GlobalStore";
import Container from "../PageParentContainer/Container.jsx";
import DangerSignSvg from "../svg/common/DangerSignSvg.jsx";
import CommonHeader from "../common/CommonHeader.jsx";
import MusicNoteLoading from "../loading/MusicNoteLoading.jsx";

function ManageActiveDevices() {
  const [currentDeviceActive, setCurrentDeviceActive] = useState();
  const [device, setDevices] = useState([]);
  const dispatch = useDispatch();
  const logOutDialogRef = useRef();
  const deleteAllDevicesDialogRef = useRef();
  const getActiveSessionQuery = useQuery(
    ["getActiveSessionQuery"],
    () => getSession(),
    {
      onSuccess: (res) => {
        setDevices(res?.data?.result?.other_sessions);
        setCurrentDeviceActive(res?.data?.result?.my_session?.name);
      },
      refetchOnWindowFocus: false,
    }
  );
  //cach data
  useEffect(() => {
    setDevices(getActiveSessionQuery?.data?.data?.result?.other_sessions);
    setCurrentDeviceActive(
      getActiveSessionQuery?.data?.data?.result?.my_session?.name
    );
  }, []);
  const removeAllDeviceMutation = useMutation(() => removeAllSessions(), {
    onSuccess: (res) => {
      getActiveSessionQuery.refetch();
    },
  });

  const handleDeleteOtherDevice = () => {
    deleteAllDevicesDialogRef.current.openDialog();
  };

  const handleConfirmLogOut = () => {
    dispatchUnauthenticatedUser();
  };

  const handleConfirmDeleteAllDevices = () => {
    removeAllDeviceMutation.mutate();
  };

  const handleLogout = () => {
    logOutDialogRef.current.openDialog();
  };

  return (
    <Container
      style="bg-primary_dark"
      header={<CommonHeader title={"مدیریت دستگاه های فعال"} />}
    >
      <div
        className={`w-full h-full ${
          !getActiveSessionQuery?.isLoading && "hidden"
        }`}
      >
        <div className="fixed flex justify-center items-center w-full h-full bg-secondary  left-0 top-0 z-10">
          <div className="h-[120px]  w-[120px] left-0 top-0 mt-[56px]">
            <MusicNoteLoading />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col font-dana gap-8 mx-auto px-4 pb-18 text-right max-w-[520px]  dir-rtl">
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-col w-full gap-3 py-6 bg-gray-1 border-1 border-gray-2 rounded-[20px]">
            <p className="font-dana text-base text-white">این دستگاه:</p>
            <div className="flex gap-4 bg-secondary px-4 py-2  rounded-[10px] items-center">
              <div className="rounded-full bg-green aspect-square h-[14px]"></div>
              <div className="flex flex-col gap-r2">
                <div className="flex">
                  {getActiveSessionQuery?.isLoading ? (
                    <ActiveDevicesSkeleton height="2.08vw" width="13.88vw" />
                  ) : (
                    <p className=" text-sm text-white font-poppins dir-ltr mb-1">
                      {currentDeviceActive}
                    </p>
                  )}
                  <p className=" text-sm text-green">&nbsp;( فعال )</p>
                </div>
                <p className=" text-sm text-green">دستگاه من</p>
              </div>
            </div>
          </div>
          <div onClick={handleLogout} className="flex gap-4 items-center">
            <div className="aspect-square h-[18px]">
              <LogOutSVG fill="#F2C94C" />
            </div>
            <p className=" text-lg text-primary">خروج از حساب کاربری</p>
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            {device?.length > 0 && (
              <p className="font-dana text-base text-white">دستگاه های دیگر:</p>
            )}
            <div className="flex flex-col gap-4">
              {device?.map((item) => (
                <DeviceItem
                  item={item}
                  getActiveSessionQuery={getActiveSessionQuery}
                />
              ))}
            </div>
          </div>
          {device?.length > 0 && (
            <button
              onClick={() => handleDeleteOtherDevice()}
              type="button"
              className=" w-full flex items-center !justify-start p-0 m-0 text-primary text-base bg-transparent rounded-[100px] border border-transparent focus:outline-none gap-4 mb-8"
           >
            
              <div className="h-[22px] w-[22px]">
                <TrashSVG fill={"#EB5757"} />
              </div>
              <p className="text-base text-red">حذف همه دستگاه های دیگر</p>
            </button>
          )}
        </div>
        <ConfirmDialogs
          dialogIcon={<LogOutSVG fill={"#FFFFFF"} />}
          title={"خروج از حساب کاربری"}
          text={"از حساب کاربری خود خارج می شوید؟"}
          buttonDirection={"dir-ltr"}
          confirmText={"خروج"}
          confirmTextColor={"text-primary"}
          confirmBGColor={"bg-transparent"}
          confirmBorderColor={"border-primary"}
          confirmAction={handleConfirmLogOut}
          cancelText={"لغو"}
          cancelTextColor={"text-secondary"}
          cancelBGColor={"bg-primary"}
          cancelBorderColor={"transparent"}
          ref={logOutDialogRef}
        />
        <ConfirmDialogs
          dialogIcon={<TrashSVG fill={"#EB5757"} />}
          title={"حذف"}
          text={
            "مطمئن هستید که میخواهید همه ی دستگاه های دیگر متصل به حساب کاربری خود را حذف کنید؟"
          }
          buttonDirection={"dir-ltr"}
          confirmText={"حذف"}
          confirmTextColor={"text-primary"}
          confirmBGColor={"bg-transparent"}
          confirmBorderColor={"border-primary"}
          confirmAction={handleConfirmDeleteAllDevices}
          cancelText={"لغو"}
          cancelTextColor={"text-secondary"}
          cancelBGColor={"bg-primary"}
          cancelBorderColor={"transparent"}
          ref={deleteAllDevicesDialogRef}
        />
      </div>
    </Container>
  );
}

export default ManageActiveDevices;
