import React from 'react';

function SaveInDeviceSvgIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className='w-full h-full'
            fill="none"
            viewBox="0 0 14 18"
            {...props}
        >
            <path
                fill="#fff"
                d="M12.6 18a.6.6 0 01-.344-.11L6.6 13.932l-5.656 3.96A.6.6 0 010 17.399V2.88A2.883 2.883 0 012.88 0h7.44a2.883 2.883 0 012.88 2.88V17.4a.6.6 0 01-.6.6zm-6-5.4a.6.6 0 01.343.108L12 16.247V2.88a1.68 1.68 0 00-1.68-1.68H2.88A1.68 1.68 0 001.2 2.88v13.367l5.057-3.54a.6.6 0 01.344-.108z"
            ></path>
        </svg>
    );
}

export default SaveInDeviceSvgIcon;
