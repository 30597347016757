import React from 'react';
import ChevronSVG from '../svg/common/ChevronSVG.jsx';
import { setCategory } from '../../redux/support/SelectedCategorySlice.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

import OtherIconSVG from '../svg/support/OtherIconSVG.jsx';

function FaqItem({ item }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectFaqCategory = item => {
    dispatch(setCategory({ category: item }));
    if (item.direct_ticket) {
      navigate('create-ticket', { state: { item } });
    } else {
      navigate('ticket-category');
    }
  };
  return (
    <div
      onClick={() => {
        selectFaqCategory(item);
      }}
      className=" cursor-pointer"
    >
      {item?.is_other ? (
        <div className="inline-flex items-center w-auto border border-primary  mt-2 bg-gray_2 rounded-full py-3 px-4 min-h-[48px]">
          <OtherIconSVG classList={'w-[20px] h-[20px] ml-2'} />
          <span className="flex items-center text-white text-xs min-[375px]:text-sm font-dana">
            {item?.title}
          </span>
        </div>
      ) : (
        <div className="inline-flex items-center w-auto mt-2 bg-gray_2 rounded-full py-3 px-4 min-h-[48px]">
          <span className="flex items-center text-white text-xs min-[375px]:text-sm font-dana">
            <span>{item?.title}</span>
          </span>
        </div>
      )}
    </div>
  );
}

export default FaqItem;
