import {createSlice} from "@reduxjs/toolkit";


const upKeepSlice=createSlice({
    name: 'upkeep',
    initialState:{
        upKeep:null,
    },
    reducers:{
        setUpkeepData:(state,action)=>{
            const {data}=action.payload
            state.upKeep=data
        }
    }


})

export const {setUpkeepData}=upKeepSlice.actions
export const selectUpkeep= (state)=>state.upKeep
export default upKeepSlice.reducer
