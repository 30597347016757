import React from 'react';

function CancelSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-full h-full"
            fill="none"
            viewBox="0 0 14 14"
        >
            <path
                fill="#FAFAFA"
                d="M8.28 7l5.455-5.454A.901.901 0 0014 .904a.874.874 0 00-.265-.639.875.875 0 00-.64-.265.901.901 0 00-.64.265L7 5.72 1.546.265A.901.901 0 00.904 0a.875.875 0 00-.639.265.875.875 0 00-.265.64.901.901 0 00.265.64L5.72 7 .265 12.454a.901.901 0 00-.265.642.875.875 0 00.265.639.875.875 0 00.64.265.9.9 0 00.64-.265L7 8.28l5.454 5.455a.901.901 0 001.28 0 .907.907 0 000-1.28L8.28 7z"
            ></path>
        </svg>
    );
}

export default CancelSvg;
