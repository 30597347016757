import React from "react";
import HillSvg from "./HillSvg.jsx";
import CrownIconType2Svg from "../svg/common/CrownIconType2Svg.jsx";
import { useAction } from "../../hooks/useAction.js";
import { dispatchSwipePlayerStatus } from "../../redux/globalstore/GlobalStore.js";
import { useLocation } from "react-router";

function TrackLimit({
  message,
  style = "bg-black_2",
  crownParentStyle = "",
  hillFill,
  inPlayer = false,
}) {
  const action = useAction();
  const location = useLocation();
  const openPlans = () => {
    dispatchSwipePlayerStatus(false);
    if (location?.pathname !== "/plans")
      action({ target_type: "plans", openPlayerWhenBack: inPlayer });
  };
  return (
    <div dir="rtl" className="flex flex-col mt-3 ">
      <div className="w-full flex justify-center relative top-[2px]">
        <HillSvg fill={hillFill} className="mt-[-1px]" />
      </div>
      <div
        className={`flex flex-col  gap-1  p-4 !pt-0 rounded-t-[10px] ${style}`}
      >
        <div className="flex flex-col gap-2 items-center">
          <div className={`w-[24px] h-[19px] relative -mt-2 `}>
            <CrownIconType2Svg />
          </div>
          <p className="text-primary font-dana text-xs font-medium leading-6 text-center">
            {message}
          </p>
          <div className="w-full flex flex-col items-center mx-auto mb-1">
            <button
              onClick={openPlans}
              className=" lg-1440:rounded-br30 bg-yellow flex items-center justify-center w-[166px] h-[46px] bg-primary rounded-[23px] text-[16px] font-dana font-[500] text-title_box "
            >
              ویژه شوید
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackLimit;
