import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie-file/playing.json";

function PlayingLottieAnim() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  return (
    <div>
      <Lottie
        isClickToPauseDisabled={true}
        options={defaultOptions}
        //   height={}
        //   width={width}
      />
    </div>
  );
}

export default PlayingLottieAnim;
