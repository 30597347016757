import { createSlice } from "@reduxjs/toolkit";

export const InitializeSlice = createSlice({
    name: 'initialize',
    initialState: {
        initialData: null,
        checkSum: null,
        deviceToken:null
    },
    reducers: {
        checkSum: (state, action) => {
            const { checkSum } = action.payload
            state.checkSum = checkSum
        },
        initialData: (state, action) => {
            const { initialData } = action.payload
            state.initialData = initialData
        }, 
        setDeviceToken: (state, action) => {
            const { deviceToken } = action.payload
            state.deviceToken = deviceToken
        },  setTicketReadCount: (state, action) => {
            const { count } = action.payload
            state.initialData = {...state.initialData,unread_ticket_count:count}
        },
        

    },
})
export const { checkSum, initialData ,setDeviceToken,setTicketReadCount} = InitializeSlice.actions;
export const selectCheckSum = (state) => state.initialize.checkSum;
export const selectInitialData = (state) => state.initialize.initialData;
export const selectAdvLimitationData = (state) => state.initialize?.initialData?.advertise_data;
export default InitializeSlice.reducer;
