import React from "react";

function EarSVGType2({fill="#B0B0B5"}) {
  return (
    <svg
      class="w-full h-full"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00008 0.833344L16.4167 19.1667"
        stroke={fill} 
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.639 15.0946L10.6389 15.0945C10.4653 14.2459 10.6968 13.3727 11.0648 12.6185C11.4356 11.8582 11.9703 11.1654 12.4865 10.6493C13.61 9.52568 14.2278 8.03431 14.2278 6.44531C14.2278 3.16751 11.5603 0.5 8.28247 0.5C5.00467 0.5 2.33716 3.16751 2.33716 6.44531C2.33716 8.02019 2.63014 9.59414 2.92457 11.1681M10.639 15.0946L2.92457 11.1681M10.639 15.0946C10.6846 15.3172 10.7122 15.5987 10.7122 15.8594C10.7122 17.8708 9.0916 19.5 7.1106 19.5C5.1296 19.5 3.50903 17.8708 3.50903 15.8594C3.50903 14.2924 3.20907 12.689 2.92879 11.1907L2.92457 11.1681M10.639 15.0946L2.4331 11.2601L2.92457 11.1681M10.4706 15.1291L10.4706 15.129C10.3089 14.3389 10.4746 13.4931 10.8476 12.6765C11.2199 11.8613 11.7844 11.1083 12.3649 10.5277C13.4547 9.43795 14.0559 7.98651 14.0559 6.44531C14.0559 3.2614 11.4664 0.671875 8.28247 0.671875C5.09855 0.671875 2.50903 3.2614 2.50903 6.44531C2.50903 8.01227 2.80899 9.61572 3.08928 11.114L3.0935 11.1365C3.38792 12.7106 3.68091 14.2845 3.68091 15.8594C3.68091 17.7662 5.2128 19.3281 7.1106 19.3281C9.00839 19.3281 10.5403 17.7662 10.5403 15.8594C10.5403 15.618 10.5155 15.3485 10.4706 15.1291Z"
        stroke={fill} 
      />
      <path
        d="M11.1592 8.61483L11.0365 8.49223C12.515 6.52114 11.4622 3.5389 8.92774 3.07474L8.92772 3.07474C6.71557 2.66967 4.85278 4.37947 4.85278 6.44523C4.85278 6.65035 4.8589 6.85642 4.87025 7.06354L4.89492 7.51353L5.34505 7.53557C6.36069 7.58531 7.30186 8.05731 7.95123 8.84942C8.59733 9.63753 8.76187 10.4396 8.76773 11.0699C8.77069 11.3876 8.73311 11.6629 8.69374 11.8719C8.67418 11.9757 8.65439 12.0622 8.63791 12.1343L8.63744 12.1364L8.63703 12.1381C8.62932 12.1719 8.62096 12.2084 8.61466 12.2396L8.6142 12.2418C8.61149 12.2552 8.60577 12.2834 8.60165 12.3179C8.44561 13.0397 7.79997 13.5624 7.05935 13.5624C6.29389 13.5624 5.63487 13.0057 5.50195 12.2224C5.41591 11.7148 5.32018 11.2063 5.22852 10.7193L5.22814 10.7173C5.22814 10.7173 5.22814 10.7173 5.22814 10.7173C4.94015 9.18685 4.68091 7.80067 4.68091 6.44523C4.68091 4.22157 6.69352 2.49083 8.95869 2.90568L9.04876 2.41386L8.95869 2.90569C11.6381 3.39639 12.7282 6.55083 11.1592 8.61483ZM7.81832 8.95835L7.81831 8.95834C7.24427 8.25817 6.4277 7.81931 5.53811 7.72309L4.90488 7.6546L4.98869 8.28599C5.09202 9.06434 5.24274 9.86533 5.39515 10.6753L5.39645 10.6821L5.39771 10.6889C5.49092 11.1842 5.58566 11.6877 5.67146 12.1936L5.67147 12.1937C5.78664 12.8726 6.35993 13.3905 7.05935 13.3905C7.7281 13.3905 8.30692 12.916 8.43807 12.2603L8.44683 12.2165L8.44721 12.197C8.44885 12.1889 8.45219 12.1724 8.45845 12.1448C8.46069 12.1349 8.46318 12.1241 8.46586 12.1123C8.47799 12.0594 8.49419 11.9886 8.5101 11.9097C8.54966 11.7134 8.59149 11.4484 8.59432 11.1399C8.60002 10.5163 8.44526 9.72308 7.81832 8.95835Z"
        fill="white"
        stroke={fill} 
      />
    </svg>
  );
}

export default EarSVGType2;
