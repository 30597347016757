import React from 'react';
import RenderDownloadStatus from '../download/RenderDownloadStatus.jsx';
import ThreeDotSvg from '../svg/common/ThreeDotSvg.jsx';
import { useContextPosition } from '../../helper/useContextPosition.js';
import { useAction } from '../../hooks/useAction.js';
import { useDownload } from '../../hooks/useDownload.js';
import { useSelectTrackItem } from '../../hooks/useSelectTrackItem.js';

function SongCardButtonsSection({isSelectMode,item,inPlayer,isInQueue}) {
  const action = useAction();
  const trackId = item?.Qid ? item?.Qid : item?.id;
  const {isSelected, selectItem, deSelectItem} = useSelectTrackItem(
    inPlayer,
    item
  );
  const [
    startDownloadTrack,
    isDownloaded,
    inQueue,
    inQueueItem,
    downloadedItem,
  ] = useDownload(item);
  const handleSelectItem = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isSelected) {
      deSelectItem(item);
    } else {
      selectItem(item);
    }
  };
  const handleDownloadTrack = (event) => {
    event.stopPropagation();
    startDownloadTrack();
  };
  const openContextMenu = (e) => {
    console.log("open context menu...");
    e.stopPropagation();
    e?.preventDefault();
    const [vertical, horizontal] = useContextPosition(e);

    action({
      target_type: "open_context_menu",
      data: {...item, isInQueue},
      inPlayer,
      horizontal,
      vertical,
      isDownloadedTrack: isDownloaded,
    });
  };
  return (
    <div
      className={`flex items-center h-[52px]`}
    >
      <div className="h-full">
        <RenderDownloadStatus
          item={item}
          isDownloaded={isDownloaded}
          startDownloadTrack={handleDownloadTrack}
          inQueue={inQueue}
          downloadedItem={downloadedItem}
          inQueueItem={inQueueItem}

        />
      </div>
      {isSelectMode ? (
        <div
          onClick={handleSelectItem}
          dir="rtl"
          className="px-4 h-[52px] flex justify-start items-center"
        >
          <div
            className={`w-[18px] h-[18px] border-[2px] rounded-[3px] ${
              isSelected
                ? 'bg-primary border-primary'
                : 'bg-transparent border-gray_3'
            }`}
          ></div>
        </div>
      ) : (
        <div  onClick={e => {
          openContextMenu(e);
        }} className="flex items-center h-full pl-4 pr-2   relative ">
          <div
            className=" h-[40px] items-center flex "

            style={{ aspectRatio: 4 / 17 }}
          >
            <ThreeDotSvg class=" " fill={'#828282'} />
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(SongCardButtonsSection);
