import {
    downloadData,
    getHaveAdvertise,
    getIsPlaying,
    getPlayerMusic,
    getPlayerShuffle,
    getPlayListClone,
    getUserToken,
    useUserData
} from "../helper/ReduxSelectorContext";
import { createMusicCdnLink } from "../helper/createMusicCdnLink";
import { useDispatch } from "react-redux";
import { setIsPlaying, setMusic, setPlayList, setPlayListShuffle, setPlayListstatic, setSwipePlayerStatus, setTrackDuration } from "../redux/player/PlayMusicSlice";
import ShuffleList from "../helper/ShuffleList";

import { db } from "../indexDB/DB.js";
import { setEmtyAudioStatus } from "../redux/player/PlayerSlice";
import { useState } from "react";
import { GetPlayerRef } from "../helper/GetPlayerRef.jsx";
let emptyPlay = true
export function usePlayedTrack(canShuffle = true, disableSwipePlayer = false) {
    let currentTrack = getPlayerMusic()
    let userData = useUserData()
    let userToken = getUserToken()
    let playerStatus = getIsPlaying()
    let isShuffle = getPlayerShuffle();
    let playListClone = getPlayListClone();
    let isPlaying = getIsPlaying()
    let havAdvertise = getHaveAdvertise();
    const dispatch = useDispatch()
    const [getPlayer] = GetPlayerRef();
   
    const EmtyAudioResource = document.getElementById("noPlayerReff")
    const { downloadedFiles } = downloadData();
    const handleDownloadedPlay = async (item) => {
        // getDownloadedFile(item?.id).then((response)  => URL.createObjectURL(response?.blob))
        const data = await db?.downloadedFiles?.get(item?.id)
        if (data?.blob) {
            if (data?.blob instanceof ArrayBuffer) {
                console.log("Played ArrayBuffer", data?.blob instanceof ArrayBuffer);
                // ArryBuffer
                const blob = new Blob([data?.blob], { type: 'audio/mp3' })
                return URL.createObjectURL(blob)
            } else {
                console.log("Played Blob", data?.blob instanceof ArrayBuffer);
                return URL.createObjectURL(data?.blob)
            }
        }

        else return createMusicCdnLink(item, userData, userToken)

    }
    const setPlayerTrack = async (track, playList = [], type = "playlist", isPlayListFromQueue = false, url = null) => {

        // const mainPlayer = document.querySelector("#playerDDD video")
        const mainPlayer = getPlayer();
        if (emptyPlay) {
            console.log("Load Empty Audio Source");
            mainPlayer.src = "/empty.mp3"
            mainPlayer.play()

        }

        //handle continue Play if not playing from Queue Playlist
        if (!disableSwipePlayer)
            dispatch(setSwipePlayerStatus({ status: true }))

        if (currentTrack?.id === track?.id && !isPlayListFromQueue) {
            dispatch(setIsPlaying({ isPlay: true }))
            dispatch(setSwipePlayerStatus({ status: true }))
            return
        }

        if (currentTrack?.id && currentTrack?.Qid == track?.Qid) {
            dispatch(setIsPlaying({ isPlay: !playerStatus }))
            return
        }

        handlePlayEmptyAudioForKeepMediaAlive()

        let cdn_url = doesExistInDownloaded(downloadedFiles, track?.id) ? await handleDownloadedPlay(track) : createMusicCdnLink(track, userData, userToken)

        let index = playList?.findIndex((item) => item?.id === track?.id);
        dispatch(setMusic({
            track: {
                ...track,
                cdn_url: cdn_url,
                Qid: track?.Qid ? track?.Qid : track?.id,
                type,
                index
            }
        }))

        // id some unique id for handle playlist and Queue
        let playListWithQid = playList.map(item => {
            let itemCopy = { ...item }
            // itemCopy.Qid = item.id + "" + Math.floor(Math.random() * 1000000).toFixed()
            itemCopy.Qid = item.id
            return itemCopy
        })
        dispatch(setPlayList({ playList: playListWithQid }))
        dispatch(setPlayListstatic({ playListStatic: playListWithQid }))

        if (isShuffle && canShuffle) {
                        let playListWithQidb = ShuffleList(playListWithQid)
            dispatch(setPlayListShuffle({ playList: playListWithQidb, clone: true }));
        } else {
            // dispatch(setPlayListShuffle({ playList: playListClone, clone: false }));
        }

        if (emptyPlay) {

            mainPlayer.pause()
            emptyPlay = false
        }
        // ppppp.src = null


        dispatch(setIsPlaying({ isPlay: true }))
        // const vvvvvv = document.getElementById("mainPlayer")
        // vvvvvv.src=cdn_url
        // vvvvvv.play()

        // const vvvvvv = document.getElementById("mainPlayer")
        if (!havAdvertise) {
            mainPlayer.src = cdn_url
            mainPlayer.load()
            mainPlayer.play()
            dispatch(setTrackDuration({ duration: 0.1 }));
        }



    }

    const handlePlayEmptyAudioForKeepMediaAlive = () => {
        EmtyAudioResource.src = "/empty.mp3"
        EmtyAudioResource?.play()
        dispatch(setEmtyAudioStatus({ status: true }))
    }

    return [currentTrack, setPlayerTrack]

}

const doesExistInDownloaded = (array, id) => {
    return array.some((trackItem) => trackItem?.id === id);
};

