import React, { Fragment, useEffect, useRef, useState } from "react";
import SidebarSearchSvg from "../svg/sidebar/SidebarSearchSvg";
import { useMutation } from "@tanstack/react-query";
import { getSearchResult } from "../../services/ApiClient.js";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSearch,
  selectSearchHistory,
  setCommitSearch,
  setCommitedSearchQuery,
  setSearchHistory,
  setSearchQuery,
} from "../../redux/search/SearchSlice.js";

import { Transition } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router";
import {
  getSearchCashResult,
  getSearchQuery,
} from "../../helper/ReduxSelectorContext";
import ClearSearchIconSvg from "../svg/sidebar/ClearSearchIconSvg";
import { mainToast, warningToast } from "../../helper/ToastsEmitter";
import ClickReaction from "../../helper/ClickReaction";
import HeaderPlayPauseIcon from "./HeaderPlayPauseIcon";
import SearchYellowIcon from "../svg/sidebar/SearchYellowIcon";
import { CardActionArea } from "@mui/material";

function SearchInput({ title }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const navigate = useNavigate();
  const searchInputRef = useRef();
  let searchQuery = getSearchQuery();
  // const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery?.trim()?.length < 2) {
      mainToast(".برای جستجو باید حداقل دو حرف وارد شود");
      return;
    }
    setShowSearchHistory(false);
    dispatch(setCommitSearch());
    // dispatch(setSearchHistory({ searchQuery: searchQuery }));
    dispatch(setCommitedSearchQuery({ commitedSearchQuery: searchQuery }));
    if (location?.pathname !== "/search-result") {
      navigate("/search-result");
    }

    if (searchInputRef.current) {
      searchInputRef.current.blur();
    }

    const searchparent = document.getElementById("search-result-parent");
    searchparent?.scrollTo({ top: 0, behavior: "smooth" });
  };
  const setSearchValue = (value) => {
    dispatch(setSearchQuery({ searchQuery: value }));
  };
  const clearSearchData = (e) => {
    e.preventDefault();
    dispatch(setSearchQuery({ searchQuery: "" }));
    searchInputRef.current.focus();
  };

  return (
    <>
      <div
        dir="rtl"
        className="w-full items-center flex relative flex-row justify-start "
      >
        <form
          onSubmit={(e) => {
            handleSearch(e);
          }}
          // class="bg-gray-1 w-[48.223vw] lg-1200:w-[52.223vw] flex items-center rounded-[12px] lg-1440:rounded-br12 border-[1px] lg-1440:border-b1 border-gray-2 mx-3 lg-1440:mx-r12 lg-1440:pr-r12 ">
          className={`rounded-[30px] bg-black_2 h-[48px] w-full  flex flex-row justify-between items-center py-[1px] pr-4 pl-[1px] gap-3`}
        >
          <>
            {searchQuery ? (
              <div
                className="cursor-pointer "
                onClick={(e) => clearSearchData(e)}
              >
                <ClearSearchIconSvg />
              </div>
            ) : (
              <div className="h-[40px]  flex justify-center items-center">
                <SearchYellowIcon />
              </div>
            )}
          </>

          <input
            ref={searchInputRef}
            onFocus={() => setShowSearchHistory(true)}
            // onBlur={() => setShowSearchHistory(false)}
            value={searchQuery}
            // defaultValue={searchQuery}
            onChange={(e) => setSearchValue(e.target.value)}
            className="flex-1 bg-transparent h-full w-full outline-none  p-0 m-0 text-[16px] leading-6
              placeholder:text-gray_4 font-dana font-[500]  
             placeholder:text-right placeholder:text-[16px] text-white"
            placeholder=" جست وجو کنید..."
          />

          <Transition
            as={Fragment}
            show={searchQuery ? true : false}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="flex flex-row items-center gap-3  h-full  p-[3px] ">
              <CardActionArea className="!h-full">
                <button
                  onClick={(e) => handleSearch(e)}
                  className="bg-primary rounded-[100px]  h-full  px-6 font-dana font-[500] text-sm"
                >
                  جستجو
                </button>
              </CardActionArea>
            </div>
          </Transition>
        </form>

      </div>
    </>
  );
}

export default React.memo(SearchInput);
