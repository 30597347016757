import React from 'react';

function SleepTimerSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                stroke="#E0E0E0"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 10.79A9 9 0 119.21 1 7 7 0 0019 10.79v0z"
            ></path>
        </svg>
    );
}

export default SleepTimerSvg;
