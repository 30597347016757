import React from "react";
import CssShimmer from "../common/CssShimmer.jsx";

function SongsDetailCardShimmer({dir='ltr',reverse=false}) {
    return (
        <div dir={dir} className={'inline-flex gap-2 min-[375px]:gap-3'}>
                <CssShimmer
                    reverse={reverse}
                    classNames="h-14 w-14 min-[375px]:h-16 min-[375px]:w-16 rounded-xl min-[375px]:rounded-2xl"
                />
            <div className="flex flex-1 flex-col justify-center items-start gap-2.5">
                    <CssShimmer
                        reverse={reverse}
                        classNames="w-full rounded-full h-5"
                    />
                    <CssShimmer
                        reverse={reverse}
                        classNames="w-1/2 rounded-full h-5"
                    />
            </div>
        </div>
    );
}

export default React.memo(SongsDetailCardShimmer);
