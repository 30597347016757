import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import HomeSlider from "../../components/home/HomeSlider";
import Container from "../../components/PageParentContainer/Container.jsx";
import PlansBackground from "../../components/plans/PlansBackground.jsx";
import PlansFeature from "../../components/plans/PlansFeature.jsx";
import PlansCardItem from "../../components/plans/PlansCardItem.jsx";
import { initialData, selectInitialData } from '../../redux/initialize/InitializeSlice';
import {CardActionArea} from "@mui/material";
import RoundedDrawerHeader from "../../components/svg/common/RoundedDrawerHeader.jsx";
import {useLocation, useNavigate} from "react-router-dom";
import {setSwipePlayerStatus} from "../../redux/player/PlayMusicSlice";
import {useQuery} from "@tanstack/react-query";
import {getPlans} from "../../services/ApiClient";
import {appendBaseUrl} from "../../helper/utils.js";

function Plans() {
    const initialDataSelector = useSelector(selectInitialData);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const handleClosePlanPage = () => {
        navigate(-1);

    };
    const openPlayer = () =>{
        console.log('openPlayer...');
        if (location?.state?.openPlayerWhenBack)
            dispatch(setSwipePlayerStatus({status: true}));
    }
    const getPlanDataQuery = useQuery(["getPlanDataQuery"], () => getPlans(), {
        onSuccess: (res) => {
            if(!res?.data?.result){
                return
            }
            dispatch(
              initialData({
                  initialData: { ...initialDataSelector,plan_settings: res?.data?.result?.plan_settings,plans: res?.data?.result?.plans,plans_banner: res?.data?.result?.plans_banner },
              }),
            );
        },
    });
    useEffect(() => {
        window.removeEventListener('popstate',openPlayer)
        window.addEventListener('popstate',openPlayer,{once:true});
    }, []);

    return (
        <Container style="bg-black_2">
            <div
                dir="rtl"
                className="w-full h-full bg-black_2  px-4 flex flex-col gap-5"
            >
                <div className="z-30 top-0 absolute left-0 right-0  ">
                    <div
                        onClick={() => handleClosePlanPage()}
                        className="!w-fit mx-auto justify-center z flex bg-transparent"
                    >
                        <RoundedDrawerHeader classList={"!h-[20px]"}/>
                    </div>
                </div>
                <PlansBackground
                    image={initialDataSelector?.plan_settings?.background_image}
                >
                    <div className="flex flex-col gap-6 relative">
                        <div className="mt-9">
                            {initialDataSelector?.plans_banner?.length !== 0 && (
                                <HomeSlider
                                    heightRelation={initialDataSelector?.plan_settings?.banner_height_relation}
                                    plan={true}
                                    data={initialDataSelector?.plans_banner}
                                    metaData={{auto_slide_time: 5}}
                                    slideItemContainerClass={'w-full'}
                                    slideItemContentClass={'rounded-[10px] pr-4 pl-4'}
                                    slideItemActionAreaClass={'!rounded-[10px]'}
                                />
                            )}
                        </div>

                        <PlansFeature/>
                    </div>
                </PlansBackground>
                <div className="grid grid-cols-2  gap-2 bg-black_2 w-full  pb-[16px] ">
                    {initialDataSelector?.plans?.map((item, index) => (
                        <CardActionArea className="h-full">
                            <PlansCardItem index={index} item={item}/>
                        </CardActionArea>
                    ))}
                </div>
            </div>
        </Container>
    );
}

export default Plans;
