import React, { useRef } from 'react';
import ArtistPlaceHolderSvg from '../svg/common/ArtistPlaceHolderSvg.jsx';
import GoToArtistBottomSheet from '../player/GoToArtistBottomSheet.jsx';
import ExclusiveTrackCardFollowSection from './ExclusiveTrackCardFollowSection.jsx';
import ExclusiveTrackCardOptionSection from './ExclusiveTrackCardOptionSection.jsx';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import { textFontBasedLanguage } from '../../helper/TextFontBasedLanguage.js';

function ExclusiveTrackCardInfo({item, inPlayer, isSelectMode, showIsPlaying}) {
    const goToArtistBottomSheetRef = useRef()
    const openGoToArtistBottomSheet = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(e.target?.id === 'dialog-transition'){
            return
        }
        goToArtistBottomSheetRef?.current?.openGoToArtistBottomSheet()
    }
    return (
        <>
            <GoToArtistBottomSheet ref={goToArtistBottomSheetRef} data={item}/>
            <div className='flex flex-col gap-2 w-[calc(100%-112px)] pb-1 '>
                <ExclusiveTrackCardOptionSection isSelectMode={isSelectMode} item={item} inPlayer={inPlayer}
                                                 showIsPlaying={showIsPlaying}/>
                <div className='w-full h-full pr-4'>
                    <div onClick={openGoToArtistBottomSheet} className='flex justify-between rounded-[50px] bg-secondary items-center w-full h-[40px]  max-w-full'>
                        <ExclusiveTrackCardFollowSection item={item}/>
                        <div className='flex gap-2 items-center h-full w-[calc(100%-50px)] '>
                            <p
                              dir='ltr'
                              className={`text-primary text-[12px] max-w-full  font-[500] relative text-gray_5  leading-6 whitespace-nowrap  w-[calc(100%-48px)] truncate ${textFontBasedLanguage(item?.artists?.[0]?.name)}  `}
                            >
                                {item?.artists?.[0]?.name}
                            </p>
                            <div
                              className=' rounded-[50px]  h-[40px] w-[40px] min-w-[40px] relative overflow-hidden'>
                                <div className='absolute top-0 left-0 rounded-[50px]'>
                                    <ArtistPlaceHolderSvg/>
                                </div>
                                {item?.artists[0]?.image_large && (
                                    <CustomLazyLoadImage
                                        hasEffect={true}
                                        image={item?.artists[0]?.image_large}
                                        imageClass="object-cover rounded-[50px]"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    );
}

export default ExclusiveTrackCardInfo;
