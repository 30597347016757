import React from 'react';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';

import ArtistProfilePlaceHolderSvg from '../svg/common/ArtistProfilePlaceHolderSvg.jsx';
import EarSvg from '../svg/artists/EarSvg.jsx';
import CssShimmer from '../common/CssShimmer.jsx';
import FollowersCountSvg from '../svg/playList/FollowersCountSvg.jsx';
import MusicCountSvg from '../svg/playList/MusicCountSvg.jsx';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import { textFontBasedLanguage } from '../../helper/TextFontBasedLanguage.js';

function ArtistProfileTitleSection({ artistObject, artistData }) {
  return (
    <div
      id="title-section"
      className="flex gap-4 flex flex-col  items-end w-full items-center relative  "
    >
      {artistObject?.image && (
        <div className="relative  rounded-[20px]  overflow-hidden aspect-square w-[55%] xl:min-h-[13.343vw] ">
          <CustomLazyLoadImage image={artistObject?.image} />
          <ArtistProfilePlaceHolderSvg className=" w-full h-full aspect-square " />
        </div>
      )}
      <div className="flex flex-col w-full  gap-9 items-center">
        <p
          dir={setLanguageDirection(artistObject?.name)}
          className={` text-[20px]   font-semibold leading-normal text-gray_5 ${textFontBasedLanguage(artistObject?.name,'font-kalameh','en-kalameh')} `}
        >
          {artistObject?.name}
        </p>
        <div className="flex items-center justify-center  gap-7 w-full">
          <div className="flex gap-2  items-center">
            <EarSvg />
            <div className="flex flex-col h-full justify-between min-w-14">
              {artistData?.play_count ? (
                <p className=" font-semibold leading-6 font-dana text-[18px] text-gray_5">
                  {artistData?.play_count}
                </p>
              ) : (
                <CssShimmer classNames={'w-14 h-6 rounded-[8px]'} />
              )}
              <p className=" font-[400] leading-6 font-dana text-[10px] text-gray_5">
                پخش
              </p>
            </div>
          </div>
          <div className="flex gap-2  items-center">
            <FollowersCountSvg />
            <div className="flex flex-col h-full justify-between w-14">
              {artistData?.followers_count ? (
                <p className=" font-semibold leading-6 font-dana text-[18px] text-gray_5">
                  {artistData?.followers_count}
                </p>
              ) : (
                <CssShimmer classNames={'w-14 h-6 rounded-[8px]'} />
              )}
              <p className=" font-[400] leading-6 font-dana text-[10px] text-gray_5">
                دنبال کننده
              </p>
            </div>
          </div>
          <div className="flex gap-2  items-center">
            <MusicCountSvg />

            <div className="flex flex-col h-full justify-between w-14">
              {artistData?.tracks_count ? (
                <p className=" font-semibold leading-6 font-dana text-[18px] text-gray_5">
                  {artistData?.tracks_count}
                </p>
              ) : (
                <CssShimmer classNames={'w-14 h-6 rounded-[8px]'} />
              )}

              <p className=" font-[400] leading-6 font-dana text-[10px] text-gray_5">
                آهنگ
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArtistProfileTitleSection;
