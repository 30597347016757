import { Skeleton } from '@mui/material';
import React from 'react'

function ActiveDevicesSkeleton({ width, height, mt = 0, ml = 0,style="!rounded-[12px] lg-1440:!rounded-br12" }) {
    return (
        <Skeleton
          className={`after:!animate-spin-slow  after:!bg-shimmer-gradient !bg-shimmer-bg  shadow-none ${style} `}
          animation="wave"
          sx={{
            ml: ml,
            bgcolor: "rgb(46 46 48 / 100%)",
            animationDuration: "0.8s !important",
            mt: mt,
          }}
          variant="rounded"
          width={width}
          height={height}
        />
      );
}

export default ActiveDevicesSkeleton