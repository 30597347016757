import React,{useEffect}  from "react";
import {addToPlaylistData, downloadData, getIsPlaying, getPlayedPlaylist,} from "../../helper/ReduxSelectorContext.js";
import SongDetailCard from "../../components/playlist/SongDetailCard.jsx";
import {PlayListType} from "../../consts/PlayListType.js";
import {setIsPlaying, setPlayedPlaylist,} from "../../redux/player/PlayMusicSlice.js";
import {useDispatch} from "react-redux";
import {usePlayedTrack} from "../../hooks/usePlayedTrack.js";
import Container from "../../components/PageParentContainer/Container.jsx";
import CommonHeader from "../../components/common/CommonHeader.jsx";
import {Virtuoso} from 'react-virtuoso'
import ProfileRecentlyMp3Placeholder from "../../components/MyMelodify/ProfileRecentlyMp3Placeholder.jsx";
import NoMusicSvg from "../../components/svg/myMelodify/NoMusicSvg.jsx";
import AddToPlaylistModeButton from "../../components/playlist/AddToPlaylistModeButton.jsx";
import {disableListSelectMode} from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import ScrollTopButton from "../../components/common/ScrollTopButton.jsx";

function Download(props) {
    const addToPlaylistDataState = addToPlaylistData()
    const dispatch=useDispatch()
    const {downloadedFiles} = downloadData();
    const collectionObject = {
        id: "0000",
    };
    useEffect(() => {
        return () => {
            dispatch(disableListSelectMode())
        };
    }, []);
    return (
        <Container  header={<CommonHeader  hasChevronNavigateBack={false} title={'دانلود شده ها'}/>}>
            <AddToPlaylistModeButton EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}/>
            <div id='download-page' className=" w-full h-full  overflow-scroll hide-scroll-bar" dir="rtl">
                <ScrollTopButton id={'download-page'}/>
                {downloadedFiles && downloadedFiles.length > 0 ? (
                    <div className="flex flex-col w-full h-full  relative mt-4   px-3">
                        <Virtuoso

                            customScrollParent={document.getElementById('download-page')}
                            data={downloadedFiles}
                            itemContent={(index, item) =>
                                (
                                    <div className='pb-2'>
                                        <SongDetailCard
                                            isSelectMode={addToPlaylistDataState?.listSelectItems?.length > 0}
                                            inPlayer={false}
                                            id={item}
                                            number={false}
                                            index={index}
                                            data={item}
                                            playList={downloadedFiles}
                                            collectionObject={collectionObject}
                                        />
                                    </div>
                                )
                            }
                        />
                    </div>
                ) : (
                    <div className="flex flex-col items-center justify-center w-full h-[80%] gap-2  ">
                        <NoMusicSvg className="w-[70px] h-[70px]" />
                        <p className="text-[14px] text-gray_3  font-dana font-normal  text-center">
                            هنوز آهنگی دانلود نکرده اید
                        </p>
                    </div>
                )}
            </div>
        </Container>
    );
}

export default Download;
