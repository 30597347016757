import * as React from "react";

function VisitCountIcon(props) {
    return (
        <svg
            width={21}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M19.396 5.637C18.104 3.532 15.164 0 10.004 0 4.844 0 1.904 3.532.61 5.637a4.09 4.09 0 000 4.301c1.293 2.105 4.233 5.637 9.393 5.637 5.16 0 8.1-3.532 9.392-5.637a4.09 4.09 0 000-4.301zm-1.42 3.429c-1.11 1.805-3.623 4.842-7.972 4.842-4.35 0-6.862-3.037-7.972-4.842a2.432 2.432 0 010-2.557c1.11-1.805 3.623-4.842 7.972-4.842s6.861 3.034 7.971 4.842a2.432 2.432 0 010 2.557z"
                fill="#E0E0E0"
            />
            <circle cx={10.004} cy={7.787} r={4} fill="#E0E0E0" />
        </svg>
    );
}

export default VisitCountIcon;
