import React from 'react';
import CustomLazyLoadImage from './CustomLazyLoadImage.jsx';

function AbsoluteBackground({ children, image, animation }) {
  return (
    <div
      id="absolute-background"
      className="absolute top-0 left-0 w-full      overflow-hidden   "
    >
      <div className="  w-full aspect-square  z-10 overflow-hidden relative  ">
        <CustomLazyLoadImage
          loadStrategy={'lazy'}
          imageClass={`object-cover block  ${
            animation && 'img-scale-playlist'
          }`}
          image={image}
        />

        <div className="absolute  top-0 right-0 left-0 bottom-0 bg-collection-overlay  h-[100.15%] "></div>
      </div>
    </div>
  );
}

export default AbsoluteBackground;
