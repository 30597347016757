import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useParams } from "react-router";
import SongDetailCard from "../../components/playlist/SongDetailCard";
import EnptySearchSVG from "../../components/svg/search/EnptySearchSVG";
import TicketsSkeleton from "../../components/Tickets/TicketsSkeleton";

import {
  getArtistTracks,
  getMyMelodifyItems,
  getUserLikedTracks,
} from "../../services/ApiClient";
import Loading from "../../components/loading/Loading";

function AllNewestArtistsSong() {
  const { id: artistId } = useParams();
  const [id, setId] = useState();
  const [artistTrackList, setArtistTrackList] = useState(null);
  const [end, setEnd] = useState();
  const [sort, setSort] = useState();
  const [title, setTiltle] = useState();

  const location = useLocation();

  useEffect(() => {
    if (artistQueryData.sort_by) {
      getArtistTracksMutation.mutate();
    }
    setArtistTrackList(getArtistTracksMutation?.data?.data?.result?.tracks);
    setTiltle(location.state.title);
    setSort(location.state.sort);
  }, [sort]);

  const artistQueryData = {
    artist_id: artistId,
    offset: artistTrackList?.length || 0,
    sort_by: sort,
  };
  const getArtistTracksMutation = useMutation(
    () => getArtistTracks(artistQueryData),
    {
      onSuccess: (res) => {
        if (artistTrackList) {
          setArtistTrackList((prevState) => {
            const updatedState = Array.isArray(prevState) ? [...prevState] : [];
            return [...updatedState, ...res?.data?.result?.tracks];
          });

        } else {
          setArtistTrackList(res?.data?.result?.tracks);
        }
        setEnd(res?.data?.result?.end);
      },
    }
  );

  const getNextPage = () => {
    getArtistTracksMutation.mutate();
  };
  return (
    <div
      id="scrollbarDiv"
      dir="rtl"
      className="w-full h-full overflow-scroll hide-scroll-bar">
      <p className="text-[24px] lg-1440:text-f24 fa-format-500 text-yellow px-6 lg-1440:px-r24 mt-4 lg-1440:mt-r16">
        {title}
      </p>
      <div className="flex gap-3 lg-1440:gap-r12 flex-col mt-6 lg-1440:mt-r24 p-6 ">
        {getArtistTracksMutation.isFetching ? (
          <>
            {Array.from(
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                <TicketsSkeleton height="5.27vw" mt="0.27vw" />
              ))
            )}
          </>
        ) : (
          <InfiniteScroll
            dataLength={artistTrackList?.length || 0}
            hasMore={!end}
            loader={
              getArtistTracksMutation.offset === 0 ? (
                <></>
              ) : (
                <div className="mt-4 flex w-full justify-center">
                  <Loading bgColor={"#F2C94C"} />
                </div>
              )
            }
            scrollableTarget="scrollbarDiv"
            next={getNextPage}>
            {artistTrackList?.map((item, index) => (
              <SongDetailCard index={index} number={true} data={item} />
            ))}
          </InfiniteScroll>
        )}

        {!getArtistTracksMutation.isFetching &&
          artistTrackList?.length === 0 && (
            <div className="flex flex-col items-center mt-[88px] lg-1440:mt-r96">
              <div className="h-[120px]  min-[1440px]:h-[8.3333vw]" >
                <EnptySearchSVG />
              </div>
              <p className="text-[18px] lg-1440:text-f18 text-white fa-format-500 mt-8 lg-1440:mt-r32 max-w-[400px] lg-1440:max-w-[27vw] text-center">
                آهنگی وجود ندارد
              </p>
            </div>
          )}
      </div>
    </div>
  );
  return <div>AllNewestArtistsSong</div>;
}

export default AllNewestArtistsSong;
