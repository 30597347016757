import React from 'react'

function ReportFlagSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#F2F2F2"
        d="M3.75 16.5c-.45 0-.75-.3-.75-.75v-12c0-.45.3-.75.75-.75h10.5c.3 0 .6.15.675.45.15.3.075.6-.15.825l-2.85 2.85 2.85 2.85c.225.225.3.525.15.825-.075.3-.375.45-.675.45H4.5v4.5c0 .45-.3.75-.75.75zm.75-6.75h7.95l-2.1-2.1c-.3-.3-.3-.75 0-1.05l2.1-2.1H4.5v5.25z"
      ></path>
    </svg>
  )
}

export default ReportFlagSvg