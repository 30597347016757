import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import PlayerSlice from "./player/PlayerSlice";
import { persistReducer } from "redux-persist";
import DropDownSlice from "./player/DropDownSlice.js";
import loginReducer from "./login/LoginSlice";
import initializeReducer from "./initialize/InitializeSlice";
import indexDb from "redux-persist-indexeddb-storage";
import supportSlice from "./support/SupportSlice.js";
import categoryReducer from "./support/SelectedCategorySlice.js";
import SearchSlice from "./search/SearchSlice.js";
import globalDataSlice from "./globalData/globalDataSlice.js";
import limitationSlice from "./limitation/LimitationSlice.js";
import navigationSlice from "./navigation/NavigationSlice.js";
import AuthErrorSlice from "./autherror/AuthErrorSlice.js";
import playMusicSlice from "./player/PlayMusicSlice.js";
import DialogSlice from "./dialog/DialogSlice";
import RecentlyHeardSlice from "./RecentlyHeard/RecentlyHeardSlice";
import downloadSlice from "./download/downloadSlice.js";
import AdvertiseSlice from "./player/AdvertiseSlice";
import upKeepSlice from "./upKeepSlice.js";
import UploadFileSlice from "./UploadFile/UploadFileSlice";
import AddToPlaylistSlice from "./adToPlaylist/AddToPlaylistSlice.js";
import { ScrollPositionSlice } from './scrollManageStore/scrollManageSlice.js';



const userDataPersistConfig = {
    key: "userDate",
    storage: indexDb("melodify"),
};

const advertisePersistConfig = {
    key: "advertise",
    storage: indexDb("melodify"),
    blacklist: ['haveAdvertise','advertiseData','advertiseStatusCode']
};

const initializeDataPersistConfig = {
    key: "initialize",
    // storage: storage,
    storage: indexDb("melodify"),
};
const CategoryPersistConfig = {
    key: "selectedCategory",
    storage: storage,
};
const playMusicPersistConfig = {
    key: "playMusic",
    storage: storage,
    blacklist: ['isPlaying', 'seekTime', 'weightCount', "swipePlayerStatus", "lyricFullScreenStatus",
        // player clear on refresh
        "playMusic", "duration", "isPlaying", "playList", "playListClone", "queueList", "playedFrom", "playerSeek", "onTrackEnd", "playlistPlayed", "seekBarValue", "HeaderPlayData", "swipePlayerStatus", "lyricFullScreenStatus","playerPaginationData","playerPaginationLoader"

    ]

};
const userLimitationPersistConfig = {
    key: "limitations",
    // storage: storage,
    storage: indexDb("melodify"),
}

const CollectionPersistConfig = {
    key: "collection",
    storage: indexDb("melodify"),
};

const SearchPersistConfig = {
    key: "search",
    storage: indexDb("melodify"),
    blacklist: ["searchQuery"]
};
const DownloadPersistConfig = {
    key: "download",
    storage: indexDb("download_lists"),
    blacklist: ['downloadQueue', 'downloadActive', 'downloadActiveId', 'downloadPercent', 'downloadQualityDialogState', 'temporaryDownloadQueue']
}

const userGlobalDataPersistConfig = {
    key: "collection",
    storage: indexDb("melodify"),
    blacklist: ['limitationDialogData', 'limitationDialogState', 'unfollowDialogState', 'unfollowItemId', 'expireSoonDialog','isDragging','draggingList','openModalList','backClicked','redirectToTelegramOpenState','generatedLink'],
};
const upKeepPersistConfig = {
    key: "upKeep",
    storage: indexDb("upKeep"),
};

export const store = configureStore({
    reducer: {
        player: PlayerSlice,
        dropDown: DropDownSlice,
        user: persistReducer(userDataPersistConfig, loginReducer),
        initialize: persistReducer(initializeDataPersistConfig, initializeReducer),
        support: supportSlice,
        category: persistReducer(CategoryPersistConfig, categoryReducer),
        search: persistReducer(SearchPersistConfig, SearchSlice),
        globalData: persistReducer(userGlobalDataPersistConfig, globalDataSlice),
        limitations: persistReducer(userLimitationPersistConfig, limitationSlice),
        authErrorSlice: AuthErrorSlice,
        navigation: navigationSlice,
        playMusic: persistReducer(playMusicPersistConfig, playMusicSlice),
        RecentlyHeardData: persistReducer(CollectionPersistConfig, RecentlyHeardSlice),
        dialog: DialogSlice,
        download: persistReducer(DownloadPersistConfig, downloadSlice),
        advertise: persistReducer(advertisePersistConfig, AdvertiseSlice),
        upKeep: persistReducer(upKeepPersistConfig, upKeepSlice),
        UploadFile: UploadFileSlice,
        addToPlaylist:AddToPlaylistSlice,
        scrollPosition: ScrollPositionSlice.reducer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
