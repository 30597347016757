import AlbumSearchResultAvatar from "../pages/search/allResultItem/AlbumSearchResultAvatar";
import ArtistSearchResultAvatar from "../pages/search/allResultItem/ArtistSearchResultAvatar";
import PlaylistAndSongSearchResultAvatar from "../pages/search/allResultItem/PlaylistAndSongSearchResultAvatar";

import SearchResultTrack from "../pages/search/allResultItem/SearchResultTrack";
import SearchResultArtist from "../pages/search/allResultItem/SearchResultArtist";
import SearchResultAlbum from "../pages/search/allResultItem/SearchResultAlbum";
import SearchResultPlaylist from "../pages/search/allResultItem/SearchResultPlaylist";
import { CardActionArea } from "@mui/material";


export const SearchModelType = {
  artist: {
    component: (item,fromHistory,index) =>   <SearchResultArtist item={item} fromHistory={fromHistory} index={index}  />,
  },
  track: {
    component: (item,fromHistory,index) => <SearchResultTrack item={item} fromHistory={fromHistory} index={index} /> ,
  },
  album: {
    component: (item,fromHistory,index) => <SearchResultAlbum item={item} fromHistory={fromHistory} index={index} /> ,
  },
  collection: {
    component: (item,fromHistory,index) => <SearchResultPlaylist item={item} fromHistory={fromHistory} index={index}  />
  },
};
