import React, { useState } from "react";
import ChevronSVG from "../../components/svg/common/ChevronSVG";
import { selectCategory } from "../../redux/support/SelectedCategorySlice.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectCategoryBottomSheet from "../../components/support/SelectCategoryBottomSheet";
import CommonHeader from "../../components/common/CommonHeader.jsx";
import TicketsHeader from "../../components/Tickets/TicketsHeader.jsx";
import FolderIconSVG from "../../components/svg/support/FolderIconSVG.jsx";
import OtherIconSVG from "../../components/svg/support/OtherIconSVG.jsx";
import Container from "../../components/PageParentContainer/Container.jsx";
import ShowAnswerSheet from "../../components/support/ShowAnswerSheet.jsx";

function TicketCategory() {
  const activeCategory = useSelector(selectCategory);
  const [isQuestionOpen, setIsQuestionOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [sheetData, setSheetData] = useState(null);
  const navigate = useNavigate();
  const handleSelectCategory = (subCategory) => {
    if (subCategory?.answer) {
      setSheetData(subCategory);
      console.log("sheetData", sheetData);
      openQuestionSheet();
    } else {
      navigateToCreateTicket(subCategory);
    }
  };
  const openCategorySheet = () => {
    setOpenCategory((prevState) => !prevState);
  };
  const openQuestionSheet = () => {
    setIsQuestionOpen(true);
  };
  const navigateToCreateTicket = (subCategory) => {
    navigate("/support/create-ticket", { state: { item: subCategory } });
  };

  return (
      <Container header={<CommonHeader title={"ثبت تیکت"}/>}>
          <TicketsHeader />
          <div
            dir="rtl"
            className="flex flex-col bg-transparent justify-start items-start my-6 mx-4 hide-scroll-bar"
          >
            <SelectCategoryBottomSheet
              open={openCategory}
              setOpen={setOpenCategory}
            />
            <ShowAnswerSheet
              open={isQuestionOpen}
              setOpen={setIsQuestionOpen}
              sheetData={sheetData}
              navigateToCreateTicket={navigateToCreateTicket}
            />
            <p className="font-dana text-gray_4 -mt-1 text-sm mb-2">
              دسته بندی :
            </p>
            <div className="w-full flex flex-col justify-center items-center hide-scroll-bar">
              <div className="w-full h-full flex flex-col">
                <div
                  onClick={openCategorySheet}
                  className="cursor-pointer w-full flex justify-between items-center flex-row border border-gray_4 rounded-[5px] px-4 min-h-[48px]"
                >
                  <div className="flex flex-row items-center">
                    <span className="font-dana text-white text-sm min-[375px]:text-base">
                      {activeCategory?.title}
                    </span>
                  </div>
                  <div className="rotate-[270deg]">
                    <ChevronSVG classList={"w-2"} fill={"#F2C94C"} />
                  </div>
                </div>
                <div className="bg-primary_dark mt-4 p-4">
                  <div>
                    <p className="font-danaBold text-primary text-base inline-flex">
                      <FolderIconSVG classList={"w-[24px] h-[24px] ml-2"} />
                      عنوان تیکت را انتخاب کنید:
                    </p>
                  </div>
                  <ul className="inline-grid justify-items-start">
                    {activeCategory?.faqs.map((item) => (
                      <>
                        {item?.is_other ? (
                          <li
                            onClick={() => {
                              handleSelectCategory(item);
                            }}
                            className="flex items-center cursor-pointer  border border-primary mt-2 bg-gray_2 rounded-3xl py-3 px-4 min-h-[48px]"
                          >
                            <OtherIconSVG
                              classList={"w-[20px] h-[20px] ml-2"}
                            />
                            <span className="inline-flex leading-none font-dana text-white text-xs">
                              {item?.question}
                            </span>
                          </li>
                        ) : (
                          <li
                            onClick={() => {
                              handleSelectCategory(item);
                            }}
                            className="flex items-center cursor-pointer mt-2 bg-gray_2 rounded-3xl py-3 px-4 min-h-[48px]"
                          >
                            <span className="inline-flex font-dana text-white text-xs  leading-5">
                              {item?.question}
                            </span>
                          </li>
                        )}
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </Container>
  );
}

export default TicketCategory;
