import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import hintTrack from '../../assets/dialogs/hintTrack.webp';
import DialogHeader from './DialogHeader.jsx';
import { CardActionArea } from '@mui/material';
import { globalData } from '../../helper/ReduxSelectorContext.js';
import { useDispatch } from 'react-redux';
import { toggleEditDialogHint } from '../../redux/globalData/globalDataSlice.js';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

function UserCollectionHintDialog({}, ref) {
  const { editPlaylistHintDialogShowed } = globalData();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!editPlaylistHintDialogShowed) {
      setOpen(true);
    }
    dispatch(toggleEditDialogHint());
  }, []);
  const closeCustomDialog = () => {
    setOpen(false);
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto font-dana">
          <div className="flex min-h-full items-center justify-center p-4  text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-secondary p-4  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={''}
                  onCloseDialog={closeCustomDialog}
                />
                <div className="px-6 w-[90%] mx-auto flex flex-col items-center mt-4">
                  <p className="text-white text-[22px] text-center mb-4 mt-8 font-semibold">
                    با لمس و نگه داشتن روی هر آهنگ <br />
                    می توانید ترتیب آن را عوض کنید.
                  </p>
                </div>

                <CustomLazyLoadImage
                  image={hintTrack}
                  appendBaseUrl={false}
                  imageClass="w-[90%] h-full mx-auto relative"
                />
                <div className={'flex items-center justify-center mt-6   mb-4'}>
                  <CardActionArea className="mt-4 !h-12 !rounded-full !w-[180px]">
                    <div className="w-[180px] flex items-center justify-center">
                      <button
                        onClick={closeCustomDialog}
                        type="button"
                        className="inline-flex justify-center items-center !h-12 text-white text-base bg-dialogButton-gradient w-full justify-center rounded-full border border-transparent bg-blue-100 px-4 focus:outline-none"
                      >
                        <p className="font-semibold">متوجه شدم</p>
                      </button>
                    </div>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default UserCollectionHintDialog;
