import * as React from "react";

function ToggleFollowSvgIcon({fill}) {
    return (
        <svg
            width={28}
            height={21}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"

        >
            <path
                d="M1 3.684l5.454.085M3.727 1v5.455"
                stroke={fill}
                strokeWidth={2}
                strokeLinecap="round"
            />
            <path
                d="M20.202 13.933h-2.917c-1.722 0-3.113 1.39-3.113 3.084 0 1.663 1.353 3.083 3.133 3.083 1.721 0 3.112-1.39 3.112-3.083V2.175l1.33.724 1.415.77h0a5.859 5.859 0 013.07 5.141v2.14c0 .05.042.1.107.1.066 0 .108-.05.108-.1V8.81a6.056 6.056 0 00-3.178-5.314l-3.067 10.437zm0 0V2.001c0-.024.006-.037.012-.047a.12.12 0 01.095-.054.103.103 0 01.054.015l2.906 1.581-3.067 10.437zm0 1.1v-.9h-2.917a2.893 2.893 0 00-2.898 2.884 2.893 2.893 0 002.898 2.883 2.892 2.892 0 002.917-2.883v-1.984z"
                fill={fill}
                stroke={fill}
                strokeWidth={1.8}
            />
            <path
                d="M1.455 10.09h13.182M1.455 16.455h9.091M9.636 3.728h5"
                stroke={fill}
                strokeWidth={2}
                strokeLinecap="round"
            />
        </svg>
    );
}

export default ToggleFollowSvgIcon;
