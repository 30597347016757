import React from 'react'

function MessageIconSVG() {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 32 32"
      >
        <g fill="#F2C94C" clipPath="url(#clip0_74_5162)">
          <path d="M25.051 25.817c4.8-5.004 4.636-12.951-.367-17.751a12.555 12.555 0 00-8.71-3.496C9.041 4.56 3.412 10.174 3.402 17.108a12.555 12.555 0 003.496 8.709L3.63 30.17a1.143 1.143 0 001.829 1.372l3.166-4.222a12.48 12.48 0 0014.697 0l3.166 4.222a1.143 1.143 0 101.829-1.372l-3.267-4.354zm-9.076 1.611c-5.679-.006-10.28-4.608-10.287-10.286 0-5.68 4.606-10.286 10.287-10.286 5.68 0 10.286 4.605 10.286 10.286 0 5.681-4.606 10.286-10.286 10.286zM9.73 1.673a5.715 5.715 0 00-8.082 8.08c.446.447 1.17.447 1.616 0L9.729 3.29a1.143 1.143 0 000-1.616zM2.593 7.19a3.429 3.429 0 014.572-4.572L2.594 7.191zM30.302 1.673a5.714 5.714 0 00-8.08 0 1.143 1.143 0 000 1.616l6.464 6.464c.446.447 1.17.447 1.616 0a5.714 5.714 0 000-8.08zm-.943 5.521l-.003-.002-4.571-4.571a3.502 3.502 0 013.903.67 3.429 3.429 0 01.67 3.903z"></path>
          <path d="M15.976 9.142c-.631 0-1.143.511-1.143 1.143v6.476l-3.2 4.267a1.143 1.143 0 001.829 1.371l3.428-4.571c.149-.198.229-.439.229-.686v-6.857c0-.632-.512-1.143-1.143-1.143z"></path>
        </g>
        <defs>
          <clipPath id="clip0_74_5162">
            <path fill="#fff" d="M0 0H32V32H0z"></path>
          </clipPath>
        </defs>
      </svg>
  )
}

export default MessageIconSVG