import React from 'react';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

function PlansBackground({ children, image }) {
  return (
    <div className=" rounded-b-[30px] bg-primary_dark pb-5 shadow-plans ">
      <div className="relative w-full h-[50%] rounded-b-[30px] ">
        <CustomLazyLoadImage image={image} />
        <div className="absolute   top-0 right-0 left-0 bottom-0  h-full bg-plan-background "></div>
        {children}
      </div>
    </div>
  );
}

export default PlansBackground;
