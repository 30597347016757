import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useRef } from "react";
import {
  getAdvLimitationData,
  getAdvertiseData,
  getAdvertiseStatusCode,
  getEmitAdvertise,
} from "../../helper/ReduxSelectorContext";
import { downloadTrack, getAdvertise } from "../../services/ApiClient";
import { createAdvertiseCdnLink } from "../../helper/createMusicCdnLink";
import FileSaver from "file-saver";
import { useDispatch } from "react-redux";
import {
  getDownloadedAdvAsync,
  removeDownloadedAdv,
  storeAdvFileToDB,
} from "../../indexDB/dbQuery";
import { setWaitForAdv } from "../../redux/player/AdvertiseSlice";

function AdvertiseGlobalComponent() {
  const advData = getAdvertiseData();
  const isMounted = useRef(false);
  const isMountedAdvertise = useRef(false);
  const emitAdve = getEmitAdvertise();
  const dispatch = useDispatch();
  const advLimitationData = getAdvLimitationData();
  const adveriseStarusCode = getAdvertiseStatusCode();
  const getAdvMutation = useMutation(() =>
    // getAdvertise(advLimitationData?.advertise_type)
    getAdvertise(adveriseStarusCode)
  );

  useEffect(() => {
    if (isMounted.current) {
      startDownloadAdvData();
    } else {
      isMounted.current = true;
    }
  }, [advData]);

  const startDownloadAdvData = () => {
    if (advData) {
      handleDownloadAdvData();
    }
  };

  const handleDownloadAdvData = async () => {
    try {
      const DownloadedAdvData = await getDownloadedAdvAsync();
      //store each advertise track to store
      advData?.map((item, index) => {
        const advUrl = item?.ads_track;
        downloadTrack(createAdvertiseCdnLink(advUrl)).then((response) => {
          const blob = new Blob([response.data], { type: "audio/mp3" });
          // not store data if have
          if (DownloadedAdvData && DownloadedAdvData[0]) return;
          storeAdvFileToDB(index, blob);
          dispatch(setWaitForAdv({ waitForAdv: false }));
        });
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (isMountedAdvertise.current) {
      if (getAdvMutation.isLoading) return;
      getAdvMutation.mutate();
    } else {
      isMountedAdvertise.current = true;
    }

    return () => {
      removeDownloadedAdv();
    };
  }, [emitAdve]);

  return <div></div>;
}

export default AdvertiseGlobalComponent;
