import { Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { convertTime } from "../../helper/ConvertPlayerTIme";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPlayerSeek,
  selectSeekTime,
  setSeekTime,
} from "../../redux/player/PlayMusicSlice";
import { IsIosDevice } from "../../helper/CheckDevice";
function PlayerSeekBar({ duration, playerRef,seekTime }) {
 
  const [canChangeSeek, setCanChangeSeek] = useState(true);
  const dispatch = useDispatch();
  const [playerSeekBar, setPlayerSeekBar] = useState(0);
  const seekFromLyric = useSelector(selectPlayerSeek);
  const handleOnChangeSeekCommitted = (event, value) => {
    if (IsIosDevice() && event?.type === "mousedown") {
      return;
    }
    event.preventDefault();
    dispatch(setSeekTime({ seekTime: value }));
    playerRef?.current?.player?.seekTo(value);
    setCanChangeSeek(true);
  };

  const handleOnChangeSeek = (value) => {
    setPlayerSeekBar(value);
    setCanChangeSeek(false);
  };
  useEffect(() => {
    playerRef?.current?.player?.seekTo(seekFromLyric);
    playerRef?.current?.player?.player?.play();
  }, [seekFromLyric]);

  useEffect(() => {
    if (canChangeSeek) {
      console.log("useEffect Change SeekBar",seekTime);
      setPlayerSeekBar(seekTime);
    }
  }, [seekTime, canChangeSeek]);

  return (
    <>
      <div class="flex flex-col items-center w-full rounded-full gap-2 ">
        {/* <Slider value={playerSeekBar}  min="0"  step={0.001}  max={duration} defaultValue={50} onChange={( value) => handleOnChangeSeekCommitted(value?.target.value)}/> */}
        {/* <input className="w-full bg-transparent" min="0"   step={0.001} max={duration} color="#000000" value={playerSeekBar} type="range"  onChange={( value) => handleOnChangeSeekCommitted(value?.target.value)}/> */}
        <Slider
          aria-label="time-indicator"
          className="!w-full !h-[3px] !m-0 !px-0 !py-[5px] !fix-renge"
          value={playerSeekBar}
          min={0}
          // step={0.001}
          disableSwap
          max={duration}
          onChangeCommitted={(_, value) =>
            handleOnChangeSeekCommitted(_, value)
          }
          onChange={(_, value) => handleOnChangeSeek(value)}
          sx={{
            color: "#F2C94C",
            height: 4,
            "& .MuiSlider-thumb": {
              width: 13,
              height: 13,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:hover, &.Mui-focusVisible": {
                boxShadow: `0px 0px 0px 8px rgb(242, 201, 76 , 16%)`,
              },
              "&.Mui-active": {
                width: 16,
                height: 16,
                boxShadow: `0px 0px 0px 8px rgb(242, 201, 76 , 16%)`,
              },
            },
            "& .MuiSlider-rail": {
              backgroundColor: "#303030",
              opacity: 1,
            },
          }}
        />
        <div className="w-full flex flex-row justify-between items-center">
          <span class="fa-format-400 text-[14px] lg-1920:text-f10 text-white leading-[24px]  ">
            {convertTime(playerSeekBar)}
          </span>

          <span class="fa-format-400 text-[14px] lg-1920:text-f10 text-white leading-[24px]  ">
            {convertTime(duration)}
          </span>
        </div>
      </div>
    </>
  );
}

export default React.memo(PlayerSeekBar);
