import React from 'react';

function ArtistProfilePlaceHolderSvg(props) {
    return (
        <svg
            className="w-full h-full absolute top-0 z-[-1]"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 228 228"
        >
            <path
                fill="#EB5757"
                d="M178.556 220.314a.624.624 0 00.89 0l7.996-8.101c3.334-3.378.965-9.213-3.777-9.213-2.848 0-4.154 2.093-4.664 2.483-.511-.392-1.81-2.483-4.663-2.483-4.727 0-7.126 5.82-3.776 9.213l7.994 8.101z"
            ></path>
            <path
                stroke="#EB5757"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M78 105v5.333m0 5.334h.013m-2.293-17.52L64.427 117a2.666 2.666 0 002.28 4h22.586a2.666 2.666 0 002.28-4L80.28 98.147a2.666 2.666 0 00-4.56 0z"
            ></path>
            <path fill="#303036" d="M0 0H228V228H0z"></path>
            <path
                fill="#4C4C50"
                d="M112.351 112.333l-6.249 6.249a2.344 2.344 0 003.314 3.314l6.249-6.249a2.344 2.344 0 00-3.314-3.314z"
            ></path>
            <path
                fill="#4C4C50"
                d="M148.049 108.01c7.945-7.962 7.931-20.096-.015-28.043-7.992-7.992-20.196-7.92-28.059 0-4.588 4.62-6.437 10.838-5.61 16.784L76.548 136.83a9.333 9.333 0 00.213 13.035l1.374 1.375a9.335 9.335 0 0013.042.207l40.072-37.811c6.4.889 12.705-1.412 16.8-5.626zm-3.329-24.729c5.542 5.543 6.056 13.641 1.54 19.657L125.063 81.74c6.02-4.518 14.117-3.998 19.657 1.541zm-56.754 64.751a4.666 4.666 0 01-6.517-.106l-1.375-1.375a4.664 4.664 0 01-.111-6.511l35.889-38.036a19.824 19.824 0 0010.145 10.144l-38.03 35.884zm35.323-43.32c-5.344-5.345-5.853-13.722-1.528-19.645l21.173 21.173c-5.923 4.325-14.3 3.816-19.645-1.528z"
            ></path>
        </svg>
    );


}

export default ArtistProfilePlaceHolderSvg;
