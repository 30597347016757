import React from "react";
import LyricRequestGlobal from "./LyricRequestGlobal";
import GlobalDownload from "../download/GlobalDownload.jsx";
import SearchGlobalComponent from "./SearchGlobalComponent";
import AdvertiseGlobalComponent from "./AdvertiseGlobalComponent";
import GlobalUserCollection from "../playlist/GlobalUserCollection.jsx";
import PushNotificationGlobalComponent from "./PushNotificationGlobalComponent";
import UpdateCollectionDialog from "../dialogs/UpdateCollectionDialog.jsx";

function GlobalComponent() {

    return <>
        <GlobalUserCollection/>
        <LyricRequestGlobal/>
        <GlobalDownload/>
        {/* <SearchGlobalComponent/> */}
        <AdvertiseGlobalComponent/>
        <PushNotificationGlobalComponent/>
    </>;
}

export default GlobalComponent;
