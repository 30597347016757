import React from 'react'

function PlayListSearchIcon() {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5907 1.13711L9.67228 0.0827069C9.2302 -0.16019 8.68785 0.16101 8.68785 0.667291V8.02277H7.35572C5.89527 8.02277 4.70703 9.21416 4.70703 10.6785C4.70703 12.1161 5.86341 13.3342 7.36905 13.3342C8.8295 13.3342 10.0177 12.1428 10.0177 10.6785C10.0177 10.214 10.0177 2.23284 10.0177 1.79305L10.9516 2.30645C12.0105 2.88861 12.6685 4.00013 12.6685 5.20732V6.63432C12.6685 7.0024 12.9662 7.30102 13.3334 7.30102C13.7007 7.30102 13.9984 7.0024 13.9984 6.63432V5.20732C13.9984 3.51295 13.0758 1.95348 11.5907 1.13711ZM8.68768 10.6785C8.68768 11.3881 8.12058 12.0008 7.35572 12.0008C6.62861 12.0008 6.03692 11.4075 6.03692 10.6785C6.03692 9.94945 6.62861 9.35618 7.35572 9.35618H8.68768V10.6785Z" fill="#F2C94C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.9516 2.30645C12.0105 2.88861 12.6685 4.00013 12.6685 5.20732V6.63432C12.6685 7.0024 12.9662 7.30102 13.3334 7.30102C13.7007 7.30102 13.9984 7.0024 13.9984 6.63432V5.20732C13.9984 3.51295 13.0758 1.95348 11.5907 1.13711L9.67228 0.0827069C9.2302 -0.16019 8.68785 0.16101 8.68785 0.667291V8.02277H7.35572C5.89527 8.02277 4.70703 9.21416 4.70703 10.6785C4.70703 12.1161 5.86341 13.3342 7.36905 13.3342C8.8295 13.3342 10.0177 12.1428 10.0177 10.6785V1.79305L10.9516 2.30645ZM9.4093 0.570711C9.40928 0.570699 9.40932 0.570723 9.4093 0.570711C9.32735 0.525745 9.238 0.589908 9.238 0.667291V8.57836H7.35572C6.19537 8.57836 5.25718 9.52477 5.25718 10.6785C5.25718 11.8116 6.16961 12.7786 7.36905 12.7786C8.5294 12.7786 9.46759 11.8322 9.46759 10.6785V0.858027L11.2146 1.81848C12.4485 2.49683 13.2187 3.79439 13.2187 5.20732V6.63432C13.2187 6.69188 13.2664 6.74543 13.3334 6.74543C13.4005 6.74543 13.4482 6.69188 13.4482 6.63432V5.20732C13.4482 3.71848 12.6376 2.34518 11.3278 1.62512M11.3278 1.62512L9.4093 0.570711L11.3278 1.62512ZM9.23783 8.80059V10.6785C9.23783 11.6902 8.42902 12.5564 7.35572 12.5564C6.32851 12.5564 5.48677 11.7181 5.48677 10.6785C5.48677 9.63884 6.32851 8.80059 7.35572 8.80059H9.23783ZM7.35572 12.0008C8.12058 12.0008 8.68768 11.3881 8.68768 10.6785V9.35618H7.35572C6.62861 9.35618 6.03692 9.94945 6.03692 10.6785C6.03692 11.4075 6.62861 12.0008 7.35572 12.0008Z" fill="#F2C94C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M-4.85718e-08 2.13372C-2.17463e-08 1.82687 0.246309 1.57813 0.550146 1.57813L6.88783 1.57813C7.19167 1.57813 7.43798 1.82687 7.43798 2.13372C7.43798 2.44056 7.19167 2.68931 6.88783 2.68931L0.550146 2.68931C0.246309 2.68931 -7.53972e-08 2.44056 -4.85718e-08 2.13372Z" fill="#F2C94C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M-4.85718e-08 4.79973C-2.17463e-08 4.49289 0.246309 4.24414 0.550146 4.24414L6.88783 4.24414C7.19167 4.24414 7.43798 4.49289 7.43798 4.79973C7.43798 5.10658 7.19167 5.35532 6.88783 5.35532L0.550146 5.35532C0.246309 5.35532 -7.53972e-08 5.10658 -4.85718e-08 4.79973Z" fill="#F2C94C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M-4.85719e-08 7.46672C-2.17464e-08 7.15988 0.246309 6.91113 0.550146 6.91113L4.77527 6.91113C5.07911 6.91113 5.32542 7.15988 5.32542 7.46672C5.32542 7.77357 5.07911 8.02232 4.77527 8.02232L0.550146 8.02232C0.246309 8.02232 -7.53974e-08 7.77357 -4.85719e-08 7.46672Z" fill="#F2C94C"/>
</svg>

  )
}

export default PlayListSearchIcon