import { CheckVideoPermision } from './CheckVideoPermision';

export function GetPlayerRef() {
  const IsCanUseVideoForplay = CheckVideoPermision();

  const getPlayer = () => {
    const mainPlayer = document.querySelector(
      `#playerDDD ${IsCanUseVideoForplay() ? 'video' : 'audio'}`,
    );
    return mainPlayer;
  };
  return [getPlayer];
}
