import React from 'react';
import SongCardDemoSection from './SongCardDemoSection.jsx';
import SongCardButtonsSection from './SongCardButtonsSection.jsx';

function SongCardActionSection({ item,handlePlayMusic,isSelected,isInQueue,inPlayer,isSelectMode }) {

  return (
    <>
      {item?.is_demo ? (
        <SongCardDemoSection item={item}  handlePlayMusic={handlePlayMusic} />
      ) : (
        <SongCardButtonsSection isSelected={isSelected} isSelectMode={isSelectMode} inPlayer={inPlayer} item={item} isInQueue={isInQueue} />
      )}
    </>
  );
}

export default React.memo(SongCardActionSection);
