import { Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { convertTime } from "../../helper/ConvertPlayerTIme";
import { useDispatch, useSelector } from "react-redux";
import { selectSeekTime, setSeekTime } from "../../redux/player/PlayMusicSlice";
import { getTrackDuration } from "../../helper/ReduxSelectorContext";
import { GetPlayerRef } from "../../helper/GetPlayerRef";
function FullLyricSeekbar() {
  let seekTime = useSelector(selectSeekTime);
  const duration = getTrackDuration();
  const [canChangeSeek, setCanChangeSeek] = useState(true);
  const dispatch = useDispatch();
  // const mainPlayer = document.querySelector('#playerDDD video');
  const [getPlayer] = GetPlayerRef();
  const mainPlayer = getPlayer();
  const [lyricSeekbar, setlyricSeekbar] = useState(0);
  const handleOnChangeSeekCommitted = (value) => {
    dispatch(setSeekTime({ seekTime: value }));
    if (mainPlayer) mainPlayer.currentTime = value;
    setCanChangeSeek(true);
  };

  const handleOnChangeSeek = (value) => {
    setlyricSeekbar(value);
    setCanChangeSeek(false);
  };
  useEffect(() => {
    if (canChangeSeek) {
      setlyricSeekbar(seekTime);
    }
  }, [seekTime, canChangeSeek]);

  return (
    <>
      <div class="h-10 items-center w-full rounded-full gap-2  ">
        <Slider
          className="!w-full !h-[3px] !m-0 !px-0 border-none !py-[10px]  "
          value={lyricSeekbar}
          min={0}
          step={0.01}
          aria-label="Small"
          max={duration}
          onChangeCommitted={(_, value) => handleOnChangeSeekCommitted(value)}
          onChange={(_, value) => handleOnChangeSeek(value)}
          sx={{
            color: "#E0E0E0",
            height: 4,
            "& .MuiSlider-thumb": {
              width: 13,
              height: 13,

              boxShadow: "none",
              "&:hover, &.Mui-focusVisible": {
                boxShadow: "none",
              },
            },
            "& .MuiSlider-rail": {
              backgroundColor: "#000000",
              opacity: 1,
              height: 2.8,
            },
          }}
        />
        <div className="w-full flex flex-row justify-between items-center">
          <span class="fa-format-400 text-[14px] lg-1920:text-f10 text-white leading-[24px]  ">
            {convertTime(lyricSeekbar)}
          </span>

          <span class="fa-format-400 text-[14px] lg-1920:text-f10 text-white leading-[24px]  ">
            {convertTime(duration)}
          </span>
        </div>
      </div>
    </>
  );
}

export default React.memo(FullLyricSeekbar);
