// db.js
import Dexie from 'dexie';

export const db = new Dexie('download');
db.version(4).stores({
    friends: '++id', // Primary key and indexed props,
    downloadedFiles: 'trackId , blob',// Define an object store named "files" with "id", "name", and "blob" fields
    advFiles: 'id,blob',
});


export const oldPwaDB = new Dexie('downloads');
oldPwaDB.version(10).stores({
    dlTracks: "id,item,file", 
  });


  export const notificationDB = new Dexie("notification");
  notificationDB.version(1).stores({ notif: "++id,item" });