import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CompleteSongList from "../../components/ArtistProfile/CompleteSongList";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getArtistData, getArtistTracks } from "../../services/ApiClient.js";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useParams } from "react-router-dom";
import { getLimitations } from "../../helper/ReduxSelectorContext";
import { setHeaderTrackList } from "../../redux/player/PlayMusicSlice";
import ScrollTopButton from "../../components/common/ScrollTopButton.jsx";
import SortSvg from "../../components/svg/playList/SortSvg.jsx";
import Container from "../../components/PageParentContainer/Container.jsx";
import CollectionCategoryHeader from "../../components/playlist/CollectionCategoryHeader.jsx";
import ArtistProfileTitleSection from "../../components/artists/ArtistProfileTitleSection.jsx";
import ArtistProfileFollowArtistSection from "../../components/artists/ArtistProfileFollowArtistSection.jsx";
import ArtistProfileBackground from "../../components/artists/ArtistProfileBackground.jsx";
import ErrorReportDropDown from "../../components/artists/ErrorReportDropDown.jsx";
import { setSeenArtist } from "../../redux/RecentlyHeard/RecentlyHeardSlice";
import SongListLoading from "../../components/loading/SongListLoading.jsx";
import { sortTypesArtist } from "../../consts/MenuItemsConsts";

function ArtistsProfile() {
  //todo:share artist didn't exist yet
  const { id: artistId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const limitations = getLimitations();
  const artistObject = location.state?.artistObject;
  const [sortType, setSortType] = useState(sortTypesArtist[0].type);
  const [artistData, setArtistData] = useState("");
  const artistQueryData = {
    artist_id: artistId,
    sort_by: sortType,
  };
  const getArtistDataQuery = useQuery(
    ["getArtistData" + artistId],
    () => getArtistData(artistId),
    {
      onSuccess: (res) => {
        setArtistData(res?.data?.result?.artist);
        dispatch(setSeenArtist({ seen_Artist: res?.data?.result?.artist }));
      },
      refetchOnWindowFocus: false,
    }
  );

  const changeSort = (type) => {
    setSortType(type);
  };
  const hasSortLimit = () => {
    if (limitations?.artist_sort?.enable) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setArtistData(getArtistDataQuery?.data?.data?.result?.artist);
  }, [getArtistDataQuery?.data]);

  useEffect(() => {
    changeSort(sortTypesArtist[0].type);
    const pageElement = document.getElementById("artist-page");
    pageElement.scrollTop = 0;
  }, [artistId]);
  useEffect(() => {
    dispatch(
      setHeaderTrackList({ profileObject: location.state?.artistObject })
    );
  }, [location.state?.artistObject]);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ["getArtistTrackList" + sortType + artistId],
      ({ pageParam = 0 }) =>
        getArtistTracks({
          artist_id: artistId,
          sort_by: sortType,
          offset: pageParam,
        }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const allData = allPages?.flatMap(
            (page) => page?.data?.result?.tracks
          );
          return lastPage?.data?.result?.end ? false : allData?.length;
        },
        refetchOnWindowFocus: false,
        enabled: !getArtistDataQuery?.isLoading,
      }
    );
  const tracksList = data?.pages?.flatMap((page) => page?.data?.result?.tracks);
  const reFetchData = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <Container>
      <div
        dir="rtl"
        id="artist-page"
        className="w-full h-full overflow-scroll hide-scroll-bar flex flex-col pt-[56px] gap-6  overflow-visible pb-10  "
      >
        <CollectionCategoryHeader
          extraFunction={<ErrorReportDropDown data={artistData} />}
          startAdornmentClassName="!h-full !py-2"
          hasSearch={false}
          title={artistObject?.name}
          backgroundId="title-section"
          pageId="artist-page"
        />
        <ArtistProfileBackground artistData={artistObject} />
        <ArtistProfileTitleSection
          artistData={artistData}
          artistObject={artistObject}
        />
        <ArtistProfileFollowArtistSection artistId={artistId} />
        <InfiniteScroll
          className="px-4"
          next={reFetchData}
          hasMore={hasNextPage}
          loader={
            <div className="mt-4 flex w-full justify-center">
              <SongListLoading />
            </div>
          }
          scrollableTarget="artist-page"
          dataLength={tracksList?.length || 0}
        >
          <div className="relative">
            <CompleteSongList
              scrollId={"artist-page"}
              className="!overflow-visible"
              sortLimit={hasSortLimit()}
              onChangeSort={changeSort}
              list={tracksList}
              pageId={artistId}
              title={"همه آهنگ ها"}
              titleUnderLine={true}
              sortTypes={sortTypesArtist}
              apiUrl="/getArtistTracks"
              requestPayload={{
                artist_id: artistId,
                sort_by: sortType,
              }}
              endOfPagination={!hasNextPage}
              sortButton={(activeSortText) => (
                <div className="bg-[#373737] shadow px-5 py-3 flex gap-2 rounded-[30px] shadow-sort-button">
                  <div className="flex w-[22px] h-[16px]  ">
                    <SortSvg />
                  </div>
                  <p className="text-primary font-dana text-[12px] font-medium">
                    {activeSortText}
                  </p>
                </div>
              )}
              loading={isLoading && !tracksList}
              collectionObject={artistObject}
            />
          </div>
        </InfiniteScroll>
        <ScrollTopButton id={"artist-page"} />
      </div>
    </Container>
  );
}

export default ArtistsProfile;
