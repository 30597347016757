export const convertPlayListObject = (item,artist) => {
    const collection=item?.collections?.data?item?.collections?.data:item?.collections
    const newObject = {
        collection_meta_data: {
            title:artist?item?.header: item?.title,
            show_more:artist?true: item?.show_more,
            title_color:'#F2C94C',
            subtitle:item?.subtitle,
            subtitle_color:'#B0B0B5',
            show_more_text:'دیدن همه',
            type:item?.type,
            action: {
                target_type: "collection_category",
                children:item,
                subtitle:item?.subtitle,
                subtitle_color:'#B0B0B5'
            },
        },
        collection_items: [

            {
                collection_items_meta_data: {
                    artistView:artist?true:false,
                },
                collections: artist?collection?.slice(0,20):collection
            }
        ],
    }

    return newObject


}
