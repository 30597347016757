import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPlayerSeek,
  selectSeekTime,
  setPlayerSeek,
  setSeekTime,
} from "../../redux/player/PlayMusicSlice";
import {
  getIsPlaying,
  getLyricFullScreen,
  getPlayerMusic,
  getPlayerSeekTime,
} from "../../helper/ReduxSelectorContext";
import { dispatchIsPlaying } from "../../redux/globalstore/GlobalStore";
import {setLanguageDirection} from "../../helper/LanguageDetection.js";

const LyricItem = ({ item, index, lyric, scroll }) => {
  const ref = useRef(null);
  let playedTrack = getPlayerMusic();
  const isLyricFullScreen = getLyricFullScreen();
  const dispatch = useDispatch();
  let isPaying = getIsPlaying();
  let PlayerSeekTime = getPlayerSeekTime();
  const handleSeekToText = (time) => {
    if(playedTrack?.is_demo)return
    if(isLyricFullScreen){

      dispatch(setSeekTime({ seekTime: time }));
      dispatch(setPlayerSeek({ playerSeek: time }));
      dispatchIsPlaying(true);
    }
  };

  // useEffect(() => {
  //   if (!scroll) return;
  //   if (PlayerSeekTime > item?.time && ref.current) {
  //     ref.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //       inline: "center",
  //     });
  //   }
  // }, [PlayerSeekTime]);

  // useEffect(() => {
  //   if (!scroll) return;
  //   if (isPaying) return;
  //   let time = setTimeout(() => {
  //     if (PlayerSeekTime > item?.time && ref.current) {
  //       ref.current.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //         inline: "center",
  //       });
  //     }
  //     return () => {
  //       clearTimeout(time);
  //     };
  //   }, 10);
  // }, []);

  const checkLastItem = () => {
    if (PlayerSeekTime < lyric?.sync[index + 1]?.time) return true;
    else if (lyric?.sync[index + 1]?.time == undefined) return true;
  };

  return (
    <div
      ref={ref}
      key={index}
      dir={setLanguageDirection(item?.text)}
      onClick={() => handleSeekToText(item?.time)}
      dangerouslySetInnerHTML={{ __html: item?.text }}
      className={`text-[16px]  transition-all ease-in-out duration-75 text-center py-2 cursor-pointer font-kalameh font-[500] ${
        PlayerSeekTime > parseInt(item?.time,10)
          ? //  && checkLastItem()
            "text-white played-sync"
          : "text-black_2 "
      } `}
    ></div>
  );
};

export default LyricItem;
