import { useCallback } from 'react';
import CommonHeader from '../../components/common/CommonHeader';
import GiftForm from '../../components/gift/GiftForm';
import Container from '../../components/PageParentContainer/Container';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { mainToast } from '../../helper/ToastsEmitter';
import { useMutation } from '@tanstack/react-query';
import { updateGiftApi } from '../../services/ApiClient';
import LoaderContainer from '../../components/loading/LoaderContainer';

function EditGift() {
  const location = useLocation();
  const { gift_id } = useParams();
  const navigate = useNavigate();

  const submitActiveGiftCode = useMutation(
    giftData => updateGiftApi(giftData),
    {
      onSuccess: res => {
        if (res?.data?.done) navigate('/manage-gift', { replace: true });
        else if (res?.data?.error?.message)
          mainToast(res?.data?.error?.message);
      },
    },
  );

  const submitData = useCallback(data => {
    const { name, description } = data;
    if (
      data.name?.trim() === location.state?.name?.trim() &&
      data.description?.trim() === location.state?.description?.trim()
    )
      mainToast('!تغییری اعمال نشده است');
    else submitActiveGiftCode.mutate({ gift_id, name, description });
  }, []);

  return (
    <Container
      style="bg-primary_dark p-4"
      header={<CommonHeader title="ویرایش اشتراک هدیه" />}
    >
      <LoaderContainer loading={submitActiveGiftCode?.isLoading}>
        <GiftForm
          description={location.state?.description}
          name={location.state?.name}
          isEditPage={true}
          onSubmit={submitData}
        />
      </LoaderContainer>
    </Container>
  );
}

export default EditGift;
