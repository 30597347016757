import { memo } from "react";

function CrownIconSvg({ rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="19"
      fill="none"
      viewBox="0 0 30 19"
      {...rest}
    >
      <path
        fill="#F2C94C"
        d="M27.903 17.342H2.343v.926h25.56v-.926zM27.697 16.43l1.939-9.833h-.57s-.919 5.816-4.631 5.2c-3.4-.569-2.389-6.127-2.389-6.127h-.794s.192 4.513-2.646 4.348c-3.699-.206-3.13-8.516-3.13-8.516h-.662s1.152 8.119-2.547 8.33c-2.865.16-3.255-4.162-3.255-4.162h-.788s1.026 5.24-2.375 5.81c-3.739.615-4.672-4.883-4.672-4.883H.608l1.94 9.832h25.15z"
      ></path>
      <path
        fill="#F2C94C"
        d="M16.469 1.323a1.323 1.323 0 11-2.647 0 1.323 1.323 0 012.647 0zM22.61 5.38a.873.873 0 11-1.747 0 .873.873 0 011.746 0zM9.495 5.38a.873.873 0 11-1.747 0 .873.873 0 011.747 0zM30 6.657a.873.873 0 11-1.747 0 .873.873 0 011.747 0zM.873 7.53a.873.873 0 100-1.747.873.873 0 000 1.747z"
      ></path>
    </svg>
  );
}

export default memo(CrownIconSvg);
