import React from "react";

function FireFlameSVG({fill="#B0B0B5"}) {
  return (
    <svg
      class="w-full h-full"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2419 5.713C12.0923 5.46543 11.7959 5.34777 11.5173 5.42548C11.2385 5.5032 11.0458 5.75698 11.0458 6.04635C11.0458 6.83304 10.4058 7.47288 9.61929 7.47288C8.83259 7.47288 8.19276 6.83304 8.19276 6.04635V0.64454C8.19276 0.383874 8.03565 0.148889 7.79479 0.04902C7.55393 -0.0506809 7.27665 0.00454067 7.09236 0.188836C7.02018 0.261178 5.30127 1.98983 3.56003 4.60186C2.53331 6.14202 1.71388 7.66976 1.12474 9.14261C0.378494 11.0084 0 12.7948 0 14.4518C0 18.6139 3.38614 22 7.54806 22C11.7102 22 15.0963 18.6139 15.0963 14.4518C15.0963 11.7877 14.1359 8.84754 12.2419 5.713V5.713ZM7.54806 20.7109C4.09697 20.7109 1.28906 17.903 1.28906 14.4518C1.28906 11.0546 3.09962 7.62058 4.61863 5.33787C5.44192 4.10067 6.27159 3.05801 6.90353 2.31646V6.04635C6.90353 7.54371 8.12176 8.76194 9.61912 8.76194C10.5346 8.76194 11.3458 8.30674 11.8379 7.61068C13.1454 10.0728 13.8072 12.3693 13.8072 14.4518C13.8072 17.903 10.9995 20.7109 7.54806 20.7109Z"
        fill={fill}
      />
    </svg>
  );
}

export default FireFlameSVG;
