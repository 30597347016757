import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useInfiniteQuery} from "@tanstack/react-query";
import {getNewTracks} from "../../services/ApiClient";
import SongDetailCard from "../../components/playlist/SongDetailCard.jsx";
import Container from "../../components/PageParentContainer/Container.jsx";
import CommonHeader from "../../components/common/CommonHeader.jsx";
import InfiniteScroll from "react-infinite-scroll-component";
import SongsDetailCardShimmer from "../../components/Tickets/SongsDetailCardShimmer.jsx";
import {Virtuoso} from "react-virtuoso";
import SongListLoading from "../../components/loading/SongListLoading.jsx";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperTabs from "./SwiperTabs.jsx";
import HotTracksSVG from "../../components/svg/newestSongs/HotTracksSVG.jsx";
import AllNewTracksSVG from "../../components/svg/newestSongs/AllNewTracksSVG.jsx";
import {isDesktop} from "react-device-detect";
import {disableListSelectMode} from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import {addToPlaylistData} from "../../helper/ReduxSelectorContext.js";
import {useDispatch} from "react-redux";
import AddToPlaylistModeButton from "../../components/playlist/AddToPlaylistModeButton.jsx";
import ScrollTopButton from "../../components/common/ScrollTopButton.jsx";

function NewestSongs() {
    const [HasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
        setHasLoaded(true);
    }, []);

    const location = useLocation();
    const dispatch = useDispatch()
    const addToPlaylistDataState = addToPlaylistData()
    const params = new URLSearchParams(location.search);
    const languages = params.get("language");
    const {
        isLoading: getNewSongIsLoading,
        isFetchingNextPage: getNewSongIsFetchingNextPage,
        fetchNextPage: getNewSongIsFetchNextPage,
        hasNextPage: getNewSongHasNextPage,
        data: getNewSongData
    } = useInfiniteQuery([`getNewestSongsInfiniteQuery${languages}`], ({pageParam = 0}) => getNewTracks({
            featured: 0,
            language: languages,
            offset: pageParam,
        }),
        {
            getNextPageParam: (lastPage, allPages) => {
                const allData = allPages?.flatMap((page) => page.data.result.tracks);
                return lastPage?.data?.result?.end ? false : allData?.length;
            },
            refetchOnWindowFocus: false
        })
    const getNewSongTracks = getNewSongData?.pages?.flatMap((page) => page.data.result.tracks);
    const getNewSongNextPage = () => {
        if (getNewSongHasNextPage && !getNewSongIsFetchingNextPage) {
            getNewSongIsFetchNextPage();
        }
    };
    const {
        isLoading: getMonthNewSongIsLoading,
        isFetchingNextPage: getMonthNewSongIsFetchingNextPage,
        fetchNextPage: getMonthNewSongIsFetchNextPage,
        hasNextPage: getMonthNewSongHasNextPage,
        data: getMonthNewSongData
    } = useInfiniteQuery([`getMonthNewestSongsInfiniteQuery${languages}`], ({pageParam = 0}) => getNewTracks({
            featured: 1,
            language: languages,
            offset: pageParam,
        }),
        {
            getNextPageParam: (lastPage, allPages) => {
                const allData = allPages?.flatMap((page) => page.data.result.tracks);
                return lastPage?.data?.result?.end ? false : allData?.length;
            },
            refetchOnWindowFocus: false
        })

    const getMonthNewSongTracks = getMonthNewSongData?.pages?.flatMap((page) => page.data.result.tracks);
    const getMonthNewSongNextPage = () => {
        if (getMonthNewSongHasNextPage && !getMonthNewSongIsFetchingNextPage) {
            getMonthNewSongIsFetchNextPage();
        }

    };
    const [slideIndex, setSlideIndex] = useState(1)

    function handleIndex(swiper) {
        // alert(swiper)
        setSlideIndex(swiper.activeIndex)
    }

    // const newestSwiperRef = useRef(null);
    // PauseBounceOnSwipe(newestSwiperRef);
    const collectionObject = {
        id: "0000",
    }
    useEffect(() => {
        return () => {
            dispatch(disableListSelectMode())
        };
    }, []);
    return (
        <Container header={<CommonHeader title={slideIndex === 1 ? 'جدیدهای ویژه' : 'همه جدیدها'}/>}
                   scroll={false}
                   style={"!overscroll-none"}>
            <AddToPlaylistModeButton EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}/>
            <ScrollTopButton show={slideIndex === 1} id={"scrollbarNewMonth"}/>
            <ScrollTopButton show={slideIndex === 0} id={"scrollbarWeekNew"}/>
            <Swiper cssMode={!isDesktop} className={"w-full"}
                    onSlideChange={handleIndex}
                    initialSlide={1}
            >
                <span slot="container-start">
                <SwiperTabs slideIndex={slideIndex} setSlideIndex={setSlideIndex}
                            tabContents={{
                                first: <AllNewTracksSVG color={slideIndex === 0 ? '#f2c94c' : '#717171'}/>,
                                second: <HotTracksSVG color={slideIndex === 1 ? '#f2c94c' : '#717171'}/>
                            }}/>
                    </span>

                <SwiperSlide className={"px-4 w-fit !webkit-overflow-scrolling-touch overflow-hidden"}>
                    <div id="scrollbarWeekNew"
                         className={"w-full mx-auto h-full !overflow-y-scroll !overflow-hidden !webkit-overflow-scrolling-touch"}>
                        {getNewSongTracks ?
                            <InfiniteScroll
                                className={"!overflow-hidden"}
                                next={getNewSongNextPage}
                                hasMore={getNewSongHasNextPage}
                                dataLength={getNewSongTracks?.length || 0}
                                loader={
                                  getNewSongIsFetchingNextPage &&
                                    <div className="w-full flex justify-center items-start h-[100px] -mt-20 ">
                                        <SongListLoading/>
                                    </div>
                                }
                                scrollableTarget="scrollbarWeekNew">
                                <div className={"w-full h-full !overflow-hidden pb-28 mt-3"}>
                                    <Virtuoso
                                        style={{overflowY: 'hidden !important'}}
                                        customScrollParent={document.getElementById('scrollbarWeekNew')}
                                        data={getNewSongTracks}
                                        itemContent={(index, item) => (
                                            <div className={"flex flex-col py-[0.175rem]"}>
                                                <SongDetailCard
                                                    isSelectMode={addToPlaylistDataState?.listSelectItems?.length > 0}
                                                    inPlayer={false}
                                                    id={item}
                                                    number={false}
                                                    index={index}
                                                    data={item}
                                                    playList={getNewSongTracks}
                                                    collectionObject={collectionObject}
                                                    apiUrl={'/getNewestTracks'}
                                                    requestPayload={{
                                                        featured: 0,
                                                        language: languages,
                                                    }}
                                                    endOfPagination={!getNewSongHasNextPage}
                                                />
                                            </div>
                                        )}/>
                                </div>
                            </InfiniteScroll>
                            :
                            <div className={"flex flex-col gap-3 mt-2"}>
                                {Array.from(
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                                        <SongsDetailCardShimmer/>
                                    ))
                                )}
                            </div>
                        }

                    </div>

                </SwiperSlide>
                <SwiperSlide className={"px-4 w-fit !webkit-overflow-scrolling-touch  overflow-hidden"}>
                    <div id="scrollbarNewMonth"
                         className={"h-full  !overflow-y-scroll  !overflow-x-hidden !webkit-overflow-scrolling-touch"}>
                        {getMonthNewSongTracks ?
                            <InfiniteScroll
                                className={"!overflow-hidden"}
                                next={getMonthNewSongNextPage}
                                hasMore={getMonthNewSongHasNextPage}
                                dataLength={getMonthNewSongTracks?.length || 0}
                                loader={
                                  getMonthNewSongIsFetchingNextPage &&
                                    <div className="w-full flex justify-center items-start h-[100px] -mt-20 ">
                                        <SongListLoading/>
                                    </div>
                                }
                                scrollableTarget="scrollbarNewMonth">

                                <div className={"w-full h-full !overflow-hidden  pb-28 mt-3"}>
                                    <Virtuoso
                                        style={{overflowY: 'scroll !important'}}
                                        customScrollParent={document.getElementById('scrollbarNewMonth')}
                                        data={getMonthNewSongTracks}
                                        itemContent={(index, item) => (
                                            <div className={"flex flex-col py-[0.175rem]"}>
                                                <SongDetailCard
                                                    isSelectMode={addToPlaylistDataState?.listSelectItems?.length > 0}
                                                    inPlayer={false}
                                                    id={item}
                                                    number={false}
                                                    index={index}
                                                    data={item}
                                                    playList={getMonthNewSongTracks}
                                                    collectionObject={collectionObject}
                                                    apiUrl={'/getNewestTracks'}
                                                    requestPayload={{
                                                        featured: 1,
                                                        language: languages,
                                                    }}
                                                    endOfPagination={!getMonthNewSongHasNextPage}
                                                />
                                            </div>
                                        )}/>
                                </div>
                            </InfiniteScroll>
                            :
                            <div className={"flex flex-col gap-3 mt-2"}>
                                {Array.from(
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                                        <SongsDetailCardShimmer/>
                                    ))
                                )}
                            </div>
                        }

                    </div>
                </SwiperSlide>
            </Swiper>
        </Container>

    );
}

export default NewestSongs;
